import React from "react";

export const Faucet = ({ width = "20px", height = "20px" }) => (
    <svg width="22" height="22" viewBox="0 0 22 22" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M9 6.0268C9.483 3.17323 11.9665 1 14.9575 1C18.2947 1 21 3.70532 21 7.0425C21 10.0335 18.8268 12.517 15.9732 13" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M7 21C10.3137 21 13 18.3137 13 15C13 11.6863 10.3137 9 7 9C3.68629 9 1 11.6863 1 15C1 18.3137 3.68629 21 7 21Z" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1 8C1 4.68286 3.68286 2 7 2L6.14286 3.71429" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M21 14C21 17.3171 18.3171 20 15 20L15.8571 18.2857" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

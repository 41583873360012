import Skeleton from "react-loading-skeleton";
import {TableContent} from "../../../library/table";
import {PaginationSection} from "../../../library/paginationSection";
import React, {useEffect, useState} from "react";
import {dateformatter, getPATTokenDetails, getUserDetails} from "../../../deps";
import {useParams} from "react-router-dom";
import {fetchApi} from "../../../_config/api";
import Image, {notification, Tooltip} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import "./styles.less";
import addIcon from "./addIcon.png"
import {AddContract} from "./addContract";

const STATUS = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    IN_ACTIVE: "IN-ACTIVE",
    FAILED: "FAILED",
    ALL: "ALL",
}
export const SmartContractPage = ({showLoader,isLoading}) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const {
        profile: { userRole, subscriptionId, userId },
    } = getUserDetails();
    const token = getPATTokenDetails();
    const authToken = token.patToken;
    const { instanceId } = useParams();
    const [collections, setCollections] = useState([]);
    const [instance, setInstance] = useState(null);
    const [instanceList, setInstanceList] = useState([]);
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: STATUS.ALL,
        chainOption: ""
    });
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [hitsList, setHitsList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [addContractOpen, setAddContractOpen] = useState(false);
    const [customId, setCustomId] = useState("");

    useEffect(() => {
        getAllInstance();
    }, [subscriptionId, token.patToken]);
    useEffect(() => {
        onGetCollection();
    }, [instance]);
    useEffect(() => {
        getAllTemplates();
    }, []);
    const getAllTemplates = async () => {
        showLoader(true);
        const filter =  {
            and: [
                {
                    key: "name",
                    value: "custom",
                    opt: "regex",
                },
                {
                    key: "isActive",
                    value: "true",
                    opt: "eq",
                },
            ],
        }

        try {
            const response = await fetchApi({
                method: "GET",
                url: `smart-contract-studio/templates?options={"page":0,"limit":1,"sort":{"order": 1},"select":[""]}&filter=${JSON.stringify(filter)}`,
                isDevApp: false,
            });
            if (response.statusCode === 200) {
                if(response.docs.length > 0) {
                    for(var i=0;i<response.docs.length;i++) {
                        if (response.docs[i].templateType === "CUSTOM"){
                            setCustomId(response.docs[i]._id);
                        }
                    }
                }
                showLoader(false);
            }
        } catch (error) {
            showLoader(false);
            notification.error({
                message: error.toString(),
                duration: 3,
            });
        }
    };
    const getAllInstance = () => {
        showLoader(true);
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify({ "services": ["smart-contract-studio"] })
        };
        console.log("Options ", options)
        fetch(`${gwURL}/api/v0/instance/getInstance`, options)
            .then(response => response.json())
            .then((response) => {
                showLoader(false);
                if (response.Status === "SUCCESS") {
                    const { Data = [] } = response
                    if (response.Data) {
                        setInstanceList([...Data]);
                        const instanceObj = Data.find(e => e.instanceId === instanceId);
                        if (instanceObj && !instanceObj?.instanceId) {
                            notification.error({
                                message: "instanceId not found",
                                duration: 3,
                            });
                        }
                        setInstance(instanceObj);
                    } else {
                        setInstanceList([]);
                    }
                }
            })
            .catch((error) => {
                setInstanceList([]);
                showLoader(false);
                notification.error({
                    message: error.toString(),
                    duration: 3,
                });
            });


    };

    const onGetCollection = () => {
            showLoader(true);
            let filter;
                filter = {
                    and: [
                        {
                            key: "developer",
                            value: userId,
                            opt: "eq",
                        },
                    ],
                    or: [
                        {
                            key: "status",
                            value: STATUS.ACTIVE,
                            opt: "eq",
                        },
                        {
                            key: "status",
                            value: STATUS.PENDING,
                            opt: "eq",
                        }
                    ],
                };
            const Options = () => {
                return {
                    select: "",
                    sort: { createdAt: -1 },
                    page: pageSetting.page - 1,
                    limit: pageSetting.itemsPerPage,
                };
            };
            let requestLink = `smart-contract-studio/contracts?options=${JSON.stringify(
                Options()
            )}&filter=${JSON.stringify(filter)}`;

            fetchApi({
                method: "GET",
                url: requestLink,
                isDevApp: false,
            })
                .then(async (response) => {
                    if (response.statusCode === 200) {
                        setCollections(response.docs);
                        showLoader(false);
                        setTotalCount(response.meta.totalDocs)
                    } else {
                        notification.error({
                            message: response.message,
                            duration: 3,
                        });
                    }
                })
                .catch((error) => {
                    notification.error({
                        message: error.toString(),
                        duration: 3,
                    });
                });
    };
    const ContractTable =[
        {
            title: "",
            dataIndex: "",
        },
        {
            title: "Name",
            dataIndex: "name",
            render: (_, { name }) => (
                <a>
                    <Tooltip title={name}>
                        {name}</Tooltip>
                </a>
            ),
        },
        {
            title: "",
            dataIndex: "",
        },
        {
            title: "Chain Id",
            dataIndex: "chainId",
        },
        {
            title: "Address",
            dataIndex: "contractAddress",
            render: (_, { contractAddress }) => (
                <a>
                    <Tooltip title={contractAddress}>
                        <span style={{overflow:"hidden",textOverflow:"ellipsis"}}>{contractAddress}</span>
                    </Tooltip>
                </a>
            ),
        },
        {
            title: "",
            dataIndex: "contractAddress",
            render: (_, { contractAddress }) => (
                <a>
                   <CopyOutlined onClick={() => onCopy(contractAddress)} />
                </a>
            ),
        },
        {
            title: "",
            dataIndex: "",
        },
        {
            title: "Standard",
            dataIndex: "ercStandard",
        },
        {
            title: "Linked dApp",
            dataIndex: "projectID",
            render: (_, { projectID }) => (
                <a>
                    <Tooltip title={projectID}>
                        <span style={{overflow:"hidden",textOverflow:"ellipsis"}}>{projectID}</span>
                    </Tooltip>
                </a>
            ),
        },
        {
            title: "",
            dataIndex: "projectID",
            render: (_, { projectID }) => (
                <a>
                    <CopyOutlined onClick={() => onCopy(projectID)} />
                </a>
            ),
        },
        {
            title: "Status",
            dataIndex: "status",
        },
    ];
    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        onGetCollection();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        onGetCollection();
    };

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };
    const addContractModal = () => {
        setAddContractOpen(!addContractOpen);
    };
     return (
         <div className="transaction-page-container">
             <div className="table-container">
                 <div style={{display:"flex",justifyContent:"space-between"}} className="header-section">
                     <div className="text">Your Contracts</div>
                     <div>
                         <button className="sc-button" onClick={() => setAddContractOpen(true)}>
                             <span style={{padding:"15px",fontFamily:'Poppins',fontSize:'18px',fontWeight:'700',lineHeight:'35px'}}>Add Contract <img style={{marginBottom:"2px",marginLeft:"5px"}} width="20px" height="20px" src={addIcon} /></span>
                         </button>
                     </div>
                 </div>
                 {isLoading?(<Skeleton height={180} style={{marginTop:'30px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                     <TableContent
                         rowKey="developerId"
                         data={collections}
                         columns={ContractTable}
                         isDashboard={false}
                         pagination={false}
                     />)}
             </div>
             <PaginationSection
                 last={last}
                 first={startIndex + 1}
                 hitsList={hitsList}
                 totalCount={totalCount}
                 page={pageSetting.page}
                 itemsPerPage={pageSetting.itemsPerPage}
                 onPageChange={onPageChange}
                 onPerPageChange={onPerPageChange}
             />
             <AddContract
                 isOpen={addContractOpen}
                 toggleState={addContractModal}
                 showLoader={showLoader}
                 isLoading={isLoading}
                 customId={customId}
             />
         </div>
     );
}
import React from 'react';
import './NewLandingPage.less';
import iconNoImage from './Icons/icon-no.svg';
import iconYesImage from './Icons/icon-yes.svg';

const PricingTable = () => {
    return (
        <div className="pricing-table-container">
            <table className="pricing-table">
                <thead>
                <tr>
                    <th style={{textAlign: "left", textIndent: "40px"}} className="gradient-text">Particulars</th>
                    <th>Bronze</th>
                    <th>Silver</th>
                    <th>Gold</th>
                </tr>
                {/*background: linear-gradient(90deg, #F81F49 0%, #A219BC 100%);*/}

                </thead>
                <tbody>
                <tr>
                    <td className="row-heading">Price</td>
                    <td>Free</td>
                    <td>$99/Mo</td>
                    <td>$1999/Mo</td>
                </tr>
                <tr>
                    <td className="row-heading">API Credits</td>
                    <td>50,000/Mo</td>
                    <td>150,000/Mo</td>
                    <td>5,000,000/Mo</td>
                </tr>
                <tr>
                    <td className="row-heading">Projects</td>
                    <td>Up to 5</td>
                    <td>Up to 15</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td className="row-heading">Service Instances</td>
                    <td>5 of each</td>
                    <td>Unlimited</td>
                    <td>Unlimited</td>
                </tr>
                <tr>
                    <td className="row-heading">Teams</td>
                    {/*<td><span className="icon-no">✘</span></td>*/}
                    {/*<td><span className="icon-no">✘</span></td>*/}
                    {/*<td><span className="icon-yes">✔</span></td>*/}

                    <td><img src={iconYesImage} style={{marginLeft:"15px", width:"35px", height:"35px"}} alt="Yes Icon" /></td> {/* Use imported image */}
                    <td><img src={iconYesImage} style={{marginLeft:"15px", width:"35px", height:"35px"}} alt="Yes Icon" /></td> {/* Use imported image */}
                    <td><img src={iconNoImage} style={{marginLeft:"15px", width:"35px", height:"35px"}} alt="No Icon" /></td> {/* Use imported image */}
                </tr>
                <tr>
                    <td className="row-heading">Gasless</td>
                    <td>Up to 0.1 testnet <span className="next-line-indent">Matic</span></td>
                    <td>Up to 0.5 testnet <span className="next-line-indent">Matic</span></td>
                    <td>Custom</td>
                </tr>
                <tr>
                    <td className="row-heading">Datalake</td>
                    <td>Up to 100 <span className="next-line-indent">records</span></td>
                    <td>Up to 500 <span className="next-line-indent">records</span></td>
                    <td>Custom</td>
                </tr>
                <tr>
                    <td className="row-heading">Support</td>
                    <td>Community</td>
                    <td>Dedicated</td>
                    <td>Priority</td>
                </tr>
                </tbody>
            </table>
        </div>
    );
};

export default PricingTable;

import type, {RadioChangeEvent} from "antd";
import {Form, Radio,Tooltip} from "antd";
import React from "react";
import {InfoCircleOutlined} from "@ant-design/icons";

export const Service = ({
                            title = "Choose the storage for your Project",
                            id,
                            selectedValue = [],
                            onChange,
                            options = [],
                            nextClicked,
                            nodeValue
                        }) => {
    const [form] = Form.useForm(); // Create a form instance
    const storageExist = nodeValue.find(obj => obj.hasOwnProperty("StorageEndpoint"));
    const didExist = nodeValue.find(obj => obj.hasOwnProperty("DIEndpoint"));
    const scExist = nodeValue.find(obj => obj.hasOwnProperty("SCEndpoint"));
    let isStorageExist = true;
    let obj1 =   {
        ["EasyNFT"]: {InstanceID: "", ServiceName: "easy-nft", isRequired: false}
    }
    let obj5 =  {
        ["StorageManager"]: {InstanceID: "", ServiceName: "storagemanageripfs", isRequired: false}
    }
    if(storageExist){
        isStorageExist = false;
        obj1 =   {
            ["EasyNFT"]: {InstanceID: "", ServiceName: "easy-nft", isRequired: true}
        }
        obj5 =  {
            ["StorageManager"]: {InstanceID: "", ServiceName: "storagemanageripfs", isRequired: true}
        }
    }else{
        obj1 =   {
            ["EasyNFT"]: {InstanceID: "", ServiceName: "easy-nft", isRequired: false}
        }
        obj5 =  {
            ["StorageManager"]: {InstanceID: "", ServiceName: "storagemanageripfs", isRequired: false}
        }
    }
    let isDidExist = true;
    let obj4 =  {
        ["DID_Manager"]: {InstanceID: "", ServiceName: "did", isRequired: false}
    }
    if(didExist){
        isDidExist = false;
        obj4 =  {
            ["DID_Manager"]: {InstanceID: "", ServiceName: "did", isRequired: true}
        }
    }else{
        obj4 =  {
            ["DID_Manager"]: {InstanceID: "", ServiceName: "did", isRequired: false}
        }
    }
    let isSCExist = true;
    let obj8 =  {
        ["SmartContractWallet"]: {InstanceID: "", ServiceName: "scWallet", isRequired: false}
    }
    if(scExist){
        isSCExist = false;
        obj8 =  {
            ["SmartContractWallet"]: {InstanceID: "", ServiceName: "scWallet", isRequired: true}
        }
    }else{
        obj8 =  {
            ["SmartContractWallet"]: {InstanceID: "", ServiceName: "scWallet", isRequired: false}
        }
    }
    let newList = [...selectedValue];
    const obj2 =   {
        ["SmartContractStudio"]: {InstanceID: "", ServiceName: "smart-contract-studio", isRequired: true}
    }
    const obj3 =   {
        ["FT_Manager"]: {InstanceID: "", ServiceName: "ft-manager", isRequired: true}
    }
    const obj6 =  {
        ["CustodialWallet"]: {InstanceID: "", ServiceName: "wallet", isRequired: false}
    }
    const obj7 =  {
        ["NonCustodialWallet"]: {InstanceID: "", ServiceName: "ncWallet", isRequired: true}
    }
    const obj9 =  {
        ["DeveloperWallet"]: {InstanceID: "", ServiceName: "devWallet", isRequired: true}
    }
    /*const obj8 =  {
        ["SmartContractWallet"]: {InstanceID: "", ServiceName: "scWallet", isRequired: false}
    }*/
    React.useEffect(
        () => {
            if(newList.length === 0){
                newList.push(obj1,obj2,obj3,obj4,obj5,obj6,obj7, obj8,obj9)
                // console.log("newlist---------->",newList)
            }else{
                const storageexistingObject = newList.find(obj => obj.StorageManager !== undefined);
                const smartcontractObject = newList.find(obj => obj.SmartContractWallet !== undefined);
                const nftexistingObject = newList.find(obj => obj.EasyNFT !== undefined);
                const didexistingObject = newList.find(obj => obj.DID_Manager !== undefined);
                let required = false;
                let didrequired = false;
                let screquired = false;
                if(storageExist){
                    required = true
                }
                if(didExist){
                    didrequired = true;
                }
                if(scExist){
                    screquired = true;
                }
                const storagenewObj = {
                    ["StorageManager"]: {InstanceID: "", ServiceName: "storagemanageripfs", isRequired: required}
                }
                const nftnewObj = {
                    ["EasyNFT"]: {InstanceID: "", ServiceName: "easy-nft", isRequired: required}
                }
                const didnewObj = {
                    ["DID_Manager"]: {InstanceID: "", ServiceName: "did", isRequired: didrequired}
                }
                const scnewObj = {
                    ["SmartContractWallet"]: {InstanceID: "", ServiceName: "scWallet", isRequired: screquired}
                }
                if(storageexistingObject.StorageManager.isRequired !== false){
                    newList = newList.map(obj => obj === storageexistingObject ? storagenewObj : obj);
                }else{
                    // newList = newList.map(obj => obj === storagenewObj);
                }
                if(nftexistingObject.EasyNFT.isRequired !== false){
                    newList = newList.map(obj => obj === nftexistingObject ? nftnewObj : obj);
                }else{
                    // newList.push(nftnewObj)

                }
                if(didexistingObject.DID_Manager.isRequired !== false){
                    newList = newList.map(obj => obj === didexistingObject ? didnewObj : obj);
                }else{
                    // newList.push(didnewObj)

                }
                if(smartcontractObject.SmartContractWallet.isRequired !== false){
                    newList = newList.map(obj => obj === smartcontractObject ? scnewObj : obj);
                }else{
                    // newList.push(didnewObj)

                }
                // console.log("newlist---------->",newList)
            }
            onChange(id,newList)
        },[storageExist,didExist,scExist])
    // if(newList.length === 0){
    //     newList.push(obj1,obj2,obj3,obj4,obj5,obj6,obj7)
    //     console.log("newlist---------->",newList)
    //     onChange(id,newList)
    // }
    // alert(newList);
    React.useEffect(
        () => {
            form.setFieldsValue({
                "Custodial Wallet": "Yes",
                "Non-Custodial Wallet": "Yes",
                "Smart Contract Wallet": "Yes",
                "Storage Manager": "Yes",
                "Smart Contract Studio": "Yes",
                "EasyNFT": "Yes",
                "DID Manager": "Yes",
                "FT Manager": "Yes",
            });
            if(nextClicked){
                newList.map(obj => {
                    if(obj.CustodialWallet !== undefined && obj.CustodialWallet.ServiceName === "wallet"){
                        form.setFieldsValue({
                            "Custodial Wallet": obj.CustodialWallet.isRequired === true ? "Yes" : "No",
                        });
                    }else if(obj["NonCustodialWallet"] !== undefined && obj["NonCustodialWallet"].ServiceName === "ncWallet"){
                        form.setFieldsValue({
                            "Non-Custodial Wallet": obj["NonCustodialWallet"].isRequired === true ? "Yes" : "No",
                        });
                    }else if(obj["SmartContractWallet"] !== undefined && obj["SmartContractWallet"].ServiceName === "scWallet"){
                        form.setFieldsValue({
                            "Smart Contract Wallet": obj["SmartContractWallet"].isRequired === true ? "Yes" : "No",
                        });
                    }else  if(obj.StorageManager !== undefined && obj.StorageManager.ServiceName === "storagemanageripfs"){
                        form.setFieldsValue({
                            "Storage Manager": obj.StorageManager.isRequired === true ? "Yes" : "No",
                        });
                    }else  if(obj.SmartContractStudio !== undefined && obj.SmartContractStudio.ServiceName === "smart-contract-studio"){
                        form.setFieldsValue({
                            "Smart Contract Studio": obj.SmartContractStudio.isRequired === true ? "Yes" : "No",
                        });
                    }else  if(obj.EasyNFT !== undefined && obj.EasyNFT.ServiceName === "easy-nft"){
                        form.setFieldsValue({
                            "EasyNFT": obj.EasyNFT.isRequired === true ? "Yes" : "No",
                        });
                    }else  if(obj.DID_Manager !== undefined && obj.DID_Manager.ServiceName === "did"){
                        form.setFieldsValue({
                            "DID Manager": obj.DID_Manager.isRequired === true ? "Yes" : "No",
                        });
                    }else  if(obj.FT_Manager !== undefined && obj.FT_Manager.ServiceName === "ft-manager"){
                        form.setFieldsValue({
                            "FT Manager": obj.FT_Manager.isRequired === true ? "Yes" : "No",
                        });
                    }

                });


            }
        }, [nextClicked,selectedValue,form]);
    const onChangeRadio = (e,name) => {
        const value  = e.target.value;
        let existingObject = "";
                let newObj={};
                let required = false;
                if(name === "easy-nft"){
                    existingObject = newList.find(obj => obj.EasyNFT !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["EasyNFT"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "smart-contract-studio"){
                    existingObject = newList.find(obj => obj.SmartContractStudio !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["SmartContractStudio"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "did"){
                    existingObject = newList.find(obj => obj.DID_Manager !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["DID_Manager"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "ft-manager"){
                    existingObject = newList.find(obj => obj.FT_Manager !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["FT_Manager"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "storagemanageripfs"){
                    existingObject = newList.find(obj => obj.StorageManager !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["StorageManager"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "wallet"){
                    existingObject = newList.find(obj => obj.CustodialWallet !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["CustodialWallet"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "ncWallet"){
                    existingObject = newList.find(obj => obj["NonCustodialWallet"] !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["NonCustodialWallet"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }else if(name === "scWallet"){
                    existingObject = newList.find(obj => obj["SmartContractWallet"] !== undefined);
                    if(value === "Yes") {
                        required = true;
                    }else{
                        required = false;
                    }
                    newObj = {
                        ["SmartContractWallet"]: {InstanceID: "", ServiceName: name, isRequired: required}
                    }
                    if(existingObject){
                        newList = newList.map(obj => obj === existingObject ? newObj : obj);
                    }else{
                        newList.push(newObj)

                    }
                }
            onChange(id, newList);
        // console.log("newlist------->",newList)
    };


    return (
        <div>
            <Form name="service" form={form} initialValues={{}}
                  labelCol={{ xs: { span: 24 }, sm: { span: 12 } }} wrapperCol={{ xs: { span: 24 }, sm: { span: 16 } }}>
                <Form.Item
                    name="DID Manager"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className={isDidExist ? "disabled-service-text" : "service-text"}>Do you require a DID Manager? &nbsp;<Tooltip title="Decentralized Identity Manager" color={"geekblue"}><InfoCircleOutlined /></Tooltip></p>}>
                    <Radio.Group name="did" disabled={isDidExist}
                        onChange={(event) => onChangeRadio(event, "did")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title={!isDidExist ? "A DID Manager helps manage and authenticate decentralized identities securely.": "Please select DID Nodes to proceed with this option"} color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title={!isDidExist ?"You may use other identity management approaches." : "Please select DID Nodes to proceed with this option"} color={"geekblue"}>No</Tooltip></span></Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="Non-Custodial Wallet"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className="service-text">Do you need a Self-Managed Wallet Manager? &nbsp;<Tooltip title="Blockchain-based Self-Managed Wallet Manager" color={"geekblue"}><InfoCircleOutlined /></Tooltip> </p>}>
                    <Radio.Group name="ncWallet"
                                 onChange={(event) => onChangeRadio(event, "ncWallet")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title="A Self-Managed Wallet Manager enables secure management of blockchain-based assets." color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title="You may consider alternative methods for managing digital assets." color={"geekblue"}>No</Tooltip></span></Radio>

                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="Smart Contract Wallet"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className={isSCExist ? "disabled-service-text" : "service-text"}>Do you need a Smart Contract Wallet Manager? &nbsp;<Tooltip title="Blockchain-based Self-Managed Wallet Manager" color={"geekblue"}><InfoCircleOutlined /></Tooltip> </p>}>
                    <Radio.Group name="scWallet" disabled={isSCExist}
                                 onChange={(event) => onChangeRadio(event, "scWallet")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title="A Smart Contract Wallet Manager enables secure management of blockchain-based assets." color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title="You may consider alternative methods for managing digital assets." color={"geekblue"}>No</Tooltip></span></Radio>

                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    name="Storage Manager"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className={isStorageExist ? "disabled-service-text" : "service-text"}>Will you be utilizing a Storage Manager? &nbsp;<Tooltip title="Blockchain-based Storage Manager" color={"geekblue"}><InfoCircleOutlined /></Tooltip></p>}>
                    <Radio.Group name="storage" disabled={isStorageExist}
                        onChange={(event) => onChangeRadio(event, "storagemanageripfs")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title={!isStorageExist ? "A Storage Manager facilitates storing and retrieving data on the blockchain network securely." : "Please select Storage Nodes to proceed with this option" } color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title={!isStorageExist ? "You may use alternative data storage solutions." : "Please select Storage Nodes to proceed with this option" } color={"geekblue"}>No</Tooltip></span></Radio>

                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="EasyNFT"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className={isStorageExist ? "disabled-service-text" : "service-text"}>Are you interested in using EasyNFT? &nbsp;<Tooltip title="Easy Non-Fungible Token Integration" color={"geekblue"}><InfoCircleOutlined /></Tooltip></p>}>
                    <Radio.Group name="easynft" disabled={isStorageExist}
                                 onChange={(event) => onChangeRadio(event, "easy-nft")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title={!isStorageExist ? "EasyNFT simplifies the creation and management of non-fungible tokens (NFTs) on the blockchain." : "Please select Storage Nodes to proceed with this option" } color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title={!isStorageExist ? "You may explore other methods for NFT integration." : "Please select Storage Nodes to proceed with this option" } color={"geekblue"}>No</Tooltip></span></Radio>

                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="FT Manager"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className="service-text">Will you be utilizing the FT Manager? &nbsp;<Tooltip title="Fungible Token Manager" color={"geekblue"}><InfoCircleOutlined /></Tooltip></p>}>
                    <Radio.Group name="ft"
                                 onChange={(event) => onChangeRadio(event, "ft-manager")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title="The FT Manager assists in managing fungible tokens on the blockchain network." color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title="You may choose alternative methods for managing fungible tokens." color={"geekblue"}>No</Tooltip></span></Radio>

                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    name="Smart Contract Studio"
                    label={<p style={{ whiteSpace: 'normal',display: 'inline-block' }} className="service-text">Do you require a Smart Contract Studio? &nbsp;<Tooltip title="Blockchain-based Smart Contract Development Tool" color={"geekblue"}><InfoCircleOutlined /></Tooltip></p>}>
                    <Radio.Group name="smc"
                        onChange={(event) => onChangeRadio(event, "smart-contract-studio")}
                    >
                        <Radio value="Yes"><span
                            className="service-text"><Tooltip title="A Smart Contract Studio provides a user-friendly environment for developing and deploying smart contracts on the blockchain." color={"geekblue"}>Yes</Tooltip></span></Radio>
                        <Radio value="No"><span
                            className="service-text"><Tooltip title="You may consider other methods for smart contract development." color={"geekblue"}>No</Tooltip></span></Radio>

                    </Radio.Group>
                </Form.Item>

            </Form>
        </div>
    );
};

export default Service;
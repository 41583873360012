import React from "react";

export const Optimism = ({ width = "50px", height = "50px" }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 400.000000 400.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,400.000000) scale(0.100000,-0.100000)"
      fill="#fe0420"
      stroke="#ffffff"
    >
      <path
        d="M1768 3985 c-458 -56 -852 -248 -1179 -574 -156 -157 -257 -292 -357
-481 -285 -536 -306 -1173 -58 -1741 150 -341 425 -662 746 -871 251 -162 544
-266 855 -303 338 -39 677 9 995 142 248 104 445 236 641 432 156 157 257 292
357 481 285 536 306 1174 58 1741 -150 341 -425 662 -746 871 -379 245 -863
357 -1312 303z m-46 -1446 c152 -33 260 -138 283 -276 8 -48 -6 -150 -42 -313
-74 -334 -238 -480 -540 -480 -257 0 -413 122 -413 324 0 99 71 399 115 486
73 144 209 243 360 263 65 9 187 6 237 -4z m1068 -10 c77 -12 164 -49 203 -87
52 -49 71 -99 70 -182 -1 -175 -103 -325 -259 -381 -49 -18 -88 -23 -235 -27
l-176 -4 -28 -137 c-38 -192 -43 -209 -65 -221 -30 -16 -235 -13 -249 4 -9 11
2 79 49 297 33 156 81 381 106 499 35 169 49 219 66 233 18 15 45 17 237 17
119 0 245 -5 281 -11z"
      />
      <path
        d="M1481 2308 c-80 -39 -116 -113 -160 -325 -12 -56 -21 -129 -21 -162
0 -53 4 -65 29 -93 24 -28 36 -32 90 -36 114 -8 188 40 229 151 27 72 72 289
72 349 0 26 -4 58 -10 72 -24 64 -142 87 -229 44z"
      />
      <path
        d="M2494 2318 c-5 -8 -54 -234 -54 -250 0 -5 47 -8 105 -8 86 0 113 4
145 20 53 27 80 69 87 134 5 49 3 57 -20 80 -24 24 -34 26 -109 27 -46 1 -98
2 -116 4 -18 1 -35 -2 -38 -7z"
      />
    </g>
  </svg>
);

export default Optimism;

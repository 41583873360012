import React, {useEffect, useState} from 'react';
import './style.less';
import {getUserDetails} from "../../deps";
import GaslessProtocolConfig from "./protocol-gasLimit-config/gaslessProtocolConfig";
import GaslessWhitelistedContracts from "./whitelisted-contracts/gaslessWhitelistedContracts";
import GaslessFundedTx from "./funded-transaction/gaslessFundedTx";
import GaslessFooterDetails from "./footer-details/gaslessFooterDetails";
import fetchApi from "../../_config/api";
import {notification} from "antd";

export const GaslessManager = ({ showLoader,isLoading }) => {
    const userDetails = getUserDetails();
    const [userSubscribedToGasless, setUserSubscribedToGasless] = useState(false);



    async function getSubscriptionDetails(){
        let httpUrl = 'subscription/get';
        let planDetailsResp = await localApiCaller('POST', httpUrl, { subscriptionId: userDetails.profile.subscriptionId });


        const dataLakeService = planDetailsResp?.planDetails?.services.find(
            (service) => service.key === "gasless"
        );

        if (dataLakeService && dataLakeService?.isRequired)  {
            setUserSubscribedToGasless(true)
        }else{
            showLoader(false)
            notification.error({
                message: "You are not subscribed to Gasless Service.",
                description: "Please subscribe to Gasless service to access the features.",
                duration: 4,
            });
        }
    }
    const localApiCaller = (methodName, url, request) => {
        return fetchApi({ method: methodName, url: url, data: request })
            .then((response) => {
                return response.statusCode == 200 ? response.docs || response.doc : [];
            })
            .catch((error) => {
                throw error; // Re-throw the error
            });
    };


    useEffect(()=>{
        showLoader(true)
        getSubscriptionDetails().then()
    },[])

    function callBack(status){
        showLoader(false)
    }

    return (
        <div className="gasless-manager-container">

            {userSubscribedToGasless ? (
                <>
                    <GaslessProtocolConfig isLoading={isLoading} callBack={callBack} />
                    <GaslessWhitelistedContracts isLoading={isLoading} />
                    <GaslessFundedTx isLoading={isLoading} />
                    {/*<GaslessFooterDetails isLoading={isLoading} />*/}
                </>
            ) : (
                <>
                    <p>Gasless Service not available</p>
                </>
            )}


        </div>
    );
};

import React from "react";
import {Logos, Menus} from "../../assets";
import "./styles.less";

const tutorialurl = process.env.REACT_APP_DASHBOARD_TUTORIAL_URL;
const developerurl = process.env.REACT_APP_DASHBOARD_DEVELOPER_URL;
const releaseurl = process.env.REACT_APP_DASHBOARD_RELEASE_URL;
const exampleurl = process.env.REACT_APP_DASHBOARD_EXAMPLE_URL;



export const Card = ({ type, count, text, color="orange" }) => (
  <div className={`card-section-container radial-gradient-${color}`}>
    <div className="icon-container">
        <div className="count-container">{count}</div>
        <div className="text-container">{text}</div>
    </div>
  </div>
);
export const CardGradient = ({ type, count, text, color="orange" }) => (
    <div className={`card-section-container radial-gradient-${color}`}>
      <div className="icon-container">
          <div className="text-container-grad">{text}</div>
      </div>
    </div>
  );

export const Card3 = ({ body, name, color="yellow" }) => (
    <div className={`card-section-container3`}>
        <div className="text-container">{name}</div>
        <div className="text-container">{body}</div>
    </div>
);
export const EndpointCard3 = ({ body, name, color="yellow" }) => (
    <div className={`endpoint-card-section-container3`}>
        <div className="text-container">{name}</div>
        <div className="text-container">({body})</div>
    </div>
);
export const TutorialCard = ({ body, text, color="yellow" }) => (
    <div className={`card-section-container2`}>
        <div className="text-container">{text}</div>
        <div className="card-text-body">{body}</div>
        <div className="card-text-bottom">
            <a href={tutorialurl} target="_blank">Learn how <Logos type="link" width="30px" height="30px" /></a>
        </div>
    </div>
);

export const DeveloperCard = ({ body, text, color="yellow" }) => (
    <div className={`card-section-container2`}>
        <div className="text-container">{text}</div>
        <div className="card-text-body">{body}</div>
        <div className="card-text-bottom">
            <a href={developerurl} target="_blank">Learn how <Logos type="link" width="30px" height="30px" /></a>
        </div>
    </div>
);

export const ReleaseCard = ({ body, text, color="yellow" }) => (
    <div className={`card-section-container2`}>
        <div className="text-container">{text}</div>
        <div className="card-text-body">{body}</div>
        <div className="card-text-bottom">
            <a href={releaseurl} target="_blank">Learn how <Logos type="link" width="30px" height="30px" /></a>
        </div>
    </div>
);

export const ExampleCard = ({ body, text, color="yellow" }) => (
    <div className={`card-section-container2`}>
        <div className="text-container">{text}</div>
        <div className="card-text-body">{body}</div>
        <div className="card-text-bottom">
            <a href={exampleurl} target="_blank">Learn how <Logos type="link" width="30px" height="30px" /></a>
        </div>
    </div>
);
export const ProjectCard = ({ body, name, color="yellow",onClick }) => (
    <div style={{cursor:"pointer"}} className={`card-section-container3`} onClick={onClick}>
        <div className="text-container">{name}</div>
        <div className="text-container">{body}</div>
    </div>
);

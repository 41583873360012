import React from 'react'
//import Logo1 from '../../../assets/icons/Krypcore logo 1.svg'
import oneaccessLogo from '../../../assets/icons/400 1 (1).png'
import group1 from '../../../assets/icons/image01.svg'
import group2 from '../../../assets/icons/image02.svg'
import group3 from '../../../assets/icons/image03.svg'
import group4 from '../../../assets/icons/image04.svg'
import group5 from '../../../assets/icons/image05.svg'
import group6 from '../../../assets/icons/image06.svg'
import group7 from '../../../assets/icons/image07.svg'
import group8 from '../../../assets/icons/image08.svg'
import group9 from '../../../assets/icons/image09.svg'
import group10 from '../../../assets/icons/image 116.svg'
import group11 from '../../../assets/icons/image11.svg'
import group12 from '../../../assets/icons/image12.svg'
import group13 from '../../../assets/icons/image13.svg'
// import group14 from '../../../assets/icons/image14.svg'
import group15 from '../../../assets/icons/image15.svg'
import group16 from '../../../assets/icons/image16.svg'
import { useInView } from 'react-intersection-observer'
import useIntersectionObserver from './useIntersectionObserver'

const BlockchainNetwork = () => {

  const [ref, isInView] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });


  return (
    <>
    <div className='light-theme-blockchain-container'>
        <div className='light-theme-blockchain'>
            <div>
                <h2 ref={ref} className={`light-theme-blockchain-header ${isInView ?'animated animatedFadeInUp fadeInUp':'hidden'}`}>One-click Access to Leading <br className="light-theme-hiddenbreaktag1" /> <span className='light-theme-blockchain-header-gradient'> <br className="light-theme-hiddenbreaktag" />Blockchain Networks</span> </h2>
                <p className='light-theme-blockchain-para'>Access fast, reliable, and highly scalable RPC Nodes to<br className="light-theme-hiddenbreaktag" /> seamlessly build decentralized solutions on various EVM <br className="light-theme-hiddenbreaktag" /> and non-EVM networks.</p>
            </div>
            <div className='blockchainlogoborder1'>
                <img src={oneaccessLogo} className='blockchainlogoborder' height='auto' width='auto'/>
            </div>
        </div>
    </div>
    <div style={{display:'flex',flexDirection:'column'}}>
    <div className="light-theme-clientlogo-wrap">
            <div className="marquee-content1">
              <div className="container-fluid">
                <div className="light-theme-plandetails-sec2-image-container_1 ">
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group1} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group2} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group3} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group4} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group5} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group6} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group7} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group8} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group1} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group2} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group3} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group4} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group5} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group6} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group7} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group8} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group1} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group2} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group3} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group4} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group5} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group6} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group7} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group8} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group1} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group2} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group3} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group4} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group5} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group6} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group7} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group8} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                
                
                </div>
              </div>
            </div>
          </div>

          <div className="light-theme-clientlogo-wrap">
            <div className="marquee-content2">
              <div className="container-fluid">
                <div className="light-theme-plandetails-sec2-image-container_2">
                
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group9} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group10} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group11} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group12} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group13} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  {/* <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group14} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div> */}
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group15} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group16} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group9} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group10} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group11} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group12} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group13} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  {/* <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group14} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div> */}
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group15} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group16} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group9} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group10} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group11} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group12} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group13} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  {/* <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group14} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div> */}
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group15} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group16} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group9} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group10} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group11} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group12} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group13} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  {/* <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group14} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div> */}
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group15} className="light-theme-clientlogowidth" alt="Simens" />
                    </div>
                  </div>
                  <div className="col-md-1">
                    <div className="clientlogo-item">
                      <img src={group16} className="light-theme-clientlogowidth" alt="" />
                    </div>
                  </div>
                
                </div>
              </div>
            </div>
          </div>
          </div>
  </>)
}

export default BlockchainNetwork
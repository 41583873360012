import Web3Modal from "web3modal";
import { ethers } from "ethers";
const { providerOptions } = require("./providerOptions");


export const connectWallet = async () => {
  const web3Modal = new Web3Modal({
    theme: "dark",
    
  });
  const connection = await web3Modal.connect();
  const provider = new ethers.providers.Web3Provider(connection, "any");
  const signer = provider.getSigner();
  const address = await signer.getAddress();
  return [provider, signer, address];
};

import React from "react";

export const FooterDiscord = ({}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
        <g clipPath="url(#clip0_175_1359)">
            <mask id="mask0_175_1359"  style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
                <path d="M24 0.580078H0V24.5801H24V0.580078Z" fill="white"/>
            </mask>
            <g mask="url(#mask0_175_1359)">
                <path fillRule="evenodd" clipRule="evenodd" d="M16.3562 19.1763C16.9726 19.9572 17.7123 20.8407 17.7123 20.8407C22.2534 20.6969 24 17.7174 24 17.7174C24 11.101 21.0411 5.73795 21.0411 5.73795C18.0822 3.51878 15.2671 3.58025 15.2671 3.58025L14.9795 3.90919C18.4726 4.97768 20.0959 6.51878 20.0959 6.51878C17.9589 5.34754 15.863 4.7722 13.911 4.54617C12.4315 4.38179 11.0137 4.42289 9.76027 4.58727C9.65265 4.58727 9.56068 4.60293 9.45703 4.62058C9.44195 4.62315 9.42662 4.62576 9.41096 4.62837C8.69178 4.69001 6.94521 4.95713 4.74657 5.92289C3.9863 6.2722 3.53425 6.51878 3.53425 6.51878C3.53425 6.51878 5.23973 4.89549 8.93835 3.82699L8.73288 3.58025C8.73288 3.58025 5.91781 3.51878 2.9589 5.73795C2.9589 5.73795 0 11.101 0 17.7174C0 17.7174 1.72603 20.6969 6.26712 20.8407C6.26712 20.8407 7.0274 19.9161 7.64384 19.1352C5.03425 18.3544 4.04795 16.7106 4.04795 16.7106C4.04795 16.7106 4.25342 16.8544 4.62329 17.0599C4.64383 17.0804 4.66439 17.101 4.70548 17.1215C4.7363 17.1421 4.76712 17.1575 4.79794 17.1729C4.82877 17.1883 4.85959 17.2037 4.89041 17.2243C5.40411 17.5119 5.91781 17.738 6.39041 17.9229C7.23288 18.2517 8.23973 18.5804 9.41096 18.8065C10.9521 19.0941 12.7603 19.1969 14.7329 18.827C15.6986 18.6626 16.6849 18.375 17.7123 17.9435C18.4315 17.6763 19.2329 17.2859 20.0753 16.7311C20.0753 16.7311 19.0479 18.4161 16.3562 19.1763ZM6.06164 13.5257C6.06164 12.2722 6.9863 11.2448 8.15753 11.2448C9.32877 11.2448 10.274 12.2722 10.2534 13.5257C10.2534 14.7791 9.32877 15.8065 8.15753 15.8065C7.00685 15.8065 6.06164 14.7791 6.06164 13.5257ZM13.5616 13.5257C13.5616 12.2722 14.4863 11.2448 15.6575 11.2448C16.8288 11.2448 17.7534 12.2722 17.7534 13.5257C17.7534 14.7791 16.8288 15.8065 15.6575 15.8065C14.5068 15.8065 13.5616 14.7791 13.5616 13.5257Z" fill="#D2D2D2"/>
            </g>
        </g>
        <defs>
            <clipPath id="clip0_175_1359">
                <rect width="24" height="24" fill="white" transform="translate(0 0.580078)"/>
            </clipPath>
        </defs>
    </svg>
);

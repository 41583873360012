import React, {useEffect, useState} from "react";
import Logo from "../../../assets/logos/logo.avif"
import {useLocation, useNavigate} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {DEVELOPERROLE, removeAllDataInLocal} from "../../../deps";
import {notification} from "antd";
import '../photoNftStyles.less'
import {MenuFoldOutlined} from "@ant-design/icons";

export const Header = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [section,setsection] = useState('')

  let features = ""
   React.useEffect(()=>{
     if (location.state != null){
       if (location.state.value != null) {
         features = location.state.value
         document.getElementById(features).scrollIntoView({behavior: "smooth"})
         setsection(features)
       }
     }

  },[])


  const navigateToheader = (value) => {
    document.getElementById(value).scrollIntoView({ behavior: "smooth" })
    setsection(value)
  };

  const logout_ = () => {
    logout({
      returnTo: window.location.origin,
    });
    removeAllDataInLocal();
  };
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();
  const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL
  const onNavigateDeveloper = () => {
    loginWithRedirect({
      appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
      userRole: DEVELOPERROLE,
      userType: "INDIVIDUAL",
    });

  };
  const onNavigatetosignup = ()=>{
      loginWithRedirect({
        screen_hint: 'signup',
        appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
        userRole: DEVELOPERROLE,
        userType: "INDIVIDUAL",
      });
  }
  const open = (value) => {
    window.open(value)
  };
  const navigateToBlog = (value) => {
    navigate('/blog')
  };

  const onnavigate = ()=>{
    navigate('/enterprise')
  }

  useEffect(() => {
    if (isAuthenticated && user.email_verified === false) {
      logout_();
      notification.error({
        message: "Please Verify Email!",
        duration: 10,
      });
    }
  }, [isAuthenticated]);

  return (
    <>
      <div className="header-wrap">
          <nav className="navbar navbar-expand-lg">
            <div className="navbar-logo-division">
              <Link className="navbar-brand" to="/" aria-label="">
                <img src={Logo} alt="KrypC" className="logo" />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"><MenuFoldOutlined style={{color:"#fff"}}/></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">

                <li className="nav-item" onClick={()=>navigateToheader('home')}>
                  <a className={section === 'home' ? 'nav-link active-link' : 'nav-link'}  >
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={()=>onnavigate()}>
                    Enterprise
                  </a>
                </li>
                <li className="nav-item" onClick={()=>navigateToBlog()}>
                  <a className="nav-link" >
                    Blog
                  </a>
                </li>
                <li className="nav-item" >
                  <a className="nav-link" href="/contact-us">
                    Contact Us
                  </a>
                </li>

              </ul>
              <ul className="navbar-nav menu-right ml-auto d-flex align-items-center">
                <li>
                  {isAuthenticated ? (
                    <button
                      className="btn header-signupbtn"
                      onClick={() => logout_()}
                    >
                      Log Out
                    </button>
                  ) : (
                      <div>
                        &nbsp;&nbsp;
                    <span
                        className="text-white"
                      onClick={() => onNavigateDeveloper()}
                    >
                      Login
                    </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            className="header-signupbtn"
                            onClick={() => onNavigatetosignup()}
                        >
                         Get Started
                        </button>

                    </div>
                  )}
                </li>
              </ul>
            </div>
          </nav>
        </div>
    </>
  );
};

export default Header;
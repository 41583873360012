import React from "react";
import { EnterpriseRoleSelect } from "./enterpriseRoleSelect";
import { PromotionTile } from "./promoTile"; 
import './style.less';

export const EnterpriseSignUp = () => {
  return (
    <div className="enterprise-signup-page">
        <PromotionTile/>
        <EnterpriseRoleSelect/> 
    </div>
  );
};

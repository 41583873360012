import React from "react";
import Header from "./ui/Header.js";
import Banner from "./ui/Banner.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import './photoNftStyles.less'

import { notification } from "antd";
import {Helmet} from "react-helmet";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const PhotoNFTPage = () => {

  const navigate = useNavigate();
  let query = useQuery();

  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    AuthenticatiedUser()
  }, [])

  useEffect(() => {
    if(query.get("message")){
      notification.info({
        message: query.get("message"),
        duration: 3,
      });
    }
  }, [query.get("message")])

  const AuthenticatiedUser = () => {
    if (isAuthenticated) { // && user.email_verified === true
      navigate("/dashboard")
    }
  }

  return (
      <>
        <Helmet>
          <meta name="robots" content="noindex, nofollow" />
        </Helmet>
        <div className="photoNFT_responsive">
          <Header />
          <Banner />
        </div>
  </>
  );
};

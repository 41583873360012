import React, {useEffect, useState} from "react";
import "./style.less"
import fetchApi from "../../_config/api";
import {notification} from "antd";
import {RightOutlined } from "@ant-design/icons";
import {Link, useNavigate} from "react-router-dom";
import {Helmet} from "react-helmet";
import FaucetImage from '../../assets/icons/Group 811557 (1).svg'
import AvalancheImage from "../../assets/icons/Group318.svg";
import PolygonImage from "../../assets/icons/Group320.svg";

const FaucetPage = () => {
    const [data, setData] = useState([]);
    const navigate = useNavigate();
    useEffect(() => {
        getFaucetList();
    },[]);

    const getFaucetList = () => {
        fetchApi({
            method: "GET",
            url: `faucet/faucet-list`,
            isDevApp: false,
        })
            .then((response) => {
                if (response.docs.length > 0) {
                    setData(response.docs);
                } else {
                    notification.error({
                        message: response,
                        duration: 3,
                    });
                }
            })
            .catch((err) => {
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3,
                });
            });
    }
    const protocolNameContent = (content) => {
       
        const match = content.toLowerCase().split(" ").join("-");
        // console.log(match)
        return match ;
      };

    const navigateToFaucetPage = (protocol) => {
      navigate(`/faucet/${protocol.protocolName.toLowerCase().split(" ").join("-")}`,{state:{data:protocol}});
    }
    const imageStyles = {
        borderRadius: "50%",
        width: "50px",
        height: "50px",
        objectFit: "cover",
    };

    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Accelerate Web3 Development- Krypcore's dApp dev Tools</title>
                <meta name="description" content="Krypcore faucet, Krypcore's faucet,Krypcore's faucet page" />
                <link rel="canonical" href="https://krypcore.com/faucet" />
            </Helmet>
        <div className="faucet-page-container">
            {/* <div className="faucet-heading">
                <h2>
                    Testnet Faucets
                </h2><br/>
            </div> */}
            <div  >
              <h3 className="light-theme-faucet-header" style={{marginTop:'70px'}}>Testnet <span className="light-theme-faucet-header-gradient">Faucets</span></h3><br/>
            </div>
            {/* {data.length > 0 ? data.map((protocol, i) => (
                        <div  className="faucet-card-container">
                            <div key={i} className={protocol.status === "ACTIVE" ? "faucet-card" : "faucet-card disabled-card"} onClick={() => {navigateToFaucetPage(protocol.protocolName,protocol.currencySymbol,protocol.chainID,
                                protocol.description,protocol.faucetID,protocol.maximumWalletBalance)}}>
                                <img
                                    src={protocol.icon}
                                    alt="Profile"
                                    style={imageStyles}
                                />
                                <div className="faucet-card-text">{protocol.status === "ACTIVE"?<div>{protocol.protocolName}</div>:<div style={{color:'black'}}>{protocol.protocolName}</div>}
                                    {protocol.status === "ACTIVE" ?
                                        <div className="faucet-card-description">{protocol.description}</div>
                                        :
                                        <div className="faucet-card-description1" style={{color:'black'}}>Coming Soon</div>
                                    }
                                </div>
                                <RightOutlined className={protocol.status === "ACTIVE" ? "faucets-header_arrow-wrapper" : "hide-arrow"} />
                            </div>
                        </div>

                    )): "No Faucets Found"} */}
                <div className="faucet-section">
                    <div>
                      <img src={FaucetImage} className="light-theme-faucet-image" />
                    </div> 
                    <div className="light-theme-faucet-image-container-main">
                    {data.length > 0 ? data.map((protocol, i) => (
                        protocol.status == "ACTIVE"&&(
                          
                            <div className="light-theme-faucet-image-container" 
                               onClick={() => {navigateToFaucetPage(protocol)}}
                            >
                              <img src={protocol.icon} className="light-theme-faucet-image1"  />
                              <span className="light-theme-faucet-image-container-text">{protocol.protocolName}</span>
                         </div>
                      )
                      
                     
                      )): "No Faucets Found"}
                     {/* <div className="light-theme-faucet-image-container-2"> 
                        <img src={PolygonImage} className="light-theme-faucet-image1" />
                        <span className="light-theme-faucet-image-container-text" style={{color:'black'}}>Polygon Mumbai</span>
                     </div> */}
                    </div>

                </div>
                <div className="faucet-page-container1">
                <div  >
                   <h3 className="light-theme-faucet-header" style={{marginTop:'70px'}}>What are<br/> Testnet <span className="light-theme-faucet-header-gradient">Faucets? </span></h3><br/>
               </div>
               <div className="light-theme-faucet-para-container">
                <p className="light-theme-faucet-para">
                  Testnet faucets provide users with free cryptocurrency tokens for testing purposes on a blockchain's test network.<br className="light-theme-hiddenbreaktag" />  These faucets are essential for developers interested in experimenting with blockchain applications without using<br className="light-theme-hiddenbreaktag" />  real cryptocurrency that holds real value.
                </p>
                <p className="light-theme-faucet-para">
                   When developers are building decentralized applications or testing smart contracts, they often need testnet tokens <br className="light-theme-hiddenbreaktag" />  to interact with the blockchain without incurring any costs. Our testnet faucets allow users to easily acquire these <br className="light-theme-hiddenbreaktag" />  tokens by providing their testnet wallet address, and in return, the faucet dispenses a small amount of testnet <br className="light-theme-hiddenbreaktag" />  cryptocurrency. This enables developers to conduct various tests, simulate transactions, and ensure the <br className="light-theme-hiddenbreaktag" />  functionality of their applications in a risk-free environment before deploying them on the mainnet.
                </p>
               </div>
               </div>
        </div>
            </>
    );

}
export default FaucetPage
/* eslint-disable import/no-unresolved */
import React, { Suspense, lazy, useEffect, useState } from "react";
import { notification } from "antd";
import { Routes, Route, useNavigate, useLocation, Navigate, useParams } from "react-router-dom";
import PrivateRouter from "./component/router/privateRouter";
import {
  LoginPage,
  LandingPage,
  UserLockScreen,
  EnterpriseSignUp,
  EnterpriseSignUpForm,
  CreateProject,
} from "./component/pages";
import { EnterpriseDevLogin } from "./component/pages/enterpriseSignUp/enterpriseDevLogin";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import "./assets/css/style.less";
import "./assets/css/login.less";
import "./App.less";
import Loading from "./auth/components/Loading";
import {
  getEnterpriseId,
  getRole,
  getTempRecord,
  setAuthToken,
  setUserDetails,
  setPATToken, setAuthUserEmail, ENTERPRISEDEVELOPERROLE, ENTERPRISEADMINROLE, setProfileImage,
} from "./component/deps";
import fetchApi from "./component/_config/api";
import ReactGA from "react-ga";
import { useAuth0 } from "@auth0/auth0-react";
import { deepCopy, DEVELOPERROLE,removeAllDataInLocal, setActivateEmail } from "./component/deps";
import { ContactSupportForm } from "./component/pages/contactSupport/PlanDetails1/ContactSupportForm";
import { PlanDetailsForm } from "./component/pages/PlanDetails/planDetailsForm";
import { StaticPage } from "./component/pages/StaticPage/staticPage";
import { EnterprisePage } from "./component/pages/enterprisePage";
import { TermsandConditions } from "./component/pages/terms&condition_page";
import { Policy } from "./component/pages/policy_page";

import { FaucetTransactionPage } from "./component/pages/faucet/FaucetTransaction";

import { ExperiencePage } from "./component/pages/ExperiencesPage";
import { PhotoNFTPage } from "./component/pages/PhotoNFT";
import {ThankyouPage} from "./component/pages/Thankyoupage/PlanDetails1/thankyou";
import TagManager from 'react-gtm-module'
import BlogData from "./component/pages/blogUI/BlogData";
import BlogPage from "./component/pages/blogUI";
import {KrypCoreSensor} from "./component/pages/krypcoreSensor";
import MaintenancePage from "./component/pages/maintenance/maintenance";
import Loader from "./component/pages/advanced/chatLoader";


import NotFoundPage from "./component/pages/404/404page";
import NewLandingPage from "./component/pages/new-landing-page/NewLandingPage";
import NewPricingPage from "./component/pages/new-landing-page/NewPricingPage";
import ContactUs from "./component/pages/new-landing-page/ContactUs";
import PrivacyPolicy from "./component/pages/new-landing-page/PrivacyPolicy";
import NewTerms from "./component/pages/new-landing-page/NewTerms";
import { Helmet } from "react-helmet";
import FaucetLandingPage from "./component/pages/faucet/FaucetLandingPage";
import FaucetTransactionLandingPage from "./component/pages/faucet/FaucetTrnasactonLandingPage";
import New_LandingPage from "./component/pages/new-light-theme/New_LandingPage";
import NewPrivateRouter from "./component/pages/newTemplate/Index";
import Router from "./component/pages/newTemplate/Router";
// import PreviewImg from '../public/krypcore_logo.png'





const tagManagerArgs = {
  gtmId: 'GTM-5MZHH9BV'
}

TagManager.initialize(tagManagerArgs)
 const TRACKING_ID = process.env.REACT_APP_GA_TRACKING_ID;
 const Public_Url = process.env.REACT_APP_PUBLIC_URL;
 ReactGA.initialize(TRACKING_ID);
notification.config({ duration: 2 });
const App = () => {
  const {
    isAuthenticated,
    isLoading,
    user,
    loginWithRedirect,
    getAccessTokenSilently,
    logout
  } = useAuth0();
  let auth0Token = "";
  const [query, setQuery] = useState(false);
  const search = useLocation().search;
  const navigate = useNavigate();
  const pathname1=window.location.pathname
  const segments = pathname1.split('/').filter(Boolean); // filter(Boolean) removes empty strings
 
  const setAuth0TokenInLocalStrage = async () => {
    /*if(user !== undefined){
      setUserDetails(user);
    }*/
    // console.log("app-js-user::", JSON.stringify(user));
    console.log("user response----------->",user)
    console.log("setAuth0TokenInLocalStrage", new Date().toISOString())
    if (user && user.email_verified === false) {
      removeAllDataInLocal();
      // navigate("/activate");
      setAuthUserEmail(user?.email);
      setActivateEmail(user?.sub);
      logout({ returnTo: window.location.origin + "/activate" });
      return false;
    }
    auth0Token = await getAccessTokenSilently();
    setAuthToken(auth0Token);
    setProfileImage(user.picture);
    // console.log("user---->",getRole())
    if (!query){
      if (getRole() === ENTERPRISEDEVELOPERROLE || getRole() === ENTERPRISEADMINROLE) {
        getEntDevDetailsFromAPI();
      } else {
        getUserDetailsFromAPI();
      }
    }
  };

  const getPATTokenFromAPI = () => {
    const filter = {
      and: [
        {
          key: "",
          value: false,
          opt: "",
        },
      ],
    };

    const Options = () => {
      return {
        select: "",
        sort: "",
        skip: 0,
        limit: 100,
      };
    };

    let requestLink = `client-credential/getAll/?options=${JSON.stringify(
      Options()
    )}&filter=${JSON.stringify(filter)}`;
    console.log("getPATTokenFromAPI", new Date().toISOString())

    fetchApi({
      method: "GET",
      url: requestLink,
      isDevApp: false,
    })
      .then((response) => {
        const { data = [] } = response;
        if (data.length > 0) {
          fetchToken([data[0].appName]);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const fetchToken = (appName) => {
    return fetchApi({
      method: "GET",
      url: `auth/getToken?appName=${appName}`,
      isDevApp: false,
    }).then((body) => {
      // console.log("response",body)
      if (body.statusCode === 200 || body.statusCode === 201) {
        if (body.doc != null) {
          setPATToken({ patToken: body.doc.token });
        }
      }

    });
  }
  
  useEffect(()=>{
    if(window.location.pathname=="/login"){
      onNavigateDeveloper()
    }
  },[])

  // const location=useLocation()
  // console.log(location)
  

  const onNavigateDeveloper = () => {
    loginWithRedirect({
      appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
      userRole: DEVELOPERROLE,
      userType: "INDIVIDUAL",
    });

  };

  const getUserDetailsFromAPI = () => {
    let userObj = {
      email: user?.email,
      givenName: user?.given_name,
      familyName: user?.family_name,
      imageUrl: user?.picture,
    };
    const accessBody = {
      profileObj: userObj,
      role: getRole() !== undefined && getRole() !== null ? getRole() : "",
      enterpriseId:
        getEnterpriseId() !== undefined && getEnterpriseId() !== null
          ? getEnterpriseId()
          : "",
      tokenObj: auth0Token,
      projectId: getTempRecord()?.projectId,
    };
    console.log("getUserDetailsFromAPI::");
    fetchApi({
      method: "POST",
      url: `auth/authorize`,
      isDevApp: false,
      data: { projectId: getTempRecord()?.projectId },
    })
      .then((response) => {
        if (response.message !== undefined && response.statusCode === 400) {
          removeAllDataInLocal();

        } else if (Object.keys(response).length > 0) {
          if (response.userLock) {
            notification.error({
              message: "User Locked.",
              duration: 3,
            });
            removeAllDataInLocal();
            logout({
              returnTo: window.location.origin,
            });
          } else {
            // setAuthToken(response?.token);
            setUserDetails({ profile: response });
            setQuery(true);
            // navigate("/dashboard");
            getPATTokenFromAPI();
          }
        } else {
          removeAllDataInLocal();
          logout({
            returnTo: window.location.origin,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };

  const getEntDevDetailsFromAPI = () => {
    const encryptdata = new URLSearchParams(search).get("data");
    console.log(encryptdata);
    fetchApi({
      method: "POST",
      url: `enterprise-developer/authorize`,
      isDevApp: false,
      // data: { encryptedData: encryptdata },
    })
      .then((response) => {
        if (response.message !== undefined && response.statusCode === 400) {
          removeAllDataInLocal();
          logout({
            returnTo: window.location.origin + "/waitlist"
          });

        } else if (response.message !== undefined && response.statusCode === 401) {
          removeAllDataInLocal();
          notification.error({
            message: response.message,
            duration: 5,
          });
          logout({
            returnTo: window.location.origin,
          });
        } else if (Object.keys(response).length > 0) {
          setUserDetails({ profile: response });
          setQuery(true);
          getPATTokenFromAPI();
        } else {
          removeAllDataInLocal();
          logout({
            returnTo: window.location.origin,
          });
        }
      })
      .catch((err) => {
        // console.log(err);
      });
  };


  useEffect(() => {
    if (isAuthenticated === true) {
      console.log("isAuthenticated", new Date().toISOString())
      console.log("isAuthenticated-val::", isAuthenticated);
      console.log("isAuthenticated-typeof::", typeof isAuthenticated);
      setAuth0TokenInLocalStrage();
    }
  }, [isAuthenticated]);


  if (isLoading) {
    return (
      <div>
        <Loading />
      </div>
    );
  } else {
    if (!isAuthenticated) {
      return (<>
      <Helmet>
          {window.location.href !== `${window.location.origin}/blog/${segments[1]}`? <meta property="og:image" itemprop="image" content={`${Public_Url}/krypcore_logo.png`}/>:null}
      </Helmet>
  
        <Routes>
        {/*<Route path="/new-dashboard" element={<NewPrivateRouter/>}/>*/}
          <Route path="/waitlist" element={<StaticPage />} />
          <Route path="/activate" element={<StaticPage />} />
          <Route path="/create" element={<CreateProject />} />
          <Route path="/enterpriseLogin" element={<EnterpriseSignUp />} />
          <Route path="/contact-us" element={<ContactUs />} />
          <Route path="/pricing" element={<NewPricingPage />} />
          <Route path="/create-enterprise" element={<EnterpriseSignUpForm />} />
          <Route path="/enterprise-dev-login" element={<EnterpriseDevLogin />} />
          <Route path="/"  element={<New_LandingPage/>}/>
          <Route path="/blog" element={<BlogPage />} />
          <Route path="/blog/:id" element={<BlogData />} />
          {/*<Route path="/pricinglist" element={<NewPricingPage/>}/>*/}
          <Route path="/thankyou" element={<ThankyouPage />} />
          <Route path="/maintenance" element={<MaintenancePage />} />
          <Route path="/krypcoreSensor" element={<KrypCoreSensor />} />
          <Route path="/experiences" element={<ExperiencePage />} />
          <Route path="/photoNFT" element={<PhotoNFTPage />} />
          <Route path="/enterprise" element={<EnterprisePage />} />
          <Route path="/terms" element={<NewTerms />} />
          <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
          {/*<Route path="/*" element={<Navigate to='/' />} />*/}
          <Route path="/*" element={<NotFoundPage />} />

          <Route path="/faucet" element={<FaucetLandingPage/>} />
          <Route path="/faucet/:protocolName" element={<FaucetTransactionLandingPage />} />
          <Route path="/login" element={<Loader/>} />
          {/* <Route path='/new-landing-page' element={<New_LandingPage/>}/> */}
          {/*<Route path="/*" element={<NotFoundPage />} />*/}
          {/*<Route path="/new-landing-page" element={<NewLandingPage />} />*/}
          
        </Routes>
  
     </> );
    } else {
      if (query) {
        return (<>
        <Helmet>
           {window.location.href !== `${window.location.origin}/blog/${segments[1]}`? <meta property="og:image" itemprop="image" content={`${Public_Url}/krypcore_logo.png`}/>:null}
        </Helmet>
          <Routes>
            {/* <Route path="/login" element={<LoginPage />} /> */}
            <Route path="/create" element={<CreateProject />} />
            <Route path="/userlock" element={<UserLockScreen />} />
            <Route path="/"  element={<NewLandingPage />}/>
            {/*<Route path="/*" element={<PrivateRouter />} />*/}
            <Route path="/*" element={<NewPrivateRouter/>}/>

            <Route path="/contact-us" element={<ContactUs/>} />
            <Route path="/privacy-policy" element={<PrivacyPolicy/>} />
            <Route path="/terms" element={<NewTerms />} />
            <Route path="/faucet" element={<FaucetLandingPage />} />
            <Route path="/enterpriseLogin" element={<EnterpriseSignUp />} />
            <Route path="/create-enterprise" element={<EnterpriseSignUpForm />} />
              <Route path="/faucet/:protocolName" element={<FaucetTransactionLandingPage />} />
            <Route path="/enterprise" element={<EnterprisePage />} />
            <Route path="/pricing" element={<NewPricingPage />} />
            <Route path="/blog" element={<BlogPage />} />
            <Route path="/blog/:id" element={<BlogData />} />
            <Route path="/login" element={<Loader/>} />
            {/* <Route path='/new-landing-page' element={<New_LandingPage/>}/> */}
            {/*<Route path="/pricinglist" element={<NewPricingPage/>}/>*/}
            {/*<Route path="/new-landing-page" element={<NewLandingPage />} />*/}

            {/*  <Route path="/*" element={<NotFoundPage />} />*/}
          </Routes>

          </> );
      } else {
        return (
          <div>

              <Loading />
 
          </div>
        );
      }
    }
  }
  
};

export default App;
import React, { useState } from 'react';

const Paragraph = ({ frontContent,frontContent1, backContent ,gradient}) => {
  const [isFlipped, setIsFlipped] = useState(false);

  const handleMouseEnter = () => {
    setIsFlipped(true);
  };

  const handleMouseLeave = () => {
    setIsFlipped(false);
  };

  return (
    <div
      className={`paragraph ${isFlipped ? 'flipped ' : ''}`}
      onMouseEnter={handleMouseEnter}
      onMouseLeave={handleMouseLeave}
    >
      <div className="front">
             <img src={frontContent} className='BlockchainComplexities-icon'/>
              <span className='BlockchainComplexities-icon-text' style={{marginTop:`${frontContent1.length<8?'12px':'0px'}`,marginRight:`${frontContent1.length<8?'25px':'0px'}`}} >{frontContent1}</span>
      </div>
      
      <div className={gradient}>
        <div className='back1'>{backContent}</div>
      </div>        
      
      
    </div>
  );
};

export default Paragraph;
import React, {useRef, useState} from "react";
import './NewLandingPage.less'

export const PolicyPage = () => {

  return (
    <>
        <div className="policy_container">
                  <div className="policy-heading">Krypcore Web3 Privacy Policy </div>

            <div className="policy-content">
                <p className="policy-content-paragraph">
                    We at KrypC Corp, including our affiliated entities, group companies or permitted assigns, (“KrypC”, “Company” or “we”) recognize that your privacy is very important and it is our endeavour to protect the data and privacy of every user (“User” or “You”) in compliance with applicable laws and as per the details outlined in this privacy policy (“Privacy Policy”). Privacy Policy describes Company’s policies and procedures on the collection, use, processing and disclosure of your information pursuant to your use of our website (www.krypc.com), desktop or mobile application, any software application, Application Programming Interface (‘API’), software development kit, Web3 core and related applications (collectively, the “Platform”) or receiving any services from us through the Platform (“Services”). This Privacy Policy is part of and is to be read along with the Terms of Service (‘TOS’) and other materials as available on our Platform.
                </p>
                <p className="policy-content-paragraph">
                    We advise you to read this Privacy Policy and TOS before using our Platform, using any of our services or submitting any personal data. By using the Platform, it shall be deemed that you are expressly consenting to be bound by the terms and conditions described in this Privacy Policy and TOS.
                </p>
                <p className="policy-content-paragraph">
                    Please note that our services offered through the Platform are not intended for minors below the age of 18 years and we do not knowingly collect data relating to minors.
                </p>
                <h5 className="policy-content-heading">
                    Introduction
                </h5>
                <p className="policy-content-paragraph">
                    We reserve our right to change and update this Privacy Policy from time to time, therefore, you should regularly check for any changes in our Privacy Policy. We will notify all users of any material changes, to the extent practical, to this Privacy Policy and the Privacy Policy document will also signify the date of Last update for user’s convenience. Your continued access to or use of our Platform and services after the date of the update in Privacy Policy constitutes your acknowledgment and agreement that you have read, understood and accepted the terms of the updated Privacy Policy. If you do not agree to the updated Privacy Policy, you must stop accessing or using our Platform and/or our Services.
                </p>
                <h5 className="policy-content-heading">
                    What data we collect from you
                </h5>
                <p className="policy-content-paragraph"> We may collect and process the following data about you:</p>
                <ul className="policy-list-content">
                <li>You may be required to give us sensitive personal data about yourself before, during or after creating an Account, or while making payment of any fee or while corresponding with us through any means of communications. Sensitive personal data that you will voluntarily provide us may include information such as your name, postal address, e-mail address and contact number. </li>
                <li>Details of your activities, all transactions, actions or tasked performed on or through our Platforms including transactions relating to creating, developing, deploying, sending, minting or transferring any smart contract, dApp, token, decentralised digital identity, wallet or NFT from time to time.</li>
                <li>Technical information, including the Internet protocol (IP) address used to connect your device to the Internet, your login information, browser type and version, time zone setting, browser plug-in types and versions, operating system and platform, material or information that you viewed or searched for; page response times, download errors, length of visits to certain pages, page interaction information (such as scrolling, clicks, and mouse-hovers), and methods used to browse away from the page and any phone number used to call our customer service number.</li>
                <li><b>Cookies :</b> Our Platform uses Cookies and tracking technology in order to distinguish you from other Users of our Platform and enables us to serve you better and provide you a customised and personalised user experience. Cookie and tracking information collected by Us may be shared with third-parties which directly help us in development or improvement of our Platform.</li>
                </ul>
                <h5 className="policy-content-heading">
                    Use of your data
                </h5>
                <p className="policy-content-paragraph">The data that we collect from or about you may be used and/or processed :</p>
                <ul className="policy-list-content">
                    <li>to verify, maintain, update and retain your personal details and details relating to your identity, address, financial information and other aspects as may be required as per our Company’s policy or to comply with applicable laws and regulations.</li>
                    <li>to facilitate and provide the services offered through our Platform.</li>
                    <li>to inform you of our products, services, promotions, offers, surveys and events which may be of interest to you.</li>
                    <li>to carry out update, upgrade, maintenance, customization and other changes in our products and services to serve you better.</li>
                    <li>to provide you with the information and Services, that you request from us.</li>
                    <li>to enhance the security and user experience of our Platform.</li>
                    <li>To track and remember information and details relating to the transactions executed by You on or through our Platform.</li>
                    <li>to send you Service related notices or emails and other non-essential promotional material (you will be provided an option to unsubscribe to such email or other communications).</li>
                </ul>
                <h5 className="policy-content-heading">
                    Disclosure and sharing of your data
                </h5>
                <p  className="policy-content-paragraph">1. We reserve the right to share any data that we collect from you, as prescribed above with our Affiliates. Your personal data may be processed and stored in a foreign country or countries. In this regard, we will take such necessary measures to ensure that such overseas recipients are bound by legally enforceable obligations to ensure that they will provide a standard of protection that is at least comparable to the protection to your personal data under the applicable law of our jurisdiction of incorporation. Under those circumstances, the governments, courts, law enforcement, or regulatory agencies of that country or those countries may be able to obtain access to your personal data under relevant foreign laws. You acknowledge that the privacy standards of those countries may be lower than those followed in your country of residence.</p>
                <p  className="policy-content-paragraph">2. We further reserve the right to share your personal data to any third party when we are under obligation to share such information under applicable laws or under the order of any competent authority/court.</p>
                <h5 className="policy-content-heading">
                    Your Consent
                </h5>
                <p className="policy-content-paragraph">By using any of the Platform, you agree and consent to the collection and use of data collected to the extent and in the manner described above and in accordance with this Privacy Policy. You also agree and consent to any changes made to this Privacy Policy in due course and without notice. If you do not agree with the terms and conditions of this Privacy Policy, please do not provide any information to us. If you refuse or withdraw your consent, or if you choose not to provide us with any information as required by us, we may not be able to provide you access to our Platform or extend our Services that are being offered to you through the Platform.</p>
                <p className="policy-content-paragraph">You also agree that all your data and/or information shared with us is accurate, correct and complete, at all times. You understand that you must promptly inform us of any changes to your data and/or information which may impact your use of our Platform. </p>
                <h5 className="policy-content-heading">
                    Assignment
                </h5>
                <p className="policy-content-paragraph"> We may assign, novate, or transfer any or all rights and obligations that accrued in our favour under this Privacy Policy, at our sole discretion, to our subsidiaries, affiliated entities or Selected Third Parties to offer you a full suite of services through the Platform. </p>
                <h5 className="policy-content-heading">
                    Data Security
                </h5>
                <p className="policy-content-paragraph">1. Your personal data is accessible only by our authorized employees and Selected Third Parties, and is otherwise kept secure.</p>
                <p className="policy-content-paragraph">2. We have in place appropriate technical and security measures to prevent unlawful access to or accidental loss of information collected including those prescribed under applicable laws. We have taken the following steps to ensure data security :
                    <ul className="policy-list-content">
                        <li>All the user information can only be accessed by authorized employees.</li>
                        <li>Users need to authenticate themselves with a username-password combination and</li>
                        <li>We store our data on third party hosting service providers which follow reasonable and robust security standards.</li>
                    </ul>
                </p>
                <p className="policy-content-paragraph">3. Unfortunately, the transmission of information via the internet is not completely secure. Although we will do our best to protect your personal data, we cannot guarantee, give no warranties, and shall not be liable for breach of the security of your data transmitted through the Platform due to malicious attacks, errors, commission or omissions not willfully initiated by us. Any transmission is at your own risk. Once we have received your information, we will use reasonable standards and practices to strict physical, electronic, and procedural safeguards to try to prevent any unauthorised access.</p>
                <h5 className="policy-content-heading">
                    Data Retention
                </h5>
                <p className="policy-content-paragraph">1. We may, at our sole discretion, retain any information and/or data provided by you for as long as your Account is active, and even subsequently as allowed under applicable laws, for resolution of any disputes, for enforcement of any agreements, and/or as necessitated under applicable laws.</p>
                <p className="policy-content-paragraph">2. We make no warranties and shall not be liable regarding non-availability or non-retention of any information and/or data provided by you beyond the deletion of your Account.</p>
                <h5 className="policy-content-heading">
                    Data retention period
                </h5>
                <p className="policy-content-paragraph">
                    We will retain your personal data for a period of 5 years after the end of the relationship between us as a company and you as a user. This period may be further extended in certain cases as per applicable laws.
                </p>
                <h5 className="policy-content-heading">
                    For users from European Economic Area and UK
                </h5>
                <p className="policy-content-paragraph">
                 1. Personal data : As per EU General Data Protection Regulation (GDPR), ‘personal data’ means any information relating to an identified or identifiable natural person (‘data subject’) an identifiable natural person is one who can be identified, directly or indirectly, in particular by reference to an identifier such as a name, an identification number, location data, an online identifier or to one or more factors specific to the physical, physiological, genetic, mental, economic, cultural or social identity of that natural person.
                </p>
                <p className="policy-content-paragraph">2. Special category of personal data : Certain types of sensitive personal data are classified as “special categories” of personal data and are subject to additional protection under the legislation applicable to you. We do NOT collect any special category of personal data. </p>
                <p className="policy-content-paragraph">
                    3. International transfers : We share your personal data with our Affiliates and Selected Third Parties. This will involve transferring your personal data outside the European Economic Area (EEA) or the UK. Many of our external third parties are based outside the EEA or the UK so their processing of your personal data will involve a transfer of data outside the EEA or the UK. Whenever we transfer your personal data out of the EEA or the UK, we ensure a similar degree of protection is afforded to it by ensuring at least one of the following safeguards is implemented:
                    <ul className="policy-list-content">
                        <li>the country to which we transfer your personal data has been deemed to provide an adequate level of protection; or</li>
                        <li>a specific contractual obligation requiring Selected Third Parties to comply with level of data protection standards as per your applicable laws.</li>
                    </ul>
                </p>
                <p className="policy-content-paragraph">4. Lodging a complaint: Should you wish to report a complaint or if you feel that Our Company has not addressed your concern in a satisfactory manner, you may contact the relevant Information Commissioner’s Office in your country.</p>
                <h5 className="policy-content-heading">
                    Your Legal rights
                </h5>
                <p className="policy-content-paragraph">1. The right to access – You have the right to request our Company for copies of your personal data. We may charge you a small fee for this service.</p>
                <p className="policy-content-paragraph">2. The right to rectification – You have the right to request that our Company correct any information you believe is inaccurate. You also have the right to request our Company to complete the information you believe is incomplete.</p>
                <p className="policy-content-paragraph">3. The right to erasure – You have the right to request that our Company erase your personal data, under certain conditions.</p>
                <p className="policy-content-paragraph">4. The right to restrict or object to processing – You have the right to request that our Company restrict or stop the processing of your personal data, under certain conditions.</p>
                <p className="policy-content-paragraph">If you make a request, we have one month to respond to you.</p>
                <h5 className="policy-content-heading">
                    Data protection officer
                </h5>
                <p className="policy-content-paragraph">The data controller responsible for your personal data is KrypC Corp. In case you have any queries, please write to our Data Protection Officer at <a href="mailto:support@krypcore.com">support@krypcore.com</a></p>
            </div>
        </div>
    </>
  );
};

export default PolicyPage;



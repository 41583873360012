import React from "react";

export const Authereum = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M38.1652 11.6084C31.4039 13.772 24.5975 15.9356 22.9974 16.4314L20.1351 17.3554V37.2336C20.1351 49.1335 20.2252 58.1035 20.3604 59.591C21.3521 70.0034 25.8596 79.8749 33.3872 88.0786C35.1001 89.9492 62.2805 113.636 63.0467 113.929C63.3623 114.042 87.2522 93.6679 91.1737 89.9266C97.9801 83.4583 103.299 73.9925 105.192 64.9324C106.184 60.1544 106.251 58.3739 106.184 37.1886L106.116 17.2653L90.8131 12.4422C82.4066 9.80534 75.4875 7.66426 75.4424 7.68681C75.4199 7.73188 76.682 10.2336 78.2822 13.2761L81.1895 18.7979L85.5393 20.1952C87.9283 20.9615 91.5343 22.1109 93.5627 22.7419L97.2363 23.8914L97.1462 42.1919C97.0786 59.7713 97.056 60.5826 96.6053 62.6336C94.8924 70.364 91.354 76.9224 85.9224 82.4667C84.8857 83.5259 79.6119 88.1912 74.2254 92.8115C68.8389 97.4542 64.1511 101.488 63.813 101.804L63.227 102.345L52.8823 93.4425C40.2386 82.5343 38.3455 80.6862 35.3254 76.0435C33.0041 72.46 31.2912 68.606 30.2545 64.6169C29.2403 60.5601 29.1501 59.0501 29.1501 40.9749V23.9139L32.373 22.8997C34.1309 22.3363 37.737 21.1868 40.3739 20.353C42.9882 19.5191 45.1518 18.7528 45.1518 18.6852C45.1518 18.6176 46.414 16.1384 47.9691 13.186C49.5242 10.211 50.7863 7.75442 50.7863 7.70934C50.7863 7.57411 50.5158 7.66426 38.1652 11.6084Z"
      fill="#FF4C2F"
    />
    <path
      d="M62.5959 8.36309C62.3931 8.76877 56.7136 19.6094 49.9974 32.4333L37.782 55.7597L37.9623 57.6529C38.368 61.5294 39.8104 65.6312 42.1543 69.3499C42.9882 70.7022 43.2361 70.9501 43.4615 70.7022C43.5967 70.5444 48.0591 62.1154 53.378 51.9734C58.6744 41.854 63.0692 33.5602 63.1594 33.5602C63.227 33.5602 67.6894 41.7639 73.0534 51.7706C78.4173 61.7773 82.9023 70.1388 83.0376 70.3641C83.2629 70.7473 83.308 70.7247 83.6686 70.2515C85.99 67.2089 87.9057 62.183 88.4466 57.7431L88.717 55.4893L75.9157 31.6219C68.8839 18.505 63.0917 7.7095 63.0467 7.68697C63.0241 7.64189 62.8213 7.93488 62.5959 8.36309Z"
      fill="#FF4C2F"
    />
    <path
      d="M56.8714 65.7666L50.6735 78.1398L54.1669 81.1824C56.0826 82.8502 58.8998 85.2617 60.4098 86.5238L63.1819 88.8226L63.7904 88.3268C64.151 88.0564 66.9232 85.6448 69.9657 82.9628L75.5325 78.0947L69.3572 65.7441C65.954 58.9603 63.1594 53.3935 63.1143 53.3935C63.0918 53.3935 60.2746 58.9603 56.8714 65.7666Z"
      fill="#FF4C2F"
    />
  </svg>
);

export default Authereum;

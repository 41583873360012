import React from "react";

export const Ethereum = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_903_3196)">
      <g clipPath="url(#clip1_903_3196)">
        <path
          d="M61.5404 0C95.4554 0 122.946 27.4904 122.946 61.4054C122.946 95.3204 95.4554 122.811 61.5404 122.811C27.6254 122.811 0.13501 95.3204 0.13501 61.4054C0.13501 27.4904 27.6254 0 61.5404 0Z"
          fill="white"
        />
        <path
          d="M63.2694 19.1892L62.7169 21.0215V74.3243L63.2694 74.8548L88.0004 60.2375L63.2694 19.1892Z"
          fill="#343434"
        />
        <path
          d="M63.2665 19.1892L38.5135 60.2375L63.2665 74.8548V48.9983V19.1892Z"
          fill="#8C8C8C"
        />
        <path
          d="M63.2646 79.5368L62.9561 79.9115V98.9016L63.2646 99.7847L88.0176 64.9196L63.2646 79.5368Z"
          fill="#3C3C3B"
        />
        <path
          d="M63.2666 99.7847V79.5368L38.5135 64.9196L63.2666 99.7847Z"
          fill="#8C8C8C"
        />
        <path
          d="M63.2667 74.8522L87.9976 60.2349L63.2667 48.9957V74.8522Z"
          fill="#141414"
        />
        <path
          d="M38.5135 60.2349L63.2666 74.8522V48.9957L38.5135 60.2349Z"
          fill="#393939"
        />
      </g>
    </g>
  </svg>
);

export default Ethereum;

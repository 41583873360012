// Essentials
import React, { useState } from "react"; // {UserContext}
// Style
import "../styles.less";
import { erc20 } from "@dinesh-kd/wizard";
import { Input, Checkbox, Radio, Form } from "antd"; //Form, Spin, notification
import { fetchApi } from "../../../_config/api";
import {
  getUserDetails,
  getPATTokenDetails,
  onKeyPressInputValidation,
  onKeyPressAddressValidation,
  scstudioinputValidation, 
  onKeyPressERC20InputValidation
} from "../../../deps";
import Skeleton from "react-loading-skeleton";
// import {UserContext} from "../../../context";

const CUSTODIAL_WALLET = "custodial";
const NON_CUSTODIAL_WALLET = "non-custodial";
const DEVELOPER_WALLET = "developer";

export const ERC20 = ({ instance, onChangeEvent,isLoading }) => {
  const gwURL = process.env.REACT_APP_GATEWAY_URL;
  const walletURL = gwURL + "/api/v0/wallet";

  const [data, setData] = React.useState({
    name: "ExampleToken",
    symbol: "ETK",
    info: {
      license: "MIT",
      securityContact: "",
    }
  });
  const [chains, setChains] = React.useState([]);
  const [selected, setSelected] = React.useState('');
  const [selectUpgrade, setSelectUpgrade] = React.useState('');
  const [upgradeCheck, setUpgradeCheck] = React.useState();
  const [accessCheck, setAccessCheck] = React.useState();
  const [accessControlDisplay, setAccessControlDisplay] = React.useState(false);
  const [custodialWallets, setCustodialWallets] = React.useState([]);
  const [walletType, setWalletType] = React.useState("");
  const [ncWalletData, setNCWalletData] = useState([]);
  const {
    profile: { subscriptionId },
  } = getUserDetails();
  const token = getPATTokenDetails();
  const authToken = token.patToken; // subscriptionId + "_" +


  React.useEffect(() => {

    if (instance) {
      // console.log("instance::", instance);
      const filter = {
        and: [
          {
            key: "projectID",
            value: instance?.ProjectId,
            opt: "eq",
          },
          {
            key: "subscriptionId",
            value: subscriptionId,
            opt: "eq",
          },
          {
            key: "infraType",
            value: "blockchainNode",
            opt: "eq",
          },
          {
            key: "status",
            value: "ACTIVE",
            opt: "eq",
          },
        ],
      };

      const Options = () => {
        return {
          select: "",
          sort: "",
          // skip: 0,
          // limit: 10
        };
      };

      let requestLink = `endpoints?options=${JSON.stringify(
        Options()
      )}&filter=${JSON.stringify(filter)}`;

      fetchApi({
        method: "GET",
        // url: "developer/project-config/PROTOCOLS",
        // url: "protocols/getAllProtocols",
        url: requestLink,
        isDevApp: false,
      })
        .then((response) => {
          setChains(response.docs);
        })
        .catch((error) => {
          console.error(error);
        });
    } else {
      // console.log("project / endpoint not exists")
    }

  }, [instance]);

  const onChangeWallet = (event, name) => {
    onChange(event.target.name, event.target.value);
    setWalletType(event.target.value);
    if (event.target.value === NON_CUSTODIAL_WALLET) {
      getAllNCWallets()
    }
  };

  const getAllNCWallets = () => {
    console.log("instance ", instance)
    const { instanceKey, projectDetails: { services: { NonCustodialWallet } } } = instance;
    const options = {
      method: "POST",
      mode: "cors",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken,
        'Instanceid': NonCustodialWallet?.InstanceID
      },
      body: JSON.stringify({
        mode: "api",
        //   serviceKey:instanceKey
      })
    };
    fetch(`${gwURL}/api/v0/ncWallet/getAllWallets`, options)
      .then(response => response.json())
      .then((response) => {
        // showLoader(false);
        if (response.Status === "SUCCESS") {
          if (response.Data) {
            setNCWalletData(response.Data)
          } else {
            setNCWalletData([]);
          }
        }
      })
      .catch((error) => {
        // console.log("err::", error);
        setNCWalletData([]);
        // showLoader(false);
      });
  };

  const onTextChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const onCheckboxChange = (e) => {
    onChange(e.target.name, e.target.checked);
  };

  const onRadioChange = (e) => {
    onChange(e.target.name, e.target.value);
    if (e.target.name === "Access") {
      setSelected(e.target.value)
      setAccessCheck(true)
    }
    if (e.target.name === "Upgradeable") {
      setSelectUpgrade(e.target.value)
      if (e.target.value === "uups") {
        setAccessCheck(true)
        setAccessControlDisplay(true)
        setSelected("ownable")
      }
      setUpgradeCheck(true)
    }
  };

  const onSelectChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  const onChange = (name, value) => {
    setData({
      ...data,
      [name]: value,
    });

    setTimeout(() => {
      // console.log("bef-data", data);
      let obj = {
        name: "ExampleToken",
        symbol: "ETK",
        info: {
          license: "MIT",
          securityContact: "",
        },
      };

      if (name === "custodialWallet") {
        obj.custodialWallet = value;
      } else if (data.custodialWallet) {
        obj.custodialWallet = data.custodialWallet;
      }

      if (name === "name") {
        obj.name = value;
      } else if (data.name) {
        obj.name = data.name;
      }

      if (name === "symbol") {
        obj.symbol = value;
      } else if (data.symbol) {
        obj.symbol = data.symbol;
      }

      if (name === "premint") {
        obj.premint = value;
      } else if (data.premint) {
        obj.premint = data.premint;
      }

      if (name === "apiKey") {
        obj.apiKey = value;
      } else if (data.apiKey) {
        obj.apiKey = data.apiKey;
      }

      //features
      if (name === "Mintable") {
        obj.mintable = value;
        if (value === true) {
          obj.access = "ownable";
          setSelected("ownable")
          setAccessCheck(true)
          setAccessControlDisplay(true)
        } else if (value === false) {
          obj.access = "";
          setAccessCheck(false)
          setAccessControlDisplay(false)
          setSelected('')
        }
      } else if (data.Mintable) {
        obj.mintable = data.Mintable;
      }

      if (name === "Burnable") {
        obj.burnable = value;
      } else if (data.Burnable) {
        obj.burnable = data.Burnable;
      }

      if (name === "Pausable") {
        obj.pausable = value;
        if (value === true) {
          obj.access = "ownable";
          setSelected("ownable")
          setAccessCheck(true)
          setAccessControlDisplay(true)
        } else if (value === false) {
          setAccessCheck(false)
          setAccessControlDisplay(false)
          setSelected('')
        }
      } else if (data.Pausable) {
        obj.pausable = data.Pausable;
      }

      if (name === "Permit") {
        obj.permit = value;
      } else if (data.Permit) {
        obj.permit = data.Permit;
      }

      if (name === "Votes") {
        obj.votes = value;
      } else if (data.Votes) {
        obj.votes = data.Votes;
      }

      if (name === "FlashMinting") {
        obj.flashmint = value;
      } else if (data.FlashMinting) {
        obj.flashmint = data.FlashMinting;
      }

      if (name === "Snapshots") {
        obj.snapshots = value;
        if (value === true) {
          obj.access = "ownable";
          setSelected("ownable")
          setAccessCheck(true)
          setAccessControlDisplay(true)
        } else if (value === false) {
          setAccessCheck(false)
          setAccessControlDisplay(false)
          setSelected('')
        }
      } else if (data.Snapshots) {
        obj.snapshots = data.Snapshots;
      }

      if (name === "UpgradeableCheck") {
        obj.upgradeableCheck = value;
        if (value === true) {
          obj.upgradeable = "transparent";
          setSelectUpgrade("transparent")
          setUpgradeCheck(true)
        } else if (value === false) {
          setSelectUpgrade('')
          setUpgradeCheck(false)
          setAccessControlDisplay(false)
          obj.access = "ownable";
        }
      } else if (data.UpgradeableCheck) {
        obj.upgradeableCheck = data.UpgradeableCheck;
      }

      if (name === "AccessControlCheck") {
        obj.accessCheck = value;
        if (value === true) {
          obj.accessCheck = "ownable";
          setSelected("ownable")
          setAccessCheck(true)
        } else {
          setSelected('')
          setAccessCheck(false)
        }
      } else if (data.AccessControlCheck) {
        obj.accessCheck = data.AccessControlCheck;
      }

      if (name === "Upgradeable") {
        obj.upgradeable = value;
      } else if (data.Upgradeable) {
        obj.upgradeable = data.Upgradeable;
      }

      if (name === "address") {
        obj.owner = value;
      } else if (data.address) {
        obj.owner = data.address;
      }

      if (name === "license") {
        obj.info = {
          license: value,
          securityContact: data?.info?.contact,
        };
      } else if (data?.info?.license) {
        obj.info = {
          license: data?.info?.license,
          securityContact: data?.info?.securityContact,
        };
      }

      if (name === "contact") {
        obj.info = {
          license: data?.info?.license,
          securityContact: value,
        };
      } else if (data?.info?.securityContact) {
        obj.info = {
          license: data?.info?.license,
          securityContact: data?.info?.securityContact,
        };
      }

      if (!obj.upgradeable || obj.upgradeable === "uups") {
        if (name === "Access") {
          obj.access = value;
        } else if (data.access) {
          obj.access = data.access;
        }
      } else {
        obj.access = false;
      }

      // console.log({name, value});
      // console.log("obj", obj);

      onChangeEvent({
        ...data,
        [name]: value,
        contract: (erc20.print(obj)).replace(/^.*@custom:oz-upgrades-unsafe-allow constructor.*$\n/m, "").replace(/^.*_disableInitializers().*$\n/m, ""),
      });

      // if (name === "license" || name === "contact") {
      //   if (name === "license") {
      //     obj.info = {
      //       securityContact: data?.info?.contact,
      //       license: value,
      //     };
      //   }
      //   if (name === "contact") {
      //     obj.info = {
      //       securityContact: value,
      //       license: data?.info?.license,
      //     };
      //   }
      // }

    }, 100);
  };

  return (
    <>
      <div className="subtitle">Platform Setting</div>
      {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
      <select
        className="dropdown-filter create-collection-drop input-container"
        placeholder="Choose Chain for Collection"
        name="chain"
        onChange={onSelectChange}
      >
        <option value="">Choose Chain for Collection</option>
        {chains && chains.length > 0 && chains.map((chain, ind) => (
          <option key={ind} value={chain.chainId}>{chain.protocolName}</option>
        ))}
      </select>
      )}
      <Form.Item name="walletType" rules={[
        {
          required: true,
          message: "Select Wallet Type",
        },
      ]}>
        <>
        {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <select
            className="dropdown-filter create-collection-drop"
            placeholder="Choose Wallet Type"
            name="walletType"
            onChange={(event) => onChangeWallet(event, "walletType")}
          >
            <option value="">Choose Wallet Type</option>
            {/*<option value={CUSTODIAL_WALLET}>Custodial</option>*/}
            <option value={NON_CUSTODIAL_WALLET}>Self-Managed</option>
            <option value={DEVELOPER_WALLET}>Developer-Wallet</option>
          </select>
        )}
        </>
      </Form.Item>
      {/* {walletType === CUSTODIAL_WALLET && (
        <Form.Item name="custodialWallet" rules={[
          {
            required: walletType === CUSTODIAL_WALLET,
            message: "Select Wallet",
          },
        ]} style={{marginTop:"-22px"}}>
          <select
            className="dropdown-filter create-collection-drop"
            placeholder="Choose Wallet"
            name="custodialWallet"
            onChange={onSelectChange}
          >
            <option value="">Choose Wallet</option>
            {custodialWallets.map((wallets, ind) => (
              <option key={ind} value={JSON.stringify(wallets)}>{wallets.walletName}</option>
            ))}
          </select>
        </Form.Item>
      )} */}
      {walletType === NON_CUSTODIAL_WALLET && (
        <Form.Item name="custodialWallet"
          rules={[
            {
              required: walletType === NON_CUSTODIAL_WALLET,
              message: "Select Wallet",
            },
          ]} style={{marginTop:"-22px"}}>
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <select className="dropdown-filter create-collection-drop" placeholder="Choose Wallet" name="custodialWallet"
            onChange={onSelectChange}
          >
            <option value="">Choose Wallet</option>
            {ncWalletData.map((wallet, ind) => (
              <option key={ind} value={wallet.walletId}>{wallet.walletName}</option>
            ))}
          </select>
            )}
        </Form.Item>
      )}
      {walletType === DEVELOPER_WALLET && (
        <Form.Item name="custodialWallet"
          rules={[
            {
              required: walletType === DEVELOPER_WALLET,
              message: "Select Wallet",
            },
          ]} style={{marginTop:"-22px"}}>
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Input
            title={"Private Key"}
            className="input-container"
            placeholder="Private Key"
            name="privateKey"
            onChange={onTextChange}
          />
            )}
        </Form.Item>
      )}

      {/* <select
        className="dropdown-filter create-collection-drop input-container"
        placeholder="Choose Custodial Wallet"
        name="custodialWallet"
        onChange={onSelectChange}
      >
        <option value="">Choose Custodial Wallet</option>
        {custodialWallets && custodialWallets.length > 0 && custodialWallets.map((wallets, ind) => (
          <option key={ind} value={JSON.stringify(wallets)}>{wallets.walletName}</option>
        ))}
      </select>*/}

      <div className="subtitle">Contract Setting</div>
      {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
      <Input
        className="input-container"
        placeholder="Contract Name"
        name="name"
        onChange={onTextChange}
        maxLength={100}
        onKeyPress={scstudioinputValidation}
      />
      )}

{isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(

      <Input
        className="input-container"
        placeholder="Symbol"
        name="symbol"
        onChange={onTextChange}
        maxLength={100}
        onKeyPress={onKeyPressInputValidation}
      />
)}

{isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
      <Input
        className="input-container"
        placeholder="Premint"
        name="premint"
        onChange={onTextChange}
        maxLength={42}
        onKeyPress={onKeyPressAddressValidation}
      />
)}

      <div className="subtitle">Features</div>

      <ul>
        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="Mintable">
            Mintable
          </Checkbox>
        )}
        </li>

        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="Burnable">
            Burnable
          </Checkbox>
        )}
        </li>

        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="Pausable">
            Pausable
          </Checkbox>
        )}
        </li>

        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="Permit">
            Permit
          </Checkbox>
        )}
        </li>

        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="Votes">
            Votes
          </Checkbox>
        )}
        </li>

        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="FlashMinting">
            Flash Minting
          </Checkbox>
        )}
        </li>

        <li>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Checkbox onChange={onCheckboxChange} name="Snapshots">
            Snapshots
          </Checkbox>
        )}
        </li>

        {/* <li>
          <Checkbox onChange={onCheckboxChange} name="Transfer">
            Transfer Ownership
          </Checkbox>
        </li> */}
      </ul>

      <div className="subtitle">
        Access Control
        {/*<Checkbox onChange={onCheckboxChange} checked={accessCheck} name="AccessControlCheck"></Checkbox>*/}
        <span> </span>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <input
          style={{cursor:"pointer"}}
          type="checkbox"
          className="normalCheckbox"
          onClick={onCheckboxChange}
          checked={accessCheck}
          disabled={accessControlDisplay}
          name="AccessControlCheck" />
        )}
      </div>

      <Radio.Group onChange={onRadioChange} value={selected} name="Access">
      {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <Radio value="ownable">Ownable</Radio>
      )}
      {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <Radio value="roles">Roles</Radio>
      )}
      </Radio.Group>

      <div className="subtitle">Upgradeability
        {/*<Checkbox onChange={onCheckboxChange} checked={upgradeCheck} name="UpgradeableCheck"></Checkbox>*/}
        <span> </span>
        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <input  style={{cursor:"pointer"}} type="checkbox" className="normalCheckbox" onClick={onCheckboxChange} checked={upgradeCheck} name="UpgradeableCheck" />
        )}
      </div>


      <Radio.Group onChange={onRadioChange} value={selectUpgrade} name="Upgradeable">
      {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <Radio value="transparent">Transparent</Radio>
      )}
      {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(  
        <Radio value="uups">UUPS</Radio>
      )}
      </Radio.Group>


      <div className="subtitle">Info</div>
      {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
      <Input
        className="input-container"
        placeholder="Contact"
        name="contact"
        onChange={onTextChange}
        maxLength={100}
        onKeyPress={onKeyPressERC20InputValidation}
      />
      )}
      {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
      <Input
        className="input-container"
        placeholder="License"
        name="license"
        defaultValue={"MIT"}
        onChange={onTextChange}
        maxLength={100}
        onKeyPress={onKeyPressInputValidation}
      />
      )}
    </>
  );
};

export default ERC20;

import React from "react";
import { Table } from "./index";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";

export const TableContent = ({
  pagination = true,
  rowKey,
  text,
  columns,
  data,
  isDashboard = false,
  canNavigate = false,
  tooltip,
}) => {
  return (
    <>
      <div className="container-text" style={{marginBottom: "16px"}}>
        <div className="text">{text}</div>
        {(text || tooltip) && (
          <Tooltip title={tooltip ? tooltip : text}>
            <InfoCircleOutlined
              style={{ fontSize: "15px", color: "#D9D9D9" }}
            />
          </Tooltip>
        )}
      </div>
      {isDashboard ? (
        <div className="dashboard-container-service">
          <button className="dashboard-services">
            <span>Projects</span>
          </button>
          <button className="dashboard-services-border">
            <span>DID Instance</span>
          </button>
          <button className="dashboard-services-border">
            <span>ERC - 20 Tokens</span>
          </button>
          <button className="dashboard-services-border">
            <span>NFT Projects</span>
          </button>
          <button className="dashboard-services-border">
            <span>Private Chains</span>
          </button>
        </div>
      ) : null}
      <div className="table-container">
        {!pagination ? (

          <Table
            pagination={pagination}
            //   pagination={{ pageSize: 5 }}
            rowKey={rowKey}
            columns={columns}
            canNavigate={canNavigate}
            data={data}
          />
        ) : <Table
          pagination={{ pageSize: 5 }}
          rowKey={rowKey}
          columns={columns}
          canNavigate={canNavigate}
          data={data}
        />}

      </div>
    </>
  );
};

import React from "react";

export const Discord = ({ width = "24px", height = "24px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 35 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_discord)">
      <path
        d="M28.5911 2.12868C32.3127 7.60054 34.1507 13.7727 33.4636 20.8782C33.4607 20.9082 33.4451 20.9358 33.4204 20.954C30.6021 23.0234 27.8715 24.2793 25.1792 25.1122C25.1582 25.1186 25.1358 25.1182 25.1151 25.1112C25.0943 25.1042 25.0763 25.0909 25.0635 25.0731C24.4415 24.2077 23.8763 23.2953 23.3812 22.3372C23.3528 22.2807 23.3787 22.2128 23.4372 22.1905C24.3348 21.8523 25.1883 21.447 26.0092 20.9671C26.0739 20.9292 26.078 20.8366 26.0183 20.7921C25.844 20.6627 25.6714 20.5268 25.5063 20.3909C25.4754 20.3657 25.4338 20.3608 25.3988 20.3777C20.069 22.8389 14.2305 22.8389 8.83777 20.3777C8.80276 20.362 8.76115 20.3674 8.73108 20.3921C8.56632 20.528 8.39331 20.6627 8.22072 20.7921C8.161 20.8366 8.16594 20.9292 8.23102 20.9671C9.05196 21.438 9.90545 21.8523 10.8018 22.1922C10.8599 22.2144 10.8875 22.2807 10.8586 22.3372C10.3742 23.2965 9.80906 24.2089 9.17554 25.0743C9.14794 25.1093 9.10263 25.1254 9.05979 25.1122C6.38029 24.2793 3.64972 23.0234 0.831404 20.954C0.807925 20.9358 0.791036 20.907 0.788565 20.8769C0.214357 14.7308 1.3846 8.50757 5.65656 2.12744C5.66686 2.11055 5.68251 2.09737 5.70063 2.08955C7.80263 1.12484 10.0546 0.415116 12.4082 0.00979307C12.4511 0.00320245 12.4939 0.0229743 12.5162 0.0608704C12.807 0.575763 13.1394 1.23606 13.3643 1.77567C15.8452 1.39671 18.3649 1.39671 20.8978 1.77567C21.1227 1.24759 21.4436 0.575763 21.7331 0.0608704C21.7435 0.0420705 21.7594 0.0270091 21.7788 0.0178364C21.7982 0.00866369 21.82 0.00584861 21.8411 0.00979307C24.196 0.416352 26.4479 1.12608 28.5482 2.08955C28.5668 2.09737 28.582 2.11055 28.5911 2.12868ZM14.6256 13.8147C14.6515 11.9977 13.3268 10.4942 11.6639 10.4942C10.0146 10.4942 8.70266 11.9845 8.70266 13.8147C8.70266 15.6444 10.0406 17.1347 11.6639 17.1347C13.3136 17.1347 14.6256 15.6444 14.6256 13.8147ZM25.5751 13.8147C25.601 11.9977 24.2763 10.4942 22.6138 10.4942C20.9641 10.4942 19.6522 11.9845 19.6522 13.8147C19.6522 15.6444 20.99 17.1347 22.6138 17.1347C24.2763 17.1347 25.5751 15.6444 25.5751 13.8147Z"
        fill="#5865F2"
      />
    </g>
    <defs>
      <clipPath id="clip_discord">
        <rect width="34.3676" height="25.1296" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

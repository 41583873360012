import React, {useState} from "react";
import { useNavigate } from "react-router-dom";
import "./style.less";
import { useAuth0 } from "@auth0/auth0-react";
import { DEVELOPERROLE, ENTERPRISEDEVELOPERROLE } from "../../deps";
import { useLocation } from "react-router-dom";
import fetchApi from "../../../component/_config/api";
import { notification } from "antd";
import {GetEnterpriseIdPopup} from "./popup";
notification.config({ duration: 2 });

export const SignUpCard = ({
  imageSrc,
  title,
  isEnterprise = false,
  isEntDeveloper = false,
}) => {
  const search = useLocation().search;
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [devEnterprise, setDevEnterprise] = useState(false);

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const activateToken = new URLSearchParams(search).get("activateToken");

  const navigate = useNavigate();
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();


  const OnNavigate = () => {
    if (isEnterprise) {
      if (isEntDeveloper && activateToken !== null) {
        // console.log("in ent dev");
        fetchApi({
          method: "GET",
          url: `enterprise-developer/validate/${activateToken}`,
          isDevApp: false,
        })
          .then((response) => {
            if (response.enterpriseId !== "") {
              loginWithRedirect({
                appState: {
                  role: ENTERPRISEDEVELOPERROLE,
                  type: "ENTERPRISE",
                  enterpriseId: response.enterpriseId,
                },
                userRole: ENTERPRISEDEVELOPERROLE,
                userType: "ENTERPRISE",
                enterpriseId: response.enterpriseId,
              });
            } else {
              notification.error({
                message: response,
                duration: 3,
              });
            }
          })
          .catch((err) => {
            notification.error({
              message: "Something went wrong. Please try again",
              duration: 3,
            });
          });
      } else {
        setIsModalOpen(true);
        setDevEnterprise(isEntDeveloper);
        // console.log("in ent");
        // navigate("/create-enterprise");
      }
    } else {
      // console.log("in dev");
      setIsModalOpen(true);
      setDevEnterprise(isEntDeveloper)
    }
  };

  return (
    <>
      <div className="enterprise-signup-card" onClick={OnNavigate}>
        <div className="enterprise-signup-icon">
          <img src={imageSrc} alt="" />
        </div>
        <h4>{title}</h4>
      </div>
      <GetEnterpriseIdPopup
          isModalOpen={isModalOpen}
          handleCancel={handleCancel}
          devEnterprise={devEnterprise}
      />
    </>
  );
};

import React, { useState } from "react";
import {CopyOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Tooltip, notification} from "antd";
import {Link} from "react-router-dom";

export const Carousel = ({ items,infraType }) => {
    const [index, setIndex] = useState(0);
    // console.log("items--->",items)
    const onNext = () => {
        if (index + 3 < items.length) {
            setIndex(index + 1);
        }
    };
    const onPrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const onCopy = (url) => {
        navigator.clipboard.writeText(url);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };

    return (
        // <>
        //     {items  ? (
        //
        //         <div className="endpoint-column-container">
        //             <div className="endpoint-col">
        //                 <div className="endpoint-text">Endpoint Name</div>
        //                 <div className="endpoint-sub-text">{items?.EndpointName}</div>
        //             </div>
        //             <div className="endpoint-col">
        //                 <div className="endpoint-text">Protocol Name</div>
        //                 <div className="endpoint-sub-text">{items?.Protocol}</div>
        //             </div>
        //             {items?.IPFSGateway_URL !== undefined ? (<>
        //                 <div className="endpoint-col-url">
        //                     <div className="endpoint-text">GateWay URL</div>
        //                     <div className="endpoint-sub-text">{items?.IPFSGateway_URL}</div>
        //                 </div>
        //                 <div className="endpoint-copy-col">
        //                     <CopyOutlined onClick={() => onCopy(items?.IPFSGateway_URL)} />
        //                 </div></>):""}
        //             <div className="endpoint-col-url">
        //                 <div className="endpoint-text">User Endpoint</div>
        //                 <Tooltip title={items?.RPC_URL}><div className="endpoint-sub-text">{items?.RPC_URL}</div></Tooltip>
        //             </div>
        //             <div className="endpoint-copy-col">
        //                 <CopyOutlined onClick={() => onCopy(items?.RPC_URL)} />
        //             </div>
        //         </div>
        //     ) : <div className="service-not-available-label">
        //         Endpoint not selected!
        //     </div>}
        // </>
        <table className="dapp-endpoints-table table-bordered">
            <thead>
            <tr>
                <th className="dapp-endpoint-header">Sl.No</th>
                <th className="dapp-endpoint-header">Name</th>
                <th className="dapp-endpoint-header">Chain Id</th>
                <th className="dapp-endpoint-header">Endpoint Id</th>
                <th className="dapp-endpoint-header">Manage</th>
            </tr>
            </thead>
            <tbody>
            {items && items?.ID !== "" ? <tr>
                <td className="dapp-endpoint-text">{1}</td>
                {/* Serial number */}
                <td className="dapp-endpoint-text">{items?.Protocol}</td>
                <td className="dapp-endpoint-text">{items?.ChainId}</td>
                <td className="dapp-endpoint-text">{items?.ID}</td>
                <td className="dapp-endpoint-text">
                    <Link to={"/dapp-endpointsDetails/"} state={{id: items?._id, infraType: infraType,protocolName:items?.protocolName}}>
                        <button className="dapp-endpoint-table-button"><span
                            style={{
                                padding: "40px 20px 40px 20px",
                                fontSize: "12px",
                                fontWeight: "700"
                            }}>View Details</span>
                        </button>
                    </Link>
                </td>
            </tr>:
                <td colSpan={5}>
                    <tr style={{display:"flex",justifyContent:"center"}} className="dapp-endpoint-text">{'Endpoint not selected!'}</tr>
                </td>
            }

            </tbody>
        </table>
    );
};
import React, { useState, useEffect } from 'react';
import {
    Col,
    Form,
    Input,
    Radio,
    Row,
    Space,
    Tooltip,
    Button,
    Slider, notification, message, InputNumber, Spin, // Import the Slider component
} from 'antd';
import '../style.less';
import fetchApi from "../../../_config/api";
import {getPATTokenDetails, getUserDetails} from "../../../deps";
import {apiCaller} from "../utils/utils";
import TextArea from "antd/es/input/TextArea";
import {GASLESS_URLS} from "../utils/constants";

export const AddExistingContractAsWhitelisted = ({callBackFunc}) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const token = getPATTokenDetails();
    const authToken = token.patToken;
    const userDetails = getUserDetails();
    const [projectList, setProjectList] = useState([]);
    const [networksList, setNetworkDropdown] = useState([]);
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const [showLoader, setLoader] = useState(false);

    const [instanceList, setInstanceList] = useState([]);


    const getAllInstance = async () => {
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify({"services": ["smart-contract-studio"]})
        };

        await fetch(`${gwURL}/api/v0/instance/getInstance`, options)
            .then(response => response.json())
            .then((response) => {
                if (response.Status === "SUCCESS") {
                    const {Data = []} = response
                    if (response.Data) {
                        setInstanceList([...Data]);
                    } else {
                        setInstanceList([]);
                    }
                }
            })
            .catch((error) => {
                setInstanceList([])
                notification.error({
                    message: error,
                    duration: 3,
                });
            });
    }

    async function getAllProjectUsingSubscriptionId() {
        let httpUrl = 'projects/list/projects/' + userDetails.profile.subscriptionId;
        let projectsDetails = await localApiCaller('GET', httpUrl, null);
        setProjectList(projectsDetails);
    }

    const localApiCaller = (methodName, url, request) => {
        return fetchApi({ method: methodName, url: url, data: request })
            .then((response) => {
                return response.statusCode == 200 ? response.docs || response.doc : [];
            })
            .catch((error) => {
                throw error; // Re-throw the error
            });
    };

    async function getNetworksDropDownArray() {
        let resp = await apiCaller('POST', GASLESS_URLS.LIST_ALL_PROTOCOL_CONFIGS, {userId: userDetails.profile.developerId}, false);
        setNetworkDropdown(resp.data.data);
    }



    useEffect(()=>{
        getAllProjectUsingSubscriptionId().then()
        getNetworksDropDownArray().then()
        getAllInstance().then()
    },[])


    async function addOrUpdateSubscription(value) {
        setLoader(true);
        try {
            value.gasLimitConfigured = Number(value.gasLimitConfigured)
            value.chainId = Number(value.chainId)

            console.log(value.linkedDAppId)
            console.log(instanceList)
            const instanceObj = instanceList.find(
                (service) => service.ProjectId === value.linkedDAppId
            );
            console.log(instanceObj?.instanceKey)
            value.serviceInstance = instanceObj?.instanceKey

            let resp = await apiCaller("POST", GASLESS_URLS.ADD_EXISTING_WHITELISTED_CONTRACT_AND_LINK_DAPP, value, true)
            if (resp.status) {
                form.resetFields()
                callBackFunc(null)
            }
        } catch (e) {
            console.log("error ", e)
        }
        setLoader(false);
    }

    return (
        <>
            <Form
                name="basic"
                ref={formRef}
                form={form}
                initialValues={{
                    remember: false,
                }}
                onFinish={addOrUpdateSubscription}
                autoComplete="off"
                style={{display:'grid',gap:'25px' ,marginTop:'30px', justifyContent:'center', marginLeft:'40px'}}
            >
                <Form.Item
                    name="contractName"
                    rules={[{ required: true, message: 'missing contract name' }]}
                    style={{ width: "610px" }}
                    // className={'column-container'}
                >
                    <Input
                        id="description"
                        className="input-container create-collection-drop"
                        placeholder="Enter contract name"
                        style={{ background:"#2e2e2e", color: "#bfbfbf"}}
                    />
                    {/*<p className={'header-text'} style={{display:'inline', paddingLeft:'5%'}}>wei</p>*/}
                </Form.Item>

                <Form.Item
                    name="contractAddress"
                    rules={[{ required: true, message: 'missing contract address' }]}
                    style={{ width: "610px" }}
                    // className={'column-container'}
                >
                    <Input
                        id="description"
                        className="input-container create-collection-drop"
                        placeholder="Enter contract address"
                        style={{ background:"#2e2e2e", color: "#bfbfbf"}}
                    />
                    {/*<p className={'header-text'} style={{display:'inline', paddingLeft:'5%'}}>wei</p>*/}
                </Form.Item>

                <Form.Item
                    name="contractABIArrayAsBase64"
                    rules={[{ required: true, message: 'missing contract ABI' }]}
                    style={{ width: "610px" }}
                    // className={'column-container'}
                >
                    <TextArea
                        size={2}
                        id="description"
                        className="input-container create-collection-drop"
                        placeholder="Enter Base64 ABI"
                        style={{ background:"#2e2e2e", color: "#bfbfbf"}}
                    />
                    {/*<p className={'header-text'} style={{display:'inline', paddingLeft:'5%'}}>wei</p>*/}
                </Form.Item>

                <Form.Item
                    name="chainId"
                    rules={[
                        {
                            required: true,
                            message: 'Select network Linked this contract',
                        },
                    ]}
                >
                    <select
                        className="gassless-dropdown-filter"
                        name="chainId"
                        style={{ width:"550px" }}
                    >
                        <option value="">Select network</option>
                        {networksList.map((item) => (
                            <option key={item?.chainId} value={item?.chainId}>
                                {item?.network}
                            </option>
                        ))}
                    </select>
                </Form.Item>

                <Form.Item
                    name="gasLimitConfigured"
                    rules={[{ required: true, message: 'Please Set limit for gas funding' }]}
                    style={{ width: "610px" }}
                    // className={'column-container'}
                >
                    <Input
                        id="description"
                        className="input-container create-collection-drop"
                        placeholder="Set limit for gas funding ( ether )"
                        style={{ background:"#2e2e2e", color: "#bfbfbf"}}
                    />
                    {/*<p className={'header-text'} style={{display:'inline', paddingLeft:'5%'}}>wei</p>*/}
                </Form.Item>

                <Form.Item
                    name="linkedDAppId"
                    rules={[
                        {
                            required: true,
                            message: 'Select dApp to Link this contract to',
                        },
                    ]}
                >
                    <select
                        className="gassless-dropdown-filter"
                        name="linkedDAppId"
                        style={{ width: "550px" }}
                        // onChange={onChangeInProjects}
                    >
                        <option value="">Select dApp to Link this contract to</option>
                        {projectList.map((item) => (
                            <option key={item?._id} value={item?.id}>
                                {item?.value || item}
                            </option>
                        ))}
                    </select>
                </Form.Item>


                <button type="submit" className={"gradient-button"} style={{ marginLeft: "200px"}}>
                    <Spin spinning={showLoader}>
                        Save
                    </Spin>
                </button>


            </Form>
            {/*</div>*/}
        </>
    );
};
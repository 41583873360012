import React from "react";

export const Filter = ({ width = "34", height = "34" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 34"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_filter_1)">
      <g clipPath="url(#clip_filter_2)">
        <path
          d="M12.75 22.3125C12.75 22.0307 12.8619 21.7605 13.0612 21.5612C13.2605 21.3619 13.5307 21.25 13.8125 21.25H20.1875C20.4693 21.25 20.7395 21.3619 20.9388 21.5612C21.1381 21.7605 21.25 22.0307 21.25 22.3125C21.25 22.5943 21.1381 22.8645 20.9388 23.0638C20.7395 23.2631 20.4693 23.375 20.1875 23.375H13.8125C13.5307 23.375 13.2605 23.2631 13.0612 23.0638C12.8619 22.8645 12.75 22.5943 12.75 22.3125ZM8.5 15.9375C8.5 15.6557 8.61194 15.3855 8.8112 15.1862C9.01046 14.9869 9.28071 14.875 9.5625 14.875H24.4375C24.7193 14.875 24.9895 14.9869 25.1888 15.1862C25.3881 15.3855 25.5 15.6557 25.5 15.9375C25.5 16.2193 25.3881 16.4895 25.1888 16.6888C24.9895 16.8881 24.7193 17 24.4375 17H9.5625C9.28071 17 9.01046 16.8881 8.8112 16.6888C8.61194 16.4895 8.5 16.2193 8.5 15.9375ZM4.25 9.5625C4.25 9.28071 4.36194 9.01046 4.5612 8.8112C4.76046 8.61194 5.03071 8.5 5.3125 8.5H28.6875C28.9693 8.5 29.2395 8.61194 29.4388 8.8112C29.6381 9.01046 29.75 9.28071 29.75 9.5625C29.75 9.84429 29.6381 10.1145 29.4388 10.3138C29.2395 10.5131 28.9693 10.625 28.6875 10.625H5.3125C5.03071 10.625 4.76046 10.5131 4.5612 10.3138C4.36194 10.1145 4.25 9.84429 4.25 9.5625Z"
          fill="#D9D9D9"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip_filter_1">
        <rect width="34" height="34" fill="white" />
      </clipPath>
      <clipPath id="clip_filter_2">
        <rect width="34" height="34" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

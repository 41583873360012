import React from "react";

export const AddKey = ({ width = "20px", height = "20px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.2499 10.7891H10.7916V17.2475C10.7916 17.59 10.6555 17.9186 10.4133 18.1608C10.171 18.4031 9.84249 18.5391 9.49992 18.5391C9.15735 18.5391 8.82881 18.4031 8.58657 18.1608C8.34434 17.9186 8.20825 17.59 8.20825 17.2475V10.7891H1.74992C1.40735 10.7891 1.07881 10.6531 0.836573 10.4108C0.594338 10.1686 0.458252 9.84005 0.458252 9.49748C0.458252 9.15491 0.594338 8.82637 0.836573 8.58413C1.07881 8.3419 1.40735 8.20581 1.74992 8.20581H8.20825V1.74748C8.20825 1.40491 8.34434 1.07637 8.58657 0.834131C8.82881 0.591896 9.15735 0.455811 9.49992 0.455811C9.84249 0.455811 10.171 0.591896 10.4133 0.834131C10.6555 1.07637 10.7916 1.40491 10.7916 1.74748V8.20581H17.2499C17.5925 8.20581 17.921 8.3419 18.1633 8.58413C18.4055 8.82637 18.5416 9.15491 18.5416 9.49748C18.5416 9.84005 18.4055 10.1686 18.1633 10.4108C17.921 10.6531 17.5925 10.7891 17.2499 10.7891Z"
      fill="white"
    />
  </svg>
);

import Header from "./Header";
import React from "react";
import './alert.less'
import { WarningFilled,CloseOutlined } from "@ant-design/icons";

export const AlertInfo = () => {
    return (
        <div class="alert alert-warning">
            <div class="alert-container">
                <div class="alert-icon">
                    <WarningFilled style={{ color: "#fff" }} />
                </div>
                <button type="button" className="close-icon" data-dismiss="alert" aria-label="Close">
                    <CloseOutlined/>
                </button>
                Our website is undergoing scheduled maintenance and will be back up shortly. We apologize for any inconvenience caused. Thank you for your patience.
            </div>
        </div>
    )
}
export default AlertInfo;
import React from "react";
import { Link } from "react-router-dom";
import { ACCESS } from "../../_config/constant";
import { Restricted, isAllowed } from "../../../auth/Restricted";
import { getUserDetails } from "../../deps";
import { ENTERPRISEDEVELOPERROLE } from "../../deps";

const { userRole } = getUserDetails().profile || {};

export const instancesColumn = (
  changeStatus,
  onReallocation,
  setDeveloperModalState
) =>
  [
    {
      title: "Instance Name",
      key: "name",
      render: (_, { name, uniqueId, teamId }) => (
        <Link to={`/nft-studio/collections/${uniqueId}?team=${teamId}`}>
          <span className="table_Link_nftStudio">{name}</span>
        </Link>
      ),
    },
    {
      title: "API Key",
      dataIndex: "apiKey",
    },
    {
      title: "Allocated Team",
      dataIndex: "allocatedTeam",
      render: (_, { teamName, teamId }) => (
        <span onClick={() => setDeveloperModalState(true, teamId)}>
          {teamName[0]}
        </span>
      ),
    },
    {
      title: "No of Collections",
      dataIndex: "collections",
      render: (_, { easynftcollections }) => (
        <span>{easynftcollections.length}</span>
      ),
    },
    {
      title: "Allocated Budget",
      dataIndex: "creditTotal",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      hidden: userRole === ENTERPRISEDEVELOPERROLE ? true : false,
      render: (_, { uniqueId, isActive }) => (
        <Restricted code={ACCESS.EASYNFT_UPDATE_INSTANCE}>
          <button
            onClick={() => changeStatus(uniqueId, isActive)}
            className={isActive ? "save-changes-btn-difer" : "save-changes-btn"}
          >
            {isActive ? "Deactivate" : "Activate"}
          </button>
        </Restricted>
      ),
    },
    {
      title: "Allocate Budget",
      render: (_, { uniqueId }) => (
        <Restricted code={ACCESS.EASYNFT_UPDATE_INSTANCE}>
          <button
            onClick={() => onReallocation(uniqueId)}
            className={"save-changes-btn"}
          >
            Allocate
          </button>
        </Restricted>
      ),
    },
  ].filter((item) => !item.hidden);

export const deployedCollectionColumn = () => [
  {
    title: "Collection Name",
    key: "collectionName",
    render: (_, { collectionName }) => <span>{collectionName}</span>,
  },
  {
    title: "Contract Address",
    dataIndex: "collectionContractAddress",
  },
  {
    title: "ERC Standard",
    dataIndex: "ercStandard",
    render: (_, { ercStandard }) => <span>{ercStandard}</span>,
  },
  {
    title: "No of NFT's",
    dataIndex: "mintedNfts",
    render: (_, { mintedNfts }) => <span>{mintedNfts.length}</span>,
  },
  {
    title: "Status",
    dataIndex: "status",
    render: (_, { status }) => (
      <button
        className={
          status === "PENDING" ? "save-changes-btn-difer" : "save-changes-btn"
        }
      >
        {status === "PENDING" ? "PENDING" : "SUCCESS"}
      </button>
    ),
  },
];

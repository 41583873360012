import { Table, Spin } from "antd";
import React from "react";
import { useNavigate } from "react-router-dom";
import "./styles.less";


export const CustomTable = ({
  data = [],
  columns = [],
  pagination = false,
  rowKey = "id",
  rowSelection = false,
  canNavigate = false,
}) => {
  const navigate = useNavigate();
  const redirectToDetails = (record) => {
    if (canNavigate) {
      navigate(`/project/${record.projectId}`);
    }
  };
  // console.log(data)

  
 

  return (
    <div className="table-section-container">
      {data ? (
        <Table
          rowKey={rowKey}
          pagination={pagination}
          rowClassName="table-row"
          columns={columns}
          dataSource={data}
          rowSelection={rowSelection}
          onRow={(record, rowIndex) => {
            return {
              onClick: (event) => {
                redirectToDetails(record);
              },
            };
          }}
        />
      ) : (
        <Spin tip="Loading..." />
      )}
    </div>
  );
};

import React from 'react'

import PolicyPage from './privacyPage'
import Header from '../new-light-theme/ui/Header'
import Footer from '../new-light-theme/ui/Footer'



const PrivacyPolicy = () => {
  return (
    <div style={{backgroundColor:'black'}}>
        <Header/>
      <PolicyPage/>
        <Footer/>

    </div>
  )
}

export default PrivacyPolicy
import {
  isEmptyObject,
  getAuthToken,
  isNonEmptyValue,
  getUserDetails,getPATTokenDetails,
    getDAppId
} from "../deps";
import { notification } from "antd";

const baseURL = process.env.REACT_APP_BASE_URL;
const devApp = process.env.REACT_APP_DEV_APP;

const getRequestBody = (method = "POST", data, contentType, isFormData, dAppID) => {
  const token = getAuthToken();
  const user = getUserDetails();
  const headers = {
    Accepts: "application/json",
  };

  if (!isFormData) {
    headers["Content-Type"] = contentType;
  }
  const dAppId = getDAppId();
  if(dAppID !== undefined && dAppID !== null && dAppID !== ""){
    headers["dAppId"] = dAppID;
  }else if (dAppId != null) {
    headers["dAppId"] = dAppId;
  }
  if (isNonEmptyValue(token)) {
    headers["authorization"] = token;
    if (user !== null) {
      headers["x-jwt-token"] = user.token;
    }
  }

  const request = { method, headers };

  if (!isEmptyObject(data)) {
    request["body"] = isFormData ? null : JSON.stringify(data);
  }
  return request;
};

export const getGenericRequestHeaders = () => {
  const dAppId = getDAppId();
  const patToken = getPATTokenDetails();
  const headers = {};
  headers["Content-Type"] = "application/json";
  if (dAppId != null){
    headers["dAppId"] = dAppId;
  }
  headers["Authorization"] = patToken?.patToken;
  return headers;
}

export const getRequestHeaders = (method,data, contentType, isFormData,chainId,instanceId,dAppID, mode) => {
  const patToken = getPATTokenDetails();
  const userSub = getUserDetails();
  const token = getAuthToken()
  const headers = {};

  if (!isFormData) {
    headers["Content-Type"] = contentType;
  }
  const dAppId = getDAppId();
  if(dAppID !== undefined && dAppID !== null && dAppID !== ""){
    headers["dAppId"] = dAppID;
  }else if (dAppId != null) {
    headers["dAppId"] = dAppId;
  }
  if (userSub !== null) {
    headers["ChainId"] = chainId;
    headers["Instanceid"] = instanceId;
    headers["Authorization"] = patToken?.patToken;
    headers["x-jwt-token"] = token;
   // headers["Authorization"] = userSub.profile?.subscriptionId+"_"+patToken?.patToken;
  }
  // mode = "cors"
  const request = { method, headers, mode };

  if (!isEmptyObject(data)) {
    request["body"] = isFormData ? null : JSON.stringify(data);
  }
  return request;
}

const userAuthenticationRedirect = () => {
  if (!window.location.hash.includes("/login")) {
    // removeAuthToken();
    //window.location.hash = "/login";
  }
};
export const gwURL = process.env.REACT_APP_GATEWAY_URL;
export const fetchApiGw = async (requestParams) => {
  const patToken = getPATTokenDetails();
  const userSub = getUserDetails();
  if (requestParams) {
    const {
      url = "",
      useBase = true,
      method = "POST",
      data = {},
      isBlob = false,
      isDevApp = false,
      isWalletApi = false,
      contentType = "application/json",
      isFormData = false,
      instanceId = "",
      serviceKey='',
      chainId = "",
      dAppID="" ,
    } = requestParams;
    console.log(requestParams)
    const request = getRequestHeaders(method, data, contentType, isFormData,chainId, instanceId,dAppID);
    let urlPath = `${useBase ? baseURL + "/" : ""}${
        isDevApp ? devApp : ""
    }${url}`;
    return new Promise((resolve, reject) => {
      isFormData ? (request.body = data) : console.log();
      fetch(urlPath, { ...request })
          .then(async (response) => {
            const {status, statusText = "", ok = ""} = response;
            if (!ok) {
              let resp = await response.json();
              throw Error(`${resp?.message || resp?.Message || `${statusText}: ${resp}`}`);
            } else {
              return response;
            }
          })
          .then((response) => {
            try {
              return response && isNonEmptyValue(response)
                  ? isBlob
                      ? response.blob()
                      : response.json()
                  : {};
            } catch (err) {
              reject();
            }
          })
          .then((data) => {
            resolve(data);
          })
          .catch((err) => {
            reject(err);
          });
    });
  }
  return "";
};

export const fetchApi = async (requestParams) => {
  const patToken = getPATTokenDetails();
  const userSub = getUserDetails();
  if (requestParams) {
    const {
      url = "",
      useBase = true,
      method = "POST",
      data = {},
      isBlob = false,
      isDevApp = false,
      isWalletApi = false,
      contentType = "application/json",
      isFormData = false,
      dAppID="" ,
    } = requestParams;
    const request = getRequestBody(method, data, contentType, isFormData,dAppID);
    let urlPath = `${useBase ? baseURL + "/" : ""}${
      isDevApp ? devApp : ""
    }${url}`;
    return new Promise((resolve, reject) => {
      isFormData ? (request.body = data) : console.log();
      fetch(urlPath, { ...request })
        .then(async (response) => {
          const { status, statusText = "", ok = "" } = response;
          if (!ok) {
            if (status === 401) {
              userAuthenticationRedirect();
              reject();
            } else if (status === 500 || status === 404 || status === 400) {
              let resp = await response.json();
              throw Error(`${resp?.message || resp?.Message || `${statusText}: ${resp}`}`);
            } else {
              response.json().then((json) => {
                notification.error({
                  message: json?.error || "Error",
                  description:
                    json?.message ||
                    "Resource Is Not Permitted To Access This Page",
                  duration: 3,
                });
              });
            }
          } else {
            // console.log("ok::", {ok, response});
            return response;
          }
        })
        .then((response) => {
          // console.log("second-then-data::", response);
          try {
            return response && isNonEmptyValue(response)
              ? isBlob
                ? response.blob()
                : response.json()
              : {};
          } catch (err) {
            reject(err);
          }
        })
        .then((data) => {
          resolve(data);
        })
        .catch((err) => {
          reject(err);
        });
    });
  }
  return "";
};
export default fetchApi;

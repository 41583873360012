import React from "react";

export const TickDefault = ({ width = "20px", height = "20px" }) => (
    <svg
        width={width}
        height={height}
        viewBox="0 0 19 19"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <circle cx="9.5" cy="9.5" r="9.5" fill="#181818" />

    </svg>
);

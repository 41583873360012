/* eslint-disable react/style-prop-object */
import React from "react";

export const Chain = ({ width = "50px", height = "50px" }) => (
  <svg 
      width={width}
      height={height}
      viewBox="0 0 14 22"
      fill="none" 
      xmlns="http://www.w3.org/2000/svg">
        <path d="M7.34424 1.49503L13.295 11.029L7.34424 14.424V1.49503Z" stroke="white"/>
        <path d="M6.50032 14.3352L0.69987 11.0289L6.50032 1.74406V8.13738V14.3352Z" stroke="white"/>
        <path d="M7.41211 16.8129L12.202 14.0826L7.41211 20.595V16.8129Z" stroke="white"/>
        <path d="M1.79766 14.0826L6.50032 16.7632V20.4763L1.79766 14.0826Z" stroke="white"/>
        <path d="M7.50049 8.90205L12.8831 11.2633L7.50049 14.3342V8.90205Z" stroke="#FDFDFD"/>
        <path d="M6.50032 8.90177V14.3346L1.11221 11.2633L6.50032 8.90177Z" stroke="#FDFDFD"/>
  </svg>
);

export default Chain;

import React, { useState } from "react";
import { Typography, Input, Space, notification } from "antd";
import { fetchApi } from "../../_config/api";
import "./style.less";
import { useAuth0 } from "@auth0/auth0-react";
import { ENTERPRISEADMINROLE } from "../../deps";
import { GetEnterpriseIdPopup } from "./popup";

export const SignUpForm = () => {
  const [data, setData] = React.useState({
    enterpriseName: "",
    enterpriseDesc: "",
    administratorName: "",
    administratorEmailId: "",
    enterpriseId: "",
  });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const handleCancel = () => {
    setIsModalOpen(false);
  };
  const onChangeDetail = (e) => {
    setData({ ...data, [e.target.id]: e.target.value });
  };

  const createAccount = () => {
    fetchApi({ method: "POST", url: "enterprise", isDevApp: false, data: data })
      .then((response) => {
        if (response.status === false) {
          notification.error({
            message: response.message ? response.message : "Error has occurred",
            duration: 2,
          });
        } else {
          notification.success({
            message: "successfully Created",
            duration: 2,
          });
          loginWithRedirect({
            appState: {
              role: ENTERPRISEADMINROLE,
              type: "ENTERPRISE",
              enterpriseId: response.enterpriseId,
            },
            enterpriseId: response.enterpriseId,
            userRole: ENTERPRISEADMINROLE,
            userType: "ENTERPRISE",
          });
        }
      })
      .catch(() => {
        debugger;
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 2,
        });
      });
  };

  const { Title } = Typography;
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  return (
    <div className="enterprise-role-select">
      <Space
        direction="vertical"
        size="middle"
        style={{
          display: "flex",
          padding: "4rem",
          paddingLeft: "6rem",
          paddingRight: "6rem",
        }}
      >
        <Title level={2}>Create an Enterprise Account!</Title>
        <p className="form-content">Please create your account.</p>
        <Space direction="vertical" size={25} style={{ display: "flex" }}>
          <Input
            id="enterpriseName"
            className="input-container"
            placeholder="organisation Name"
            value={data.enterpriseName}
            onChange={onChangeDetail}
          />
          <Input
            id="enterpriseDesc"
            className="input-container"
            placeholder="organisation Description"
            value={data.enterpriseDesc}
            onChange={onChangeDetail}
          />
          <Input
            id="administratorName"
            className="input-container"
            placeholder="Administrator Name"
            value={data.administratorName}
            onChange={onChangeDetail}
          />
          <Input
            id="administratorEmailId"
            className="input-container"
            placeholder="Administrator Mail Id"
            value={data.administratorEmailId}
            onChange={onChangeDetail}
          />
        </Space>
        <button className="btn form-submit-btn" onClick={createAccount}>
          Create Account
        </button>
        <p className="form-login-p">
          Already have an Enterprise account ?{" "}
          <span
            className="form-login-span"
            style={{ cursor: "pointer" }}
            onClick={
              () => showModal()
            }
          >
            Login Here
          </span>
        </p>
      </Space>
      <GetEnterpriseIdPopup
        isModalOpen={isModalOpen}
        handleCancel={handleCancel}
      />
    </div>
  );
};

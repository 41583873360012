import React from "react";
import { Select } from "antd";

const { Option } = Select;

export const ItemPerPage = ({ value, list, onChange, first, last, total }) => {
	

  return (
    <div className="hits-per-page-container">
      <Select value={value} style={{ width: 64.5 }} onChange={onChange}>
        {list.map((item, index) => (
          <Option key={index} value={item}>
            {item}
          </Option>
        ))}
      </Select>
      <div className="hits-per-page-text">
        <div className="items-text">Items per page</div>
        <div className="number-text">{`${first}-${last} of ${total} items`}</div>
      </div>
    </div>
  );
};

import React from "react";
import '../enterprisePagestyles.less'
export const GetInvolved = () => {

  return (
    <>
        <div className="get_Involved-section mt-lg-5">
            <div className="get_Involved-container p-lg-5">
                <div className="get_Involved-heading">
             <h2>Join our Community</h2>
             <p>Ready to dive into the exciting world of Web3 development? Join our vibrant Krypcore Web3 community today and connect with like-minded developers, share insights, collaborate on groundbreaking projects, and stay ahead in the fast-paced world of blockchain technology. Whether you're a seasoned pro or just starting out, our community offers the perfect platform to learn, innovate, and shape the future of decentralized applications. Join us now and be a part of the Web3 revolution!
             </p>
                </div>

            </div>
        </div>
    </>
  );
};

export default GetInvolved;

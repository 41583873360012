import React from "react";

export const FilterDropdown = ({options, onChange, placeholder, optionStyle }) => {

    return (
        <select className="dropdown-filter" onChange={onChange}>
            <option value="all" style={optionStyle}>
                {placeholder}
            </option>
            {options.map((item) => (
                <option key={item?._id || item} value={item?.id || item}>
                    {item?.value || item}
                </option>
            ))}
        </select>
    );
};



export default FilterDropdown;

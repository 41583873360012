/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import {PaginationSection, TableContent} from "../../../library";
import { DEVELOPERROLE, getUserDetails} from "../../../deps";
import "./styles.less";
import {columns} from "./columns";
import addIcon from "../SmartContract/addIcon.png";
import CreateTeams from "./CreateTeams";
import fetchApi from "../../../_config/api";
export const Teams = ({ showLoader}) => {
  const [pageSetting, setSetting] = useState({
    page: 1,
    itemsPerPage: 10,
    searchText: "",
    filterValue: "",
  });
  const {
    profile: { userRole },
  } = getUserDetails();
  const [filteredData, setFilteredData] = useState([]);
  const [createDappOpen, setCreateDappOpen] = useState(false);
  const [hitsList, setHitsList] = useState([]);
  const [totalCount, setTotalCount] = useState(0);
  const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
  const onCreateDappModal = () => {
    setCreateDappOpen(!createDappOpen);
  };
  useEffect(() => {
    getActiveTeamsList();
    setHitsList([5, 10, 15, 20]);
  }, [])

  const getActiveTeamsList = () => {
    const filter = {value:pageSetting.searchTextActive,status:"ACTIVE"}

    const Options = () => {
      setHitsList([5, 10, 15, 20]);
      return {
        select: [],
        sort: {},
        page: pageSetting.page - 1,
        limit: pageSetting.itemsPerPage,
      };
    };

    let requestLink = `teams/getAll?options=${JSON.stringify(
        Options()
    )}&filter=${JSON.stringify(filter)}`;
    showLoader(true);
    fetchApi({ method: "GET", url: requestLink, isDevApp: false })
        .then((response) => {
          if (response.statusCode === 200 || response.statusCode === 201  ){
            const { docs = [] } = response;
            if(response.docs != null) {
              setFilteredData([...docs]);
              showLoader(false);
            }else{
              setFilteredData([]);
            }
          }
          setTotalCount(response.totalCount)
        })
        .catch(() => {
          showLoader(false);
          setFilteredData([]);
        });
  };
  const onPageChange = (pageNumber) => {
    pageSetting.page = Number(pageNumber);
    setSetting({ ...pageSetting });
    getActiveTeamsList();
  };

  const onSearchFilter = () => {
    pageSetting.page = Number(1);
    setSetting({ ...pageSetting });
    getActiveTeamsList();
  }

  const onPerPageChange = (value) => {
    pageSetting.itemsPerPage = Number(value);
    pageSetting.page = 1;
    setSetting({ ...pageSetting });
    getActiveTeamsList();
  };

  const last =
      pageSetting.page * pageSetting.itemsPerPage > totalCount
          ? totalCount
          : pageSetting.page * pageSetting.itemsPerPage;
  return (
      <>
    <div className="transaction-page-container">
      <div className="transaction-header-section1 marginBtm">
        <div className="header-section1">
      </div>
        <div style={{display:"flex",justifyContent:"space-between"}} className="header-section">
          <div>
            <button className="sc-button" onClick={onCreateDappModal} disabled={true}>
              <span style={{padding:"15px",fontFamily:'Poppins' ,fontSize:'18px',fontWeight:'700',lineHeight:'35px'}}><img style={{marginBottom:"2px",marginRight:'5px',marginLeft:"5px"}} width="20px" height="20px" src={addIcon} /> Create </span>
            </button>
          </div>
        </div>
        <CreateTeams
            isOpen={createDappOpen}
            toggleState={onCreateDappModal}
        />
    </div>

      <div className="table-container">
        <TableContent
            rowKey="endpointId"
            data={filteredData}
            columns={columns}
            isDashboard={false}
            pagination={false}
        />
        <PaginationSection
            last={last}
            first={startIndex + 1}
            hitsList={hitsList}
            totalCount={totalCount}
            page={pageSetting.page}
            itemsPerPage={pageSetting.itemsPerPage}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
        />
      </div>
    </div>

    </>
  );
};

// Essentials
import React, {useEffect, useState} from "react";
//Utils
import "../../../nftstudio/styles.less";
import '../styles.less'
import {Modal, Space, Input, Steps, Collapse, notification} from "antd";
import { Menus } from "../../../../assets";
import fetchApi from "../../../../_config/api";

const { Step } = Steps;
const { Panel } = Collapse;
export const ViewPlanpopup = ({
                                  Open,
                                  toggleState,
                                  planName
                              }) => {
    const [planDetails, setPlanDetails] = React.useState({})
    const [serviceFeatures, setServiceFeatures] = React.useState({})

    const [planothers, setPlanothers] = React.useState({
        planNameH: "",
        price: "",
        duration:"",
        description:""
    })



    const  getPlanDetails = () =>{
        // console.log("planId",planName)
        let data = {
            planName: planName
        }
        return fetchApi({
            method: "POST",
            url: `subscription/plan/get`,
            isDevApp: false,
            data:data,
        }).then((response) => {
            // console.log("sdf",response)
            if (response.statusCode === 200 || response.statusCode === 201) {
                if (response.doc) {
                    setPlanDetails(response.doc.generalFeatures)
                    setPlanothers({planNameH: response.doc.planName,
                        price: response.doc.price,
                        duration: response.doc.validity,
                        description:response.doc.description})
                    setServiceFeatures(response.doc.serviceFeatures)
                }else{
                    setPlanDetails([])
                    setPlanothers({})
                    setServiceFeatures({})
                }
            }
        });
    }
    useEffect(() => {
        getPlanDetails()
    }, [Open]);


    return (
        <>
            <Modal className="view-plan-popup-width" centered maskStyle={{backgroundColor: '#CFCFCF80'}} title="" footer={null} closable={false} open={Open}>
                <div className="plan-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text">Existing Plan</div>
                    <Space
                        direction="vertical"
                        size={12}
                        style={{ display: "flex", marginTop: "25px"}}
                    >
                        <>
                            <p className="text">
                                {planothers.planNameH}</p>
                            <p className="plan_text">
                                Description&nbsp;:&nbsp;{planothers.description}<br/>
                                Features&nbsp;:&nbsp;<br/>
                                <ul id="plan_text">
                                    {Object.entries(planDetails).map(([key,value],index)=>(
                                        <li>
                                            {key}&nbsp;-&nbsp;{Array.isArray(value) ?
                                            value.map((list, index) => (
                                                <div key={index} >
                                                    <>
                                                        <Menus type="tick"/>&nbsp;
                                                        {list}
                                                    </>
                                                </div>
                                            )):value}
                                        </li>
                                    ))}
                                    {Object.entries(serviceFeatures).map(([key, value], index) => (
                                        typeof value === "object" && value.hasOwnProperty('description') !== true ? (
                                            Object.entries(value).map(([nestedKey, nestedValue], nestedIndex) => (
                                                <li key={nestedKey}>
                                                    {nestedValue.description}
                                                </li>
                                            ))
                                        ) : typeof value !== "object" && value.hasOwnProperty('description') !== true ?(
                                            <li key={key}>
                                                {value}
                                            </li>
                                        ):(
                                            <li key={key}>
                                                {value.description}
                                            </li>
                                        )
                                    ))}
                                </ul>
                                Pricing&nbsp;:&nbsp;${planothers.price}/month<br/>
                                Duration&nbsp;:&nbsp;{planothers.duration} months
                            </p>
                        </>
                    </Space>
                </div>
            </Modal>
        </>
    );
};

import React from "react";

export const Percentage = ({ width = "30px", height = "30px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 30 30"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_percentage)">
      <path
        d="M15.4154 26.875C21.7438 26.875 26.8737 21.7451 26.8737 15.4167C26.8737 9.08825 21.7438 3.95833 15.4154 3.95833C9.08696 3.95833 3.95705 9.08825 3.95705 15.4167C3.95705 21.7451 9.08696 26.875 15.4154 26.875Z"
        stroke="#20CD89"
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M18.1472 19.6668C18.2853 19.6668 18.4178 19.612 18.5154 19.5143C18.6131 19.4166 18.668 19.2841 18.668 19.146C18.668 19.0079 18.6131 18.8754 18.5154 18.7777C18.4178 18.68 18.2853 18.6252 18.1472 18.6252C18.009 18.6252 17.8765 18.68 17.7789 18.7777C17.6812 18.8754 17.6263 19.0079 17.6263 19.146C17.6263 19.2841 17.6812 19.4166 17.7789 19.5143C17.8765 19.612 18.009 19.6668 18.1472 19.6668ZM10.8555 12.3752C10.9936 12.3752 11.1261 12.3203 11.2238 12.2226C11.3214 12.1249 11.3763 11.9925 11.3763 11.8543C11.3763 11.7162 11.3214 11.5837 11.2238 11.486C11.1261 11.3884 10.9936 11.3335 10.8555 11.3335C10.7174 11.3335 10.5849 11.3884 10.4872 11.486C10.3895 11.5837 10.3347 11.7162 10.3347 11.8543C10.3347 11.9925 10.3895 12.1249 10.4872 12.2226C10.5849 12.3203 10.7174 12.3752 10.8555 12.3752Z"
        fill="#20CD89"
        stroke="#20CD89"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M19.668 11.3335L11.3347 19.6668"
        stroke="#20CD89"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip_percentage">
        <rect width="30" height="30" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React, { useState } from 'react'

const CustomForm = ({ status, message, onValidated }) => {
    const [subscribe,setSubscribe]=useState('')
    const [status1,setStatus1]=useState(false)
    let email;
  const subscribeHandler = () =>{
    email &&
    !subscribe.includes('krypc.com')&&
    email.value.indexOf("@") > -1 &&
    onValidated({
      EMAIL: email.value,
    });
    if(subscribe.includes('krypc.com')){
      setStatus1(true)
      setTimeout(()=>{
        setStatus1(false)
      },3000)
    }
    setSubscribe('')
}
  return (

    <div>
    <input
      placeholder="Enter your email..."
      className="buildK-copy-btn3"
      ref={node => (email = node)}
      type="email"
      value={subscribe}
      onChange={e => setSubscribe(e.target.value)}
    />
    <button onClick={subscribeHandler} className="buildK-copy-btn4">Subscribe</button> 
    {status === "sending" && <div style={{ color: "blue" }} className='hideMe'>sending...</div>}
    {status === "error" && <div style={{ color: "red" }} className='hideMe' dangerouslySetInnerHTML={{__html: message}}/>}
    {status === "success" && <div style={{ color: "green" }} className='hideMe'>Subscribed Successfully!</div>}
    {status1 === true && <div style={{ color: "red" }} className='hideMe'>Invalid Email</div>}
    
    </div>

  )
}

export default CustomForm
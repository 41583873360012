import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {gaslessProtocolConfigColumn, gasRequestColumn} from "../utils/columns";
import {Filters, PaginationSection, TableContent} from "../../../library";
import {apiCaller} from "../utils/utils";
import {getUserDetails} from "../../../deps";
import {GASLESS_URLS} from "../utils/constants";
import Skeleton from "react-loading-skeleton";

export const RequestedGasList = ({callBack,isLoading}) => {
    const userDetails = getUserDetails()
    const [gaslessRequestsArr, setGaslessRequestsArr] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);

    const [pageSettingForGasless, setSetting] = useState({
        page: 1,
        limit: 5,
        searchValue: "",
        searchIn:[],
        filterValue: "",
        filterIn:[],
        userId:userDetails.profile.developerId,
    });

    const last =
        pageSettingForGasless.page * pageSettingForGasless.limit > totalCount
            ? totalCount
            : pageSettingForGasless.page * pageSettingForGasless.limit;

    const startIndex = (pageSettingForGasless.page - 1) * pageSettingForGasless.limit;

    const listAllProtocolConfig =  async () => {
        let resp = await apiCaller("POST", GASLESS_URLS.LIST_ALL_GAS_REQUESTS , pageSettingForGasless, false)
        setGaslessRequestsArr(resp.data.data)
        setTotalCount(resp.data.total)
        callBack()
    }


    useEffect(()=>{
        listAllProtocolConfig().then()
    },[])

    const onPageChange = (pageNumber) => {
        pageSettingForGasless.page = Number(pageNumber);
        setSetting({ ...pageSettingForGasless });
    };

    const onPerPageChange = (value) => {
        pageSettingForGasless.page = 1;
        pageSettingForGasless.limit = Number(value);
        setSetting({ ...pageSettingForGasless });
    };

    return (
        <>
            {/*<div className="table-container marginTop10px  gasless-table">*/}
            {/*{!isLoading?(<Skeleton height={210} style={{marginTop:'30px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):( */}
            {/*    <TableContent*/}
            {/*        text={"Protocol & Gas Limit Configurations"}*/}
            {/*        rowKey="projectId"*/}
            {/*        data={gaslessRequestsArr}*/}
            {/*        columns={gasRequestColumn}*/}
            {/*        isDashboard={false}*/}
            {/*        pagination={false}*/}
            {/*    />*/}
            {/*)}*/}
            {/*    <PaginationSection*/}
            {/*        last={last}*/}
            {/*        first={startIndex + 1}*/}
            {/*        hitsList={[5, 10, 50, 100]}*/}
            {/*        totalCount={totalCount}*/}
            {/*        page={pageSettingForGasless.page}*/}
            {/*        itemsPerPage={pageSettingForGasless.limit}*/}
            {/*        onPageChange={onPageChange}*/}
            {/*        onPerPageChange={onPerPageChange}*/}
            {/*    />*/}
            {/*</div>*/}
        </>
    );
};

export default RequestedGasList;

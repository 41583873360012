import React from "react";
import "./style.less";




export const ContactForm= () => {


    return (

      <div className="plan-details" id="pricing&plans">
              <div className="">
                  <div className="">
                      <div className="plan-section">
                          <h3 className="contact-text">Thanks for your interest. Our team will get in touch with you.</h3>
                      </div>
                  </div>
              </div>
          <br/><br/>
      </div>
  );
};

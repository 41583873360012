import React from "react";

export const Avalanche = ({ width = "50px", height = "50px" }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 225.000000 225.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,225.000000) scale(0.100000,-0.100000)"
      fill="#e84142"
      stroke="none"
    >
      <path
        d="M1040 2244 c-164 -18 -296 -56 -430 -125 -662 -340 -810 -1225 -297
-1766 96 -101 182 -164 312 -228 198 -99 397 -136 607 -115 142 14 259 48 393
115 130 64 216 127 312 228 411 433 411 1111 0 1544 -159 167 -364 280 -592
327 -74 15 -246 27 -305 20z m131 -301 c14 -16 69 -106 123 -201 84 -149 99
-181 103 -230 4 -39 0 -69 -10 -93 -26 -62 -438 -772 -466 -802 -14 -16 -44
-39 -66 -50 -38 -21 -54 -22 -261 -22 -204 0 -224 2 -243 19 -45 41 -42 49
340 723 199 351 372 648 386 661 32 30 64 28 94 -5z m468 -820 c16 -16 85
-128 155 -250 135 -238 146 -272 105 -309 -20 -18 -40 -19 -309 -19 -404 0
-397 -12 -199 332 72 125 142 238 156 251 32 29 56 28 92 -5z"
      />
    </g>
  </svg>
);

export default Avalanche;

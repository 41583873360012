import {Link} from "react-router-dom";
import {CopyOutlined} from "@ant-design/icons";
import {AccountSettings} from "../../../assets/menus/accountSettings";
import React from "react";

export const columns =[
    {
        title: "",
        dataIndex: "",
    },
    {
        title: "Name",
        dataIndex: "name",
    },
    {
        title: "Credits Used",
        dataIndex: "creditsUsed",
        render: (_, { _id }) => (
            <>
                <span>0</span>
            </>
        ),
    },
    {
        title: "Credit Limit",
        dataIndex: "creditLimit",
        render: (_, { _id }) => (
            <>
                <span>0</span>
            </>
        ),
    },
    {
        title: 'Action',
        dataIndex: '',
        render: (_, { _id,name,teamId }) => (
            <>
                <Link to={"/manage-teams"} state={{id:_id,teamName:name,teamId:teamId}}>
                    Manage&nbsp;&nbsp;&nbsp;
                    <AccountSettings width="20px" height="20px" />
                </Link>
            </>
        ),
    }
];

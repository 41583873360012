/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useCallback } from "react";
import { getUserDetails, setUserDetails ,setPATToken,getPATTokenDetails} from "../deps";

export const UserContext = React.createContext({getUserDetails,getPATTokenDetails});
const UserContextProvider = UserContext.Provider;

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(getUserDetails);
  const [token, setToken] = useState(getPATTokenDetails);


  const onSetUserDetails = useCallback((data) => {
    setUser(data);
    setUserDetails(data);
  }, []);
  const onSetPATTokenDetails = useCallback((data) => {
    setToken(data);
    setPATToken(data);
  }, []);

  return (
    <UserContextProvider value={{ user, setUserDetail: onSetUserDetails ,token,setPATToken:onSetPATTokenDetails }}>
      {children}
    </UserContextProvider>
  )
};

export default UserProvider;

import React from "react";

export const TickInner = ({ width = "20px", height = "20px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill="white" />
    <path
      d="M5.01307 9.10769C5.13027 8.98869 5.28922 8.92184 5.45494 8.92184C5.62067 8.92184 5.77961 8.98869 5.89682 9.10769L8.10682 11.3522L12.5256 6.86385C12.5836 6.80487 12.6525 6.75808 12.7283 6.72615C12.8042 6.69421 12.8854 6.67776 12.9675 6.67773C13.0496 6.6777 13.1309 6.6941 13.2068 6.72598C13.2826 6.75785 13.3516 6.80459 13.4096 6.86353C13.4677 6.92246 13.5138 6.99243 13.5452 7.06945C13.5767 7.14646 13.5929 7.22902 13.5929 7.31239C13.5929 7.39576 13.5768 7.47833 13.5454 7.55536C13.514 7.6324 13.468 7.70241 13.4099 7.76138L8.10682 13.1472L5.01307 10.0052C4.8959 9.88619 4.83008 9.72477 4.83008 9.55646C4.83008 9.38815 4.8959 9.22672 5.01307 9.10769Z"
      fill="#AA4BEC"
    />
  </svg>
);

import React from 'react'
import RightIcon1 from "./rightIcon.svg"

const RightIcon = () => {
    return (
        <div className="ml-5">
            <img src={RightIcon1} style={{height:"14px",width:"14px",opacity: 0.8}}/>
        </div>
    )
}

export default RightIcon
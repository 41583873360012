import {ENTERPRISEDEVELOPERROLE} from "../../deps";

export const tabIds = {
  TAB_1: "details",
  TAB_2: "nodeconfig",
  TAB_3: "services",
  TAB_4: "summary",
};

export const tabValues = Object.values(tabIds);

export const getActiveIndex = (tabId) => {
  return tabValues.indexOf(tabId) + 1;
};

export const timelineList = [
  {
    id: tabIds.TAB_1,
    text: "dApp Details",
    index: 1,
    placeholderText: "Enter dApp Details",
  },
  {
    id: tabIds.TAB_2,
    text: "Node Configuration",
    index: 2,
    placeholderText: "Node Configuration",
  },
  {
    id: tabIds.TAB_3,
    text: "Services",
    index: 3,
    placeholderText: "Select Services",
  },
  {
    id: tabIds.TAB_4,
    text: "Summary",
    index: 4,
    placeholderText: "Project summary",
  },
];

export const IsFilled = (data, activeTab,role) => {
  let hasData = false;
  if (activeTab === tabIds.TAB_1) {
    if(role === ENTERPRISEDEVELOPERROLE){
      hasData = data?.teamId && data?.title && data?.description;
    }else{
      hasData = data?.title && data?.description;
    }
  } else if (activeTab === tabIds.TAB_2) {
    // hasData = data?.nodeconfig?.length && data.nodeconfig.length > 0;
    if(data?.nodeconfig?.length && data.nodeconfig.length > 0) {
      data.nodeconfig.some(element => {
        if (element.BlockchainEndpoint !== undefined && element.BlockchainEndpoint.length > 0) {
          hasData =  true;
        }
      });
    }
  }else if (activeTab === tabIds.TAB_3) {
    hasData = data?.services?.length && data.services.length > 0;
  }
  return hasData;
};

export const btnList = {
  details: { prevTab: "", nextTab: tabIds.TAB_2 },
  nodeconfig: { prevTab: tabIds.TAB_1, nextTab: tabIds.TAB_3 },
  services: { prevTab: tabIds.TAB_2, nextTab: tabIds.TAB_4 },
  summary: { prevTab: tabIds.TAB_3, nextTab: "create" },
  // summary: { prevTab: tabIds.TAB_3, nextTab: "create" },
};

export const defaultList = {
  protocolList: [
    { svgId: "ethereum", id: "5", name: "Ethereum (testnet)",  },
    { svgId: "polygon", id: "80001", name: "Polygon (testnet)" },
    { svgId: "ethereum", id: "1",  name: "Ethereum (Mainnet)" },
    { svgId: "polygon", id: "137",  name: "Polygon (Mainnet)" },
    { svgId: "solana", id: 5,  name: "Solana" , disabled:true},
    { svgId: "tezos", id: 6,  name: "Tezos" , disabled:true},
    { svgId: "stellar", id: 7,  name: "Stellar" , disabled:true},
  ],
  walletList: [
    { svgId: "metamask", id: "metamask", name: "Metamask" },
    { svgId: "coinbase", id: "coinbase", name: "Coinbase" , disabled:true},
    { svgId: "walletconnect", id: "walletConnect",  name: "Wallet Connect" , disabled:true},
  ],

  storageList: [
    { svgId: "ipfs",  id: "ipfs", name: "IPFS" },
    { svgId: "filecoin", id: 2, name: "Filecoin", disabled: true },
    { svgId: "storej", id: 3, name: "Storej", disabled: true },
    { svgId: "ceramicNetwork", id: 4, name: "Ceramic Network", disabled: true },
  ],
  setEndpointTypes : [
    { svgId: "shared", id: "SHARED", name: "Shared",  },
    { svgId: "dedicated", id: "DEDICATED", name: "Dedicated" },
  ],
  infraTypes : [
    { svgId: "blockchain", id: "blockchainNode", name: "Blockchain Node",  },
    { svgId: "storage", id: "storageNode", name: "Storage Node" },
    // { svgId: "did", id: "didNode", name: "DID Node" },
    { svgId: "bundler", id: "bundlerNode", name: "Bundler Node" }
  ],

};

export const industriesList = [
  { value: "Finance and Banking", label: "Finance and Banking" },
  { value: "Supply Chain and Logistics", label: "Supply Chain and Logistics" },
  { value: "Healthcare", label: "Healthcare" },
  { value: "Real Estate", label: "Real Estate" },
  { value: "Gaming and Entertainment", label: "Gaming and Entertainment" },
  { value: "Energy and Utilities", label: "Energy and Utilities" },
  { value: "Supply Chain Finance", label: "Supply Chain Finance" },
  { value: "E-commerce", label: "E-commerce" },
  { value: "Intellectual Property Rights", label: "Intellectual Property Rights" },
  { value: "Government and Public Services", label: "Government and Public Services" },
  { value: "Insurance", label: "Insurance" },
  { value: "Education", label: "Education" },
  { value: "Agriculture", label: "Agriculture" },
  { value: "Manufacturing", label: "Manufacturing" },
  { value: "Media and Publishing", label: "Media and Publishing" },
  { value: "Transportation and Mobility", label: "Transportation and Mobility" },
  { value: "Others", label: "Others" }
];



import {notification} from "antd";

const { REACT_APP_POLYGONSCAN, REACT_APP_ETHERSCAN_GOERLI } = process.env;

export function setFormData(data) {
  let formData = new FormData();
  for (const property in data) {
    formData.append(property, data[property]);
  }
  return formData;
}
export const onCopy = (apiKey) => {
  navigator.clipboard.writeText(apiKey);
  notification.success({
    message: "Copied to clipboard",
    duration: 3,
  });
};
export function getChainName(collection) {
  switch (collection.collectionChainID) {
    case "80001":
      return {
        svgId: "polygon",
        name: "Polygon (mumbai)",
        blockViewUrl: REACT_APP_POLYGONSCAN,
      };
    case "1002":
      return {
        svgId: "tezos",
        name: "Tezos",
        blockViewUrl: "",
      };
    case "5":
      return {
        svgId: "ethereum",
        name: "Ethereum (testnet)",
        blockViewUrl: REACT_APP_ETHERSCAN_GOERLI,
      };
    case "1":
      return {
        svgId: "ethereum",
        name: "Ethereum (mainnet)",
        blockViewUrl: "",
      };
    case "11155111":
      return {
        svgId: "ethereum",
        name: "Ethereum (sepolia)",
        blockViewUrl: ""
      }
    case "137":
      return {
        svgId: "polygon",
        name: "Polygon (mainnet)",
        blockViewUrl: ""
      }
    case "10":
      return {
        svgId: "optimism",
        name: "Optimism (mainnet)",
        blockViewUrl: ""
      }
    case "420":
      return {
        svgId: "optimism",
        name: "Optimism (goerli)",
        blockViewUrl: ""
      }
    case "42161":
      return {
        svgId: "arbitrum",
        name: "Arbitrum (mainnet)",
        blockViewUrl: ""
      }
    case "421613":
      return {
        svgId: "arbitrum",
        name: "Arbitrum (goerli)",
        blockViewUrl: ""
      }
    case "43114":
      return {
        svgId: "avalanche",
        name: "Avalanche (mainnet)",
        blockViewUrl: ""
      }
    case "43113":
      return {
        svgId: "avalanche",
        name: "Avalanche (fuji)",
        blockViewUrl: ""
      }
    case "295":
      return {
        svgId: "hedera",
        name: "Hedera (mainnet)",
        blockViewUrl: ""
      }
    case "296":
      return {
        svgId: "hedera",
        name: "Hedera (testnet)",
        blockViewUrl: ""
      }
    case "56":
      return {
        svgId: "binance",
        name: "bsc (mainnet)",
        blockViewUrl: ""
      }
    case "97":
      return {
        svgId: "binance",
        name: "bsc (testnet)",
        blockViewUrl: ""
      }
    case "84531":
      return {
        svgId: "base",
        name: "base (goerli)",
        blockViewUrl: ""
      }
    case "8453":
      return {
        svgId: "base",
        name: "base (mainnet)",
        blockViewUrl: ""
      }
    default:
      return "";
  }
}

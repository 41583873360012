import React from "react";

export const Footer = () => {
    const year = new Date().getFullYear();
    return(
    <div className="footer-container">
        &copy; {year} Krypc LLC.
    </div>
)
    }

export default Footer;
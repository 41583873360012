import {useNavigate} from "react-router-dom";
import React, {useEffect, useState, useRef} from "react";
import {notification} from "antd";
import {Filters, PaginationSection, TableContent} from "../../../library";
import {gaslessFundedTxColumns} from "../utils/columns";
import {apiCaller} from "../utils/utils";
import {GASLESS_URLS} from "../utils/constants";
import {getUserDetails} from "../../../deps";
import Skeleton from "react-loading-skeleton";
import searchIcon from "../whitelisted-contracts/search-icon/searchicon.svg"

const GaslessFundedTx = ({isLoading}) =>{
    const userDetails = getUserDetails()
    const [fundedTransactionList, setFundedTransactionList] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const searchBoxRef = useRef(null);


    const [pageSetting, setSetting] = useState({
        page: 1,
        limit: 5,
        searchValue: "",
        searchIn:[],
        filterValue: "",
        filterIn:[],
        userId: userDetails.profile.developerId
    });

    const last =
        pageSetting.page * pageSetting.limit > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.limit;

    const startIndex = (pageSetting.page - 1) * pageSetting.limit;

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
    };

    const onPerPageChange = (value) => {
        pageSetting.limit = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
    };

    const searchValue = (value) => {
        pageSetting.searchIn = ["transactionHash"]
        pageSetting.searchValue = value
        setSetting({ ...pageSetting });
    }

    const listAllFundedTransactions =  async () => {
        let resp = await apiCaller("POST", GASLESS_URLS.LIST_ALL_FUNDED_TRANSACTIONS , pageSetting, false)
        setFundedTransactionList( resp.data.data)
        setTotalCount(resp.data.total)
    }

    useEffect(()=>{
        listAllFundedTransactions().then()
    },[pageSetting])

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target) && pageSetting.searchValue === "") {
                setSearchExpanded(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pageSetting.searchValue]);

    return (
        <>
            {/* Header section */}
            <div className="gasless-header-section">
                <div className="header-text">Funded Transactions</div>
                <div className="gasless-filter">
                    {/* Filters component */}
                    {isLoading?(<Skeleton height={45} width={220} style={{marginTop:'30px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):( 
                        <div className={`search-container-gasless ${searchExpanded ? 'expanded' : ''}`} ref={searchBoxRef}>
                            <img
                                src={searchIcon}
                                alt="Search Icon"
                                className="search-expand-icon"
                                onClick={() => { setSearchExpanded(true); }}
                            />
                            {searchExpanded && (
                        <Filters
                        placeHolder={"Enter Txn Hash"}
                        onTextChange={searchValue}
                        showCreateBtn={false}
                        value={pageSetting.searchValue}
                        showFilters={false}
                        btnText="Enable Gasless For Protocol"
                    />
                                )}
                        </div>
                    )}
                </div>
            </div>

            {/* Table content */}
            {isLoading?(<Skeleton height={210} style={{marginTop:'30px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):( 
            <TableContent
                rowKey="_id"
                data={fundedTransactionList}
                columns={gaslessFundedTxColumns} // You should have defined the columns elsewhere
                isDashboard={false}
                pagination={false}
            />
            )}

            <PaginationSection
                last={last}
                first={startIndex + 1}
                hitsList={[5, 10, 50, 100]}
                totalCount={totalCount}
                page={pageSetting.page}
                itemsPerPage={pageSetting.limit}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
            />
        </>
    );
}

export default GaslessFundedTx
import React, { useState } from "react";
import "./style.less";
import { PromotionTile } from "./promoTile";
import { useAuth0 } from "@auth0/auth0-react";
import fetchApi from "../../_config/api";
import { useLocation } from "react-router-dom";
import { notification } from "antd";

export const EnterpriseDevLogin = () => {
    const { loginWithRedirect } = useAuth0();
    const search = useLocation().search;
    const [showSignUp, setShowSignUp] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");

    React.useEffect(() => {
        getEntDevDetailsFromAPI()
    }, []);

    const signUpForEntDev = () => {
        loginWithRedirect({
            screen_hint: 'signup',
        });
    };

    const getEntDevDetailsFromAPI = () => {
        const encryptdata = new URLSearchParams(search).get("data");
        console.log(encryptdata);
        fetchApi({
            method: "POST",
            url: `enterprise-developer/validate`,
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({ encryptedData: encryptdata }),
            isDevApp: false,
            data: { encryptedData: encryptdata },
        })
            .then((response) => {
                if (response.statusCode !== 200) {
                    setShowSignUp(false);
                    setErrorMessage(response.message);
                    notification.error({
                        message: response.message,
                        duration: 5,
                    });
                } else if (response.doc.enterpriseId !== "") {
                    setShowSignUp(true);
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    };

    return (

        <div className="enterprise-signup-page">
            <PromotionTile />
            <div className="enterprise-role-select">
                <div className="enterprise-role-select-inner">
                    <div className="form-title">
                    </div>
                    <div className="form-card">
                        {showSignUp ?
                            <button className="gradient-button" onClick={signUpForEntDev}>Sign Up</button>
                            :
                            null
                        }
                    </div>
                </div>
            </div>
        </div>
    );
};

const TOKEN = "user_token";
const USER_DATA = "user_data";
const TEMP_DATA = "temp_data";
const PAT_TOKEN = "pat_token";
const DAPP_ID = "dApp_id";

//roles
export const ENTERPRISEDEVELOPERROLE = "ENTERPRISE-DEVELOPER";
export const ENTERPRISEADMINROLE = "ENTERPRISE-ADMIN";
export const DEVELOPERROLE = "DEVELOPER";
export const DEVELOPERTYPE = "INDIVIDUAL";

const setTempRecord = (data) => {
  localStorage.setItem(TEMP_DATA, JSON.stringify(data));
};

const getTempRecord = () => {
  const sessionData = localStorage.getItem(TEMP_DATA) || "";
  return sessionData ? JSON.parse(sessionData) : {};
};


const setUserDetails = (data) => {
  localStorage.setItem(USER_DATA, JSON.stringify(data));
};
const setPATToken = (data) => {
  localStorage.setItem(PAT_TOKEN, JSON.stringify(data));
};
const setDAppId = (data) => {
  localStorage.setItem(DAPP_ID, JSON.stringify(data));
}
const getUserDetails = () => {
  const sessionData = localStorage.getItem(USER_DATA) || "";
  return sessionData ? JSON.parse(sessionData) : {};
};
const getPATTokenDetails = () => {
  const sessionData = localStorage.getItem(PAT_TOKEN) || "";
  return sessionData ? JSON.parse(sessionData) : {};
};
const getDAppId = () => {
  const dAppDetails = localStorage.getItem(DAPP_ID) || "";
  return dAppDetails ? JSON.parse(dAppDetails) : {};
};

const removeAllDataInLocal = () => {
  localStorage.clear();
};

const setAuthUserEmail = (data) => {
  localStorage.setItem("auth_user_email", data ?  data : "");
};
const getAuthUserEmail = () => {
  return localStorage.getItem("auth_user_email");
};

const setAuthToken = (data) => {
  localStorage.setItem(TOKEN, data ? "Bearer " + data : "");
};
const setRole = (data) => {
  localStorage.setItem("role_type", data ? data : "");
};
const setLoginType = (data) => {
  localStorage.setItem("login_type", data ? data : "");
};
const setEnterpriseId = (data) => {
  localStorage.setItem("enterprise_id", data ? data : "");
};
const setActivateEmail = (data) => {
  localStorage.setItem("activate_email", data ? data : "");
};
const getRole = () => {
  return localStorage.getItem("role_type");
};

const getEnterpriseId = () => {
  return localStorage.getItem("enterprise_id");
};
const getAuthToken = () => {
  return localStorage.getItem(TOKEN);
};
const getActivateEmail = () => {
  return localStorage.getItem("activate_email");
};

const removeAuthToken = () => {
  localStorage.removeItem(TOKEN);
};
const setProfileImage = (data) => {
  localStorage.setItem("profileImage", data ? data : "");
};
const getProfileImage = () => {
  return localStorage.getItem("profileImage");
};
const isArray = (props) => {
  const givenType = typeof props;
  const isArrayBooleanVal = props instanceof Array;
  return givenType && isArrayBooleanVal;
};

const isEmptyObject = (obj) => {
  return (
      obj === undefined ||
      obj === null ||
      !(obj instanceof Object) ||
      Object.keys(obj).length === 0
  );
};


const deepCopy = (obj) => {
  return JSON.parse(JSON.stringify(obj));
};

const isNonEmptyValue = (value) => {
  return value !== undefined && value !== null && value !== "";
};

const dateformatter = (date, isCustom = false, showTime = false) => {
  if (date) {
    const today = new Date(date);
    const yyyy = today.getFullYear();
    let mm = today.getMonth() + 1; // Months start at 0!
    let dd = today.getDate();

    let hh = today.getHours();
    let min = today.getMinutes();

    if (dd < 10) dd = "0" + dd;
    if (mm < 10) mm = "0" + mm;
    if (hh < 10) hh = "0" + hh;
    if (min < 10) min = "0" + min;

    const months = [
      "Jan",
      "Feb",
      "Mar",
      "Apr",
      "May",
      "Jun",
      "Jul",
      "Aug",
      "Sep",
      "Oct",
      "Nov",
      "Dec",
    ];

    if (isCustom) {
      return `${months[today.getMonth()]} ${dd}, ${yyyy
          .toString()
          .substring(0, 4)}`;
    }

    const dateString = dd + "/" + mm + "/" + yyyy;
    const time = hh + ":" + min;

    return dateString + (showTime ? ` ${time}` : "");
  }
  return "";
};

const onKeyPressERC20InputValidation = (event) => {
  // Validation rules
  const regexPattern = /^[a-zA-Z0-9_ -!@#$%^&*()+=<>?.]+$/; // Allow letters, numbers, one space, "-", and "_"
  // Prevent entering invalid characters
  if (!regexPattern.test(event.key)) {
    event.preventDefault();
  }
};
const onKeyPressInputValidation = (event) => {
  // Validation rules
  const regexPattern = /^[a-zA-Z0-9_ -]+$/; // Allow letters, numbers, one space, "-", and "_"
  // Prevent entering invalid characters
  if (!regexPattern.test(event.key)) {
    event.preventDefault();
  }
};

const scstudioinputValidation = (event) => {
  const regexPattern = /^(?![0-9]{1,2}$)[0-9a-zA-Z]+$/; // Allow letters, numbers, one space, "-", and "_"
  // Prevent entering invalid characters
  const inputValue = event.target.value + event.key;
  if (!regexPattern.test(inputValue)) {
    event.preventDefault();
  }
};
const onKeyPressAddressValidation = (event) => {
  // Validation rules
  const regexPattern = /^[a-zA-Z0-9]+$/; // Allow letters, numbers
  // Prevent entering invalid characters
  if (!regexPattern.test(event.key)) {
    event.preventDefault();
  }
};
const onKeyPressNumberValidation = (event) => {
  // Validation rules
  const regexPattern = /^[0-9]+$/; // Allow numbers
  // Prevent entering invalid characters
  if (!regexPattern.test(event.key)) {
    event.preventDefault();
  }
};
const onKeyPressDateValidation = (event) => {
  // Validation rules
  const regexPattern = /^[0-9/-]+$/;
  // Prevent entering invalid characters
  if (!regexPattern.test(event.key)) {
    event.preventDefault();
  }
};
export {
  setTempRecord,
  getTempRecord,
  setUserDetails,
  setPATToken,
  getUserDetails,
  removeAllDataInLocal,
  getAuthToken,
  removeAuthToken,
  setAuthToken,
  isArray,
  isEmptyObject,
  deepCopy,
  isNonEmptyValue,
  dateformatter,
  setRole,
  setLoginType,
  getRole,
  setEnterpriseId,
  getEnterpriseId,
  getPATTokenDetails,
  onKeyPressInputValidation,
  onKeyPressAddressValidation,
  onKeyPressNumberValidation,
  onKeyPressERC20InputValidation,
  scstudioinputValidation,
  setActivateEmail,
  getActivateEmail,
  setAuthUserEmail,
  getAuthUserEmail,
  onKeyPressDateValidation,
  getDAppId,
  setDAppId,
  setProfileImage,
  getProfileImage
};

import React, { useState } from "react";
import { Modal } from "antd";
import { Menus } from "../../assets";
import { Steps } from "antd";
import { Input, Space } from "antd";
import { Slider } from "antd";
import { notification } from "antd";
import fetchApi from "../../../component/_config/api";
import { getEnterpriseId } from "../../deps";

const { Step } = Steps;
notification.config({ duration: 2 });

export const AddprojectPopup = ({
  init,
  projectData,
  projectId,
  isEdit = false,
  step = 0,
  isModalOpen,
  handleCancel,
}) => {
  const [stepSize, setStepSize] = React.useState(step);
  const [budjetValue, setBudjetValue] = React.useState(0);
  const [maxBudjetValue, setMacxbudjetValue] = React.useState(0);
  const [data, setData] = useState({
    name: "",
    description: "",
  });

  const enterpriseId = getEnterpriseId() || "";

  const getProjectBudject = () => {
    fetchApi({
      method: "GET",
      url: `enterprise/${enterpriseId}`,
      isDevApp: false,
    })
      .then((response) => {
        const { creditAvailable } = response;
        setMacxbudjetValue(creditAvailable);
      })
      .catch(() => {});
  };

  const onChange = (id, value) => {
    data[id] = value;
    setData({ ...data });
  };

  const nextStep = () => {
    setStepSize(1);
  };

  const backStep = () => {
    setStepSize(0);
  };

  React.useEffect(() => {
    getProjectBudject();
    if (projectData) {
      setData({
        name: projectData?.name,
        description: projectData?.description,
      });
      setBudjetValue(projectData?.creditAvailable);
      setStepSize(step);
    }
  }, [projectData]);

  const addProject = () => {
    const accessBody = {
      ...data,
      creditTotal: budjetValue,
      creditAvailable: budjetValue,
    };
    fetchApi({
      method: "POST",
      url: `projects`,
      data: accessBody,
      isDevApp: false,
    })
      .then((response) => {
        handleCancel();
        setStepSize(0);
        init();
        notification.success({
          message: "successfully created",
          duration: 3,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  const updateProject = () => {
    const accessBody = {
      ...data,
      creditAllocated: budjetValue,
    };
    fetchApi({
      method: "PATCH",
      url: `projects/${projectId}`,
      data: accessBody,
      isDevApp: false,
    })
      .then((response) => {
        handleCancel();
        setStepSize(0);
        init();
        notification.success({
          message: "successfully created",
          duration: 3,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  return (
    <>
      <Modal title="" footer={null} closable={false} open={isModalOpen}>
        <div className="popup-request-container">
          <div className="close-icon" onClick={handleCancel}>
            <Menus type="close" />
          </div>
          <div className="popup-text">{isEdit ? "Update" : "Add"} Project</div>
          <div>
            <Steps current={stepSize} size="large">
              <Step title="Project Details" />
              <Step title="Allocate Budget" />
            </Steps>
          </div>
          {stepSize === 0 && <Project onChange={onChange} />}
          {stepSize === 1 && (
            <IconSlider
              setBudjetValue={setBudjetValue}
              budjetValue={budjetValue}
              max={maxBudjetValue}
              min={0}
            />
          )}
          <div className="btn-section mt-4">
            {stepSize === 0 && (
              <button className="ok-btn" onClick={nextStep}>
                Continue
              </button>
            )}
            {stepSize === 1 && !isEdit && (
              <button className="ok-btn" onClick={addProject}>
                Submit
              </button>
            )}
            {stepSize === 1 && isEdit && (
              <button className="ok-btn" onClick={updateProject}>
                Update
              </button>
            )}
            {stepSize === 1 && (
              <button className="cancel-btn" onClick={backStep}>
                Back
              </button>
            )}
            {stepSize === 0 && (
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export const Project = ({ onChange }) => {
  const onTextChange = (e) => {
    onChange(e.target.id, e.target.value);
  };

  return (
    <div className="column-add-project-container mt-4">
      <Space direction="vertical" size={20}>
        <Input
          id="name"
          className="input-container"
          placeholder="Project Title *" 
          onChange={onTextChange}
        />
        <Input.TextArea
          rows={5}
          id="description"
          className="input-container"
          placeholder="Project Description *"
          onChange={onTextChange}
        />
      </Space>
    </div>
  );
};

export const IconSlider = (
  { setBudjetValue, budjetValue, min = 0, max = 400 },
  props
) => {
  const marks = {
    [`${min}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{min}</strong>,
    },
    [`${max}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{max}</strong>,
    },
  };
  return (
    <div className="icon-wrapper mt-4">
      <Slider
        marks={marks}
        min={min}
        max={max}
        {...props}
        onChange={setBudjetValue}
        value={budjetValue}
      />
      <div className="desc-text mt-5">{budjetValue}-Allocate Budget For your Project</div>
    </div>
  );
};

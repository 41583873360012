import React from "react";
import {EditOutlined} from "@ant-design/icons";
import {Link} from "react-router-dom";
import {Tooltip} from "antd";


export const formatAge = (timestamp) => {
    if (!timestamp) return "";

    const currentTime = Date.now();
    const diff = currentTime - timestamp * 1000;

    const seconds = Math.floor(diff / 1000);
    const minutes = Math.floor(seconds / 60);
    const hours = Math.floor(minutes / 60);
    const days = Math.floor(hours / 24);

    if (days > 0) {
        return `${days} day${days > 1 ? "s" : ""} ago`;
    } else if (hours > 0) {
        return `${hours} hour${hours > 1 ? "s" : ""} ago`;
    } else if (minutes > 0) {
        return `${minutes} minute${minutes > 1 ? "s" : ""} ago`;
    } else {
        return `${seconds} second${seconds > 1 ? "s" : ""} ago`;
    }
};

export const data_Lake = (onViewDetails) => [

    {
        title: "Sr. No",
        dataIndex: "serialNumber",
        render: (_, __, index) => <span>{index + 1}</span>,
    },

    {
        title: "Txn Hash",
        dataIndex: "hash",
        render: (_, row) => (
                <a onClick={() => onViewDetails(row)}>
                    <Link style={{color:"white"}}>
                        {row.hash || " - "}
                    </Link>
                </a>
            ),
    },
    {
        title: "Method Id",
        dataIndex: "method_id",
    },
    {
        title: "Block Number",
        dataIndex: "blockNumber",
    },
    {
        title: "Age",
        dataIndex: "timestamp",
        render: (_, { timestamp }) => <span>{formatAge(timestamp)}</span>,
    },
    {
        title: "From",
        dataIndex: "from",
    },
    {
        title: "To",
        dataIndex: "to",

    },
    {
        title: "Value",
        dataIndex: "value",

    },
    {
        title: "Gas Fees",
        dataIndex: "gas_price",

    },
];


export const setUp_column = (onViewDetails) => [

    {
        title: "DB URL",
        dataIndex: "dbUrl",
        render: (_, row) => (
            <Tooltip title= {row?.dbUrl}>
                <a onClick={() => onViewDetails(row)}>
                    <Link style={{color:"white"}}>
                        {row?.dbUrl || " - "}
                    </Link>
                </a>
            </Tooltip>

        ),

    },
    {
        title: "Created At",
        dataIndex: "createdAt",

    },
    {
        title: "Last Modified At",
        dataIndex: "updatedAt",

    },
    {
        title: "Status",
        dataIndex: "status",
        render: (_, {status}) => (
            <span>{status == "true"?"ACTIVE":"DEACTIVE"}</span>
        ),

    },

    {
        title: "Action",
        key: "action",
        render: (_, row) => (
            <span onClick={() => onViewDetails(row)} className={"view-details1"}>{row.status == "true"?"Deactivate":"Activate"}</span>
        ),
    },

    ]

import React, { useEffect, useState } from "react";
import './staticPagestyles.less';
import { getActivateEmail } from "../../../component/deps";
import { fetchApi } from "../../_config/api";
import { notification } from "antd";
import Logo from "../../assets/logos/krypcore_logo.avif"
import {getAuthUserEmail} from "../../deps";
import {Helmet} from "react-helmet";

export const StaticPage = () => {
    const [unverifiedUser, setUnverifiedUser] = useState(null);
    const [emailID, setEmailID] = useState("");

    useEffect(() => {
        const user = getActivateEmail();
        const userEmailID = getAuthUserEmail();
        setEmailID(userEmailID);
        if (user) {
            setUnverifiedUser(user);
        }
    }, []);

    const sendVerificationEmail = () => {
        fetchApi({ method: "POST", url: "auth/sendAuth0VerificationEmail", data: { userId: unverifiedUser } })
            .then((response) => {
                console.log("response::", response);
                if(response.statusCode === 200) {
                    notification.success({
                        message: "verification email sent successfully",
                        duration: 3
                    });
                } else {
                    notification.error({
                        message: response?.message || "Something went wrong. Please try again",
                        duration: 3
                    });
                }
            })
            .catch((error) => {
                console.log("error::", error);
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3
                });
            });
    };

    return (
        <>
            <Helmet>
                <meta name="robots" content="noindex, nofollow" />
            </Helmet>
            <div className="activate-page-container">
                 <div className="activate-page-content-container">
                     <img src={Logo} alt="" className="logo" />
                     <p style={{fontSize:"24px",fontWeight:"bold",paddingTop:"70px"}}>Please verify your email</p>
                     <div>You're almost there! We've sent an email to
                         <br/><b>{emailID}</b>
                     </div>
                     <div className="mt-4">
                         <span>Just click on the link in the email to complete your signup.</span><br />
                         <span>If you don't see it,you may need to <b>check your spam</b> folder.</span>
                     </div>
                     <div className="mt-4">Still can't find the email?</div>
                     <button onClick={() => sendVerificationEmail()} className="gradient-button mt-1">Resend Email</button>
                     <div style={{fontSize:"16px"}} className="mt-5">Need help?
                         <br/>
                     <a href="/contact-us">
                         <b style={{color:"white",textDecoration:"underline"}}>Contact Us</b>
                     </a>
                     </div>
                 </div>
            </div>
        </>
    );
}

import React from "react";

export const Email = ({ width = "35px", height = "26px" }) => (
    <svg xmlns="http://www.w3.org/2000/svg"
         viewBox="0 0 24 24" id="IconChangeColor"
         width={width}
         height={height}
    >
      <path d="M12,19c0-3.87,3.13-7,7-7c1.08,0,2.09,0.25,3,0.68V6c0-1.1-0.9-2-2-2H4C2.9,4,2,4.9,2,6v12c0,1.1,0.9,2,2,2h8.08 C12.03,19.67,12,19.34,12,19z M4,6l8,5l8-5v2l-8,5L4,8V6z M17.34,22l-3.54-3.54l1.41-1.41l2.12,2.12l4.24-4.24L23,16.34L17.34,22z" id="mainIconPathAttribute" fill="blue">

      </path>
    </svg>
);

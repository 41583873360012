/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useContext, useEffect, useState} from "react";

import "./styles.less";
import {PaginationSection, TableContent} from "../../../library";
import {EndpointTransaction} from "./columns";
import fetchApi from "../../../_config/api";
import {useLocation} from "react-router-dom";
import {Menus} from "../../../assets";
import {dateformatter, deepCopy, ENTERPRISEDEVELOPERROLE, getUserDetails} from "../../../deps";
import {EditEndpoint} from "./EditEndpoint";
import {CopyOutlined} from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";
import {schemaJson} from "../DappDetails/schema";
import {Form, notification, Modal,Space, Spin,Button,Upload} from "antd";
import EditIcon from "./editIcon.svg";
import RightIcon1 from "../../../assets/menus/rightIcon.svg";
import APISandboxPage from "../DappDetails/apiSandbox";
import {EnpointsAPISandboxPage} from "./endpointsApiSandbox";


export const EndpointsDetails = ({showLoader,isLoading}) => {
    const [data, setData] = useState({});
    const [transactionData, setTransactionData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const  location = useLocation()
    let endpointId ='',  infraType = "",protocolName=""
    if (location.state !== null){
        endpointId = location.state.id
        infraType = location.state.infraType
        protocolName = location.state.protocolName
    }
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const user = getUserDetails()
    const [apiKey, setApiKey] = React.useState("");
    const [warningPopupOpen, setWarningPopupOpen] = useState(false);
    const [editEndpointOpen, setEditEndpointOpen] = useState(false);
    const [hitsList, setHitsList] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;


    const formRef = React.useRef(null);

    const [networks, setNetworks] = React.useState([]);
    const [tokenType, setTokenType] = React.useState([]);
    const [tokenTypeList, setTokenTypeList] = useState([]);
    const [walletList, setWalletList] = React.useState([]);
    const [wallets, setWallets] = useState([]);
    const [subTokenType, setSubTokenType] = React.useState([]);
    const [tokenTypeSubList, setTokenTypeSubList] = useState([]);
    const [schema, setSchema] = useState(null);
    const { pathname } = useLocation();
    const [projectId, setProjectId] = useState("");
    const [projectDetails, setProjectDetails] = useState({});
    const [schemaDatas, setSchemaDatas] = useState(null);
    const [methodNameList, setMethodNameList] = useState([]);
    const [firstTenIPFSUrl, setFirstTenIPFSUrl] = useState("");
    const [lastTenIPFSUrl, setLastTenIPFSUrl] = useState("");
    const onEditEndpointModal = () => {
        setEditEndpointOpen(!editEndpointOpen);
    };
    const onWarningModal = () => {
        setWarningPopupOpen(!warningPopupOpen);
    };
        const { userEndpoint } = data;

        const firstTen = userEndpoint?.substring(0, 20);
        const lastTen = userEndpoint?.substring(userEndpoint?.length - 5);
        const stars = '*****'; // Or you can use actual star symbols/icons if you prefer
    useEffect(() => {
        getEndpointsList();
        // getToken();
        // getETHMethods();
    }, []);

    useEffect(() => {
        onFilterDataChange();
    }, [data, pageSetting]);
    useEffect(() => {
        getEndpointsTransactionList();
    }, [data]);
    const getEndpointsList = () => {
        fetchApi({ method: "GET", url: `endpoints/${endpointId}` })
            .then((response) => {
                const { doc = {} } = response;
                setData(doc);
                setFirstTenIPFSUrl(doc?.ipfsGatewayUrl?.substring(0, 20));
                setLastTenIPFSUrl(doc?.ipfsGatewayUrl?.substring(doc?.ipfsGatewayUrl?.length - 5));
            })
            .catch(() => {
                setData({});
            });
    };
    const getETHMethods = () => {
        const data = {};
        data.protocolName = protocolName;
        fetchApi({ method: "POST", url: `endpoints/getETHMethodName`,data:data })
            .then((response) => {
                if(response.statusCode === 200){
                    const { doc = {} } = response;
                    setMethodNameList(doc)
                }
            })
            .catch(() => {
                setMethodNameList([]);
            });
    };
    const getToken = () => {
        fetchApi({ method: "GET", url: `endpoints/getBySubId/${user.profile.subscriptionId}` })
            .then((response) => {
                const { doc = {} } = response;


            })
            .catch(() => {
            });
    };
    const getEndpointsTransactionList = () => {
        setHitsList([5, 10, 15, 20, 25]);
        showLoader(true)
        let filter = {};
        let newFilter = {};
        let endpointUrl = "";
        if(data.userEndpoint !== undefined){
            endpointUrl = data.userEndpoint.replace(/^https:\/\//i, '');
        }
        let ipfsUrl = "";
        if(data.ipfsGatewayUrl !== undefined){
            ipfsUrl = data.ipfsGatewayUrl.replace(/^https:\/\//i, '');
        }
        if(infraType === "blockchainNode"){
            filter = {
                and: [
                    {
                        key: "service",
                        value: "rpc",
                        opt: "eq",
                    },
                    {
                        key: "requestURL",
                        value: endpointUrl,
                        opt: "eq",
                    },
                ],
            };
        }else if(infraType === "storageNode"){
            filter = {
                or: [
                    {
                        key: "requestURL",
                        value: endpointUrl,
                        opt: "eq",
                    },
                    {
                        key: "requestURL",
                        value: ipfsUrl,
                        opt: "eq",
                    },
                ],
            };
            newFilter = {
             "requestUrl" : endpointUrl
            }
        }else if(infraType === "didNode"){
            filter = {
                and: [
                    {
                        key: "service",
                        value: "did",
                        opt: "eq",
                    },
                ],
            };
        }else{
            filter = {
                or: [
                    {
                        key: "service",
                        value: "scWallet",
                        opt: "eq",
                    },
                    {
                        key: "requestURL",
                        value: endpointUrl,
                        opt: "eq",
                    },
                ],
            };
        }
        const Options = () => {
            return {
                select: "",
                sort: "",
                skip:  (pageSetting.page - 1) * (pageSetting.itemsPerPage) ,
                limit: pageSetting.itemsPerPage,
            };
        };
        const encodedFilter = encodeURIComponent(JSON.stringify(filter));
        const encodedNewFilter = encodeURIComponent(JSON.stringify(newFilter));
        fetchApi({ method: "GET", url: `endpoints/transaction?options=${JSON.stringify(
                Options()
            )}&filter=${encodedFilter}&newFilter=${encodedNewFilter}` })
            .then((response) => {
                showLoader(false)
                if (response.statusCode === 200 ||response.statusCode === 200){
           
                    const { docs = [] } = response;
                    // console.log("data",docs)
                    setTransactionData([...docs])
                    setTotalCount(response.meta.totalDocs[0].count)
                }
            })
            .catch(() => {
                // console.log("false")
                setTransactionData([])
                showLoader(false)
            });
    };

    const onFilterDataChange = () => {
        // console.log("onFilterDataChange========>")
        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > transactionData.length
                ? transactionData.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchText
                ? transactionData.filter((item) =>
                    item.status
                        .toLowerCase()
                        .includes(pageSetting.searchText.toLowerCase())
                )
                : deepCopy(transactionData),
                pageSetting.searchText
                    ? transactionData.filter((item) =>
                        item.protocolName
                            .toLowerCase()
                            .includes(pageSetting.searchText.toLowerCase())
                    )
                    : deepCopy(transactionData)
        )
            .filter((item) =>
                pageSetting.filterValue !== ""
                    ? (pageSetting.filterValue === "locked" && item.isLocked) ||
                    (pageSetting.filterValue === "unlocked" && !item.isLocked)
                    : true
            )
            .slice(0, endIndex);
        setFilteredData(filterList);
    };
    useEffect(() => {
        setTotalCount(totalCount);
    }, [transactionData]);

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getEndpointsTransactionList();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getEndpointsTransactionList();
    };

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;

    const ViewDocumentation = () => {
        if(infraType === "blockchainNode"){
            window.open(`https://docs.beta.krypcore.com/krypcore-web3/api-reference/infrastructure-apis/ethereum-json-rpc-apis`);
        }else if(infraType === "storageNode"){
            window.open(`https://docs.beta.krypcore.com/krypcore-web3/api-reference/infrastructure-apis/ipfs-http-client-apis`);
        }else if(infraType === "didNode"){
            window.open(`https://docs.beta.krypcore.com/krypcore-web3/api-reference/core-service-apis/did-manager-apis`);
        }
    }

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };




    React.useEffect(() => {
        getTokenTypeList();
        getWalletList();
        fetchSchema();

    }, []);



    const fetchSchema = async () => {
        try {
            const response = schemaJson
            console.log("response",response)

            setSchemaDatas(response[0]);
            console.log("schemaData")
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    const getTokenTypeList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setTokenTypeList([
            {tokenId:"FT",tokenType:"FT"},{tokenId:"NFT",tokenType:"NFT"},{tokenId:"STORAGE",tokenType:"STORAGE"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };

    const getWalletList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setWalletList([
            {walletId:"Dev Wallet",walletType:"Dev Wallet"},{walletId:"Self Managed Wallet",walletType:"Self Managed Wallet"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };

    useEffect(() => {
        const paths = pathname?.slice?.(1).split?.("/");
        if (paths && paths.length === 2) {
            setProjectId(paths[1]);
        }
    }, [pathname]);
    console.log("projectId ------>",projectId)
    useEffect(() => {
        if (projectId) {
            getProjectDetails();
        }
    }, [projectId]);
    const getProjectDetails = () => {
        // showLoader(true);
        fetchApi({ method: "GET", url: `developer/projects/${projectId}` })
            .then((response) => {
                console.log("response.doc ------>",response.doc)
                if (response.doc !== null) {
                    console.log("response.doc ------>",response.doc)
                    setProjectDetails(response.doc);
                }
                // showLoader(false);
            })
            .catch(() => {
                // showLoader(false);
            });
    };

  return (
      <>
          <div className="transaction-page-container">
              <a id="endpointExport" style={{display: "none"}}/>
              <div className="endpointDetails_header">
                  {data.infraType === "blockchainNode" ? (
                      <>
                          {/*<div className="endpointDetails_text_header"><span className="endpointDetails_text">Blockchain Endpoint: {data.endpointName}</span></div>*/}
                          {/*<div className="endpointDetails_text_header"><span className="endpointDetails_text">{data.protocolName}</span></div>*/}
                          <div className="endpointDetails_text_header"><span className="text">Token</span></div>

                          <div className="endpointDetails_text_header">
                              {user.profile.userRole !== ENTERPRISEDEVELOPERROLE ? data.status === "SUSPENDED" ?
                                  <>{isLoading ? (<Skeleton height={45} width={160} style={{marginRight: '10px'}}
                                                            baseColor="#262626"
                                                            highlightColor="#404040"></Skeleton>) : (
                                      <button className="suspend-btn" onClick={onWarningModal}>
                                          Activate
                                      </button>)}</> :

                                  <> {isLoading ? (<Skeleton height={45} width={160} style={{marginRight: '10px'}}
                                                             baseColor="#262626"
                                                             highlightColor="#404040"></Skeleton>) : (
                                      <button className="suspend-btn" onClick={onWarningModal}>
                                          Suspend
                                      </button>)}</> : null}
                              {isLoading ? (
                                  <Skeleton height={45} width={160} style={{marginRight: '10px'}} baseColor="#262626"
                                            highlightColor="#404040"></Skeleton>) : (
                                  <button className="doc-btn" onClick={ViewDocumentation}>
                                      View Documentation
                                  </button>)}
                          </div>
                      </>
                  ) : data.infraType === "storageNode" ? (
                          <>
                              <div className="endpointDetails_text_header"><span className="endpointDetails_text">Storage Endpoint: {data.endpointName}</span>
                              </div>

                          </>
                      )
                      : data.infraType === "didNode" ? (
                          <>
                              <div className="endpointDetails_text_header"><span className="endpointDetails_text">DID Endpoint: {data.endpointName}</span>
                              </div>

                          </>
                      ) : ""}
              </div>
              {/*{isLoading ? (<Skeleton height={170} style={{marginBottom: '10px'}} baseColor="#262626"*/}
              {/*                        highlightColor="#404040"></Skeleton>) : (*/}
              <div className="App">
                  <table>
                      <tr>
                          {infraType === "blockchainNode" ?
                              <td colSpan={3} className="endpointDetails_text"><p>Endpoint URL<span
                                  style={{paddingLeft: "55px"}}></span>: &nbsp;&nbsp;
                                  <span className="endpointDetails_text_inner">
                                     {firstTen}&nbsp;{stars}&nbsp;{lastTen}
                                  </span> &nbsp;&nbsp;
                                  <CopyOutlined onClick={() => onCopy(data.userEndpoint)}/></p></td>
                              : infraType === "storageNode" ?
                                  <td colSpan={3} className="endpointDetails_text">
                                      <p>Client URL<span style={{paddingLeft: "85px"}}></span>:&nbsp;&nbsp;&nbsp;
                                          <span className="endpointDetails_text_inner">
                                            {firstTen}&nbsp;{stars}&nbsp;{lastTen}
                                          </span>&nbsp;&nbsp;
                                          <CopyOutlined onClick={() => onCopy(data.userEndpoint)}/></p>
                                      {/* <p>IPFS Gateway URL&nbsp;:&nbsp;<span className="endpointDetails_text_inner">{data.ipfsGatewayUrl}</span>&nbsp;&nbsp;<CopyOutlined onClick={() => onCopy(data.ipfsGatewayUrl)} /></p>*/}
                                  </td>
                                  : infraType === "didNode" ?
                                      <td colSpan={3} className="endpointDetails_text"><p>DID URL <span style={{paddingLeft: "101px"}}></span>:&nbsp;&nbsp;&nbsp;
                                          <span className="endpointDetails_text_inner">
                                            {firstTen}&nbsp;{stars}&nbsp;{lastTen}
                                          </span>&nbsp;&nbsp;
                                          <CopyOutlined onClick={() => onCopy(data.userEndpoint)}/></p></td>
                                      :
                                      <td colSpan={3} className="endpointDetails_text"><p>Bundler URL <span style={{paddingLeft: "64px"}}></span>:&nbsp;&nbsp;
                                          <span className="endpointDetails_text_inner">
                                            {firstTen}&nbsp;{stars}&nbsp;{lastTen}
                                          </span>&nbsp;&nbsp;
                                          <CopyOutlined onClick={() => onCopy(data.userEndpoint)}/></p></td>
                          }
                      </tr>
                      <tr>
                          {infraType === "blockchainNode" ?
                              <>
                                  <td className="endpointDetails_text"><p>Chain ID <span style={{paddingLeft: "90px"}}></span>:&nbsp;&nbsp; {data.chainId}
                                  </p></td>
                                  {/*<td className="endpointDetails_text"><p>Network : {data.network}</p></td>*/}
                                  {/*<td className="endpointDetails_text"><p>Created On : {dateformatter(data.createdAt, false, true)}</p></td>*/}
                              </>
                              : null
                          }
                      </tr>
                      <tr>
                          <td className="endpointDetails_text"><p>Status<span style={{paddingLeft:"115px"}}></span>:&nbsp;&nbsp; {data.status}</p></td>
                          {/*<td className="endpointDetails_text"><p>Endpoint Type : {data.endpointType}</p></td>*/}
                          {/*<td className="endpointDetails_text"><p>API Version : {data.serviceApiVersion}</p></td>*/}
                      </tr>
                      {infraType === "storageNode" ?
                          <tr>
                              <td colSpan={3} className="endpointDetails_text">
                                  <p>IPFS Gateway URL<span style={{paddingLeft: "14px"}}></span>:&nbsp;&nbsp;<span
                                      className="endpointDetails_text_inner">{firstTenIPFSUrl}&nbsp;{stars}&nbsp;{lastTenIPFSUrl}</span>&nbsp;&nbsp;
                                      <CopyOutlined onClick={() => onCopy(data.ipfsGatewayUrl)}/></p>
                              </td>
                          </tr> : ""}
                  </table>
              </div>
              {/*)}*/}

              <br/>
              <>


                  <WarningPopup isOpen={warningPopupOpen} toggleState={onWarningModal} status={data.status}
                                endpointId={endpointId} reload={getEndpointsList}/>


                  <EditEndpoint
                      isOpen={editEndpointOpen}
                      toggleState={onEditEndpointModal}
                      endpointId={endpointId}
                      reload={getEndpointsList}
                  />
              </>

              <div className="transaction-page-container">
                  <div className="table-container">
                      <div className="header-section">
                          <div className="text">API History</div>
                      </div>
                      {isLoading ? (<Skeleton height={180} style={{marginTop: '30px'}} baseColor="#262626"
                                              highlightColor="#404040"></Skeleton>) : (
                          <TableContent
                              rowKey="developerId"
                              data={transactionData}
                              columns={EndpointTransaction}
                              isDashboard={false}
                              pagination={false}
                          />)}
                  </div>
                  <PaginationSection
                      last={last}
                      first={startIndex + 1}
                      hitsList={hitsList}
                      totalCount={totalCount}
                      page={pageSetting.page}
                      itemsPerPage={pageSetting.itemsPerPage}
                      onPageChange={onPageChange}
                      onPerPageChange={onPerPageChange}
                  />
              </div>

              <div className="header-section">
                  <div className="text">API Sandbox</div>
              </div>
              <div class="api-sandbox-line"></div>
              <EnpointsAPISandboxPage methodList={methodNameList} rpcURL={data?.userEndpoint}/>

          </div>
      </>

  );
};

export default EndpointsDetails;


export const WarningPopup = ({
                                 isOpen,
                                 toggleState,
                                 status,
                                 endpointId,
                                 reload

                             }) => {

    const UpdateStatus = () => {
        let statusData = ""
        if (status === "ACTIVE") {
            statusData = "SUSPENDED"
        } else {
            statusData = "ACTIVE"
        }
        fetchApi({method: "PUT", url: `endpoints/updateStatus/${endpointId}/${statusData}`})
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    notification.success({
                        message: "Successfully Status Updated",
                        duration: 3,
                    });
                    reload()
                    toggleState()
                } else {
                    notification.error({
                        message: "Something went wrong. Please try again",
                        duration: 3,
                    });
                }
            })
            .catch(() => {
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3,
                });
            });
    };

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen} style={{top:"30%",display:'flex',alignContent:'center',justifyContent:'center',alignItems:'center'}} className="suspend">
                <div className="nft-collection-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <Space
                        direction="vertical"
                        size={25}
                        style={{ display: "flex", marginTop: "25px" }}
                    >
                    <div className="text">{status === "ACTIVE"?"Are You Sure want to Suspend Endpoint?":"Are You Sure want to Activate Endpoint?"}</div>
                        <div className="endpoint_button1">
                        <button className="gradient-button"  onClick={UpdateStatus}>
                            Yes
                        </button>
                        <button className="gradient-button" onClick={toggleState} >
                            No
                        </button>
                        </div>
                    </Space>
                </div>
            </Modal>
        </>
    );
};

import React, {useRef, useState} from "react";
import '../enterprisePagestyles.less'
import { FileTextOutlined } from "@ant-design/icons";
import {useAuth0} from "@auth0/auth0-react";
import {useNavigate} from "react-router-dom";

export const Banner = () => {
  const navigate= useNavigate();
  const onNavigate = () => {
    navigate('/contact-us')
  };
  return (
    <>
      <div className="Bannersection-wrap" id="home">
        <div className="container">
          <div className="col-md-10 mx-auto banner-head-container">
            <h1 className="banner-heading">
              Transform your Business with <span className="banner-highlight">Krypcore Web3</span>
            </h1>
            <p className="banner-para">
              Harness the True Potential of Web3 with Krypcore
            </p>
            <div className="banner-button-section">
            <button className="banner_btn" onClick={() => onNavigate()}>
              Contact Us
            </button>
              </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;

export const GASLESS_URLS = {
    LIST_ALL_SUPPORTED_NETWORKS: `listAllSupportedNetworks`,
    CREATE_GAS_LIMIT_REQUEST: `createGasRequest`,
    GET_PROTOCOL_CONF_BY_CHAIN_AND_USER: `getProtocolConfig/userIdAndChainId`,
    LIST_ALL_PROTOCOL_CONFIGS: `listAllProtocolConfig`,
    LIST_ALL_GAS_REQUESTS: `listAllGasRequest`,


    LIST_ALL_WHITELISTED_CONTRACTS: `listAllWhitelistedContracts`,
    UPDATE_GAS_CONFIGURED_LIMIT: `updateGasLimitConfigured`,
    DEACTIVATE_WHITELISTED_CONTRACT: `deactivateWhitelistedContract`,
    ADD_WHITELISTED_CONTRACT_LINKED_DAPP: `addWhitelistedContractLinkedToDApp`,
    ADD_EXISTING_WHITELISTED_CONTRACT_AND_LINK_DAPP: `addExistingContractAsWhitelistedAndLinkToDApp`,


    LIST_ALL_FUNDED_TRANSACTIONS: `listAllFundedTransactions`,
};
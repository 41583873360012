import React from "react";
import {Typography, Space, Tooltip} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { Icons } from "../../../assets";
import { Card } from "./card";
import TruncateText from "../../../library/TrunucateText/truncate";


export const Summary = ({ data }) => {
    return (
        <div className="column-container p-0" style={{marginBottom: "50px"}}>
            <Typography.Text className="label-text d-inline text-left">
                Summary
                <p className="subheader mt-2">dApp configuration summary</p>
                {/*<Tooltip title={"dApp configuration summary"}><InfoCircleOutlined style={{cursor:"pointer"}}/></Tooltip>*/}
            </Typography.Text>
            {/*<Space direction="vertical" size={30} className="mt-3" style={{border: "1px solid #233C80", padding: "15px", borderRadius:"15px"}}>*/}
            <Space direction="vertical" size={30}>

                <div className="three-row-container mt-3">
                  <div className="card">
                    {/* <div className="card-svg-div"> */}
                      <Icons width="35px" height="35px" type="project_details" />
                    {/* </div> */}
                      <div className="header-text">dApp Details</div>
                      {/*<div className="content-text">{data.globalSetting ? data.globalSetting : "No"}</div>*/}
                      {data?.teamName && <div className="label-text ellipsis">Team Name: {data?.teamName}</div>}
                      <Tooltip title={data.title}> <div className="label-text ellipsis">dApp Name:<TruncateText text={data.title} maxLength={15}/></div></Tooltip>
                      <Tooltip title={data.description}><div className="label-text ellipsis">dApp Description: <TruncateText text={data.description} maxLength={15}/></div></Tooltip>
                      {/*<div className="label-text ellipsis">
                          Industry :
                          {data.industries !== undefined
                              ? data.industries
                                  .filter(industry => industry !== 'Others')
                                  .join(',')
                              : ''}
                          {data.industries.includes("Others") && data.otherIndustries !== undefined
                              ? data.industries.length > 1 ? `, ${data.otherIndustries}` : `${data.otherIndustries}`
                              : ''}
                      </div>*/}
                      {/*<div className="label-text ellipsis">Industry : {data.industries !== undefined ? data.industries.join(','): ""}</div>*/}
                  </div>
                  <div className="card">
                    <Icons width="35px" height="35px" type="blockchain" />
                    <div className="header-text">Node Configuration</div>
                    {/*<div className="label-text">Chosen Configuration</div>*/}
                    {data?.nodeconfig &&
                    data?.nodeconfig?.map((list, index) => (
                        <>
                        {list.BlockchainEndpoint !== undefined && (
                                    <div key={`block-${index}`} className="label-text ellipsis">
                                        Blockchain Protocol: {list.BlockchainEndpoint.EndpointName}
                                    </div>
                                )}

                                {list.StorageEndpoint !== undefined  && (
                                    <div key={`storage-${index}`} className="label-text ellipsis">
                                        Storage Protocol: {list.StorageEndpoint.EndpointName}
                                    </div>
                                )}

                                {list.DIEndpoint !== undefined  && (
                                    <div key={`di-${index}`}  className="label-text ellipsis">
                                        DID Protocol: {list.DIEndpoint.EndpointName}
                                    </div>
                                )}
                            {list.SCEndpoint !== undefined  && (
                                <div key={`sc-${index}`}  className="label-text ellipsis">
                                    Bundler Protocol: {list.SCEndpoint.EndpointName}
                                </div>
                            )}
                          {/*<Card key={index} value={list} />*/}
                        </>
                    ))}
                  </div>
                  <div className="card">
                    <Icons width="35px" height="35px" type="blockchain" />
                    <div className="header-text">Services</div>
                      {data?.services &&
                      data?.services?.map((list, index) => (
                          <>
                                      {list.DID_Manager !== undefined && list.DID_Manager.isRequired === true && (
                                          <div key={`did-${index}`} className="label-text ellipsis">
                                              {/*{list.DID_Manager.ServiceName}: Active*/}
                                              DID Manager: Active
                                          </div>
                                      )}

                                      {list.StorageManager !== undefined && list.StorageManager.isRequired === true && (
                                          <div key={`storeman-${index}`} className="label-text ellipsis">
                                              {/*{list.StorageManager.ServiceName}: Active*/}
                                              Storage Manager: Active
                                          </div>
                                      )}
                                      {list["SmartContractWallet"] !== undefined && list["SmartContractWallet"].isRequired === true && (
                                          <div key={`scWallet-${index}`} className="label-text ellipsis">
                                              {/*{list["NonCustodialWallet"].ServiceName}: Active*/}
                                              Smart Contract Wallet: Active
                                          </div>
                                      )}
                                  {list["NonCustodialWallet"] !== undefined && list["NonCustodialWallet"].isRequired === true && (
                                    <div key={`noncustwall-${index}`} className="label-text ellipsis">
                                      {/*{list["NonCustodialWallet"].ServiceName}: Active*/}
                                      Non Custodial Wallet: Active
                                    </div>
                                 )}
                          </>
                      ))}
                    {/*</div>*/}
                  </div>
                  <div className="card">
                    <Icons width="35px" height="35px" type="blockchain" />
                    <div className="header-text">Enabler</div>
                    {/*<div className="label-text">Chosen services</div>*/}
                    {/*<div className="row m-0">*/}
                    {data?.services &&
                    data?.services?.map((list, index) => (
                        <>
                        {list.SmartContractStudio !== undefined && list.SmartContractStudio.isRequired === true && (
                                    <div key={`smc-${index}`} className="label-text ellipsis">
                                        {/*{list.SmartContractStudio.ServiceName}: Active*/}
                                        Smart Contract Studio: Active
                                    </div>
                                )}

                                {list.EasyNFT !== undefined && list.EasyNFT.isRequired === true && (
                                    <div key={`easynft-${index}`} className="label-text ellipsis">
                                        {/*{list.EasyNFT.ServiceName}: Active*/}
                                        Easy NFT: Active
                                    </div>
                                )}

                                {list.FT_Manager !== undefined && list.FT_Manager.isRequired === true && (
                                    <div key={`ft-${index}`} className="label-text ellipsis">
                                        {/*{list.FT_Manager.ServiceName}: Active*/}
                                        FT Manager: Active
                                    </div>
                                )}

                        </>
                        // <Card key={index} value={list} />
                    ))}
                    {/*</div>*/}
                  </div>
                  <div className="card" style={{background:"#676767"}}>
                    {/* <div className="card-svg-div"> */}
                    <Icons width="35px" height="35px" type="project_details" />
                    {/* </div> */}
                    <div className="header-text">Global Settings</div>
                    <div className="label-text ellipsis">Datalake: OFF</div>
                    <div className="label-text ellipsis"> Gasless: OFF</div>
                  </div>
                </div>
            </Space>
        </div>
    );
};

export default Summary;

import React, {useRef, useState} from "react";
import '../enterprisePagestyles.less'

export const Faster = () => {

  return (
    <>
        <div className="tenx-section">
     <div className="faster-section">
       <h1 className="faster-heading">Unlock Web3 with <span className="faster-highlight">Krypcore</span>
       </h1>
       <div className="faster-content">Krypcore Web3 empowers developers and enterprises to build and deploy decentralized applications (dApps) on multiple blockchain networks. Our infrastructure includes endpoints, node options, and blockchain/storage support.</div><br/>
     </div>
        <div className="tenx-card-container">
            <div className="tenx-card">
                <p>10x</p>
                <p>Faster Cross Chain Integration</p>
            </div>
            <div className="tenx-card">
                <p>3x</p>
                <p>Scalable when Compared to Competitors</p>
            </div>
            <div className="tenx-card">
                <p>50%</p>
                <p>Reduction in Development Time</p>
            </div>
        </div>
        </div>
    </>
  );
};

export default Faster;

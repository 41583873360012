import { dateformatter } from "../../../deps";
import {Link} from "react-router-dom";
import {notification, Tooltip} from "antd";
import React from "react";
import {CopyOutlined, EyeOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {AccountSettings} from "../../../assets/menus/accountSettings";
import "./styles.less";

const onCopy = (apiKey) => {
  navigator.clipboard.writeText(apiKey);
  notification.success({
    message: "Copied to clipboard",
    duration: 3,
  });
};
export const columns =[
  {
    title: "",
    dataIndex: "",

  },
  {
    title: "Endpoint Name",
    dataIndex: "endpointName",
    render: (_, { endpointName,_id,infraType,protocolName }) => (
        <Link to={"/my-endpoints/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
          {endpointName}
        </Link>
    )
  },
  {
    title: "Protocol",
    dataIndex: "protocolName",
  },
  // {
  //   title: "Network",
  //   dataIndex: "network",
  // },
  {
    title: "",
    dataIndex: "",
  },

  {
    title: "User Endpoint",
    dataIndex: "userEndpoint",
    render: (_, { userEndpoint,_id,infraType,protocolName }) => (
        <>
        <Link to={"/my-endpoints/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
          {userEndpoint}
        </Link>
        </>
    ),
  },
  {
    title: "",
    dataIndex: "userEndpoint",
    render: (_, { userEndpoint,_id }) => (
        <a>
          <CopyOutlined onClick={() => onCopy(userEndpoint)} />
        </a>
    ),
  },

  // {
  //   title: "Linked dApp ",
  //   dataIndex: "projectID",
  // },

  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: 'Action',
    dataIndex: '',
    render: (_, { _id,infraType,protocolName }) => (
        <>
          <Link to={"/my-endpoints/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
            <AccountSettings width="20px" height="20px" />
          </Link>
        </>
    ),
  }
];
export const storageandDidcolumns = [
  {
    title: "Endpoint Name",
    dataIndex: "endpointName",
    render: (_, { endpointName,_id,infraType,protocolName }) => (
        <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
          {endpointName}
        </Link>
    )
  },
  {
    title: "Protocol",
    dataIndex: "protocolName",
  },
  {
    title: "User Endpoint",
    dataIndex: "userEndpoint",
    render: (_, { userEndpoint,_id,infraType,protocolName }) => (
        <>
        <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
          {userEndpoint}
        </Link>
          </>

    ),
  },
  {
    title: "",
    dataIndex: "userEndpoint",
    render: (_, { userEndpoint,_id }) => (
        <a>
          <CopyOutlined onClick={() => onCopy(userEndpoint)} />
        </a>
    ),
  },
  {
    title: "Gateway URL",
    dataIndex: "ipfsGatewayUrl",
    render: (_, { ipfsGatewayUrl,_id,infraType,protocolName }) => (
        <>
        <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
          {ipfsGatewayUrl}
        </Link>
          </>
    ),
  },
  {
    title: "",
    dataIndex: "ipfsGatewayUrl",
    render: (_, { ipfsGatewayUrl,_id,infraType }) => (
        <a>
          <CopyOutlined onClick={() => onCopy(ipfsGatewayUrl)} />
        </a>
    ),
  },
  // {
  //   title: "Endpoint Type",
  //   dataIndex: "endpointType",
  // },
  {
    title: "Linked dApp",
    dataIndex: "projectID",
  },
  // {
  //   title: "Created At",
  //   dataIndex: "createdAt",
  //   render: (_, { createdAt }) => (
  //       <span>{dateformatter(createdAt, false, true)}</span>
  //   ),
  // },
  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: 'Action',
    dataIndex: '',
    render: (_, { _id,infraType,protocolName }) => (
        <>
          <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
            <AccountSettings width="20px" height="20px" />
          </Link>
        </>
    ),
  }
];
export const Didcolumns = [
  {
    title: "Endpoint Name",
    dataIndex: "endpointName",
    render: (_, { endpointName,_id,infraType,protocolName }) => (
        <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
          {endpointName}
        </Link>
    )
  },
  {
    title: "Protocol",
    dataIndex: "protocolName",
  },
  {
    title: "User Endpoint",
    dataIndex: "userEndpoint",
    render: (_, { userEndpoint,_id,infraType,protocolName }) => (
        <>
          <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
            {userEndpoint}
          </Link>
        </>

    ),
  },
  {
    title: "",
    dataIndex: "userEndpoint",
    render: (_, { userEndpoint,_id }) => (
        <a>
          <CopyOutlined onClick={() => onCopy(userEndpoint)} />
        </a>
    ),
  },
  {
    title: "Linked dApp",
    dataIndex: "projectID",
  },

  {
    title: "Status",
    dataIndex: "status",
  },
  {
    title: 'Action',
    dataIndex: '',
    render: (_, { _id,infraType,protocolName }) => (
        <>
          <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType,protocolName:protocolName}}>
            <AccountSettings width="20px" height="20px" />
          </Link>
        </>
    ),
  }
];
export const EndpointTransaction =[
  {
    title: "Request Id",
    dataIndex: "requestId",
  },
  {
    title: "Service Name",
    dataIndex: "service",
  },
  {
    title: "Method Name",
    dataIndex: "methodName"
  },
  // {
  //   title: "Request",
  //   dataIndex: "request",
  // },
  // {
  //   title: "",
  //   dataIndex: 'request',
  //   key: 'x',
  //   render: (request) =>
  //       <a>
  //         <CopyRequest request={request}/>
  //       </a>
  // },
  {
    title: "Request Time",
    dataIndex: "requestTime",
    render: (_, { requestTime }) => (
        <span>{dateformatter(requestTime, false, true)}</span>
    ),
  },

  {
    title: "Status Code",
    dataIndex: "statusCode",
  },
  {
    title: "API Weightage",
    dataIndex: "apiWeightage",
  },
];

export const DAppcolumns = [
  {
    title: "dApp Name",
    dataIndex: "projectName",
    // render: (_, { endpointName,_id,infraType }) => (
    //     <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
    //       {endpointName}
    //     </Link>
    // )
  },
  {
    title: "App ID",
    dataIndex: "projectID",
  },
  {
    title: "Description",
    dataIndex: "description",
    // render: (_, { userEndpoint,_id,infraType }) => (
    //     <>
    //       <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
    //         {userEndpoint}
    //       </Link>
    //     </>

    // ),
  },
  // {
  //   title: "App ID",
  //   dataIndex: "userEndpoint",
  //   render: (_, { userEndpoint,_id }) => (
  //       <a>
  //         <CopyOutlined onClick={() => onCopy(userEndpoint)} />
  //       </a>
  //   ),
  // },
  {
    title: "Token",
    dataIndex: "token",
  },

  // {
  //   title: "Status",
  //   dataIndex: "status",
  // },
  {
    title: 'Action',
    dataIndex: '',
    render: (_, {projectID}) => (
        <>
           <Link to={`/dAppDetails-page/${projectID}`}>
            <button className="new-button">Manage dApp</button>
           </Link>
        </>
    ),
  }
];

import React from "react";

export const Dashboard = ({ width = "20px", height = "20px" }) => (
  /*<svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M2.25 11.9991L11.204 3.04409C11.644 2.60509 12.356 2.60509 12.795 3.04409L21.75 11.9991M4.5 9.74909V19.8741C4.5 20.4951 5.004 20.9991 5.625 20.9991H9.75V16.1241C9.75 15.5031 10.254 14.9991 10.875 14.9991H13.125C13.746 14.9991 14.25 15.5031 14.25 16.1241V20.9991H18.375C18.996 20.9991 19.5 20.4951 19.5 19.8741V9.74909M8.25 20.9991H16.5"
      stroke="white"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>*/
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M12.8125 7.875C14.5729 7.875 16 6.44791 16 4.6875C16 2.92709 14.5729 1.5 12.8125 1.5C11.0521 1.5 9.625 2.92709 9.625 4.6875C9.625 6.44791 11.0521 7.875 12.8125 7.875Z" stroke="#7D7575" stroke-width="1.5"/>
      <path d="M4.1875 7.875C5.94791 7.875 7.375 6.44791 7.375 4.6875C7.375 2.92709 5.94791 1.5 4.1875 1.5C2.42709 1.5 1 2.92709 1 4.6875C1 6.44791 2.42709 7.875 4.1875 7.875Z" stroke="#7D7575" stroke-width="1.5"/>
      <path d="M12.8125 16.5C14.5729 16.5 16 15.0729 16 13.3125C16 11.5521 14.5729 10.125 12.8125 10.125C11.0521 10.125 9.625 11.5521 9.625 13.3125C9.625 15.0729 11.0521 16.5 12.8125 16.5Z" stroke="#7D7575" stroke-width="1.5"/>
      <path d="M4.1875 16.5C5.94791 16.5 7.375 15.0729 7.375 13.3125C7.375 11.5521 5.94791 10.125 4.1875 10.125C2.42709 10.125 1 11.5521 1 13.3125C1 15.0729 2.42709 16.5 4.1875 16.5Z" stroke="#7D7575" stroke-width="1.5"/>
    </svg>
);

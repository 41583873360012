export const schemaJson = [
  {
    "type": "object",
    "properties": {
      "createERC20": {
        "type": "object",
        "properties": {
          "ERCStandard": {
            "type": "string",
            "title": "ERC Standard",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
          "quantity": {
            "type": "string",
            "title": "Quantity",
            "pattern": "^[0-9]+$",
            "required": true
          },
          "decimal": {
            "type": "string",
            "title": "Decimal",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
          "premintAddress": {
            "type": "string",
            "title": "Premint Address",
            "required": true
          },
          "tokenName": {
            "type": "string",
            "title": "Token Name",
            "required": true
          },
          "tokenSymbol": {
            "type": "string",
            "title": "Token Symbol",
            "required": true
          },
        }
      },
      "erc20-tokens": {
        "type": "object",
        "properties": {
          "page": {
            "type": "string",
            "title": "Page",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
          "instanceId": {
            "type": "string",
            "title": "Instance Id",
            "required": true
          },
          "pageSize": {
            "type": "string",
            "title": "Page Size",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
        }
      },
      "erc20-token": {
        "type": "object",
        "properties": {
          "page": {
            "type": "string",
            "title": "Page",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
          "pageSize": {
            "type": "string",
            "title": "Page Size",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
        }
      },
      "mintFT": {
        "type": "object",
        "properties": {
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
          "erc": {
            "type": "string",
            "title": "ERC",
            "required": true
          },
          "quantity": {
            "type": "string",
            "title": "Quantity",
            "required": true
          },
          "recipientAddress": {
            "type": "string",
            "title": "Recipient Address",
            "required": true
          },
        }
      },
      "approveFT": {
        "type": "object",
        "properties": {
          "approveAddress": {
            "type": "string",
            "title": "Approve Address",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
          "quantity": {
            "type": "string",
            "title": "Quantity",
            "required": true
          },

        }
      },
      "transferFT": {
        "type": "object",
        "properties": {
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
          "quantity": {
            "type": "string",
            "title": "Quantity",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": true
          },
          "recipientAddress": {
            "type": "string",
            "title": "Recipient Address",
            "required": true
          },
        }
      },
      "burnFT": {
        "type": "object",
        "properties": {
          "quantity": {
            "type": "string",
            "title": "Quantity",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": true
          },
          "burnAddress": {
            "type": "string",
            "title": "Burn Address",
            "required": true
          },
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
        }
      },
      "getTotalSupply": {
        "type": "object",
        "properties": {
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
        }
      },
        "balanceOf": {
            "type": "object",
            "properties": {
              "contractAddress": {
                "type": "string",
                "title": "Contract Address",
                "required": true
              },
              "address": {
                "type": "string",
                "title": "Address",
                "required": true
              },
            }
        },
      "createNFTCollection": {
        "type": "object",
        "properties": {
          "ERCStandard": {
            "type": "string",
            "title": "ERCStandard",
            "required": true
          },
          "collectionDescription": {
            "type": "string",
            "title": "Collection Description",
            "required": true
          },
          collectionImage: {
            type: 'string',
            title: 'Image',
            'x-component': 'image-upload', // Use the custom image upload field
            "required": true,
  //           "x-component-props":{
  //   listType: 'card'
  // },
          },
          // "collectionImage": {
          //   type: 'string',
          //   title: 'Collection Image',
          //   'x-component': 'upload' // Use a custom component for file upload
          // },
          "collectionName": {
            "type": "string",
            "title": "Collection Name",
            "required": true
          },
          "collectionSymbol": {
            "type": "string",
            "title": "Collection Symbol",
            "required": true
          },
          "isSoulBound": {
            "type": "boolean",
            "title": "Is Soul Bound",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
        }
      },
      "nft-collection-list": {
        "type": "object",
        "properties": {
          "page": {
            "type": "string",
            "title": "Page",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": true
          },
          "instanceId": {
            "type": "string",
            "title": "Instance Id",
            "required": true
          },
          "pageSize": {
            "type": "string",
            "title": "Page Size",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": true
          },
        }
      },
      "nft-collection": {
        "type": "object",
        "properties": {
          "page": {
            "type": "string",
            "title": "Page",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
          "id": {
            "type": "string",
            "title": "Id",
            "required": true
          },
          "pageSize": {
            "type": "string",
            "title": "Page Size",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
        }
      },
      "createNFT": {
        "type": "object",
        "properties": {
          "name": {
            "type": "string",
            "title": "Name",
            "required": true
          },
          "files": {
            type: 'string',
            title: 'File',
            'x-component': 'file-upload', // Use the custom image upload field
            "required": true,
          },
          "description": {
            "type": "string",
            "title": "Description",
            "required": true
          },
          "erc": {
            "type": "string",
            "title": "ERC",
            "required": true
          },
          "quantity": {
            "type": "string",
            "title": "Quantity",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": true
          },
          "recepientAddress": {
            "type": "string",
            "title": "Recipient Address",
            "required": true
          },
          "attributes": {
            "type": "string",
            "title": "Attributes",
            "required": false
          },
          "contractAddress": {
            "type": "string",
            "title": "Contract Address",
            "required": true
          },
          "custodialWalletAccessToken": {
            "type": "string",
            "title": "Custodial Wallet Access Token",
            "required": true
          },
        }
      },
      "nft-list": {
        "type": "object",
        "properties": {
          "page": {
            "type": "string",
            "title": "Page",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
          "contractAddress": {
            "type": "string",
            "title": "Address",
            "required": true
          },
          "pageSize": {
            "type": "string",
            "title": "Page Size",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
        }
      },
      "storefile": {
        "type": "object",
        "properties": {
          "files": {
            type: 'string',
            title: 'File',
            'x-component': 'storefile', // Use the custom image upload field
            "required": true,
          },
        }
      },
      "getFileDetails": {
        "type": "object",
        "properties": {
          "page": {
            "type": "string",
            "title": "Page",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
          "pageSize": {
            "type": "string",
            "title": "Page Size",
            "pattern": "^[0-9]+$", // Regular expression pattern to accept only numbers
            "required": false
          },
        }
      },
      "downloadFileFromIPFS": {
        "type": "object",
        "properties": {
          "id": {
            "type": "string",
            "title": "Id",
            "required": true
          },
        }
      },
      "updatePinStatus": {
        "type": "object",
        "properties": {
          "id": {
            "type": "string",
            "title": "Id",
            "required": true
          },
        }
      },
      "deployDevWalletContract": {
        "type": "object",
        "properties": {
          "service": {
            "type": "string",
            "title": "service",
            "required": true
          },
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "byteCode": {
            "type": "string",
            "title": "byteCode",
            "required": true
          },
          "abi": {
            "type": "string",
            "title": "abi",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "params",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "chainId",
            "required": true
          },
        }
      },
      "callDevWalletContract": {
        "type": "object",
        "properties": {
          "to": {
            "type": "string",
            "title": "to",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "chainId",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "method",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "params",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "contractABI",
            "required": true
          },
        }
      },
      "getDevWalletBalance": {
        "type": "object",
        "properties": {
          "chainId": {
            "type": "string",
            "title": "chainId",
            "required": true
          },
          "address": {
            "type": "string",
            "title": "address",
            "required": true
          },
        }
      },
      "signDevWalletAndSubmitGaslessTx": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "dAppId": {
            "type": "string",
            "title": "dAppId",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "chainId",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "to",
            "required": true
          },
          "contractAbi": {
            "type": "string",
            "title": "contractAbi",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "method",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "params",
            "required": true
          },
        }
      },
      "createDevWalletAndExecuteTx": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "to",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "chainId",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "method",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "params",
            "required": true
          },
          "isContractTxn": {
            "type": "string",
            "title": "isContractTxn",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "contractABI",
            "required": true
          },
        }
      },
      "signDevWalletEIP712Tx": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "data": {
            "type": "string",
            "title": "data",
            "required": true
          },
        }
      },
      "verifyDevWalletSignatureOffChain": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "message": {
            "type": "string",
            "title": "message",
            "required": true
          },
          "signature": {
            "type": "string",
            "title": "signature",
            "required": true
          },
        }
      },
      "signDevWalletMessage": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "message": {
            "type": "string",
            "title": "message",
            "required": true
          },
        }
      },
      "signDevWalletTxHash": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "txnHash": {
            "type": "string",
            "title": "txnHash",
            "required": true
          },
        }
      },
      "estimateDevWalletGas": {
        "type": "object",
        "properties": {
          "privateKey": {
            "type": "string",
            "title": "privateKey",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "to",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "chainId",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "method",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "params",
            "required": true
          },
          "isContractTxn": {
            "type": "string",
            "title": "isContractTxn",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "contractABI",
            "required": true
          },
        }
      },
      "createWallet": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "name": {
            "type": "string",
            "title": "Name",
            "required": true
          },
          "algorithm": {
            "type": "string",
            "title": "Algorithm",
            "required": true
          },
        }
      },
      "getBalance": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "Chain Id",
            "required": true
          },
        }
      },
      "signMessage": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "message": {
            "type": "string",
            "title": "Message",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
        }
      },
      "verifySignatureOffChain": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "message": {
            "type": "string",
            "title": "Message",
            "required": true
          },
          "signature": {
            "type": "string",
            "title": "Signature",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
        }
      },
      "deployContract": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "byteCode": {
            "type": "string",
            "title": "Byte Code",
            "required": true
          },
          "abi": {
            "type": "string",
            "title": "ABI",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "Params",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
        }
      },
      "submitTransaction": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "To",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "ChainId",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "Params",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "Method",
            "required": true
          },
          "isContractTxn": {
            "type": "string",
            "title": "Is Contract Txn",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "Contract ABI",
            "required": true
          },
        }
      },
      "estimateGas": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "To",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "Params",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "Method",
            "required": true
          },
          "isContractTxn": {
            "type": "string",
            "title": "Is Contract Txn",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "Contract ABI",
            "required": true
          },
        }
      },
      "callContract": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "To",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "WalletId",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "Params",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "Method",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "Contract ABI",
            "required": true
          },
        }
      },
      "signAndSubmitGaslessTxn": {
        "type": "object",
        "properties": {
          "walletURL": {
            "type": "string",
            "title": "Wallet URL",
            "required": true
          },
          "to": {
            "type": "string",
            "title": "To",
            "required": true
          },
          "walletId": {
            "type": "string",
            "title": "Wallet Id",
            "required": true
          },
          "userId": {
            "type": "string",
            "title": "User Id",
            "required": true
          },
          "method": {
            "type": "string",
            "title": "Method",
            "required": true
          },
          "contractABI": {
            "type": "string",
            "title": "Contract ABI",
            "required": true
          },
          "dappId": {
            "type": "string",
            "title": "dApp Id",
            "required": true
          },
          "chainId": {
            "type": "string",
            "title": "Chain Id",
            "required": true
          },
          "params": {
            "type": "string",
            "title": "Params",
            "required": true
          },
        }
      },
    }
  }
]





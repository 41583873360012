import React, {useEffect, useState} from "react";
import { useNavigate } from "react-router-dom";
import {gaslessProtocolConfigColumn} from "../utils/columns";
import {Filters, PaginationSection, TableContent} from "../../../library";
import {apiCaller} from "../utils/utils";
import {getUserDetails} from "../../../deps";
import {GASLESS_URLS} from "../utils/constants";
import Skeleton from "react-loading-skeleton";

export const GASLESS_BACKEND_URL = process.env.REACT_APP_GATEWAY_URL +"/api/v0/gasless/"
const GaslessProtocolConfig = (callBack,{isLoading}) => {
    const userDetails = getUserDetails()
    const navigate = useNavigate();
    const [protocolConfigDetailsArr, setProtocolConfigDetailsArr] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);

    const [pageSetting, setSetting] = useState({
        page: 1,
        limit: 5,
        searchValue: "",
        searchIn:[],
        filterValue: "",
        filterIn:[],
        userId:userDetails.profile.developerId,
    });

    const last =
        pageSetting.page * pageSetting.limit > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.limit;

    const startIndex = (pageSetting.page - 1) * pageSetting.limit;


    const onNavigate = () => {
        navigate("/gasless/enableGasless");
    };

    const listAllProtocolConfig =  async () => {
        let resp = await apiCaller("POST", GASLESS_URLS.LIST_ALL_PROTOCOL_CONFIGS , pageSetting, false)
        callBack.callBack(false)
        setProtocolConfigDetailsArr(resp.data.data)
        setTotalCount(resp.data.total)
    }


    useEffect(()=>{
        listAllProtocolConfig().then()
    },[])

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
    };

    const onPerPageChange = (value) => {
        pageSetting.page = 1;
        pageSetting.limit = Number(value);
        setSetting({ ...pageSetting });
    };

    return (
        <>
            {/* Header section */}
            <div className="gasless-header-section">
                <div className="header-text">Protocol & Gas Limit Configurations</div>
                <div className="gasless-filter">
                    {/* Filters component */}
                    {isLoading?(<Skeleton height={45} width={220} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <Filters
                        showCreateBtn={true}
                        showFilters={false}
                        showSearch={false}
                        btnText={<span className="createBtn1">Enable Gasless </span>}
                        onNavigate={onNavigate}
                    />
                    )}
                </div>
            </div>

            {/* Table content */}
            <div className="table-container marginBtm30 gasless-table">
            {isLoading?(<Skeleton height={210} style={{marginTop:'30px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <TableContent
                    rowKey="projectId"
                    data={protocolConfigDetailsArr}
                    columns={gaslessProtocolConfigColumn} // You should have defined the columns elsewhere
                    isDashboard={false}
                    pagination={false}
                />
            )}
                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={[5, 10, 50, 100]}
                    totalCount={totalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.limit}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />
            </div>



        </>
    );
};

export default GaslessProtocolConfig;

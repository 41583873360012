import React from "react";

export const Logo = ({ width = "100px", height = "36px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 100 36"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M17.5392 17.8733L8.91309 8.93663L17.5392 0V17.8733Z"
      fill="#E1E1E1"
    />
    <path
      d="M8.91309 8.93652L17.5392 17.8732L8.91309 26.8097V8.93652Z"
      fill="#E1E1E1"
    />
    <path
      d="M17.5396 35.7586L26.1714 26.8219L17.5396 17.8853V35.7586Z"
      fill="#E1E1E1"
    />
    <path
      d="M17.5392 17.8853L8.91309 26.8219L17.5392 35.7586V17.8853Z"
      fill="#E1E1E1"
    />
    <path
      d="M8.91267 8.94873L0.280762 17.8854L8.91267 26.822V8.94873Z"
      fill="#E1E1E1"
    />
    <path
      d="M0.280762 17.8857L8.91267 8.94908L0.280762 0.0124512V17.8857Z"
      fill="#E1E1E1"
    />
    <path
      d="M8.91309 26.822L17.5392 17.8854L8.91309 8.94873V26.822Z"
      fill="#E1E1E1"
    />
    <path
      d="M0.280762 35.7406L8.91267 26.8039L0.280762 17.8674V35.7406Z"
      fill="#E1E1E1"
    />
    <path
      d="M17.5396 17.8857C20.4324 14.8794 23.3255 11.8672 26.2183 8.86084C23.3255 5.90745 20.4324 2.95995 17.5396 0.0124512V17.8857Z"
      fill="#E1E1E1"
    />
    <path
      d="M17.5396 35.7522C20.4089 32.7813 23.2842 29.8102 26.1537 26.8391L35.3666 35.7522H17.5396Z"
      fill="#E1E1E1"
    />
    <path
      d="M35.0905 0.0581055C32.1038 3.01737 29.1227 5.97664 26.1359 8.93591C23.2488 5.97664 20.3675 3.01737 17.4805 0.0581055C23.3485 0.0581055 29.2224 0.0581055 35.0905 0.0581055Z"
      fill="#E1E1E1"
    />
    <path
      d="M39.8695 18.6263L37.7217 20.862V26.804H35.4097V9.21313H37.7217V17.9144L45.5204 9.21313H48.3136L41.4069 16.9791L48.8535 26.804H46.0838L39.8695 18.6263Z"
      fill="#E1E1E1"
    />
    <path
      d="M56.8409 15.7376C56.5004 15.6788 56.1367 15.6553 55.7435 15.6553C54.2824 15.6553 53.2907 16.2789 52.7685 17.5262V26.804H50.5386V13.7314H52.7097L52.745 15.2434C53.4785 14.0786 54.5113 13.4902 55.855 13.4902C56.2893 13.4902 56.6178 13.5491 56.8409 13.6608V15.7376Z"
      fill="#E1E1E1"
    />
    <path
      d="M63.3482 23.5332L66.388 13.7375H68.7762L63.536 28.828C62.7263 31.0048 61.4353 32.0874 59.669 32.0874L59.2465 32.052L58.4132 31.8932V30.0811L59.0176 30.1283C59.7747 30.1283 60.3615 29.9753 60.7839 29.6694C61.2064 29.3634 61.5527 28.8044 61.8285 27.9926L62.3213 26.6631L57.668 13.7375H60.1032L63.3482 23.5332Z"
      fill="#E1E1E1"
    />
    <path
      d="M81.7681 20.4148C81.7681 22.4034 81.3164 24.0094 80.4068 25.2214C79.4973 26.4392 78.2707 27.0452 76.7216 27.0452C75.1373 27.0452 73.8932 26.5392 72.9837 25.5333V31.8283H70.7539V13.7314H72.79L72.8957 15.1787C73.8052 14.0491 75.0668 13.4902 76.6806 13.4902C78.2474 13.4902 79.4854 14.0845 80.401 15.267C81.3105 16.4495 81.7681 18.0968 81.7681 20.2089V20.4148ZM79.5442 20.1618C79.5442 18.6852 79.2332 17.5261 78.6053 16.6731C77.9774 15.82 77.1206 15.3905 76.0291 15.3905C74.6795 15.3905 73.6643 15.9906 72.9895 17.1908V23.4387C73.6585 24.633 74.6795 25.2272 76.0527 25.2272C77.1206 25.2272 77.9716 24.8037 78.5994 23.9506C79.2273 23.0976 79.5442 21.8326 79.5442 20.1618Z"
      fill="#E1E1E1"
    />
    <path
      d="M99.0013 21.3209C98.8018 23.1743 98.1387 24.5921 97.0179 25.5746C95.8971 26.5571 94.4066 27.0513 92.5406 27.0513C91.2379 27.0513 90.0878 26.7277 89.0901 26.0747C88.0926 25.4216 87.318 24.498 86.7722 23.3037C86.2265 22.1094 85.9507 20.7386 85.939 19.203V16.9204C85.939 15.3554 86.2148 13.9728 86.7605 12.7668C87.3063 11.5607 88.0926 10.6253 89.1136 9.97225C90.1405 9.31333 91.3142 8.98975 92.6521 8.98975C94.5298 8.98975 96.0144 9.49571 97.1059 10.5135C98.1914 11.5254 98.8253 12.9315 99.0013 14.7318H97.5049C97.1353 11.7431 95.5156 10.2488 92.6463 10.2488C91.0559 10.2488 89.7884 10.843 88.8437 12.0373C87.8989 13.2316 87.4295 14.8789 87.4295 16.9851V19.1383C87.4295 21.168 87.8872 22.7919 88.8085 23.9978C89.7297 25.2098 90.9738 25.8099 92.5406 25.8099C94.0897 25.8099 95.2575 25.4393 96.0497 24.6922C96.836 23.9449 97.323 22.8271 97.5049 21.3387H99.0013V21.3209Z"
      fill="#E1E1E1"
    />
    <g opacity="0.16">
      <path
        d="M17.2554 17.885L8.62939 8.94838L17.2554 0.00585938V17.885Z"
        fill="#E1E1E1"
      />
      <path
        d="M8.62939 8.94873L17.2554 17.8854L8.62939 26.822V8.94873Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.2559 35.7703L25.8879 26.8336L17.2559 17.8911V35.7703Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.2554 17.8911L8.62939 26.8336L17.2554 35.7703V17.8911Z"
        fill="#E1E1E1"
      />
      <path
        d="M8.63192 8.95459L0 17.8912L8.63192 26.8337V8.95459Z"
        fill="#E1E1E1"
      />
      <path d="M0 17.8906L8.63192 8.95397L0 0.017334V17.8906Z" fill="#E1E1E1" />
      <path
        d="M8.62939 26.8337L17.2554 17.8912L8.62939 8.95459V26.8337Z"
        fill="#E1E1E1"
      />
      <path d="M0 35.7525L8.63192 26.8158L0 17.8733V35.7525Z" fill="#E1E1E1" />
      <path
        d="M17.2559 17.8916C20.1489 14.8852 23.0418 11.8789 25.9348 8.86669C23.0418 5.91919 20.1489 2.97168 17.2559 0.0183105V17.8916Z"
        fill="#E1E1E1"
      />
      <path
        d="M17.2559 35.7647C20.1254 32.7936 23.0007 29.8225 25.8702 26.8516L35.083 35.7647H17.2559Z"
        fill="#E1E1E1"
      />
      <path
        d="M34.8063 0.0646973C31.8194 3.02397 28.8327 5.98324 25.8517 8.94249C22.9647 5.98324 20.0833 3.02397 17.1963 0.0646973C23.0643 0.0646973 28.9382 0.0646973 34.8063 0.0646973Z"
        fill="#E1E1E1"
      />
    </g>
  </svg>
);

import React from "react";

export const Hedera = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_2583_5546)">
      <path
        d="M12 0C8.8174 0 5.76516 1.26428 3.51472 3.51472C1.26428 5.76516 0 8.8174 0 12C0 15.1826 1.26428 18.2348 3.51472 20.4853C5.76516 22.7357 8.8174 24 12 24C15.1826 24 18.2348 22.7357 20.4853 20.4853C22.7357 18.2348 24 15.1826 24 12C24 8.8174 22.7357 5.76516 20.4853 3.51472C18.2348 1.26428 15.1826 0 12 0ZM16.9571 17.3963H15.3759V14.01H8.6224V17.3877H7.0498V6.6037H8.631V9.9882H15.3845V6.6037H16.9657L16.9571 17.3963ZM15.3759 11.1371H8.6224V12.8612H15.3759V11.1371Z"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip0_2583_5546">
        <rect width="24" height="24" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

export default Hedera;

import React from "react";

export const Binance = ({ width = "50px", height = "50px" }) => (
  <svg
    version="1.0"
    xmlns="http://www.w3.org/2000/svg"
    width={width}
    height={height}
    viewBox="0 0 512.000000 512.000000"
    preserveAspectRatio="xMidYMid meet"
  >
    <g
      transform="translate(0.000000,512.000000) scale(0.100000,-0.100000)"
      fill="#f3bc19"
      stroke="none"
    >
      <path
        d="M0 2560 l0 -2560 2560 0 2560 0 0 2560 0 2560 -2560 0 -2560 0 0
-2560z m3103 1222 l537 -537 -200 -200 -200 -200 -340 340 -340 340 -340 -340
-340 -340 -200 200 -200 200 537 537 c296 296 540 538 543 538 3 0 247 -242
543 -538z m-1703 -1027 l195 -195 -195 -195 c-107 -107 -199 -195 -205 -195
-6 0 -98 88 -205 195 l-195 195 195 195 c107 107 199 195 205 195 6 0 98 -88
205 -195z m1360 0 l195 -195 -198 -198 -197 -197 -197 197 -198 198 195 195
c107 107 197 195 200 195 3 0 93 -88 200 -195z m1370 0 l195 -195 -195 -195
c-107 -107 -199 -195 -205 -195 -6 0 -98 88 -205 195 l-195 195 195 195 c107
107 199 195 205 195 6 0 98 -88 205 -195z m-1908 -822 l338 -338 340 340 340
340 200 -200 200 -200 -540 -540 -540 -540 -540 540 -540 540 197 197 c109
109 200 198 203 198 3 0 157 -152 342 -337z"
      />
    </g>
  </svg>
);

export default Binance;

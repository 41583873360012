import React from "react";

export const DropdownUpArrow = ({ width = "12px", height = "12px" }) => (
  <svg
    width={width}
    height={height}
    paddingBottom="2px"
    viewBox="0 0 12 7"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6.00078 0.446094C5.86745 0.446094 5.73845 0.471094 5.61378 0.521094C5.48845 0.571095 5.38411 0.637761 5.30078 0.721094L0.700781 5.32109C0.517448 5.50443 0.425781 5.73776 0.425781 6.02109C0.425781 6.30443 0.517448 6.53776 0.700781 6.72109C0.884114 6.90443 1.11745 6.99609 1.40078 6.99609C1.68411 6.99609 1.91745 6.90443 2.10078 6.72109L6.00078 2.82109L9.90078 6.72109C10.0841 6.90443 10.3174 6.99609 10.6008 6.99609C10.8841 6.99609 11.1174 6.90443 11.3008 6.72109C11.4841 6.53776 11.5758 6.30443 11.5758 6.02109C11.5758 5.73776 11.4841 5.50443 11.3008 5.32109L6.70078 0.721094C6.60078 0.621094 6.49245 0.550427 6.37578 0.509094C6.25911 0.467094 6.13411 0.446094 6.00078 0.446094Z"
      fill="#E3E3E3"
    />
  </svg>
);

import React from "react";
import './NewLandingPage.less'
import rightIcon from './Icons/Group 189.svg'
import { useInView } from "react-intersection-observer";

export const ServicesPage = () => {

    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.5 // Trigger animation when 50% of the element is visible
      });

    return (
        <div className="bg-white">
            <div className="startK-container-new" id="start-with-krypCore" style={{backgroundColor:'white'}}>
                <div className="startK-heading">
                    <p className="text-uppercase">Features</p>
                    <h2 ref={ref} className={`serviceheaderr ${inView?'animated animatedFadeInUp fadeInUp':'hidden'}`}>Develop feature-rich Web3 Applications</h2>
                </div>
                <div class="container-fluid  ">
                    <div  className="row w-100% justify-content-between services-padding">
                        <div class="col-md-3 col-xs-12 col-sm-6  text-dark mt-4">
                            <img  src={rightIcon} alt="Wallet-Solutions-for-dApps" className="mb-4"/>
                            <h4 class="column-header mb-sm-2 mb-xl-5" >Wallets</h4>
                            <p class="column-subheader text-left">
                                Choose from various wallet solutions such as custodial, non-custodial or smart contract wallets</p>
                        </div>
                        <div class="col-md-3 col-xs-12   col-sm-6 mt-4">
                           <img  src={rightIcon} alt="NFT-Functionality-Integration" className="mb-4"/>
                            <h4 class="column-header  mb-sm-2 mb-xl-5">NFT Studio</h4>
                            <p class="column-subheader text-left">
                               Leverage our NFT APIs to effortlessly build and integrate NFT functionality in your dApps
                            </p>
                        </div>
                        <div class="col-md-3 col-xs-12   col-sm-6 mt-4">
                           <img  src={rightIcon} alt="Fungible-Token-Management-Tools" className="mb-4"/>
                            <h4 class="column-header  mb-sm-2 mb-xl-5">FT Manager</h4>
                            <p class="column-subheader text-left">Tools for seamless creation and management of Fungible tokens within your dApps</p>
                        </div>
                        <div class="col-md-3 col-xs-12   col-sm-6 mt-4">
                           <img  src={rightIcon} alt="Decentralized-Identifier-Integration" className="mb-4"/>
                            <h4 class="column-header  mb-sm-2 mb-xl-5">DID Manager</h4>
                            <p class="column-subheader text-left">Seamlessly integrate and manage DIDs, enhancing privacy, security  and control with our DID API</p>
                        </div>
                    </div>
                  
                </div>
            </div>
        </div>
    );
};

export default ServicesPage;

import {Input, Modal, notification, Space, Spin} from "antd";
import {Menus} from "../../../assets/menus";
import React, {useState} from "react";
import {
    getPATTokenDetails,
    getUserDetails,
    onKeyPressAddressValidation,
    onKeyPressInputValidation
} from "../../../deps";
import {useNavigate, useParams} from "react-router-dom";
import {fetchApi} from "../../../_config/api";
import Web3 from "web3";
import {LoadingOutlined} from "@ant-design/icons";

export const LoadContract = ({isLoadContractOpen,loadContractToogleState}) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const formRef = React.useRef(null);
    const params = useParams();
    const navigate = useNavigate();
    const {
        profile: { enterpriseId, subscriptionId },
    } = getUserDetails();
    const token = getPATTokenDetails();
    const authToken = token.patToken; // subscriptionId + "_" +
    const [chains, setChains] = useState([]);
    const [data, setData] = useState({});
    const [isDeploying, setIsDeploying] = useState(false);
    const [instanceList, setInstanceList] = useState([]);
    const [instance, setInstance] = useState(null);
    const [projectList, setProjectList] = useState([]);
    const [projectId, setProjectId] = React.useState("");

    // React.useEffect(() => {
    //     getAllInstance();
    //
    // }, []);

    React.useEffect(() => {
        getProjectList();
    }, []);
    React.useEffect(() => {
        if(projectId !== ""){
            getChainList();
        }
    }, [projectId]);

    const getChainList = async () => {
        const filter = {
            and: [
                {
                    key: "projectID",
                    value: projectId,
                    opt: "eq",
                },
                {
                    key: "subscriptionId",
                    value: subscriptionId,
                    opt: "eq",
                },
                {
                    key: "infraType",
                    value: "blockchainNode",
                    opt: "eq",
                },
                {
                    key: "status",
                    value: "ACTIVE",
                    opt: "eq",
                },
            ],
        };

        const Options = () => {
            return {
                select: "",
                sort: "",
            };
        };

        let requestLink = `endpoints?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({
            method: "GET",
            url: requestLink,
            isDevApp: false,
        })
            .then((response) => {
                setChains(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const getProjectList = async () => {
        fetchApi({
            method: "GET",
            url: "developer/project-list",
            isDevApp: false,
        })
            .then((response) => {
                setProjectList(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getAllInstance = () => {
        // showLoader(true);
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify({ "services": ["smart-contract-studio"] })
        };

        fetch(`${gwURL}/api/v0/instance/getInstance`, options)
            .then(response => response.json())
            .then((response) => {
                // showLoader(false);
                if (response.Status === "SUCCESS") {
                    const { Data = [] } = response
                    if (response.Data) {
                        setInstanceList([...Data]);

                        const instanceObj = Data.find(e => e.instanceId === params?.instanceId);
                        if (instanceObj && !instanceObj?.instanceId) {
                            notification.error({
                                message: "instanceId not found",
                                duration: 3,
                            });
                        }
                        setInstance(instanceObj);

                    } else {
                        setInstanceList([]);
                    }
                }
            })
            .catch((response) => {
                setInstanceList([]);
                // showLoader(false);
            });
    };

    const deploy = () => {
        if (data.name === "" || data.name === undefined) {
            notification.error({
                message: "Name is mandatory",
                duration: 2,
            });
            return false;
        }

        if (data.chain === "" || data.chain === undefined) {
            notification.error({
                message: "chainValue is mandatory",
                duration: 2,
            });
            return false;
        }

        if (data.address === "" || data.address === undefined) {
            notification.error({
                message: "Contract address is mandatory",
                duration: 2,
            });
            return false;
        }

        if (data.contractABIArrayAsBase64 === "" || data.contractABIArrayAsBase64 === undefined) {
            notification.error({
                message: "Abi is mandatory",
                duration: 2,
            });
            return false;
        }

        var o = JSON.parse(data.contractABIArrayAsBase64);
        if (o && typeof o !== "object") {
            notification.error({
                message: "Abi should be JSON String",
                duration: 2,
            });
            return false;
        }

        let contractAddress = Web3.utils.isAddress(data.address);
        if (contractAddress == false) {
            notification.error({
                message: "Contract Address shoud be Valid",
                duration: 2,
            });
            return false;
        }

        const accessBody = {
            serviceInstance: instance?.instanceKey,
            name: data.name,
            chainId: data.chain,
            contractAddress: data.address,
            contractABIArrayAsBase64: btoa(data.contractABIArrayAsBase64),
        };

        setIsDeploying(true);
        fetchApi({
            method: "POST",
            url: `smart-contract-studio/contracts/load-existing`,
            data: accessBody,
            isDevApp: false,
        })
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    notification.success({
                        message: "Contract added successfully",
                        duration: 2,
                    });
                    setIsDeploying(false);
                    // allToggleState();
                    navigate(`/smart-contract-studio/${instance?.instanceId}/contracts`);
                } else {
                    notification.error({
                        message: response.message,
                        duration: 2,
                    });
                    setIsDeploying(false)
                }
            })
            .catch((error) => {
                setIsDeploying(false);
                notification.error({
                    message: error.toString(),
                    duration: 3,
                });
            });
    };

    const onTextChange = (e) => {
        const { name, value } = e.target;
        onChange(name, value);

    };

    const onSelectChange = (e) => {
        onChange(e.target.name, e.target.value);
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });
        if(name === "dApp"){
            setProjectId(value);
        }
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    return (
        <>
            <Modal maskStyle={{backgroundColor: '#CFCFCF80'}} centered title="" footer={null} closable={false} open={isLoadContractOpen}>
                <div>
                    <div className="close-icon" onClick={loadContractToogleState}>
                        <Menus type="close" />
                    </div>
                    <div className="add-contract-modal-title">Load Contract</div>
                    <div className="add-contract-container">
                        <Space
                            direction="vertical"
                            size={15}
                            style={{ display: "flex", marginTop: "5px", width:"400px" }}
                        >
                            <select
                                className="load-contract-dropdown-filter load-contract-input-container"
                                placeholder="Choose dApp"
                                name="dApp"
                                onChange={onSelectChange}
                            >
                                <option value="">Choose dApp</option>
                                {projectList && projectList.length > 0 && projectList.map((project, ind) => (
                                    <option key={ind} value={project.projectID}>{project.projectName}</option>
                                ))}
                            </select>


                            <select
                                className="load-contract-dropdown-filter load-contract-input-container"
                                placeholder="Choose Chain for Collection"
                                name="chain"
                                onChange={onSelectChange}
                            >
                                <option value="">Choose Chain for Collection</option>
                                {chains && chains.length > 0 && chains.map((chain, ind) => (
                                    <option key={ind} value={chain.chainId}>
                                        {chain.protocolName}
                                    </option>
                                ))}
                            </select>

                            <Input
                                className="load-contract-input-container"
                                placeholder="Name"
                                name="name"
                                onChange={onTextChange}
                                maxLength={100}
                                onKeyPress={onKeyPressInputValidation}
                                style={{
                                    height:"60px"
                                }}
                            />

                            <Input
                                className="load-contract-input-container"
                                placeholder="Contract Address"
                                name="address"
                                onChange={onTextChange}
                                maxLength={42}
                                onKeyPress={onKeyPressAddressValidation}
                                style={{
                                    height:"60px"
                                }}
                            />

                            <Input
                                className="load-contract-input-container"
                                placeholder="Abi"
                                name="contractABIArrayAsBase64"
                                onChange={onTextChange}
                                style={{
                                    height:"60px"
                                }}
                            />
                            <div style={{display:"flex",justifyContent: "center"}}>
                                <button disabled={isDeploying} className="load-contract-submit-button" onClick={deploy}>
                                    {isDeploying ? <Spin indicator={antIcon} /> : "Load"}
                                </button>
                            </div>
                        </Space>
                    </div>
                </div>
            </Modal>
        </>
    );
}
import React, {useEffect, useState} from "react";

import { DataLakeSetup } from './setup/datalake-setup'
import { DataLakeReport } from './report/datalake-report'


export const DataLakePage = ({showLoader,isLoading }) => {

    return (
        <>
            <DataLakeSetup/>

            <DataLakeReport/>
        </>
    );
}
export default DataLakePage;

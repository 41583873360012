// Essentials
import React, {useEffect, useState} from "react";
//Utils
import "../nftstudio/styles.less";
import './style.less'
import {Modal, Space, Input, Steps, Collapse, notification} from "antd";
import { Menus } from "../../assets";
import fetchApi from "../../_config/api";
import {onKeyPressNumberValidation, onKeyPressDateValidation, DEVELOPERROLE, getUserDetails} from "../../deps";

const { Step } = Steps;
const { Panel } = Collapse;
export const PlanPopup = ({
  isOpen,
  toggleState,
  plan,
    handlecancel,
    reload
}) => {
  const [planName, setPlanName] = useState("");
  const [stepSize, setStepSize] = React.useState(0);

  const [data, setData] = useState({
    paymentMethod: "",
    cardNumber: "",
    expiryDate:"",
    cvv:"",
    billingAddress:"",
  });
  const [error, setError] = React.useState({
    paymentMethod: "",
    cardNumber: "",
    expiryDate:"",
    cvv:"",
    billingAddress:""
  });
  const [planDetails, setPlanDetails] = React.useState({})
  const [serviceFeatures, setServiceFeatures] = React.useState({})

  const [planothers, setPlanothers] = React.useState({
    planNameH: "",
    price: "",
    duration:"",
    description:""
    })
  const [planDropbox, setPlandropbox] = useState([]);
  const [activePlan, setActivePlan] = useState("");
  const [features, setFeatures] = useState([]);
  const [featuresdetails, setFeaturesDetails] = useState([]);


  const  getSubscription = () =>{
    return fetchApi({
      method: "POST",
      url: `subscription/plan/getAll`,
      isDevApp: false,
    }).then((response) => {
      if (response.statusCode === 200 || response.statusCode === 201) {
        let planName = response.doc.map((planName) => ({
          label: `${planName.planName}`,
          value: planName.subscriptionPlanId,
        }));
        setPlandropbox([...planName])
        removeSecond(planDropbox);

        //Getting plan Comparison Table Header
        const {doc = []} = response;
        let features = doc.map((planName) => ({
          label: `${planName.planName}`,
        }));
        let p = [{label: ""}]
        p.push(...features)
        // setFeaturesDetails(p)
        setFeaturesDetails([...features])
       //Complete plan Comparison Table Header

       //Getting plan Comparison Table Row Data Method Starts
        let s = []
        let m = []
        let n = []
        let k = []

        //fetching plan object keys
        s.push(Object.keys(response.doc[0].serviceFeatures))

        //fetching plan object values
        if (response.doc !== null) {
          if (response.doc[0]) {
            let obj = response.doc[0].serviceFeatures
            let generalfeatures = response.doc[0].generalFeatures
            m.push(response.doc[0].description)
            Object.entries(generalfeatures).map(([key, value], index) => (
                Array.isArray(value) ?
                    value.map((list, index) => (
                        m.push(list)
                    )) : m.push(value)
            ))
            Object.entries(obj).map(([key, value], index) => (
                typeof value === "object" && value.hasOwnProperty('description') !== true ? (
                    Object.entries(value).map(([nestedKey, nestedValue], nestedIndex) => (
                        m.push(nestedValue.description)

                    ))
                ) : typeof value !== "object" && value.hasOwnProperty('description') !== true ? (
                    m.push(value)
                ) : (
                    m.push(value.description)
                )
            ))
          }
          if (response.doc[1]) {

            let obj1 = response.doc[1].serviceFeatures
            let generalfeatures1 = response.doc[1].generalFeatures
            n.push(response.doc[1].description)
            Object.entries(generalfeatures1).map(([key, value], index) => (
                Array.isArray(value) ?
                    value.map((list, index) => (
                        n.push(list)
                    )) : n.push(value)
            ))
            Object.entries(obj1).map(([key, value], index) => (
                typeof value === "object" && value.hasOwnProperty('description') !== true ? (
                    Object.entries(value).map(([nestedKey, nestedValue], nestedIndex) => (
                        n.push(nestedValue.description)

                    ))
                ) : typeof value !== "object" && value.hasOwnProperty('description') !== true ? (
                    n.push(value)
                ) : (
                    n.push(value.description)
                )
            ))
          }
          // console.log("m", n)
          if (response.doc[2] ) {
            let obj2 = response.doc[2].serviceFeatures
            let generalfeatures2 = response.doc[2].generalFeatures
            k.push(response.doc[2].description)
            Object.entries(generalfeatures2).map(([key, value], index) => (
                Array.isArray(value) ?
                    value.map((list, index) => (
                        k.push(list)
                    )) : k.push(value)
            ))
            Object.entries(obj2).map(([key, value], index) => (
                typeof value === "object" && value.hasOwnProperty('description') !== true ? (
                    Object.entries(value).map(([nestedKey, nestedValue], nestedIndex) => (
                        k.push(nestedValue.description)

                    ))
                ) : typeof value !== "object" && value.hasOwnProperty('description') !== true ? (
                    k.push(value)
                ) : (
                    k.push(value.description)
                )
            ))
          }
        }

        //mapping values for keys to render the data in table row
        let o = []
        // o.push(s)
        if(m.length >0){
          o.push(m)
        }if(n.length >0){
          o.push(n)
        }if(k.length >0){
          o.push(k)
        }
        setFeatures(o)
      }
    });

  }
  const removeSecond = () => {
    setPlandropbox((current) =>
        current.filter((planlabel) => planlabel.label !== plan)
    );
  };
  useEffect(() => {
    getSubscription()
  }, [isOpen]);

  const  getPlanDetails = (planId) =>{
    // console.log("planId",planId)
    let data = {
      planName: planId
    }
    return fetchApi({
      method: "POST",
      url: `subscription/plan/get`,
      isDevApp: false,
      data:data,
    }).then((response) => {
      // console.log("sdf",response)
      if (response.statusCode === 200 || response.statusCode === 201) {
        setPlanDetails(response.doc.generalFeatures)
        setPlanothers({planNameH: response.doc.planName,
        price: response.doc.price,
        duration: response.doc.validity,
          description:response.doc.description})
      }
      setServiceFeatures(response.doc.serviceFeatures)
    });
  }
  React.useEffect(() => {
    setPlanName("")
    setStepSize(0)
    setData([]);
    setError({
      paymentMethod: "",
      cardNumber: "",
      expiryDate:"",
      cvv:"",
      billingAddress:""
    })
  }, [isOpen]);

  const onChange = (id, value) => {
    data[id] = value;
    setData({ ...data });
  };
  const handleChangePlanName = (event) => {
    // console.log(event.target.value)
    setPlanName(event.target.value)
    // console.log("planName",planName)
    const index = event.target.selectedIndex;
    const el = event.target.childNodes[index]
    let option =  el.getAttribute('data-remove');
    setActivePlan(option)
    if (option !== null){
      getPlanDetails(option)
    }


  };

  const  onSubmit = ()=>{
    const accessBody = {
      planName: activePlan,
    };
    fetchApi({method: "POST", url: `developer/planChange-notify`, data: accessBody})
        .then((response) => {
          notification.success({
            message: response?.message,
            duration: 3,
          });
          handlecancel()
          reload()
        })
        .catch(() => {
        });
  };
  return (
    <>
      <Modal title="" footer={null} closable={false} open={isOpen} width={1000}>
        <div className="plan-popup">
          <div className="close-icon" onClick={toggleState}>
            <Menus type="close" />
          </div>
          <div className="popup-text">Enterprise Subscription</div>
          <Space
            direction="vertical"
            size={12}
            style={{ display: "flex", marginTop: "25px",marginBottom:"30px"}}
          >
                <>
                  <p className="curent_text">
                    Current Plan : {plan}</p>
                  <select
                      className="dropdown-filter create-collection-drop"
                      placeholder="Choose Plan Name"
                      name="plan"
                      size="large"
                      id ="plan"
                      value={planName}
                      style={{
                        width: 250,
                      }}
                    onChange={handleChangePlanName}
                  >
                    <option value="">Choose Plan</option>
                    {planDropbox.map((users, ind) => (
                        <option key={ind} value={users.value} data-remove={users.label}>
                          {users.label}
                        </option>
                    ))}
                  </select>
                </>
            {planName !== "" && planName !== undefined?<> <PlanDetails
                serviceFeatures={serviceFeatures}
                featuresdetails={featuresdetails}  features={features} plan={planothers} planDetails={planDetails} activePlan={activePlan} onChange={onChange} error={error}/>
                <div className="btn-section">
                  <button className="ok-btn" onClick={onSubmit}>
                    Submit
                  </button>
                </div></>:<></>}
          </Space>
        </div>
      </Modal>
    </>
  );
};

export const PlanDetails = ({featuresdetails,features,plan,planDetails,activePlan,serviceFeatures}) => {
  return (
      <div className="column-add-project-container mt-4">
        <Space direction="vertical" size={5}>
          <p className="current_text">
            Selected Plan : {plan.planNameH}</p>
          <p className="plan_text">
            Description&nbsp;:&nbsp;{plan.description}<br/>
            Features&nbsp;:&nbsp;<br/>
            <ul id="plan_text">
              {Object.entries(planDetails).map(([key,value],index)=>(
                <li>
                  {key}&nbsp;-&nbsp;{Array.isArray(value) ?
                    value.map((list, index) => (
                <div key={index} >
                  <>
                <Menus type="tick"/>&nbsp;
                {list}
                    </>
                </div>
              )):value}
                </li>
              ))}
              {Object.entries(serviceFeatures).map(([key, value], index) => (
                  typeof value === "object" && value.hasOwnProperty('description') !== true ? (
                      Object.entries(value).map(([nestedKey, nestedValue], nestedIndex) => (
                          <li key={nestedKey}>
                            {nestedValue.description}
                          </li>
                      ))
                  ) : typeof value !== "object" && value.hasOwnProperty('description') !== true ?(
                      <li key={key}>
                        {value}
                      </li>
                  ):(
                      <li key={key}>
                        {value.description}
                      </li>
                  )
              ))}
            </ul>
            Pricing&nbsp;:&nbsp;${plan.price}/month<br/>
            Duration&nbsp;:&nbsp;{plan.duration} months
          </p>
        </Space>
          <div className="faq-details">
          <Collapse end="right">
            <Panel header= "Plan Comparison">
          <div>
            <table>
              <tr>
              {featuresdetails.map((list,index) => (
                  <th className={activePlan === list.label ?"plan_th_active":"plan_th"} key={index}>
                    {list.label}
                  </th>
              ))}
              </tr>

              <tbody>
              {features[0].map((_, colIndex) => (
                   <tr>
                     {features.map((row,index) => (
                  <td className="plan_td" key={index}>
                    {row[colIndex]}
                  </td>
                         ))}
                   </tr>
              ))}
              </tbody>


            </table>
          </div>
            </Panel>
          </Collapse>
        </div>
      </div>
  );
};

import React from "react";
import Header from "./ui/Header.js";
import Banner from "./ui/Banner.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate, useLocation } from "react-router-dom";
import { useEffect } from "react";
import './demoStyles.less'

import { notification } from "antd";
import NewFooter from "../landingPage/ui/landing_page_new_footer";

// A custom hook that builds on useLocation to parse
// the query string for you.
function useQuery() {
  const { search } = useLocation();

  return React.useMemo(() => new URLSearchParams(search), [search]);
}

export const KrypCoreSensor = () => {

  const navigate = useNavigate();
  let query = useQuery();

  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    AuthenticatiedUser()
  }, [])

  useEffect(() => {
    if(query.get("message")){
      notification.info({
        message: query.get("message"),
        duration: 3,
      });
    }
  }, [query.get("message")])

  const AuthenticatiedUser = () => {
    if (isAuthenticated) { // && user.email_verified === true
      navigate("/dashboard")
    }
  }

  return (
    <div className="krypcore_sensor">
      <Header />
      <Banner />
      <NewFooter />

    </div>
  );
};

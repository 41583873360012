import React, { useState } from "react";
import Logo from "../../../assets/logos/logo.avif";

import { useNavigate } from "react-router-dom";
import '../landingPageStyles.less'
import {Logos} from "../../../assets";


const NewFooter = () => {
    const twitter= process.env.REACT_APP_TWITTER_URL
    const telegram = process.env.REACT_APP_TELEGRAM_URL
    const discord= process.env.REACT_APP_DISCORD_URL
    const linkedIn= process.env.REACT_APP_LINKEDIN_URL
    const aboutus= process.env.REACT_APP_LANDING_PAGE_ABOUT_URL
    const career = process.env.REACT_APP_LANDING_PAGE_CAREER_URL
    const docs= process.env.REACT_APP_LANDING_PAGE_DOCS_URL
    const api= process.env.REACT_APP_LANDING_PAGE_API_URL
    const sdk= process.env.REACT_APP_LANDING_PAGE_SDK_URL
    const userManual= process.env.REACT_APP_LANDING_PAGE_USER_MANUAL_URL
    const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL
    const youtube=  process.env.REACT_APP_YOUTUBE_URL

    const open = (value)=>{
        window.open(value)
    }

    const [query, setQuery] = useState(false)
    const navigate = useNavigate();

    const navigateHome = (value) => {
        if (window.location.pathname != "/"){
            navigate("/", { state: { value: value } })
        }else{
            scrollToPricing()
        }
    };
    const scrollToPricing = () => {
        document.getElementById('pricing_works').scrollIntoView({ behavior: "smooth" })
    };

    React.useEffect(() => {
        if (window.location.pathname.includes('contact-us')) {
            setQuery(true);
        }

    }, [])
    return (
        <>

            <div className="new_footer">
                <div className="new_footer_container">
                    <div className="new_footer_card left-card">
                        <img src={Logo} alt="" className="new_footer_card_logo" style={{width:"138px",height:"35px"}} />
                        <p className="footerText"> Krypcore Web3 simplifies the intricacies involved in the development of decentralized applications, minimizing both time and costs for developers and enterprises. </p>
                    </div>
                    <div className="new_footer_card right-card">
                        <div className="sub-grid-item-1">
                            <h3>Company</h3>
                            <ul className="sub-grid-item-1-list">
                                <li onClick={() => open(aboutus)}><a>About us</a></li>
                                <li><a href="/pricing" >Pricing</a></li>
                                <li><a href="/contact-us">Contact Us</a></li>
                                <li onClick={() => open(career)}><a>Careers</a></li>
                            </ul>
                        </div>
                        <div className="sub-grid-item-1">
                            <h3>Resources</h3>
                            <ul className="sub-grid-item-1-list">
                                <li onClick={() => open(docs)}><a>Documentation</a></li>
                                <li onClick={() => open(api)}><a>API Reference</a></li>
                                <li onClick={() => open(sdk)}><a>SDK Reference</a></li>
                                <li onClick={() => open(userManual)}><a>User Manual</a></li>
                                <li ><a href="/blog">Blogs</a></li>
                            </ul>
                        </div>

                    </div>
                </div>
                <div className="text-center">
                    <div className="social-icons-footer">
                        {/*<a onClick={() => open(telegram)}><Logos type="telegram"/></a>
                        <a onClick={() => open(twitter)}><Logos type="footertwitter"/></a>
                        <a onClick={() => open(discord)}><Logos type="footerdiscord"/></a>
                        <a onClick={() => open(linkedIn)}><Logos type="footerlinkedin"/></a>
                        <a onClick={() => open(youtube)}><Logos type="footerYoutube"/></a>*/}
                        <a id="telegramid" className="telegramid" href={telegram} target="_blank"><Logos type="telegram"/></a>
                        <a id="twitterid" className="twitterid" href={twitter} target="_blank"><Logos type="footertwitter"/></a>
                        <a id="discordid" className="discordid" href={discord} target="_blank"><Logos type="footerdiscord"/></a>
                        <a id="linkedinid" className="linkedinid" href={linkedIn} target="_blank"><Logos type="footerlinkedin"/></a>
                        <a id="youtubeid" className="youtubeid" href={youtube} target="_blank"><Logos type="footerYoutube"/></a>
                    </div>
                </div>
                <hr className="divider" />
                <div className="bottom-section">
                    <div className="word-divider1">
                        <span>@2023 Krypcore</span>
                    </div>
                    <div className="word-divider mt-3">
                        <p className="mb-0">
                            <a href="/terms"><span>Terms</span></a>
                            <a href="/privacy-policy"><span>Privacy</span></a>
                            <a href="/contact-us"><span>Contact Us</span></a>
                        </p>
                    </div>
                </div>
            </div>
        </>
    )
};

export default NewFooter;

import React from 'react'
import BlogUi from './BlogUi'

import Header from '../new-light-theme/ui/Header'
import Footer from '../new-light-theme/ui/Footer'

import '../new-landing-page/NewLandingPage.less'
const BlogPage = () => {
  return (
    <div className="enterprise_responsive">
      <div className="banner-bg">
        <Header/>
        <BlogUi/>
      </div>
      <Footer/>
    </div>
  )
}

export default BlogPage
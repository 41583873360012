/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { Collapse, Input, Select, notification } from "antd";
import { fetchApi } from "../../_config/api";
import { SearchOutlined } from "@ant-design/icons";
import { Card } from "./card";
import { faqData } from "./tempData";
import { deepCopy } from "../../deps";
import "./styles.less";
import { Modal } from "../../library";
import { Slack } from "../../assets/logos/slack";
import { Discord } from "../../assets/logos/discord";
import { TickOutline } from "../../assets/menus/tickoutine";
import { getUserDetails } from "../../deps";

const options = [
  { id: 0, name: "Plan Upgrade" },
  { id: 1, name: "Feedback" },
  { id: 2, name: "Complaints" },
  { id: 3, name: "Queries" },
];
const { Panel } = Collapse;

const { profile } = getUserDetails();

const urls = {
  Github: "https://www.github.com",
  Slack: "https://www.slack.com",
  Discord: "https://discord.gg/ygUt33Tf",
  Twitter: "https://twitter.com/krypctalk",
  Telegram: "https://t.me/krypctech",
};

export const Help = ({ showLoader }) => {
  const [searchText, setSearchText] = useState();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [isModalOneOpen, setIsModalOneOpen] = useState(false);
  const [isModalTwoOpen, setIsModalTwoOpen] = useState(false);
  const [contactUsData, setContactUsData] = useState({
    subject: "",
    message: "",
    emailId: profile.emailId,
    name: profile.firstName,
  });

  useEffect(() => {
    showLoader(true);
    fetchApi({ url: "developer/help/faq", method: "GET" })
      .then((resp) => {
        const { docs } = resp;
        setData(deepCopy(docs));
        showLoader(false);
      })
      .catch((err) => {
        setData(faqData);
        showLoader(false);
      });
  }, []);

  useEffect(() => {
    onFilterDataChange();
  }, [data, searchText]);

  const onFilterDataChange = () => {
    const filterList = searchText
      ? data.filter((item) =>
          item.question.toLowerCase().includes(searchText.toLowerCase())
        )
      : deepCopy(data);
    setFilteredData(filterList);
  };

  const onTextChange = (e) => {
    setSearchText(e.target.value);
  };

  const showModal = () => {
    setIsModalOneOpen(true);
  };

  const setModalOneState = (state) => {
    setIsModalOneOpen(state);
  };
  const setModalTwoState = (state) => {
    setIsModalTwoOpen(state);
  };

  const contactUsModalSubmit = () => {
    setIsModalOneOpen(false);
    setIsModalTwoOpen(false);
  };

  const onChangeMessage = (e) => {
    setContactUsData({ ...contactUsData, [e.target.id]: e.target.value });
  };
  const onChangeSubject = (value) => {
    setContactUsData({ ...contactUsData, subject: value });
  };

  const contactUs = (action) => {
    setIsModalTwoOpen(true);
    if (action === "remove") {
      //remove modal
      setIsModalOneOpen(false);
      setIsModalTwoOpen(false);

      return;
    }
    if (!contactUsData.subject && !contactUsData.message) {
      notification.error({
        message: "Please enter subject and message.",
        duration: 2,
      });
      setIsModalTwoOpen(false);
      return;
    }
    fetchApi({
      method: "POST",
      url: `developer/contact-us`, //enter url of the api that needs to be used to PUT the data

      data: contactUsData,
    })
      .then((response) => {
        notification.success({
          message: "Thank you for contacting us.",
          duration: 2,
          //setIsModalTwoOpen()
        });
        sessionStorage.setItem("emailId", profile.emailId);
      })
      .catch(() => {
        notification.error({
          message: "Unable to send details",
          duration: 2,
        });
      });
  };

  return (
    <div className="help-page-container">
      <div className="need-some-help-section">
        <div className="need-some-help-text">Need some help ?</div>
        <button
          className="contact-us-btn"
          style={{ zIndex: 1 }}
          onClick={showModal}
        >
          Contact Us
        </button>
        <Modal
          title="Thank You!"
          primaryButtonText="Done"
          secondaryButtonText="Cancel"
          isSelectedTeam={true}
          isModalOpen={isModalTwoOpen}
          setModalState={setModalTwoState}
          handlerMethod={contactUsModalSubmit}
        >
          <div style={{ alignSelf: "center" }}>
            <TickOutline />
          </div>
          <h3 style={{ color: "white", alignSelf: "center" }}>Message Sent </h3>
          <h4
            style={{ color: "white", alignSelf: "center", paddingBottom: 18 }}
          >
            Contact us on{" "}
            <a href={urls.Slack} target="_blank" rel="noreferrer">
              <Slack />{" "}
            </a>
            and{" "}
            <a href={urls.Discord} target="_blank" rel="noreferrer">
              <Discord />
            </a>
          </h4>
        </Modal>
        <Modal
          title="Contact Us"
          primaryButtonText="Submit"
          secondaryButtonText="Cancel"
          isModalOpen={isModalOneOpen}
          isSelectedTeam={true}
          setModalState={setModalOneState}
          handlerMethod={contactUs}
        >
          <Select
            showArrow={true}
            style={{
              width: "100%",
              backgroundColor: "inherit",
              color: "white",
            }}
            id="subject"
            bordered={false}
            placeholder="Subject"
            onChange={onChangeSubject}
          >
            {options.map((option) => (
              <Select.Option key={option.id} value={option.name}>
                {option.name}
              </Select.Option>
            ))}
          </Select>

          <Input
            style={{
              width: "100%",
              backgroundColor: "inherit",
              marginTop: 20,
              borderRadius: 8,
              marginBottom: 20,
              color: "white",
            }}
            id="message"
            className="input-container"
            placeholder="Message"
            value={contactUsData.message}
            onChange={onChangeMessage}
          />
          {/*<button onClick={() => setIsModalTwoOpen(true)}>Click</button>*/}
        </Modal>
      </div>
      <div className="join-our-community-container">
        <div className="header-text">Join our Community</div>
        <div className="join-community-section">
          <a href={urls.Telegram} target="_blank" rel="noreferrer">
            <Card
                type="telegram"
                label="Telegram"
                description="Join our Telegram"
            />
          </a>
          <a href={urls.Github} target="_blank" rel="noreferrer">
            <Card
              type="github"
              label="Github"
              description="Join our Github Community"
            />
          </a>
          <a href={urls.Slack} target="_blank" rel="noreferrer">
            <Card
              type="slack"
              label="Slack"
              description="Join our Slack Community"
            />
          </a>

          <a href={urls.Discord} target="_blank" rel="noreferrer">
            <Card
              type="discord"
              label="Discord"
              description="Join our Discord Community"
            />
          </a>

          <a href={urls.Twitter} target="_blank" rel="noreferrer">
            <Card
              type="twitter"
              label="Twitter"
              description="Join our Twitter Community"
            />
          </a>
        </div>
      </div>
      <div className="faq-container">
        <div className="faq-header">
          <div className="header-text">FAQ</div>
          <div className="search-box-container">
            <SearchOutlined style={{ width: "20%" }} className="search-icon" />
            <input
              type="text"
              value={searchText}
              className="input-box"
              placeholder="search"
              onChange={onTextChange}
            />
          </div>
        </div>
        <div className="faq-details">
          <Collapse end="right">
            {filteredData.map((item) => (
              <Panel header={item.question} key={item.id}>
                <div>{item.answer}</div>
              </Panel>
            ))}
          </Collapse>
        </div>
      </div>
    </div>
  );
};

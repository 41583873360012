/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, {useCallback, useEffect, useState} from "react";
import { useLocation } from "react-router-dom";
import {notification, Tabs, Tooltip} from "antd";

import "./styles.less";
import "./../../../../assets/css/common.less";
import {EndpointsList} from "./endpoints";
import {deepCopy, DEVELOPERROLE, ENTERPRISEDEVELOPERROLE, getUserDetails} from "../../../deps";
import {CreateEndpoint} from "./createEndpoint";
import fetchApi from "../../../_config/api";
import Skeleton from "react-loading-skeleton";
import DappTable from "./DappTable";

export const Endpoints = ({showLoader,isLoading }) => {
  const {
    profile: { userRole },
  } = getUserDetails();

  const  location  = useLocation();
  const [activeKey, setActiveKey] = useState("blockchainNode");
  const [queryBlock, setQueryBlock] = useState(true);
  const [queryStorage, setQueryStorage] = useState(false);
  const [queryDid, setQueryDid] = useState(false);
  const [queryBundler, setQueryBundler] = useState(false);
  const [endpointDashoard, setEndpointDashboard] = useState(false);
  const [createEndpointOpen, setCreateEndpointOpen] = useState(false);
  const [data, setData] = useState([]);


  useEffect(() => {
    getEndpointsList()
    if(location.state !==null){
      openPopup()
    }


  }, []);
  const onCreateEndpointModal = () => {
    setCreateEndpointOpen(!createEndpointOpen);
  };


  const openPopup =()=>{
    const isOpenPopup= location.state;
    setCreateEndpointOpen(isOpenPopup)

  }

  const getEndpointsList = () => {
    // console.log("reload")
    showLoader(true);
    fetchApi({ method: "GET", url: `endpoints` })
        .then((response) => {
          showLoader(false);
          const { docs = [] } = response;
            setData(docs)
        })
        .catch(() => {
          setData([]);
          showLoader(false);
        });
  };



  const onChange = (key) => {
    if (key === "blockchainNode"){
      setQueryBlock(true)
    }else if (key === "storageNode"){
      setQueryStorage(true)
    }else if (key === "didNode"){
      setQueryDid(true)
    }else if (key === "bundlerNode"){
      setQueryBundler(true)
    }
    setActiveKey(key);
  };
  const onCreateChange = (key) => {
    console.log("safcadvs",key)
    if (key === "blockchainNode"){
      setEndpointDashboard(true)
      setQueryBlock(true)
    }else if (key === "storageNode"){
      setQueryStorage(true)
    }else if (key === "didNode"){
      setQueryDid(true)
    }else if (key === "bundlerNode"){
      setQueryBundler(true)
    }
    setActiveKey(key);
  };

  const TabItem = [
    {
      label: "My Endpoints",
      key: "blockchainNode",
      children: (
        <EndpointsList
            tabName={activeKey}
            queryblock={queryBlock}
            onCreateChange={onCreateChange}
            dashboard={endpointDashoard}
            showLoader={showLoader}
        />
      ),
    },
    {
      label: "My dApps",
      key: "storageNode",
      children: (
          <DappTable
              tabName={activeKey}
              querystorage={queryStorage}
              onCreateChange={onCreateChange}
              showLoader={showLoader}
              isLoading={isLoading}
          />
      ),
    },
/*    {
      label: "Wallets",
      key: "didNode",
      children: (
          <EnpointsList
              tabName={activeKey}
              querydid={queryDid}
              onCreateChange={onCreateChange}
          />
      ),
    },*/

  ];

  return (
      <>

    <div className="my-account-page-container mt-0">
       <h1 className="header-two">My Projects</h1>
      {/*<p className="subheader">My Projects</p>*/}
    {isLoading?(<Skeleton height={55} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
      <Tabs onChange={onChange} activeKey={activeKey} items={TabItem} />
    )}
    </div>
        <CreateEndpoint
            isOpen={createEndpointOpen}
            toggleState={onCreateEndpointModal}
            reloadCollection={
              userRole !== DEVELOPERROLE
                  ? getEndpointsList
                  : getEndpointsList
            }
            activeKey={activeKey}
            query={onCreateChange}
            role={userRole}
        />
        </>

  );
};

export default Endpoints;

import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { useLocation } from "react-router-dom";
import "./style.less";

const SubMenu = ({
                     list,
                     hasPremium = false,
                     highlightTitle,
                 }) => {
    const [subNav, setSubNav] = useState(false);
    const { pathname } = useLocation();

    useEffect(() => {
        // Automatically open sub menu if a sub item is active
        if (list.subMenu && list.subMenu.some(item => `/${item.id}` === pathname || pathname.includes("/dAppDetails-page") || pathname.includes("/endpointsDetails"))) {
            setSubNav(true);
        }
    }, [list.subMenu, pathname]);

    const showSubMenu = () => {
        setSubNav(!subNav);
    };

    const isActive = (id) => pathname === `/${id}` || (pathname.includes(`/${id}`) && pathname.includes("/dAppDetails-page")) || (pathname.includes(`/${id}`) && pathname.includes("/endpointsDetails"));

    const isParentActive = (list) => {
        if (list.subMenu) {
            return list.subMenu.some(item => `/${item.id}` === pathname || pathname.includes("/dAppDetails-page") || pathname.includes("/endpointsDetails") );
        }
        return false;
    };

    return (
        <div className="new-sidenav-list">
            <Link to={list.subMenu ? "#" : `/${list.id}`} onClick={list.subMenu && showSubMenu}>
                <div
                    key={list.id}
                    className={`menu-item${isActive(list.id) || isParentActive(list) ? " new-sidenav-list-active" : ""}`}
                >
                    <img
                        src={list.src}
                        style={{
                            marginRight: '15px',
                            height: '25px',
                            width: '25px',
                            opacity: isActive(list.id) || isParentActive(list) ? '100%' : '50%'
                        }}
                    />
                    <span className="menu-text-new">{list.name}</span>
                    <div className="menu-Icon">
                        {list.subMenu && (subNav ? list.iconClosed : list.iconOpened)}
                    </div>
                </div>
            </Link>
            <div>
                {subNav && list.subMenu && list.subMenu.map((item, index) => (
                    <div key={index}>
                        <Link to={`/${item.id}`}>
              <span className={`submenu-style${isActive(item.id) ? " active-menu-item1" : ""}`}>
                <span className={`${"my-dapps" === item.id ? "sub-menu-text-new pr-35" : "sub-menu-text-new"}`}>
                  {item.name}
                </span>
              </span>
                        </Link>
                    </div>
                ))}
            </div>
        </div>
    );
};

export default SubMenu;

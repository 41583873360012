import React, {useState} from 'react';
import {Modal, Space, Form, Button, Select, notification, Spin} from 'antd';
import { Menus } from '../../../assets';
import './AssignProject.css';
import fetchApi from "../../../_config/api"; // Import the CSS file with the input field styles
import {LoadingOutlined} from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";

const antIcon = <LoadingOutlined style={{ fontSize: 24 }} spin />;

const { Option } = Select;

const AssignProject = ({ isOpen, toggleState, reload, teamId }) => {


    const [projectList, setProjectList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [ProjectId, setProjectId] = useState("");
    const [ProjectName, setProjectName] = useState("");
    const [resetKey, setResetKey] = useState(0);
    React.useEffect(() => {
        getProjectList();
    }, []);

    const getProjectList = async () => {
        fetchApi({
            method: "GET",
            url: "developer/project-list",
            isDevApp: false,
        })
            .then((response) => {
                setProjectList(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const assigndapp =async ()=>{
        fetchApi({
            method: "POST",
            url: "teams/assignProject",
            isDevApp: false,
            data: {
                projects: {
                    projectId: ProjectId,
                    projectName: ProjectName,

                },
                teamId: teamId
            }
        })
            .then((response) => {
                if (response.statusCode===200){
                    notification.success({
                        message: response.message,
                        duration: 2,
                    });
                    // Reset ProjectId and ProjectName before toggling state
                    setProjectId("");
                    setProjectName("");
                    // Change the reset key to trigger re-rendering of the <select> element
                    setResetKey(prevKey => prevKey + 1);
                    toggleState();
                    reload();
                } else {
                    notification.error({
                        message: response.message,
                        duration: 2,
                    });
                }
                // setProjectListList(response.docs);
            })
            .catch((error) => {
                console.error(error);
                notification.error({
                    message: error,
                    duration: 2,
                });
            });
    }

    const handleSelectChange = (event) => {
        const selectedProjectId = event.target.value;
        const selectedProjectName = event.target.options[event.target.selectedIndex].text;
        setProjectId(selectedProjectId)
        setProjectName(selectedProjectName)
        console.log(selectedProjectId)
        console.log(selectedProjectName)
        console.log(teamId)
        // Do something with the selected project ID and name
        // For example, store it in state, pass it to a function, etc.
    };


    return (
        <>
            <Modal maskStyle={{backgroundColor: '#CFCFCF80'}} centered title="" footer={null} closable={false}
                   open={isOpen}>
                <div>
                    <div className="close-icon" onClick={() => {
                        // Reset selected project ID and name when closing popup
                        setProjectId("");
                        setProjectName("");
                        toggleState();
                    }}>
                        <Menus type="close"/>
                    </div>
                    <div className="modal-title">Assign Project</div>
                    <div className="create-dapp-container">
                        <Space
                            direction="vertical"
                            size={10}
                            style={{
                                minHeight: 'calc(100% - 100px)',
                                display: 'flex',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            <Form
                                name="basic"
                                autoComplete="off"
                                layout="vertical"
                            >
                                <div className="assign-project">

                                    <Form.Item
                                        // className="MemberRole"
                                        // name="     "
                                        // rules={[
                                        //     {
                                        //         // required: isEnterpriseAdmin,
                                        //         message: 'Missing Project Name',
                                        //     },
                                        // ]}
                                    >
                                        <div className="input-wrapper">
                                            <label htmlFor="teamName" className="input-label">
                                                <p></p>
                                            </label>
                                            <select id="teamName" className="add-member-input-field"  onChange={handleSelectChange} key={resetKey}  >
                                                <option value="">Assign Project</option>
                                                {projectList && projectList.length > 0 && projectList.map((project, ind) => (
                                                    <option key={ind} value={project.projectID}>{project.projectName}</option>
                                                ))}
                                            </select>
                                        </div>
                                    </Form.Item>
                                </div>

                                <div>
                                    <Button className="assign-button" onClick={assigndapp} style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px', alignItems: 'center', justifyContent: 'center',padding:"10px"}}>
                                        {loading ? <span>Assign Project  <Spin indicator={antIcon} className="spinner"/></span> :
                                            <span>Assign Project</span>}
                                    </Button>
                                </div>


                            </Form>


                        </Space>
                    </div>
                </div>

            </Modal>

        </>
    );
}

export default AssignProject;

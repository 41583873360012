import React from "react";

export const FooterLinkedIn = ({}) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="25" viewBox="0 0 24 25" fill="none">
      <g clipPath="url(#clip0_175_1349)">
        <mask id="mask0_175_1349"  style={{ maskType: "luminance" }} maskUnits="userSpaceOnUse" x="0" y="0" width="24" height="25">
          <path d="M24 0.580078H0V24.5801H24V0.580078Z" fill="white"/>
        </mask>
        <g mask="url(#mask0_175_1349)">
          <path d="M19 0.580078H5C2.239 0.580078 0 2.81908 0 5.58008V19.5801C0 22.3411 2.239 24.5801 5 24.5801H19C21.762 24.5801 24 22.3411 24 19.5801V5.58008C24 2.81908 21.762 0.580078 19 0.580078ZM8 19.5801H5V8.58008H8V19.5801ZM6.5 7.31208C5.534 7.31208 4.75 6.52208 4.75 5.54808C4.75 4.57408 5.534 3.78408 6.5 3.78408C7.466 3.78408 8.25 4.57408 8.25 5.54808C8.25 6.52208 7.467 7.31208 6.5 7.31208ZM20 19.5801H17V13.9761C17 10.6081 13 10.8631 13 13.9761V19.5801H10V8.58008H13V10.3451C14.396 7.75908 20 7.56808 20 12.8211V19.5801Z" fill="#D2D2D2"/>
        </g>
      </g>
      <defs>
        <clipPath id="clip0_175_1349">
          <rect width="24" height="24" fill="white" transform="translate(0 0.580078)"/>
        </clipPath>
      </defs>
    </svg>
);

import React, {useRef, useState} from "react";
import '../photoNftStyles.less'

export const Banner = () => {

  return (
    <>
      <div className="Bannersection-wrap" id="home">
        <div className="container">
          <div className="col-md-10 mx-auto banner-head-container">
            <h1 className="banner-heading">
              Krypcore Web3 <span className="banner-highlight">Experience/Demo</span>
            </h1>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;

import React from "react";
import { PromotionTile } from "./promoTile"; 
import { SignUpForm } from './signupForm';
import './style.less';

export const EnterpriseSignUpForm = () => {
  return (
    <div className="enterprise-signup-page">
      <PromotionTile />
      <SignUpForm/>
    </div>
  );
};

import React from "react";

export const Filecoin = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_903_3172)">
      <path
        d="M122.451 70.4244C117.462 104.006 86.3756 127.224 52.7945 122.235C19.2134 117.246 -4.00547 85.9677 0.791829 52.3866C5.78102 18.9974 37.0594 -4.22152 70.6405 0.575774C104.03 5.56496 127.249 36.8433 122.451 70.4244Z"
        fill="#42C1CA"
      />
      <path
        d="M89.254 24.5621C76.7811 16.6945 67.954 33.9648 67.7621 34.5404C65.4594 38.7621 63.7324 45.4783 62.5811 50.4675L46.2702 47.9729L45.6946 52.3864L61.6216 54.6891C61.2378 56.9918 61.0459 58.5269 61.0459 58.5269L60.0865 63.3242V63.5161L44.3513 61.2134L43.7756 65.6269L59.5108 67.9296C55.4811 88.654 52.4108 94.9864 49.3405 98.4404C48.7648 99.208 44.3513 102.086 42.0486 96.9053C40.5135 93.2594 37.6351 93.6431 37.6351 93.6431C36.6756 93.4512 32.8378 95.9458 38.7865 99.7837C51.0675 107.651 60.0865 90.381 60.2783 89.8053C62.5811 85.5837 65.4594 73.8783 66.6108 68.8891L82.9216 71.3837L83.4973 66.9702L67.3783 64.6675C67.7621 62.3648 67.954 60.8296 67.954 60.8296L68.7216 56.0323V55.8404L84.6486 58.1431L85.2243 53.7296L69.4892 51.4269C73.9027 30.7026 75.8216 29.5512 78.8919 26.0972C79.4675 25.3296 83.8811 22.4512 86.1838 27.6323C87.7189 31.2783 90.5973 30.8945 90.5973 30.8945C91.3648 30.8945 95.2027 28.3999 89.254 24.5621Z"
        fill="#FEFEFE"
      />
    </g>
    <defs>
      <clipPath id="clip0_903_3172">
        <rect
          width="122.811"
          height="122.811"
          fill="white"
          transform="translate(0.216187)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Filecoin;

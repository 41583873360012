import React, { useEffect, useState } from 'react'
import { DEVELOPERROLE, removeAllDataInLocal } from '../../../deps';
import { useAuth0 } from '@auth0/auth0-react';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import fetchApi from '../../../_config/api';
import { notification } from 'antd';
import Logo1 from "../../../assets/icons/1000103458.png"
import RightArrow from '../../../assets/icons/right-arrow 2.svg'
import WhiteLogo from '../../../assets/icons/krypcore-white 1.svg'
import Icon from  '../../../assets/icons/Icon.svg'

const Header = () => {

  const navigate = useNavigate();
  const location = useLocation();
  const {pathname}=location
  const [section,setsection] = useState('')
  let features = ""
   React.useEffect(()=>{
     if (location.state != null){
       if (location.state.value != null) {
         features = location.state.value
         document.getElementById(features).scrollIntoView({behavior: "smooth"})
         setsection(features)

       }
     }

  },[])


  useEffect(()=>{
    navigate({state:{value:null}})
  },[pathname])

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };
  const onViewDocs = () => {
    window.open(process.env.REACT_APP_LANDING_PAGE_DOCS_URL)
   };

  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get("referrer");

  useEffect(() => {
    //console.log("dataQueryParam header:", referralCode);
  }, [referralCode]);
  const navigateToheader = (value) => {
    if(window.location.pathname !=='/'){
      navigate('/',{state:{value:value}})
}
    document.getElementById(value).scrollIntoView({ behavior: "smooth" })
    setsection(value)
    setDropdownOpen(false);

  };

  const logout_ = () => {
    logout({
      returnTo: window.location.origin,
    });
    removeAllDataInLocal();
  };
  const { loginWithRedirect,loginWithPopup, isAuthenticated, logout, user } = useAuth0();

  const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL
  const onNavigateDeveloper = () => {
    // console.log("developer")
    loginWithRedirect({
      appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
      userRole: DEVELOPERROLE,
      userType: "INDIVIDUAL",
    });

  };
  const onNavigatetosignup = ()=>{
    if(referralCode != null && referralCode !== ""){
      fetchApi({
        method: "POST",
        url: `auth/validateReferer`,
        isDevApp: false,
        data: { referralCode },
      })
          .then((response) => {
            //console.log("ref--",response)
            if ( response.statusCode !== 200) {
              notification.error({
                message: response.message,
                duration: 3,
              });

            }else {
              const {emailId: referralEmail } = response?.doc;
              loginWithRedirect({
                screen_hint: 'signup',
                appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                userRole: DEVELOPERROLE,
                userType: "INDIVIDUAL",
                referralCode,
                referralEmail
              });
            }
          })
          .catch((err) => {
            // console.log(err);
          });
    }else{
      loginWithRedirect({
        screen_hint: 'signup',
        appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
        userRole: DEVELOPERROLE,
        userType: "INDIVIDUAL",
        referralCode: ""
      });
    }


  }


  useEffect(() => {
    if (isAuthenticated && user.email_verified === false) {
      logout_();
      notification.error({
        message: "Please Verify Email!",
        duration: 10,
      });
    }
  }, [isAuthenticated]);
  const highlightActiveLink = () => {
    const scrollPosition = window.scrollY;

    const sectionToLinkMap = {
      home: "home-link",
      "start-with-krypCore": "features-link",
      pricing_works: "plans-link",
      "enterprise-link": "enterprise-link",
    };
    for (const sectionId in sectionToLinkMap) {
      const linkId = sectionToLinkMap[sectionId];
      const sectionElement = document.getElementById(sectionId);
      const linkElement = document.getElementById(linkId);

      if (sectionElement && linkElement) {
        const sectionTop = sectionElement.offsetTop - 80 ;
        const sectionHeight = sectionElement.offsetHeight - 80;
        // console.log(`Section ${sectionId}: Top=${sectionTop}, Height=${sectionHeight}`);
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          // console.log("linkID",linkId,sectionId)
          if (linkId === "home-link") {
            setsection("home");
          } else if (linkId === "features-link") {
            setsection("start-with-krypCore");
          }else if (linkId === "plans-link"){
            setsection("pricing_works");
          }
        }

      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", highlightActiveLink);
    return () => {
      window.removeEventListener("scroll", highlightActiveLink);
    };
  }, []);
  const tooltipStyle = {
    background: "white", // Set the background color to white
    color: "black", // Set the text color
    // Add more styles as needed
  };
  const onNavigatekcsensor = () => {
    navigate('/krypcoreSensor')
  };
  const onViewGithub=()=>{
    window.open(process.env.REACT_APP_GITHUB_SDK)
   }

  const onNavigate = () => {
    navigate("/contact-us");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLoginButtonClick = () => {
      setTimeout(() => {
        navigate('/dashboard')
      }, 1000);
  
  };

  
  const [scrolled, setScrolled] = useState(false);

  useEffect(() => {
    const handleScroll = () => {
      const scrollTop = window.scrollY;
      if (scrollTop > 100) { // Change 100 to your desired scroll position
        setScrolled(true);
      } else {
        setScrolled(false);
      }
    };

    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);


  return (
    <div className={scrolled ? 'header-wrapl1' : 'header-wrapl'} >
          <nav className="navbar navbar-expand-lg">
            <div className="navbar-logo-division">
              <Link className="navbar-brand" to="/" aria-label="">
                <img  src={WhiteLogo} alt="Accelerate-Your-Web3-Development-Journey" className="logo"  />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <img src={Icon } />
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav  mr-auto">

             
                <li className="nav-item" >
                  <a className={"nav-link1"} id="features-link" onClick={()=>navigateToheader('start-with-krypCore')}>
                    Features
                  </a>
                </li>
                <li className="nav-item" >
                  <a className={"nav-link1"} id="pricing-link" onClick={()=>navigateToheader('pricing_works')}>
                    Pricing
                  </a>
                </li>
                <li className="nav-item" >
                  <a className={"nav-link1"} id="enterprise-link" onClick={() => onViewDocs()}>
                  Resources
                  </a>
                </li>
                <li className="nav-item">
                  <a className={"nav-link1"} id="resources-link" href="/blog">
                    Blog
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav menu-right  ">

                <li>
                  <div className="mobile_responsive_header">
                
                    <span
                        className="text-white-signl"
                        onClick={() => onNavigate()}
                    >
                      Contact Sales <img src={RightArrow} height='13px' width='auto'/>
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span
                        className="text-white-signl1"
                        onClick={() => {
                          if(isAuthenticated){
                            handleLoginButtonClick()
                          }else{
                            onNavigateDeveloper()
                          }
                        }}
                    >
                        Sign in
                    </span>

                  </div>

                </li>
              </ul>
            </div>
          </nav>

        </div>
  )
}

export default Header
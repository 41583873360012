import {dateformatter, getUserDetails} from "../../../../deps";
import React from "react";
import {Menus} from "../../../../assets";
import  "../styles.less"
import {notification} from "antd";
import {CopyOutlined} from "@ant-design/icons";

export const columns = (handleAPI)  => [

    {
        title: "App Name",
        dataIndex: "appName",
    },
    {
        title: "Client ID",
        dataIndex: "clientId",
    },
    {
        title: "Client Password",
        dataIndex: "clientPassword",
    },
    {
        title: "Created Date",
        dataIndex: "createdAt",
        render: (_, { createdAt }) => <span>{dateformatter(createdAt, false, false)}</span>,

    },
    {
        title: "Modified Date",
        dataIndex: "updatedAt",
        render: (_, { updatedAt }) => <span>{dateformatter(updatedAt, false, false)}</span>,

    },
    /*{
      title: "Modified By",
      dataIndex: "modifiedBy",
    },*/
    {
        title: "Team ID",
        dataIndex: "teamId",
    },
    {
        title: "OwnerShip",
        dataIndex: "userName",
    },
    {
        title: "Suspend Status",
        dataIndex: "status",
        render: (_,  row) => (
            <button
                className={
                    row.status === 'INACTIVE' ? "save-changes-btn" : "save-changes-btn-difer"
                }
                onClick={() => handleAPI(row,"inactive")}
            >
                {row.status === "INACTIVE" ? "Activate" : "Deactivate"}
            </button>
        ),
    },
    {
        title: "Manage",
        dataIndex: "manage",
        key: "action",
        render: (_,  row) => (
            <a className="manage_icon_alignment">

                <a onClick={() => handleAPI(row,"refresh")}><Menus type="refresh" /></a>
                <a onClick={() => handleAPI(row,"delete")}> <Menus type="delete" /></a>
            </a>


        ),
    },

];

const onCopy = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    notification.success({
        message: "Copied to clipboard",
        duration: 3,
    });
};
export const columnsDeveloper = (handleAPI)  => [

    {
        title: "App Name",
        dataIndex: "appName",
    },
    {
        title: "Client ID",
        dataIndex: "clientId",
    },
    {
        title: "Client Password",
        dataIndex: "clientPassword",
    },
    {
        title: "Created Date",
        dataIndex: "createdAt",
        render: (_, { createdAt }) => <span>{dateformatter(createdAt, false, false)}</span>,

    },
    {
        title: "Modified Date",
        dataIndex: "updatedAt",
        render: (_, { updatedAt }) => <span>{dateformatter(updatedAt, false, false)}</span>,

    },

    {
        title: "Auth Token",
        dataIndex: "token",
        render: (_, { token,_id }) => (
            <a>
                <CopyOutlined onClick={() => onCopy(token)} />
            </a>
        )
    },
    // {
    //   title: "Auth Token",
    //   dataIndex: "token",
    //   render: (_,  row) => (
    //       <a>
    //         <CopyOutlined onClick={() => onCopy(row.subscriptionId+"_"+row.token)} />
    //       </a>
    //   ),
    // },
    {
        title: "Manage",
        dataIndex: "manage",
        key: "action",
        render: (_,  row) => (
            <a className="manage_icon_alignment">


                <a onClick={() => handleAPI(row,"refresh")}><Menus type="refresh" /></a>
            </a>


        ),
    },

];

export const rewardColumns = (onClaimClick) => [
    {
        title: "Program Name",
        dataIndex: "programName",
        render: (_, { rewardProgramId }) => (
            <span>{ rewardProgramId ? rewardProgramId : ""}</span>
        ),
    },
    {
        title: "Credit",
        dataIndex: "creditPoint",
        render: (_, { creditPoint }) => (
            <span>{creditPoint || 0}</span>
        ),
    },
    {
        title: "Status",
        dataIndex: "status",
        render: (_, { status }) => (
            <span>{status}</span>
        ),
    },
    {
        title: "Action",
        key: "key",
        render: (_, { status, rewardProgramId, _id }) => (
            <>
                {
                    status === "COMPLETED" ? (
                        <div
                            className="api-key"
                        >
                            Claimed
                        </div>
                    ) : (
                        <div
                            className="api-key"
                            onClick={() => onClaimClick(rewardProgramId, _id )}
                        >
                            Claim
                        </div>
                    )
                }
            </>

        ),
    },
];

import React from "react";

export const Close = ({ width = "21px", height = "21px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_close)">
      <path
        d="M10.5 0C4.70105 0 0 4.70105 0 10.5C0 16.2993 4.70105 21 10.5 21C16.2993 21 21 16.2993 21 10.5C21 4.70105 16.2993 0 10.5 0ZM10.5 19.7082C5.43408 19.7082 1.3125 15.5659 1.3125 10.5C1.3125 5.43404 5.43408 1.31246 10.5 1.31246C15.5659 1.31246 19.6875 5.43406 19.6875 10.5C19.6875 15.5659 15.5659 19.7082 10.5 19.7082ZM14.2121 6.78792C13.9558 6.53166 13.5404 6.53166 13.2841 6.78792L10.5 9.57206L7.71586 6.78792C7.45959 6.53166 7.04419 6.53166 6.78759 6.78792C6.53133 7.04419 6.53133 7.45959 6.78759 7.71586L9.57173 10.5L6.78759 13.2841C6.53133 13.5401 6.53133 13.9562 6.78759 14.2121C7.04386 14.4684 7.45927 14.4684 7.71586 14.2121L10.5 11.428L13.2841 14.2121C13.5404 14.4684 13.9558 14.4684 14.2121 14.2121C14.4683 13.9562 14.4683 13.5401 14.2121 13.2841L11.4279 10.5L14.2121 7.71586C14.4687 7.45927 14.4687 7.04386 14.2121 6.78792Z"
        fill="#E0E0E0"
      />
    </g>
    <defs>
      <clipPath id="clip_close">
        <rect width="21" height="21" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from 'react'
import img1 from '../../../assets/icons/Group 811559.svg'
import img2 from '../../../assets/icons/Group 811561.svg'
import img3 from '../../../assets/icons/Group 811560.svg'
import img4 from '../../../assets/icons/Group 811562.svg'
import useIntersectionObserver from './useIntersectionObserver'

const Applications = () => {

  const [ref, isInView] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });


  return (
    <div className='light-theme-application-main'> 
        <h2 ref={ref} className={`light-theme-application-header ${isInView ?'animated animatedFadeInUp fadeInUp':'hidden'}`} >Ready-to-Use<span className='light-theme-application-header-grad'> {" "}Applications</span> </h2>
        <span className='light-theme-application-header-para'>Discover tailor-made Web3 solutions ready for immediate <br className="light-theme-hiddenbreaktag"  /> use, all built on the robust Krypcore infrastructure.</span>
        <div className='light-theme-application-contauiner2'>
           <div className='light-theme-application-contauiner1'>
           <div className='light-theme-application-contauinerf'>
             <div className='light-theme-application-contauiner'>
                <div style={{paddingTop:'13px'}}>
                    <span className='light-theme-application-header-mini'>
                       Krypcore Sensor
                    </span>
                    <p className='light-theme-application-para-mini'>A digital forensics tool to streamline the tracking and analysis of cryptocurrency transactions.</p>
                </div>
                <div>
                  <img src={img1} className='light-theme-application-img'/>
                </div>
                
             </div>
             </div>
             <div className='light-theme-application-contauinerf'>
             <div className='light-theme-application-contauiner'>
                <div style={{paddingTop:'13px'}}>
                    <span className='light-theme-application-header-mini'>
                      RWA Tokenization
                    </span>
                    <p className='light-theme-application-para-mini'>A Platform that enables digitization and  representation of real-world assets as  tokens on the blockchain.</p>
                </div>
                <div>
                  <img src={img2} className='light-theme-application-img'/>
                </div>
                
             </div>
             </div>
           </div>
           <div className='light-theme-application-contauiner1'>
           <div className='light-theme-application-contauinerf'>
             <div className='light-theme-application-contauiner'>
                <div style={{paddingTop:'13px'}}>
                    <span className='light-theme-application-header-mini'>
                      Non-Fungible Token
                    </span>
                    <p className='light-theme-application-para-mini'>Streamline the development of NFT-based  applications with our NFT APIs, enabling  seamless  creation, management and   deployment within your dApp.</p>
                </div>
                <div>
                  <img src={img3} className='light-theme-application-img'/>
                </div>
                
             </div>
             </div>
             <div className='light-theme-application-contauinerf'>
             <div className='light-theme-application-contauiner'>
                <div style={{paddingTop:'13px'}}>
                    <span className='light-theme-application-header-mini'>
                      Decentralized Identity
                    </span>
                    <p className='light-theme-application-para-mini'>Integrate and manage decentralized identifiers  and verifiable credentials to establish a secure and user-centric identity management system <br className="light-theme-hiddenbreaktag" /> for dApps.</p>
                </div>
                <div>
                  <img src={img4} className='light-theme-application-img'/>
                </div>
                
             </div>
             </div>
           </div>

        </div>
    </div>
  )
}

export default Applications
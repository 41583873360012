import React from "react";

export const Twitter = ({ width = "24", height = "24" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 34 27"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_twitter)">
      <path
        d="M10.4004 26.8723C22.8804 26.8723 29.7063 16.5327 29.7063 7.56637C29.7063 7.2726 29.7063 6.98025 29.6866 6.6879C31.0144 5.72781 32.1607 4.53901 33.0718 3.17713C31.8334 3.72587 30.5197 4.08574 29.1746 4.24473C30.591 3.39677 31.6511 2.06308 32.1576 0.491856C30.8257 1.28218 29.3685 1.83915 27.849 2.13873C26.826 1.05094 25.4729 0.330642 23.9993 0.0892981C22.5257 -0.152046 21.0135 0.0990167 19.697 0.803636C18.3804 1.50826 17.3327 2.62715 16.7161 3.98718C16.0995 5.3472 15.9483 6.87254 16.2859 8.32715C13.5882 8.1919 10.9492 7.49084 8.54001 6.26949C6.13086 5.04815 4.00548 3.33381 2.30185 1.23778C1.43416 2.7315 1.1684 4.49975 1.55865 6.18254C1.94891 7.86532 2.96585 9.33611 4.40242 10.2954C3.32265 10.2634 2.26642 9.97214 1.32287 9.44617V9.53208C1.32328 11.0987 1.86558 12.6169 2.85777 13.8292C3.84996 15.0415 5.23097 15.8733 6.76654 16.1835C5.76759 16.4559 4.71948 16.4958 3.70275 16.2999C4.13632 17.6481 4.98045 18.8271 6.11706 19.672C7.25368 20.5169 8.62595 20.9854 10.0419 21.012C7.63924 22.9003 4.67114 23.9254 1.61522 23.9223C1.07536 23.9213 0.53603 23.8886 0 23.8244C3.10298 25.8157 6.71347 26.8719 10.4004 26.867"
        fill="#1DA1F2"
      />
    </g>
    <defs>
      <clipPath id="clip_twitter">
        <rect width="33.0718" height="27" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from "react";
import {notification, Upload} from "antd";
import { Menus } from "../../assets";

const { Dragger } = Upload;

export const Uploader = ({ uploadBase, isBase = true }) => {
  const [fileTypes] = React.useState([
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg+xml',
    'video/mp4',
    'video/webm',
    'audio/mpeg',
    'audio/wav',
    'audio/ogg',
    'model/gltf-binary',
    'model/gltf+json',
    'application/msword',
    'application/vnd.openxmlformats-officedocument.wordprocessingml.document',
    'application/pdf',
    'application/json',
    '.glb',
    '.gltf',
  ]);
  const [maxFileSize] = React.useState(10000000); // 10mb
  const [threeDmaxFileSize] = React.useState(1000000000); // 1gb
  const onGetbase = (file) => {
    const fileExt = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
    const is3DFile = fileTypes.includes(fileExt);
    var numOfTrue = fileTypes.filter(x => x === file.type).length;
    if(numOfTrue > 0 || is3DFile) {
      if(is3DFile){
        if(file.size > threeDmaxFileSize) {
          notification.error({
            message: "Invalid File Size - "+file.name,
            duration: 3,
          });
          return false;
        }else{
          if (!isBase) {
            uploadBase(file);
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              uploadBase(reader.result);
            };
          }
        }
      }else{
        if(file.size > maxFileSize) {
          notification.error({
            message: "Invalid File Size - "+file.name,
            duration: 3,
          });
          return false;
        }else{
          if (!isBase) {
            uploadBase(file);
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              uploadBase(reader.result);
            };
          }
        }
      }
    }else{
      notification.error({
        message: "Invalid File Type - "+file.name,
        duration: 3,
      });
      uploadBase(null);
      return false;
    }
  };

  return (
    <Dragger
      multiple={false}
      maxCount={1}
      beforeUpload={(file) => {
        onGetbase(file);
        return false;
      }}
      onRemove={(file) => {
        uploadBase(null);
      }}
    >
      <Menus type="upload file" />
      <p className="nft-upload-header">Drag and Drop file to upload</p>
      <p className="nft-upload-limmiter">or</p>
      <p className="nft-upload-browse">Browse</p>
    </Dragger>
  );
};

export const UploaderNFTColl = ({ uploadBase, isBase = true }) => {
  const [fileTypes] = React.useState([
    'image/jpeg',
    'image/png',
    'image/gif',
    'image/svg+xml'
  ]);
  const [maxFileSize] = React.useState(10000000); // 10mb
  const [threeDmaxFileSize] = React.useState(1000000000); // 1gb
  const onGetbase = (file) => {
    const fileExt = file.name.slice(file.name.lastIndexOf('.')).toLowerCase();
  //  const is3DFile = fileTypes.includes(fileExt);
    var numOfTrue = fileTypes.filter(x => x === file.type).length;
    if(numOfTrue > 0) {

        if(file.size > maxFileSize) {
          notification.error({
            message: "Invalid File Size - "+file.name,
            duration: 3,
          });
          return false;
        }else{
          if (!isBase) {
            uploadBase(file);
          } else {
            let reader = new FileReader();
            reader.readAsDataURL(file);
            reader.onload = () => {
              uploadBase(reader.result);
            };
          }
        }
     // }
    }else{
      notification.error({
        message: "Invalid File Type - "+file.name,
        duration: 3,
      });
      uploadBase(null);
      return false;
    }
  };

  return (
      <Dragger
          multiple={false}
          maxCount={1}
          beforeUpload={(file) => {
            onGetbase(file);
            return false;
          }}
          onRemove={(file) => {
            uploadBase(null);
          }}
      >
        <Menus type="upload file" />
        <p className="nft-upload-header">Drag and Drop file to upload</p>
        <p className="nft-upload-limmiter">or</p>
        <p className="nft-upload-browse">Browse</p>
      </Dragger>
  );
};

import React from "react";
import "./style.less";
import { Menus, Logos } from "../../assets";
import { Link } from "react-router-dom";
import Logo from "../../assets/logos/logo.avif";

export const PromotionTile = () => {
  return (
    <div className="promo-tile-left">
      <div className="promo-tile-left-inner">
        <div className="logo">
          <Link className="navbar-brand" to="/" aria-label="">
            <img src={Logo} alt="KrypC" className="logo" />
          </Link>
        </div>
        <div>
          <h1 className="promo-content" style={{width:'auto'}}>
            Supercharge your Project with{" "}
            <span className="banner-highlight">Krypcore Web3 Engine</span>
          </h1>
        </div>
        <div className="social-link">
          <a href="https://discord.gg/ygUt33Tf">
            <Logos type="Discord" width="30px" height="30px" />
          </a>

          <a href="https://twitter.com/krypctalk">
            <Logos type="twitter" width="30px" height="30px" />
          </a>
          <a href="https://www.linkedin.com/company/krypc/">
            <Logos type="linkedin" width="30px" height="30px" />
          </a>
        </div>
      </div>
      <div className="login-shape-bg1"></div>
      <div className="login-shape-bg2"></div>
    </div>
  );
};

import React from "react";
import {Timeline, Badge, Typography, Space} from "antd";
import { Logos, Menus } from "../../../assets";
import Logo from "../../../../assets/images/logo.avif";


import {
  timelineList,
  IsFilled,
  getActiveIndex,
  tabIds,
  tabValues,
} from "../constant";
import { Card } from "../steps";
import "./styles.less";
import Skeleton from "react-loading-skeleton";

export const SideNav = ({
  activeTab,
  data,
  onPrevNext,
  fromLanding = true,
    role,
    isLoading
}) => {
  const renderChild = (tab) => {
    if (tab === tabIds.TAB_1)
      return (
        <>
          <div className="placeholder-text ellipsis">{data.globalSetting}</div>
            <div className="placeholder-text">{data?.teamName}</div>
            <div className="placeholder-text">{data.title}</div>
          <div className="placeholder-text ellipsis">{data.description}</div>
          <div className="placeholder-text ellipsis">{data.teamMaintainer}</div>
            <div className="placeholder-text ellipsis">
              {data.industries !== undefined
                  ? data.industries
                      .filter(industry => industry !== 'Others')
                      .join(',')
                  : ''}
              {data.industries.includes("Others") && data.otherIndustries !== undefined
                  ? data.industries.length > 1 ? `, ${data.otherIndustries}` : `${data.otherIndustries}`
                  : ''}
            </div>



        </>
      );
    else if (
      tab === tabIds.TAB_2 ||
      tab === tabIds.TAB_3 ||
      tab === tabIds.TAB_4
    ) {
      const list = data[tab];
      return (
          <>
            {list !== undefined ?
                <div className="row">
                  {list.map((item) => (
                      <Card
                          width="30px"
                          height="30px"
                          className="placeholder-text"
                          key={item}
                          value={item}
                          tab={tab}
                      />
                  ))}
          </div>:
              null}
          </>

      );
    }
    return null;
  };

  const onClick = (tab, index) => {
    const activeIndex = getActiveIndex(activeTab);
    if (index < activeIndex) {
      onPrevNext(tab);
    } else if (index > activeIndex) {
      const tabs = tabValues.slice(0, index - 1);
      const isFilled = tabs.every((item) => IsFilled(data, item,role));
      if (isFilled) {
        onPrevNext(tab);
      }
    }
    return;
  };

  return (
        <div className="slider-layout pt-lg-5">
          {fromLanding && (
              <div className="logo">
                <img src={Logo} alt="" className="logo" />
              </div>
          )}
          <div
              className={`timeline-container${
                  fromLanding ? "" : " full-timeline-container"
              }`}
          >
            <Timeline>
              {timelineList.map((list, ind) => {
                const isFilled = IsFilled(data, list.id);
                const isActive = activeTab === list.id;
                const fontWeight = isActive
                    ? { fontWeight: "600" }
                    : { fontWeight: "500" };
                // console.log(activeTab)
                return (<>
                  {isLoading?(<Skeleton height={55} style={{marginBottom:"30px"}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <Timeline.Item
                        key={ind}
                        onClick={() => onClick(list.id, list.index)}
                        // className="timeline-item"
                        // className={`${activeTab==="details"? "timeline-item1":activeTab==="protocol"? "timeline-item1":activeTab==="wallet"? "timeline-item1":activeTab==="storage"? "timeline-item":activeTab==="summary"? "timeline-item":null}`}
                        className={`${activeTab==="details"? "timeline-item1":activeTab==="nodeconfig"? "timeline-item1":activeTab==="services"? "timeline-item":activeTab==="summary"? "timeline-item":null}`}
                        style={
                          list.index < 5
                              ? { paddingBottom: "2.5em", ...fontWeight }
                              : { ...fontWeight }
                        }
                        dot={
                          isFilled ? (
                              <Menus type="tick-outline" />
                          ) : (
                              <Badge
                                  count={list.index}
                                  className="badge-icon"
                                  style={{
                                    backgroundColor: isActive ? "#AA4BEC" : "#090517", marginBottom:"10%",
                                    color: isActive ? "#FFFFFF" : "#fff",
                                  }}
                              />
                          )
                        }
                    >
                      {list.text}
                      {isFilled && list.id !== tabIds.TAB_4 ? (
                          renderChild(list.id)
                      ) : (
                          <Typography.Text className="placeholder-text">
                            {list.placeholderText}
                          </Typography.Text>
                      )}
                    </Timeline.Item>
                  )}</>
                );
              })}
            </Timeline>
          </div>
          {fromLanding && (
              <div className="icon-container">
                <Logos type="linkedin" width="30px" height="30px" />
                <Logos type="twitter" width="30px" height="30px" />
                <Logos type="discord" width="30px" height="30px" />
              </div>
          )}

        </div>
  );
};

export default SideNav;

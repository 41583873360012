import { dateformatter } from "../../../deps";
import React from "react";
import {CopyOutlined, EyeOutlined, FolderViewOutlined} from "@ant-design/icons";
import {Button, Modal, notification, Space, Tooltip} from "antd";

const onCopy = (apiKey) => {
  navigator.clipboard.writeText(apiKey);
  notification.success({
    message: "Copied to clipboard",
    duration: 3,
  });
};
export const columns =(showPopup)=> [
  {
    title: "",
    dataIndex: "",
  },
  {
    title: "dApp",
    dataIndex: "projectName",
    render: (_, { projectName }) => (
        <a><Tooltip title={projectName}>{projectName}</Tooltip></a>
    ),
  },
  {
    title: "",
    dataIndex: "",
  },
  {
    title: "Service",
    dataIndex: "serviceName",
    render: (_, { service }) => (

        <a><Tooltip title={service}>{service}</Tooltip></a>
    ),
  },
  {
    title: "Method",
    dataIndex: "methodName",
    render: (_, { methodName }) => (
        <a><Tooltip title={methodName}>{methodName}</Tooltip></a>
    ),
  },

  // {
  //   title: "Request ID",
  //   dataIndex: "requestId",
  //   render: (_, { requestId }) => (
  //       <span>{requestId}</span>
  //   ),
  // },
  {
    title: "",
    dataIndex: 'requestId',
    key: 'x',
    render: (requestId) =>
        <a>
          {/* <CopyOutlined onClick={() => onCopy(requestId)} /> */}
        </a>
  },

  {
    title: "Time Stamp",
    dataIndex: "requestTime",
    render: (_, { requestTime }) => (
        <a><Tooltip title={dateformatter(requestTime, false, true)}> <span>{dateformatter(requestTime, false, true)}</span></Tooltip></a>
    ),
  },
  // {
  //   title: "Elapsed Time",
  //   dataIndex: "elapsedTime",
  //   render: (_, { elapsedTime }) => (
  //       <>
  //       <a><Tooltip title={elapsedTime}><span>{elapsedTime}</span></Tooltip></a>
  //       </>
  //   ),
  // },
  // {
  //   title: "Created On",
  //   dataIndex: "createdAt",
  //   render: (_, { createdAt }) => (
  //       <a><Tooltip title={dateformatter(createdAt, false, true)}><span>{dateformatter(createdAt, false, true)}</span></Tooltip></a>
  //   ),
  // },
  {
    title: "Credit used",
    dataIndex: "creditUsed",
    render: (_, { apiWeightage }) => (
        <span style={{ display: 'block', textAlign: 'center', marginRight:"70px"}}>{apiWeightage}</span>
    ),
  },
  {
    title: "Status Code",
    dataIndex: "statusCode",
    render: (_, { statusCode }) => (
        <span style={{ display: 'block', textAlign: 'center', marginRight:"70px"}}>{statusCode}</span>
    ),
  },

  {
    title: "Response",
    dataIndex: '',
    key: 'x',
    render: (response) =>

        <EyeOutlined onClick={() => showPopup(response)} style={{ marginLeft:"40px" }} />
  },
];
export const tempData = [
  {
    projectId: 1,
    projectName: "Project 1",
    blockchain: "blockchain 1",
    cId: "123456",
    createdOn: "12/12/2019",
    transactionId: "1234567890",
  },
  {
    projectId: 2,
    projectName: "Project 2",
    blockchain: "blockchain 2",
    cId: "123456",
    createdOn: "12/12/2019",
    transactionId: "1234567890",
  },
  {
    projectId: 3,
    projectName: "Project 3",
    blockchain: "blockchain 3",
    cId: "123456",
    createdOn: "12/12/2019",
    transactionId: "1234567890",
  },
  {
    projectId: 4,
    projectName: "Project 4",
    blockchain: "blockchain 4",
    cId: "123456",
    createdOn: "12/12/2019",
    transactionId: "1234567890",
  },
  {
    projectId: 5,
    projectName: "Project 5",
    blockchain: "blockchain 5",
    cId: "123456",
    createdOn: "12/12/2019",
    transactionId: "1234567890",
  },
];

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useState, useEffect, useContext } from "react";
import { notification } from "antd";
import { useNavigate } from "react-router-dom";
import { UserContext } from "../../context";
import { SideNav, Header, Footer, Content } from "../createProject/template";
import { tabIds, defaultList } from "../createProject/constant";
import fetchApi from "../../_config/api";
import {deepCopy, ENTERPRISEDEVELOPERROLE, getAuthToken, getUserDetails} from "../../deps";
import "./styles.less";
import {tempData} from "../endpoints/columns";
import Skeleton from "react-loading-skeleton";

const emptyData = {
  title: "",
  description: "",
  protocol: [],
  wallet: [],
  storage: [],
};

export const CreateApps = ({ showLoader,isLoading }) => {
  const navigate = useNavigate();
  const { user } = useContext(UserContext);
  const [activeTab, setActiveTab] = useState(tabIds.TAB_1);
  const [data, setData] = useState(deepCopy(emptyData));
  const [walletList, setWalletList] = useState([]);
  const [protocolList, setProtocolList] = useState([]);
  const [storageList, setStorageList] = useState(defaultList.storageList);
  const [storageProtocolList, setStorageProtocolList] = useState([]);
  const [didProtocolList, setDidProtocolList] = useState([]);
  const userData = getUserDetails();
  const token = getAuthToken();

  const getList = async () => {
    try {
      showLoader(true);
      const wallets = await fetchApi({
        url: "developer/project-config/WALLETS",
        method: "GET",
      });
      const protocols = await fetchApi({
        url: "developer/project-config/PROTOCOLS",
        method: "GET",
      });
      const storages = await fetchApi({
        url: "developer/project-config/STORAGES",
        method: "GET",
      });
      fetchApi({ method: "GET", url: "endpoints" })
          .then((response) => {
            showLoader(false);
            const { docs = [] } = response;
            let blocklist = [];
            let storelist = [];
            let didlist = [];
            for(let i=0; i < docs.length;i++){
              if(docs[i].infraType === "blockchainNode"){
                blocklist.push(docs[i])
                setProtocolList(blocklist)
              }
              if(docs[i].infraType === "didNode"){
                didlist.push(docs[i])
                setDidProtocolList(didlist)
              }
              if(docs[i].infraType === "storageNode"){
                storelist.push(docs[i])
                setStorageProtocolList(storelist)
              }
            }
          })
      showLoader(false);
    } catch (e) {
      showLoader(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const onChange = (id, value) => {
    data[id] = value;
    setData({ ...data });
  };

  const onPrevNext = (tab) => {
    if (tab === "create") {
      onCreate();
    }
    setActiveTab(tab);
  };

  const onCreate = () => {
    let globalvalue = true;

    const endpointsObject = data.nodeconfig.reduce((acc, endpoint) => {
      const key = Object.keys(endpoint)[0];
      acc[key] = endpoint[key];
      return acc;
    }, {});
    const serviceObject = data.services.reduce((acc, endpoint) => {
      const key = Object.keys(endpoint)[0];
      acc[key] = endpoint[key];
      return acc;
    }, {});
    let industries = [];
    if(data.industries.includes("Others")){
      const updatedArray = data.industries.filter(item => item !== "Others");
      industries = [...updatedArray,data.otherIndustries];
    }else{
      industries = data.industries;
    }
    const requestData = {
      globalSetting: {
          accessDatalake: false,
          accessGasless: false,
          clientID: "",
          sameAPIKey:globalvalue
      },
      maintainerId: userData?.profile.developerId,
      projectName: data.title,
      description: data.description,
      subscriptionId:userData.profile.subscriptionId,
      endpoints: endpointsObject,
      services: serviceObject,
      industries: industries,
    };
    if(userData.profile.userRole === ENTERPRISEDEVELOPERROLE){
      requestData.enterpriseId = userData.profile.enterpriseId;
      requestData.teamId = data.teamId;
    }
    showLoader(true);
    fetchApi({ url: "developer/create-project", data: requestData })
      .then((response) => {
        showLoader(false);
        if(response.statusCode === 201){
          notification.success({
            message: "Successfully created",
            duration: 5,
          });
        }else{
          notification.error({
            message: response.message,
            duration: 5,
          });
        }
          navigate(`/project`);
      })
      .catch((err) => {
        showLoader(false);
        notification.destroy();
        notification.error({
          message: err.message,
          duration: 5,
        });
          navigate(`/project`);
      });
    // navigate(`/project`);
  };

  return (
    <div className="create-app-container">
      <SideNav
        fromLanding={false}
        data={data}
        isLoading={isLoading}
        activeTab={activeTab}
        onPrevNext={onPrevNext}
        role={userData.profile.userRole}
      />

      <div className="main-container">
        <Content
          data={data}
          isLoading={isLoading}
          activeTab={activeTab}
          protocolList={protocolList}
          walletList={walletList}
          storageList={storageList}
          storageProtocolList={storageProtocolList}
          didProtocolList= {didProtocolList}
          onChange={onChange}
          onPrevNext={onPrevNext}
          role={userData.profile.userRole}
        />
      </div>
    </div>
  );
};

export default CreateApps;

import React, {useEffect, useState} from "react";
import { Modal, Select } from "antd";
import { Menus } from "../../assets";
import { Steps } from "antd";
import { Input, Space } from "antd";
import { notification } from "antd";
import fetchApi from "../../../component/_config/api";
import {

  ENTERPRISEADMINROLE,
  ENTERPRISEDEVELOPERROLE,
} from "../../deps";
import { useAuth0 } from "@auth0/auth0-react";

const { Step } = Steps;
notification.config({ duration: 2 });

export const GetEnterpriseIdPopup = ({ isModalOpen, handleCancel,devEnterprise }) => {
  const { loginWithRedirect } = useAuth0();
  const [role, setRole] = useState(false);
  const [inputValue, setInputValue] = useState("");
  const onRoleChange = () => {
    setRole(document.getElementById("cb1").checked);
  };
  useEffect(() => {
    if (devEnterprise) setRole(true);
  }, [devEnterprise]);

  useEffect(() => {
    if (isModalOpen) {
      setInputValue("");
    }
  }, [isModalOpen]);

  const continueNext = () => {
    // console.log(role);
    const enterpriseId = inputValue;
    if(enterpriseId === "") {
      notification.error({
        message: "Please enter valid enterprise id.",
        duration: 2,
      });
      return false;
    };

    fetchApi({
      method: "GET",
      url: `enterprise/${enterpriseId}/enterprise`,
      isDevApp: false,
    })
      .then((response) => {
        if (response.statusCode === 200) {
          if(response.doc != null){
            const authRole = (!role) ? ENTERPRISEADMINROLE: ENTERPRISEDEVELOPERROLE;
            const authOptions = {
              appState: {
                role: authRole,
                type: "ENTERPRISE",
                enterpriseId,
              },
              enterpriseId,
              userRole: authRole,
              userType: "ENTERPRISE",
            }
            loginWithRedirect(authOptions);
          }else{
            notification.error({
              message: "enterprise not exists",
              duration: 3,
            });
          }

        } else {
          notification.error({
            message: response.message,
            duration: 3,
          });
        }
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  return (
    <>
      <Modal title="" footer={null} closable={false} open={isModalOpen}>
        <div className="popup-request-container">
          <div className="close-icon" onClick={handleCancel}>
            <Menus type="close" />
          </div>
          <div className="popup-text">Login</div>
          <div className="column-add-project-container mt-4">
            <Space align="center" direction="vertical" size={20}>
              <Input
                id="enterpriseId"
                className="input-container"
                placeholder="Enterprise Id"
                autoComplete="off"
                value={inputValue}
                onChange={(e) => setInputValue(e.target.value)}
              />
              {devEnterprise?"":<div className="form-group d-flex align-items-center checkboxclr">
                <input type="checkbox" id="cb1" onChange={onRoleChange} />
                <label htmlFor="cb1"></label>
                <span className="pl-10">
                  Do you wish to login as enterprise developer
                </span>
              </div>}
            </Space>
          </div>

          <div className="btn-section mt-4">
            <button className="ok-btn" onClick={continueNext}>
              Continue
            </button>
            <button className="cancel-btn" onClick={handleCancel}>
              Cancel
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

import React from "react";
import Honeywell from "../../../../assets/images/Honeywell.avif";
import LT from "../../../../assets/images/L&T.avif";
import Maersk from "../../../../assets/images/maersk.avif";
import Vfsglobal from "../../../../assets/images/vfs-global.avif";
import Siemens from "../../../../assets/images/siemens.avif";
import '../enterprisePagestyles.less'

export const Clientlogo = () => {

    return (
      <>
        <div className="text-trust">Trusted By</div>
        <div className="clientlogo-wrap">
            <div className="marquee-content">
          <div className="container-fluid">
            <div className="plandetails-sec2-image-container_1">
              <div className="col-md-2">
                <div className="clientlogo-item">
                  <img src={Honeywell} className="" alt="" />
                </div>
              </div>
              <div className="col-md-2">
                <div className="clientlogo-item">
                  <img src={LT} className="" alt="" />
                </div>
              </div>
              <div className="col-md-2">
                <div className="clientlogo-item">
                  <img src={Maersk} className="" alt="" />
                </div>
              </div>
              <div className="col-md-2">
                <div className="clientlogo-item">
                  <img src={Vfsglobal} className="" alt="" />
                </div>
              </div>
              <div className="col-md-2">
                <div className="clientlogo-item">
                  <img src={Siemens} className="" alt="Simens" />
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>

      </>
    );
}

export default Clientlogo;

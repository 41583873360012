import { useEffect, useRef, useState } from 'react';
import './styles.less';
import { fetchApi } from "../../_config/api";
import Loader from './chatLoader';
import { notification } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import React from "react";


export const AIAssistant = ({ showLoader, chatOpen, isopen }) => {
    const [messages, setMessages] = useState([]);
    const [serverMessages, setServerMessages] = useState([]);
    const [isLoading, setIsLoading] = useState(false);
    const [disable, setDisable] = useState(false);
    const handleMessageSubmit = (e) => {
        e.preventDefault();
        const messageInput = e.target.elements.message;
        const newMessage = messageInput.value.trim();
        let timestamp = new Date();
        if (newMessage !== '') {
            setMessages((prevMessages) => [
                ...prevMessages,
                { content: newMessage, role: 'user', time: timestamp.toLocaleTimeString() },
            ]);
            sendMessage(newMessage);
            messageInput.value = '';
        }
    };
    const sendMessage = (message) => {
        setIsLoading(true)
        setDisable(true)
        const data = [message];
        let serverResponse1;
        fetchApi(
            {
                method: 'POST',
                url: 'chat/create',
                data: data,
                useBase: true, isDevApp: false
            })
            .then(response => {
                setIsLoading(false)
                setDisable(false)
                if (response != null && response.doc != null) {
                    serverResponse1 = response.doc.response;
                    if(response.doc.payload.messages !== undefined && response.doc.payload.messages.length > 0){
                        if (response.doc.payload.messages[response.doc.payload.messages.length - 1] !== null){
                            let timestamp = new Date();
                            setMessages((prevMessages) => [
                                ...prevMessages,
                                { content: response.doc.payload.messages[response.doc.payload.messages.length - 1], role: 'Assistant', time: timestamp.toLocaleTimeString() }
                            ])
                        }
                    }
                } else {
                    notification.error({
                        message: response,
                        duration: 2,
                    });
                }
            }).catch(error => {
                setIsLoading(false)
                setDisable(false)
                showLoader(false);
                notification.error({
                    message: error.toString(),
                    duration: 2,
                });
                console.error(error); // Handle any errors if the request fails
            });
    };
    useEffect(() => {
        // Scroll to the bottom of the chat window when new messages are added
        const chatWindow = document.getElementById('chat-messages');
        if (chatWindow != null) {
            chatWindow.scrollTop = chatWindow.scrollHeight;
        }
    }, [messages]);
    const historyTabRef = useRef(null);

    const toggleHistoryTab = () => {
        chatOpen()
    }

    return (
        <>
            <div className={`history-tab-container ${isopen ? "open" : ""}`} ref={historyTabRef}>
                <div className="back-arrow" onClick={toggleHistoryTab}>
                    <CloseOutlined />
                </div>
                <div className="chat-container">
                    <div className="chat-messages" id="chat-messages">
                        <div className="chat-container-text marginBtm50" >
                            <div className="text">Krypcore AI</div>
                        </div>
                        <div className="chat-card-container grid-repeat-container marginBtm50 mt-xl-5">
                            <div className="chat-div">
                                <div className="chat-content">
                                    <h5 className="marginBtm20">Examples</h5>
                                    <div className="chat-box">
                                        <p>What can Krypcore Web3 do for my dApp Development?</p>
                                    </div>
                                </div>
                            </div>

                            <div className="chat-div">
                                <div className="chat-content">
                                    <h5 className="marginBtm20">Capabilities</h5>
                                    <div className="chat-box">
                                        <p>Trained to answer questions about Krypcore Web3.</p>
                                    </div>
                                </div>
                            </div>

                            <div className="chat-div">
                                <div className="chat-content">
                                    <h5 className="marginBtm20">Limitations</h5>
                                    <div className="chat-box">
                                        <p>Limited knowledge about use cases.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                        {messages.map((message, index) => (
                            <div key={index} /*className="message left-msg"*/ className={`message ${message.role === "Assistant" || message.role === "System"  ? "left-msg" : "right-msg"}`}>
                                <div className="msg-bubble">
                                    <div className="msg-info">
                                        <div className="msg-info-name">{message.role.charAt(0).toUpperCase() + message.role.slice(1)}</div>
                                        <div className="msg-info-time">{message.time.toString()}</div>
                                    </div>
                                    <div className="msg-text" style={{ whiteSpace: "pre-line" }}>{message.content}</div>
                                </div>
                            </div>
                        ))}
                    </div>
                    <div>
                        {isLoading ? <Loader /> : ""}
                    </div>
                    <form onSubmit={handleMessageSubmit} className="message-form">
                        <input style={{ height: 40 }} type="text" name="message" placeholder="Type your message" />
                        <button style={{ height: 40, cursor: disable ? "not-allowed" : "pointer", background: disable ? "#a9a9a9" : "" }} className="gradient-button" type="submit" disabled={disable}>SEND</button>
                    </form>
                </div>
            </div>
        </>
    );
};

export default AIAssistant;

import Skeleton from "react-loading-skeleton";
import {TableContent} from "../../../library/table";
import {PaginationSection} from "../../../library/paginationSection";
import React, {useEffect, useState} from "react";
import {dateformatter, deepCopy, DEVELOPERROLE, getPATTokenDetails, getUserDetails} from "../../../deps";
import {useLocation, useParams} from "react-router-dom";
import {fetchApi} from "../../../_config/api";
import Image, {notification, Tooltip} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import "../SmartContract/styles.less";
import addIcon from "../SmartContract/addIcon.png"
import {CreateEndpoint} from "../Endpoints/createEndpoint";
import {columns, Didcolumns, storageandDidcolumns} from "../Endpoints/columns";
import {EntColumns, EntDidcolumns, EntStorageandDidcolumns} from "../Endpoints/enterpriseColumns";
// import {AddContract} from "./addContract";

export const MyEndpointsPage = ({showLoader,isLoading}) => {
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const {
        profile: { userRole },
    } = getUserDetails();
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [hitsList, setHitsList] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);
    const [createEndpointOpen, setCreateEndpointOpen] = useState(false);
    const [role, setRole] = React.useState(null);
    const [apiKey, setApiKey] = React.useState("");
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    console.log(filteredData)
    const location = useLocation();
    useEffect(() => {
            getEndpointsList();
        if (location.state != null && location.state.openModal === true) {
            setCreateEndpointOpen(true);
        }
    }, [])



    useEffect(() => {
        onFilterDataChange();
    }, [data, pageSetting]);


    const getEndpointsList = () => {
        setHitsList([5, 10, 15, 20, 25]);
        showLoader(true);
        const filter = {
            and: [
                {
                    key: "infraType",
                    value: "didNode",
                    opt: "ne",
                },
            ],
        };

        const Options = () => {
            return {
                select: "",
                sort: "",
                skip:  (pageSetting.page - 1) * (pageSetting.itemsPerPage) ,
                limit: pageSetting.itemsPerPage,
            };
        };
        fetchApi({ method: "GET", url: `endpoints/my-endpoints?options=${JSON.stringify(
                Options()
            )}&filter=${JSON.stringify(filter)}`  })
            .then((response) => {
                showLoader(false);
                const { docs = [] } = response;
                // console.log("docs",docs)
                setData(docs)
                setTotalCount(response.meta.totalDocs[0].count)
                /*let list = []
                for(let i=0; i < docs.length;i++){
                  if(docs[i].infraType === tabName){
                    list.push(docs[i])
                  }
                  console.log("response",list)*/

                // }
            })
            .catch(() => {
                showLoader(false);
                setData([]);
            });
    };
    const onFilterDataChange = () => {
        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > data.length
                ? data.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchText
                ? data.filter((item) =>
                    item.status
                        .toLowerCase()
                        .includes(pageSetting.searchText.toLowerCase())
                )
                : deepCopy(data),
                pageSetting.searchText
                    ? data.filter((item) =>
                        item.protocolName
                            .toLowerCase()
                            .includes(pageSetting.searchText.toLowerCase())
                    )
                    : deepCopy(data)
        )
            .filter((item) =>
                pageSetting.filterValue !== ""
                    ? (pageSetting.filterValue === "locked" && item.isLocked) ||
                    (pageSetting.filterValue === "unlocked" && !item.isLocked)
                    : true
            )
            .slice(0, endIndex);
        setFilteredData(filterList);
    };
    useEffect(() => {
        setTotalCount(totalCount);
    }, [data]);


    const onTextChange = (value) => {
        pageSetting.searchText = value;
        setSetting({ ...pageSetting });

    };

    const onRadioChange = (value) => {
        pageSetting.filterValue = value;
        setSetting({ ...pageSetting });
    };

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getEndpointsList();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getEndpointsList();
    };

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;



    const onCreateEndpointModal = () => {
        setCreateEndpointOpen(!createEndpointOpen);
    };
    const openEndpointModal = () => {
        setCreateEndpointOpen(true)
    };
    return (
        <div className="transaction-page-container">
            <div className="table-container">
                <div style={{display:"flex",justifyContent:"space-between"}} className="header-section">
                    <div className="text">My Endpoints</div>
                    <div>
                        <button className="sc-button" onClick={openEndpointModal}>
                            <span style={{padding:"15px",fontFamily:'Poppins' ,fontSize:'18px',fontWeight:'700',lineHeight:'35px'}}><img style={{marginBottom:"2px",marginRight:'5px',marginLeft:"5px"}} width="20px" height="20px" src={addIcon} /> Create </span>
                        </button>
                    </div>
                </div>
                {isLoading ? (<Skeleton height={600} style={{marginTop: '30px'}} baseColor="#262626"
                                        highlightColor="#404040"></Skeleton>) : (
                // {userRole === DEVELOPERROLE ?
                //     <TableContent
                //         rowKey="endpointId"
                //         data={filteredData}
                //         columns={columns}
                //
                //         isDashboard={false}
                //         pagination={false}
                //     />:
                    <TableContent
                        rowKey="endpointId"
                        data={filteredData}
                        columns={columns}

                        isDashboard={false}
                        pagination={false}
                    />
                )
                }
                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={hitsList}
                    totalCount={totalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.itemsPerPage}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />
            <CreateEndpoint
                isOpen={createEndpointOpen}
                toggleState={onCreateEndpointModal}
                reloadCollection={
                    userRole !== DEVELOPERROLE
                        ? getEndpointsList
                        : getEndpointsList
                }
                // activeKey={tabName}
                // query={onCreateChange}
                role={userRole}
            />
        </div>
        </div>
    );
}

import React from 'react'
import { useLocation, useNavigate } from 'react-router-dom';
import fetchApi from '../../_config/api';
import { notification } from 'antd';
import { DEVELOPERROLE } from '../../deps';
import { useAuth0 } from '@auth0/auth0-react';
import { useInView } from 'react-intersection-observer';
import PricingTable from "./PricingTable";

const FooterPricing = () => {

    const onNavigateSignup = () => {
        if(referralCode != null && referralCode !== ""){
            fetchApi({
                method: "POST",
                url: `auth/validateReferer`,
                isDevApp: false,
                data: { referralCode },
            })
                .then((response) => {
                    //console.log("ref--",response)
                    if ( response.statusCode !== 200) {
                        notification.error({
                            message: response.message,
                            duration: 3,
                        });

                    }else {
                        const {emailId: referralEmail } = response?.doc;
                        loginWithRedirect({
                            screen_hint: 'signup',
                            appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                            userRole: DEVELOPERROLE,
                            userType: "INDIVIDUAL",
                            referralCode,
                            referralEmail
                        });
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        }else{
            loginWithRedirect({
                screen_hint: 'signup',
                appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
                userRole: DEVELOPERROLE,
                userType: "INDIVIDUAL",
                referralCode: ""
            });
        }
    };

    const navigate = useNavigate();

    const onNavigate = () => {
        navigate("/contact-us");
        // window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const onNavigatePlan = () => {
        // console.log("plan")
        navigate("/pricing");
        //  window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("referrer");

    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.1 // Trigger animation when 50% of the element is visible
    });

    return (
        <div className='light-theme-pricing-container' id="pricing_works">
            <h2 className={`light-theme-pricing-header ${inView ?'animated animatedFadeInUp fadeInUp':'hidden'}`} >Pricing</h2>
            <div ref={ref} className={` ${inView?'light-theme-pricing-container-inner2pricing animate fadeInUp1':"hidden"}`} >
                <div className='light-theme-pricing-container-inner11'>
                    <div className='light-theme-pricing-container-inner111'>
                        <h3 className='light-theme-pricing-container-header'>Bronze</h3>
                        <p className='light-theme-pricing-container-para'>Access a broad range of features <br/> and tools that empower your <br/> projects.</p>
                        <div className='light-theme-pricing-container-inner'>
                            <span className='light-theme-pricing-feature'>50,000 credits/month</span>
                            <span className='light-theme-pricing-feature'>Build upto 5 dApps</span>
                            <span className='light-theme-pricing-feature'>Community support</span>
                        </div>
                        <button className='light-theme-pricing-btn' onClick={onNavigateSignup}>Sign up</button>
                    </div>
                </div>
                <div className='light-theme-pricing-container-inner12'>
                    <div className='light-theme-pricing-container-inner112'>
                        <h3 className='light-theme-pricing-container-header'>Silver</h3>
                        <p className='light-theme-pricing-container-para'>Unlock a suite of premium <br/>features tailored for professional <br/>teams.</p>
                        <div className='light-theme-pricing-container-inner'>
                            <span className='light-theme-pricing-feature'>150,000 credits/month</span>
                            <span className='light-theme-pricing-feature'>Build upto 15 dApps</span>
                            <span className='light-theme-pricing-feature'>Dedicated support</span>
                        </div>
                        <a href="/contact-us" style={{color:'black'}}> <button className='light-theme-pricing-btn' > $99/Month</button></a>
                    </div>
                </div>
                <div className='light-theme-pricing-container-inner13'>
                    <div className='light-theme-pricing-container-inner113'>
                        <h3 className='light-theme-pricing-container-header'>Gold</h3>
                        <p className='light-theme-pricing-container-para'>Contact us to explore a <br/>  fully tailored service that addresses <br/> your needs.</p>
                        <div className='light-theme-pricing-container-inner'>
                            <span className='light-theme-pricing-feature'>Custom credits </span>
                            <span className='light-theme-pricing-feature'>Custom dApps Limits</span>
                            <span className='light-theme-pricing-feature'>Priority support</span>
                        </div>
                        <a href="/contact-us" style={{color:'black'}}><button className='light-theme-pricing-btn' > Contact Us</button></a>

                    </div>
                </div>
            </div>
            <PricingTable/>
        </div>
    )
}

export default FooterPricing;
import React from 'react'
import Logo1 from '../../../assets/icons/krypcore-logo 1 (1).svg'
import WhiteLogo from '../../../assets/icons/krypcore-white 1.svg'
import Arrowblack from '../../../assets/icons/arrow 31 (1).svg'
import Arrowwhite from '../../../assets/icons/arrow 31.svg'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation } from 'react-router-dom'
import fetchApi from '../../../_config/api'
import { notification } from 'antd'
import { DEVELOPERROLE } from '../../../deps'
import useIntersectionObserver from './useIntersectionObserver'

const BannerBottom = () => {

    const { loginWithRedirect } = useAuth0();
  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("referrer");

  const onNavigateSignup = () => {
    if(referralCode != null && referralCode !== ""){
        fetchApi({
            method: "POST",
            url: `auth/validateReferer`,
            isDevApp: false,
            data: { referralCode },
        })
            .then((response) => {
                //console.log("ref--",response)
                if ( response.statusCode !== 200) {
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });

                }else {
                    const {emailId: referralEmail } = response?.doc;
                    loginWithRedirect({
                        screen_hint: 'signup',
                        appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                        userRole: DEVELOPERROLE,
                        userType: "INDIVIDUAL",
                        referralCode,
                        referralEmail
                    });
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    }else{
        loginWithRedirect({
            screen_hint: 'signup',
            appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
            userRole: DEVELOPERROLE,
            userType: "INDIVIDUAL",
            referralCode: ""
        });
    }
};

const [ref, isInView] = useIntersectionObserver({
    root: null,
    rootMargin: '0px',
    threshold: 0.5,
  });


  return (<>
    <div className='light-theme-banner-bottom-container'>
        <div className='light-theme-banner-bottom-logo'>
            <img src={WhiteLogo} className='light-theme-banner-bottom-logo1'/>
        </div>
        <div>
            <h2 ref={ref} className={`light-theme-banner-bottom-header ${isInView ?'animated animatedFadeInUp fadeInUp':'hidden'}`}>Start Building with  <span className='light-theme-banner-bottom-header-gradient'>Krypcore Web3</span> </h2>
            <p className='light-theme-banner-bottom-para'>Build, manage and scale web3 apps with ease</p>
        </div>
        <div className='light-theme-banner-bottom-btn-container'>
            <button className='light-theme-banner-bottom-btn1' onClick={() => onNavigateSignup()}>
              Get Started   <img src={Arrowwhite} height='15px' width='auto' style={{marginLeft:'5px'}}/>
            </button>
            <a href="/contact-us" style={{color:'black'}}>
               <button className='light-theme-banner-bottom-btn2'>
                 Contact Sales  <img src={Arrowblack} height='15px' width='auto' style={{marginLeft:'5px'}}/> 
              </button>
            </a>
        </div>
    </div>
    {/* <div className='light-theme-banner-bottom-bg'>

    </div> */}
    </>)
}

export default BannerBottom
import React from "react";
import { Menus } from "../../assets";
import { Steps } from "antd";
import { Input, Space, Form } from "antd";
import { Slider } from "antd";
import { notification } from "antd";
import fetchApi from "../../../component/_config/api";
import { getEnterpriseId } from "../../deps";

const { Step } = Steps;
notification.config({ duration: 2 });

export const CreateInstance = ({ step = 0, modalState, reloadInstance }) => {
  const enterpriseId = getEnterpriseId() || "";
  const [form] = Form.useForm();
  const [stepSize, setStepSize] = React.useState(step);
  const [budjetValue, setBudjetValue] = React.useState(0);
  const [maxBudjetValue, setMacxbudjetValue] = React.useState(0);
  const [teams, setTeams] = React.useState([]);
  const [instancedata, setInstancedata] = React.useState({
    name: "",
    teamId: "",
  });

  React.useEffect(() => {
    fetchApi({ method: "GET", url: "teams", isDevApp: false })
      .then((response) => {
        setTeams(response.docs);
      })
      .catch((error) => {
        console.error(error);
      });
  }, []);

  React.useEffect(() => {
    fetchApi({
      method: "GET",
      url: `enterprise/${enterpriseId}`,
      isDevApp: false,
    })
      .then((response) => {
        const { creditAvailable } = response;
        setMacxbudjetValue(creditAvailable);
      })
      .catch(() => {});
  }, []);

  const onChange = (name, value) => {
    setInstancedata({
      ...instancedata,
      [name]: value,
    });
  };

  const nextStep = () => {
    if (stepSize == 0) {
      form
        .validateFields()
        .then(() => {
          setStepSize(1);
        })
        .catch(() => {});
    } else {
      onCreateInstance();
    }
  };

  const backStep = () => {
    setStepSize(0);
  };

  const onCreateInstance = () => {
    let data = {
      ...instancedata,
      allocatedBudget: budjetValue,
      enterpriseRef: enterpriseId,
      serviceRef: "ftmanager",
    };
    fetchApi({
      method: "POST",
      url: "ft-manager/ft-instance",
      isDevApp: false,
      data: data,
    })
      .then((response) => {
        notification.success({ message: "successfully Created", duration: 2 });
        modalState(false);
        reloadInstance();
        setStepSize(0);
        setBudjetValue(0);
        setMacxbudjetValue(250);
        setTeams([]);
        setInstancedata({
          name: "",
          teamId: "",
        });
      })
      .catch((error) => {
        console.error(error);
      });
  };

  return (
    <>
      <div className="popup-request-container">
        <div className="ft-instance-create">
          <Steps current={stepSize} size="large">
            <Step title="Instance Details" />
            <Step title="Allocate Budget" />
          </Steps>
        </div>
        {stepSize == 0 && (
          <InstanceDetails
            onChange={onChange}
            teams={teams}
            data={instancedata}
            form={form}
          />
        )}
        {stepSize == 1 && (
          <IconSlider
            setBudjetValue={setBudjetValue}
            budjetValue={budjetValue}
            max={maxBudjetValue}
            min={0}
          />
        )}
        <div className="btn-section mt-4 ft-instance-spacing">
          <button className="cancel-btn" onClick={backStep}>
            Back
          </button>
          <button className="ok-btn" onClick={nextStep}>
            {stepSize == 1 ? "Create" : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
};

export const InstanceDetails = ({ onChange, teams, data, form }) => {
  const onTextChange = (e) => {
    onChange(e.target.name, e.target.value);
  };

  return (
    <div className="column-add-project-container mt-4">
      <Space direction="vertical" size={20}>
        <Form
          form={form}
          initialValues={{
            remember: true,
          }}
          autoComplete="off"
        >
          <Form.Item
            name="name"
            rules={[
              {
                required: true,
                message: "Please input the Instance Name",
              },
            ]}
          >
            <Input
              style={{ background: "#2e2e2e" }}
              className="input-container"
              placeholder="Instance Name"
              name="name"
              onChange={onTextChange}
              value={data.name}
            />
          </Form.Item>
          <Form.Item
            name="teamId"
            rules={[
              {
                required: true,
                message: "Please select the team",
              },
            ]}
          >
            <select
              style={{ width: "100%" }}
              className="dropdown-filter mint-nft-select"
              name="teamId"
              onChange={onTextChange}
            >
              <option value="">Select Team</option>
              {teams.map((team) => (
                <option value={team._id}>{team.name}</option>
              ))}
            </select>
          </Form.Item>
        </Form>
      </Space>
    </div>
  );
};

export const IconSlider = (
  { setBudjetValue, budjetValue, min = 0, max = 400 },
  props
) => {
  const marks = {
    [`${min}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{min}</strong>,
    },
    [`${max}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{max}</strong>,
    },
  };
  return (
    <div className="icon-wrapper mt-4">
      <Slider
        marks={marks}
        min={min}
        max={max}
        {...props}
        onChange={setBudjetValue}
        value={budjetValue}
      />
      <div className="desc-text mt-5">Allocate Budget For your Instance</div>
    </div>
  );
};

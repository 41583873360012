import React from 'react'
import PlatformCard from './Icons/Architecture 1-min.png'
import './NewLandingPage.less'
import { useInView } from "react-intersection-observer";


const PlatformMobile = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.5 // Trigger animation when 50% of the element is visible
      });
  return (
    <div className="faster-section-new1">
                    <div className="faster-heading">
                        <div>
                            <h1 className="platform-header-two animated animatedFadeInUp fadeInUp">Your All-In-One Toolkit for<br/> Building dApps</h1>
                            <div ref={ref} className={`faster-content ${inView ?'animate fadeInRight':'hidden'}`}>
                                <img   src={PlatformCard} className={`${inView ?'animate fadeInRight':''}`} alt="Supercharge-dApp-Development" />
                            </div>
                            <p className="platform-subheader">Create and deploy decentralized <br/>  applications (dApps) across diverse <br/>  blockchain networks</p>
                         
                        </div>

                        <div className="tenx-card-container-new">
                            <div className="tenx-card">
                                <h2 className="tenx-card-h2">10x</h2>
                                <p className="tenx-card-p text-left">Faster Cross <br/>  Chain Integration</p>
                            </div>
                            <div className="tenx-card">
                                <h2 className="tenx-card-h2 d-flex">50<p className="platformvalue d-flex align-items-end">%</p></h2>
                                <p className="tenx-card-p text-left"> Reduction in <br/>  Development <br/>  Time</p>
                            </div>
                            <div className="tenx-card">
                                <h2 className="tenx-card-h2">3x</h2>
                                <p className="tenx-card-p text-left"> Scalable when <br/>  Compared <br/>  to Competitors</p>
                            </div>
                            
                        </div>

                    </div>
                    <br/>
                </div>
  )
}

export default PlatformMobile
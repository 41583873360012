import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import * as serviceWorker from "./serviceWorker";

import App from "./App";
import { UserProvider } from "./component/context";
import AuthProviderWithHistory from "./auth/AuthProviderWithHistory";
import { MetaMaskProvider } from "metamask-react";
import ReactGA from "react-ga";


const root = ReactDOM.createRoot(document.getElementById("root"));
const TRACKING_ID = process.env.GA_TRACKING_ID;
ReactGA.initialize(TRACKING_ID);
root.render(
  <UserProvider>
    <BrowserRouter>
      <AuthProviderWithHistory>
        <MetaMaskProvider>
            <App />
        </MetaMaskProvider>
      </AuthProviderWithHistory>
    </BrowserRouter>
  </UserProvider>
);
// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();

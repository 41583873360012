import {Col, type, Form, Input, message, Modal, notification, Row, Space, Typography, Radio, Button} from "antd";
import React, {useEffect, useState} from "react";
import fetchApi from "../../../../_config/api";
import {Menus} from "../../../../assets";
import '../styles.less'
import {getUserDetails} from "../../../../deps";
export const CreateDataLakeSetup = (
                                        {
                                            callbackUrl,
                                            setupDetails,
                                            isUpdate,
                                            title,
                                            isModalOpen,
                                            setModalState,
                                            type,
                                        }) => {
    const userDetails = getUserDetails()
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [displayAddress, setDisplayAddress] = useState(false);
    const [displayUrl, setDisplayUrl] = useState(false);
    const [status, setStatus] = useState(setupDetails?.status ? "true" : "false");


    const addSetup = (value) => {
        let url = "dataLake/addSetup"
        if(isUpdate){
            url = "dataLake/editSetup"
            value._id = setupDetails?._id
            value.status = status
            value.keyRef = setupDetails?.keyRef

            if(!value.dbUrl){
                value.dbUrl = setupDetails.dbUrl
            }

        }
        value.subscriptionId = userDetails.profile.subscriptionId
        fetchApi({ method: "POST", url: url, data: value })
            .then((response) => {
                if (response.statusCode === 200) {
                    form.resetFields()
                    callbackUrl()

                    notification.success({
                        message: response.message,
                        duration: 3,
                    });
                }else {
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });
                }
            })
            .catch((error) => {
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3,
                });
            });
    };

    const validateUrl = async () => {
        let url =  setupDetails.dbUrl
        if(displayAddress){
            const ip = form.getFieldValue("ip");
            const port = form.getFieldValue("port");
            const userName = form.getFieldValue("username");
            const password = form.getFieldValue("password");

            if (isUpdate){
                const parsedUrl = new URL(url);

                parsedUrl.username = userName;
                parsedUrl.password = password;

                url = parsedUrl.toString();
            }else {
                if (ip && port) {
                    url = `mongodb://${userName}:${password}@${ip}:${port}`;
                } else if (ip) {
                    url = `mongodb+srv://${userName}:${password}@${ip}`;
                }
            }
        }else{
            url = form.getFieldValue("dbUrl")
        }

        fetchApi({ method: "POST", url: "dataLake/validateDBUrl", data: { dbUrl: url } })
            .then((response) => {
                if (response.statusCode === 200) {
                    setIsSaveDisabled(true);
                    notification.success({
                        message: "Connection Successful",
                        duration: 3,
                    });
                    form.setFieldValue("dbUrl", url)
                } else {
                    setIsSaveDisabled(true);
                    notification.error({
                        message: "Connection failed. MongoDB URL is not reachable.",
                        duration: 3,
                    });
                }
            })
            .catch((error) => {
                setIsSaveDisabled(true);
                notification.error({
                    message: "Connection failed. MongoDB URL is not reachable.",
                    duration: 3,
                });
            });

    };

    const handleDbUrlChange = (e) => {
        setIsSaveDisabled(true);
    };

    const handleEnterUrl = () => {
        setDisplayAddress(false);
        setDisplayUrl(true)

        if (isUpdate){
            form.setFieldsValue({
                dbUrl: setupDetails?.dbUrl,
            });
            setStatus(setupDetails?.status == "true" ? "true" : "false")
            setIsSaveDisabled(false)
        }else{
            form.resetFields(["dbUrl" ])
        }
    };

    const handleEnterAddress = () => {
        setDisplayAddress(true);

        if (isUpdate){
            form.setFieldsValue({
                ip: setupDetails?.hostname,
                port: setupDetails?.port,
                username: setupDetails?.username,
                password: setupDetails?.password,
            });
            setStatus(setupDetails?.status == "true" ? "true" : "false")
            setIsSaveDisabled(false)
        }else {
            form.resetFields(["ip", "port", "username", "password", "dbUrl", "status" ])
        }
    };

    const onRadioChange = (e) => {
        setStatus(e.target.value);
    };

    useEffect(() => {
        setIsSaveDisabled(true);
        setDisplayAddress(false);
        setDisplayUrl(false)
        if(type === "url"){
           handleEnterUrl();
        }else if(type === "address"){
            handleEnterAddress();
            form.setFieldValue("dbUrl" , null)
        }

    }, [isModalOpen]);

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isModalOpen}>
            <div className="nft-collection-popup">
                <div className="close-icon" onClick={setModalState}>
                    <Menus type="close" />
                </div>
                <div className="datalake-popup-header">{title}</div>
                {/*<Space*/}
                {/*    direction="horizontal"*/}
                {/*    size={25}*/}
                {/*    style={{ display: "flex", marginTop: "25px" }}*/}
                {/*>*/}

            {(!displayAddress && !displayUrl) &&(
                <div style={{display:"flex", justifyContent:"space-around"}}>
                    <button onClick={handleEnterUrl}>Enter URL</button>
                    <button onClick={handleEnterAddress}>Enter Address</button>
                </div>
            )}
            <Form
                name="basic"
                ref={formRef}
                form={form}
                initialValues={{
                    remember: false,
                }}
                onFinish={addSetup}
                autoComplete="off"
            >
                {displayAddress && (
                    <>
                        <Form.Item
                            name="ip"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing ip",
                                },
                            ]}
                        >
                            <Input className="datalake-input-container" placeholder="IP Address" onChange={handleDbUrlChange} disabled={isUpdate} />
                        </Form.Item>

                        <Form.Item
                            name="port"
                            rules={[
                                {
                                    required: !isUpdate,
                                    message: "Missing port",
                                },
                            ]}
                        >
                            <Input className="datalake-input-container" placeholder="Port" onChange={handleDbUrlChange} disabled={isUpdate} />
                        </Form.Item>

                        <Form.Item
                            name="username"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing username",
                                },
                            ]}
                        >
                            <Input className="datalake-input-container" placeholder="Username" onChange={handleDbUrlChange} />
                        </Form.Item>

                        <Form.Item
                            name="password"
                            rules={[
                                {
                                    required: true,
                                    message: "Missing password",
                                },
                            ]}
                        >
                            <Input className="datalake-input-container" placeholder="Password" onChange={handleDbUrlChange}   />
                        </Form.Item>
                    </>
                )}

                {(displayUrl || displayAddress) &&(
                    <>
                            {isSaveDisabled && (
                                <div style={{display: "flex", justifyContent: "space-between", position: "relative"}}>
                                    <Form.Item
                                        name="dbUrl"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Missing DB Url",
                                            },
                                        ]}
                                        style={{flex: 1}} // Ensure the Form.Item takes the full width
                                    >
                                        <div style={{position: "relative"}}>
                                            <Input
                                                disabled={displayAddress}
                                                className="datalake-input-container"
                                                style={{
                                                    background: "#2e2e2e",
                                                    color: "#bfbfbf",
                                                    paddingRight: "100px",
                                                }}
                                                placeholder="DB URL"
                                                onChange={handleDbUrlChange}
                                            />
                                            <button
                                                style={{
                                                    height: "35px",
                                                    width: "90px",
                                                    position: "absolute",
                                                    right: "5px",
                                                    top: "10%",
                                                    background: "white"
                                                }}
                                                className="gradient-button"
                                                onClick={validateUrl}
                                                type="button"
                                            >
                                                <span style={{fontSize:"12px"}} className="validate-text">Validate</span>
                                            </button>
                                        </div>
                                    </Form.Item>
                                </div>
                            )}

                        {isUpdate && (
                            <Form.Item
                                name="status"
                            >
                                <label style={{color: "#fff"}}>STATUS: </label>
                                <Radio.Group style={{marginLeft: "30px"}} onChange={onRadioChange} value={status}>
                                    <Radio style={{color: "#fff"}} value="true">ACTIVATE</Radio>
                                    <Radio value="false" style={{color: "#fff"}}>DEACTIVATE</Radio>
                                </Radio.Group>

                            </Form.Item>
                        )}
                        <div className="datalake-save-button-container">
                            <button className="datalake-save-button" type="submit">
                                <span className="datalake-save-button-text">Save</span>
                            </button>
                        </div>

                    </>

                )}
            </Form>
                {/*</Space>*/}
            </div>
            </Modal>
        </>
    );
};

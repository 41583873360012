import React, {useEffect, useState} from "react";
import "./styles.less";
import {useLocation} from "react-router-dom";
import {fetchApi, fetchApiGw} from "../../../_config/api";
import {Carousel} from "./carousel";
import {CarouselBlockchain} from "./carouselBlockchain";
import {dateformatter, ENTERPRISEADMINROLE} from "../../../deps";
import {CopyOutlined, LoadingOutlined} from "@ant-design/icons";
import {Form, notification, Space, Spin,Button,Upload} from "antd";
import { schemaJson } from './schema'
import {setFormData} from "../../nftstudio/utils";
import ReactJson from 'react-json-view';
import APISandboxPage from "./apiSandbox";

const gateWayURL = process.env.REACT_APP_GATEWAY_URL;



export const DappDetailsPage = ({showLoader,isLoading }) => {
    const { pathname } = useLocation();
    const [projectId, setProjectId] = useState("");
    const [projectDetails, setProjectDetails] = useState({});
    const formRef = React.useRef(null);
    const [responseJson,setResponseJson] = React.useState(null);
    const [loading, setLoading] = useState(false);

    const showResponseData = (data) => {
        // const responseBox = document.getElementById('response-box');
        // if (responseBox) {
        //     responseBox.innerHTML = `
        //     <div style="background-color: #383838 ; padding: 10px; margin-top: 5%; overflow-y: auto; max-height: 500px; border-radius: 20px;">
        //          <ReactJson src={responseJson} />
        //     </div>
        // `;
        // }
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const [networks, setNetworks] = React.useState([]);
    const [tokenType, setTokenType] = React.useState([]);
    const [tokenTypeList, setTokenTypeList] = useState([]);
    const [walletList, setWalletList] = React.useState([]);
    const [wallets, setWallets] = useState([]);
    const [subTokenType, setSubTokenType] = React.useState([]);
    const [tokenTypeSubList, setTokenTypeSubList] = useState([]);
    const [schema, setSchema] = useState(null);

    const [schemaDatas, setSchemaDatas] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const customUpload = ({ onChange }) => (
        <Upload onChange={onChange} showUploadList={false}>
            <Button>Upload Image</Button>
        </Upload>
    );
    const tabs = [
        { id: "BlockchainEndpoint", text: "Blockchain" },
        { id: "StorageEndpoint", text: "Storage" },
        // { id: "DIEndpoint", text: "DID" },
        { id: "SCEndpoint", text: "Bundler" },
    ];
    const [activeTab, setActiveTab] = useState("BlockchainEndpoint");

    useEffect(() => {
        const paths = pathname?.slice?.(1).split?.("/");
        if (paths && paths.length === 3) {
            setProjectId(paths[2]);
        }
        console.log("paths ------>",paths,paths.length)
    }, [pathname]);
    console.log("projectId ------>",projectId)
    useEffect(() => {
        if (projectId) {
            getProjectDetails();
        }
    }, [projectId]);
    const getProjectDetails = () => {
        // showLoader(true);
        fetchApi({ method: "GET", url: `developer/projects/${projectId}` })
            .then((response) => {
                console.log("response.doc ------>",response.doc)
                if (response.doc !== null) {
                    console.log("response.doc ------>",response.doc)
                    setProjectDetails(response.doc);
                }
                // showLoader(false);
            })
            .catch(() => {
                // showLoader(false);
            });
    };
    const onTabClick = (value) => {
        setActiveTab(value);
    };
    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };





    React.useEffect(() => {
        getTokenTypeList();
        getWalletList();
        fetchSchema();

    }, []);



    const fetchSchema = async () => {
        try {
            const response = schemaJson
            console.log("response",response)

            setSchemaDatas(response[0]);
            console.log("schemaData")
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    const getTokenTypeList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setTokenTypeList([
            {tokenId:"FT",tokenType:"FT"},{tokenId:"NFT",tokenType:"NFT"},{tokenId:"STORAGE",tokenType:"STORAGE"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };

    const getWalletList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setWalletList([
            {walletId:"dev-wallet",walletType:"Dev Wallet"},{walletId:"non-custodial",walletType:"Self Managed Wallet"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };



    const onChangeSelectNetwork = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setNetworks(event.target.value)
    };

    const onChangeSelectTokenType = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setTokenType(event.target.value)
        console.log("event.target.value",event.target.value)
        if(event.target.value === "FT"){
            setTokenTypeSubList([
                {tokenId:"createERC20",tokenType:"Create ERC20"},
                {tokenId:"erc20-tokens",tokenType:"ERC20 Tokens"},
                {tokenId:"erc20-token",tokenType:"ERC20 Token"},
                {tokenId:"mintFT",tokenType:"Mint FT"},
                {tokenId:"approveFT",tokenType:"Approve FT"},
                {tokenId:"transferFT",tokenType:"Transfer FT"},
                {tokenId:"burnFT",tokenType:"BURN FT"},
                {tokenId:"getTotalSupply",tokenType:"Get Total Supply"},
                {tokenId:"balanceOf",tokenType:"Balance Of"},
            ])
        }else if(event.target.value === "NFT"){
            setTokenTypeSubList([
                {tokenId:"createNFTCollection",tokenType:"Create NFT Collection"},
                {tokenId:"nft-collection-list",tokenType:"NFT Collection List"},
                {tokenId:"nft-collection",tokenType:"NFT Collection"},
                {tokenId:"createNFT",tokenType:"Create NFT"},
                {tokenId:"nft-list",tokenType:"NFT List"}

            ])
        }else if(event.target.value === "STORAGE"){
            setTokenTypeSubList([
                {tokenId:"storefile",tokenType:"Store File"},
                {tokenId:"getFileDetails",tokenType:"Get File Details"},
                {tokenId:"downloadFileFromIPFS",tokenType:"Download File From IPFS"},
                {tokenId:"updatePinStatus",tokenType:"Update Pin Status"},

            ])
        }


    };

    const onChangeSelectWallet = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setWallets(event.target.value)
    };






        return (
            <div className="dapp-details-container">
                <div className="details-section">
                    <div>
                        <div className="dapp-details-header">Token</div>
                        <div className="dapp-details-text">Digital system that facilitates the creation, issuance, and
                            management of digital tokens, enabling secure and efficient transactions across blockchain
                            networks.
                        </div>
                        <div style={{marginTop: "30px"}}>
                            <div className="dapp-details-row">
                                <div className="dapp-details-label"><span className="label-text1">Created on</span>
                                    <span>:</span></div>
                                <div
                                    className="dapp-details-value">{dateformatter(projectDetails?.createdAt, true)}</div>
                            </div>
                            <div className="dapp-details-row">
                                <div className="dapp-details-label">
                                    <span className="label-text">APP ID</span>
                                    <span className="label-colon">:</span>
                                </div>
                                <div className="dapp-details-value">{projectDetails.projectID}&nbsp;&nbsp;<CopyOutlined
                                    style={{marginTop: "5px"}} className="float-right"
                                    onClick={() => onCopy(projectDetails.projectID)}/></div>
                            </div>
                        </div>
                    </div>
                    <div style={{width: "90%"}}>
                        <p className="dapp-details-header">Endpoints</p>
                        <div className="dapp-tab-container">
                            <div className="tab-container" style={{zIndex: 1}}>
                                {tabs.map((projectDetails) => (
                                    <button
                                        key={projectDetails.id}
                                        className={`tab${activeTab === projectDetails.id ? " active-tab" : ""}`}
                                        onClick={() => onTabClick(projectDetails.id)}
                                    >
                                        {projectDetails.text}
                                    </button>
                                ))}
                            </div>
                            <span style={{zIndex: 1}}>
                            {activeTab === "BlockchainEndpoint" && (
                                <CarouselBlockchain items={projectDetails?.endpoints?.BlockchainEndpoint} infraType="blockchainNode"/>
                            )}
                                {activeTab === "StorageEndpoint" && (
                                    <Carousel items={projectDetails?.endpoints?.StorageEndpoint} infraType="storageNode"/>
                                )}
                                {/*{activeTab === "DIEndpoint" && (*/}
                                {/*    <Carousel items={projectDetails?.endpoints?.DIEndpoint}/>*/}
                                {/*)}*/}
                                {activeTab === "SCEndpoint" && (
                                    <Carousel items={projectDetails?.endpoints?.SCEndpoint} infraType="bundlerNode"/>
                                )}
                        </span>
                        </div>
                    </div>


                </div>
                <div className="api-sandbox-layout">{"API Sandbox"}
                    <div className="api-sandbox-line"></div>
                </div>
                <APISandboxPage callingPage={"Dapp"} projectID={""}/>


                <div id="response-box" style={{display: "none"}}>
                    <div
                        style={{
                            backgroundColor: "#383838",
                            padding: "10px",
                            overflowY: "auto",
                            maxHeight: "500px",
                            borderRadius: "20px",
                            marginTop: "50px"
                        }}
                    >
                        <ReactJson style={{backgroundColor: "white", marginTop: 20, overflowX: "auto"}}
                                   enableClipboard={true} src={responseJson}/>

                    </div>
                </div>

            </div>
        );
}
export default DappDetailsPage;

import React, {useState} from "react";
import { Button, Input, DatePicker } from "antd";
import {SearchOutlined, ExportOutlined, CloseCircleOutlined} from "@ant-design/icons";
import { CSVLink } from "react-csv";
import "../styles.less";
import FilterDropdown from "./filterDropDown";

const { RangePicker } = DatePicker;

export const TransactionSearch = ({
                                      onDateChange,
                                      searchTransactionHash,
                                      csvData,
                                      serviceType, // Assuming you have the 'serviceType' data for the FilterDropdown
                                      onchangeInServiceType, // Assuming you have the 'onchangeInServiceType' function for the FilterDropdown
                                  }) => {
    const [showclose,setShowclose] = useState(false)
    const onClear = (e) => {
        document.getElementById('search-text').value = ''
        setShowclose(false)
        searchTransactionHash(e)
    };
    const onKeypress = (e) => {
        if (e.target.value !== '') {
            setShowclose(true)
        }else{
            setShowclose(false)
        }
    };
 return (
    <div className="transaction-search-section">
        <div className="filter-container">

            <FilterDropdown placeholder="Service Type" options={serviceType} onChange={onchangeInServiceType}/>


            <div className="search-box-container">
                {showclose ? <CloseCircleOutlined className="close-icon" onClick={onClear}/> :
                    <SearchOutlined className="search-icon"/>}
                <input
                    type="text"
                    className="input-box"
                    id = 'search-text'
                    placeholder="Txn Hash, Block No, To"
                    onChange={searchTransactionHash}
                    onKeyPress={onKeypress}
                />
            </div>
        </div>
    </div>
 )
};

export default TransactionSearch;

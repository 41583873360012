import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";
import {DEVELOPERROLE, removeAllDataInLocal} from "../../deps";
import {notification,} from "antd";
import fetchApi from "../../_config/api";
import GameIcon from "./Icons/Group 153.svg"
import ShareIcon from "./Icons/Group 154.svg"
import SendIcon from "./Icons/Group 190.svg"
import Logo1 from "./Icons/krypcore-logo 1.svg"
import Youtube from "./Icons/youtube-circle-logo-svgrepo-com (1).svg"
import Twitter1 from "./Icons/x-social-media-round-icon.svg"
import LinkedIn1 from "./Icons/linkedin-svgrepo-com.svg"
import AlertInfo from "../landingPage/ui/AlertInfo";

export const Header = ({onLoginButtonClick}) => {


  const telegram = process.env.REACT_APP_TELEGRAM_URL
  const discord= process.env.REACT_APP_DISCORD_URL
  const linkedIn= process.env.REACT_APP_LINKEDIN_URL
  const youtube=  process.env.REACT_APP_YOUTUBE_URL
  const twitter= process.env.REACT_APP_TWITTER_URL
  const navigate = useNavigate();
  const location = useLocation();
  const {pathname}=location
  const [section,setsection] = useState('')
  let features = ""
   React.useEffect(()=>{
     if (location.state != null){
       if (location.state.value != null) {
         features = location.state.value
         document.getElementById(features).scrollIntoView({behavior: "smooth"})
         setsection(features)

       }
     }

  },[])


  useEffect(()=>{
    navigate({state:{value:null}})
  },[pathname])

  const [isDropdownOpen, setDropdownOpen] = useState(false);

  const handleMouseEnter = () => {
    setDropdownOpen(true);
  };

  const handleMouseLeave = () => {
    setDropdownOpen(false);
  };
  const onViewDocs = () => {
    window.open(process.env.REACT_APP_LANDING_PAGE_DOCS_URL)
   };

  const searchParams = new URLSearchParams(location.search);
  const referralCode = searchParams.get("referrer");

  useEffect(() => {
    //console.log("dataQueryParam header:", referralCode);
  }, [referralCode]);
  const navigateToheader = (value) => {
    if(window.location.pathname !=='/'){
      navigate('/',{state:{value:value}})
}
    document.getElementById(value).scrollIntoView({ behavior: "smooth" })
    setsection(value)
    setDropdownOpen(false);

  };

  const logout_ = () => {
    logout({
      returnTo: window.location.origin,
    });
    removeAllDataInLocal();
  };
  const { loginWithRedirect,loginWithPopup, isAuthenticated, logout, user } = useAuth0();

  const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL
  const onNavigateDeveloper = () => {
    // console.log("developer")
    loginWithRedirect({
      appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
      userRole: DEVELOPERROLE,
      userType: "INDIVIDUAL",
    });

  };
  const onNavigatetosignup = ()=>{
    if(referralCode != null && referralCode !== ""){
      fetchApi({
        method: "POST",
        url: `auth/validateReferer`,
        isDevApp: false,
        data: { referralCode },
      })
          .then((response) => {
            //console.log("ref--",response)
            if ( response.statusCode !== 200) {
              notification.error({
                message: response.message,
                duration: 3,
              });

            }else {
              const {emailId: referralEmail } = response?.doc;
              loginWithRedirect({
                screen_hint: 'signup',
                appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                userRole: DEVELOPERROLE,
                userType: "INDIVIDUAL",
                referralCode,
                referralEmail
              });
            }
          })
          .catch((err) => {
            // console.log(err);
          });
    }else{
      loginWithRedirect({
        screen_hint: 'signup',
        appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
        userRole: DEVELOPERROLE,
        userType: "INDIVIDUAL",
        referralCode: ""
      });
    }


  }


  useEffect(() => {
    if (isAuthenticated && user.email_verified === false) {
      logout_();
      notification.error({
        message: "Please Verify Email!",
        duration: 10,
      });
    }
  }, [isAuthenticated]);
  const highlightActiveLink = () => {
    const scrollPosition = window.scrollY;

    const sectionToLinkMap = {
      home: "home-link",
      "start-with-krypCore": "features-link",
      pricing_works: "plans-link",
      "enterprise-link": "enterprise-link",
    };
    for (const sectionId in sectionToLinkMap) {
      const linkId = sectionToLinkMap[sectionId];
      const sectionElement = document.getElementById(sectionId);
      const linkElement = document.getElementById(linkId);

      if (sectionElement && linkElement) {
        const sectionTop = sectionElement.offsetTop - 80 ;
        const sectionHeight = sectionElement.offsetHeight - 80;
        console.log(`Section ${sectionId}: Top=${sectionTop}, Height=${sectionHeight}`);
        if (scrollPosition >= sectionTop && scrollPosition < sectionTop + sectionHeight) {
          console.log("linkID",linkId,sectionId)
          if (linkId === "home-link") {
            setsection("home");
          } else if (linkId === "features-link") {
            setsection("start-with-krypCore");
          }else if (linkId === "plans-link"){
            setsection("pricing_works");
          }
        }

      }
    }
  };

  useEffect(() => {
    window.addEventListener("scroll", highlightActiveLink);
    return () => {
      window.removeEventListener("scroll", highlightActiveLink);
    };
  }, []);
  const tooltipStyle = {
    background: "white", // Set the background color to white
    color: "black", // Set the text color
    // Add more styles as needed
  };
  const onNavigatekcsensor = () => {
    navigate('/krypcoreSensor')
  };
  const onViewGithub=()=>{
    window.open(process.env.REACT_APP_GITHUB_SDK)
   }

  const onNavigate = () => {
    navigate("/contact-us");
    window.scrollTo({ top: 0, behavior: 'smooth' });
  };

  const handleLoginButtonClick = () => {
      setTimeout(() => {
        navigate('/dashboard')
      }, 1000);
  
  };


  return (
    <>
      <div className="header-wrap">
          <nav className="navbar navbar-expand-lg">
            <div className="navbar-logo-division">
              <Link className="navbar-brand" to="/" aria-label="">
                <img  src={Logo1} alt="Accelerate-Your-Web3-Development-Journey" className="logo"  />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
              <svg width="26" height="20" viewBox="0 0 16 12" fill="white" xmlns="http://www.w3.org/2000/svg">
                <path d="M1 1H15M1 6H15M1 11H15" stroke="white" stroke-width="2" stroke-linecap="round"/>
                </svg>

              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">

             
                <li className="nav-item" onClick={()=>navigateToheader('start-with-krypCore')}>
                  <a style={{color:'black'}} className={"nav-link1"} id="features-link">
                    Features
                  </a>
                </li>
                <li  className="nav-item" onClick={()=>navigateToheader('pricing_works')}>
                  <a style={{color:'black'}}  className={"nav-link1"} id="pricing-link">
                    Pricing
                  </a>
                </li>
                <li  className="nav-item" onClick={()=>navigateToheader('enterprise-wrap')}>
                  <a style={{color:'black'}}  className={"nav-link1"} id="enterprise-link">
                    Enterprise
                  </a>
                </li>
                <li className="nav-item">
                  <a style={{color:'black'}}  className={"nav-link1"} id="resources-link" onMouseEnter={handleMouseEnter}>
                    Resources
                  </a>
                </li>
              </ul>
              <ul className="navbar-nav menu-right ml-auto ">

                <li>
                  <div className="mobile_responsive_header">
                
                    <span
                        className="text-white-sign"
                        onClick={() => {
                          if(isAuthenticated){
                            handleLoginButtonClick()
                          }else{
                            onNavigateDeveloper()
                          }
                        }}
                    >
                      Sign In
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        className="header-signupbtn"
                        onClick={() => {
                          if(isAuthenticated){
                            handleLoginButtonClick()
                          }else{
                            onNavigatetosignup()
                          }
                        }}
                    >
                      Sign Up
                    </button>

                  </div>

                </li>
              </ul>
            </div>
          </nav>

        </div>
        {isDropdownOpen && (

           <div
              onMouseEnter={handleMouseEnter}
              onMouseLeave={handleMouseLeave}
              className="expandResource"
            >
              <div className="headerExpand">
              <div className="header-wrap">
          <nav className="navbar navbar-expand-lg">
            <div className="navbar-logo-division">
              <Link className="navbar-brand" to="/" aria-label="">
                <img  src={Logo1} alt="KrypC" className="logo"  />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon">
                <svg viewBox="64 64 896 896" focusable="false" data-icon="menu-fold" width="1em" height="1em" fill="black" aria-hidden="true"><path d="M408 442h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8zm-8 204c0 4.4 3.6 8 8 8h480c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8H408c-4.4 0-8 3.6-8 8v56zm504-486H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zm0 632H120c-4.4 0-8 3.6-8 8v56c0 4.4 3.6 8 8 8h784c4.4 0 8-3.6 8-8v-56c0-4.4-3.6-8-8-8zM115.4 518.9L271.7 642c5.8 4.6 14.4.5 14.4-6.9V388.9c0-7.4-8.5-11.5-14.4-6.9L115.4 505.1a8.74 8.74 0 000 13.8z"></path></svg>
              </span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav mr-auto">

           

                <li className="nav-item" onClick={()=>navigateToheader('start-with-krypCore')} onMouseEnter={handleMouseLeave}>
                  <a className={"nav-link1"} id="features-link">
                    Features
                  </a>
                </li>
                <li className="nav-item" onClick={()=>navigateToheader('pricing_works')} onMouseEnter={handleMouseLeave}>
                  <a className={"nav-link1"} id="pricing-link">
                    Pricing
                  </a>
                </li>
                <li className="nav-item" onClick={()=>navigateToheader('enterprise-wrap')} onMouseEnter={handleMouseLeave}>
                  <a className={"nav-link1"} id="enterprise-link">
                    Enterprise
                  </a>
                </li>
                <li className="nav-item" onMouseEnter={handleMouseLeave}>
                  <a className={"nav-link1"} id="resources-link" onMouseEnter={handleMouseEnter}>
                    Resources
                  </a>
                </li>


              </ul>
              <ul className="navbar-nav menu-right ml-auto d-flex align-items-center">

                <li>
                  <div>
                  {/* &nbsp;&nbsp; */}

                    {/*<span onMouseEnter={handleMouseEnter} className="text-white">Resources</span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <span className="text-white1"/>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;*/}
                    <span
                        onMouseEnter={handleMouseLeave}
                        className="text-white-sign"
                        onClick={() => {
                          if(isAuthenticated){
                            handleLoginButtonClick()
                          }else{
                            onNavigateDeveloper()
                          }
                        }}
                    >
                      Sign In
                    </span>
                    &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                    <button
                        onMouseEnter={handleMouseLeave}
                        className="header-signupbtn"
                        onClick={() => {
                          if(isAuthenticated){
                            handleLoginButtonClick()
                          }else{
                            onNavigatetosignup()
                          }
                        }}
                    >
                      Sign Up
                    </button>

                  </div>

                </li>
              </ul>
            </div>
          </nav>

        </div>



          <div style={{paddingLeft:'50px',paddingRight:'50px'}}>
          <div class="container-fluid pt-4 pb-5">
                    <div class="row w-100% justify-content-between">
                        <div class="col-md-3 col-xs-12 col-sm-6 text-dark">
                          <div className="dapplandingpage-header3">
                              <div>
                              <h2 class="text-light">Documentation</h2>
                              <p class="text-light text-left">Get familiar with all our features and learn how to start integrating them</p>
                              </div>
                              <div className="d-flex justify-content-start">
                                  <button className="banner_btn_docs text-white mr-1" onClick={onViewDocs}>View Docs</button>
                                  <button className="banner_btn_github ml-1" onClick={onViewGithub}>Github</button>
                              </div>
                          </div>
                        </div>
                        <div class="col-md-3 col-xs-12 col-sm-6">
                          <div className="dapplandingpage-header1 bg-dark" style={{border:'1px solid #3A3C44'}}>
                            <div>
                              <h2 class="text-light">Community</h2>
                              <p class="text-light text-left">Go beyond the website, explore more in our Community</p>
                              </div>
                              <span className="d-flex justify-content-start mr-1">
                                {/*<a href={linkedIn}><img  src={LinkedIn} alt=""/></a>
                                <a href={linkedIn}><img  className="ml-3" src={Twitter} alt=""/></a>*/}
                                <a href={discord} target="_blank"><img  src={GameIcon} style={{height:'40px'}} alt="discord"/></a>
                                <a href={telegram} target="_blank"><img  className="ml-1" src={ShareIcon} style={{height:'40px'}} alt="telegram"/></a>
                                <a href={youtube} target="_blank"><img  className="ml-1" src={Youtube} style={{height:'40px'}} alt="youtube"/></a>
                                <a href={twitter} target="_blank"><img  className="ml-1" src={Twitter1} style={{height:'42px'}} alt="twitter"/></a>
                                <a href={linkedIn} target="_blank"><img  className="ml-1" src={LinkedIn1} style={{width:'40px'}} alt="linkedin"/></a>
                                {/*<a href={youtube}><img  className="ml-3" src={FooterYoutube} alt=""/></a>*/}

                              </span>
                          </div>
                        </div>
                        <a href="/blog" class="col-md-3 col-xs-12 col-sm-6">
                          <div className="dapplandingpage-header ">
                                <span className="d-flex-column justify-content-start mr-3">
                                  <a href="/blog"><img  src={SendIcon}  alt="blog"/></a>
                                    <h2 class="text-dark">Read</h2>
                                    <h2 class="text-dark">our blogs</h2>
                                </span>

                            </div>
                        </a>
                        <div class="col-md-3 col-xs-12 col-sm-6">
                          <div className="dapplandingpage-header2">
                                <span className="d-flex-column justify-content-start mr-3">
                                    <h2 class="text-dark">Have any queries ?</h2>
                                    <button onClick={() => onNavigate()} className="banner_btn_docs-header  mr-1">Contact Us</button>
                                </span>
                            </div>
                        </div>
                    </div>
                </div>
          </div>


              </div>


            </div>
         )}

      <AlertInfo/>
    </>
  );
};

export default Header;

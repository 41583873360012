import React, {useEffect, useRef, useState} from "react";
import {Dropdown, Form, Radio} from "antd";
import { Menus } from "../../assets";
import { SearchOutlined,CloseCircleOutlined } from "@ant-design/icons";
import "./styles.less";
import { Restricted } from "../../../auth/Restricted";
import { ACCESS } from "../../_config/constant";
import {fetchApi} from "../../_config/api";
import {ENTERPRISEDEVELOPERROLE, getUserDetails} from "../../deps";
import Skeleton from "react-loading-skeleton";

export const Filters = ({
  isLoading,
  btnText,
  btnCode = "",
  role = "",
  onTextChange,
  value, onChainChange,
  selectedValue,
  onRadioChange,
  placeHolder = "",
  onNavigate,
  showCreateBtn = true, showChainSelect,showSearch=true, radioOptions = [],
  showFilters = true,
  name = "Project",
  subscriptionId = "",
  projectId = "",
    createBtnClassName = "",
}) => {
  const inputRef = useRef(null);

  // Step 3: Use useEffect to focus the input on mount
  useEffect(() => {
    // Focus the input element
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);
  const { userRole  } = getUserDetails().profile || {};
  const onChange = (e) => {
    onTextChange(e.target.value);
  };
  const [showclose,setShowclose] = useState(false)
  const onClear = (e) => {
    onTextChange('');
    setShowclose(false)
  };
  const onRadio = (e) => {
    onRadioChange(e.target.value);
  };
  const onKeypress = (e) => {
    if (e.target.value !== '') {
      setShowclose(true)
    }else{
      setShowclose(false)
    }
  };

  const [chains, setChains] = React.useState([]);

  const onSelectChange = (e) => {
      onChainChange(e.target.value);
  };


  // {{domain}}/endpoints??options={"skip":0,"limit":8,"sort":{"updatedAt":-1},"select":[]}&filter={"or":[],"and":[{"key":"infraType","value":"blockchainNode","opt":"eq"},{"key":"projectID","value":"PROJECT24","opt":"eq"},{"key":"status","value":"ACTIVE","opt":"eq"}]}
  React.useEffect(() => {

    if(showChainSelect && subscriptionId !== "" && projectId !== "") {

      // console.log("filter-projectId::", projectId);

      const filter = {
        and: [
          {
            key: "projectID",
            value: projectId,
            opt: "eq",
          },
          {
            key: "infraType",
            value: "blockchainNode",
            opt: "eq",
          },
          {
            key: "status",
            value: "ACTIVE",
            opt: "eq",
          },
          {
            key: "subscriptionId",
            value: subscriptionId,
            opt: "eq",
          },
        ],
      };

      const Options = () => {
        return {
          select: "",
          sort: "",
          // skip: 0,
          // limit: 10
        };
      };

      let requestLink = `endpoints?options=${JSON.stringify(
        Options()
      )}&filter=${JSON.stringify(filter)}`;
      
      fetchApi({
        method: "GET",
        // url: "developer/project-config/PROTOCOLS",
        // url: "protocols/getAllProtocols",
        url: requestLink,
        isDevApp: false,
      })
      .then((response) => {
        setChains(response?.docs?.length > 0 ? response.docs : []);
      })
      .catch((error) => {
        console.error(error);
      });
    }
  }, [showChainSelect, subscriptionId, projectId]);


  return (
    <div className="filter-container">
        {
          showChainSelect && (
            <div className="filter-section">
            <select
              className="dropdown-filter create-collection-drop input-container"
              placeholder="Select Chain"
              name="chain"
              onChange={onSelectChange}
            >
              <option value="">Select Chain</option>
              {chains && chains.length > 0 && chains.map((chain, ind) => (
                <option key={ind} value={chain.chainId}>{chain.protocolName}</option>
              ))}
            </select>
          </div>)
        }
      { showSearch && (<div className="search-box-container">
      {isLoading?(<Skeleton height={40} width={150} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <input
            ref={inputRef}
            type="text"
            value={value}
            className="input-box"
            placeholder={placeHolder !== ""?placeHolder:"Search"}
            onChange={onChange}
            onKeyPress={onKeypress}
        />
      )}
        {showclose ?<CloseCircleOutlined className="close-icon" onClick={onClear} />:<SearchOutlined className="search-icon" /> }

      </div>) }

      {showFilters && (
        <div className="filter-section">
          <Dropdown
            overlay={
              <Radio.Group
                className="filter-option-list"
                onChange={onRadio}
                value={selectedValue}
              >
                <span className="option-header">Filter</span>
                {radioOptions && radioOptions.map((item, index) => (
                  <Radio key={index} value={item.value}>
                    {item.text}
                  </Radio>
                ))}
              </Radio.Group>
            }
            trigger={["click"]}
          >
            <button className="filter-btn">
              <Menus type="filter" />
            </button>
          </Dropdown>
        </div>
      )}

      {
        userRole === ENTERPRISEDEVELOPERROLE ?<>
              {showCreateBtn && (<>
                {isLoading?(<Skeleton height={40} width={150}  baseColor="#262626" highlightColor="#404040" ></Skeleton>):( 
                  <Restricted code={btnCode} type={""}>
                    <button className={createBtnClassName !== "" ? createBtnClassName : "createBtn"} onClick={onNavigate}>
                      {btnText}
                    </button>
                  </Restricted>
                )}
             </> )}
            </>
            :<>
          {showCreateBtn && (<>
            {isLoading?(<Skeleton height={40} width={150}  baseColor="#262626" highlightColor="#404040" ></Skeleton>):( 
              <Restricted code={btnCode} type={role}>
                <button className={createBtnClassName !== "" ? createBtnClassName : "createBtn"} onClick={onNavigate}>
                  {btnText}
                </button>
              </Restricted>
            )}
         </> )}
        </>
      }

          {/*{showCreateBtn && (*/}
          {/*    // <Restricted code={btnCode} type={role}>*/}
          {/*      <button className="createBtn" onClick={onNavigate}>*/}
          {/*        {btnText}*/}
          {/*      </button>*/}
          {/*    // </Restricted>*/}
          {/*)}*/}

      {/*}*/}
    </div>
  );
};

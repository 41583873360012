/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useCallback, useEffect, useState } from "react";
import "./styles.less";
import { DEVELOPERROLE, getUserDetails } from "../../deps";
import { SendQuery } from "./send_query";
import { Logos } from "../../assets";
import Skeleton from "react-loading-skeleton";
import gettingstarted from "./gettingstarted.svg";
import settings from "./settings.svg";
import  billing from "./billing.svg";
import faq from "./faq.svg";
import communication from "./communication.svg";
import discord from "./discord.svg";
import twitter from "./twitter.svg";

export const Support = ({ showLoader,isLoading }) => {
    const { profile: { userRole } } = getUserDetails();
    const user = getUserDetails();
    const [query, setQuery] = useState(false);
    const [createRequest, setCreateRequest] = useState(false);

    const onCreateRequest = () => {
        setCreateRequest(!createRequest);
    };
    const reloadPage = () => {
    };

    const onCreateChange = (key) => {
        if (key) {
            setQuery(!query);
        }
    };

    return (
        <>
        {/*{isLoading?(<Skeleton height={190} style={{marginBottom:'50px',marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
            <div className="my-account-page-container">
                <div className="jumbotron jumbotron-fluid kc-jumbotron">
                    <div className="">
                        <h1>Welcome to Krypcore Support</h1>
                    </div>
                </div>
            </div>
        )} */}
            <div className="marginBtm20">
                <div className="title" style={{alignItems:"baseline"}}>
                    {/*<h2 className="lead">Need help? We've got your back</h2>*/}
                    <div>
                        <h1 class="header-two">Welcome to Krypcore Support</h1>
                        <p class="subheader">Need help? We've got your back</p>
                    </div>
                    {isLoading?(<Skeleton height={40} width={150} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <button className="support-button" onClick={onCreateRequest}>
                        Write to Us
                    </button>
                    )}
                </div>
            </div>
            <div className="row mt-5">
                <div className="allCardsContainer">

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href={"https://docs.beta.krypcore.com/getting-started/"} target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={ gettingstarted } alt="Hunter-logo" style={{ color:"white" }}/>
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">Getting Started</h2>
                                        <span className="cardSubTitle">Start from the dApp Studio</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href={"https://docs.beta.krypcore.com/getting-started/user-management/user-account-management"} target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={settings} alt="Hunter-logo" />
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">Account Settings</h2>
                                        <span className="cardSubTitle">Manage your account here</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href={"https://docs.beta.krypcore.com/getting-started/user-management/user-subscription-plans"} target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={billing} alt="Hunter-logo" />
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">Billing</h2>
                                        <span className="cardSubTitle">Manage your Subscription & Billing</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href="https://docs.beta.krypcore.com/krypcore-web3/faq" target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={faq} alt="Hunter-logo" />
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">FAQ</h2>
                                        <span className="cardSubTitle">Frequently Asked Questions</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href="https://twitter.com/krypctalk" target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={twitter} alt="Hunter-logo"/>
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">X</h2>
                                        <span className="cardSubTitle">Meet the Community</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href="https://t.me/KrypC" target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={communication} alt="Hunter-logo"/>
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">Telegram</h2>
                                        <span className="cardSubTitle">Meet the Community</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                    <div className="col-md-3">
                    {isLoading?(<Skeleton height={220} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <a href="https://discord.com/invite/m74sFXNv5W" target="_blank">
                            <div className="eachCard">
                                <div className="cardContent">
                                    <div className="cardImage">
                                        <img src={discord} alt="Hunter-logo"/>
                                    </div>
                                    <div className="cardInfo">
                                        <h2 className="cardTitle">Discord</h2>
                                        <span className="cardSubTitle">Meet the Community</span>
                                    </div>
                                </div>
                            </div>
                        </a>
                    )}
                    </div>

                </div>

            </div>

            <SendQuery
                isOpen={createRequest}
                toggleState={onCreateRequest}
                reloadCollection={reloadPage}
                query={onCreateChange}
                subscription={user.profile.subscriptionId}
                emailId={user.profile.emailId}
                name={user.profile.firstName}
            />
        </>

    );
};

export default Support;

import React from "react";

export const SmartContract = ({ width = "20px", height = "20px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 18 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M6 11H9.75M6 14H9.75M6 17H9.75M12.75 17.75H15C16.2426 17.75 17.25 16.7426 17.25 15.5V5.10822C17.25 3.97324 16.405 3.01015 15.2739 2.91627C14.9006 2.88529 14.5261 2.85858 14.1505 2.83619M8.34952 2.83619C8.28483 3.04602 8.25 3.26894 8.25 3.5C8.25 3.91421 8.58579 4.25 9 4.25H13.5C13.9142 4.25 14.25 3.91421 14.25 3.5C14.25 3.26894 14.2152 3.04602 14.1505 2.83619M8.34952 2.83619C8.63276 1.91757 9.48842 1.25 10.5 1.25H12C13.0116 1.25 13.8672 1.91757 14.1505 2.83619M8.34952 2.83619C7.97386 2.85858 7.59937 2.88529 7.22608 2.91627C6.09499 3.01015 5.25 3.97324 5.25 5.10822V7.25M5.25 7.25H1.875C1.25368 7.25 0.75 7.75368 0.75 8.375V19.625C0.75 20.2463 1.25368 20.75 1.875 20.75H11.625C12.2463 20.75 12.75 20.2463 12.75 19.625V8.375C12.75 7.75368 12.2463 7.25 11.625 7.25H5.25ZM3.75 11H3.7575V11.0075H3.75V11ZM3.75 14H3.7575V14.0075H3.75V14ZM3.75 17H3.7575V17.0075H3.75V17Z"
      stroke="#E3E3E3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

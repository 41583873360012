import {Button, Checkbox, Form, Input, Modal, notification, Space, Spin} from "antd";
import {Menus} from "../../../assets";
import React, {useState} from "react";
import "./styles.less";
import Skeleton from "react-loading-skeleton";
import fetchApi from "../../../_config/api";
import {LoadingOutlined} from "@ant-design/icons";

export const CreateDApp = ({isOpen,toggleState,showLoader,isLoading,getProjectLit}) => {
    const [data, setData] = React.useState({});
    const [protocolList, setProtocolList] = React.useState([]);
    const [storageProtocolList, setStorageProtocolList] = React.useState([]);
    const [didProtocolList, setDidProtocolList] = React.useState([]);
    const [bundlerProtocolList, setBundlerProtocolList] = React.useState([]);
    const [protocolNames, setProtocolNames] = React.useState([]);
    const [bundlerProtocolNames, setBundlerProtocolNames] = React.useState([]);
    const [networkList, setNetworks] = React.useState([]);
    const [bundlerNetworkList, setBundlerNetworkList] = React.useState([]);
    const [blockchainProtocolId,setBlockchainProtocolId] = React.useState("");
    const [bundlerProtocolId,setBundlerProtocolId] = React.useState("");
    const [loading, setLoading] = useState(false);

    const formRef = React.useRef(null);
    const [deployDisabled, setDeployDisabled] = React.useState(false);

    const onSelectChange  = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
    };
    const onCheckboxChange = (event) => {
        console.log("event.target.name",event.target.name,event.target.checked)
        formRef.current?.setFieldsValue({
            [event.target.name]: event.target.checked,
        });
        // onChange(e.target.name, e.target.checked);
    };
    React.useEffect(() => {
        getProtocalList();
    }, []);
    const getProtocalList = () => {
        let blockchainList = []
        let bundlerList = []
        fetchApi({ method: "GET", url: `protocols/getAllProtocols` })
            .then((response) => {
                if (response.docs) {
                    const {docs = []} = response;
                    let didlist = []
                    let storageList = [];
                    for (let i= 0;i< response.docs.length;i++){
                        if(response.docs[i].protocol !== "DID" && response.docs[i].protocolType !== "bundler" && response.docs[i].protocol !== "IPFS"){
                            blockchainList.push(response.docs[i]);
                            //setProtocolList(blockchainList);
                        }
                        if (response.docs[i].protocol === "IPFS"){
                            storageList.push(response.docs[i])
                            setStorageProtocolList(storageList)
                        }
                        // if (response.docs[i].protocol === "DID"){
                        //     didlist.push(response.docs[i])
                        //     setDidProtocolList(didlist)
                        // }
                        if (response.docs[i].protocolType === "bundler"){
                            bundlerList.push(response.docs[i])
                        }
                    }
                }
                // setInfraTypes(defaultList.infraTypes);

            })
            .catch(() => {

            }).finally(() => {

            setProtocolList(blockchainList);
            const grades = [...new Set(blockchainList.map(item => item.protocol))];
            // console.log("grades", [...grades.values()]);
            setProtocolNames([...grades]);
            setBundlerProtocolList(bundlerList);
            const grades_ = [...new Set(bundlerList.map(item => item.protocol))];
            setBundlerProtocolNames([...grades_]);
        });
    };
    const onChangeSelectProtocolName = (event, name,infraType) => {
        // console.log("onChangeSelectProtocolName ", JSON.stringify(event.target.value));
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        if(infraType === "blockchainNode"){
            // let protocol = (event.target.value).split("-");
            if(event.target.value === ""){
                setNetworks([]);
            }else{
                const networks = protocolList.filter((obj) => obj.protocol.includes(event.target.value));
                console.log("networks-->",networks);
                setBlockchainProtocolId(networks[0].chainId);
                setNetworks(networks);
            }
            // console.log("networks ",networks);

        }else{
            if(event.target.value === ""){
                setNetworks([]);
                setBundlerNetworkList([]);
            }else{
                const networks = bundlerProtocolList.filter((obj) => obj.protocol.includes(event.target.value));
                setBundlerProtocolId(networks[0].chainId);
                setBundlerNetworkList(networks);
            }
        }

    };
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    const onCreate = (value) => {
        // showLoader(true);
        setLoading(true);
        let data = {
            ...value,
        };
        const blockchainProtocolName = data.blockchainProtocol + "-" + data.blockchainNetwork;
        const blockchainNetwork = networkList.filter((obj) => obj.name.includes(blockchainProtocolName));
        if(blockchainNetwork.length > 0) {
            data.blockchainProtocolId = blockchainNetwork[0].chainId;
        }
        const bundlerProtocolName = data.bundlerProtocol + "-" + data.bundlerNetwork;
        const bundlerNetwork = bundlerNetworkList.filter((obj) => obj.name.includes(bundlerProtocolName));
        if(bundlerNetwork.length > 0) {
            data.bundlerProtocolId = bundlerNetwork[0].chainId;
        }
        fetchApi({ url: "developer/create-dApp", data: data })
            .then((response) => {
                // showLoader(false);
                if(response.statusCode === 201){
                    setLoading(false);
                    notification.success({
                        message: "Successfully created",
                        duration: 5,
                    });
                    toggleState(); // Close the popup after successful addition
                    formRef.current?.resetFields(); // Clear the form fields
                    getProjectLit();
                }else{
                    setLoading(false);
                    notification.error({
                        message: response.message,
                        duration: 5,
                    });
                }
                // navigate(`/project`);
                toggleState()
                formRef?.current?.resetFields();
                getProjectLit();

            })
            .catch((err) => {
                // showLoader(false);
                setLoading(false);
                notification.destroy();
                notification.error({
                    message: err.message,
                    duration: 5,
                });
                // navigate(`/project`);
            });
    }
    return (
        <>
            <Modal maskStyle={{backgroundColor: '#CFCFCF80'}} centered title="" footer={null} closable={false} open={isOpen}>
                <div>
                    <div className="close-icon" onClick={() => {
                        toggleState();
                        formRef.current?.resetFields();
                    }}>
                        <Menus type="close"/>
                    </div>

                    <div className="modal-title">Create New dApp</div>
                    <div className="create-dapp-container">
                        {/*<Space*/}
                        {/*    direction="vertical"*/}
                        {/*    size={10}*/}
                        {/*>*/}
                            <Form
                                name="basic"
                                ref={formRef}
                                initialValues={{
                                    remember: true,
                                }}
                                onFinish={onCreate}
                                autoComplete="off"
                                layout="vertical"
                            >
                                <div className="form-label">Choose Chain For Blockchain Endpoint</div>
                                <div style={{display: "flex"}}>
                                    <Form.Item name="blockchainProtocol" rules={[
                                        {
                                            required: true,
                                            message: "Missing Chain",
                                        },
                                    ]}>
                                        <>
                                                <select
                                                    className="select-dropdown-filter"
                                                    // style={{background: "transparent"}}
                                                    placeholder="Choose Chain"
                                                    name="blockchainProtocol"
                                                    onChange={(event) => onChangeSelectProtocolName(event, "blockchainProtocol", "blockchainNode")}
                                                >
                                                    <option value="">Choose Chain</option>
                                                    {protocolNames.map((chain, ind) => (
                                                        <option key={chain} value={chain}>
                                                            {chain}
                                                        </option>))}
                                                </select>
                                        </>
                                    </Form.Item>
                                    <Form.Item name="blockchainNetwork" rules={[
                                        {
                                            required: true,
                                            message: "Missing Network",
                                        },
                                    ]}>
                                        <>
                                                <select
                                                    className="select-dropdown-filter"
                                                    // style={{background: "transparent"}}
                                                    placeholder="Select Network"
                                                    name="blockchainNetwork"
                                                    onChange={(event) => onSelectChange(event, "blockchainNetwork")}>
                                                >
                                                    <option value="">Select Network</option>
                                                    {networkList.map((obj, ind) => (

                                                        <option key={obj.network} value={obj.network}>
                                                            {obj.network}
                                                        </option>
                                                    ))
                                                    }
                                                </select>
                                        </>
                                    </Form.Item>
                                </div>
                                <div className="form-label">Choose Chain For Bundler Endpoint</div>
                                <div style={{display: "flex"}}>
                                    <Form.Item name="bundlerProtocol" rules={[
                                        {
                                            required: true,
                                            message: "Missing Chain",
                                        },
                                    ]}>
                                        <>
                                                <select
                                                    className="select-dropdown-filter"
                                                    // style={{background: "transparent"}}
                                                    placeholder="Choose Chain"
                                                    name="bundlerProtocol"
                                                    onChange={(event) => onChangeSelectProtocolName(event, "bundlerProtocol", "bundlerNode")}
                                                >bundlerNode
                                                    <option value="">Choose Chain</option>
                                                    {bundlerProtocolNames.map((chain, ind) => (
                                                        <option key={chain} value={chain}>
                                                            {chain}
                                                        </option>))}
                                                </select>
                                        </>
                                    </Form.Item>
                                    <Form.Item name="bundlerNetwork" rules={[
                                        {
                                            required: true,
                                            message: "Missing Network",
                                        },
                                    ]}>
                                        <>
                                                <select
                                                    className="select-dropdown-filter"
                                                    // style={{background: "transparent"}}
                                                    placeholder="Select Network"
                                                    name="bundlerNetwork"
                                                    onChange={(event) => onSelectChange(event, "bundlerNetwork")}>
                                                >
                                                    <option value="">Select Network</option>
                                                    {bundlerNetworkList.map((obj, ind) => (

                                                        <option key={obj.network} value={obj.network}>
                                                            {obj.network}
                                                        </option>
                                                    ))
                                                    }
                                                </select>
                                        </>
                                    </Form.Item>
                                </div>
                                <div style={{display: "flex"}}>
                                    <Form.Item name="dAppName" label="Name your dApp" rules={[
                                        {
                                            required: true,
                                            message: "Missing dApp Name",
                                        },
                                    ]}>
                                        <Input
                                            className="input-container"
                                            style={{background: "#2e2e2e",color: "#bfbfbf"}}
                                            placeholder="e.g. TradeX"
                                            name="dAppName"
                                        />

                                    </Form.Item>
                                    <Form.Item name="description" label="Short Description" rules={[
                                        {
                                            required: true,
                                            message: "Missing Description",
                                        },
                                    ]}>
                                        <Input
                                            className="input-container"
                                            style={{background: "#2e2e2e",color: "#bfbfbf"}}
                                            placeholder="e.g. Decentralized exchange (DEX) platform"
                                            name="description"
                                        />

                                    </Form.Item>
                                </div>
                                <div>
                                    <Form.Item name="isStorageEndpoint">
                                    <Checkbox onChange={onCheckboxChange} name="isStorageEndpoint">
                                        <span className="form-label">Storage Endpoint</span>
                                    </Checkbox>
                                    </Form.Item>
                                </div>
                                <div style={{paddingTop: "30px", marginLeft: "180px", paddingBottom: "30px"}}>
                                    <Button type="primary" htmlType="submit" loading={loading} icon={loading ? antIcon : null} style={{ display: 'flex', flexDirection: 'row-reverse', gap: '8px', alignItems: 'center', justifyContent: 'center', fontSize: "14px"}} >
                                        {!deployDisabled ? (
                                            "Create"
                                        ) : (
                                            <Spin indicator={antIcon}/>
                                        )}
                                    </Button>
                                </div>
                            </Form>
                        {/*</Space>*/}
                    </div>
                </div>
            </Modal>
        </>
    );
}
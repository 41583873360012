import {Modal, notification, Space} from "antd";
import {Menus} from "../../../assets/menus";
import React, {useEffect, useState} from "react";
import "./styles.less";
import {LoadContract} from "./loadContract";
import {useNavigate} from "react-router-dom";
import {fetchApi} from "../../../_config/api";

export const AddContract = ({isOpen,toggleState,showLoader,isLoading,customId}) => {
    const [loadContractOpen, setLoadContractOpen] = useState(false);
    const navigate = useNavigate();
    const loadContractModal = () => {
        setLoadContractOpen(!loadContractOpen);
    };
    const onClickOfChooseTemplate = () => {
        navigate(`/smart-contract/template`);
    };
    const onClickCreateCustom = () => {
        navigate(`/smart-contract/contract-templates/${customId}`);
    }
    return (
        <>
            <Modal maskStyle={{backgroundColor: '#CFCFCF80'}} centered title="" footer={null} closable={false} open={isOpen}>
                <div>
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="add-contract-modal-title">Add Contract</div>
                   <div className="add-contract-container">
                       <div className="add-contract-choose-button">
                           <div style={{cursor:"pointer"}}  className="add-contract-container-button-text" onClick={onClickOfChooseTemplate}>Choose from Template</div>
                       </div>
                       <div style={{background:'#4C59E3',borderColor:'#4C59E3',cursor:"pointer"}} className="add-contract-choose-button" onClick={onClickCreateCustom}>
                           <div className="add-contract-container-button-text">Create Custom</div>
                       </div>
                       <div style={{background:'#fff',borderColor:'#fff',color:'#4C59E3',cursor:"pointer"}} className="add-contract-choose-button" onClick={()=> setLoadContractOpen(true)}>
                           <div className="add-contract-container-button-text">Load Existing</div>
                       </div>
                   </div>
                </div>
            </Modal>
            <LoadContract
                isLoadContractOpen={loadContractOpen}
                loadContractToogleState={loadContractModal}
            />
        </>
    );
}
import React, { useState, useEffect } from 'react';
import {
    Col,
    Form,
    Input,
    Radio,
    Row,
    Space,
    Tooltip,
    Button,
    Slider, notification, message, Spin, // Import the Slider component
} from 'antd';
import '../style.less';
import fetchApi from "../../../_config/api";
import {getPATTokenDetails, getUserDetails} from "../../../deps";
import {apiCaller} from "../utils/utils";
import {GASLESS_URLS} from "../utils/constants";

export const AddWhitelistedContractLinkedToDApp = ({callBackFunc}) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const userDetails = getUserDetails();
    const token = getPATTokenDetails();
    const authToken = token.patToken;
    const [projectList, setProjectList] = useState([]);
    const [smartContractsList, setSmartContractsList] = useState([]);
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const [instanceList, setInstanceList] = useState([]);
    const [showLoader, setLoader] = useState(false);

    const [selectedProject, setSelectedProject] = useState('')
    const [selectedSmartContract, setSelectedSmartContract] = useState('')

    const [displayContractAddress, setDisplayContractAddress] = useState('')

    async function getAllProjectUsingSubscriptionId() {
        let httpUrl = 'projects/list/projects/' + userDetails.profile.subscriptionId;
        let projectsDetails = await localApiCaller('GET', httpUrl, null);
        setProjectList(projectsDetails);
    }

    const getAllInstance = async () => {
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify({"services": ["smart-contract-studio"]})
        };

        await fetch(`${gwURL}/api/v0/instance/getInstance`, options)
            .then(response => response.json())
            .then((response) => {
                if (response.Status === "SUCCESS") {
                    const {Data = []} = response
                    if (response.Data) {
                        setInstanceList([...Data]);
                    } else {
                        setInstanceList([]);
                    }
                }
            })
            .catch((error) => {
                setInstanceList([])
                notification.error({
                    message: error,
                    duration: 3,
                });
            });
    }

    async  function getSmartContractInstanceByPID(projectId){
        const response = await fetchApi({
            method: "GET",
            url: `developer/projects/${projectId}`,
            isDevApp: false,
        })

        if (response.doc != null){
            setSelectedProject(response.doc)
            await getSmartContractByInstance(response.doc.services.SmartContractStudio.InstanceID)
        }
    }

    const getSmartContractByInstance = async (instanceID) => {
        const instanceObj = instanceList.find(
            (service) => service.instanceId === instanceID
        );
        let smartContractsResp = await localApiCaller('GET', "smart-contract-studio/contracts/byId/" + instanceObj.instanceKey);
        setSmartContractsList(smartContractsResp)
    }

    const onChangeInProjects = async (value) => {
        if (value.target.value != 'all'){
            let parsedObj = JSON.parse(value.target.value)
            await getSmartContractInstanceByPID(parsedObj.id)
        }else {
            setDisplayContractAddress("")
            setSmartContractsList([])
        }
    };

    const onChangeSmartContractAddress = async (value) => {
        if (value.target.value != 'all'){
            setSelectedSmartContract([value.target.value])
            let parsedContract = JSON.parse(value.target.value)
            setDisplayContractAddress(parsedContract?.contractAddress)
        }else {
            setDisplayContractAddress("")
        }
    };

    const localApiCaller = (methodName, url, request) => {
        return fetchApi({ method: methodName, url: url, data: request })
            .then((response) => {
                return response.statusCode == 200 ? response.docs || response.doc : [];
            })
            .catch((error) => {
                throw error; // Re-throw the error
            });
    };

    useEffect(()=>{
            getAllProjectUsingSubscriptionId().then()
            getAllInstance().then()
    },[])

    async function addOrUpdateSubscription(value) {
        setLoader(true);
        try {
            let parsedContract = JSON.parse(selectedSmartContract)

            let formData = {
                contractAddress: parsedContract.contractAddress,
                contractName: parsedContract.name,
                linkedDAppId: selectedProject.projectID,
                gasLimitConfigured: Number(value.gasLimitConfigured),
                chainId: Number(selectedProject.endpoints.BlockchainEndpoint.ChainId)

            }

            let resp = await apiCaller("POST", GASLESS_URLS.ADD_WHITELISTED_CONTRACT_LINKED_DAPP, formData, true)
            if (resp.status) {
                form.resetFields()
                setSelectedSmartContract('')
                setSelectedProject('')
                callBackFunc(null)
            }
        } catch (e) {
            notification.error(
                {
                    message: "Internal Sever Error",
                    duration: 4,
                }
            )
        }

        setLoader(false);
    }

    return (
        <>
                    <Form
                        name="basic"
                        ref={formRef}
                        form={form}
                        initialValues={{
                            remember: false,
                        }}
                        onFinish={addOrUpdateSubscription}
                        autoComplete="off"
                        style={{display:'grid',gap:'25px' ,marginTop:'30px', justifyContent:'center', marginLeft:'45px'}}
                    >
                            <Form.Item
                                name="project"
                                rules={[
                                    {
                                        required: true,
                                        message: 'Missing dApp Name',
                                    },
                                ]}
                            >
                                <select
                                    className="gassless-dropdown-filter"
                                    name="project"
                                    onChange={onChangeInProjects}
                                    style={{ width:"550px "}}
                                >
                                    <option value="all">Choose dApp to whitelist a contract</option>
                                    {projectList.map((item) => (
                                        <option key={item?._id} value={JSON.stringify(item)}>
                                            {item?.value || item}
                                        </option>
                                    ))}
                                </select>
                            </Form.Item>

                        <Form.Item
                            name="contractAddress"
                            rules={[
                                {
                                    require: true,
                                    message: "Missing Contract Address"
                                }
                            ]}
                        >
                            <select
                                className="gassless-dropdown-filter"
                                placeholder="Contract Address"
                                name="contractAddress"
                                onChange={onChangeSmartContractAddress}
                                style={{ width:"550px "}}
                            >
                                <option value="all">Select contract to whitelist</option>
                                {smartContractsList.map((item) => (
                                    <option key={item?.contractAddress} value={JSON.stringify(item)}>
                                        {item?.name}
                                    </option>
                                ))}
                            </select>
                            {displayContractAddress &&<p>{displayContractAddress}</p>}
                        </Form.Item>
                        <Form.Item
                            name="gasLimitConfigured"
                            rules={[{ required: true, message: 'Please select or enter a Contract Address' }]}
                            style={{ width: "610px" }}
                            // className={'column-container'}
                        >
                            <Input
                                id="description"
                                className="input-container"
                                placeholder="Set limit for gas funding ( ether )"
                                style={{ background:"#2e2e2e", color: "#bfbfbf" }}
                            />
                        </Form.Item>


                        <button type="submit" className={"gradient-button"} style={{ marginLeft: "200px"}}>
                            <Spin spinning={showLoader}>
                                Save
                            </Spin>

                        </button>
                    </Form>
        </>
    );
};

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
import "../styles.less";
import {PlanPopup} from "./PlanPopup";
import fetchApi, {fetchApiGw} from "../../../../_config/api";
import {dateformatter, deepCopy} from "../../../../deps";
import {ViewPlanpopup} from "./ViewPlanpopup";
import Skeleton from "react-loading-skeleton";
import {PlanBilling} from "./PlanBilling";
import {TableContent} from "../../../../library/table";
import {columns} from "./billingColumns";
import Restricted from "../../../../../auth/Restricted";
import {PaginationSection} from "../../../../library/paginationSection";
import addIcon from "../../SmartContract/addIcon.png";




export const BillingAndSubscription = ({ showLoader,isLoading }) => {
    const [planPopup, setPlanPopupOpen] = React.useState(false);
    const [planName, setPlanName] = React.useState("");
    const [planId, setPlanId] = React.useState("");
    const [expriyDate, setExpiryDate] = React.useState("");
    const [subscriptionDate, setSubscriptionDate] = React.useState("");
    const [viewPopup, setViewPopupOpen] = React.useState(false);
    const [pageSetting, setSetting] = React.useState({
        page: 1,
        itemsPerPage: 10,
        searchTextBilling: "",
        searchTextConsump:"",
        filterValue: "",
    });
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const [filteredData, setFilteredData] = React.useState([]);
    const [totalCountBilling, setTotalCountBilling] = React.useState(-1);
    const [totalCountConsump, setTotalCountConsump] = React.useState(-1);

    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [hitsList, setHitsList] = React.useState([]);
    const [billingList, setBillingList] = useState([]);
    const [consumptionList, setConsumptionList] = useState([]);

    const onviewPopupOpen = () => {
        setViewPopupOpen(!viewPopup);
    };
    const onviewPopupClose= () => {
        setViewPopupOpen(false);
        getSubscription()

    };

    const onPopupOpen = () => {
        setPlanPopupOpen(!planPopup);
    };
    const onPopupClose= () => {
        setPlanPopupOpen(false);
        getSubscription()

    };
    const  getSubscription = () =>{
        return fetchApi({
            method: "POST",
            url: `subscription/get`,
            isDevApp: false,
        }).then((body) => {
            if (body.statusCode === 200 || body.statusCode === 201){
                // console.log("response",body)
                const { doc = [] } = body;
                setPlanName(doc.planDetails.planName)
                setPlanId(doc.planDetails.subscriptionPlanId)
                setExpiryDate(dateformatter(doc.planDetails.expiryDate, false, false))
                setSubscriptionDate(dateformatter(doc.planDetails.subscriptionDate, false, false))
            }
        });
    }

    useEffect(() => {
        getSubscription()
    }, []);

    useEffect(() => {
        setHitsList([5, 10, 15, 20]);
        getBillingList()
        getCounsumptionist()
    },[])
    useEffect(() => {
        onFilterDataChangeBillingList();
    }, [billingList, pageSetting]);

    useEffect(() => {
        onFilterDataChangeConsump();
    }, [consumptionList, pageSetting]);


    const getBillingList = () => {
        showLoader(true);
        fetchApiGw({ method: "POST",data:{"page":pageSetting.page,"pageSize":pageSetting.itemsPerPage}, url: `${gwURL}`+"/api/v0/subscription/getAll",useBase:false,instanceId:""})
            .then((response) => {
                const { Data = [] } = response;
                if(response.Status === "SUCCESS"){
                    setBillingList(Data.result)
                    setTotalCountBilling(Data.totalCount)
                }
                showLoader(false);
            })
            .catch((response) => {
                setBillingList([])
                showLoader(false);
            });
    };


    const getCounsumptionist = async () => {
        setHitsList([5, 10, 15, 20]);
        showLoader(true);
        fetchApiGw({ method: "POST",data:{"page":pageSetting.page,"pageSize":pageSetting.itemsPerPage}, url: `${gwURL}`+"/api/v0/subscription/getAll",useBase:false,instanceId:""})
            .then((response) => {
                const { Data = [] } = response
                if(response.Status === "SUCCESS"){
                    setConsumptionList(Data.result)
                    setTotalCountConsump(Data.totalCount)
                }
                showLoader(false);
            })
            .catch((response) => {
                setConsumptionList([])
                showLoader(false);
            });
    };


    const onFilterDataChangeBillingList = () => {

        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > billingList.length
                ? billingList.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchTextBilling
                ? billingList.filter((item) =>
                    item.planName
                        .toLowerCase()
                        .includes(pageSetting.searchTextBilling.toLowerCase())
                )
                : deepCopy(billingList)
        )
            .slice(0, endIndex);
        setFilteredData(filterList);
    };

    const onFilterDataChangeConsump = () => {

        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > billingList.length
                ? consumptionList.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchTextConsump
                ? consumptionList.filter((item) =>
                    item.planName
                        .toLowerCase()
                        .includes(pageSetting.searchTextConsump.toLowerCase())
                )
                : deepCopy(consumptionList)
        )
            .slice(0, endIndex);
        setFilteredData(filterList);
    };


    useEffect(() => {
        setTotalCountBilling(totalCountBilling);
    }, [billingList]);

    useEffect(() => {
        setTotalCountConsump(totalCountConsump);
    }, [consumptionList]);


    const onPageChangeBilling = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getBillingList();

    };

    const onPerPageChangeBilling = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getBillingList();
    };

    const onPageChangeConsump = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getCounsumptionist();

    };

    const onPerPageChangeConsump = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getCounsumptionist();
    };

    const lastBilling =
        pageSetting.page * pageSetting.itemsPerPage > totalCountBilling
            ? totalCountBilling
            : pageSetting.page * pageSetting.itemsPerPage;

    const lastConsump =
        pageSetting.page * pageSetting.itemsPerPage > totalCountConsump
            ? totalCountConsump
            : pageSetting.page * pageSetting.itemsPerPage;


    const downloadFile = async (id,fileName,value) => {
        let url = ""
        if (value === "consumption"){
            url =`${gwURL}/api/v0/subscription/billing/consumption`
        }else{
            url =`${gwURL}/api/v0/subscription/billing/purchase`
        }
        fetchApiGw(
            {
                method: 'POST',
                url:url,
                useBase:false,isDevApp:false,instanceId:""
            })
            .then(function (response) {
                showLoader(false);
                // console.log("pdf response", response)
                const base64Data = response.Data;
                downloadBase64File(base64Data,fileName);
            })
            .catch((res) => {
                showLoader(false);
            })
    };
    function downloadBase64File(base64Data, fileName) {
        const byteCharacters = atob(base64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += 512) {
            const slice = byteCharacters.slice(offset, offset + 512);
            const byteNumbers = new Array(slice.length);

            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }
        const blob = new Blob(byteArrays, { type: 'application/pdf' });
        const downloadLink = document.createElement('a');
        downloadLink.href = URL.createObjectURL(blob);
        downloadLink.download = fileName;
        downloadLink.click();
    }

    return (
        <>
            {isLoading?(<Skeleton height={170} style={{marginBottom:'50px',marginTop:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <div className="my-account-page-container">
                        <div className="profile-header" style={{paddingBottom:"20px",display:"flex",justifyContent:"space-between"}}>Subscription
                            <button className="purchase-credit-button">
                                <span style={{padding:"15px"}}>Purchase credits</span>
                            </button></div>
                        <table className="subscription-table">
                            <tr>
                                <th>Existing Plan</th>
                                <th>Activation</th>
                                <th>Expiry</th>
                                <th>Action</th>
                            </tr>
                            <tr>
                                <td>{planName}</td>
                                <td>{subscriptionDate}</td>
                                <td>{expriyDate}</td>
                                <td>
                                    <div className="btn-section1">
                                        <button className="gradient-button" onClick={onPopupOpen} disabled={true}     style={{
                                            border: '1px solid gray',
                                            color: 'black',
                                            background: '#D9D9D9',
                                            fontSize: '14px',
                                            fontWeight: 700,
                                        }}>
                                            Change Plan
                                        </button><button className="gradient-button" onClick={onviewPopupOpen}>
                                        View Plan
                                    </button>
                                    </div>
                                </td>
                            </tr>
                        </table>
                        <br />
                        <br />
                        <div className="profile-header" style={{paddingBottom:"20px"}}>Billing</div>
                        <table className="subscription-table">
                            <tr>
                                <th>Purchase Date</th>
                                <th>Expiry Date</th>
                                <th>Paid Amount</th>
                                <th>Paid Purchased</th>
                                <th>Subscription ID</th>
                                <th>Invoice</th>
                            </tr>
                            {billingList.map((item) => (
                                <tr>
                                    <td>{dateformatter(item?.planDetails?.subscriptionDate, false, false)}</td>
                                    <td>{dateformatter(item?.planDetails?.expiryDate, false, false)}</td>
                                    <td>{item?.paymentDetails != null ?item?.paymentDetails?.amount:0}</td>
                                    <td>{item?.planDetails?.planName}</td>
                                    <td>{item?.subscriptionId}</td>
                                    <td>
                                        <Restricted>
                                            <button className="save-changes-btn1">
                                                <a
                                                    onClick={() => downloadFile(item?._id,"Starter (Freemium)","")}
                                                    target="_new"
                                                >
                                                    {"Download"}
                                                </a>

                                            </button>
                                        </Restricted>
                                    </td>
                                </tr>
                                ))}
                        </table>
                        <PaginationSection
                            last={lastBilling}
                            first={startIndex + 1}
                            hitsList={hitsList}
                            totalCount={totalCountBilling}
                            page={pageSetting.page}
                            itemsPerPage={pageSetting.itemsPerPage}
                            onPageChange={onPageChangeBilling}
                            onPerPageChange={onPerPageChangeBilling}
                        />
                        <br />
                        <br />
                        <div className="profile-header" style={{paddingBottom:"20px"}}>Consumption</div>
                        <table className="subscription-table">
                            <tr>
                                <th>Bill Date</th>
                                <th>Consumption Date</th>
                                <th>Paid Amount</th>
                                <th>Paid Purchased</th>
                                <th>Subscription ID</th>
                                <th>Invoice</th>
                            </tr>
                            {consumptionList.map((item) => (
                                <tr>
                                    <td>{dateformatter(item?.planDetails?.subscriptionDate, false, false)}</td>
                                    <td>{dateformatter(item?.planDetails?.expiryDate, false, false)}</td>
                                    <td>{item?.paymentDetails != null ?item?.paymentDetails?.amount:0}</td>
                                    <td>{item?.planDetails?.planName}</td>
                                    <td>{item?.subscriptionId}</td>
                                    <td>
                                        <Restricted>
                                            <button className="save-changes-btn1">
                                                <a
                                                    onClick={() => downloadFile(item._id,"Consumption Credit","consumption")}
                                                    target="_new"
                                                >
                                                    {"Download"}
                                                </a>

                                            </button>
                                        </Restricted>
                                    </td>
                                </tr>
                            ))}
                        </table>
                        <PaginationSection
                            last={lastConsump}
                            first={startIndex + 1}
                            hitsList={hitsList}
                            totalCount={totalCountConsump}
                            page={pageSetting.page}
                            itemsPerPage={pageSetting.itemsPerPage}
                            onPageChange={onPageChangeConsump}
                            onPerPageChange={onPerPageChangeConsump}
                        />
                        <PlanPopup
                            isOpen={planPopup}
                            toggleState={onPopupOpen}
                            plan={planName}
                            handlecancel={onPopupClose}
                            reload = {getSubscription}
                        />
                        <ViewPlanpopup
                            Open={viewPopup}
                            toggleState={onviewPopupOpen}
                            handlecancel={onviewPopupClose}
                            planName={planName}
                        />
                    </div>
            )}
            {/*<PlanBilling*/}
            {/*    showLoader={showLoader}*/}
            {/*    isLoading={isLoading}*/}
            {/*/>*/}
        </>);
};

export default BillingAndSubscription;

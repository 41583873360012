import React, { useEffect, useState } from 'react'
import './NewLandingPage.less'
import Header from './Header'
import Banner from './Banner'
import {StartwithKrypcore} from "./startwithKrypcore";
import Platform from "./platform";
import ServicesPage from "./servicesPage";
import Clientlogo from './Clientlogo';
import Pricingplan from './Pricingplan';
import EnterprisePage from './EnterprisePage';
import NewLandingFooter from './NewLandingFooter';
import { Helmet } from 'react-helmet';
import { useLocation, useNavigate } from 'react-router-dom';
import { useAuth0 } from "@auth0/auth0-react";
import { notification } from "antd";

const NewLandingPage = () => {
    console.log(window.scrollTo)



  return (
    <>
    <Helmet>
                  <meta charSet="utf-8" />
                  <title>Accelerate Web3 Development- Krypcore's dApp dev Tools</title>
                  <meta name="description" content="Krypcore simplifies dApp development for developers, startups, and enterprises. Explore our robust APIs, SDK, and web3 development tools for a seamless experience." />
                  <link rel="canonical" href="http://mysite.com/example" />
                </Helmet>
    <div className='new-landing-page-ui'>
        <div className="banner-bg">
            <Header />
            <Banner/>
        </div>
        <Clientlogo/>
        <Platform />
        <ServicesPage />
        <StartwithKrypcore/>
        <Pricingplan/>
        <EnterprisePage/>
        <NewLandingFooter/>
    </div>
 </>)
}

export default NewLandingPage
import React from 'react'

import Terms from './termsandConditionsPage'
import Header from '../new-light-theme/ui/Header'
import Footer from '../new-light-theme/ui/Footer'


const NewTerms = () => {
  return (
    <div style={{backgroundColor:'black'}}>
     <Header/>
       <Terms/>
     <Footer/>
    </div>
  )
}

export default NewTerms
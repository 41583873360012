import React, {useRef, useState} from "react";
import './NewLandingPage.less'
import PlatformCard from './Icons/Architecture 1-min.png'
import './NewLandingPage.less'
import { useInView } from "react-intersection-observer";
import PlatformMobile from "./PlatformMobile";

export const Platform = () => {

    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.5 // Trigger animation when 50% of the element is visible
      });

    return (
        <>

            <div className="tenx-section">
                <div className="faster-section-new">
                    <div className="faster-heading">
                        <div>
                            <h2  className={`platform-header-two ${inView ? 'animated animatedFadeInUp fadeInUp' : 'hidden'}`}>Your All-In-One Toolkit for <br/>Building dApps</h2>
                            {/* <p className="platform-subheader">Create and deploy decentralized applications (dApps) across <br/> diverse blockchain networks</p> */}
                            <p className="platform-subheader">Our dApp development platform offers cutting-edge web3 infrastructure<br/> such as customizable node options, support for various <br/>blockchain networks, and storage endpoints</p>
                            </div>

                        <div className="tenx-card-container-new">
                            <div className="tenx-card">
                                <h3 className="tenx-card-h2">10x</h3>
                                <p className="tenx-card-p text-left">Faster Cross Chain <br/> Integration</p>
                            </div>
                            <div className="tenx-card">
                                <h3 className="tenx-card-h2 d-flex align-items-end">50<p className="platformvalue">%</p></h3>
                                <p className="tenx-card-p text-left"> Reduction in <br/> Development Time</p>
                            </div>
                            <div className="tenx-card">
                                <h3 className="tenx-card-h2">3x</h3>
                                <p className="tenx-card-p text-left"><span className="text-nowrap">Scalable when Compared</span>  <br/> to Competitors</p>
                            </div>
                           
                        </div>

                    </div>
                    <div  className={`faster-content ${inView ?'animate fadeInRight':'hidden'}`}>
                                <img ref={ref}  src={PlatformCard} className={`${inView ?'animate fadeInRight':''}`} alt="Supercharge-dApp-Development" />
                            </div><br/>
                </div>
                <div className="platform-bg-image">
                
                </div>
                <PlatformMobile/>

            </div>



            {/*<div className="platform-platform-section">
                <div className="platform-card-container row">
                    <div className="col-md-6 text-center">
                        <h1 className="platform-header-two">One Stop Platform <br/>for dApp Development</h1>
                        <p className="platform-subheader">Create and deploy decentralized (dApps) across diverse blockchain networks</p>
                        <p className="platform-subheader">Benefit from our cutting-edge infrastructure featuring<br/>
                        flexible endpoints, customizable node options and extensive blockchain/ storage support</p>
                    </div>
                    <div className="col-md-6 text-center">
                        <div className="platform-card-bg">
                            <img  src={PlatformCard} alt={"Platform Card"}/>
                        </div>
                    </div>
                </div>
            </div>*/}
        </>
    );
};

export default Platform;

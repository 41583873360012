/* eslint-disable react-hooks/exhaustive-deps */
import React, { useRef, useState, useEffect } from "react"; // useContext
import { fetchApi } from "../../../_config/api";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import {
    DEVELOPERROLE,
    DEVELOPERTYPE,
    getUserDetails,
    getPATTokenDetails,
    scstudioinputValidation,
    onKeyPressInputValidation
} from "../../../deps";
import "./styles.less";
import { Col, Form, Input, notification, Popconfirm, Row, Space, Spin, Tooltip } from 'antd';
import { useLocation, useNavigate, useParams } from "react-router-dom";
import Highlight from "react-highlight";
import TextArea from "antd/es/input/TextArea";
import { Buffer } from "buffer";
import TruncateText from "../../../library/TrunucateText/truncate";
import Skeleton from "react-loading-skeleton";

const STANDARD = {
    ERC20: "ERC20",
    ERC721: "ERC721",
    ERC1155: "ERC1155",
}

const CUSTODIAL_WALLET = "custodial";
const NON_CUSTODIAL_WALLET = "non-custodial";
const DEVELOPER_WALLET = "developer";

export const onCopy = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    notification.success({
        message: "Copied to clipboard",
        duration: 3,
    });
};
export const DeployContracts = ({ showLoader, id, isLoading }) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const walletURL = gwURL + "/api/v0/wallet";
    const params = useParams();
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const formRef = useRef(null);
    const {
        profile: { userRole = DEVELOPERROLE, userType, subscriptionId },
    } = getUserDetails();
    const token = getPATTokenDetails();
    const authToken = token.patToken; // subscriptionId + "_" +

    const [deployDisabled, setDeployDisabled] = useState(false);
    const [uniqueId, setUniqueId] = useState("");
    const [inputParams, setInputParams] = useState([]);
    const [contractResponse, setResponse] = useState({});
    const [contract, setContract] = useState("");
    const [chains, setChains] = useState([]);
    const [chainValue, setChainValue] = useState("");
    const [contractType, setContractType] = useState("");
    const [contractCode, setContractCode] = useState("");
    const [tokenName, setTokenName] = useState("");
    const [privateKey, setPrivateKey] = useState("");
    const [custodialWallets, setCustodialWallets] = useState([]);
    const [instanceList, setInstanceList] = useState([]);
    const [instance, setInstance] = useState(null);
    const [custodialWallet, setCustodialWallet] = useState({});
    const [walletType, setWalletType] = React.useState("");
    const [ncWalletData, setNCWalletData] = useState([]);
    const [nonCustodialWallet, setNonCustodialWallet] = useState({});
    const [developerInstance, setDeveloperInstance] = useState(null);
    const [formData, setFormData] = useState({});
    const [canSubmit, setCanSubmit] = React.useState(false);
    const [confirmationMessage, setConfirmationMessage] = React.useState("");
    const [confirmLoading, setConfirmLoading] = useState(false);

    const [walletDetails, setWalletDetails] = useState();
    const [apiKey, setApiKey] = useState("");
    const [SCInstanceDetail, setSCInstanceDetail] = useState({});
    let chainIDVal = "";
    const location = useLocation();
    if (location.state != null) {
        chainIDVal = location.state.chainIDVal;
    }
    const [projectList, setProjectList] = useState([]);
    const [projectId, setProjectId] = React.useState("");
    const [projectDetails, setProjectDetails] = React.useState({});
    const [formChainId, setFormChainId] = React.useState("");
    const [formWalletType, setFormWalletType] = React.useState("");

    React.useEffect(() => {
        getProjectList();
    }, []);
    React.useEffect(() => {
        if(projectId !== ""){
            getChainList();
        }
    }, [projectId]);

    const getChainList = async () => {
        const filter = {
            and: [
                {
                    key: "projectID",
                    value: projectId,
                    opt: "eq",
                },
                {
                    key: "subscriptionId",
                    value: subscriptionId,
                    opt: "eq",
                },
                {
                    key: "infraType",
                    value: "blockchainNode",
                    opt: "eq",
                },
                {
                    key: "status",
                    value: "ACTIVE",
                    opt: "eq",
                },
            ],
        };

        const Options = () => {
            return {
                select: "",
                sort: "",
            };
        };

        let requestLink = `endpoints?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({
            method: "GET",
            url: requestLink,
            isDevApp: false,
        })
            .then((response) => {
                setChains(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }


    const getProjectList = async () => {
        fetchApi({
            method: "GET",
            url: "developer/project-list",
            isDevApp: false,
        })
            .then((response) => {
                setProjectList(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    React.useEffect(() => {

        if (instance) {
            const filter = {
                and: [
                    {
                        key: "projectID",
                        value: instance?.ProjectId,
                        opt: "eq",
                    },
                    {
                        key: "subscriptionId",
                        value: subscriptionId,
                        opt: "eq",
                    },
                    {
                        key: "infraType",
                        value: "blockchainNode",
                        opt: "eq",
                    },
                    {
                        key: "status",
                        value: "ACTIVE",
                        opt: "eq",
                    },
                ],
            };

            const Options = () => {
                return {
                    select: "",
                    sort: "",
                    // skip: 0,
                    // limit: 10
                };
            };

            let requestLink = `endpoints?options=${JSON.stringify(
                Options()
            )}&filter=${JSON.stringify(filter)}`;

            fetchApi({
                method: "GET",
                url: requestLink,
                isDevApp: false,
            })
                .then((response) => {
                    setChains(response.docs);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
        }

    }, [instance]);

    const onChangeWallet = (event, name) => {
        const walletType = event.target.value;
        setWalletType(walletType);
        setFormWalletType(walletType);
        if (walletType === NON_CUSTODIAL_WALLET) {
            getAllNCWallets()
        }
        if (walletType === CUSTODIAL_WALLET) {
            getAllCWallets()
        }
    };

    const getAllNCWallets = () => {
        console.log("getAllNCWallets-instance ", instance)
        const { services: { NonCustodialWallet }  } = projectDetails;
        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken,
                'Instanceid': NonCustodialWallet?.InstanceID,
                'ChainId':chainValue
            },
            body: JSON.stringify({
                mode: "api",
                //   serviceKey:instanceKey
            })
        };
        fetch(`${gwURL}/api/v0/ncWallet/getAllWallets`, options)
            .then(response => response.json())
            .then((response) => {
                // showLoader(false);
                if (response.Status === "SUCCESS") {
                    if (response.Data) {
                        setNCWalletData(response.Data)
                    } else {
                        setNCWalletData([]);
                    }
                }
            })
            .catch((error) => {
                // console.log("err::", error);
                setNCWalletData([]);
                // showLoader(false);
            });
    };

    const getAllCWallets = () => {
        console.log("getAllCWallets-instance ", instance)
        const { instanceKey, projectDetails: { services: { CustodialWallet } } } = instance;

        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken,
                'Instanceid': CustodialWallet?.InstanceID,
                'ChainId':chainValue
            },
            body: JSON.stringify({
                "mode": "api",
                "serviceKey": instanceKey
            })
        };
        fetch(`${walletURL}/getAllWallets`, options)
            .then(response => response.json())
            .then((response) => {
                // showLoader(false);
                if (response.Status === "SUCCESS") {
                    if (response.Data) {
                        setCustodialWallets(response.Data)
                    } else {
                        setCustodialWallets([]);
                    }
                }
            })
            .catch((response) => {
                setCustodialWallets([]);
                // showLoader(false);
            });
    };

    React.useEffect(() => {

        getAllInstance();
    }, [params.id]);

    useEffect(() => {
        const paths = pathname?.slice?.(1).split?.("/");
        if (paths && paths.length === 2) {
            // setUniqueId(paths[1]);
        }
        setUniqueId(params.id);
    }, [pathname]);

    useEffect(() => {
        if (uniqueId) {
            getTemplateDetails();
        }
    }, [uniqueId]);

    const getTemplateDetails = () => {
        showLoader(true);
        fetchApi({ method: "GET", url: `smart-contract-studio/templates/${uniqueId}` })
            .then((response) => {
                if (response) {
                    setResponse(response.doc);
                    setContract(response.doc.contractCodeAsBase64);
                    setInputParams(response.doc.inputParameters);
                    setContractType(response.doc.templateType)
                }
            })
            .catch(() => {
                showLoader(false);
            });
    };

    const getAllInstance = () => {
        showLoader(true);
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify({ "services": ["smart-contract-studio"] })
        };

        fetch(`${gwURL}/api/v0/instance/getInstance`, options)
            .then(response => response.json())
            .then((response) => {
                showLoader(false);
                if (response.Status === "SUCCESS") {
                    const { Data = [] } = response
                    if (response.Data) {
                        setInstanceList([...Data]);

                        const instanceObj = Data.find(e => e.instanceId === params?.instanceId);
                        if (instanceObj && !instanceObj?.instanceId) {
                            notification.error({
                                message: "instanceId not found",
                                duration: 3,
                            });
                        }
                        setInstance(instanceObj);

                    } else {
                        setInstanceList([]);
                    }
                }
            })
            .catch((response) => {
                setInstanceList([]);
                showLoader(false);
            });
    };

    React.useEffect(() => {
        formRef?.current?.resetFields();
    }, []);

    const cancel = () => {
        setCanSubmit(false);
    };

    const fetchGasFee = async () => {

        if (tokenName === "" || tokenName === undefined) {
            notification.error({
                message: "Name is mandatory",
                duration: 2,
            });
            return false;
        }

        if (!contractResponse || typeof contractResponse === "undefined") {
            notification.error({
                message: "template data missing, please try again later",
                duration: 2,
            });
            return false;
        }

        if (chainValue === "" || chainValue === undefined) {
            notification.error({
                message: "please select chain to deploy",
                duration: 2,
            });
            return false;
        }

        if (walletType === undefined) {
            notification.error({
                message: "please select wallet type to deploy",
                duration: 2,
            });
            return false;
        }

        let data = {
            ...formRef.current?.getFieldsValue(),
        };
        let array = [];
        array.push(data)
        let paramList = []
        const objKeys = Object.keys(data);
        objKeys.forEach((key) => {
            const newObj = {
                paramName: "",
                paramType: "",
                paramValue: ""
            }
            array.forEach((obj, index) => {
                newObj.paramName = key;
                newObj.paramType = inputParams.find((ele) => ele.paramName === key)?.paramType;
                newObj.paramValue = obj[key];
                if (newObj.paramValue === "" || newObj.paramValue === undefined) {
                    notification.error({
                        message: newObj.paramName + " is mandatory",
                        duration: 2,
                    });
                    return false;
                }
                paramList.push(newObj);
            })
        });

        console.log("contractResponse:::", contractResponse);
        console.log("contractCode:::", contractCode);
        console.log("data:::", data);
        console.log("walletType:::", walletType);

        let serviceApiKey = custodialWallet.serviceApiKey;
        let walletInstanceId = custodialWallet.instanceId;
        // let url = "/api/v0/wallet/estimateGas";
        if (walletType === NON_CUSTODIAL_WALLET) {
            console.log("nonCustodialWallet:::", nonCustodialWallet);
            serviceApiKey = nonCustodialWallet.walletId;
            walletInstanceId = nonCustodialWallet.instanceID;
            // url = "/api/v0/ncWallet/estimateGas";
        }

        if (walletType === DEVELOPER_WALLET) {
            serviceApiKey = "";
            walletInstanceId = developerInstance;
        }

        console.log("walletInstanceId:::", walletInstanceId);
        console.log("typeof - walletInstanceId:::", typeof walletInstanceId);
        console.log("serviceApiKey:::", serviceApiKey);
        console.log("typeof - serviceApiKey:::", typeof serviceApiKey);
        if (walletType === NON_CUSTODIAL_WALLET && (serviceApiKey === undefined || walletInstanceId === undefined)) {
            notification.error({
                message: "please choose wallet to deploy",
                duration: 2,
            });
            return false;
        }

        console.log("privateKey: ", privateKey)
        if (walletType === DEVELOPER_WALLET && privateKey === undefined) {
            notification.error({
                message: "please enter the private key",
                duration: 2,
            });
            return false;
        }

        console.log("privateKey: ", privateKey)
        if (walletType === DEVELOPER_WALLET && walletInstanceId === "") {
            notification.error({
                message: "Developer wallet instance missing",
                duration: 2,
            });
            return false;
        }

        setDeployDisabled(true);
        setConfirmLoading(true);

        console.log("Data: ", data);
        let newPrivateKey = privateKey;
        if(newPrivateKey !== undefined && newPrivateKey !== null && !newPrivateKey.startsWith('0x')){
            newPrivateKey = '0x'+newPrivateKey;
        }
        const payload = {
            name: tokenName,
            mode: "api",
            serviceAPIKey: serviceApiKey,
            chainId: chainValue,
            gas: 0,
            value: 0,
            method: "",
            params: paramList,
            isContractTxn: true,
            contractABI: contractResponse?.contractABIArrayAsBase64,
            byteCode: contractResponse?.byteCode,
            contractCodeAsBase64: Buffer.from(contractCode).toString("base64").replace(/=*$/, ""),
            walletType: walletType,
            walletInstance: walletInstanceId,
            privateKey: newPrivateKey
        };
        console.log("dAppID->",projectId)
        const options = {
            method: "POST",
            url: `smart-contract-studio/estimateGas`,
            data: payload,
            isDevApp: false,
            dAppID: projectId
        }
        fetchApi(options).then((response) => {
            if (response.statusCode === 200 && response.doc !== null) {
                const { doc = {} } = response;
                setConfirmationMessage("Do you want to proceed with this gas fee " + doc.estimatedGas + " Gwei ?");
                setCanSubmit(true);
            } else {
                notification.error({
                    message: response.message,
                    duration: 3,
                });
                setCanSubmit(false);
            }
            setDeployDisabled(false);
        })
            .catch((error) => {
                // console.error("ee",error);
                setDeployDisabled(false);
                setCanSubmit(false);
                notification.error({
                    message: error.toString(),
                    duration: 3,
                });
            }).finally((error) => {
            setConfirmLoading(false);
        });
    };

    const onDeployContract = (value) => {

        if (tokenName === "" || tokenName === undefined) {
            notification.error({
                message: "Name is mandatory",
                duration: 2,
            });
            return false;
        }

        let array = [];
        array.push(value)
        let paramList = []
        let name;
        let data = {};
        const objKeys = Object.keys(value);
        objKeys.forEach((key) => {
            const newObj = {
                paramName: "",
                paramType: "",
                paramValue: ""
            }
            array.forEach((obj, index) => {
                newObj.paramName = key;
                newObj.paramType = inputParams.find((ele) => ele.paramName === key)?.paramType;
                newObj.paramValue = obj[key];
                if (newObj.paramValue === "" || newObj.paramValue === undefined) {
                    notification.error({
                        message: newObj.paramName + " is mandatory",
                        duration: 2,
                    });
                    return false;
                }
                // if (newObj.paramName === "_tokenName") {
                //     name = newObj.paramValue
                // }
                paramList.push(newObj);
            })
        })

        if (chainValue === "" || chainValue === undefined) {
            notification.error({
                message: "chainValue is mandatory",
                duration: 2,
            });
            return false;
        }


        console.log("contractResponse:::", contractResponse);
        console.log("contractCode:::", contractCode);

        if ((contractCode === "" || contractCode === undefined) && contractResponse.templateType === "CUSTOM") {
            notification.error({
                message: "Code is mandatory",
                duration: 2,
            });
            return false;
        }

        let base64Value;
        if (contractResponse.templateType === "CUSTOM") {
            base64Value = Buffer.from(contractCode)
                .toString("base64")
                .replace(/=*$/, "")
        }

        console.log("value::", value);
        console.log("walletType::", walletType);

        let serviceApiKey = custodialWallet.serviceApiKey;
        let walletInstanceId = custodialWallet.instanceId;
        let walletAddress = custodialWallet.address;
        if (walletType === NON_CUSTODIAL_WALLET) {
            console.log("nonCustodialWallet:::", nonCustodialWallet);
            serviceApiKey = nonCustodialWallet.walletId;
            walletInstanceId = nonCustodialWallet.instanceID;
            walletAddress = nonCustodialWallet.walletAddress;
        }

        if (walletType === DEVELOPER_WALLET) {
            console.log("developerInstance::", developerInstance);
            serviceApiKey = "";
            walletInstanceId = developerInstance;
        }
        let newPrivateKey = privateKey;
        if(newPrivateKey !== undefined && newPrivateKey !== null && !newPrivateKey.startsWith('0x')){
            newPrivateKey = '0x'+newPrivateKey;
        }
        // const { projectID, instanceKey } = instance;
        data = {
            projectID: projectId,
            subId: subscriptionId,
            serviceInstance: projectDetails?.services?.SmartContractStudio?.InstanceID,
            template: uniqueId,
            name: tokenName,
            chainId: chainValue,
            ercStandard: STANDARD.ERC20,
            walletType: walletType,
            custodialWalletAccessToken: serviceApiKey,
            walletInstance: walletInstanceId,
            walletAddress: walletAddress,
            params: paramList,
            contractCodeAsBase64: base64Value,
            privateKey: newPrivateKey
        };
        setDeployDisabled(true)
        setConfirmLoading(true);

        let Url;
        if (contractType === "TEMPLATE") {
            Url = `smart-contract-studio/templates/${uniqueId}/deploy`
        } else if (contractType === "CUSTOM") {
            Url = `smart-contract-studio/contracts`
        }

        fetchApi({
            method: "POST",
            url: Url,
            isDevApp: false,
            data: data,
            dAppID: projectId
        })
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    notification.success({
                        message: response.message,
                        duration: 2,
                    });
                    setDeployDisabled(false)
                    setConfirmLoading(false);
                    navigate(`/smart-contract`);
                } else {
                    notification.error({
                        message: response.message,
                        duration: 2,
                    });
                    setDeployDisabled(false)
                    setConfirmLoading(false);
                }
            })
            .catch((error) => {
                console.error(error);
                setDeployDisabled(false);
                setConfirmLoading(false);
                notification.error({
                    message: error.message,
                    duration: 3,
                });
            });
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const onSelectChange = (e) => {

        console.log("e.target.name::", e.target);
        if(e.target.name === "chain"){
            setFormChainId(e.target.value);
        }
        if(e.target.name === "dApp"){
            setProjectId(e.target.value);
            projectList.map((project) => {
            if (project.projectID === e.target.value) {
                setProjectDetails(project);
                setFormWalletType("");
                setFormChainId("");
            }});
        }
        if (e.target.name === "custodialWallet") {
            console.log("wallets", e.target.value)
            setWalletDetails(e.target.value);
            setFormData({
                ...formData,
                [e.target.name]: e.target.value,
            });
            console.log("formData::", formData);
            if (walletType === CUSTODIAL_WALLET) {
                getWallet(e.target.value)
            }
            if (walletType === NON_CUSTODIAL_WALLET) {
                const { services: { NonCustodialWallet } }  = projectDetails;
                setNonCustodialWallet({ instanceID: NonCustodialWallet?.InstanceID, walletId: e.target.value, walletAddress: "" })
            }

            if (walletType === DEVELOPER_WALLET) {
                const {
                        services: {
                            DeveloperWallet: {
                                InstanceID: DevWalInstanceId
                            },
                            NonCustodialWallet: {
                                InstanceID: NonCusWalInstanceId
                            }
                    }
                } = instance;
                console.log("DevWalInstanceId::", DevWalInstanceId);
                console.log("NonCusWalInstanceId::", NonCusWalInstanceId);
                setDeveloperInstance(DevWalInstanceId)
            }

        } else {
            setChainValue(e.target.value);
        }
    };

    const onNameChange = (e) => {
        setTokenName(e.target.value);
    };

    const onPrivateKeyChange = (e) => {
        setPrivateKey(e.target.value);
    };

    const onContractChange = (e) => {
        setContractCode(e.target.value);
    };

    const getWallet = async (wallet) => {
        if (instance) {
            const { projectDetails: { services: { CustodialWallet } } } = instance;
            console.log("wallet::", wallet);
            const { walletName } = JSON.parse(wallet);
            const payload = {
                mode: "creds",
                name: walletName
            };

            fetch(`${walletURL}/getWallet`, {
                method: "POST",
                mode: "cors",
                body: JSON.stringify(payload),
                headers: {
                    'Content-Type': 'application/json',
                    'Authorization': authToken,
                    'Instanceid': CustodialWallet?.InstanceID,
                    'ChainId':chainValue
                },
            }).then(response => response.json())
                .then((response) => {
                    // showLoader(false);
                    if (response.Status === "SUCCESS") {
                        const { Data = [] } = response;
                        console.log("setCustodialWallet::", Data);
                        setCustodialWallet(Data)
                    }
                })
                .catch((response) => {
                    // showLoader(false);
                });
        } else {
            console.log("instance not found::");
        }
    };

    return (
        <>
            <div className="smartcontract">
                {isLoading ? (<Skeleton height={40} width={120} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                    <div style={{fontWeight:"700"}} className="template-header">{contractResponse !== null ? 'Custom Contract' : (null)}</div>

                )}
                <Row>
                    <Col span={12}>
                        <Space direction="vertical" size={25} style={{ display: "flex", marginTop: "25px" }}>
                            <Form name="basic" ref={formRef} initialValues={{ remember: true, }} onFinish={onDeployContract} autoComplete="off">
                                {isLoading ? (<Skeleton height={45} width="80%" borderRadius={10} style={{ marginTop: '15px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                    <Input className="input-container" placeholder="Contract Name" name="name" onChange={onNameChange} maxLength={100}
                                           onKeyPress={scstudioinputValidation} />)}
                                {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                                    <select
                                        className="create-contract-dropdown-filter"
                                        placeholder="Choose dApp"
                                        name="dApp"
                                        onChange={onSelectChange}
                                    >
                                        <option value="">Choose dApp</option>
                                        {projectList && projectList.length > 0 && projectList.map((project, ind) => (
                                            <option key={ind} value={project.projectID}>{project.projectName}</option>
                                        ))}
                                    </select>
                                )}
                                {isLoading ? (<Skeleton height={45} width="80%" borderRadius={10} style={{ marginTop: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                    <select  className="create-contract-dropdown-filter"
                                             value={formChainId}
                                             placeholder="Choose Chain for Collection" name="chain" onChange={onSelectChange}>
                                        <option value="">Choose Chain for Collection</option>
                                        {chains && chains.length > 0 && chains.map((chain, ind) => (
                                            <option key={ind} value={chain.chainId}>{chain.protocolName}</option>
                                        ))}
                                    </select>
                                )}
                                {isLoading ? (<Skeleton height={45} width="80%" borderRadius={10} style={{ marginTop: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                    <select
                                        className="create-contract-dropdown-filter"
                                        value={formWalletType}
                                        placeholder="Choose Wallet Type"
                                        name="walletType"
                                        onChange={(event) => onChangeWallet(event, "walletType")}
                                    >
                                        <option value="">Choose Wallet Type</option>
                                        {/*<option value={CUSTODIAL_WALLET}>Custodial</option>*/}
                                        <option value={NON_CUSTODIAL_WALLET}>Self-Managed</option>
                                        <option value={DEVELOPER_WALLET}>Developer-Wallet</option>
                                    </select>
                                )}

                                {walletType === NON_CUSTODIAL_WALLET && (<>
                                    {isLoading ? (<Skeleton height={45} width="80%" borderRadius={10} style={{ marginTop: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                        <select    className="create-contract-dropdown-filter"
                                                 placeholder="Choose Wallet" name="custodialWallet"
                                                onChange={onSelectChange}
                                        >
                                            <option value="">Choose Wallet</option>
                                            {ncWalletData.map((wallet, ind) => (
                                                <option key={ind} value={wallet.walletId}>{wallet.walletName}</option>
                                            ))}
                                        </select>
                                    )}
                                </>)}
                                {walletType === DEVELOPER_WALLET && (
                                    <Form.Item name="custodialWallet"
                                               rules={[
                                                   {
                                                       required: walletType === DEVELOPER_WALLET,
                                                       message: "Select Wallet",
                                                   },
                                               ]}>
                                        {isLoading ? (<Skeleton height={45} width="80%" borderRadius={10} style={{ marginTop: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                            <Input
                                                title={"Private Key"}
                                                className="input-container"
                                                placeholder="Private Key"
                                                name="privateKey"
                                                onChange={onPrivateKeyChange}
                                            />
                                        )}
                                    </Form.Item>
                                )}
                                {inputParams.length > 0 && (
                                    <div style={{fontSize:"16px",fontWeight:"700",marginBottom:"10px"}} className="template-header">Input Parameters</div>
                                )}
                                {inputParams && inputParams.length > 0 && inputParams.map((item, ind) => (
                                    <Form.Item style={{width: "650px"}} name={item.paramName} key={ind}>
                                        {isLoading ? (<Skeleton height={45} width="80%" borderRadius={10} style={{ marginTop: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                            <Input  className="input-container" name={item.paramName} placeholder={item.paramDisplayName} />
                                        )}
                                    </Form.Item>
                                ))}
                                <div className="deploy-nft-collection">
                                    <Form.Item>
                                        <Popconfirm
                                            placement='top'
                                            title={confirmationMessage}
                                            onConfirm={(e) => {
                                                e?.stopPropagation();
                                                formRef.current.submit();
                                            }}
                                            okButtonProps={{
                                                loading: confirmLoading,
                                            }}
                                            okText='Proceed'
                                            cancelText='Cancel'
                                            open={canSubmit}
                                            onCancel={cancel}
                                        >
                                            {isLoading ? (<Skeleton height={42} width={180} style={{ marginTop: '22px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                                                <div style={{marginTop:"30px"}}>
                                                    <button disabled={deployDisabled}
                                                            className="deploy-button"
                                                            type='submit'
                                                            onClick={fetchGasFee}
                                                    >
                                                        {deployDisabled ? <Spin indicator={antIcon} /> : <span style={{fontSize:"16px",fontWeight:"700"}} className="template-header">Deploy</span>}
                                                    </button>
                                                </div>
                                            )}
                                        </Popconfirm>
                                    </Form.Item>
                                </div>
                            </Form>
                        </Space>
                    </Col>
                    <Col span={12}>
                        {isLoading ? (<Skeleton height={250} style={{ marginTop: '22px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (<>
                            {contractType !== "CUSTOM" &&
                                <>
                            <div style={{marginBottom: "10px",fontFamily:'Poppins'}}>Supported Solidity Version: 0.8.9</div>
                                <Highlight className="python">{atob(contract)}</Highlight>
                                </>
                            }
                            {contractType === "CUSTOM" && (
                                <>
                                    {/*<div className="custom-copy-alignment">
                                        <div style={{marginBottom: "10px"}}>Supported Solidity Version: 0.8.9</div>
                                        <div className="copy-alignment"><CopyOutlined onClick={() => onCopy(contractCode)} /></div></div>*/}
                                    <div style={{marginBottom: "10px",fontFamily:'Poppins'}}>Supported Solidity Version: 0.8.9</div>
                                    <TextArea placeholder="// Paste Your Code Here" className="custom-contract-box" onChange={onContractChange}></TextArea>
                                </>
                            )}
                        </>)}
                    </Col>
                </Row>
            </div>
        </>
    );
};

export default DeployContracts;

// Essentials
import React from "react";
//Utils

// Components and Styles
import "../../endpoints/styles.less";
import {Modal, Space, Input, Form, notification, Spin, Select} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import fetchApi from "../../../_config/api";
import {defaultList} from "../constant";
import {Menus} from "../../../assets";

export const    CreateEndpoint = ({
                                      isOpen,
                                      toggleState,
                                      id,
                                      reloadCollection,
                                      activeKey,
                                      query
                                  }) => {
    const formRef = React.useRef(null);


    const [protocolList, setProtocolList] = React.useState([]);
    const [storageProtocolList, setStorageProtocolList] = React.useState([]);
    const [didProtocolList, setDidProtocolList] = React.useState([]);
    const [infraType, setInfraType] = React.useState(activeKey);
    const [networkList, setNetworks] = React.useState([]);
    const [protocolNames, setProtocolNames] = React.useState([]);
    const [bundlerProtocolList, setBundlerProtocolList] = React.useState([]);
    const [bundlerProtocolNames, setBundlerProtocolNames] = React.useState([]);
    // const [endpointTypes, setEndpointTypes] = React.useState([]);
    const [infraTypes, setInfraTypes] = React.useState([]);
    const [deployDisabled, setDeployDisabled] = React.useState(false);
    const [chainId, setChainId] = React.useState("");

    const getProtocalList = () => {
        let blockchainList = []
        let bundlerList = []
        fetchApi({ method: "GET", url: `protocols/getAllProtocols` })
            .then((response) => {
                if (response.docs) {
                    const {docs = []} = response;
                    let didlist = []
                    let storageList = []
                    for (let i= 0;i< response.docs.length;i++){
                        if(response.docs[i].protocol !== "DID" && response.docs[i].protocolType !== "bundler" && response.docs[i].protocol !== "IPFS"){
                            blockchainList.push(response.docs[i]);
                            //setProtocolList(blockchainList);
                        }
                        if (response.docs[i].protocol === "IPFS" && response.docs[i].protocolType !== "bundler"){
                            storageList.push(response.docs[i])
                            setStorageProtocolList(storageList)
                        }
                        if (response.docs[i].protocol === "DID" && response.docs[i].protocolType !== "bundler"){
                            didlist.push(response.docs[i])
                            setDidProtocolList(didlist)
                        }
                        if (response.docs[i].protocolType === "bundler"){
                            bundlerList.push(response.docs[i])
                        }
                    }
                }
                setInfraTypes(defaultList.infraTypes);
            })
            .catch(() => {

            }).finally(() => {
            setProtocolList(blockchainList);
            const grades = [...new Set(blockchainList.map(item => item.protocol))];
            setProtocolNames([...grades]);
            setBundlerProtocolList(bundlerList);
            const grades_ = [...new Set(bundlerList.map(item => item.protocol))];
            setBundlerProtocolNames([...grades_]);
        });
    };

    React.useEffect(() => {
        formRef.current?.setFieldsValue({
            ["infraType"]: activeKey
        });
        setInfraType(activeKey);
    }, [activeKey]);

    React.useEffect(() => {
        getProtocalList()
    }, []);


    React.useEffect(() => {
        formRef?.current?.resetFields();
    }, [isOpen,chainId]);

    const onCreateEndpoint = (value) => {
        setDeployDisabled(true);
        let data = {
            ...value,
        };


        if(data.infraType === undefined || data.infraType === ""){
            data.infraType = infraType;
        }
        if(data.infraType === "blockchainNode" || data.infraType === "bundlerNode"){
            data.protocolName = value.protocolName + "-" + value.network;
        }
        const network1 = networkList.filter((obj) => obj.name.includes(data.protocolName));
        if(network1.length > 0){
            data.chainId = network1[0].chainId;
        }else{
            data.chainId = "";
        }
        data.endpointType = "SHARED";
        // console.log("Request Payload: ",JSON.stringify(data))
        fetchApi({
            method: "POST",
            url: "endpoints",
            isDevApp: false,
            data: data,
        })
            .then((response) => {
                if(response.statusCode === 200 || response.statusCode === 201){
                    notification.success({
                        message:
                            "Endpoint Successfully Created.",
                        duration: 5,
                    });
                    reloadCollection();
                    toggleState();
                    query(true);
                    // activeKey(infraType);
                } else if(response.message){
                    notification.error({
                        message:response.message,
                        duration: 5,
                    });
                }
                setDeployDisabled(false);
            })
            .catch((error) => {
                // console.error(error);
                setDeployDisabled(false);
                notification.error({
                    message: error.toString(), // "Not able to create an Endpoint. Try different Chain",
                    duration: 5,
                });
            });
    };

    const onChangeSelect = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setInfraType(event.target.value)
    };
    const onChangeSelectEndPoint = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
    };
    const onChangeSelectProtocolName = (event, name,infraType) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        if(infraType === "blockchainNode") {
            const networks = protocolList.filter((obj) => obj.protocol.includes(event.target.value));
            setNetworks(networks);
        }else{
            const networks = bundlerProtocolList.filter((obj) => obj.protocol.includes(event.target.value));
            setNetworks(networks);
        }
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    const { Option } = Select;
    const IconOption = props => (
        <Option {...props}>
            <img
                src={require('./' + props.svgId)}
                style={{ width: 36 }}
                alt={props.name}
            />
            {props.name}
        </Option>
    );

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup endpoint-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text d-inline">
                        {infraType === "blockchainNode" && <p className="popup-text-endpoint">Protocol</p> }
                        {infraType === "storageNode" && <p className="popup-text-endpoint">Storage</p> }
                        {infraType === "didNode" && <p className="popup-text-endpoint">Decentralized Identity </p> }
                        {infraType === "bundlerNode" && <p className="popup-text-endpoint">Bundler</p> }
                    </div>
                    <Space
                        direction="vertical"
                        size={25}
                    >
                        <Form
                            name="basic"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onCreateEndpoint}
                            autoComplete="off"
                        >
                            {/*<Form.Item name="infraType"

                            >
                                <>
                                    <select
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Infra Type"
                                        name="infraType"
                                        onChange={(event) => onChangeSelect(event, "infraType")}
                                    >
                                        {infraTypes.map((infra, ind) => (
                                            <option
                                                key={infra.id}
                                                value={infra.id}
                                                disabled={infra.id !== activeKey}
                                                selected={infra.id === activeKey}
                                            >
                                                {infra.name}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Form.Item>*/}
                            <Form.Item name="protocolName" rules={[
                                {
                                    required: true,
                                    message: "Missing Protocol Name",
                                },
                            ]}>
                                <>
                                    <select
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Protocol Name"
                                        name="protocolName"
                                        onChange={(event) => onChangeSelectProtocolName(event, "protocolName",infraType)}
                                    >
                                        <option key={"00"} value={""}>
                                            Select Protocol
                                        </option>
                                        {infraType === "storageNode"?
                                            storageProtocolList.map((chain, ind) => (
                                                <option key={chain.name} value={chain.protocol}>
                                                    {chain.name}
                                                </option>
                                            )):infraType === "didNode" ? didProtocolList.map((chain, ind) => (
                                                <option key={chain.name} value={chain.protocol}>
                                                    {chain.name}
                                                </option>
                                            )): infraType === "blockchainNode" ? protocolNames.map((chain, ind) => (

                                                <option key={chain} value={chain}>
                                                    {chain}
                                                </option>

                                            )): bundlerProtocolNames.map((chain, ind) => (
                                                <option key={chain} value={chain}>
                                                    {chain}
                                                </option>

                                            ))
                                        }

                                    </select>
                                </>
                            </Form.Item>
                            {infraType === "blockchainNode"  || infraType === "bundlerNode"?
                                <Form.Item name="network" rules={[
                                    {
                                        required: true,
                                        message: "Missing Network Name",
                                    },
                                ]}>
                                    <>
                                        <select className="dropdown-filter create-collection-drop" placeholder="Network"
                                                name="network"
                                                onChange={(event) => onChangeSelectEndPoint(event, "network")}>
                                            <option key={"00"} value={""}>
                                                Select Network
                                            </option>
                                            {
                                                networkList.map((obj, ind) => (

                                                    <option key={obj.network} value={obj.network}>
                                                        {obj.network}
                                                    </option>
                                                ))
                                            }

                                        </select>
                                    </>
                                </Form.Item>:""
                            }



                            <div className="deploy-nft-collection">
                                <button disabled={deployDisabled}>
                                    {!deployDisabled ? (
                                        "Create Endpoint"
                                    ) : (
                                        <Spin indicator={antIcon} />
                                    )}
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    );
};
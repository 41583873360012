import React, {useEffect, useState} from "react";
import {CopyOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Tooltip, notification} from "antd";
import {Link} from "react-router-dom";
import fetchApi from "../../../_config/api";

export const CarouselBlockchain = ({ items,infraType }) => {
    const [index, setIndex] = useState(0);
    // console.log("items--->",items)
    const onNext = () => {
        if (index + 3 < items.length) {
            setIndex(index + 1);
        }
    };
    const onPrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const onCopy = (url) => {
        navigator.clipboard.writeText(url);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };
    return (
        // <>
        //     {/* Headings */}
        //     <div className="endpoint-column-container">
        //         <div className="endpoint-col">
        //             <div className="endpoint-text" style={{paddingBottom:'0px'}}>Endpoint Name</div>
        //         </div>
        //         <div className="endpoint-col">
        //             <div className="endpoint-text" style={{paddingBottom:'0px'}}>Protocol Name</div>
        //         </div>
        //         {items?.IPFSGateway_URL !== undefined && (
        //             <div className="endpoint-col-url">
        //                 <div className="endpoint-text" style={{paddingBottom:'0px'}}>GateWay URL</div>
        //             </div>
        //         )}
        //         <div className="endpoint-col-url">
        //             <div className="endpoint-text" style={{paddingBottom:'0px',marginTop:'0px'}}>User Endpoint</div>
        //         </div>
        //     </div>
        //
        //     {/* Items */}
        //     {items ? items.map((item, index) => (
        //         <div key={index} className="endpoint-column-container">
        //             <div className="endpoint-col">
        //                 <div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.EndpointName}</div>
        //             </div>
        //             <div className="endpoint-col">
        //                 <div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.Protocol}</div>
        //             </div>
        //             {items?.IPFSGateway_URL !== undefined && (
        //                 <>
        //                     <div className="endpoint-col-url">
        //                         <div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.IPFSGateway_URL}</div>
        //                     </div>
        //                     <div className="endpoint-copy-col" style={{marginTop:'-15px'}}>
        //                         <CopyOutlined onClick={() => onCopy(item?.IPFSGateway_URL)} />
        //                     </div>
        //                 </>
        //             )}
        //             <div className="endpoint-col-url">
        //                 <Tooltip title={item?.RPC_URL}><div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.RPC_URL}</div></Tooltip>
        //             </div>
        //             <div className="endpoint-copy-col" style={{marginTop:'-15px'}}>
        //                 <CopyOutlined onClick={() => onCopy(item?.RPC_URL)} />
        //             </div>
        //         </div>
        //     )) : (
        //         <div className="service-not-available-label">
        //             Endpoint not selected!
        //         </div>
        //     )}
        // </>
        <table className="dapp-endpoints-table table-bordered">
            <thead>
            <tr>
                <th className="dapp-endpoint-header">Sl.No</th>
                <th className="dapp-endpoint-header">Name</th>
                <th className="dapp-endpoint-header">Chain Id</th>
                <th className="dapp-endpoint-header">Endpoint Id</th>
                <th className="dapp-endpoint-header">Manage</th>
            </tr>
            </thead>
            <tbody>
            {items && items.map((item, index) => (
                <tr key={index}>
                    <td className="dapp-endpoint-text">{index + 1}</td>
                    {/* Serial number */}
                    <td className="dapp-endpoint-text">{item?.Protocol}</td>
                    <td className="dapp-endpoint-text">{item?.ChainId}</td>
                    <td className="dapp-endpoint-text">{item?.ID}</td>
                    <td className="dapp-endpoint-text">
                        <Link to={"/dapp-endpointsDetails/"} state={{id: item?.ID, infraType: infraType}}>
                            <button className="dapp-endpoint-table-button"><span
                                style={{padding: "40px 20px 40px 20px", fontSize: "12px", fontWeight: "700"}}>View Details</span>
                            </button>
                        </Link>
                </td>
                </tr>
                ))}
            </tbody>
        </table>
    );
};
import React from "react";

export const Bell = ({ width = "20px", height = "20px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 26"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M11.0946 25.7252C10.3343 25.7237 9.59306 25.4825 8.97297 25.0349C8.35287 24.5873 7.88438 23.9552 7.63194 23.2256H14.5573C14.3046 23.955 13.836 24.5868 13.216 25.0344C12.5959 25.482 11.8549 25.7233 11.0946 25.7252ZM20.9251 21.9752H1.26422C0.938301 21.9748 0.625828 21.843 0.395366 21.6085C0.164903 21.3741 0.0352739 21.0563 0.034919 20.7247V18.2252C0.0343757 18.061 0.0658506 17.8984 0.127521 17.7467C0.189191 17.5951 0.279831 17.4573 0.394192 17.3415L2.49219 15.2088V10.7252C2.49771 8.81121 3.11856 6.95162 4.26003 5.43006C5.4015 3.90851 7.00092 2.80852 8.81433 2.2979C8.99654 1.83462 9.31103 1.43749 9.71725 1.15776C10.1235 0.878025 10.6028 0.728516 11.0933 0.728516C11.5838 0.728516 12.0631 0.878025 12.4693 1.15776C12.8756 1.43749 13.1901 1.83462 13.3723 2.2979C15.1858 2.80832 16.7853 3.90825 17.9268 5.42985C19.0683 6.95145 19.6891 8.81115 19.6944 10.7252V15.2088L21.7924 17.3415C21.9068 17.4573 21.9974 17.5951 22.0591 17.7467C22.1207 17.8984 22.1522 18.061 22.1517 18.2252V20.7247C22.1513 21.0558 22.0221 21.3732 21.7922 21.6076C21.5623 21.8419 21.2505 21.9741 20.9251 21.9752ZM11.0946 4.47563C9.46573 4.47743 7.90406 5.13644 6.75225 6.30807C5.60044 7.4797 4.95257 9.06825 4.9508 10.7252V15.7256C4.95156 15.8898 4.92019 16.0525 4.85851 16.2042C4.79682 16.3559 4.70607 16.4936 4.59152 16.6093L2.49219 18.7434V19.4756H19.6957V18.7434L17.5964 16.6093C17.4817 16.4938 17.3909 16.3561 17.3294 16.2043C17.2679 16.0526 17.237 15.8898 17.2385 15.7256V10.7252C17.2367 9.06825 16.5888 7.4797 15.437 6.30807C14.2852 5.13644 12.7235 4.47743 11.0946 4.47563Z"
      fill="#A8A6A6"
    />
  </svg>
);

import React, { useEffect, useState } from 'react'
import './blogsuistylings.less'
import axios from 'axios';
import {dateformatter} from "../../deps";
import {Link, useNavigate } from 'react-router-dom';
import { Spin } from "antd";
import MailchimpSubscribe from 'react-mailchimp-subscribe';
import { Pagination } from 'antd';
import 'antd/dist/antd.css';
import CustomForm from './CustomForm';
import {Helmet} from "react-helmet";
import Skeleton from 'react-loading-skeleton';
import { useInView } from 'react-intersection-observer';

const BlogUi = () => {

  const [posts, setPosts] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [postsPerPage] = useState(6);
  const [blogDataLength,setBlogDataLength]=useState('')
  const [searchTerm, setSearchTerm] = useState('');
  const[latestPost,setLatestPost]=useState('')
  const [tab1,setTab1]=useState('all')
  const[loading1,setLoading1]=useState(false)
  console.log(loading1)


    // console.log(latestPost)
    const perPage = 100;
    // console.log(posts)
    // console.log(blogData)



    useEffect(() => {
      fetchData();
    }, []);
    useEffect(() => {
      getLatestPost() 
    }, [blogDataLength]);
 
    const learnCategory=20
    const pressReleaseCategory=19
    const DevGuids=25
    const unCategory=5

    const fetchData =  (id) => {
      setLoading1(true)
      console.log(id)
      if(id==20){
    
        axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts/?_embed&categories=${learnCategory}&&per_page=${perPage}`)
        .then(response=>{
          if(response.data !==''){
            setPosts(response.data);
            setBlogDataLength(response.data.length)
            getLatestPost() 
            setLoading1(false)
          }
        })
        .catch (err=>{
          console.error('Error fetching data:', err);
        })
      }
      if(id==19){
        axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts/?_embed&categories=${pressReleaseCategory}&&per_page=${perPage}`)
        .then(response=>{
          if(response.data !==''){
            setPosts(response.data);
            setBlogDataLength(response.data.length)
            getLatestPost() 
            setLoading1(false)
          }
        })
        .catch (err=>{
          console.error('Error fetching data:', err);
        })
      }
      if(id==25){
        axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts/?_embed&categories=${DevGuids}&&per_page=${perPage}`)
        .then(response=>{
          if(response.data !==''){
            setPosts(response.data);
            setBlogDataLength(response.data.length)
            getLatestPost() 
            setLoading1(false)
          }
        })
        .catch (err=>{
          console.error('Error fetching data:', err);
        })
      }
      if(id==undefined){
        axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts/?per_page=${perPage}`)
        .then(response=>{
          if(response.data !==''){
            setPosts(response.data);
            setBlogDataLength(response.data.length)
            getLatestPost() 
            setLoading1(false)
          }
        })
        .catch (err=>{
          console.error('Error fetching data:', err);
        })
      }
      
        
        
      
    };

    const filteredPosts = posts.filter(post =>
      post.title.rendered.toLowerCase().includes(searchTerm.toLowerCase())
    );
  
    const indexOfLastPost = currentPage * postsPerPage;
    const indexOfFirstPost = indexOfLastPost - postsPerPage;
    const currentPosts = filteredPosts.slice(indexOfFirstPost, indexOfLastPost);
  
    const paginate = (pageNumber) =>{
       setCurrentPage(pageNumber);
    
        // window.scrollTo({
        //   top: 0,
        //   behavior: 'instant' // Optional: Scroll behavior (smooth or auto)
        // });

    }


    const extractImageFromContent = (content) => {
        // Use a regex or DOMParser to extract the image from the HTML content
        const regex = /<img.*?src=['"](.*?)['"]/;
        const match = content.match(regex);
        // console.log(match)
        return match ? match[1] : null;
      };

      const navigate =useNavigate()



      const getLatestPost = () => {
        if (posts.length>0) {
          const sortedPosts = posts.sort((a, b) => new Date(b.date) - new Date(a.date));
          const latestPost = sortedPosts[0];
          setLatestPost(latestPost)
        }
      };

      


     const mailurl="https://krypc.us11.list-manage.com/subscribe/post?u=76475fad3bd2c968109e39fa8&amp;id=fd7be5cd0e&amp"

     const ActiveTab1=()=>{
      fetchData(20)
      setTab1('learn')
     }
     const ActiveTab2=()=>{
      fetchData(19)
      setTab1('press')
     }
     const ActiveTab3=()=>{
      fetchData(25)
      setTab1('dev')
     }
     const ActiveTabA=()=>{
      fetchData()
      setTab1('all')
     }

    const decodeHtmlEntities = (html) => {
      const el = document.createElement('p');
      el.innerHTML = html;
      return el.innerText || el.textContent;
    };

    const decodeHtmlEntities1 = (html) => {
 
    const tempElement = document.createElement('p');
    tempElement.innerHTML = html;

 
    const paragraphElement = tempElement.querySelector('p');
    if (paragraphElement) {
      const textToRemove = '[…]'
       paragraphElement.innerHTML = paragraphElement.innerHTML.replace(textToRemove, '...');
    }


    const modifiedContent = tempElement.innerHTML;

    return modifiedContent;
    };

    const { ref, inView } = useInView({
      triggerOnce: true, // Only trigger animation once
      threshold: 0.5 // Trigger animation when 50% of the element is visible
    });

  return (<>

    <Helmet>
                <meta charSet="utf-8" />
                <title>Krypcore Web3 Blog</title>
                <meta name="description" content="Krypcore simplifies dApp development for developers, startups, and enterprises. Explore our robust APIs, SDK, and web3 development tools for a seamless experience." />
                <link rel="canonical" href="http://mysite.com/example" />
            </Helmet>
  
  

<div className="dapp-sectionb">
    {/* <div className='dapp-sectionflex'>
    <div className="dapp-heading1">
            <span className="dapp-highlight">Krypcore Blogs</span>
            <input
              placeholder="Search Blog..."
              className="buildK-copy-btn1"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}

            />
        </div>
    </div> */}
    <div className='dapp-sectionflexTab'>
      
    <div className="dapp-headingTab">
        {tab1==='all'?<span onClick={ActiveTabA} className='dapp-headingTextActive'>All</span>:<span onClick={ActiveTabA} className='dapp-headingText'>All</span>}
        {tab1==='learn'?<span onClick={ActiveTab1} className='dapp-headingTextActive'>Learn</span>:<span onClick={ActiveTab1} className='dapp-headingText'>Learn</span>}   
        {tab1==='press'?<span onClick={ActiveTab2} className='dapp-headingTextActive'>Press Release</span>:<span onClick={ActiveTab2} className='dapp-headingText'>Press Release</span>}
        {tab1==='dev'?<span onClick={ActiveTab3} className='dapp-headingTextActive'>Developer Guides</span>:<span onClick={ActiveTab3} className='dapp-headingText'>Developer Guides</span>}
    </div>
        <div>
        <input
              placeholder="Search Blog..."
              className="buildK-copy-btn1"
              value={searchTerm}
              onChange={e => setSearchTerm(e.target.value)}

            />
        </div>
    </div>
        
        {!loading1?
        <>
        <div className={`${searchTerm ===''?"dapp-card-container2":""}`}>
          {searchTerm ===''?
          <>
        {latestPost !==''?
        <Link to={{pathname:`/blog/${latestPost.slug}`,state:{data:latestPost}}} target="_blank" >
          <div className="dapp-card2">
            <div className="dapp-card-content2">
               <h4 className="subheading-highlight">{decodeHtmlEntities(latestPost.title?.rendered)}</h4>
                <p  dangerouslySetInnerHTML={{ __html:decodeHtmlEntities1(latestPost.excerpt.rendered )}}/>
                
                <p>Posted on <strong>{dateformatter(latestPost.date, "mmmm dS, yyyy")}</strong></p>
                <Link to={{pathname:`/blog/${latestPost.slug}`,state:{data:latestPost}}} target="_blank"><button  className="buildK-copy-btn4">Continue Reading..</button></Link>
            </div>
            <div  ref={ref} className={`dapp-card-img2`}>
              <img  src={extractImageFromContent(latestPost.content.rendered)} className={`blogViewImg`} alt="Post Image"/>
            </div>

            </div></Link>:null}</>:null}
        </div>
        
        <>
        <div className="dapp-card-container">
            {currentPosts.length>0?
             <>  
              {currentPosts.map((data)=>{
                return(
                    <div className="dapp-card" key={data.id}>
                        <Link to={{pathname:`/blog/${data.slug}`,state:{data:data}}} target="_blank" >
                            <div ref={ref} className={`dapp-card-img ${inView ? 'animate fadeIn':''}`}>
                            <img src={extractImageFromContent(data.content.rendered)} className='blogViewImg' alt="Post Image"/>
                            </div>
                            <div className="dapp-card-content">
                                 <h4 className="subheading-highlight">{decodeHtmlEntities(data.title?.rendered)}</h4>
                                <p dangerouslySetInnerHTML={{ __html: decodeHtmlEntities1(data.excerpt.rendered) }}/>
                                <p>Posted on <strong>{dateformatter(data.date, "mmmm dS")}</strong></p>
                            </div>
                        </Link>
                    </div>
                )
             })}
             
             </>:
            null}
        </div>
        <div className='paginationui'>
              <Pagination
                  showLessItems={true} 
                  className="custom-pagination1"
                  current={currentPage}
                  total={filteredPosts.length}
                  pageSize={postsPerPage}
                  onChange={paginate}
                />
            </div>
            </>
        </>:<>
        <div className='skelonloader1'>
          <Skeleton height={600} borderRadius={16}  baseColor="#fff" highlightColor="#e1e1e1" ></Skeleton> 
          </div>
         <div className='skelonloader2'>
           
         <Skeleton className='skelonloader2l' baseColor="#fff" highlightColor="#e1e1e1" ></Skeleton>
         <Skeleton className='skelonloader2l'  baseColor="#fff" highlightColor="#e1e1e1" ></Skeleton>
         <Skeleton className='skelonloader2l'  baseColor="#fff" highlightColor="#e1e1e1" ></Skeleton>
    </div>
           </> }
            <div className='subscritefield'> 
              <span className='subscritefield1 mb-3'>Subscribe to our Newsletter</span>
      
              <MailchimpSubscribe
                url={mailurl}
                render={({ subscribe, status, message }) => (
                  
                  <CustomForm
                    status={status}
                    message={message}
                    onValidated={formData => subscribe(formData)}
                  />
                )}
              />
            </div>
     </div>
  
  
    
  </>)
}

export default BlogUi
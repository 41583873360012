import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard';
import CopyTextData from "../../../assets/menus/CopyText.avif"

const CopyRequest = ({request}) => {
    return (
        <>
            <CopyToClipboard text={request} title="Click To Copy data">
                <img src={CopyTextData} style={{height:"22px",width:"20px"}}/>
            </CopyToClipboard>
        </>
    )
}

export default CopyRequest




import React, { useEffect, useState, useRef } from "react";
import { gaslessWhitelistedColumns } from "../utils/columns";
import { Filters, PaginationSection, TableContent } from "../../../library";
import { apiCaller } from "../utils/utils";
import { GASLESS_URLS } from "../utils/constants";
import { useNavigate } from "react-router-dom";
import { getUserDetails } from "../../../deps";
import { AddWhitelistedContract } from "./index";
import Skeleton from "react-loading-skeleton";
import searchIcon from "./search-icon/searchicon.svg";

const GaslessWhitelistedContracts = ({ isLoading }) => {
    const userDetails = getUserDetails();
    const navigate = useNavigate();
    const [whiteListedContractsArr, setWhiteListedContractsArr] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);
    const [openModelForWhitelistedContract, setOpenModelForWhitelistedContract] = useState(false);
    const [pageLoad, setPageLoad] = useState(true);
    const [searchExpanded, setSearchExpanded] = useState(false);
    const searchBoxRef = useRef(null);

    const openAddWhitelistedContractModel = (a) => {
        setOpenModelForWhitelistedContract(!openModelForWhitelistedContract);
        setPageLoad(!pageLoad);
    };

    const [pageSetting, setSetting] = useState({
        page: 1,
        limit: 5,
        searchValue: "",
        searchIn: [],
        filterValue: "ACTIVE",
        filterIn: ["contractStatus"],
        userId: userDetails.profile.developerId
    });

    const last =
        pageSetting.page * pageSetting.limit > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.limit;

    const startIndex = (pageSetting.page - 1) * pageSetting.limit;

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
    };

    const onPerPageChange = (value) => {
        pageSetting.limit = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
    };

    const searchValue = (value) => {
        pageSetting.searchIn = ["contractAddress"];
        pageSetting.searchValue = value;
        setSetting({ ...pageSetting });
    };

    const listAllWhitelistedContracts = async () => {
        let resp = await apiCaller("POST", GASLESS_URLS.LIST_ALL_WHITELISTED_CONTRACTS, pageSetting, false);
        setWhiteListedContractsArr(resp.data.data);
        setTotalCount(resp.data.total);
    };

    function navigateToManageContract(data) {
        navigate("/addons/gasless/manageContractPage", { state: { ...data } });
    }

    useEffect(() => {
        listAllWhitelistedContracts().then();
    }, [pageLoad, pageSetting]);

    useEffect(() => {
        function handleClickOutside(event) {
            if (searchBoxRef.current && !searchBoxRef.current.contains(event.target) && pageSetting.searchValue === "") {
                setSearchExpanded(false);
            }
        }

        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [pageSetting.searchValue]);

    return (
        <>
            {/* Header section */}
            <div className="gasless-header-section">
                <div className="header-text">Whitelisted Contracts</div>
                <div className="gasless-filter">
                    {isLoading ? (
                        <Skeleton height={45} width={220} style={{ marginTop: '30px' }} baseColor="#262626" highlightColor="#404040"></Skeleton>
                    ) : (
                        <div className="actions-container">
                            <div className={`search-container-gasless ${searchExpanded ? 'expanded' : ''}`} ref={searchBoxRef}>
                                <img
                                    src={searchIcon}
                                    alt="Search Icon"
                                    className="search-expand-icon"
                                    onClick={() => { setSearchExpanded(true); }}
                                />
                                {searchExpanded && (
                                    <div className="search-box-wrapper">
                                        <Filters
                                            placeHolder={"Enter Contract Address"}
                                            onTextChange={searchValue}
                                            showCreateBtn={false}
                                            value={pageSetting.searchValue}
                                            showFilters={false}
                                            onNavigate={openAddWhitelistedContractModel}
                                        />
                                        {/*<button className="close-search-btn" onClick={() => setSearchExpanded(false)}>X</button>*/}
                                    </div>
                                )}
                            </div>
                            <button className="createBtn3" onClick={openAddWhitelistedContractModel}>Whitelist Contract</button>
                        </div>
                    )}
                </div>
            </div>

            {/* Table content */}
            <div className="table-container marginBtm30">
                {isLoading ? (
                    <Skeleton height={210} style={{ marginTop: '30px' }} baseColor="#262626" highlightColor="#404040"></Skeleton>
                ) : (
                    <TableContent
                        rowKey="_id"
                        data={whiteListedContractsArr}
                        columns={gaslessWhitelistedColumns(navigateToManageContract)}
                        isDashboard={false}
                        pagination={false}
                    />
                )}

                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={[5, 10, 20, 50]}
                    totalCount={totalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.limit}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />

            </div>

            <AddWhitelistedContract isModalOpen={openModelForWhitelistedContract} setModalState={openAddWhitelistedContractModel} displayFormConst={"C"} />
        </>
    );
};

export default GaslessWhitelistedContracts;

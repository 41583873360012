import React, {useEffect} from "react";
import { Form } from "antd";
import {fetchApi} from "../../../_config/api";


export const NodeConfig = ({
                             title = "Choose the storage for your Project",
                             id,
                             selectedValue = [],
                             onChange,
                             options = [],
                               nextClicked
                         }) => {
    let newList = [...selectedValue];

    const [blockData, setBlockData] = React.useState([]);
    const [storageData, setStorageData] = React.useState([]);

    // let newList = [...selectedValue];
    const [form] = Form.useForm(); // Create a form instance
    useEffect(() => {
        getEndpointsList();
    }, []);
    const getEndpointsList = () => {
        fetchApi({ method: "GET", url: "endpoints" })
            .then((response) => {
                const { docs = [] } = response;
                let blocklist = []
                let storagelist = []

                for(let i=0; i < docs.length;i++){
                    if(docs[i].infraType === "blockchainNode"){
                        blocklist.push(docs[i])
                    }
                    if(docs[i].infraType === "storageNode"){
                        storagelist.push(docs[i])
                    }
                    setBlockData(blocklist)
                    setStorageData(storagelist)
                }
            })
            .catch(() => {
                setBlockData([])
                setStorageData([])
            });
    };
    const [formObject, setFormObject] = React.useState({});
    const [array, setArray] = React.useState([...selectedValue]);
    React.useEffect(
        () => {
            if(nextClicked){

                form.setFieldsValue({
                    blockchain: selectedValue.blockchain,
                    storage: selectedValue.storage,
                });

            }
        }, [nextClicked, selectedValue, form]);
    const onChangeSelect = (event,name) => {
        const existingObject = newList.find(obj => obj[name] !== undefined);

        if (existingObject) {
            const updatedObject = { ...existingObject, [name]: event.target.value };
            newList = newList.map(obj => obj === existingObject ? updatedObject : obj)
        } else {
            const newObject = { [name]: event.target.value };
            newList.push(newObject)
        }
        onChange(id, newList);
    };

    return (
        <div className="common-dropdown">
            <Form form={form} initialValues={{}}>
                <Form.Item
                    name="blockchain"
                    label={<p style={{marginTop: 15, marginBottom: 15}} className="service-text">Select Blockchain Protocols</p>}>
                    <select className="dropdown-filters"
                            onChange={(event) => onChangeSelect(event, "blockchain")}
                    >
                        <option value="">Choose Endpoint</option>
                        {blockData.map((endpoint) => (
                            <option value={endpoint.protocolName}>
                                {endpoint.protocolName}
                            </option>
                        ))}
                    </select>
                </Form.Item>
                <Form.Item
                    name="storage"
                    label={<p style={{marginTop: 15, marginBottom: 15}} className="service-text">Select Storage Protocols</p>}>
                    <select className="dropdown-filters"
                            onChange={(event) => onChangeSelect(event, "storage")}
                    >
                        <option value="">Choose Endpoint</option>
                        {storageData.map((endpoint) => (
                            <option value={endpoint.protocolName}>
                                {endpoint.protocolName}
                            </option>
                        ))}
                    </select>
                </Form.Item>
            </Form>

        </div>

    );
};

export default NodeConfig;

import React from "react";
import "./style.less"

import FaucetTransactionPage from "./FaucetTransaction";
import Header from "../new-light-theme/ui/Header";
import Footer from "../new-light-theme/ui/Footer";
import { useLocation } from "react-router-dom";

const FaucetTransactionLandingPage = () => {
    const location=useLocation()
    console.log(location)
    return (
        <div className="bg-light2">
            <Header />
            <FaucetTransactionPage />
            <Footer />
        </div>
    );

}
export default FaucetTransactionLandingPage 
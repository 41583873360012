import React from "react";

export const LinkedIn = ({ width = "24", height = "24" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M114.668 2H5.03214C5.01439 2 5 2.01515 5 2.03383V117.44C5 117.459 5.01439 117.474 5.03214 117.474H114.668C114.686 117.474 114.7 117.459 114.7 117.44V2.03383C114.7 2.01515 114.686 2 114.668 2Z"
      fill="#CFCFCF"
    />
    <path
      d="M35.4246 41.4683C39.8029 41.4683 43.3521 37.7322 43.3521 33.1235C43.3521 28.5148 39.8029 24.7787 35.4246 24.7787C31.0464 24.7787 27.4971 28.5148 27.4971 33.1235C27.4971 37.7322 31.0464 41.4683 35.4246 41.4683Z"
      fill="#030303"
    />
    <path
      d="M57.2789 45.7536V90.4094V45.7536ZM35.4246 45.7536V90.4094V45.7536Z"
      fill="#030303"
    />
    <path
      d="M57.2789 45.7536V90.4094M35.4246 45.7536V90.4094"
      stroke="#030303"
      strokeWidth="14.8853"
    />
    <path
      d="M64.1351 65.6007C64.1351 61.09 66.9205 56.5794 71.8484 56.5794C76.9906 56.5794 78.9189 60.639 78.9189 66.7284V90.4095H93.0599V64.9241C93.0599 51.1665 86.2037 44.8516 76.7763 44.8516C69.4916 44.8516 65.8492 49.1367 64.1351 52.0687"
      fill="#030303"
    />
  </svg>
);

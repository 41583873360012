/* eslint-disable react-hooks/exhaustive-deps */
import React, {useEffect} from "react";
import "./style.less";

const NotFoundPage = () => {
    // const telegram = process.env.REACT_APP_TELEGRAM_URL;
    const serverUrl = "https://krypcore.com"  //"https://web3-dev-ui-sandbox-dev.krypcore.com"
    //const localUrl = "http://localhost:3000"
    const goToHome = (value)=>{
        // window.open(value);
        window.location.href = value;
    }

    useEffect(() => {
        let square = document.querySelector("#Square");
        console.log("square ",square)
        let background = document.getElementById("Background");
        let squareLen = (window.outerWidth / square.clientWidth) * (window.outerHeight / square.clientHeight);
        for (let i = 0; i <= squareLen; i++) {
            background.innerHTML += square.outerHTML;
        }
    }, []);

    function ActiveSquare(e) {
        if (e.classList.contains('active')) {
            e.classList.remove("active")
        } else {
            e.classList.add("active")
        }
    }

    return (
        <>
            <div className="body-404">
                <section id="Background" className="Background">
                    <div id="Square" onClick={(e) => ActiveSquare(e)} className="Square"/>
                </section>
                <div className="glass-Box">
                    <h1>404</h1>
                    <p>Oops! page not found</p>
                    <button type="button" className="button" onClick={() => goToHome(serverUrl)}>Click here to go back</button>
                </div>
            </div>

        </>
    );
};
export default NotFoundPage


import React, { useEffect, useState } from "react";
import {Button, notification} from "antd";
import { ArrowLeftOutlined } from "@ant-design/icons";
import { Project, CardList, Summary } from "../steps";
import { btnList, IsFilled, tabIds } from "../constant";
import './styles.less';
import Service from "../steps/service";
import NodeConfig from "../steps/nodeConfig";
import {DEVELOPERROLE} from "../../../deps";

export const Content = ({
  data,
  activeTab,
  protocolList,
  walletList,
  storageList,
  onChange,
  onPrevNext,
  storageProtocolList,
  didProtocolList,
    role,
    isLoading
}) => {
  const [isActive, setActive] = useState(false);
  // console.log("activeTab ",activeTab,btnList?.[activeTab]?.nextTab)

  useEffect(() => {
    setActive(activeTab === tabIds.TAB_4 || IsFilled(data, activeTab,role));
  }, [activeTab, data]);

  const onPrev = () => {
    onPrevNext(btnList[activeTab].prevTab);
  };

  const onNext = () => {
    if(btnList[activeTab].nextTab === tabIds.TAB_2){
      if(protocolList.length === 0 && role === DEVELOPERROLE){
        notification.error({
          message: "Blockchain Endpoint is not created",
          duration: 5,
        });
      }
      onPrevNext(btnList[activeTab].nextTab);
    }else{
      onPrevNext(btnList[activeTab].nextTab);
    }
  };

  return (
    <div className="content-container">
      {(() => {
        switch (activeTab) {
          case tabIds.TAB_1:
            return (
              <Project
              isLoading={isLoading}
                title={data?.title}
                description={data?.description}
                teamMaintainer={data?.teamMaintainer}
                globalSetting={data?.globalSetting}
                industries={data?.industries || []}
                otherIndustries={data?.otherIndustries || ""}
                onChange={onChange}
              />
            );
          case tabIds.TAB_2:
            return (
              <CardList
              isLoading={isLoading}
                id={tabIds.TAB_2}
                options={protocolList}
                storageProtocolList={storageProtocolList}
                didProtocolList={didProtocolList}
                title="Choose Node Configuration"
                selectedValue={data?.nodeconfig || []}
                teamId={data?.teamId}
                onChange={onChange}
              />

            );
          case tabIds.TAB_3:
                return (
                    <Service
                        id={tabIds.TAB_3}
                        options={protocolList}
                        title="Choose the Services"
                        nodeValue={data?.nodeconfig}
                        selectedValue={data?.services || []}
                        onChange={onChange}
                        nextClicked={true}
                    />
                );

          case tabIds.TAB_4:
            return <Summary data={data} />;
          default:
            return null;
        }
      })()}
      <div className="prev-next-btn-section">
        {btnList?.[activeTab]?.prevTab && (
          <Button
            className="prev-btn"
            type="link"
            /*icon={<ArrowLeftOutlined />}*/
            onClick={onPrev}
          >
            Back
          </Button>
        )}
        {btnList?.[activeTab]?.nextTab && (
            <>
              {activeTab === tabIds.TAB_4 ?
                  <>
                    <button disabled={!isActive} className="next-btn" onClick={onNext}>
                      Create
                    </button>
                  </>
                  :
                  <>
                    <Button disabled={!isActive} className="next-btn" onClick={onNext}>
                      Next
                    </Button>
                  </>
              }
            </>

        )}
      </div>
    </div>
  );
};

export default Content;

import React from "react";

export const UploadFile = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 54 40"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M13.7082 39.3327C10.0348 39.3327 6.90123 38.068 4.30734 35.5385C1.71345 32.993 0.416504 29.8916 0.416504 26.2344C0.416504 23.0927 1.359 20.2894 3.244 17.8244C5.14511 15.3594 7.62623 13.7885 10.6873 13.1118C11.7023 9.40629 13.7162 6.40963 16.729 4.12185C19.7579 1.81796 23.1815 0.666016 26.9998 0.666016C31.7204 0.666016 35.7159 2.30935 38.9865 5.59602C42.2732 8.86657 43.9165 12.8621 43.9165 17.5827C46.7037 17.9049 49.0076 19.1132 50.8282 21.2077C52.6648 23.2699 53.5832 25.6866 53.5832 28.4577C53.5832 31.4866 52.5279 34.0563 50.4173 36.1669C48.3068 38.2774 45.7371 39.3327 42.7082 39.3327H29.4165C28.0954 39.3327 26.9596 38.8574 26.009 37.9068C25.0584 36.9724 24.5832 35.8366 24.5832 34.4994V22.0535L20.7165 25.7993L17.3332 22.416L26.9998 12.7493L36.6665 22.416L33.2832 25.7993L29.4165 22.0535V34.4994H42.7082C44.3998 34.4994 45.8257 33.9113 46.9857 32.7352C48.1618 31.5752 48.7498 30.1493 48.7498 28.4577C48.7498 26.766 48.1618 25.3402 46.9857 24.1802C45.8257 23.0041 44.3998 22.416 42.7082 22.416H39.0832V17.5827C39.0832 14.2477 37.9071 11.396 35.5548 9.02768C33.2026 6.67546 30.3509 5.49935 26.9998 5.49935C23.6648 5.49935 20.8132 6.67546 18.4448 9.02768C16.0926 11.396 14.9165 14.2477 14.9165 17.5827H13.7082C11.3721 17.5827 9.38234 18.4124 7.739 20.0718C6.07956 21.7152 5.24984 23.7049 5.24984 26.041C5.24984 28.3771 6.07956 30.391 7.739 32.0827C9.38234 33.6938 11.3721 34.4994 13.7082 34.4994H19.7498V39.3327"
      fill="#464646"
    />
  </svg>
);

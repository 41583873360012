// Essentials
import React, { useState } from "react"; // {useContext}
// Style
import "../styles.less";
import { erc721 } from "@dinesh-kd/wizard";
import {Input, Checkbox, Radio, Form, Space} from "antd"; // notification
import Skeleton from "react-loading-skeleton";
import {
    getPATTokenDetails,
    getUserDetails,
    onKeyPressERC20InputValidation,
    onKeyPressInputValidation, scstudioinputValidation
} from "../../../../deps";
import fetchApi from "../../../../_config/api";

const CUSTODIAL_WALLET = "custodial";
const NON_CUSTODIAL_WALLET = "non-custodial";
const DEVELOPER_WALLET = "developer";

export const ERC721 = ({ instance, onChangeEvent,isLoading }) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const {
        profile: { subscriptionId },
    } = getUserDetails();
    const token = getPATTokenDetails()
    const authToken = token.patToken; // subscriptionId + "_" +
    const [data, setData] = useState({
        name: "ExampleToken",
        symbol: "ETK",
        info: {
            license: "MIT",
            securityContact: "",
        },
    });
    const [chains, setChains] = useState([]);
    const [selected, setSelected] = useState('');
    const [selectUpgrade, setSelectUpgrade] = useState('');
    const [upgradeCheck, setUpgradeCheck] = useState();
    const [accessCheck, setAccessCheck] = useState();
    const [mintCheck, setMintCheck] = useState();
    const [accessControlDisplay, setAccessControlDisplay] = useState(false);
    const [walletType, setWalletType] = React.useState("");
    const [ncWalletData, setNCWalletData] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [projectId, setProjectId] = React.useState("");
    const [projectDetails, setProjectDetails] = React.useState({});
    const [formChainId, setFormChainId] = React.useState("");
    const [formWalletType, setFormWalletType] = React.useState("");

    React.useEffect(() => {
        if(projectId !== ""){
            getChainList();
        }
    }, [projectId]);
    React.useEffect(() => {
        getProjectList();
    }, []);

    const getChainList = async () => {
        const filter = {
            and: [
                {
                    key: "projectID",
                    value: projectId,
                    opt: "eq",
                },
                {
                    key: "subscriptionId",
                    value: subscriptionId,
                    opt: "eq",
                },
                {
                    key: "infraType",
                    value: "blockchainNode",
                    opt: "eq",
                },
                {
                    key: "status",
                    value: "ACTIVE",
                    opt: "eq",
                },
            ],
        };

        const Options = () => {
            return {
                select: "",
                sort: "",
            };
        };

        let requestLink = `endpoints?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({
            method: "GET",
            url: requestLink,
            isDevApp: false,
        })
            .then((response) => {
                setChains(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    const getProjectList = async () => {
        fetchApi({
            method: "GET",
            url: "developer/project-list",
            isDevApp: false,
        })
            .then((response) => {
                setProjectList(response.docs);
            })
            .catch((error) => {
                console.error(error);
            });
    }

    React.useEffect(() => {

        if (instance) {
            const filter = {
                and: [
                    {
                        key: "projectID",
                        value: instance?.ProjectId,
                        opt: "eq",
                    },
                    {
                        key: "subscriptionId",
                        value: subscriptionId,
                        opt: "eq",
                    },
                    {
                        key: "infraType",
                        value: "blockchainNode",
                        opt: "eq",
                    },
                    {
                        key: "status",
                        value: "ACTIVE",
                        opt: "eq",
                    },
                ],
            };

            const Options = () => {
                return {
                    select: "",
                    sort: "",
                };
            };

            let requestLink = `endpoints?options=${JSON.stringify(
                Options()
            )}&filter=${JSON.stringify(filter)}`;

            fetchApi({
                method: "GET",
                url: requestLink,
                isDevApp: false,
            })
                .then((response) => {
                    setChains(response.docs);
                })
                .catch((error) => {
                    console.error(error);
                });
        } else {
        }

    }, [instance]);

    const onChangeWallet = (event, name) => {
        onChange(event.target.name, event.target.value);
        setWalletType(event.target.value);
        setFormWalletType(event.target.value);
        if (event.target.value === NON_CUSTODIAL_WALLET) {
            getAllNCWallets()
        }
    };

    const getAllNCWallets = () => {
        console.log("instance ", instance);
        const {  services: { NonCustodialWallet }  } = projectDetails;
        const options = {
            method: "POST",
            mode: "cors",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken,
                'Instanceid': NonCustodialWallet?.InstanceID
            },
            body: JSON.stringify({
                mode: "api",
            })
        };
        fetch(`${gwURL}/api/v0/ncWallet/getAllWallets`, options)
            .then(response => response.json())
            .then((response) => {
                // showLoader(false);
                if (response.Status === "SUCCESS") {
                    if (response.Data) {
                        setNCWalletData(response.Data)
                    } else {
                        setNCWalletData([]);
                    }
                }
            })
            .catch((error) => {
                // console.log("err::", error);
                setNCWalletData([]);
                // showLoader(false);
            });
    };

    const onTextChange = (e) => {
        onChange(e.target.name, e.target.value);
    };

    const onCheckboxChange = (e) => {
        onChange(e.target.name, e.target.checked);
    };

    const onRadioChange = (e) => {
        onChange(e.target.name, e.target.value);
        if (e.target.name === "Access") {
            setSelected(e.target.value)
            setAccessCheck(true)
        }
        if (e.target.name === "Upgradeable") {
            setSelectUpgrade(e.target.value)
            if (e.target.value === "uups") {
                setAccessCheck(true)
                setAccessControlDisplay(true)
                setSelected("ownable")
            }
            setUpgradeCheck(true)
        }
    };

    const onSelectChange = (e) => {
        onChange(e.target.name, e.target.value);
    };

    const onChange = (name, value) => {
        setData({
            ...data,
            [name]: value,
        });

        setTimeout(() => {
            console.log("bef-data", data);
            let obj = {
                name: "ExampleToken",
                symbol: "ETK",
                info: {
                    license: "MIT",
                    securityContact: "",
                },
            };

            if (name === "custodialWallet") {
                obj.custodialWallet = value;
            } else if (data.custodialWallet) {
                obj.custodialWallet = data.custodialWallet;
            }

            if (name === "name") {
                obj.name = value;
            } else if (data.name) {
                obj.name = data.name;
            }

            if (name === "symbol") {
                obj.symbol = value;
            } else if (data.symbol) {
                obj.symbol = data.symbol;
            }

            if (name === "apiKey") {
                obj.apiKey = value;
            } else if (data.apiKey) {
                obj.apiKey = data.apiKey;
            }

            if (name === "baseUri") {
                obj.baseUri = value;
            } else if (data.baseUri) {
                obj.baseUri = data.baseUri;
            }

            if (name === "Mintable") {
                obj.mintable = value;
                if (value === true) {
                    obj.access = "ownable";
                    setSelected("ownable")
                    setMintCheck(true)
                    setAccessCheck(true)
                    setAccessControlDisplay(true)
                } else if (value === false) {
                    setSelected('')
                    setMintCheck(false)
                    setAccessCheck(false)
                    setAccessControlDisplay(false)
                }
            } else if (data.Mintable) {
                obj.mintable = data.Mintable;
            }

            if (name === "Incremental") {
                obj.incremental = value;
                obj.mintable = true;
                if (value === true) {
                    obj.access = "ownable";
                    setSelected("ownable");
                    setMintCheck(true);
                    setAccessCheck(true);
                    setAccessControlDisplay(true);
                } else if (value === false) {
                    obj.access = "ownable";
                    setSelected("ownable");
                    setMintCheck(true)
                    // setAccessCheck(false)
                    // setAccessControlDisplay(false)
                }
            } else if (data.Incremental) {
                obj.incremental = data.Incremental;
            }

            if (name === "Burnable") {
                obj.burnable = value;
            } else if (data.Burnable) {
                obj.burnable = data.Burnable;
            }

            if (name === "Pausable") {
                obj.pausable = value;
                if (value === true) {
                    obj.access = "ownable";
                    setSelected("ownable")
                    setAccessCheck(true)
                    setAccessControlDisplay(true)
                } else if (value === false) {
                    if(data.Mintable) {
                        obj.access = "ownable";
                        setSelected("ownable");
                        setMintCheck(true)
                        setAccessCheck(true)
                        setAccessControlDisplay(true)
                    } else {
                        setSelected('')
                        setMintCheck(false)
                        setAccessCheck(false)
                        setAccessControlDisplay(false)
                    }
                }
            } else if (data.Pausable) {
                obj.pausable = data.Pausable;
            }

            if (name === "Votes") {
                obj.votes = value;
            } else if (data.Votes) {
                obj.votes = data.Votes;
            }

            if (name === "Enumerable") {
                obj.enumerable = value;
            } else if (data.Enumerable) {
                obj.enumerable = data.Enumerable;
            }

            if (name === "UriStorage") {
                obj.uriStorage = value;
            } else if (data.UriStorage) {
                obj.uriStorage = data.UriStorage;
            }

            if (name === "UpgradeableCheck") {
                obj.upgradeableCheck = value;
                if (value === true) {
                    obj.upgradeable = "transparent";
                    setSelectUpgrade("transparent")
                    setUpgradeCheck(true)
                } else if (value === false) {
                    setSelectUpgrade('')
                    setUpgradeCheck(false)
                    setAccessControlDisplay(false)
                    obj.access = "ownable";
                }
            } else if (data.UpgradeableCheck) {
                obj.upgradeableCheck = data.UpgradeableCheck;
            }

            if (name === "AccessControlCheck") {
                obj.accessCheck = value;
                if (value === true) {
                    obj.accessCheck = "ownable";
                    setSelected("ownable")
                    setAccessCheck(true)
                } else {
                    setSelected('')
                    setAccessCheck(false)
                }
            } else if (data.AccessControlCheck) {
                obj.accessCheck = data.AccessControlCheck;
            }

            if (name === "Upgradeable") {
                obj.upgradeable = value;
            } else if (data.upgradeable) {
                obj.upgradeable = data.upgradeable;
            }

            if (name === "address") {
                obj.owner = value;
            } else if (data.address) {
                obj.owner = data.address;
            }

            if (name === "license") {
                obj.info = {
                    license: value,
                    securityContact: data?.info?.contact,
                };
            } else if (data?.info?.license) {
                obj.info = {
                    license: data?.info?.license,
                    securityContact: data?.info?.securityContact,
                };
            }

            if (name === "contact") {
                obj.info = {
                    license: data?.info?.license,
                    securityContact: value,
                };
            } else if (data?.info?.securityContact) {
                obj.info = {
                    license: data?.info?.license,
                    securityContact: data?.info?.securityContact,
                };
            }

            if (!obj.upgradeable || obj.upgradeable === "uups") {
                if (name === "Access") {
                    obj.access = value;
                } else if (data.access) {
                    obj.access = data.access;
                }
            } else {
                obj.access = false;
            }

            console.log({ name, value });
            console.log("obj", obj);

            if(name === "chain"){
                setFormChainId(value);
            }
            if(name === "dApp"){
                setProjectId(value);
                projectList.map((project) => {
                    if (project.projectID === value) {
                        setProjectDetails(project);
                        setFormWalletType("");
                        setFormChainId("");
                    }
                });
            }

            onChangeEvent({
                ...data,
                [name]: value,
                contract: erc721.print(obj),
                projectDetails: projectDetails,
            });

            // if (name === "license" || name === "contact") {
            //   if (name === "license") {
            //     obj.info = {
            //       securityContact: data?.info?.contact,
            //       license: value,
            //     };
            //   }
            //   if (name === "contact") {
            //     obj.info = {
            //       securityContact: value,
            //       license: data?.info?.license,
            //     };
            //   }
            // }

        }, 100);
    };

    return (
        <>
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <Input
                    className="input-container"
                    placeholder="Contract Name"
                    name="name"
                    onChange={onTextChange}
                    maxLength={100}
                    onKeyPress={scstudioinputValidation}
                />
            )}
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <Input
                    className="input-container"
                    placeholder="Symbol"
                    name="symbol"
                    onChange={onTextChange}
                    maxLength={100}
                    onKeyPress={onKeyPressInputValidation}
                />
            )}
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <Input
                    className="input-container"
                    placeholder="Base URI"
                    name="baseUri"
                    onChange={onTextChange}
                />
            )}
            <div className="note">Provides the metadata link, https://github.com/docs</div>
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <select
                    className="create-contract-dropdown-filter"
                    style={{background:"#2e2e2e"}}
                    placeholder="Choose dApp"
                    name="dApp"
                    onChange={onSelectChange}
                >
                    <option value="">Choose dApp</option>
                    {projectList && projectList.length > 0 && projectList.map((project, ind) => (
                        <option key={ind} value={project.projectID}>{project.projectName}</option>
                    ))}
                </select>
            )}

            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <select
                    value={formChainId}
                    className="create-contract-dropdown-filter"
                    style={{background:"#2e2e2e"}}
                    placeholder="Choose Chain for Collection"
                    name="chain"
                    onChange={onSelectChange}
                >
                    <option value="">Choose Chain for Collection</option>
                    {chains && chains.length > 0 && chains.map((chain, ind) => (
                        <option key={ind} value={chain.chainId}>
                            {chain.protocolName}
                        </option>
                    ))}
                </select>
            )}

            <Form.Item name="walletType" rules={[
                {
                    required: true,
                    message: "Select Wallet Type",
                },
            ]}>
                {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <select
                        value={formWalletType}
                        className="create-contract-dropdown-filter"
                        style={{background:"#2e2e2e"}}
                        placeholder="Choose Wallet Type"
                        name="walletType"
                        onChange={(event) => onChangeWallet(event, "walletType")}
                    >
                        <option value="">Choose Wallet Type</option>
                        {/*<option value={CUSTODIAL_WALLET}>Custodial</option>*/}
                        <option value={NON_CUSTODIAL_WALLET}>Self-Managed</option>
                        <option value={DEVELOPER_WALLET}>Developer-Wallet</option>
                    </select>
                )}
            </Form.Item>
            {walletType === NON_CUSTODIAL_WALLET && (
                <Form.Item name="custodialWallet"
                           rules={[
                               {
                                   required: walletType === NON_CUSTODIAL_WALLET,
                                   message: "Select Wallet",
                               },
                           ]}>
                    {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <select className="create-contract-dropdown-filter"
                                style={{background:"transparent"}}
                                placeholder="Choose Wallet" name="custodialWallet"
                                onChange={onSelectChange}
                        >
                            <option value="">Choose Wallet</option>
                            {ncWalletData.map((wallet, ind) => (
                                <option key={ind} value={wallet.walletId}>{wallet.walletName}</option>
                            ))}
                        </select>
                    )}
                </Form.Item>
            )}
            {walletType === DEVELOPER_WALLET && (
                <Form.Item name="custodialWallet"
                           rules={[
                               {
                                   required: walletType === DEVELOPER_WALLET,
                                   message: "Select Wallet",
                               },
                           ]}>
                    <Input
                        title={"Private Key"}
                        className="input-container"
                        placeholder="Private Key"
                        name="privateKey"
                        onChange={onTextChange}
                    />
                </Form.Item>
            )}
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <Input
                    className="input-container"
                    placeholder="Contact"
                    name="contact"
                    onChange={onTextChange}
                    maxLength={100}
                    onKeyPress={onKeyPressERC20InputValidation}
                />
            )}
            {isLoading?(<Skeleton height={45} width="80%" borderRadius={10} style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <Input
                    className="input-container"
                    placeholder="License"
                    name="license"
                    defaultValue={"MIT"}
                    onChange={onTextChange}
                    maxLength={100}
                    onKeyPress={onKeyPressInputValidation}
                />
            )}
            <div style={{fontSize:"16px",fontWeight:"700"}} className="template-header">Features</div>
            <div style={{display:"flex",gap:"30px"}}>
                <ul>
                    <li>
                        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                            <Checkbox onChange={onCheckboxChange} checked={mintCheck} name="Mintable">
                                <span className="create-contract-text">Mintable</span>
                            </Checkbox>
                        )}
                    </li>

                    <li>
                        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                            <Checkbox onChange={onCheckboxChange} name="Incremental">
                                <span className="create-contract-text">Auto Increment Ids</span>
                            </Checkbox>
                        )}
                    </li>

                    <li>
                        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                            <Checkbox onChange={onCheckboxChange} name="Burnable">
                                <span className="create-contract-text">Burnable</span>
                            </Checkbox>
                        )}
                    </li>
                </ul>
                <ul>
                    <li>
                        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                            <Checkbox onChange={onCheckboxChange} name="Pausable">
                                <span className="create-contract-text">Pausable</span>
                            </Checkbox>
                        )}
                    </li>

                    <li>
                        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                            <Checkbox onChange={onCheckboxChange} name="Votes">
                                <span className="create-contract-text">Votes</span>
                            </Checkbox>
                        )}
                    </li>

                    <li>
                        {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                            <Checkbox onChange={onCheckboxChange} name="Enumerable">
                                <span className="create-contract-text">Enumerable</span>
                            </Checkbox>
                        )}
                    </li>
                </ul>
            </div>

            <div style={{fontSize:"16px",fontWeight:"700"}} className="template-header">
                Access Control
                <span> </span>
                {/*{isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(*/}
                {/*    <input*/}
                {/*        style={{cursor:"pointer"}}*/}
                {/*        type="checkbox"*/}
                {/*        className="normalCheckbox"*/}
                {/*        onClick={onCheckboxChange}*/}
                {/*        checked={accessCheck}*/}
                {/*        disabled={accessControlDisplay}*/}
                {/*        name="AccessControlCheck" />*/}
                {/*)}*/}
            </div>

            <Radio.Group onChange={onRadioChange} value={selected} name="Access">
                {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <Radio value="ownable"><span className="create-contract-text">Ownable</span></Radio>
                )}
                {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <Radio value="roles"><span className="create-contract-text">Roles</span></Radio>
                )}
            </Radio.Group>

            <div style={{fontSize:"16px",fontWeight:"700"}} className="template-header">Upgradeability
                <span> </span>
                {/*{isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(*/}
                {/*    <input  style={{cursor:"pointer"}} type="checkbox" className="normalCheckbox" onClick={onCheckboxChange} checked={upgradeCheck} name="UpgradeableCheck" />*/}
                {/*)}*/}
            </div>

            <Radio.Group onChange={onRadioChange} value={selectUpgrade} name="Upgradeable">
                {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <Radio value="transparent"><span className="create-contract-text">Transparent</span></Radio>
                )}
                {isLoading?(<Skeleton height={20} width={150}  style={{marginTop:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <Radio value="uups"><span className="create-contract-text">UUPS</span></Radio>
                )}
            </Radio.Group>
        </>
    );
};

export default ERC721;

/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { Tabs } from "antd";
import { deepCopy, DEVELOPERROLE, ENTERPRISEDEVELOPERROLE, getUserDetails, } from "../../../../deps";
import { Account } from "./account";
import BillingAndSubscription from "./billing&subscription";

import '../styles.less'
// import { PlanBilling } from "../myAccount/planBilling";
// import { Referral } from "../myAccount/referral";
import Skeleton from "react-loading-skeleton";
import {Referral} from "./referral";


export const AccountSettings = ({ showLoader,isLoading }) => {
    const { userRole = DEVELOPERROLE } = getUserDetails().profile || {};

    const { pathname } = useLocation();
    let [activeKey, setActiveKey] = useState("account");

    const location = useLocation();
    useEffect(() => {
        if (location.state != null && location.state.activeKey === "referral"){
            setActiveKey(location.state.activeKey);
        }
    }, [location.state]);
    const onChange = (key) => {
        setActiveKey(key);
    };

    let TabItem;
    userRole === ENTERPRISEDEVELOPERROLE ? TabItem = [
        {
            label: <>{!isLoading?(<Skeleton height={55} style={{marginBottom:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):('Account')}</>,
            key: "account",
            children: (
                <Account
                    showLoader={showLoader}
                    isLoading={isLoading}
                />
            ),
        },

    ] : TabItem = [
        {
            label: <>{isLoading?(<Skeleton height={35} width={100} baseColor="#262626" highlightColor="#404040" ></Skeleton>):('Profile')}</>,
            key: "account",
            children: (
                <Account
                    showLoader={showLoader}
                    isLoading={isLoading}
                />
            ),
        },
        {
            label:<>{isLoading?(<Skeleton height={35} width={150} baseColor="#262626" highlightColor="#404040" ></Skeleton>):('Subscription')}</>,
            key: "billing&subscription",
            children: (
                <BillingAndSubscription
                    showLoader={showLoader}
                    isLoading={isLoading}
                />
            ),
        },
        // {
        //     label:<>{isLoading?(<Skeleton height={35} width={100} baseColor="#262626" highlightColor="#404040" ></Skeleton>):('Billing')}</>,
        //     key: "billing",
        //     children: (
        //         <PlanBilling
        //             showLoader={showLoader}
        //             activeKey={activeKey}
        //             isLoading={isLoading}
        //         />
        //     ),
        // },
        {
            label:<>{isLoading?(<Skeleton height={35} width={100} baseColor="#262626" highlightColor="#404040" ></Skeleton>):('Referral')}</>,
            key: "referral",
            children: (
                <Referral
                    showLoader={showLoader}
                    activeKey={activeKey}
                    isLoading={isLoading}
                />
            ),
        },

    ];

    return (
        <>
            <div className="my-account-page-container">
                <Tabs
                    onChange={onChange}
                    activeKey={activeKey}
                    items={TabItem}
                />
            </div>
        </>
    );
};

export default AccountSettings;

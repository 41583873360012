import React, { useMemo, useRef, useState, useEffect } from "react";
import { Modal } from "antd";
import { Menus } from "../../../assets";
import { Steps } from "antd";
import { Select, Spin, Button } from "antd";
import "antd/dist/antd.css";
import { Input, Space } from "antd";
import { notification } from "antd";
import fetchApi from "../../../../component/_config/api";
import { getUserDetails } from "../../../deps";
import { PlusCircleOutlined, MinusCircleOutlined } from "@ant-design/icons";

const { Option } = Select;

const { Step } = Steps;
notification.config({ duration: 2 });

export const AddTeamPopup = ({
  projectId,
  isEdit = false,
  step = 0,
  isModalOpen,
  handleCancel,
  togglestate,
    reloadsus,
    reloadactive
}) => {
  const [stepSize, setStepSize] = React.useState(step);
  const [budjetValue, setSudjetValue] = React.useState(0);
  const [users, setUsers] = useState([]);
  const { enterpriseId } = getUserDetails().profile || {};
  const [roles, setRoles] = useState([]);
  console.log("isModalOpen",isModalOpen)
  useEffect(() => {
    getRoles()
  }, []);
  const  getRoles = () => {
    return fetchApi({
      method: "GET",
      url: `teams/getDeveloperRoles`,
      isDevApp: false,
    }).then((response) => {
      if (response.statusCode === 200 || response.statusCode === 201) {
        let role = response.doc.map((role) => ({
          label: `${role.name}`,
          value: role.roleId,
        }));
        setRoles([...role])
      }
    })
  }
  const [disable,setDisable] = useState(true)
  const [data, setData] = useState({
    name: "",
    desc: "",
  });

  const [developers, setDevelopers] = useState([
    {
      developerId: "",
      roleId: "",
    },
  ]);
  // console.log(developers)

  const addDeveloper = () => {
    if (developers.every((item) => item.developerId !== "" && item.roleId !== "")) {
      setDevelopers([
        ...developers,
        {
          developerId: "",
          roleId: "",
        },
      ]);
    }
  };

  const removeDeveloper = (index) => {
    const rows = [...developers];
    rows.splice(index, 1);
    setDevelopers(rows);
  };

  const handleChange = (index, event, name) => {
    const value = event.target.value;
    if(name === "developerId"){
      const isValueAlreadySelected = developers.some((item, i) => i !== index && item[name] === value);
      if (isValueAlreadySelected) {
        notification.error({
          message: "Developer already selected",
          duration: 3,
        });
        return;
      }
    }
    const newList = developers.map((item, i) => {
      if (i === index) {
        return { ...item, [name]: value };
      }
      return item;
    });

    setDevelopers(newList);
  };


  const onChange = (id, value) => {
    data[id] = value;
    setData({ ...data });
  };

  const nextStep = () => {
    const clearData = developers.map((data)=>{
      const {roleId,developerId}=data
    });
    if (data.name !== "" && data.desc !== ""){
      setStepSize(1);
      setDisable(false)
    }else{
      setDisable(true)
    }

  };

  const backStep = () => {
    setStepSize(0);
  };
  const oncancel = ()=>{
    setData({name:"",desc:""})
    setDevelopers([{roleId:"",developerId:""}])
    setStepSize(0);
    handleCancel()
  }

  const addProject = () => {

    let accessBody = ""
    const hasEmptyDeveloperIds = developers.some(
        (item) => item.developerId === ""
    );
    const hasEmptyRoleIds = developers.some((item) => item.roleId === "");
    if (hasEmptyDeveloperIds){
      notification.error({
        message: "please select the developer",
        duration: 3,
      });
      return;
    }if (hasEmptyRoleIds){
      notification.error({
        message: "please select the role",
        duration: 3,
      });
      return;
    }
     accessBody = {
      ...data,
      enterpriseId: enterpriseId,
       developers,
      projects:[]
    };
    fetchApi({
      method: "POST",
      url: `teams/create`,
      data: accessBody,
      isDevApp: false,
    })
      .then((response) => {
        if (response.statusCode === 200 || response.statusCode === 201) {
          setStepSize(0);
          notification.success({
            message: "successfully created",
            duration: 3,
          });
          handleCancel();
          oncancel()
          reloadsus()
          reloadactive()
          togglestate();
        }else{
          notification.error({
            message: response.message,
            duration: 3,
          });
        }
      })
        .catch((err) => {
      });
  };

  const updateProject = () => {
    const accessBody = {
      ...data,
      creditAvailable: budjetValue,
    };
    fetchApi({
      method: "PUT",
      url: `projects/${projectId}`,
      data: accessBody,
      isDevApp: false,
    })
      .then((response) => {
        handleCancel();
        setStepSize(0);
        notification.success({
          message: "successfully created",
          duration: 3,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  async function fetchUserList(username) {
    return fetchApi({
      method: "GET",
      url: `enterprise-developer/teamDevelopersList`,
      isDevApp: false,
    }).then((body) => {
      let users = body.docs.map((user) => ({
        label: `${user.firstName} ${user.lastName}`,
        value: user.developerId,
      }));
      setUsers([...users]);
    });
  }

  useEffect(() => {
    fetchUserList();
  }, []);

  return (
    <>
      <Modal title="" footer={null} closable={false} open={isModalOpen}>
        <div className="popup-request-container add-teams-container">
          <div className="close-icon" onClick={oncancel}>
            <Menus type="close" />
          </div>
          <div className="popup-text">Add Team</div>
          <div>
            <Steps current={stepSize} size="large">
              <Step title="Team Details" />
              <Step title="Add Developers New" />
            </Steps>
          </div>
          {stepSize == 0 && <Project onChange={onChange} data={data}/>}
          {stepSize == 1 && (
            <div>
              {developers.map((data, index) => {
                const { roleId, developerId } = data;
                
                return (
                  <div className="devselect" key={index}>
                    <Space
                        direction="vertical"
                        size={20}
                        style={{ display: "flex", marginTop: "20px" }}
                    >
                    <select
                        className="dropdown-filter create-collection-drop"
                      // size="large"
                      // mode="single"
                      // allowClear
                      placeholder="Select developer *"
                      value={developerId}
                      name="developerId"
                      onChange={(evnt) => handleChange(index, evnt, "developerId")}
                      // bordered={false}
                      // showArrow={true}
                      style={{
                        width: "100%",
                      }}
                    >
                      <option  value="">Select developer *</option>
                      {/* <Option value="1">Select 1</Option>
                      <Option value="2">Select 2</Option> */}
                      {users &&
                        users?.map((item, index) => (
                          <option  key={index} value={item.value}>
                            {item.label}
                          </option>
                        ))}
                    </select>
                    <div>
                      <select
                        // size="large"
                        // mode="single"
                        // allowClear
                        className="dropdown-filter select-role-width"
                        placeholder="Select role *"
                        name="roleId"
                        value={roleId}
                        onChange={(evnt) => handleChange(index, evnt, "roleId")}
                        // bordered={false}
                        // showArrow={true}
                      >
                        <option  value="">Select role *</option>
                        {roles &&
                          roles?.map((item, index) => (
                            <option  key={index} value={item.value}>
                              {item.label}
                            </option>
                          ))}
                      </select>

                      {developers.length !== index + 1 && (
                        <Button
                          type="default"
                          shape="round"
                          icon={<MinusCircleOutlined />}
                          onClick={() => {
                            removeDeveloper(index);
                          }}
                        />
                      )}

                      {developers.length === index + 1 && (
                          <>
                            <Button
                                type="default"
                                shape="round"
                                icon={<PlusCircleOutlined />}
                                onClick={addDeveloper}
                            />
                            {developers.length > 1 &&
                            <Button
                                type="default"
                                shape="round"
                                icon={<MinusCircleOutlined/>}
                                onClick={() => {
                                  removeDeveloper(index);
                                }}
                            />
                            }
                          </>

                      )}
                    </div>
                    </Space>
                  </div>
                );
              })}
            </div>
          )}
          <div className="btn-section mt-4">
            {stepSize == 0 && (
              <button className="ok-btn"  style={{
                cursor: data.name === "" || data.desc === "" ? "not-allowed" : "pointer",
              }} onClick={nextStep}>
                Continue
              </button>
            )}
            {stepSize == 1 && !isEdit && (
              <button className="ok-btn" onClick={addProject}>
                Submit
              </button>
            )}
            {stepSize == 1 && isEdit && (
              <button className="ok-btn" onClick={updateProject}>
                Update
              </button>
            )}
            {stepSize == 1 && (
              <button className="cancel-btn" onClick={backStep}>
                Back
              </button>
            )}
            {stepSize == 0 && (
              <button className="cancel-btn" onClick={oncancel}>
                Cancel
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

export const Project = ({ onChange,data }) => {
  const onTextChange = (e) => {
    onChange(e.target.id, e.target.value);
  };

  return (
    <div className="column-add-project-container mt-4">
      <Space direction="vertical" size={20}>
        <Input
          id="name"
          className="input-container"
          placeholder="Team Name *"
          onChange={onTextChange}
          value={data.name}
        />
        <Input.TextArea
          rows={5}
          id="desc"
          className="input-container"
          placeholder="Team Description *"
          onChange={onTextChange}
          value={data.desc}
        />
      </Space>
    </div>
  );
};

export const IconSlider = (
  { setSudjetValue, budjetValue, min = 0, max = 400 },
  props
) => {
  const marks = {
    [`${min}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{min}</strong>,
    },
    [`${max}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{max}</strong>,
    },
  };
  return <div className="icon-wrapper mt-4"></div>;
};

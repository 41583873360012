import React from "react";
import {Image as Img, Input} from "antd";
import "./styles.less";
import didImage from "../../../assets/images/didImg.avif"
import CopyRequest from "../endpoints/copyRequest";

export const Card = ({ item,onNavigate }) => {
    const [imageRatio, setImageRatio] = React.useState(280);

    const onClick = () => {
        onNavigate(item.instanceId,item.ProjectId);
    };
    // console.log("item",item)
    return (
        <div className="card-box-container">
            <Img
                width={imageRatio}
                height={200}
                src={
                    item.image !== "" && item.image !== undefined
                        ? item.image
                        : didImage
                }
            />
            <div className="name-container">
                <div className="name-section">
                    <div className="project-name">Instance ID: {item.instanceId} <CopyRequest request={item.instanceId}/></div>
                    <div className="project-name">dApp Name : {item.projectDetails?.projectName}</div>
                    {/*<div className="project-name">API Key: {item.instanceKey}</div>*/}
                </div>
            </div>
            <div className="btn-container">
                <button style={{width:300}} className="view-details" onClick={onClick}>
                    Build It
                </button>
            </div>
        </div>
    );
};

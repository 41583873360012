import { useEffect, useState, useRef } from 'react';

const useIntersectionObserver = (options) => {
    const [hasIntersected, setHasIntersected] = useState(false);
    const ref = useRef(null);
  
    useEffect(() => {
      const observer = new IntersectionObserver(([entry]) => {
        if (entry.isIntersecting) {
          setHasIntersected(true);
          observer.unobserve(entry.target); // Stop observing after the first intersection
        }
      }, options);
  
      if (ref.current) {
        observer.observe(ref.current);
      }
  
      return () => {
        if (ref.current) {
          observer.unobserve(ref.current);
        }
      };
    }, [options]);
  
    return [ref, hasIntersected];
};

export default useIntersectionObserver;

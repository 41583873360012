import React from 'react'
import { Menus } from "../../assets";
import { menuList } from "./constant";
import KrypcLogo from "../../../../src/assets/images/logo.avif";

import {
  getUserDetails,
  DEVELOPERROLE,
} from "../../deps";
import "./style.less";

import SubMenu from "./SubMenu";

const SideNav = ({
    hasPremium = false,
  pageTitle,
  onSignOut,
  highlightTitle,
  onNavigate,
  onSupport,
  onSettings,
  navigateToAI
}) => {

    const {
        profile: { userRole = DEVELOPERROLE },
      } = getUserDetails();

  return (
    <div className="side-nav-container-new">
      {/*<div className="my-badge">BETA</div>*/}
      <div className="header-logo-container1">
        <img alt="krypcore-beta" src={KrypcLogo}  style={{height:'60px',width:'255px'}} />
      </div>


      <div
        className={`menu-container${hasPremium ? " premium-menu-container" : ""
          }`}
      >
        {menuList[userRole].map((list, index) => {
          return <SubMenu list={list} key={index} pageTitle={pageTitle} highlightTitle={highlightTitle} />;
        })}

{/* 
        <div className="down-menu-container mt-lg-5">
          <div className="down-menu-item">
            <Menus type="account settings" />
            <span className="down-menu-text" onClick={onSettings}>
              My Account
            </span>
          </div>
          <div className="down-menu-item">
            <Menus type="help" />
            <span className="down-menu-text" onClick={onSupport}>
              Support
            </span>
          </div>
          <div className="down-menu-item">
            <Menus type="logout" />
            <span className="down-menu-text" onClick={onSignOut}>
              Logout
            </span>
          </div>
        </div> */}
      </div>

    </div>
  )
}

export default SideNav
import React from "react";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import '../enterprisePagestyles.less';
import { DEVELOPERROLE } from "../../../deps";

export const Pricingplan = ({ refe }) => {
    const navigate = useNavigate();

    const onNavigate = () => {
        navigate("/contact-us");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const onNavigatePlan = () => {
        // console.log("plan")
        navigate("/pricing");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };

    const { loginWithRedirect } = useAuth0();

    const onNavigateSignup = () => {
        loginWithRedirect({
            screen_hint: 'signup',
            appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
            userRole: DEVELOPERROLE,
            userType: "INDIVIDUAL",
        });
    };

    return (
        <>
            <div ref={refe} className="pricingplan-wrapper" id="pricing_works">
                {/*<div className="container">*/}
                <div className="" id="pricing_plan">
                    <div className="theme-heading pt-lg-4">
                        <h3 style={{ fontWeight: 700 }}><span className="plan-highlight">Fueling Your Web3 Journey</span></h3>
                    </div>
                </div>
                <div className="plan-section">
                    {/*<div  className="plan-card-container">*/}
                    {/*    <div className="plandetail-card">*/}
                    {/*        <h3>Free</h3>*/}
                    {/*        <h5>Gain access to basic features and tools to begin your Web3 project with no initial expenses</h5>*/}
                    {/*        <p>*/}
                    {/*           <span className="plan-credit-num">100k</span> credits/month*/}
                    {/*        </p>*/}
                    {/*        <button*/}
                    {/*            className="contact-us-button" onClick={() => onNavigateSignup()}*/}
                    {/*        >*/}
                    {/*            Get Started*/}
                    {/*        </button>*/}
                    {/*        <ul className="Free-plan-basic-ul">*/}
                    {/*            <li>Basic Web3 Services</li>*/}
                    {/*            <li>7 dApps</li>*/}
                    {/*            <li>25 Request/sec</li>*/}
                    {/*            <li>Basic & Community Support</li>*/}
                    {/*        </ul>*/}
                    {/*        <a><span onClick={() => onNavigatePlan()}><u>view more</u></span></a>*/}
                    {/*    </div>*/}

                    {/*</div>*/}
                    <div className="plan-card-container">
                        <div className="plandetail-card">
                            <h3>Free Tier(Starter)</h3>
                            <h5>Enhanced scalability and gain access to a wider range of features and tools that empower your projects</h5>
                            <p>
                                <span className="plan-credit-num">Free</span>
                            </p>
                            <button
                                className="plan-button" onClick={() => onNavigateSignup()}
                            >
                                Get Started
                            </button>
                            <ul className="Starter-plan-basic-ul">
                                <li>Starter Web3 Services</li>
                                <li>50,000/month</li>
                                <li>1 (Individual use only)</li>
                                <li>Upto 5 projects</li>
                            </ul>
                            <a><span onClick={() => onNavigatePlan()}><u>view more</u></span></a>
                        </div>

                    </div>
                    <div className="pro-card-container">
                        <div className="pro-plandetail-card">
                            <button
                                className="most-popular"
                            >
                                Most popular
                            </button>
                            <h3>Developer Plan</h3>
                            <h5>Unlock a suite of premium features tailored for professional teams
                            </h5>
                            <br />
                            <p><span className="plan-credit-num">
                                $99</span> /month


                            </p>
                            <button
                                className="contact-us-button-popular" onClick={() => onNavigate()}
                            >
                                Contact us
                            </button>
                            <ul className="Pro-plan-basic-ul">
                                <li>Advanced Web3 Services</li>
                                <li>250,000/month</li>
                                <li>1 (Individual use only)</li>
                                <li>Unlimited</li>
                            </ul>
                            <a><span onClick={() => onNavigatePlan()}><u>view more</u></span></a>
                        </div>

                    </div>
                    <div className="plan-card-container">
                        <div className="plandetail-card">

                            <h3>Enterprise</h3>
                            <h5>Need more? Get in touch with us to explore a fully tailored service that addresses your enterprise's unique challenges and solutions.</h5>
                            <p><span className="plan-credit-num">
                                $1,999</span> /month</p>
                            <button
                                className="plan-button" onClick={() => onNavigate()}
                            >
                                Contact us
                            </button>
                            <ul className="Enterprise-plan-basic-ul">
                                <li>Full Suite of Web3 Services</li>
                                <li>5,000,000/month</li>
                                <li>Upto to 20 Developers</li>
                                <li>Unlimited</li>
                            </ul>
                            <a><span onClick={() => onNavigatePlan()}><u>view more</u></span></a>
                        </div>
                    </div>

                </div>
                {/*<div className="plan-bottom"></div>*/}
                <br />
                {/*<div className="planList">
              <button
                  className="getstart-button" onClick={() => onNavigatePlan()}
              >
                  View Detailed Plan
              </button>
          </div>*/}


            </div>

        </>
    );
};

export default Pricingplan;

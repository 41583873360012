/* eslint-disable react-hooks/exhaustive-deps */
import React from "react";
import { Alert, Space } from "antd";
import {Link, useLocation} from "react-router-dom";
import "./style.less";
import {Logos} from "../../assets";
import {Helmet} from "react-helmet";

const MaintenancePage = () => {
  const { pathname } = useLocation();
    const telegram = process.env.REACT_APP_TELEGRAM_URL;
    const twitter= process.env.REACT_APP_TWITTER_URL;
    const discord= process.env.REACT_APP_DISCORD_URL;
    const linkedIn= process.env.REACT_APP_LINKEDIN_URL;
    const youtube=  process.env.REACT_APP_YOUTUBE_URL;
    const open = (value)=>{
        window.open(value)
    }
  return (
      <>
          <Helmet>
              <meta name="robots" content="noindex, nofollow" />
          </Helmet>
        {/*<Space direction="vertical" style={{ width: "100%",}}>
          <Alert message={pageTitle} type="info" closable onClose={onClose} />
        </Space>*/}
        {/*<div className="error-image err-body">
          <h1>👨‍🔧</h1>
        </div>
        <div className="error-msg-container">
          <h1>We're working on this now!</h1>
          <p>KrypCore Web3 is currently undergoing maintenance. We'll provide an update on the status in a couple of hours. Thank you for your patience.</p>
          <p>We apologize for any inconvenience.</p>
        </div>*/}

          <div className="maintenance">
              <div className="maintenance_contain">
                  <h1>👨‍🔧</h1>
                      <span className="pp-infobox-title-prefix">WE ARE WORKING ON THIS</span>
                      <div className="pp-infobox-title-wrapper">
                          <h3 className="pp-infobox-title">Krypcore Web3 is currently undergoing maintenance!</h3>
                      </div>
                      <div className="pp-infobox-description">
                          <p>We'll provide an update on the status in a couple of hours. Thank you for your patience.</p>
                          <p>We apologize for any inconvenience.</p>
                      </div>

                  <span className="title-text pp-primary-title">We are social</span>
                  <div className="pp-social-icons pp-social-icons-center pp-responsive-center">
                    <span className="pp-social-icon">
                        <Link itemProp="url" href="#">
                        <a onClick={() => open(telegram)}><Logos type="telegram"/></a>
                        </Link>
                    </span>
                    <span className="pp-social-icon">
                        <Link itemProp="url" href="#">
                        <a onClick={() => open(twitter)}><Logos type="footertwitter"/></a>
                        </Link>
                    </span>
                      <span className="pp-social-icon">
                        <Link itemProp="url" href="#">
                        <a onClick={() => open(discord)}><Logos type="footerdiscord"/></a>
                        </Link>
                    </span>
                      <span className="pp-social-icon">
                        <Link itemProp="url" href="#">
                        <a onClick={() => open(linkedIn)}><Logos type="footerlinkedin"/></a>
                        </Link>
                    </span>
                      <span className="pp-social-icon">
                        <Link itemProp="url" href="#">
                        <a onClick={() => open(youtube)}><Logos type="footerYoutube"/></a>
                        </Link>
                    </span>

                  </div>


          </div>
          </div>
      </>
  );
};
export default MaintenancePage

import React, {useRef, useState} from "react";
import '../demoStyles.less'
import graph from './graph.avif'
import choose from './choose.avif'
import  sensor from '../kc-sensor.webp'
import  report from './report.webp'

export const Banner = () => {

  return (
    <>
      <div className="Bannersection-wrap" id="home">
        <div className="container">
          <div className="col-md-10 mx-auto banner-head-container">
            <h1 className="banner-heading">
              <span className="banner-highlight"> KRYPCORE SENSOR </span>
            </h1>
            <p className="banner-para">
              Empowering AML, Entity-Based Tracking, and Fraud Prevention in Cryptocurrency Transactions​
            </p>
          </div>
        </div>
        <div class="kcsensor-container_1">
          <div class="left-section">
            <h2>Cryptocurrency Forensics</h2>
            <p>Krypcore sensor is a tool that serves as the investigative tool linking cryptocurrency transactions with real-world identities. It delves into illicit actions, such as tracing the flow of stolen funds, while also highlighting lawful services like DeFi platforms, mining pools, and merchant services.</p>
          </div>
          <div class="right-section">
            <img src={graph} alt="Your Image"/>
          </div>
        </div>
        <div class="kcsensor-container_2">
          <div class="right-section">
            <img src={choose} alt="Your Image"/>
          </div>
          <div class="left-section">
            <h2>Why Choose Krypcore Sensor</h2><br/>
            <ul>
              <li><b>AML Detection:</b> Real-time alerts for suspected money laundering activities</li>
              <li><b>Fraud Prevention:</b> Identification of bad actors engaged in Illicit Transactions</li>
              <li><b>Ethical Transactions:</b> Promoting legitimate and transparent crypto practices</li>
              <li><b>Combatting Cyber Crime:</b> Enhancing security in digital financial transactions</li>

            </ul>
          </div>
        </div>
        <div class="kcsensor-container_3">
          <div class="left-section">
            <h2>Features of Krypcore Sensor</h2><br/>
            <ul>
              <li><b>Real-Time Monitoring:</b> immediate alerts on suspect transactions</li>
              <li><b>Advanced Tracking:</b> Tracing cryptocurrency provenance</li>
              <li><b>Entity-Based Analysis:</b> Identifying and profiling entities involved</li>
              <li><b>Compliance Integration:</b> Seamless integration with AML regulations</li>

            </ul>
          </div>
          <div class="right-section">
            <img src={sensor} alt="Your Image"/>
          </div>
        </div>
        <div class="kcsensor-container_4">
          <div class="right-section">
            <img src={report} alt="Your Image"/>
          </div>
          <div class="left-section">
            <h2>A Complete Knowledge Graph in Crypto Transactions</h2><br/>
            <ul>
              <li>Understanding Complex Interconnections in Crypto Transactions</li>
              <li>Unveiling Relationship Patterns between Entities and Transactions</li>
              <li>Enhancing Forensic Analysis for Money Laundering Detection</li>
              <li>Visualizing and Analyzing Cryptocurrency transactions for Effective Tracking</li>

            </ul>
          </div>
        </div>

      </div>
    </>
  );
};

export default Banner;

import React, { useEffect, useState } from 'react'
import Blog1 from '../../../assets/icons/Blog1.png'
import Blog2 from '../../../assets/icons/Blog2.png'
import Blog3 from '../../../assets/icons/Blog3.png'
import ArrowRIght from '../../../assets/icons/image 93.svg'
import { useInView } from 'react-intersection-observer'
import { Tabs } from 'antd';
import axios from 'axios'
import Icon1 from '../../../assets/icons/Group 151.svg'
import twitterIcon from '../../../assets/icons/Group 170.svg'
import youtubeIcon from '../../../assets/icons/image 95.svg'
import telegramIcon from '../../../assets/icons/Group 155.svg'
import linkedInIcon from '../../../assets/icons/image 96.svg'
import logo1 from '../../../assets/icons/400 1 (1).png'
import arrow1 from '../../../assets/icons/Arrow 1.svg'
import { Link, useParams } from 'react-router-dom'
import { TwitterTimelineEmbed } from 'react-twitter-embed'
import { Timeline } from 'react-twitter-widgets'
import useIntersectionObserver from './useIntersectionObserver'



const KrypcoreBlogs = () => {
    const { TabPane } = Tabs;

    const twitter1= process.env.REACT_APP_TWITTER_URL
    const telegram1 = process.env.REACT_APP_TELEGRAM_URL
    const discord1= process.env.REACT_APP_DISCORD_URL
    const linkedIn1= process.env.REACT_APP_LINKEDIN_URL
    const youtube1=  process.env.REACT_APP_YOUTUBE_URL



      const [posts3, setPosts3] = useState([]);

      const {id}=useParams()
      console.log(id)

      useEffect(()=>{
        fetchData1()
      },[])

      const fetchData1 =  () => {

            // const withoutPTags = response.data?.excerpt.replace(/<p>|<\/p>/g, '')
            // setPosts2(withoutPTags)
            axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts`)
            .then(response=>{
              if(response.data !==''){
                const FilterData=response.data.filter((data)=>data.slug !==id)
                setPosts3(FilterData.slice(0,2))
              }
            })
            .catch(err=>{
              console.log(err)
            })


        .catch (err=>{
          console.error('Error fetching data:', err);
        })
    };


const [activeTab, setActiveTab] = useState('1');

// Define styles for the tabs and the tab indicator
const tabStyle = {
//   border: '1px solid #1890ff',
  borderRadius: '8px',
  background: 'black',
  color:'white',
  marginBottom: '-1px', // To remove the bottom border overla

lineHeight: '60px',
textAlign: 'left',



};

const indicatorStyle = {
  backgroundColor: '#1890ff',
  height: '3px',
  borderRadius: '8px 8px 0 0', // Rounded corners only on the top
};

// Function to handle tab change
const handleTabChange = (key) => {
  setActiveTab(key);
};

// const screenName = 'AKASSH95751041'; // Twitter screen name
// const count = 5; // Number of tweets to fetch


//   const fetchTweetIds = async (screenName, count) => {
//     try {
//       const response = await axios.get(
//         `https://api.twitter.com/1.1/statuses/user_timeline.json?screen_name=${screenName}&count=${count}`,
//         {
//           headers: {
//             Authorization: `qQ0DDtr1KKAIMKVzDSOhWvrScOFaA2UEl61v8CgK0BlaJ`, // Add your Twitter API access token here
//           },
//         }
//       );
//       const tweetIds = response.data.map(tweet => tweet.id_str);
//       return tweetIds;
//     } catch (error) {
//       console.error('Error fetching tweet IDs:', error);
//       return [];
//     }
//   };
//   fetchTweetIds(screenName, count)
//   .then(tweetIds => {
//     console.log('Tweet IDs:', tweetIds);
//     // Use the tweet IDs as needed
//   })
//   .catch(error => {
//     console.error('Error:', error);
//   });


  const decodeHtmlEntities = (html) => {
    const el = document.createElement('p');
    el.innerHTML = html;
    return el.innerText || el.textContent;
  };

  const decodeHtmlEntities1 = (html) => {

    const tempElement = document.createElement('p');
    tempElement.innerHTML = html;


    const paragraphElement = tempElement.querySelector('p');
    if (paragraphElement) {
      const textToRemove = '[…]'
       paragraphElement.innerHTML = paragraphElement.innerHTML.replace(textToRemove, '...');
    }


    const modifiedContent = tempElement.innerHTML;

    return modifiedContent;
    };

    const extractImageFromContent = (content) => {
        // Use a regex or DOMParser to extract the image from the HTML content
        const regex = /<img.*?src=['"](.*?)['"]/;
        const match = content.match(regex);
        return match ? match[1] : null;
        // console.log(match)
      };


      const [ref, isInView] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      });


  return (
    <div className='light-theme-blogs-container-main'>
        <h2 ref={ref} className={`light-theme-blogs-header ${isInView ?'animated animatedFadeInUp fadeInUp':'hidden'}`} >Join our Community and Learn <span className='light-theme-banner-bottom-header-gradient'>Web3 </span> </h2>
        <>

         <Tabs
            activeKey={activeTab}
            onChange={handleTabChange}
            tabBarStyle={tabStyle} // Apply styles to the tabs

            >
            <TabPane tab={<span style={{fontSize:'20px', fontWeight:`${activeTab==='1'?'700':'400'}`,color:'white'}}>Blogs</span>} key="1" >
            <div className='d-flex justify-content-center'>
            <div className='light-theme-blogs-container'>
              {posts3.length>0?
              <>
               {posts3.map((data)=>{
                return(
                    <div >
                        <Link to={{pathname:`/blog/${data.slug}`,state:{data:data}}} target="_blank" >


                            <div >
                            <img src={extractImageFromContent(data.content.rendered)} className='light-theme-blogs-container-image' alt="Post Image"/>
                            </div>
                            {/* <div className="dapp-card-contentI">
                                 <h4 className="subheading-highlightI">{decodeHtmlEntities(data.title?.rendered)}</h4>
                                <p dangerouslySetInnerHTML={{ __html: decodeHtmlEntities1(data.excerpt.rendered) }}/>
                                <p className='mt-1'>Posted on <strong>{dateformatter(data.date, "mmmm dS")}</strong></p>
                            </div> */}
                        </Link>
                    </div>
                )
             })}
              </>:null}
              <a href='/blog'>
              <div className='community-resource-container2'>
                    Blogs
                    <img src={arrow1}/>
                  </div>
                </a>
            </div>

            </div>
                {/* <div className='light-theme-blogs-container'>
                <div>
                    <img src={Blog1} className='light-theme-blogs-container-image' />
                </div>
                <div>
                    <img src={Blog2} className='light-theme-blogs-container-image'/>
                </div>
                <div>
                    <img src={Blog3} className='light-theme-blogs-container-image'/>
                </div>
             </div>
            <div className='light-theme-blogs-readmore'> <a href="/blog" style={{color:'black'}} > Read more to learn everything Web3 <img src={ArrowRIght} height='auto' width='auto'/></a> </div> */}
            </TabPane>
            <TabPane tab={<span style={{fontSize:'20px',fontWeight:`${activeTab==='2'?'700':'400'}`,color:'white'}}>Resources</span>} key="2" >

                <div className='community-resource-container-main'>
                    <a href="https://docs.krypcore.com/dev-docs/product-overview/key-concepts" className='community-resource-container'>
                        {/*<div className='community-resource-container'>*/}
                            <h2 className='community-resource-header'>Product Overview</h2>
                            <div className='community-resource-container-mini'>
                                <div style={{color: 'black'}}>
                                    <li className='community-resource-text'><p
                                        className='community-resource-text-dot'></p><span>Key Concepts</span></li>
                                    <li className='community-resource-text'><p
                                        className='community-resource-text-dot'></p><span>Unified SDK / APIs</span></li>
                                    <li className='community-resource-text'><p
                                        className='community-resource-text-dot'></p><span>Infrastructure </span></li>
                                    <li className='community-resource-text'><p
                                        className='community-resource-text-dot'></p><span>Core Services </span></li>
                                </div>
                                <img src={logo1} className='community-resource-logo'/>

                            </div>
                        {/*</div>*/}
                    </a>
                    <a href="https://docs.krypcore.com/dev-docs/api-reference/kcw3-apis-explained"  className='community-resource-container1'>
                            <h2 className='community-resource-header1'>API Reference </h2>
                            <div className='community-resource-container-mini1'>
                                <div style={{color: 'black'}}>
                                    <li style={{visibility: 'hidden'}} className='community-resource-text1'><p
                                        className='community-resource-text-dot1'></p><span>Core Services </span></li>
                                    <li className='community-resource-text1'><p
                                        className='community-resource-text-dot1'></p><span> KCW3 APIs Explained </span>
                                    </li>
                                    <li className='community-resource-text1'><p
                                        className='community-resource-text-dot1'></p><span>Infrastructure APIs </span>
                                    </li>
                                    <li className='community-resource-text1'><p
                                        className='community-resource-text-dot1'></p><span>Core Service APIs  </span>
                                    </li>

                                </div>
                                <img src={logo1} className='community-resource-logo'/>

                            </div>
                    </a>
                        <a target='_blank' href='https://docs.beta.krypcore.com/dev-docs/'>
                            <div className='community-resource-container2'>
                                Documentation
                                <img src={arrow1}/>
                            </div>
                        </a>
                </div>
            </TabPane>
             <TabPane tab={<span style={{fontSize: '20px', fontWeight: `${activeTab==='3'?'700':'400'}`,color:'white'}}>Community</span>} key="3" >

                <div className='community-container'>
                {/*<div style={{minWidth:'400px'}}>*/}
                    {/*<Tweet tweetId="1686974506517372930" />*/}
                {/*<Timeline
                  dataSource={{
                    sourceType: 'profile',
                    screenName: 'KrypcoreWeb3'
                  }}
                  options={{
                    height: '400',
                    theme:"dark"
                  }}
                />*/}
                    {/*</div>*/}
                    <div className='light-theme-join-community-container-inner'>
                        <div className='light-theme-join-community-container-inner1'>
                        <div className='light-theme-join-community-container-row'>
                        <a href={discord1} target="_blank"><img src={Icon1} height='auto' width='auto' className='light-theme-join-community-img'/></a>
                        <a href={twitter1} target="_blank"> <img src={twitterIcon} height='auto' width='auto' className='light-theme-join-community-img'/></a>
                        <a href={youtube1} target="_blank"> <img src={youtubeIcon} height='auto' width='auto' className='light-theme-join-community-img'/></a>
                        <a href={telegram1} target="_blank"> <img src={telegramIcon} height='auto' width='auto' className='light-theme-join-community-img'/></a>
                        <a href={linkedIn1} target="_blank"> <img src={linkedInIcon} height='auto' width='auto' className='light-theme-join-community-img'/></a>
                        </div>


                        </div>
                    </div>
              </div>
            </TabPane>
            </Tabs>
    </>


    </div>
  )
}

export default KrypcoreBlogs

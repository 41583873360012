import React, {useRef, useState} from "react";
import '../enterprisePagestyles.less'
import krypcore from '../../../../assets/images/platformImg.avif'

export const Platform = () => {

  return (
    <>
        <div className="platform-section">
      <h1 className="platform-heading"><span className="platform-highlight">One Stop Platform </span>  for dApp Development</h1>
      <div className="platform-card-container">
        <div className="platform-card-bg">
          <img src={krypcore} alt={"Platform Card"}/>
        </div>
      </div>
        </div>
    </>
  );
};

export default Platform;

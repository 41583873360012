import React from "react";
import {notification, Upload} from "antd";
import { Menus } from "../../assets";

const { Dragger } = Upload;

export const Uploader = ({ uploadBase,  isBase = true }) => {
    const [fileTypes] = React.useState(["video/mp4","image/png","image/jpeg","application/pdf","application/json","text/html"]);
    const [maxNoOfFiles] = React.useState(5);
    let fileCount = 1;
    const [fileList, setFileList] = React.useState([]);
    const [maxFileSize] = React.useState(10000000); // 10mb

    const onGetbase = (file) => {
            var numOfTrue = fileTypes.filter(x => x === file.type).length;
            if(numOfTrue > 0) {
                if(file.size <= maxFileSize) {
                    let reader = new FileReader();
                    reader.readAsDataURL(file);
                    reader.onload = () => {
                        //console.log(file.name)
                        if (!isBase) {
                            uploadBase(file,file.name,file.size);
                        } else {
                            uploadBase(reader.result,file.name,file.size);
                        }
                    };
                    fileCount++;
                    fileList.push(file)
                } else {
                    notification.error({
                        message: "Invalid File Size - "+file.name,
                        duration: 3,
                    });
                    return false;
                }
            } else {
                notification.error({
                    message: "Invalid File Type - "+file.name,
                    duration: 3,
                });
                return false;
            }
  };
    const handleOkModalRemove = (file) => {
        const newFileList = fileList.filter((f) => f !== file);
        setFileList(newFileList);
        uploadBase("remove",file.name,"","");
    };

  return (
    <Dragger
      multiple={true}
       maxCount={maxNoOfFiles}
// directory={true}
      beforeUpload={(file) => {
        onGetbase(file);
        return false;
      }}
      fileList={fileList}
      onRemove={(file) => {
          handleOkModalRemove(file);
      }}
    >
      <Menus type="upload file" />
      <p className="nft-upload-header">Drag and Drop Files to Upload</p>
      <p className="nft-upload-limmiter">or</p>
      <p className="nft-upload-browse">Browse</p>
    </Dragger>
  );
};

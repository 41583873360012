import React from "react";
import { SignUpCard } from "./card";
import developer from "../../../assets/images/developer.avif"
import enterprise from "../../../assets/images/enterprise.avif"
import "./style.less";
export const EnterpriseRoleSelect = () => {
  return (
    <div className="enterprise-role-select">
      <div className="enterprise-role-select-inner">
        <div className="form-title">
          <h1>Choose the Role !</h1>
        </div>
        <div className="form-card">
          <SignUpCard imageSrc={developer} title="Dev" isEnterprise={false} isEntDeveloper={true}/>
          <SignUpCard imageSrc={enterprise} title="Admin" isEnterprise={true}/>
        </div>
      </div>
    </div>
  );
};

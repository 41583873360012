import React, { useState } from "react";
import { Select } from "antd";
import { Modal } from "antd";
import { Menus } from "../../assets";
import { Steps } from "antd";
import { notification } from "antd";
import fetchApi from "../../../component/_config/api";
import "./styles.less";

const ProjectConfig = ({ item, setSelectedItems, selectedItems }) => {
  const filteredOptions = item?.filter((o) => !selectedItems.includes(o.name));
  return (
    <Select
      mode="multiple"
      placeholder="Select Options"
      value={selectedItems}
      onChange={setSelectedItems}
      className="projectConfigWrapper"
      style={{
        width: "100%",
        marginTop: "20px",
      }}
      options={filteredOptions?.map((item) => ({
        value: item.id,
        label: item.name,
      }))}
    />
  );
};
export { ProjectConfig };

const { Step } = Steps;
notification.config({ duration: 2 });

export const ProjectConfigPopup = ({
  data,
  init = () => {},
  isModalOpen,
  handleCancel,
}) => {
  let [stepSize, setStepSize] = React.useState(0);
  // data
  const [Protocols, setProtocols] = useState([]);
  const [Wallets, setWallets] = useState([]);
  const [Storage, setStorage] = useState([]);
  // selected value
  const [selectedProtocols, setSelectedProtocols] = useState([]);
  const [selectedWallets, setSelectedWallets] = useState([]);
  const [selectedStorage, setSelectedStorage] = useState([]);

  React.useEffect(() => {
    getProjectConfig();
  }, []);

  const nextStep = () => {
    const number = stepSize + 1;
    setStepSize(number);
  };

  const backStep = () => {
    setStepSize(stepSize - 1);
  };

  const getProjectConfig = () => {
    fetchApi({
      method: "GET",
      url: `projects/project-config/WALLETS`,
      isDevApp: false,
    })
      .then((response) => {
        const { docs = [] } = response;
        setWallets([...docs]);
      })
      .catch(() => {});

    //protocol
    fetchApi({
      method: "GET",
      url: `projects/project-config/PROTOCOLS`,
      isDevApp: false,
    })
      .then((response) => {
        const { docs = [] } = response;
        setProtocols([...docs]);
      })
      .catch(() => {});

    //storage
    fetchApi({
      method: "GET",
      url: `projects/project-config/STORAGES`,
      isDevApp: false,
    })
      .then((response) => {
        const { docs = [] } = response;
        setStorage([...docs]);
      })
      .catch(() => {});
  };

  const updateProject = () => {
    const accessBody = {
      ...data,
      projectConfig: {
        walletEnabled: true,
        wallets: selectedWallets,
        protocolEnabled: true,
        protocols: selectedProtocols,
        storageEnabled: true,
        storages: selectedStorage,
      },
    };
    fetchApi({
      method: "PATCH",
      url: `projects/${data._id}`,
      data: accessBody,
      isDevApp: false,
    })
      .then((response) => {
        handleCancel();
        setStepSize(0);
        init();
        notification.success({
          message: "successfully created",
          duration: 3,
        });
      })
      .catch((err) => {
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  return (
    <>
      <Modal title="" footer={null} closable={false} open={isModalOpen}>
        <div className="popup-request-container projectConfigWrapper">
          <div className="close-icon" onClick={handleCancel}>
            <Menus type="close" />
          </div>
          <div className="popup-text">Configure Project</div>

          <div>
            <Steps current={stepSize} size="large">
              <Step title="Blockchain" />
              <Step title="Wallet" />
              <Step title="Storage" />
            </Steps>
          </div>
          {stepSize == 0 && (
            <ProjectConfig
              item={Protocols}
              selectedItems={selectedProtocols}
              setSelectedItems={setSelectedProtocols}
            />
          )}
          {stepSize == 1 && (
            <ProjectConfig
              item={Wallets}
              selectedItems={selectedWallets}
              setSelectedItems={setSelectedWallets}
            />
          )}
          {stepSize == 2 && (
            <ProjectConfig
              item={Storage}
              selectedItems={selectedStorage}
              setSelectedItems={setSelectedStorage}
            />
          )}
          <div className="btn-section mt-4">
            {(stepSize == 0 || stepSize == 1) && (
              <button className="ok-btn" onClick={nextStep}>
                Continue
              </button>
            )}
            {stepSize == 2 && (
              <button className="ok-btn" onClick={updateProject}>
                Update
              </button>
            )}
            {(stepSize == 1 || stepSize == 2) && (
              <button className="cancel-btn" onClick={backStep}>
                Back
              </button>
            )}
            {stepSize == 0 && (
              <button className="cancel-btn" onClick={handleCancel}>
                Cancel
              </button>
            )}
          </div>
        </div>
      </Modal>
    </>
  );
};

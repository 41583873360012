import React, {useEffect, useRef, useState} from "react";
import "./styles.less";
import {useLocation} from "react-router-dom";
import {fetchApi, fetchApiGw} from "../../../_config/api";
import {Carousel} from "./carousel";
import {CarouselBlockchain} from "./carouselBlockchain";
import {
    dateformatter,
    ENTERPRISEADMINROLE,
    getAuthToken,
    getDAppId,
    getPATTokenDetails,
    getUserDetails
} from "../../../deps";
import {CopyOutlined, LoadingOutlined} from "@ant-design/icons";
import {Form, notification, Space, Spin,Button,Upload} from "antd";
import { schemaJson } from './schema'
import { SchemaForm, createAsyncFormActions } from '@uform/antd';
import { registerFormField, connect } from '@uform/react-schema-renderer';
import {setFormData} from "../../nftstudio/utils";
import Highlight from "react-highlight";
import TextArea from "antd/es/input/TextArea";
import ReactJson from "react-json-view";
import Web3 from "web3";
import {ethers} from 'ethers'
import { abi as ERC721ABI, bytecode as ERC721Bytecode } from '../MyProjects/contracts/ERC721Contract';
import { abi as ERC721SBABI, bytecode as ERC721SBBytecode } from '../MyProjects/contracts/ERC721SBContract';
import { abi as ERC1155ABI, bytecode as ERC1155Bytecode } from '../MyProjects/contracts/ERC1155Contract';
import { abi as ERC1155SBABI, bytecode as ERC1155SBBytecode } from '../MyProjects/contracts/ERC1155SBContract';

const actions = createAsyncFormActions();
const gateWayURL = process.env.REACT_APP_GATEWAY_URL;


// Register custom form fields for image upload
registerFormField(
    'image-upload',
    connect()(props => {
        const { value, onChange } = props;
        const [filename, setFilename] = useState('');
        const handleChange = (e) => {
            const file = e.target.files[0];

            // Get the filename and update state
            setFilename(file.name);

            if (!file) return;

            const reader = new FileReader();
            reader.onload = () => {
                const base64Data = reader.result;
                onChange(base64Data);
            };
            reader.readAsDataURL(file);
        };

        return (
            <>
                <label className="custom-file-upload">
                    <input
                        type="file"
                        accept="image/*"
                        onChange={handleChange}
                        style={{ display: 'none' }} // Hide the input element
                    />
                    <span>Choose File</span>
                    {filename && (
                        <span className="filename">{"- "+filename}</span>
                    )}
                </label>
            </>


        );
    })
);

// Register custom form fields for file upload
registerFormField(
    'storefile',
    connect()(props => {
        const { onChange } = props;
        const [filename, setFilename] = useState('');

        const handleChange = (e) => {
            actions.reset();
            const file = e.target.files[0];

            // Get the filename and update state
            setFilename(file.name);

            if (!file) return;

            // Pass the file object directly to the onChange function
            onChange(file);
        };

        return (
            <>
                <label className="custom-file-upload">
                    <input
                        type="file"
                        accept=".pdf" // Accept only PDF files
                        onChange={handleChange}
                        style={{ display: 'none' }} // Hide the input element
                    />
                    <span>Choose File</span>
                    {filename && (
                        <span className="filename">{filename}</span>
                    )}
                </label>
            </>
        );
    })
);

// Register custom form fields for file upload
registerFormField(
    'file-upload',
    connect()(props => {
        const { onChange } = props;
        const [filename, setFilename] = useState('');

        const handleChange = (e) => {
            onChange(''); // This will update the form state with an empty value for 'file-upload'
            const file = e.target.files[0];

            // Get the filename and update state
            setFilename(file.name);

            if (!file) return;

            // Pass the file object directly to the onChange function
            onChange(file);
        };

        return (
            <>
                <label className="custom-file-upload">
                    <input
                        type="file"
                        accept=".pdf" // Accept only PDF files
                        onChange={handleChange}
                        style={{ display: 'none' }} // Hide the input element
                    />
                    <span>Choose File</span>
                    {filename && (
                        <span className="filename">{filename}</span>
                    )}
                </label>
            </>
        );
    })
);

export const APISandboxPage = ({callingPage,projectID,showLoader,isLoading }) => {
    const { pathname } = useLocation();
    const [projectId, setProjectId] = useState("");
    const [projectDetails, setProjectDetails] = useState({});
    const formRef = React.useRef(null);
    const [loading, setLoading] = useState(false);
    const [responseJson,setResponseJson] = React.useState(null);

    const [networks, setNetworks] = React.useState([]);
    const [tokenType, setTokenType] = React.useState([]);
    const [tokenTypeList, setTokenTypeList] = useState([]);
    const [walletList, setWalletList] = React.useState([]);
    const [wallets, setWallets] = useState([]);
    const [subTokenType, setSubTokenType] = React.useState("");
    const [tokenTypeSubList, setTokenTypeSubList] = useState([]);
    const [schema, setSchema] = useState(null);
    const [curlCommand, setCurlCommand] = useState(null);
    const [objects, setObjects] = useState({});
    const [showFormLayout, setShowFormLayout] = useState(false);
    const [networkSelected, setNetworkSelected] = useState(false);


    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    const [schemaDatas, setSchemaDatas] = useState(null);
    const [imageUrl, setImageUrl] = useState(null);
    const customUpload = ({ onChange }) => (
        <Upload onChange={onChange} showUploadList={false}>
            <Button>Upload Image</Button>
        </Upload>
    );
    const tabs = [
        { id: "BlockchainEndpoint", text: "Blockchain" },
        { id: "StorageEndpoint", text: "Storage" },
        { id: "DIEndpoint", text: "DID" },
        { id: "SCEndpoint", text: "Bundler" },
    ];
    const [activeTab, setActiveTab] = useState("BlockchainEndpoint");

    useEffect(() => {
        const paths = pathname?.slice?.(1).split?.("/");
        console.log("paths.length",paths.length)
        if (paths && paths.length === 3 && projectID == "") {
            setProjectId(paths[2]);
        }
    }, [pathname]);
    useEffect(() => {
        if (projectID !== "") {
            setProjectId(projectID);
        }
    }, [projectID]);

    console.log("projectId ------>",projectId)
    useEffect(() => {
        if (projectId) {
            getProjectDetails();
        }
    }, [projectId]);
    const getProjectDetails = () => {
        // showLoader(true);
        fetchApi({ method: "GET", url: `developer/projects/${projectId}` })
            .then((response) => {
                console.log("response.doc ------>",response.doc)
                if (response.doc !== null) {
                    console.log("response.doc ------>",response.doc)
                    setProjectDetails(response.doc);
                }
                // showLoader(false);
            })
            .catch(() => {
                // showLoader(false);
            });
    };
    const onTabClick = (value) => {
        setActiveTab(value);
    };
    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };





    React.useEffect(() => {
        getTokenTypeList();
        getWalletList();
        fetchSchema();

    }, []);



    const fetchSchema = async () => {
        try {
            const response = schemaJson

            setSchemaDatas(response[0]);
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    const getTokenTypeList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setTokenTypeList([
            {tokenId:"FT",tokenType:"FT"},{tokenId:"NFT",tokenType:"NFT"},{tokenId:"STORAGE",tokenType:"STORAGE"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };

    const getWalletList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setWalletList([
            {walletId:"dev-wallet",walletType:"Dev Wallet"},{walletId:"non-custodial",walletType:"Self Managed Wallet"},{walletId:"metamask-wallet",walletType:"MetaMask Wallet"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };



    const onChangeSelectNetwork = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setNetworks(event.target.value)
        setNetworkSelected(true);
    };

    const onChangeSelectTokenType = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        setTokenType(event.target.value)
        setTokenTypeSubList([])
        setSubTokenType("")
        if(event.target.value === "FT"){
            setTokenTypeSubList([
                {tokenId:"createERC20",tokenType:"Create ERC20"},
                {tokenId:"erc20-tokens",tokenType:"ERC20 Tokens"},
                {tokenId:"erc20-token",tokenType:"ERC20 Token"},
                {tokenId:"mintFT",tokenType:"Mint FT"},
                {tokenId:"approveFT",tokenType:"Approve FT"},
                {tokenId:"transferFT",tokenType:"Transfer FT"},
                {tokenId:"burnFT",tokenType:"BURN FT"},
                {tokenId:"getTotalSupply",tokenType:"Get Total Supply"},
                {tokenId:"balanceOf",tokenType:"Balance Of"},
            ])
        }else if(event.target.value === "NFT"){
            setTokenTypeSubList([
                {tokenId:"createNFTCollection",tokenType:"Create NFT Collection"},
                {tokenId:"nft-collection-list",tokenType:"NFT Collection List"},
                {tokenId:"nft-collection",tokenType:"NFT Collection"},
                {tokenId:"createNFT",tokenType:"Create NFT"},
                {tokenId:"nft-list",tokenType:"NFT List"}

            ])
        }else if(event.target.value === "STORAGE"){
            setTokenTypeSubList([
                {tokenId:"storefile",tokenType:"Store File"},
                {tokenId:"getFileDetails",tokenType:"Get File Details"},
                {tokenId:"downloadFileFromIPFS",tokenType:"Download File From IPFS"},
                {tokenId:"updatePinStatus",tokenType:"Update Pin Status"},

            ])
        }


    };

    const onChangeSelectWallet = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setWallets(event.target.value)
    };
    const onChangeSelectSubTokenType = async (event, name) => {
        setSubTokenType("");
        document.getElementById('response-box').style.display = 'none';
        const selectedValue = event.target.value;
        setShowFormLayout(selectedValue !== "");
        actions.reset({validate:false});
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        setSubTokenType(event.target.value)
        let subTokenType =  event.target.value;
        createCurlData(subTokenType)
    };


    const createCurlData = (subTokenType) => {
        console.log("subTokenType",subTokenType)
        setSchema("");
        setCurlCommand("");
        if (schemaDatas) {

            setSchema(schemaDatas.properties[subTokenType]);
            const selectedProperties = schemaDatas.properties[subTokenType].properties;

            // Map over the entries and construct the desired string
            const data={};
            for (const key in selectedProperties) {
                data[key] = selectedProperties[key].type;
            }

            let url = gateWayURL+"/api/v0/";
            let instanceId = "";
            let createData = '';

            switch (tokenType) {
                case "FT":
                    data.chainId = networks;
                    url = url + "ft-manager/" + subTokenType;
                    instanceId = projectDetails?.services.FT_Manager.InstanceID;
                    data.walletType = wallets;
                    if (wallets === "dev-wallet" && data.custodialWalletAccessToken !== "") {
                        data.privateKey = '0x' + data.custodialWalletAccessToken;
                    }else{
                        data.privateKey = "";
                    }
                    if (subTokenType === "createERC20") {
                        data.decimal = parseInt(data.decimal);
                        data.quantity = parseInt(data.quantity);
                    }else if(subTokenType === "erc20-tokens" || subTokenType === "erc20-token"){
                        data.page = parseInt(data.page)-1;
                        data.pageSize = parseInt(data.pageSize);
                    }
                    break;
                case "NFT":

                    data.chainId = networks;
                    url = url + "easy-nft/" + subTokenType;
                    instanceId = projectDetails?.services.EasyNFT.InstanceID;
                    data.walletType = wallets;
                    if (wallets === "dev-wallet" && data.custodialWalletAccessToken !== "") {
                        data.privateKey = '0x' + data.custodialWalletAccessToken;
                    }else if(subTokenType === "nft-collection-list" || subTokenType === "nft-list" || subTokenType === "nft-collection"){
                        data.page = parseInt(data.page)-1;
                        data.pageSize = parseInt(data.pageSize);
                    }
                    if(subTokenType === "createNFT"){
                        for (const key in data){
                            if (data[key] !== undefined) {
                                createData += ` --form "${key}=${data[key]}"`;
                            }
                        }
                    }
                    break;
                case "STORAGE":
                    instanceId = projectDetails?.services.StorageManager.InstanceID;
                    url = url + "storagemanageripfs/" + subTokenType;
                    if(subTokenType === "storefile"){
                        for (const key in data){
                            if (data[key] !== undefined) {
                                createData += ` --form "${key}=@${data[key]}"`;
                            }
                        }
                    }else if(subTokenType === "getFileDetails"){
                        data.page = parseInt(data.page)-1;
                        data.pageSize = parseInt(data.pageSize);
                    }
                    break;
                default:
            }
            console.log("instanceId------->",instanceId);
            console.log("data------->",data);
            console.log("url------->",url);
            console.log("dappID------->",projectId);
            generateCurlCommand(url ,instanceId, networks, projectId,createData,data,subTokenType)

        } else {
            console.error('Failed to fetch schema:', schemaDatas);
        }
    }

    const generateCurlCommand = (url ,instanceId, networks, projectId,createData,data,subTokenType) => {

        const data1 = ((tokenType === "NFT" && subTokenType === "createNFT") || (tokenType === "STORAGE" && subTokenType === "storefile")) ? createData :data;
         console.log("data1",data1)

        const patToken = getPATTokenDetails();
        const userSub = getUserDetails();
        const token = getAuthToken()
        const headers = {};

        const dAppId = getDAppId();
        let dAppId1 = ""
        if(projectId !== undefined && projectId !== null && projectId !== ""){
            dAppId1 = projectId;
        }else if (dAppId != null) {
            dAppId1 = dAppId;
        }

        let curlCommand = `curl -X POST "${url}" \\
         -H "Content-Type: application/json" \\
         -H "Dappid: ${dAppId1}" \\
         -H "Chainid: ${networks}" \\
         -H "Instanceid: ${instanceId}" \\
         -H "Authorization: ${patToken?.patToken}" \\
         -H "x-jwt-token: ${token}"`;

          if((tokenType === "NFT" && subTokenType === "createNFT") || (tokenType === "STORAGE" && subTokenType === "storefile")) {
              curlCommand = `curl -X POST "${url}" \\
              -H "Content-Type: multipart/form-data" \\
              -H "Dappid: ${dAppId1}" \\
              -H "Chainid: ${networks}" \\
              -H "Instanceid: ${instanceId}" \\
              -H "Authorization: ${patToken?.patToken}" \\
              -H "x-jwt-token: ${token}" \\
               ${createData}`;
        }else{
            curlCommand += ` -d '${JSON.stringify(data1)}'`;
        }

        setCurlCommand(curlCommand)

    };

    const handleSubmit = (values) => {
        setLoading(true); // Show spinner
        if(subTokenType !== ""){
            const data = Object.fromEntries(
                Object.entries(values).filter(([key, value]) => value !== undefined)
            );
            let url = gateWayURL+"/api/v0/";
            let instanceId = "";
            let formData = {};
            switch (tokenType) {
                case "FT":
                    data.chainId = networks;
                    url = url + "ft-manager/" + subTokenType;
                    instanceId = projectDetails?.services.FT_Manager.InstanceID;
                    data.walletType = wallets;
                    if (wallets === "dev-wallet" && data.custodialWalletAccessToken !== "") {
                        data.privateKey = '0x' + data.custodialWalletAccessToken;
                    }else{
                        data.privateKey = "";
                    }
                    if (subTokenType === "createERC20") {
                        data.decimal = parseInt(data.decimal);
                        data.quantity = parseInt(data.quantity);
                    }else if(subTokenType === "erc20-tokens" || subTokenType === "erc20-token"){
                        data.page = parseInt(data.page)-1;
                        data.pageSize = parseInt(data.pageSize);
                    }
                    break;
                case "NFT":
                    data.chainId = networks;
                    url = url + "easy-nft/" + subTokenType;
                    instanceId = projectDetails?.services.EasyNFT.InstanceID;
                    data.walletType = wallets;
                    if(wallets === "metamask-wallet"){
                        connectToMetaMask(data);
                    }
                    if (wallets === "dev-wallet" && data.custodialWalletAccessToken !== "") {
                        data.privateKey = '0x' + data.custodialWalletAccessToken;
                    }else if(subTokenType === "nft-collection-list" || subTokenType === "nft-list" || subTokenType === "nft-collection"){
                        data.page = parseInt(data.page)-1;
                        data.pageSize = parseInt(data.pageSize);
                    }else if(subTokenType === "createNFT"){
                        formData = setFormData(data);
                    }
                    break;
                case "STORAGE":
                    instanceId = projectDetails?.services.StorageManager.InstanceID;
                    url = url + "storagemanageripfs/" + subTokenType;
                    if(subTokenType === "storefile"){
                        formData = setFormData(data);
                    }else if(subTokenType === "getFileDetails"){
                        data.page = parseInt(data.page)-1;
                        data.pageSize = parseInt(data.pageSize);
                    }
                    break;
                default:
            }
            console.log("instanceId------->",instanceId);
            console.log("data------->",data);
            console.log("url------->",url);
            console.log("dappID------->",projectId);
            fetchApiGw({
                method: "POST",
                url: url,
                isDevApp: false,
                useBase:false,
                data: ((tokenType === "NFT" && subTokenType === "createNFT") || (tokenType === "STORAGE" && subTokenType === "storefile")) ? formData :data,
                isFormData: ((tokenType === "NFT" && subTokenType === "createNFT") || (tokenType === "STORAGE" && subTokenType === "storefile"))  ? true : false,
                instanceId: instanceId,
                chainId:networks,
                dAppID: projectId
            })
                .then((response) => {
                    notification.destroy();
                    if(response && Object.keys(response).length > 0){
                        if(response.Status === "SUCCESS") {
                            actions.reset();
                            setLoading(false);
                            createCurlData(subTokenType)
                            // notification.success({
                            //     message: response.Message,
                            //     duration: 5,
                            // });
                            // Show JSON response data in a box
                            // if (response.Data.result && Object.keys(response.Data.result).length > 0) {
                            //     showResponseData(response.Data.result);
                            // }
                            // actions.reset();
                            document.getElementById('response-box').style.display = 'block';
                            setResponseJson(response);
                        } else{
                            setLoading(false);
                            actions.reset();
                            createCurlData(subTokenType)
                            // notification.error({
                            //     message:response.Message,
                            //     duration: 5,
                            // });
                            document.getElementById('response-box').style.display = 'block';
                            setResponseJson(response);
                        }
                    }
                })
                .catch((error) => {
                    setLoading(false);
                    // console.error(error);
                    // setDeployDisabled(false);
                    actions.reset();
                    // notification.destroy();
                    // notification.error({
                    //     message: error.message,
                    //     duration: 3,
                    // });
                    createCurlData(subTokenType)
                    document.getElementById('response-box').style.display = 'block';
                    setResponseJson(error);
                });
        }else{
            notification.destroy();
            actions.reset();
            notification.error({
                message: "Please select action to continue",
                duration: 3,
            });
        }
    };

    const onInputChangeCurl = (values,subTokenType) => {


        let data={};
        const selectedProperties = schema.properties;

        for (const key in selectedProperties) {
            data[key] = selectedProperties[key].type;
        }
        if(subTokenType !== "createNFT") {
            for (const key in values) {
                if (data.hasOwnProperty(key)) {
                    if(values[key] !== undefined && values[key] !== NaN){
                        data[key] = values[key];
                    }
                }
            }
        }


        let url = gateWayURL+"/api/v0/";
        let instanceId = "";
        let createData = '';
        switch (tokenType) {
            case "FT":
                data.chainId = networks;
                url = url + "ft-manager/" + subTokenType;
                instanceId = projectDetails?.services.FT_Manager.InstanceID;
                data.walletType = wallets;
                if (wallets === "dev-wallet" && data.custodialWalletAccessToken !== "") {
                    data.privateKey = '0x' + data.custodialWalletAccessToken;
                }else{
                    data.privateKey = "";
                }
                if (subTokenType === "createERC20") {
                    data.decimal = parseInt(data.decimal);
                    data.quantity = parseInt(data.quantity);
                }else if(subTokenType === "erc20-tokens" || subTokenType === "erc20-token"){
                    data.page = parseInt(data.page)-1;
                    data.pageSize = parseInt(data.pageSize);
                }
                break;
            case "NFT":
                data.chainId = networks;
                url = url + "easy-nft/" + subTokenType;
                instanceId = projectDetails?.services.EasyNFT.InstanceID;
                data.walletType = wallets;
                if (wallets === "dev-wallet" && data.custodialWalletAccessToken !== "") {
                    data.privateKey = '0x' + data.custodialWalletAccessToken;
                }else if(subTokenType === "nft-collection-list" || subTokenType === "nft-list" || subTokenType === "nft-collection"){
                    data.page = parseInt(data.page)-1;
                    data.pageSize = parseInt(data.pageSize);
                }
                if(subTokenType === "createNFT"){
                    for (const key in data){
                        if (data[key] !== undefined) {
                            createData += ` --form "${key}=${data[key]}"`;
                        }
                    }

                    for (const key in values) {
                        if (createData.includes(key)) {
                            const value = values[key];
                            if(key !== "files"){
                                const regex = new RegExp(`--form "${key}=(.*?)"`);
                                createData = createData.replace(regex, `--form "${key}=${value}"`);
                            }else if(key === "files"){
                                const regex = new RegExp(`--form "${key}=(.*?)"`);
                                createData = createData.replace(regex, `--form '${key}=@"${value.name}"'`);
                            }

                        }
                    }
                }
                break;
            case "STORAGE":
                instanceId = projectDetails?.services.StorageManager.InstanceID;
                url = url + "storagemanageripfs/" + subTokenType;
                if(subTokenType === "storefile"){
                    for (const key in data){
                        if (data[key] !== undefined) {
                            createData += ` --form "${key}=${data[key]}"`;
                        }
                    }

                    for (const key in values) {
                        if (createData.includes(key)) {
                            const value = values[key];
                            if(key === "files"){
                                const regex = new RegExp(`--form "${key}=(.*?)"`);
                                createData = createData.replace(regex, `--form '${key}=@"${value.name}"'`);
                            }
                        }
                    }
                }else if(subTokenType === "getFileDetails"){
                    data.page = parseInt(data.page)-1;
                    data.pageSize = parseInt(data.pageSize);
                }
                break;

            default:
        }
        console.log("instanceId------->",instanceId);
        console.log("data------->",data);
        console.log("url------->",url);
        console.log("dappID------->",projectId);
        generateCurlCommand(url ,instanceId, networks, projectId,createData,data,subTokenType)

    };

    const connectToMetaMask = async (data) => {
        console.log("hiiii",data)
        if (!window.ethereum) {
            notification.error({
                message: "MetaMask is not installed",
                duration: 3,
            });
        }else{
            setLoading(true);
            let abi = null;
            let bytecode = null;
            if(data.ERCStandard === "ERC721" && data.isSoulbound === "false"){
                abi = ERC721ABI;
                bytecode = ERC721Bytecode;
            }else if(data.ERCStandard === "ERC721" && data.isSoulbound === "true"){
                abi = ERC721SBABI;
                bytecode = ERC721SBBytecode;
                console.log("abi -------->",abi)
                console.log("bytecode -------->",bytecode)
            }else if(data.ERCStandard === "ERC1155" && data.isSoulbound === "false"){
                abi = ERC1155ABI;
                bytecode = ERC1155Bytecode;
            }else if(data.ERCStandard === "ERC1155" && data.isSoulbound === "true"){
                abi = ERC1155SBABI;
                bytecode = ERC1155SBBytecode;
            }
            console.log("data.ERCStandard -------->",data.ERCStandard)
            console.log("data.isSoulbound -------->",data.isSoulbound)
            // console.log("abi -------->",abi)
            // console.log("bytecode -------->",bytecode)
            const web3 = new Web3(window.ethereum);
            await window.ethereum.enable();
            const accounts = await web3.eth.getAccounts();
            const deployer = accounts[0];
            const contract = new web3.eth.Contract(abi);
            const deployTx = contract.deploy({ data: bytecode, arguments: [data.collectionName, data.collectionSymbol] });
            const gasPrice = await web3.eth.getGasPrice();
            var bigInt = require("big-integer");
            const buffer = bigInt(gasPrice).multiply(13).divide(100);
            const maxPriorityFeePerGas = bigInt(gasPrice).add(buffer);
            const gasPriceHex = web3.utils.toHex(gasPrice);
            const maxPriorityFeePerGasHex = web3.utils.toHex(maxPriorityFeePerGas.value);
            // const deployedContract = await deployTx
            //     .send({
            //       from: deployer,
            //       maxPriorityFeePerGas: gasPriceHex,
            //       maxFeePerGas: maxPriorityFeePerGasHex,
            //     });
            // const transactionHash = await new Promise((resolve, reject) => {
            //   deployTx.once('transactionHash', (txHash) => {
            //     console.log("Transaction Hash:", txHash);
            //     resolve(txHash);
            //   }).on('error', (error) => {
            //     console.error('Error:', error);
            //     reject(error);
            //   });
            // });
            // console.log("Transaction Hash:", transactionHash);
            // const contractAddress = deployedContract.options.address;
            // onCreateCollectionUsingMetaMask(transactionHash, contractAddress);
            const deployedContract = await deployTx
                .send({
                    from: deployer,
                    maxPriorityFeePerGas: gasPriceHex,
                    maxFeePerGas: maxPriorityFeePerGasHex,
                })
                .on('receipt', (receipt) => {
                    console.log("receipt -------------->",receipt.contractAddress)
                    console.log("txnHash -------------->",receipt.transactionHash);
                    onCreateCollectionUsingMetaMask(receipt.transactionHash,receipt.contractAddress);
                })
                .on('error', (error) => {
                    console.error('Error:', error);
                    notification.error({
                        message: 'Transaction Error',
                        description: error.message,
                        duration: 3,
                    });
                    setLoading(false);
                });
        }
    }

    const onCreateCollectionUsingMetaMask = (transactionHash,contractAddress) => {
        if(contractAddress !== "" || transactionHash !== "") {
            setLoading(true);
            let data = {
                ...formRef.current?.getFieldsValue(),
            };
            const instanceId = projectDetails?.services.FT_Manager.InstanceID;
            data.chainId = networks;
            data.isSoulbound = (data.isSoulbound).toLowerCase() === 'true';
            data.contractAddress = contractAddress;
            data.txnHash = transactionHash;
            // data.custodialWalletAccessToken = custodialData.serviceApiKey;
            // if (data.walletType === "non-custodial") {
            //   data.custodialWalletAccessToken = value.serviceApiKey;
            // } else if (data.walletType === "dev-wallet") {
            //   let newPrivateKey = value.privateKey;
            //   if(newPrivateKey !== undefined && newPrivateKey !== null && !newPrivateKey.startsWith('0x')){
            //     newPrivateKey = '0x'+newPrivateKey;
            //   }
            //   data.privateKey = newPrivateKey;
            // }
            fetchApiGw({
                method: "POST",
                url: "http://localhost:1323/easy-nft/createNFTCollectionUsingMetaMask",
                // url: walletURL + "/api/v0/easy-nft/createNFTCollection",
                isDevApp: false, useBase: false, instanceId: instanceId,chainId:networks,
                data: data,
            })
                .then((response) => {
                    notification.destroy();
                    if (response.Status === "SUCCESS") {
                        document.getElementById('response-box').style.display = 'block';
                        setResponseJson(response);
                    } else {
                        document.getElementById('response-box').style.display = 'block';
                        setResponseJson(response);
                    }
                    setLoading(false);
                })
                .catch((error) => {
                    setLoading(false);
                });
        }else{
            notification.error({
                message: 'Contract Address/Transaction Hash is missing',
                duration: 3,
            });
            setLoading(false);
        }
    }

    return (
        <>

            <div className="dapp-details-select-items"
                 className={`${callingPage == "Dapp" ? 'dapp-details-select-items' : 'dapp-details-select-items1'}`}>
                <Space
                    direction="horizontal"
                    size={25}
                >
                    <Form
                        name="basic"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        // onFinish={onCreateEndpoint}
                        autoComplete="off"
                    >


                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="network"
                            name="network"
                            onChange={(event) => onChangeSelectNetwork(event, "network")}
                        >
                            <option value="" disabled selected>Choose Network</option>
                            {projectDetails?.endpoints?.BlockchainEndpoint.map((network, ind) => (
                                <option key={network.Protocol} value={network.ChainId}>{network.Protocol}</option>))}
                        </select>

                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="tokenType"
                            name="tokenType"
                            onChange={(event) => onChangeSelectTokenType(event, "tokenType")}
                        >
                            <option value="" disabled selected>Choose Token Type</option>

                            {/*{tokenTypeList.map((tokenType, ind) => (<option key={tokenType.tokenId}*/}
                            {/*                                                value={tokenType.tokenId}>{tokenType.tokenType}</option>))}*/}

                            {networkSelected &&
                                tokenTypeList.map((tokenType, ind) => (
                                    <option key={tokenType.tokenId} value={tokenType.tokenId}>{tokenType.tokenType}</option>
                                ))
                            }
                        </select>

                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="wallet"
                            name="wallet"
                            onChange={(event) => onChangeSelectWallet(event, "wallet")}
                        >
                            <option value="" disabled selected>Choose Wallet</option>
                            {walletList.map((wallet, ind) => (
                                <option key={wallet.walletId} value={wallet.walletId}>{wallet.walletType}</option>))}
                        </select>

                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="subTokenType"
                            name="subTokenType"
                            value={subTokenType}
                            onChange={(event) => onChangeSelectSubTokenType(event, "subTokenType")}
                        >
                            <option value="" disabled >Choose Action</option>
                            {tokenTypeSubList.map((tokenType, ind) => (<option key={tokenType.tokenId}
                                                                               value={tokenType.tokenId}>{tokenType.tokenType}</option>))}
                        </select>


                    </Form>
                </Space>
            </div>

            {showFormLayout && (
            <div className="form-sandbox-layout">
                <div className="form-layout">
                    <SchemaForm actions={actions} schema={schema} onSubmit={handleSubmit} labelCol={11} wrapperCol={10}
                                onChange={(values) => onInputChangeCurl(values, subTokenType)}>
                        <Button
                            type="primary"
                            htmlType="submit"
                            loading={loading}
                            icon={loading ? antIcon : null}
                            style={{
                                display: 'flex',
                                flexDirection: 'row-reverse',
                                gap: '8px',
                                marginLeft: '470px',
                                width: '142px',
                                justifyContent: 'center',
                                alignItems: 'center'
                            }}
                        >
                            Send Request
                        </Button>
                    </SchemaForm>
                </div>
                <div className="code-layout" style={{position: 'sticky'}}>
                    <TextArea placeholder="//" style={{height: '600px', overflowY: 'auto', paddingTop: "40px"}}
                              className="curl-code-layout"
                              value={curlCommand}/>
                    <div>
                        <button className="copy-button"
                                onClick={() => onCopy(curlCommand)}>
                            Copy
                        </button>
                    </div>
                </div>
            </div>
            )}
            <div id="response-box" style={{display: "none"}}>
                <div
                    style={{
                        backgroundColor: "#383838",
                        padding: "10px",
                        overflowY: "auto",
                        maxHeight: "500px",
                        borderRadius: "20px",
                        marginTop: "50px"
                    }}
                >
                    <ReactJson style={{backgroundColor: "white", marginTop: 20, overflowX: "auto"}}
                               enableClipboard={true} src={responseJson}/>

                </div>
            </div>
        </>
    );
}
export default APISandboxPage;

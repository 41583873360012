import React from "react";
import { useNavigate } from "react-router-dom";
import { Auth0Provider } from "@auth0/auth0-react";
import {
  DEVELOPERROLE,
  setEnterpriseId,
  setLoginType,
  setRole,
} from "../component/deps";

const AuthProviderWithHistory = ({ children }) => {
  const navigate = useNavigate();
  const domain = process.env.REACT_APP_AUTH0_DOMAIN;
  const clientId = process.env.REACT_APP_AUTH0_CLIENT_ID;
  const audience = process.env.REACT_APP_AUTH0_AUDIENCE;

  // console.log("Domain:", domain);
  // console.log("clientId:", clientId);
  // console.log("audience:", audience);

  const onRedirectCallback = async (appState) => {
    setRole(appState.role);
    setLoginType(appState.type);
    if (appState.role !== DEVELOPERROLE) {
      setEnterpriseId(appState.enterpriseId);
    }
    navigate(appState?.returnTo || "/dashboard");
  };

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      redirectUri={window.location.origin}
      onRedirectCallback={onRedirectCallback}
      audience={audience}
      useRefreshTokens={true}
      scope="user_metadata app_metadata"
      cacheLocation="localstorage"
    >
      {children}
    </Auth0Provider>
  );
};

export default AuthProviderWithHistory;

/* eslint-disable no-unused-vars */
import React, { useState, useEffect } from "react";
import { notification, Spin } from "antd";
import { SideNav, Header, Footer, Content } from "./template";
import { tabIds, defaultList } from "./constant";
import fetchApi from "../../_config/api";
import { setTempRecord } from "../../deps";
import "./styles.less";
import { DEVELOPERROLE } from "../../deps";
import { useAuth0 } from "@auth0/auth0-react";

export const CreateProject = () => {
  const [activeTab, setActiveTab] = useState(tabIds.TAB_1);
  const [showLoader, setLoader] = useState(false);
  const { loginWithRedirect, isAuthenticated, logout } = useAuth0();
  const [data, setData] = useState({
    title: "",
    description: "",
    protocol: [],
    wallet: [],
    storage: [],
  });
  const [walletList, setWalletList] = useState(defaultList.walletList);
  const [protocolList, setProtocolList] = useState(defaultList.protocolList);
  const [storageList, setStorageList] = useState(defaultList.storageList);

  const getList = async () => {
    try {
      setLoader(true);
      const wallets = await fetchApi({
        url: "developer/project-config/WALLETS",
        method: "GET",
      });
      const protocols = await fetchApi({
        url: "developer/project-config/PROTOCOLS",
        method: "GET",
      });
      const storages = await fetchApi({
        url: "developer/project-config/STORAGES",
        method: "GET",
      });
      setWalletList(wallets?.docs || defaultList.walletList);
      setProtocolList(protocols?.docs || defaultList.protocolList);
      setStorageList(storages?.docs || defaultList.storageList);
      setLoader(false);
    } catch (e) {
      setLoader(false);
    }
  };

  useEffect(() => {
    getList();
  }, []);

  const onChange = (id, value) => {
    data[id] = value;
    setData({ ...data });
  };

  const onPrevNext = (tab) => {
    if (tab === "create") {
      OnCreate();
    }
    setActiveTab(tab);
  };

  const OnCreate = () => {
    const requestData = {
      name: data.title,
      description: data.description,
      projectConfig: {
        walletEnabled: data.wallet.length > 0,
        wallets: data.wallet.map((list) => list.id),
        protocolEnabled: data.protocol.length > 0,
        protocols: data.protocol.map((list) => list.id),
        storageEnabled: data.storage.length > 0,
        storages: data.storage.map((list) => list.id),
      },
    };
    setLoader(true);
    fetchApi({ url: "developer/create-temp-project", data: requestData })
      .then((response) => {
        setTempRecord(response);
        loginWithRedirect({
          appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
          role: DEVELOPERROLE,
          type: "INDIVIDUAL",
          projectId: response.projectId,
        });
      })
      .catch((err) => {
        setLoader(false);
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  return (
    <Spin spinning={showLoader}>
      <div className="full-page-layout">
        <SideNav data={data} activeTab={activeTab} onPrevNext={onPrevNext} />
        <div className="main-container" style={{ width: "80% !important" }}>
          <Header />
          <Content
            data={data}
            activeTab={activeTab}
            protocolList={protocolList}
            walletList={walletList}
            storageList={storageList}
            onChange={onChange}
            onPrevNext={onPrevNext}
          />
          <Footer />
        </div>
      </div>
    </Spin>
  );
};

export default CreateProject;

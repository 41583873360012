import React from "react";

import './style.less';
import Header from "./Header";
import NewFooter from "../../landingPage/ui/landing_page_new_footer";
import {ContactForm} from "./contactForm";

export const ThankyouPage = () => {
  return (
    <div className="thankyou-us-page">
        <Header/>
      <ContactForm/>
        <NewFooter/>

    </div>
  );
};

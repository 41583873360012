import {PaginationSection, TableContent} from "../../../../library";
import React, {useEffect, useState} from "react";
import { data_Lake } from "../column";
import {notification} from "antd";
import {ExportOutlined,} from "@ant-design/icons";
import '../styles.less';
import FilterDropdown from "../utility/filterDropDown";
import TransactionSearch from "../utility/searchContent";
import { fetchApi } from "../../../../_config/api";
import {getUserDetails} from "../../../../deps";
import {useNavigate} from "react-router-dom";
import Skeleton from "react-loading-skeleton";

export const DataLakeReport = ({ showLoader,isLoading }) => {
    const userDetails = getUserDetails()
    const navigate = useNavigate();
    const [allBlockDetails, setBlockDetailsList] = useState([]);
    const [serviceTypeList, setServiceType] = useState([]);
    const [projectList, setProjectList] = useState([]);
    const [endpointList, setEndpointsList] = useState([]);
    const [selectProjectId, setSelectProjectId] = useState("");
    const [pageLoad, setPageLoad] = useState(false);
    const [totalCount, setTotalCount] = useState(-1);

    const [blockDetails, setBlockDetails] = useState({});

    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 5,
        searchText: "",
        filterValue: "",
        fromDate: null,
        toDate: null,
        serviceType: "",
        endpoint: "",
        dAppId:""
    });


    const getDataAndDownloadCsv = async () => {
        try {
            let requestBody = {...pageSetting}
            requestBody.page = 0
            requestBody.itemsPerPage = 0
            fetchApi({ method: "POST", url: "dataLake/transactions/"+userDetails.profile.developerId, data: requestBody })
                .then((response) => {
                    if( response.statusCode == 200){
                        downloadCsv(response.data);
                    }else{
                        downloadCsv([]);
                    }

                })
                .catch((error) => {
                    throw error; // Re-throw the error
                });
        } catch (error) {
            console.error('Error fetching data:', error);
        }
    };


    const downloadCsv = (data) => {
        if (data.length === 0){

            notification.error({
                message: "No data available for CSV export!",
                duration: 3,
            });
            return

        }
        const csvContent = "data:text/csv;charset=utf-8," + convertArrayToCsv(data);
        const encodedUri = encodeURI(csvContent);

        const link = document.createElement('a');
        link.setAttribute('href', encodedUri);
        link.setAttribute('download', 'data.csv');
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link); // Clean up after the download
    };




    const convertArrayToCsv = (data) => {
        const headers = Object.keys(data[0]).filter(key => key !== '_id');
        const csvRows = [headers.map(capitalizeFirstLetter).join(',')];

        data.forEach((item) => {
            const rowData = {};

            headers.forEach((key) => {
                const capitalizedKey = capitalizeFirstLetter(key);

                if (key === "timestamp") {
                    const unixTimestamp = item[key];
                    const utcTimestamp = new Date(unixTimestamp * 1000).toUTCString();
                    rowData[capitalizedKey] = utcTimestamp;
                } else {
                    rowData[capitalizedKey] = item[key];
                }
            });

            csvRows.push(Object.values(rowData).join(','));
        });

        return csvRows.join('\n');
    };

    const capitalizeFirstLetter = (string) => {
        return string.charAt(0).toUpperCase() + string.slice(1);
    };

    const onNavigate = (data) => {
        navigate(`/block-details/`+data?.blockNumber,{state:{dAppId:`${pageSetting.dAppId}`,endpoint:`${pageSetting.endpoint}`, searchText:`${data?.hash}`}});
    };


    const onDateChange = (dates) => {
        const [fromDate, toDate] = dates;
        setSetting({ ...pageSetting, fromDate, toDate });
    };

    const searchTransactionHash = (value) => {
        console.log('value',value.target.value)
        setSetting({ ...pageSetting, searchText: value.target.value });
    };

    const onchangeInServiceType = (value) => {
        setSetting({ ...pageSetting, filterValue: value.target.value });
    };

    const onChangeInEndpoint = (value) => {
        setSetting({ ...pageSetting, endpoint: value.target.value, dAppId: selectProjectId });
        getAllServices(value.target.value)
    };

    const onChangeInProjects = async (value) => {
        setBlockDetailsList([])
        let endpointsList = await apiCaller("GET", `projects/list/endpoints/${userDetails.profile.subscriptionId}/${value.target.value}`, null)
        setEndpointsList(endpointsList)
        setSelectProjectId(value.target.value)
    };

    function displayClickedDetails(data) {
        setBlockDetails(data)
        onNavigate(data)

    }

    async function getAllProjectUsingSubscriptionId() {
        let httpUrl = "projects/list/projects/" + userDetails.profile.subscriptionId;
        let projectsDetails = await apiCaller("GET", httpUrl, null);
        setProjectList(projectsDetails);
    }

    async function getAllTransactions() {
        let httpUrl = "dataLake/transactions/"+userDetails.profile.developerId;

        fetchApi({ method: "POST", url: httpUrl, data: pageSetting })
            .then((response) => {
                if (response.statusCode == 200){
                    setBlockDetailsList(response.data);
                    setTotalCount(response.totalCount)
                    console.log(response)
                }
            })
            .catch((error) => {
                throw error;
            });
    }

    async function getAllServices(endpoint) {
        let servReq = pageSetting
        servReq.endpoint = endpoint
        servReq.dAppId = selectProjectId
        let httpUrl = "dataLake/serviceList/"+userDetails.profile.developerId;
        let reportDetails = await apiCaller("POST", httpUrl, servReq );
        setServiceType(reportDetails);

    }

    const apiCaller = (methodName, url, request) => {
        return fetchApi({ method: methodName, url: url, data: request })
            .then((response) => {
                return  response.statusCode == 200 ? response.docs: []
            })
            .catch((error) => {
                throw error; // Re-throw the error
            });
    };

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getAllTransactions().then()
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getAllTransactions().then()
    };


    useEffect( () => {
        getAllProjectUsingSubscriptionId().then(r => "")
    },[pageLoad])

    useEffect(() => {
        getAllTransactions().then()
    },[pageSetting])

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;

    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;

    return (
        <>
            <div className="transaction-page-container">
            {isLoading?(<Skeleton height={45} style={{marginBottom:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <div className="transaction-header-section">
                    <div className="header-section" style={{flexDirection:"row"}}>
                        <div style={{fontWeight:'400',fontSize:'24px',color:'white'}}>Transaction Report</div>
                    </div>

                    <div className="filter-container">
                        <FilterDropdown placeholder="Projects"  options={projectList} onChange={onChangeInProjects} />
                        <FilterDropdown placeholder="Endpoints" options={endpointList} onChange={onChangeInEndpoint} />
                    </div>
                </div>
            )}  

                {selectProjectId && (
                    <div className="transaction-search-section">
                        <TransactionSearch
                             onchangeInServiceType={onchangeInServiceType}
                             serviceType={serviceTypeList}
                             onDateChange={onDateChange}
                             searchTransactionHash={searchTransactionHash}
                             csvData={[]}
                    />
                        <button onClick={getDataAndDownloadCsv} className="gradient-button" type="primary" icon={<ExportOutlined />}>
                            Export to CSV
                        </button>

                    </div>
                )}

                <div className="table-container marginBtm50">
                {isLoading?(<Skeleton height={200} style={{marginBottom:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                    <TableContent
                        rowKey="id"
                        columns={data_Lake(displayClickedDetails)}
                        data={allBlockDetails}
                        isDashboard={false}
                        pagination={false}
                    />
                )}
                </div>

                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={[5, 10, 50, 100, totalCount]}
                    totalCount={totalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.itemsPerPage}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />
            </div>
        </>
    );
};

export default DataLakeReport;

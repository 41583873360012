import React from "react";

export const TickOutline = ({ width = "35px", height = "35px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 37"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M36.8439 18.2965C36.8439 28.22 28.6724 36.273 18.5819 36.273C8.49146 36.273 0.32 28.22 0.32 18.2965C0.32 8.373 8.49146 0.32 18.5819 0.32C28.6724 0.32 36.8439 8.373 36.8439 18.2965Z"
      fill="#090517"
      stroke="url(#paint0_linear_2121_89876)"
      strokeWidth="0.64"
    />
    <path
      d="M10.355 16.6417C10.5824 16.4144 10.8907 16.2867 11.2123 16.2867C11.5338 16.2867 11.8421 16.4144 12.0695 16.6417L16.3569 20.9292L24.9294 12.3555C25.042 12.2429 25.1756 12.1535 25.3227 12.0925C25.4699 12.0315 25.6276 12.0001 25.7868 12C25.9461 11.9999 26.1038 12.0313 26.2509 12.0922C26.3981 12.153 26.5318 12.2423 26.6445 12.3549C26.7571 12.4675 26.8465 12.6011 26.9075 12.7483C26.9685 12.8954 26.9999 13.0531 27 13.2123C27.0001 13.3716 26.9687 13.5293 26.9078 13.6765C26.847 13.8236 26.7577 13.9573 26.6451 14.07L16.3569 24.3582L10.355 18.3562C10.1277 18.1289 10 17.8205 10 17.499C10 17.1775 10.1277 16.8691 10.355 16.6417Z"
      fill="#DBDBDB"
    />
    <defs>
      <linearGradient
        id="paint0_linear_2121_89876"
        x1="-1.67972"
        y1="18.2965"
        x2="47.1372"
        y2="18.2965"
        gradientUnits="userSpaceOnUse"
      >
        <stop stop-color="#B447EB" />
        <stop offset="0.95" stop-color="#2797FF" />
      </linearGradient>
    </defs>
  </svg>
);

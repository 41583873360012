export const ACCESS = {
  // role
  CREATE_ROLE:"role:create",
  LIST_ROLE:"role:list",
  GET_ROLE:"role:get",
  UPDATE_ROLE:"role:update",
  REMOVE_ROLE:"role:remove",

  // permission
  CREATE_PERMISSION:"permission:create",
  LIST_PERMISSION:"permission:list",
  GET_PERMISSION:"permission:get",
  UPDATE_PERMISSION:"permission:update",
  REMOVE_PERMISSION:"permission:remove",

  // enterprise
  LIST_ENTERPRISE:"enterprise:list",
  GET_ENTERPRISE:"enterprise:get",
  UPDATE_ENTERPRISE:"enterprise:update",
  REMOVE_ENTERPRISE:"enterprise:remove",

  // project
  CREATE_PROJECT:"project:create",
  LIST_PROJECT:"project:list",
  GET_PROJECT:"project:get",
  UPDATE_PROJECT:"project:update",
  REMOVE_PROJECT:"project:remove",
  RESET_PROJECT_API_KEY:"project:reset_api_key",
  LIST_DEVELOPER_BY_PROJ_ID:"project:list_developers_by_project_id",
  GET_PROJECT_CONFIG_BY_PROJ_ID:"project:get_project_config_by_project_id",
  GET_API_CONSUMPTION_BY_PROJ_ID:"project:get_project_consumption_by_project_id",
  EXPORT_CONFIG_BY_PROJ_ID:"project:export_config_by_project_id",

  // easynft contract instance
  EASY_NFT_CREATE_INST:"EASY_NFT_CREATE_INST",
  EASY_NFT_LIST_INST:"EASY_NFT_LIST_INST",
  EASY_NFT_GET_INST:"EASY_NFT_GET_INST",
  EASY_NFT_UPDATE_INST:"EASY_NFT_UPDATE_INST",
  EASY_NFT_RESET_INST:"EASY_NFT_RESET_INST",

  // smart contract instance
  SC_CREATE_INST:"smart_contract_instance:create",
  SC_LIST_INST:"smart_contract_instance:list",
  SC_GET_INST:"smart_contract_instance:get",
  SC_UPDATE_INST:"smart_contract_instance:update",
  SC_REMOVE_INST:"smart_contract_instance:remove",
  SC_RESET_API_KEY:"smart_contract_instance:reset_api_key",
  SC_SUSPEND:"smart_contract_instance:suspend",
  SC_KILL:"smart_contract_instance:kill",

  // smart contract
  SC_CREATE_CONT:"smart_contract:create",
  SC_LIST_CONT:"smart_contract:list",
  SC_GET_CONT:"smart_contract:get",
  SC_UPDATE_CONT:"smart_contract:update",
  SC_REMOVE_CONT:"smart_contract:remove",
  SC_LOAD_EXISTING_CONT:"smart_contract:load_existing",
  SC_LIST_CONTRACT_TRANS:"smart_contract:list_transaction",
  SC_GET_CONTRACT_TRANS:"smart_contract:get_transaction",

  //Common Permissions
  COMMON_VIEW_DASHBOARD:"dashboard:get",
  COMMON_VIEW_PROFILE:"profile:get",
  COMMON_UPDATE_PROFILE:"profile:update",

  //developer module related
  COMMON_LIST_PROJECT:"project:list",
  COMMON_UPDATE_PROJET:"project:update",
  COMMON_CREATE_PROJET:"project:create",
  COMMON_EXPORT_CONFIG:"project:export_config",
  COMMON_VIEW_PROJECT:"project:get",
  COMMON_LIST_TRANSACTION:"project:list_transaction",
  COMMON_EDIT_WALLET:"developer:edit_wallet",
  COMMON_HELP_PAGE:"help_page",

  //East NFT Permissions
  EASYNFT_CREATE_INSTANCE:"easynft:create_instance",
  EASYNFT_UPDATE_INSTANCE:"easynft:update_instance",
  EASYNFT_LIST_INSTANCE:"easynft:list_instance",
  EASYNFT_CREATE_COLLECTION:"easynft:create_collection",
  EASYNFT_UPDATE_COLLECTION:"easynft:update_collection",
  EASYNFT_LIST_COLLECTION:"easynft:list_collection",
  EASYNFT_VIEW_COLLECTION:"easynft:view_collection",
  EASYNFT_MINT_NFT:"easynft:mint_nft",
  EASYNFT_LIST_NFT:"easynft:list_nft",
  EASYNFT_PENDING_NFT:"easynft:pending_nft",
  EASYNFT_SYNC_NFT:"easynft:sync_nft",
  EASYNFT_UPDATE_TRANSACTION:"easynft:update_transaction",

  //East FT Manager Permissions
  FT_CREATE_INSTANCE:"ft-manager:create_instance",
  FT_UPDATE_INSTANCE:"ft-manager:update_instance",
  FT_LIST_INSTANCE:"ft-manager:list_instance",
  FT_CREATE_ERC20Token:"ft-manager:erc20-token",
  FT_LIST_ERC20 : "ft-manager:list_erc20",
  FT_UPDATE : "ft-manager:update_erc20",
  FT_VIEW :"ft-manager:ft_view",
  FT_MINT:"ft-manager:mint_ft",
  FT_LIST:"ft-manager:list_ft",
  FT_PENDING:"ft-manager:pending_ft",
  FT_SYNC:"ft-manager:sync_ft",
  FT_UPDATE_TRANSACTION:"ft-manager:update_transaction",

  FT_TRANSFER : "ft-manager:transfer_ft",
  FT_APPROVE : "ft-manager:approve_ft",
  FT_BURN : "ft-manager:burn_ft",

  // Menu
  MENU_DASHBOARD: "menu:dashboard",
  MENU_PROJECT: "menu:project",
  MENU_PROJECTS: "menu:projects",
  END_POINTS: "menu:endpoints",
  END_POINTS_REVAMP: "menu:endpoints_revamp",
  // MENU_TOI: "menu:toi",
  MENU_easynft:"menu:easynft",
  MENU_ftmanager:"menu:ftmanager",
  MENU_SC: "menu:smart_contract",
  MENU_SC_CONTRACTS: "smart_contract:smart_contract",

  MENU_TRANSACTION: "menu:transaction",
  MENU_FAUCET: "menu:faucet",
  MENU_MY_ACCOUNT: "menu:my_account",
  MENU_ACCOUNT_SETTINGS: "menu:account_settings",
  MENU_HELP: "menu:help",
  MENU_DEVELOPERS: "menu:developers",
  MENU_TEAMS: "menu:teams",
  MENU_WALLETMANAGER: "menu:wallet",
  MENU_WEB3_SERVICES: "menu:Web3Services",
  MENU_ENABLERS: "menu:enablers",
  MENU_DID_MANAGER: "menu:did-manager",
  MENU_ADVANCED: "menu:advanced",

  MENU_ADDON: "menu:addon",
  //
  DL_REPORT_LIST:"dl_report:list",

  // Endpoints
  CREATE_ENDPOINTS : "endpoint:create",
  GET_ENDPOINT : "endpoint:get",
  LIST_ENDPOINTS : "endpoint:list",
  LIST_TRANSACTION_ENDPOINTS : "endpoint:transaction_list",
  CREATE_APIKEY_ENDPOINT : "endpoint:create_apikey",
  RESET_APIKEY_ENDPOINT : "endpoint:reset_apikey",

  //wallet
  CREATE_INSTANCE:"project:create",
};


// Essentials
import React, {useEffect} from "react";
//Utils
import { fetchApi } from "../../_config/api";
// Components and Styles
import "./styles.less";
import {Modal, Space, Input, Form, notification, Spin, Select} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {Icons, Menus} from "../../assets";
import {defaultList} from "../createProject/constant";
import {onKeyPressInputValidation} from "../../deps";

export const EditEndpoint = ({
                                     isOpen,
                                     toggleState,
                                      endpointId,
                                     reload
                                 }) => {
    const formRef = React.useRef(null);
    const [protocolList, setProtocolList] = React.useState([]);
    const [storageProtocolList, setStorageProtocolList] = React.useState([{key:"ipfs",value:"IPFS"}]);
    const [didProtocolList, setDidProtocolList] = React.useState([{key:"DID",value:"DID"}]);
    const [infraType, setInfraType] = React.useState([]);
    const [protocalName, setprotocalName] = React.useState("");
    const [endpointType, setEndpointType] = React.useState("");
    const [endpointName, setEndpointName] = React.useState("");

    const [endpointTypes, setEndpointTypes] = React.useState([]);
    const [infraTypes, setInfraTypes] = React.useState([]);
    const [deployDisabled, setDeployDisabled] = React.useState(false);
    const [data,setData] = React.useState({});

    const getProtocalList = () => {
        fetchApi({ method: "GET", url: `protocols/getAllProtocols` })
            .then((response) => {
                if (response.docs) {
                    const {docs = []} = response;
                    let didlist = []
                    let storageList = []
                    let blockchainList = []
                    for (let i= 0;i< response.docs.length;i++){
                        if(response.docs[i].protocol !== "DID" && response.docs[i].protocolType !== "bundler" && response.docs[i].protocol !== "IPFS"){
                            blockchainList.push(response.docs[i])
                            setProtocolList(blockchainList);
                        }
                        if (response.docs[i].protocol === "IPFS" && response.docs[i].protocolType !== "bundler"){
                            storageList.push(response.docs[i])
                            setStorageProtocolList(storageList)
                        }
                        if (response.docs[i].protocol === "DID" && response.docs[i].protocolType !== "bundler"){
                            didlist.push(response.docs[i])
                            setDidProtocolList(didlist)
                        }
                    }
                }
                setInfraTypes(defaultList.infraTypes);
                setEndpointTypes(defaultList.setEndpointTypes);
            })
            .catch(() => {

            });
    };
    const getEndpointsList = () => {
        fetchApi({ method: "GET", url: `endpoints/${endpointId}` })
            .then((response) => {
                const { doc = {} } = response;
                // console.log("response",response)
                setData(doc)
                setInfraType(doc.infraType)
                if (doc.infraType === 'blockchainNode'){
                    // console.log("response1111")
                    setprotocalName(doc.network === "" || doc.network === "NA" ?doc.protocolName+" - "+doc.chainId :doc.protocolName+" - "+doc.chainId+" - "+doc.network)
                }if (doc.infraType === 'storageNode' || doc.infraType === 'didNode'){
                    setprotocalName(doc.protocolName)
                }
                setEndpointType(doc.endpointType)
                setEndpointName(doc.endpointName)
                getProtocalList()


            })
            .catch(() => {
                setData({})
            });
    };
    React.useEffect(() => {
        getProtocalList()
        getEndpointsList();
    }, [isOpen]);







    const onUpdateEndpoint = (value) => {
        setDeployDisabled(true);

        data.endpointName = endpointName
        fetchApi({
            method: "POST",
            url: `endpoints/update/${endpointId}`,
            isDevApp: false,
            data: data,
        })
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201){
                    notification.success({
                        message:
                            "Endpoint Successfully Updated.",
                        duration: 5,
                    });
                    toggleState();
                    reload()
                    setDeployDisabled(false);
                }else{
                    setDeployDisabled(false);
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });
                    toggleState();
                }

            })
            .catch((error) => {
                console.error(error);
                setDeployDisabled(false);
                notification.error({
                    message: error, //"Not able to update an Endpoint. Try different Chain",
                    duration: 3,
                });
            });
    };

    const onChangeEnpointSelect = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        setEndpointName((event.target.value))
    };
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text">Rename an Endpoint</div>
                    <Space
                        direction="vertical"
                        size={25}
                        style={{ display: "flex", marginTop: "25px" }}
                    >
                        <Form
                            name="basic"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onUpdateEndpoint}
                            autoComplete="off"
                        >
                            <Form.Item name="endpointName"
                                       rules={[
                                           {
                                               required: true,
                                               message: "Missing Name",
                                           },
                                           {
                                               max: 100,
                                               message: "Max chars allowed is 50"
                                           },
                                           {
                                               pattern: /^[A-Za-z][A-Za-z'-]+[A-Za-z]$/,
                                               message: 'Please enter a valid name',
                                           }
                                       ]}
                            >
                                <>
                                    <input
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Endpoint Name"
                                        name="endpointName"
                                        value={endpointName}
                                        onChange={(event) => onChangeEnpointSelect(event, "endpointName")}
                                        onKeyPress={onKeyPressInputValidation}
                                    >
                                    </input>
                                </>
                            </Form.Item>

                            <div className="deploy-nft-collection">
                                <button disabled={deployDisabled} htmlType="submit">
                                    {!deployDisabled ? (
                                        "Submit"
                                    ) : (
                                        <Spin indicator={antIcon} />
                                    )}
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    );
};

import React, { useState } from "react";
import './NewLandingPage.less'
import {notification} from "antd";
import rightTickIcon from './Icons/Group 141.svg'
import rightTickIcon1 from './Icons/Group 1266.svg'
import img1 from "./Icons/carbon.svg"
import img2 from "./Icons/carbon(2).svg"
import copyImg from "./Icons/Group 127.svg"
import { useInView } from "react-intersection-observer";

export const StartwithKrypcore = () => {
    const viewDocurl = process.env.REACT_APP_LANDING_PAGE_DOCS_URL
    const command = process.env.REACT_APP_LANDING_PAGE_COMMAND
    const github= process.env.REACT_APP_GITHUB_SDK

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };

    const viewDoc = () => {
        window.open(viewDocurl)
    }
    const open = (value)=>{
        window.open(value)
    }

    const [hoveredTitle, setHoveredTitle] = useState('Node Infrastructure');
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.5 // Trigger animation when 50% of the element is visible
      });
      
    return (
        <div className="bg-white">
            <div className="startK-container-new" id="start-with-krypCore1">
                <div className="startK-heading">
                    <p className="text-uppercase">Explore API/ SDKs</p>
                    <h2 ref={ref} className={`${inView?'animated animatedFadeInUp fadeInUp':'hidden'}`}>The Most Seamless Way  <br className="hiddenbreaktag" /> to Build Web3 Apps</h2>
                </div>
                <div className="container-fluid  pt-md-3 pt-sm-3 pt-xl-3 pl-md-5 pl-sm-4 pl-xl-5 pr-md-5 pr-sm-4 pr-xl-5 pb-md-5 pb-sm-4 pb-xl-5" >
                <div className="row justify-content-between " style={{paddingBottom:'50px'}}>
                    <div  className={` ${inView ?'col-xl-8 col-sm-12 col-lg-12 animate fadeInLeft':'hidden'}`}>
                        <div className="startK-card-left ">
                            <div className="d-grid">
                            <h2 className="left-header">APIs</h2>
                            <p className="sub-header text-left">Access our comprehensive set of APIs <br/> to build your Web3 use case</p>

                            <div className="card-box">
                                <p><img src={rightTickIcon} alt="Access-Wallet-Functionality" /> <span>Wallet API</span></p>
                                <p><img src={rightTickIcon} alt="Utilize-Storage-Solutions" /> <span>Storage API</span></p>
                                <p><img src={rightTickIcon} alt="Interact-with-Smart-Contracts" /> <span>Smart Contract API</span></p>
                                <p><img src={rightTickIcon} alt="Manage-Decentralized-Identifiers" /> <span>DID API</span></p>
                                <p><img src={rightTickIcon} alt="Integrate-Token-Functionality" /> <span>Token API</span></p>
                                <p><img src={rightTickIcon} alt="Implement-Gasless-Transactions" /> <span>Gasless Transactions API</span></p>
                                <p><img src={rightTickIcon} alt="Access-Blockchain-Data-Analytics" /> <span>Datalake API</span></p>
                            </div>

                            <a href={viewDocurl} title={"Explore all API's"} ><button className="cad-docs_btn">Explore all APIs</button></a>
                            </div>
                            <div className="d-flex-column start-card-rightbox">
                                <img src={img1} alt="" className="mt-7 mb-2 d-flex justify-content-end krypcoreimgnew"/>
                                <img src={img2} alt="" className="d-flex justify-content-end krypcoreimgnew"/>
                            </div>
                            


                        </div>

                    </div>
                    <div className={`col-xl-4 col-sm-12 col-lg-12  mt-2 mt-md-0 mt-xl-0  mt-sm-1 ${inView ?'animate fadeInRight':'hidden'}`}>
              {/* <div className="container-fluid">   */}

              <div className="row">
              <div className="col-xl-12 col-sm-12 col-lg-6 mt-lg-4 mt-md-3 mt-xl-0 mt-3">
              <div className="startK-card-right ">
                       <div>
                       <h2 className="right-header">SDK</h2>
                        <p className="sub-header text-left"> Supercharge your dApp development <br/> with Krypcore Web3 SDK</p>
                       </div>
                        

                        <div className="startK-footer">
                            <div className="buildK-field">
                                <div className="buildK-text-card">{command}<img src={copyImg} alt="Illustration-of-Web3-SDK" style={{cursor:'pointer'}} onClick={() => onCopy(command)}/></div>
                            </div>
                            
                            
                        </div>
                        <div className="d-flex align-items-center">
                            {/*<a href={viewDocurl} title={"View Documents"} className="docs_btn1">View Docs</a>*/}
                            <a href={github} target="_blank" className="banner_btn_github ml-1 mt-3" >Visit Github</a>
                        </div>
                    </div>
                  </div>
                  <div className="col-xl-12 col-sm-12 col-lg-6 mt-lg-4 mt-md-3 mt-sm-2 mt-4">
                  <div className="startK-card-right1 ">
                       <div>
                       <h2 className="right-header">Infrastructure</h2>
                        <p className="sub-header text-left "> Access our ready-to-use infrastructure for  your Dapp Development needs</p>
                           <div className="infra-card-box">
                               <p><img src={rightTickIcon1} style={{width:'20px'}} alt="Node-Setup-for-dApp-Development" /> <span className="text-dark mt-1">Node Infrastructure</span></p>
                                <p><img src={rightTickIcon1} style={{width:'20px'}} alt="Storage-Solutions-for-dApps" /> <span className="text-dark mt-1">Storage Infrastructure</span></p>
                                <p><img src={rightTickIcon1} style={{width:'20px'}} alt="Infrastructure-for-Gasless-Transactions" /> <span className="text-dark mt-1">Gasless Infrastructure</span></p>
                                <p><img src={rightTickIcon1} style={{width:'20px'}} alt="Infrastructure-for-Bundling" /> <span className="text-dark mt-1">Bundler Infrastructure</span></p>
                           </div>
                                <a href={viewDocurl} target="_blank" title={"View Documents"} className="docs_btn1 mt-1" style={{marginTop:'10px !important'}}>View Docs</a>
                       </div>
                        

                    
                    </div>
                  </div>
            </div>

             
          </div>
                   
            
                   
                </div>
                </div>
            </div>
        </div>
    );
}

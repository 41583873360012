import React, {useState} from "react";
import {Typography, Space, Tooltip, Button, Collapse} from "antd";
import {ArrowLeftOutlined, InfoCircleOutlined, PlusOutlined} from "@ant-design/icons";
import { Icons, Menus } from "../../../assets";
import fetchApi from "../../../_config/api";
import {DEVELOPERROLE, ENTERPRISEDEVELOPERROLE, getUserDetails} from "../../../deps";
import {CreateEndpoint} from "./createEndpoint";
const { Panel } = Collapse;

export const CardList = ({
  title = "Choose the storage for your Project",
  id,
  selectedValue = [],
  onChange,
    options=[],isStorageSelected,isDidSelected,teamId
}) => {
  let newList = [...selectedValue];
  // console.log("newList",selectedValue)
    const [existblockchainendpoint, setexistblockchainendpoint] = useState([]);
    const [createBlockEndpointOpen, setCreateBlockEndpointOpen] = useState(false);
    const [createStorageEndpointOpen, setCreateStorageEndpointOpen] = useState(false);
    const [createDIDEndpointOpen, setCreateDIDEndpointOpen] = useState(false);
    const [createBundlerEndpointOpen, setCreateBundlerEndpointOpen] = useState(false);
    const [protocolList, setProtocolList] = useState([]);
    const [storageProtocolList, setStorageProtocolList] = useState([]);
    const [didProtocolList, setDidProtocolList] = useState([]);
    const [bundlerProtocolList, setBundlerProtocolList] = useState([]);
    const [activeKey, setActiveKey] = useState("blockchainNode");
    const [existstorageendpoint, setexiststorageendpoint] = useState([]);
    const [existdidendpoint, setexistdidendpoint] = useState([]);
    const [existbundlerendpoint, setexistbundlerendpoint] = useState([]);


    const onCreateBlockEndpointModal = () => {
        setCreateBlockEndpointOpen(!createBlockEndpointOpen);
        setActiveKey("blockchainNode")
    };
    const onCreateStoreEndpointModal = () => {
        setCreateStorageEndpointOpen(!createStorageEndpointOpen);
        setActiveKey("storageNode")
    };
    const onCreateDIDEndpointModal = () => {
        setCreateDIDEndpointOpen(!createDIDEndpointOpen);
        setActiveKey("didNode")
    };
    const onCreateBundlerEndpointModal = () => {
        setCreateBundlerEndpointOpen(!createBundlerEndpointOpen);
        setActiveKey("bundlerNode")
    };
    const [query, setQuery] = useState(false);

    React.useEffect(() => {
        if(role === DEVELOPERROLE){
            getEndpointList();
        }else if(role === ENTERPRISEDEVELOPERROLE){
            getEndpointListForTeamID();
        }
        blockchainendpoint();
        storageendpoint();
        didendpoint();
        bundlerEndpoint();
      },[]
  )
    const {
        profile: { userRole },
    } = getUserDetails();
    const [role, setRole] = React.useState(userRole);
    const getEndpointList = () => {
        fetchApi({method: "GET", url: "projects/endpoints-list"})
            .then((response) => {
                const {docs = []} = response;
                let blocklist = [];
                let storelist = [];
                let didlist = [];
                let bundlerList = [];
                for (let i = 0; i < docs.length; i++) {
                    if (docs[i].infraType === "blockchainNode") {
                        blocklist.push(docs[i])
                        setProtocolList(blocklist)
                    }
                    if (docs[i].infraType === "didNode") {
                        didlist.push(docs[i])
                        setDidProtocolList(didlist)
                    }
                    if (docs[i].infraType === "storageNode") {
                        storelist.push(docs[i])
                        setStorageProtocolList(storelist)
                    }
                    if (docs[i].infraType === "bundlerNode") {
                        bundlerList.push(docs[i])
                        setBundlerProtocolList(bundlerList)
                    }
                }
            })
    }
    const getEndpointListForTeamID = () => {
        fetchApi({method: "GET", url: `projects/endpoints-list/${teamId}`})
            .then((response) => {
                const {docs = []} = response;
                let blocklist = [];
                let storelist = [];
                let didlist = [];
                let bundlerList = [];
                for (let i = 0; i < docs.length; i++) {
                    if (docs[i].infraType === "blockchainNode") {
                        blocklist.push(docs[i])
                        setProtocolList(blocklist)
                    }
                    if (docs[i].infraType === "didNode") {
                        didlist.push(docs[i])
                        setDidProtocolList(didlist)
                    }
                    if (docs[i].infraType === "storageNode") {
                        storelist.push(docs[i])
                        setStorageProtocolList(storelist)
                    }
                    if (docs[i].infraType === "bundlerNode") {
                        bundlerList.push(docs[i])
                        setBundlerProtocolList(bundlerList)
                    }
                }
            })
    }
  const blockchainendpoint = () => {
      fetchApi({
          url: "developer/projectBlockChainEndpointList",
          method: "GET",
      }).then((response) => {
          if(response !== null && response[0] !== undefined){
              setexistblockchainendpoint(response[0].blockChainEndpointName)
          }
      });
  }
    const storageendpoint = () => {
        fetchApi({
            url: "developer/projectStorageEndpointList",
            method: "GET",
        }).then((response) => {
            // console.log("response",response)
            if(response !== null && response[0] !== undefined){
                setexiststorageendpoint(response[0].storageEndpointName)
            }
        });
    }
    const didendpoint = () => {
        fetchApi({
            url: "developer/projectDIDEndpointList",
            method: "GET",
        }).then((response) => {
            // console.log("response",response)
            if(response !== null && response[0] !== undefined){
                setexistdidendpoint(response[0].didEndpointName)
            }
        });
    }

    const bundlerEndpoint = () => {
        fetchApi({
            url: "developer/projectBundlerEndpointList",
            method: "GET",
        }).then((response) => {
            if(response !== null && response[0] !== undefined){
                setexistbundlerendpoint(response[0].bundlerEndpointName)
            }
        });
    }
  const onClick = (name,list) => {
    // console.log("list---->",list)
    let newObject = {};
    if(list.infraType === "storageNode"){
      newObject = { [name]:{
          EndpointName:list.endpointName,
          Protocol:list.protocolName,
          Network:list.network,
          ID:list.endpointId,
          RPC_URL:list.userEndpoint,
          ChainId:list.chainId,
        } };
    }else if(list.infraType === "didNode"){
      newObject = { [name]:{
          EndpointName:list.endpointName,
          Protocol:list.protocolName,
          Network:list.network,
          ID:list.endpointId,
          RPC_URL:list.userEndpoint,
          ChainId:list.chainId,
          } };
    }else if(list.infraType === "bundlerNode"){
        newObject = { [name]:{
                EndpointName:list.endpointName,
                Protocol:list.protocolName,
                Network:list.network,
                ID:list.endpointId,
                RPC_URL:list.userEndpoint,
                ChainId:list.chainId,
            } };
    }else{
      newObject = {
          [name]: [{
              EndpointName: list.endpointName,
              Protocol: list.protocolName,
              Network: list.network,
              ID: list.endpointId,
              RPC_URL: list.userEndpoint,
              ChainId:list.chainId,
          }]
      };
    }
    const existingObject = newList.find(obj => obj[name]!== undefined);
      // console.log("existingObject---->",existingObject)
    if(existingObject){
        // const existing = newList.find(obj => obj[name].EndpointName === list.protocolName);
        // console.log("existing---->",existing)
        // if(existingObject){
        //     newList =  newList.filter(obj => !obj.hasOwnProperty(name));
        // }else{
        //     newList = newList.map(obj => obj === existingObject ? newObject : obj)
        // }
        const existingObjectName = Object.keys(existingObject)[0];
        if(existingObjectName !== "BlockchainEndpoint"){
            const updatedArray = newList.filter(obj => {
                const nestedValue = obj[name] && obj[name].EndpointName;
                return nestedValue !== list.endpointName;
            });
            newList =  updatedArray;
        }else{
            let existingArray = Array.isArray(existingObject[name]) ? existingObject[name] : [];
            // console.log(existingArray);
            const valueExists = existingArray.some(obj => {
                // console.log(obj.EndpointName);
                // console.log(list.endpointName);
                return obj.ChainId === list.chainId;
            });
            // console.log(valueExists);
            let blockObj = {
                EndpointName: list.endpointName,
                Protocol: list.protocolName,
                Network: list.network,
                ID: list.endpointId,
                RPC_URL: list.userEndpoint,
                ChainId:list.chainId,
            }
            if (!valueExists) {
                existingArray.push(blockObj);
                newObject[name] = existingArray;
            }else{
                const updatedArray = existingArray.filter(obj => {
                    // console.log(obj.EndpointName);
                    // console.log(blockObj.EndpointName);
                    return obj.ID !== blockObj.ID;
                });
                const indexToUpdate = newList.findIndex(obj => Object.keys(obj)[0] === "BlockchainEndpoint");
                // console.log(indexToUpdate);
                if (indexToUpdate !== -1) {
                    // console.log(indexToUpdate);
                    // console.log(newList[indexToUpdate]);
                    newList[indexToUpdate].BlockchainEndpoint = updatedArray;
                }
                // console.log(newList);
            }
        }
    }else{
        // console.log(newObject);
        newList.push(newObject)
    }
    onChange(id, newList);
  };
    const onCreateChange = (key) => {
        if (key){
            setQuery(!query);
        }
    };
    console.log("newList------->",newList)
    const filteredProtocolList = protocolList.filter((list) => !existblockchainendpoint.includes(list?.endpointName));
    const filteredStorageProtocolList = storageProtocolList.filter((list) => !existstorageendpoint.includes(list?.endpointName));
    const filteredDidProtocolList = didProtocolList.filter((list) => !existdidendpoint.includes(list?.endpointName));
    const filteredBundlerProtocolList = bundlerProtocolList.filter((list) => !existbundlerendpoint.includes(list?.endpointName));
  return (
    <div className="column-container" style={{marginBottom:"50px"}}>
      <Space direction="vertical" size={2}  >
        <>
            <div className="dapp-faq-details">
                    <Collapse end="right" defaultActiveKey={['1']} expandIconPosition="right">
                        <Panel header={"Select your Blockchain node"} key={"1"} title={"Select your Blockchain node"}>
                            <div className="row-container" style={{gap:"36px"}}>
                                {role === DEVELOPERROLE &&
                                <div className="card">
                                    <Button
                                        className="create-Endpoint-btn plus-btn"
                                        type="link"
                                        icon={<PlusOutlined />}
                                        onClick={onCreateBlockEndpointModal}>
                                    </Button>
                                    <p className="add-plus-text">Add node</p>
                                </div>
                                }
                                {filteredProtocolList.map((list, index) => {
                                    // console.log(existblockchainendpoint[0], list?.endpointName)
                                    if (existblockchainendpoint.includes(list?.endpointName)) {
                                        list.disabled = true;
                                    }
                                    // const isActive =
                                    //     selectedValue.findIndex((item) => item.name === list.name) > -1;
                                    let blockName = "";
                                    let endpointId = "";
                                    let isActive = false;
                                    // console.log("ssss----->", newList)
                                    if (newList.length > 0) {
                                        const ind = newList.findIndex(obj => obj.hasOwnProperty("BlockchainEndpoint"));
                                        if (newList[ind] !== undefined && newList[ind].BlockchainEndpoint !== undefined) {
                                            // blockName = newList[ind].BlockchainEndpoint.Protocol;
                                            // endpointId = newList[ind].BlockchainEndpoint.ID;
                                            // console.log("blockName--->", blockName);
                                            if(newList[ind].BlockchainEndpoint.length > 0){
                                                for(let i=0;i<newList[ind].BlockchainEndpoint.length;i++){
                                                    blockName = newList[ind].BlockchainEndpoint[i].Protocol;
                                                    endpointId = newList[ind].BlockchainEndpoint[i].ID;
                                                    if (blockName === list?.protocolName && endpointId === list?.endpointId) {
                                                        isActive = true;
                                                    }
                                                }
                                            }
                                        }
                                    }
                                    // if (blockName === list?.protocolName && endpointId === list?.endpointId) {
                                    //     isActive = true;
                                    // }
                                    const cardKey = `blockchain-${index}`; // Unique key for each card
                                    let text = "";
                                    if (list?.protocolName?.includes("optimism")) {
                                        text = "Another Ethereum Layer 2 scaling solution designed to enhance transaction throughput.";
                                    } else if (list?.protocolName?.includes("ethereum")) {
                                        text = "A widely-used blockchain platform for decentralized applications and smart contracts.";
                                    } else if (list?.protocolName?.includes("polygon")) {
                                        text = "A scalable layer 2 protocol for fast and low-cost transactions.";
                                    } else if (list?.protocolName?.includes("arbitrum")) {
                                        text = "An Ethereum Layer 2 scaling solution for improved scalability and efficiency.";
                                    } else {
                                        text = "";
                                    }
                                    let icontype = "";
                                    const iconname = list?.protocolName?.split("-");
                                    if (iconname.length > 0) {
                                        icontype = iconname[0];
                                    }
                                    return (
                                        <>
                                            <Tooltip title={text} color={"geekblue"}>
                                                <div
                                                    key={cardKey}
                                                    className={`card${isActive ? " active-card" : ""}${
                                                        list?.disabled ? " disabled-card" : ""
                                                    }`}
                                                    // onClick={list?.disabled ? null : () => onClick("BlockchainEndpoint", list)}
                                                    onClick={() => onClick("BlockchainEndpoint", list)}
                                                >
                                                    {isActive ? (
                                                        <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                                            <Menus type="tick-innerline"/>{" "}
                                                        </div>
                                                    ) : (
                                                        <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                                            <Menus type="tick-default"/>{" "}
                                                        </div>
                                                    )}
                                                    <Icons type={icontype?.toLowerCase()}/>
                                                    <div className="endpoint-name1">{list?.protocolName}</div>
                                                    <small className="endpoint-name2" style={{color: "#fff"}}>{list?.endpointName}</small>
                                                </div>
                                            </Tooltip>
                                        </>
                                    );
                                })
                                }
                                {/*{role === DEVELOPERROLE &&
              <Button
                  style={{marginTop:80,marginLeft:20}}
                  className="create-Endpoint-btn"
                  type="link"
                  icon={<PlusOutlined />}
                  onClick={onCreateBlockEndpointModal}
              >

              </Button>}*/}

                                {/*</Restricted>*/}
                                <CreateEndpoint
                                    isOpen={createBlockEndpointOpen}
                                    toggleState={onCreateBlockEndpointModal}
                                    reloadCollection={getEndpointList}
                                    activeKey={activeKey}
                                    query={onCreateChange}
                                />
                            </div>
                        </Panel>
                    </Collapse>
            </div>

          {/*<Typography.Text className="label-text d-inline">
            Select your Blockchain node<span style={{color:"red"}}>*</span>
              <p className="subheader mt-2">REST endpoint connection for protocol of choice</p>
          </Typography.Text>
          <div className="row-container">
              {role === DEVELOPERROLE &&
              <div className="card">
                  <Button
                      className="create-Endpoint-btn plus-btn"
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={onCreateBlockEndpointModal}>
                  </Button>
                  <p className="add-plus-text">Add node</p>
              </div>
              }
            {filteredProtocolList.map((list, index) => {
                if (existblockchainendpoint.includes(list?.endpointName)) {
                    list.disabled = true;
                }
                // const isActive =
                //     selectedValue.findIndex((item) => item.name === list.name) > -1;
                let blockName = "";
                let endpointId = "";
                // console.log("ssss----->", newList)
                if (newList.length > 0) {
                    const ind = newList.findIndex(obj => obj.hasOwnProperty("BlockchainEndpoint"));
                    if (newList[ind] !== undefined && newList[ind].BlockchainEndpoint !== undefined) {
                        blockName = newList[ind].BlockchainEndpoint.Protocol;
                        endpointId = newList[ind].BlockchainEndpoint.ID;
                        // console.log("blockName--->", blockName);
                    }
                }
                let isActive = false;
                if (blockName === list?.protocolName && endpointId === list?.endpointId) {
                    isActive = true;
                }
                const cardKey = `blockchain-${index}`; // Unique key for each card
                let text = "";
                if (list?.protocolName?.includes("optimism")) {
                    text = "Another Ethereum Layer 2 scaling solution designed to enhance transaction throughput.";
                } else if (list?.protocolName?.includes("ethereum")) {
                    text = "A widely-used blockchain platform for decentralized applications and smart contracts.";
                } else if (list?.protocolName?.includes("polygon")) {
                    text = "A scalable layer 2 protocol for fast and low-cost transactions.";
                } else if (list?.protocolName?.includes("arbitrum")) {
                    text = "An Ethereum Layer 2 scaling solution for improved scalability and efficiency.";
                } else {
                    text = "";
                }
                let icontype = "";
                const iconname = list?.protocolName?.split("-");
                if (iconname.length > 0) {
                    icontype = iconname[0];
                }
                return (
                    <>
                    <Tooltip title={text} color={"geekblue"}>
                        <div
                            key={cardKey}
                            className={`card${isActive ? " active-card" : ""}${
                                list?.disabled ? " disabled-card" : ""
                            }`}
                            onClick={list?.disabled ? null : () => onClick("BlockchainEndpoint", list)}
                        >
                            {isActive ? (
                                <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                    <Menus type="tick-innerline"/>{" "}
                                </div>
                            ) : (
                                <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                    <Menus type="tick-default"/>{" "}
                                </div>
                            )}
                            <Icons type={icontype?.toLowerCase()}/>
                            <div className="endpoint-name1">{list?.protocolName}</div>
                            <small className="endpoint-name2" style={{color: "#fff"}}>{list?.endpointName}</small>
                        </div>
                    </Tooltip>
                </>
                );
              })
            }
              <CreateEndpoint
                  isOpen={createBlockEndpointOpen}
                  toggleState={onCreateBlockEndpointModal}
                  reloadCollection={getEndpointList}
                  activeKey={activeKey}
                  query={onCreateChange}
              />
          </div>*/}
        </>
        <>
            <div className="dapp-faq-details">
                <Collapse end="right" expandIconPosition="right">
                    <Panel header={"Choose Storage node"} key={"2"} title={"Choose Storage node"}>
                        <div className="row-container" key="storage">
                            {role === DEVELOPERROLE &&
                            <div className="card">
                                <Button
                                    className="create-Endpoint-btn plus-btn"
                                    type="link"
                                    icon={<PlusOutlined />}
                                    onClick={onCreateStoreEndpointModal}
                                >

                                </Button>
                                <p className="add-plus-text">Add node</p>
                            </div>
                            }
                            {filteredStorageProtocolList.map((list, index) => {
                                let blockName = "";
                                // console.log("ssss----->", newList)
                                if (existstorageendpoint.includes(list?.endpointName)) {
                                    list.disabled = true;
                                }
                                let endpointId = "";
                                if (newList.length > 0) {
                                    const ind = newList.findIndex(obj => obj.hasOwnProperty("StorageEndpoint"));
                                    if (newList[ind] !== undefined && newList[ind].StorageEndpoint !== undefined) {
                                        blockName = newList[ind].StorageEndpoint.Protocol;
                                        endpointId = newList[ind].StorageEndpoint.ID;
                                        // console.log("blockName--->", blockName);


                                    }
                                }
                                let isActive = false;
                                if (blockName === list?.protocolName && endpointId === list?.endpointId) {
                                    isActive = true;
                                }
                                const cardKey = `storage-${index}`; // Unique key for each card
                                let text = "";
                                if (list?.protocolName?.includes("IPFS")) {
                                    text = "IPFS provides a decentralized and efficient way to store and retrieve files in a peer-to-peer network.";
                                } else {
                                    text = "";
                                }
                                let icontype = "";
                                const iconname = list?.protocolName?.split("-");
                                if (iconname.length > 0) {
                                    icontype = iconname[0];
                                }
                                return (
                                    <Tooltip title={text} color={"geekblue"}>
                                        <div
                                            key={cardKey}
                                            className={`card${isActive ? " active-card" : ""}${
                                                list?.disabled ? " disabled-card" : ""
                                            }`}
                                            onClick={list?.disabled ? null : () => onClick("StorageEndpoint", list)}
                                        >
                                            {isActive ? (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: "13px",
                                                        top: "12px",
                                                    }}
                                                >
                                                    <Menus type="tick-innerline"/>{" "}
                                                </div>
                                            ) : (
                                                <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                                    <Menus type="tick-default"/>{" "}
                                                </div>
                                            )}
                                            <Icons type={icontype?.toLowerCase()}/>
                                            <div className="endpoint-name1">{list?.protocolName}</div>
                                            <small className="endpoint-name2" style={{color: "#fff"}}>{list?.endpointName}</small>
                                        </div>
                                    </Tooltip>
                                );
                            })
                            }
                            <CreateEndpoint
                                isOpen={createStorageEndpointOpen}
                                toggleState={onCreateStoreEndpointModal}
                                reloadCollection={getEndpointList}
                                activeKey={activeKey}
                                query={onCreateChange}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </div>
         {/* <Typography.Text className="label-text d-inline">
            Choose Storage Node
              <p className="subheader mt-2">REST endpoint connection for protocol of choice</p>
          </Typography.Text>
          <div className="row-container" key="storage">
              {role === DEVELOPERROLE &&
              <div className="card">
                  <Button
                      className="create-Endpoint-btn plus-btn"
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={onCreateStoreEndpointModal}
                  >

                  </Button>
                  <p className="add-plus-text">Add node</p>
              </div>
              }
              {filteredStorageProtocolList.map((list, index) => {
                  let blockName = "";
                  // console.log("ssss----->", newList)
                  if (existstorageendpoint.includes(list?.endpointName)) {
                      list.disabled = true;
                  }
                  let endpointId = "";
                  if (newList.length > 0) {
                      const ind = newList.findIndex(obj => obj.hasOwnProperty("StorageEndpoint"));
                      if (newList[ind] !== undefined && newList[ind].StorageEndpoint !== undefined) {
                          blockName = newList[ind].StorageEndpoint.Protocol;
                          endpointId = newList[ind].StorageEndpoint.ID;
                          // console.log("blockName--->", blockName);


                      }
                  }
                  let isActive = false;
                  if (blockName === list?.protocolName && endpointId === list?.endpointId) {
                      isActive = true;
                  }
                  const cardKey = `storage-${index}`; // Unique key for each card
                  let text = "";
                  if (list?.protocolName?.includes("IPFS")) {
                      text = "IPFS provides a decentralized and efficient way to store and retrieve files in a peer-to-peer network.";
                  } else {
                      text = "";
                  }
                  let icontype = "";
                  const iconname = list?.protocolName?.split("-");
                  if (iconname.length > 0) {
                      icontype = iconname[0];
                  }
                  return (
                      <Tooltip title={text} color={"geekblue"}>
                          <div
                              key={cardKey}
                              className={`card${isActive ? " active-card" : ""}${
                                  list?.disabled ? " disabled-card" : ""
                              }`}
                              onClick={list?.disabled ? null : () => onClick("StorageEndpoint", list)}
                          >
                              {isActive ? (
                                  <div
                                      style={{
                                          position: "absolute",
                                          right: "13px",
                                          top: "12px",
                                      }}
                                  >
                                      <Menus type="tick-innerline"/>{" "}
                                  </div>
                              ) : (
                                  <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                      <Menus type="tick-default"/>{" "}
                                  </div>
                              )}
                              <Icons type={icontype?.toLowerCase()}/>
                              <div className="endpoint-name1">{list?.protocolName}</div>
                              <small className="endpoint-name2" style={{color: "#fff"}}>{list?.endpointName}</small>
                          </div>
                      </Tooltip>
                  );
              })
              }
              <CreateEndpoint
                  isOpen={createStorageEndpointOpen}
                  toggleState={onCreateStoreEndpointModal}
                  reloadCollection={getEndpointList}
                  activeKey={activeKey}
                  query={onCreateChange}
              />
          </div>*/}

        </>
        <>
            <div className="dapp-faq-details">
                <Collapse end="right" expandIconPosition="right">
                    <Panel header={"Choose DID node"} key={"3"} title={"Choose DID node"}>
                        <div className="row-container" key="did">
                            {role === DEVELOPERROLE &&
                            <div className="card">
                                <Button
                                    className="create-Endpoint-btn plus-btn"
                                    type="link"
                                    icon={<PlusOutlined />}
                                    onClick={onCreateDIDEndpointModal}
                                >

                                </Button>
                                <p className="add-plus-text">Add node</p>
                            </div>
                            }
                            {filteredDidProtocolList.map((list, index) => {
                                let blockName="";
                                if (existdidendpoint.includes(list?.endpointName)) {
                                    list.disabled = true;
                                }
                                // console.log("ssss----->",newList)
                                let endpointId = "";
                                if(newList.length >0){
                                    const ind = newList.findIndex(obj => obj.hasOwnProperty("DIEndpoint"));
                                    if(newList[ind] !== undefined && newList[ind].DIEndpoint !== undefined){
                                        blockName = newList[ind].DIEndpoint.Protocol;
                                        endpointId = newList[ind].DIEndpoint.ID;
                                        // console.log("blockName--->",blockName);


                                    }
                                }
                                let isActive = false ;
                                if(blockName === list?.protocolName && endpointId === list?.endpointId){
                                    isActive = true;
                                }
                                const cardKey = `did-${index}`; // Unique key for each card
                                let text = "";
                                if(list.protocolName?.includes("DID")){
                                    text = "Self-sovereign identity allows individuals to have control over their personal data and digital identities.";
                                }else {
                                    text = "";
                                }
                                let icontype = "did";
                                // const iconname = list?.protocolName?.split("-");
                                // if(iconname.length > 0){
                                //     icontype = iconname[0];
                                // }
                                return (
                                    <Tooltip title={text} color={"geekblue"}>
                                        <div
                                            key={cardKey}
                                            className={`card${isActive ? " active-card" : ""}${
                                                list?.disabled ? " disabled-card" : ""
                                            }`}
                                            onClick={list?.disabled ? null : () => onClick("DIEndpoint",list)}
                                        >
                                            {isActive ? (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: "13px",
                                                        top: "12px",
                                                    }}
                                                >
                                                    <Menus type="tick-innerline" />{" "}
                                                </div>
                                            ) : (
                                                <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                                    <Menus type="tick-default"/>{" "}
                                                </div>
                                            )}
                                            <Icons type={icontype?.toLowerCase()} />
                                            <div className="endpoint-name1">{list?.protocolName}</div>
                                            <small className="endpoint-name2" style={{color:"#fff"}}>{list?.endpointName}</small>
                                        </div>
                                    </Tooltip>
                                );
                            })
                            }
                            <CreateEndpoint
                                isOpen={createDIDEndpointOpen}
                                toggleState={onCreateDIDEndpointModal}
                                reloadCollection={getEndpointList}
                                activeKey={activeKey}
                                query={onCreateChange}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </div>
          {/*<Typography.Text className="label-text d-inline">
            Choose DID Node
              <p className="subheader mt-2">REST endpoint connection for protocol of choice</p>
          </Typography.Text>
          <div className="row-container" key="did">
              {role === DEVELOPERROLE &&
              <div className="card">
                  <Button
                      className="create-Endpoint-btn plus-btn"
                      type="link"
                      icon={<PlusOutlined />}
                      onClick={onCreateDIDEndpointModal}
                  >

                  </Button>
                  <p className="add-plus-text">Add node</p>
              </div>
              }
            {filteredDidProtocolList.map((list, index) => {
              let blockName="";
                if (existdidendpoint.includes(list?.endpointName)) {
                    list.disabled = true;
                }
              // console.log("ssss----->",newList)
              let endpointId = "";
              if(newList.length >0){
                const ind = newList.findIndex(obj => obj.hasOwnProperty("DIEndpoint"));
                if(newList[ind] !== undefined && newList[ind].DIEndpoint !== undefined){
                  blockName = newList[ind].DIEndpoint.Protocol;
                    endpointId = newList[ind].DIEndpoint.ID;
                    // console.log("blockName--->",blockName);


                }
              }
              let isActive = false ;
              if(blockName === list?.protocolName && endpointId === list?.endpointId){
                isActive = true;
              }
              const cardKey = `did-${index}`; // Unique key for each card
              let text = "";
              if(list.protocolName?.includes("DID")){
                text = "Self-sovereign identity allows individuals to have control over their personal data and digital identities.";
              }else {
                text = "";
              }
                    let icontype = "did";
                    // const iconname = list?.protocolName?.split("-");
                    // if(iconname.length > 0){
                    //     icontype = iconname[0];
                    // }
              return (
                  <Tooltip title={text} color={"geekblue"}>
                  <div
                      key={cardKey}
                      className={`card${isActive ? " active-card" : ""}${
                          list?.disabled ? " disabled-card" : ""
                      }`}
                      onClick={list?.disabled ? null : () => onClick("DIEndpoint",list)}
                  >
                    {isActive ? (
                        <div
                            style={{
                              position: "absolute",
                              right: "13px",
                              top: "12px",
                            }}
                        >
                          <Menus type="tick-innerline" />{" "}
                        </div>
                    ) : (
                        <div style={{position: "absolute",right: "13px",top: "12px"}}>
                            <Menus type="tick-default"/>{" "}
                        </div>
                    )}
                    <Icons type={icontype?.toLowerCase()} />
                    <div className="endpoint-name1">{list?.protocolName}</div>
                      <small className="endpoint-name2" style={{color:"#fff"}}>{list?.endpointName}</small>
                  </div>
                  </Tooltip>
              );
            })
            }
              <CreateEndpoint
                  isOpen={createDIDEndpointOpen}
                  toggleState={onCreateDIDEndpointModal}
                  reloadCollection={getEndpointList}
                  activeKey={activeKey}
                  query={onCreateChange}
              />
          </div>*/}
        </>
        <>
            <div className="dapp-faq-details">
                <Collapse end="right" expandIconPosition="right">
                    <Panel header={"Choose Bundler node"} key={"4"} title={"Choose Bundler node"}>
                        <div className="row-container" key="bundler">
                            {role === DEVELOPERROLE &&
                            <div className="card">
                                <Button
                                    className="create-Endpoint-btn plus-btn"
                                    type="link"
                                    icon={<PlusOutlined />}
                                    onClick={onCreateBundlerEndpointModal}
                                >

                                </Button>
                                <p className="add-plus-text">Add node</p>
                            </div>
                            }
                            {filteredBundlerProtocolList.map((list, index) => {
                                let blockName="";
                                if (existbundlerendpoint.includes(list?.endpointName)) {
                                    list.disabled = true;
                                }
                                // console.log("ssss----->",newList)
                                let endpointId = "";
                                if(newList.length >0){
                                    const ind = newList.findIndex(obj => obj.hasOwnProperty("SCEndpoint"));
                                    if(newList[ind] !== undefined && newList[ind].SCEndpoint !== undefined){
                                        blockName = newList[ind].SCEndpoint.Protocol;
                                        endpointId = newList[ind].SCEndpoint.ID;
                                        // console.log("blockName--->",blockName);
                                    }
                                }
                                let isActive = false ;
                                if(blockName === list?.protocolName && endpointId === list?.endpointId){
                                    isActive = true;
                                }
                                const cardKey = `bundler-${index}`; // Unique key for each card
                                let text = "";
                                if(list.protocolName?.includes("Shared")){
                                    text = "Self-sovereign identity allows individuals to have control over their personal data and digital identities.";
                                }else {
                                    text = "";
                                }
                                let icontype = "bundler";
                                // const iconname = list?.protocolName?.split("-");
                                // if(iconname.length > 0){
                                //     icontype = iconname[0];
                                // }
                                return (
                                    <Tooltip title={text} color={"geekblue"}>
                                        <div
                                            key={cardKey}
                                            className={`card${isActive ? " active-card" : ""}${
                                                list?.disabled ? " disabled-card" : ""
                                            }`}
                                            onClick={list?.disabled ? null : () => onClick("SCEndpoint",list)}
                                        >
                                            {isActive ? (
                                                <div
                                                    style={{
                                                        position: "absolute",
                                                        right: "13px",
                                                        top: "12px",
                                                    }}
                                                >
                                                    <Menus type="tick-innerline" />{" "}
                                                </div>
                                            ) : (
                                                <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                                    <Menus type="tick-default"/>{" "}
                                                </div>
                                            )}
                                            <Icons type={icontype?.toLowerCase()} />
                                            <div className="endpoint-name1">{list?.protocolName}</div>
                                            <small className="endpoint-name2" style={{color:"#fff"}}>{list?.endpointName}</small>
                                        </div>
                                    </Tooltip>
                                );
                            })
                            }
                            <CreateEndpoint
                                isOpen={createBundlerEndpointOpen}
                                toggleState={onCreateBundlerEndpointModal}
                                reloadCollection={getEndpointList}
                                activeKey={activeKey}
                                query={onCreateChange}
                            />
                        </div>
                    </Panel>
                </Collapse>
            </div>
              {/*<Typography.Text className="label-text d-inline">
                  Choose Bundler Node
                  <p className="subheader mt-2">REST endpoint connection for protocol of choice</p>
              </Typography.Text>
              <div className="row-container" key="bundler">
                  {role === DEVELOPERROLE &&
                  <div className="card">
                      <Button
                          className="create-Endpoint-btn plus-btn"
                          type="link"
                          icon={<PlusOutlined />}
                          onClick={onCreateBundlerEndpointModal}
                      >

                      </Button>
                      <p className="add-plus-text">Add node</p>
                  </div>
                  }
                  {bundlerProtocolList.map((list, index) => {
                      let blockName="";
                      if (existbundlerendpoint.includes(list?.endpointName)) {
                          list.disabled = true;
                      }
                      // console.log("ssss----->",newList)
                      let endpointId = "";
                      if(newList.length >0){
                          const ind = newList.findIndex(obj => obj.hasOwnProperty("SCEndpoint"));
                          if(newList[ind] !== undefined && newList[ind].SCEndpoint !== undefined){
                              blockName = newList[ind].SCEndpoint.Protocol;
                              endpointId = newList[ind].SCEndpoint.ID;
                              // console.log("blockName--->",blockName);
                          }
                      }
                      let isActive = false ;
                      if(blockName === list?.protocolName && endpointId === list?.endpointId){
                          isActive = true;
                      }
                      const cardKey = `bundler-${index}`; // Unique key for each card
                      let text = "";
                      if(list.protocolName?.includes("Shared")){
                          text = "Self-sovereign identity allows individuals to have control over their personal data and digital identities.";
                      }else {
                          text = "";
                      }
                      let icontype = "bundler";
                      // const iconname = list?.protocolName?.split("-");
                      // if(iconname.length > 0){
                      //     icontype = iconname[0];
                      // }
                      return (
                          <Tooltip title={text} color={"geekblue"}>
                              <div
                                  key={cardKey}
                                  className={`card${isActive ? " active-card" : ""}${
                                      list?.disabled ? " disabled-card" : ""
                                  }`}
                                  onClick={list?.disabled ? null : () => onClick("SCEndpoint",list)}
                              >
                                  {isActive ? (
                                      <div
                                          style={{
                                              position: "absolute",
                                              right: "13px",
                                              top: "12px",
                                          }}
                                      >
                                          <Menus type="tick-innerline" />{" "}
                                      </div>
                                  ) : (
                                      <div style={{position: "absolute",right: "13px",top: "12px"}}>
                                          <Menus type="tick-default"/>{" "}
                                      </div>
                                  )}
                                  <Icons type={icontype?.toLowerCase()} />
                                  <div className="endpoint-name1">{list?.protocolName}</div>
                                  <small className="endpoint-name2" style={{color:"#fff"}}>{list?.endpointName}</small>
                              </div>
                          </Tooltip>
                      );
                  })
                  }
                  <CreateEndpoint
                      isOpen={createBundlerEndpointOpen}
                      toggleState={onCreateBundlerEndpointModal}
                      reloadCollection={getEndpointList}
                      activeKey={activeKey}
                      query={onCreateChange}
                  />
              </div>*/}
          </>
      </Space>
    </div>
  );
};

export default CardList;

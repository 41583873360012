/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect, useRef } from "react";
import { fetchApi } from "../../../_config/api";
import { TableContent, PaginationSection, Filters } from "../../../library";
import {CopyOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {deepCopy, getPATTokenDetails, getUserDetails} from "../../../deps";
import { columns, tempData } from "./columns";
import "./styles.less";
import {Button, Modal, notification, Space, Tooltip} from 'antd';
import ReactJson from "react-json-view";
import {Menus} from "../../../assets";
import Skeleton from "react-loading-skeleton";
import searchIcon from "../../gasless/whitelisted-contracts/search-icon/searchicon.svg"



export const onCopy = (apiKey) => {
  navigator.clipboard.writeText(apiKey);
  notification.success({
    message: "Copied to clipboard",
    duration: 3,
  });
};
export const Transaction = ({ showLoader,isLoading }) => {
  const [pageSetting, setSetting] = useState({
    page: 1,
    itemsPerPage: 10,
    searchText: "",
    filterValue: "",
  });
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hitsList, setHitsList] = useState([]);
  const [totalCount, setTotalCount] = useState(-1);
  const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
  const [isOpen, setIsOpen] = React.useState(false);
  const [searchExpanded, setSearchExpanded] = useState(false);
  const searchBoxRef = useRef(null);
  const [responseJson,setResponseJson] = React.useState(null);
  const {
    profile: { subscriptionId },
  } = getUserDetails();

  useEffect(() => {
    getTransactionsList();
    setHitsList([3, 6, 9, 12, 15]);
  }, []);

  useEffect(() => {
    onFilterDataChange();
  }, [data, pageSetting]);

  console.log("apidata",data)

  const getTransactionsList = async () => {
    const Options = () => {
      setHitsList([3, 6, 9, 12]);
      return {
        select: [],
        sort: {},
        page: pageSetting.page - 1,
        limit: pageSetting.itemsPerPage,
      };
    };
    showLoader(true);
    const filter = pageSetting.searchText !== "" ?{ value: pageSetting.searchText}: {value: null}


    try {
      const response = await fetchApi({
        method: "GET",
        url: `developer/dashboard/activity-list?options=${JSON.stringify(Options())}&filter=${JSON.stringify(filter)}`,
        isDevApp: false,
      });
      if (response.statusCode === 200) {
        setData(response.docs);
        showLoader(false);
        setTotalCount(response.meta.totalDocs)
      }
    }catch (error) {
      // TypeError: Failed to fetch
      showLoader(false);
      notification.error({
        message: error.toString(),
        duration: 3,
      });
    }
  };
  const toggleState=()=>{
    setIsOpen(false)
    setResponseJson(null)

  }
  const showPopup=(response)=>{
    if(typeof response === "string"){
      response = JSON.parse(response);
    }
    setResponseJson(response);
    setIsOpen(true)
  }
  const onFilterDataChange = () => {
    const endIndex =
      pageSetting.page * pageSetting.itemsPerPage > data.length
        ? data.length
        : pageSetting.page * pageSetting.itemsPerPage;

    const filterList = (
      pageSetting.searchText
        ? data.filter((item) =>
            item.service
              .toLowerCase()
              .includes(pageSetting.searchText.toLowerCase())
          )
        : deepCopy(data),
    pageSetting.searchText
        ? data.filter((item) =>
            item.methodName
                .toLowerCase()
                .includes(pageSetting.searchText.toLowerCase())
        )
        : deepCopy(data)
    )
      .filter((item) =>
        pageSetting.filterValue !== ""
          ? (pageSetting.filterValue === "locked" && item.isLocked) ||
            (pageSetting.filterValue === "unlocked" && !item.isLocked)
          : true
      )
      .slice(startIndex, endIndex);
    setFilteredData(filterList);
  };

  const onTextChange = (value) => {
    pageSetting.searchText = value;
    setSetting({ ...pageSetting });
    getTransactionsList();
  };

  const onRadioChange = (value) => {
    pageSetting.filterValue = value;
    setSetting({ ...pageSetting });
  };

  const onPageChange = (pageNumber) => {
    pageSetting.page = Number(pageNumber);
    setSetting({ ...pageSetting });
    getTransactionsList();
  };

  const onPerPageChange = (value) => {
    pageSetting.itemsPerPage = Number(value);
    pageSetting.page = 1;
    setSetting({ ...pageSetting });
    getTransactionsList();
  };

  const last =
    pageSetting.page * pageSetting.itemsPerPage > totalCount
      ? totalCount
      : pageSetting.page * pageSetting.itemsPerPage;

  useEffect(() => {
    function handleClickOutside(event) {
      if (searchBoxRef.current && !searchBoxRef.current.contains(event.target) && pageSetting.searchText === "") {
        setSearchExpanded(false);
      }
    }

  document.addEventListener("mousedown", handleClickOutside);
  return () => {
    document.removeEventListener("mousedown", handleClickOutside);
  };
}, [pageSetting.searchText]);

  return (

    <div className="transaction-page-container">
      {/*{isLoading?(<Skeleton height={55} style={{marginBottom:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(<>*/}
      <div className="transaction-header-section mb-n3">
        <div className="header-section">
          <div>
            <p></p>
            <p style={{fontSize: '23px', color: 'white', lineHeight: '35px', fontWeight: '400'}}>Transaction summary for
              API methods, calls, and credit usage</p>
          </div>
          {/*<div className="text">API History</div>
          <Tooltip title="This is a tabular summary of the Transaction taking place wih key details like API Method, API Calls used, Credit used etc">
            <InfoCircleOutlined style={{ fontSize: "15px", color: "#D9D9D9",cursor:"pointer" }} />
          </Tooltip>*/}
        </div>
        <div>
          <div className={`search-container-gasless ${searchExpanded ? 'expanded' : ''}`} ref={searchBoxRef}>
            <img
                src={searchIcon}
                alt="Search Icon"
                className="search-expand-icon"
                onClick={() => { setSearchExpanded(true); }}
            />
            {searchExpanded && (
        <Filters
          showCreateBtn={false}
          showFilters={false}
          placeHolder="Search"
          value={pageSetting.searchText}
          selectedValue={pageSetting.filterValue}
          onRadioChange={onRadioChange}
          onTextChange={onTextChange}
          radioOptions={[
            { value: "locked", text: "Lock Projects" },
            { value: "unlocked", text: "UnLock Projects" },
            { value: "", text: "See All" },
          ]}
          style={{ marginRight: '10px' }}
        />
                )}
          </div>
        </div>
      </div>
      {/* <div className="mt-n4" style={{marginLeft:"auto",paddingRight:'37px'}}><i className="warning-content">Search By Service,API Method,Request ID</i></div> */}
      {/*</>)}*/}
      <div className="table-container">
        {/* <Table rowKey="projectId" columns={columns} data={filteredData} /> */}
        {isLoading?(<Skeleton height={200} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <TableContent
            pagination={false}
            rowKey="transactionId"
        data={data}
        columns={columns(showPopup)}
        isDashboard={false}
      />
        )}
        {/*{totalCount === 0 && <div className="nodata">No data</div>}*/}
        <PaginationSection
            last={last}
            first={startIndex + 1}
            hitsList={hitsList}
            totalCount={totalCount}
            page={pageSetting.page}
            itemsPerPage={pageSetting.itemsPerPage}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
        />
      </div>
      <Modal title="" footer={null} closable={false} open={isOpen} style={{ top: 100 }}
             bodyStyle={{ maxHeight: '80vh', overflow: 'auto' }}
             width={900} maskStyle={{backgroundColor: '#CFCFCF80'}}>
        <div className="nft-collection-popup">
          <div className="close-icon" onClick={toggleState}>
            <Menus type="close" />
          </div>
          <div className="popup-text">API Response</div>
          <div style={{display:"flex",alignItems:"flex-end",justifyContent:"flex-end"}}><CopyOutlined onClick={() => onCopy(JSON.stringify(responseJson,null,2))} /></div>
            <ReactJson
                style={{backgroundColor:"white",marginTop:20,overflowX:"auto"}}
                src={responseJson}
                enableClipboard={true}
                name={null}
            />
        </div>
      </Modal>
    </div>


  );
};


import React from 'react'
import { Route, Routes } from 'react-router-dom';
import Dashboard from './Dashboard/Dashboard';
import {
    getUserDetails,
    ENTERPRISEADMINROLE,
    DEVELOPERROLE,
    ENTERPRISEDEVELOPERROLE,
  } from "../../deps";
import DappDetailsPage from "./DappDetails";
import {SmartContractPage} from "./SmartContract";
import {ChooseTemplate} from "./SmartContract/chooseTemplate";
import {CreateSmartContract} from "./SmartContract/create";
import {DeployContracts} from "./SmartContract/deployContract";
import DataLakePage from "./DataLake";
import {MyEndpointsPage} from "./MyProjects/my-endpoints";
import {MyDappsPage} from "./MyProjects/my-dapps";
import {CreateApps} from "../CreateApps";
import {EndpointsDetails} from "./Endpoints/EndpointDetails";

import {NcWalletPage} from "./NcWallet";
import {NcWalletDetailsPage} from "./NcWallet/NcWalletDetails";
import AccountSettings from "./MyAccount/profile/index";
import Support from "../support/support";
import {Transaction} from "./ApiHistory";
import {GaslessManager} from "../gasless";
import {ManageContractPage} from "../gasless/whitelisted-contracts/manageContractPage";
import {RequestGasLimit} from "../gasless/protocol-gasLimit-config/requestGasLimit";
import {Teams} from "./enterprise";
import {ManageTeam} from "./enterprise/manageTeam";
import {DAppEndpointsDetails} from "./Endpoints/dapp-endpointdetails";

const Router = (props) => {
    const {
        profile: { userRole },
      } = getUserDetails();

    // if (userRole === DEVELOPERROLE || userRole === ENTERPRISEDEVELOPERROLE) {
        return (<>
            <Routes>
                <Route path='/dashboard' element={<Dashboard  {...props}/>}/>
                <Route path='/my-endpoints' element={<MyEndpointsPage  {...props}/>}/>
                <Route path='/my-dapps' element={<MyDappsPage  {...props}/>}/>
                <Route path='/manage-endpoints' element={<MyEndpointsPage  {...props}/>}/>
                <Route path='/my-endpoints/endpointsDetails' element={<EndpointsDetails  {...props}/>}/>
                <Route path='/dapp-endpointsDetails' element={<DAppEndpointsDetails  {...props}/>}/>
                <Route path='/my-dapps/dAppDetails-page/:id' element={<DappDetailsPage  {...props}/>}/>
                <Route path='/smart-contract' element={<SmartContractPage  {...props}/>}/>
                <Route path='/smart-contract/template' element={<ChooseTemplate  {...props}/>}/>
                <Route path='/smart-contract/contract-templates/create/:contract' element={<CreateSmartContract  {...props}/>}/>
                <Route path='/smart-contract/contract-templates/:id' element={<DeployContracts  {...props}/>}/>
                <Route path='/datalake' element={<DataLakePage  {...props}/>}/>
                <Route path='/account-settings' element={<AccountSettings  {...props}/>}/>
                <Route path='/support' element={<Support  {...props}/>}/>
                <Route path='/apiHistory' element={<Transaction {...props}/>}/>
                <Route path='/gasless' element={<GaslessManager {...props}/>}/>
                <Route path="/gasless/manageContractPage" element={<ManageContractPage {...props} />} />
                <Route path="/gasless/enableGasless" element={<RequestGasLimit {...props} />} />
                <Route path="/teams" element={<Teams {...props} />} />
                <Route path="/manage-teams" element={<ManageTeam {...props} />} />
                <Route
                    path="/project/create-apps"
                    element={<CreateApps {...props} />}
                />
                <Route path='/nc-wallet' element={<NcWalletPage  {...props}/>}/>
                <Route path='/ncWalletDetails-page' element={<NcWalletDetailsPage  {...props}/>}/>
                <Route
                    path="/*"
                    element={
                        <main style={{ padding: "1rem" }}>
                            <p>There's nothing here!</p>
                        </main>
                    }
                />
            </Routes>
        </>);
      // } else if (userRole === ENTERPRISEADMINROLE) {
      //   return (
      //     <Routes>
      //       <Route path="/new-dashboard" element={<Dashboard {...props} />} />
      //     </Routes>
      //   );
      // } else {
      //   return (
      //     <Routes>
      //       <Route path="/new-dashboard" element={<Dashboard {...props} />} />
      //     </Routes>
      //   );
      // }
}

export default Router

import React, { useState } from "react";
import {CopyOutlined, LeftOutlined, RightOutlined} from "@ant-design/icons";
import {Tooltip, notification} from "antd";

export const CarouselBlockchain = ({ items }) => {
    const [index, setIndex] = useState(0);
    // console.log("items--->",items)
    const onNext = () => {
        if (index + 3 < items.length) {
            setIndex(index + 1);
        }
    };
    const onPrev = () => {
        if (index > 0) {
            setIndex(index - 1);
        }
    };
    const onCopy = (url) => {
        navigator.clipboard.writeText(url);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };

    return (
        <>
            {/* Headings */}
            <div className="endpoint-column-container">
                <div className="endpoint-col">
                    <div className="endpoint-text" style={{paddingBottom:'0px'}}>Endpoint Name</div>
                </div>
                <div className="endpoint-col">
                    <div className="endpoint-text" style={{paddingBottom:'0px'}}>Protocol Name</div>
                </div>
                {items?.IPFSGateway_URL !== undefined && (
                    <div className="endpoint-col-url">
                        <div className="endpoint-text" style={{paddingBottom:'0px'}}>GateWay URL</div>
                    </div>
                )}
                <div className="endpoint-col-url">
                    <div className="endpoint-text" style={{paddingBottom:'0px',marginTop:'-10px'}}>User Endpoint</div>
                </div>
            </div>

            {/* Items */}
            {items ? items.map((item, index) => (
                <div key={index} className="endpoint-column-container">
                    <div className="endpoint-col">
                        <div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.EndpointName}</div>
                    </div>
                    <div className="endpoint-col">
                        <div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.Protocol}</div>
                    </div>
                    {items?.IPFSGateway_URL !== undefined && (
                        <>
                            <div className="endpoint-col-url">
                                <div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.IPFSGateway_URL}</div>
                            </div>
                            <div className="endpoint-copy-col" style={{marginTop:'-15px'}}>
                                <CopyOutlined onClick={() => onCopy(item?.IPFSGateway_URL)} />
                            </div>
                        </>
                    )}
                    <div className="endpoint-col-url">
                        <Tooltip title={item?.RPC_URL}><div className="endpoint-sub-text" style={{marginTop:'-10px',paddingBottom:'0px'}}>{item?.RPC_URL}</div></Tooltip>
                    </div>
                    <div className="endpoint-copy-col" style={{marginTop:'-15px'}}>
                        <CopyOutlined onClick={() => onCopy(item?.RPC_URL)} />
                    </div>
                </div>
            )) : (
                <div className="service-not-available-label">
                    Endpoint not selected!
                </div>
            )}
        </>
    );
};
import React from "react";
import Header from "./ui/Header.js";
import Banner from "./ui/Banner.js";
import Clientlogo from "./ui/Clientlogo.js";
import Pricingplan from "./ui/Plan.js";
import { useAuth0 } from "@auth0/auth0-react";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import NewFooter from "./ui/landing_page_new_footer";
import './enterprisePagestyles.less'
import Platform from "./ui/platform";
import Faster from "./ui/10x-section";
import {GetInvolved} from "./ui/GetInvolved";
import DappDevelopmentForEnterprise from "./ui/dappdevelopmentEnterprise";
import {Helmet} from "react-helmet";

export const EnterprisePage = () => {

  const navigate = useNavigate();

  const { isAuthenticated, user } = useAuth0();

  useEffect(() => {
    AuthenticatiedUser()
      window.scrollTo(0, 0);
  }, [])

  const AuthenticatiedUser = () => {
    // if (isAuthenticated) { 
    //   navigate("/dashboard")
    // }
  }

  return (<>
  <Helmet>
      <meta charSet="utf-8" />
      <title>Web3 For Enterprises - Krypcore's Advanced dApp Solutions</title>
      <meta name="description" content="Empower your enterprise for the decentralized app future with Krypcore. Discover the ultimate API for building Web3 wallets and accessing comprehensive blockchain data." />
      <link rel="canonical" href="http://mysite.com/example" />
  </Helmet>
    <div className="enterprise_responsive">
      <Header />
      <Banner />
      <Clientlogo />
      <DappDevelopmentForEnterprise/>
      <Faster/>
      <Platform/>
      <Pricingplan />
      <GetInvolved/>
      <NewFooter />
    </div>
  </>);
};

import React from "react";
import {useAuth0} from "@auth0/auth0-react";
import {DEVELOPERROLE} from "../../deps";
import Rectangle from './Icons/Group 138 .svg'
import fetchApi from "../../_config/api";
import {notification} from "antd";
import {useLocation} from "react-router-dom";
import { useInView } from "react-intersection-observer";

export const Banner = () => {
  const { loginWithRedirect } = useAuth0();
  const onViewDocs = () => {
   window.open(process.env.REACT_APP_LANDING_PAGE_DOCS_URL)
  };
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("referrer");
  const onNavigateSignup = () => {
      if(referralCode != null && referralCode !== ""){
          fetchApi({
              method: "POST",
              url: `auth/validateReferer`,
              isDevApp: false,
              data: { referralCode },
          })
              .then((response) => {
                  //console.log("ref--",response)
                  if ( response.statusCode !== 200) {
                      notification.error({
                          message: response.message,
                          duration: 3,
                      });

                  }else {
                      const {emailId: referralEmail } = response?.doc;
                      loginWithRedirect({
                          screen_hint: 'signup',
                          appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                          userRole: DEVELOPERROLE,
                          userType: "INDIVIDUAL",
                          referralCode,
                          referralEmail
                      });
                  }
              })
              .catch((err) => {
                  // console.log(err);
              });
      }else{
          loginWithRedirect({
              screen_hint: 'signup',
              appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
              userRole: DEVELOPERROLE,
              userType: "INDIVIDUAL",
              referralCode: ""
          });
      }
  };

  const { ref, inView } = useInView({
    triggerOnce: true, // Only trigger animation once
    threshold: 0.5 // Trigger animation when 50% of the element is visible
  });


  return (
    <>
      <div className="Bannersection-wrap" id="home">
      <div class="container-fluid  ">
        <div  className="row  ">
            <div class="col-md-12 col-xl-6 col-xs-12 col-sm-12 col-12  text-dark">
              <div className="banner-head-container">

              {/*<div className="banner-heading">*/}
                <div ref={ref} className={`banner-text ${inView ?'animated animatedFadeInUp fadeInUp':''}`}>
                  <h2 className="banner-highlight">Accelerate Your <br/> Web3 Development Journey <br/> </h2>
                </div>
                <p className="banner-para">
                Leverage our powerful SDK and APIs to effortlessly build innovative dApps. Remove the complexities of web3 and blockchain development with our robust low-code/ no-code toolkit
                </p>

                <div className="banner-button-section">
                  <button className="banner_btn" onClick={() => onNavigateSignup()}>
                    Get Started
                  </button>
                  <button className="docs_btn" onClick={() => onViewDocs()}>
                    <span className="banner-docs" >
                      {/* <svg viewBox="64 64 896 896" focusable="false" data-icon="file-text" width="1em" height="1em" fill="black" aria-hidden="true"><path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path></svg> */}
                      View Docs
                    </span>
                  </button>
                </div>
              {/*</div>*/}


              </div>
            </div>
            <div class="col-md-0 col-xl-6 col-xs-0 col-sm-0 col-0 d-flex pr-0 justify-content-end  text-dark">
              <div className={`${inView ?'BannerRightSection':'hidden'}`} >
                <img ref={ref} src={Rectangle} alt="Accelerate-Your-Web3-Development-Journey" className={`newlandingpage-rectangle  ${inView ?'animate fadeInRight four':''}`}/>

              </div>
            </div>
          </div>
      </div>    
          


        

      </div>

    </>
  );
};

export default Banner;

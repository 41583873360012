import React from "react";

export const DashboardProject = ({ width = "20px", height = "20px" }) => (
    /*<svg
      width={width}
      height={height}
      viewBox="0 0 18 22"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M15.75 13.25C15.75 13.6642 16.0858 14 16.5 14C16.9142 14 17.25 13.6642 17.25 13.25H15.75ZM5.25 0.5C4.83579 0.5 4.5 0.835786 4.5 1.25C4.5 1.66421 4.83579 2 5.25 2V0.5ZM15.375 20H2.625V21.5H15.375V20ZM2.25 19.625V2.375H0.75V19.625H2.25ZM2.625 2H7.5V0.5H2.625V2ZM15.75 10.25V19.625H17.25V10.25H15.75ZM7.5 2C12.0563 2 15.75 5.69365 15.75 10.25H17.25C17.25 4.86522 12.8848 0.5 7.5 0.5V2ZM2.625 20C2.41789 20 2.25 19.8321 2.25 19.625H0.75C0.75 20.6605 1.58947 21.5 2.625 21.5V20ZM15.375 21.5C16.4105 21.5 17.25 20.6605 17.25 19.625H15.75C15.75 19.8321 15.5821 20 15.375 20V21.5ZM2.25 2.375C2.25 2.16789 2.41789 2 2.625 2V0.5C1.58947 0.5 0.75 1.33947 0.75 2.375H2.25ZM17.25 13.25V10.625H15.75V13.25H17.25ZM13.125 6.5H11.625V8H13.125V6.5ZM11.25 6.125V4.625H9.75V6.125H11.25ZM7.125 0.5H5.25V2H7.125V0.5ZM11.25 4.625C11.25 2.34683 9.40317 0.5 7.125 0.5V2C8.57475 2 9.75 3.17525 9.75 4.625H11.25ZM17.25 10.625C17.25 8.34683 15.4032 6.5 13.125 6.5V8C14.5747 8 15.75 9.17525 15.75 10.625H17.25ZM11.625 6.5C11.4179 6.5 11.25 6.33211 11.25 6.125H9.75C9.75 7.16053 10.5895 8 11.625 8V6.5Z"
        fill="#E3E3E3"
      />
    </svg>*/
    <svg width="37" height="37" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path d="M14.5 7.596C14.5 4.72233 14.5 3.28548 13.6213 2.39274C12.7427 1.5 11.3284 1.5 8.5 1.5H7C4.17157 1.5 2.75736 1.5 1.87868 2.39274C1 3.28548 1 4.72233 1 7.596C1 10.4697 1 11.9066 1.87868 12.7993C2.23089 13.1571 2.66912 13.3715 3.25 13.5" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round"/>
        <path d="M16 13.125C16 11.7188 16 11.0158 15.6419 10.5229C15.5262 10.3637 15.3863 10.2238 15.2271 10.1081C14.7342 9.75 14.0312 9.75 12.625 9.75H8.875C7.46883 9.75 6.76578 9.75 6.27291 10.1081C6.11373 10.2238 5.97374 10.3637 5.85809 10.5229C5.5 11.0158 5.5 11.7188 5.5 13.125C5.5 14.5312 5.5 15.2342 5.85809 15.7271C5.97374 15.8863 6.11373 16.0262 6.27291 16.1419C6.76578 16.5 7.46883 16.5 8.875 16.5H12.625C14.0312 16.5 14.7342 16.5 15.2271 16.1419C15.3863 16.0262 15.5262 15.8863 15.6419 15.7271C16 15.2342 16 14.5312 16 13.125Z" stroke="#7D7575" stroke-width="1.5"/>
        <path d="M11.875 12L12.5649 12.5947C12.855 12.8447 13 12.9697 13 13.125C13 13.2803 12.855 13.4053 12.5649 13.6553L11.875 14.25" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M9.625 12L8.93508 12.5947C8.64505 12.8447 8.5 12.9697 8.5 13.125C8.5 13.2803 8.64505 13.4053 8.93508 13.6553L9.625 14.25" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
        <path d="M1.375 4.5H14.125" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

import React, { useEffect, useState } from 'react'
import { Link, useLocation, useParams } from 'react-router-dom'
import './blogsuistylings.less'
import axios from 'axios';
import { Spin } from "antd";
import {Helmet} from "react-helmet";
import {TwitterTweetEmbed} from 'react-twitter-embed'
import Header from '../new-light-theme/ui/Header';
import Footer from '../new-light-theme/ui/Footer';
import { DEVELOPERROLE, dateformatter } from '../../deps';
import KrypLogo from '../new-landing-page/Icons/krypcoresemilogo.svg'
import { useAuth0 } from '@auth0/auth0-react';
import Skeleton from 'react-loading-skeleton';



const BlogData = () => {
  const {id}=useParams()
  console.log(id)

  const [posts1, setPosts1] = useState([]);
  const [posts2, setPosts2] = useState();
  const [posts3, setPosts3] = useState([]);
  const [preview,setPreview]=useState('')
 

  const { loginWithRedirect } = useAuth0();
  const onViewDocs = () => {
   window.open(process.env.REACT_APP_LANDING_PAGE_DOCS_URL)
  };
  const onNavigateSignup = () => {
 
    loginWithRedirect({
      screen_hint: 'signup',
      appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
      userRole: DEVELOPERROLE,
      userType: "INDIVIDUAL",
    });

  };


    // const location=useLocation().state?.data
    // console.log(location)
  

    useEffect(()=>{
      fetchData1()
    },[])

    const fetchData1 =  () => {
      axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts?slug=${id}`)
      .then(response=>{
        if(response.data !==''){
          setPosts1(response.data);
          const data1=response.data[0].content
          // setPreview(data1)
          if(data1.rendered !==''){
            const regex = /<img.*?src=['"](.*?)['"]/;
            const match = data1.rendered.match(regex);
            setPreview(match[1])
          }
          // const withoutPTags = response.data?.excerpt.replace(/<p>|<\/p>/g, '')
          // setPosts2(withoutPTags)
          axios.get(`https://krypcoreblogs.krypc.com/wp-json/wp/v2/posts`)
          .then(response=>{
            if(response.data !==''){
              const FilterData=response.data.filter((data)=>data.slug !==id)
              setPosts3(FilterData.slice(0,2))
            }
          })
          .catch(err=>{
            console.log(err)
          })

        }
      })
      .catch (err=>{
        console.error('Error fetching data:', err);
      })  
  };
  
  const parseContent = (html) => {

    const tempElement = document.createElement('div');
    tempElement.innerHTML = html;


    const anchorTags = tempElement.getElementsByTagName('a');

    for (let i = 0; i < anchorTags.length; i++) {
      const anchorTag = anchorTags[i];

      if (anchorTag.getAttribute('href') && anchorTag.getAttribute('href').includes('krypcore.com')) {
        // Exclude Krypcore
      } else {
        anchorTag.setAttribute('rel', 'nofollow');
      }
    }

    return tempElement.innerHTML;
  };
 
  const removePTags = (html) => {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const paragraphs = doc.querySelectorAll('p');
    
    paragraphs.forEach((p) => {
      const parent = p.parentNode;
      while (p.firstChild) {
        parent.insertBefore(p.firstChild, p);
      }
      parent.removeChild(p);
    });

    return doc.body.innerHTML;
  };

  const dynamicHTMLContent=removePTags(posts1[0]?.excerpt.rendered)

  const extractAndEmbedTwitterIds = (content) => {
    // Replace this regex with the one that suits your needs for extracting Twitter tweet IDs
    const twitterIds = content.match(/twitter\.com\/[a-zA-Z0-9_]+\/status\/(\d+)/g);

    if (twitterIds) {
      // Replace or add the TwitterTweetEmbed tag with the extracted Twitter tweet IDs
      let updatedContent = content;
      twitterIds.forEach((id) => {
        const tweetId = id.match(/(\d+)$/)[1];
        const twitterEmbedTag = `<TwitterTweetEmbed tweetId="${tweetId}" />`;
        updatedContent = updatedContent.replace(id, twitterEmbedTag);
      });

    }

    return content;
  };

  const decodeHtmlEntities = (html) => {
    const el = document.createElement('p');
    el.innerHTML = html;
    return el.innerText || el.textContent;
  };

  const decodeHtmlEntities1 = (html) => {
 
    const tempElement = document.createElement('p');
    tempElement.innerHTML = html;

 
    const paragraphElement = tempElement.querySelector('p');
    if (paragraphElement) {
      const textToRemove = '[…]'
       paragraphElement.innerHTML = paragraphElement.innerHTML.replace(textToRemove, '...');
    }


    const modifiedContent = tempElement.innerHTML;

    return modifiedContent;
    };

    const extractImageFromContent = (content) => {
      // Use a regex or DOMParser to extract the image from the HTML content
      const regex = /<img.*?src=['"](.*?)['"]/;
      const match = content.match(regex);
      return match ? match[1] : null;
      // console.log(match)
    };

    

  
  
  

  return (<>
    {posts1.length>0?
       <Helmet>
          <meta charSet="utf-8" />
          <title>{posts1[0].title?.rendered}</title>
          <meta name="description" content={dynamicHTMLContent} data-react-helmet="true"  />
          <meta property="og:image" itemprop="image" content={preview}/>
      </Helmet>:null}
    <div className="enterprise_responsive">
    <div className="banner-bg">
      <Header/>
      
      <div className="dapp-sectionb">
      {posts1.length>0?
      <>
         {/* <h1 className="dapp-heading"><span className="dapp-highlight">All Krypc Blogs</span></h1> */}
            <div className="dapp-card-container1">
              <div className="dapp-card1">
                <div className="dapp-card-content3">
                  <div className='individual-blog-header'>
                    <div>
                      <h1 className="subheading-highlight1">{decodeHtmlEntities(posts1[0].title?.rendered)}</h1>
                    <div className='d-flex align-items-center' >
                      <img src={KrypLogo} alt='' style={{width:'60px',height:'60px',marginTop:'0',marginRight:'10px'}}/>
                      <div className='d-flex flex-column'>
                        <span className='blogTitle'>Krypcore</span>
                        <span className='blogTitle1'>{dateformatter(posts1[0].date,  "mmmm dS")}</span>
                      </div>
                      
                    </div>
                    </div>
                  
                  </div>
                   
                    <p dangerouslySetInnerHTML={{ __html:parseContent(posts1[0].content.rendered)  }}/>
                    <TwitterTweetEmbed tweetId={extractAndEmbedTwitterIds(posts1[0].content.rendered)} />
                    <div className="banner-head-container1">
                        <div className="banner-text">
                          <span className="banner-highlight">Effortlessly build dApps </span>
                        </div>
                        {/* <p className="banner-para">
                        Krypcore web3 provides a powerful toolkit designed to streamline the development of decentralized applications.  
                        </p> */}

                        <div className="banner-button-section1">
                          <button className="banner_btn" onClick={() => onNavigateSignup()}>
                            Get Started
                          </button>
                          <button className="docs_btn" onClick={() => onViewDocs()}>
                            <span className="banner-docs" >
                              {/* <svg viewBox="64 64 896 896" focusable="false" data-icon="file-text" width="1em" height="1em" fill="black" aria-hidden="true"><path d="M854.6 288.6L639.4 73.4c-6-6-14.1-9.4-22.6-9.4H192c-17.7 0-32 14.3-32 32v832c0 17.7 14.3 32 32 32h640c17.7 0 32-14.3 32-32V311.3c0-8.5-3.4-16.7-9.4-22.7zM790.2 326H602V137.8L790.2 326zm1.8 562H232V136h302v216a42 42 0 0042 42h216v494zM504 618H320c-4.4 0-8 3.6-8 8v48c0 4.4 3.6 8 8 8h184c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8zM312 490v48c0 4.4 3.6 8 8 8h384c4.4 0 8-3.6 8-8v-48c0-4.4-3.6-8-8-8H320c-4.4 0-8 3.6-8 8z"></path></svg> */}
                              View Docs
                            </span>
                          </button>
                        </div>
                    </div>
                </div>
              </div>
            </div>
            <div className='d-flex justify-content-center'>
            <div className="dapp-card-containerI">
              {posts3.length>0?
              <>
               {posts3.map((data)=>{
                return(
                    <div className="dapp-cardI" key={data.id}>
                        <Link to={{pathname:`/blog/${data.slug}`,state:{data:data}}} target="_blank" >
                            <div className="dapp-card-imgI">
                            <img src={extractImageFromContent(data.content.rendered)} className='blogViewImgI' alt="Post Image"/>
                            </div>
                            <div className="dapp-card-contentI">
                                 <h4 className="subheading-highlightI">{decodeHtmlEntities(data.title?.rendered)}</h4>
                                <p dangerouslySetInnerHTML={{ __html: decodeHtmlEntities1(data.excerpt.rendered) }}/>
                                <p className='mt-1'>Posted on <strong>{dateformatter(data.date, "mmmm dS")}</strong></p>
                            </div>
                        </Link>
                    </div>
                )
             })}
              </>:null}
            </div>

            </div>
            
            </>
            :<div className='skelonloader3'>

        
                  <Skeleton className='skelonloader3l' baseColor="#fff" highlightColor="#e1e1e1" ></Skeleton>
             </div>
                }
        </div>
        </div>
       
        <Footer/>
    </div>
  </>)
}

export default BlogData
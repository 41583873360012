import React from "react";

export const Transfer = ({ width = "20px", height = "20px" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" className="icon icon-tabler icon-tabler-transfer-out" width="20" height="20"
         viewBox="0 0 20 20" strokeWidth="2" fill="none" stroke="#ffffff" strokeLinecap="round"
         strokeLinejoin="round">
        <path  d="M0 0h24v24H0z" fill="none" stroke="#ffffff"/>
        <path d="M4 19v2h16v-14l-8 -4l-8 4v2"/>
        <path d="M13 14h-9"/>
        <path d="M7 11l-3 3l3 3"/>
    </svg>
);

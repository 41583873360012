import React, {useEffect} from "react";
import Logo from "../../../assets/logos/logo.avif";
import { useNavigate } from "react-router-dom";
import { useAuth0 } from "@auth0/auth0-react";
import { Link } from "react-router-dom";

import {notification, Tooltip} from "antd";
import {DEVELOPERROLE, removeAllDataInLocal} from "../../../deps";
import {MenuFoldOutlined} from "@ant-design/icons";
import './style.less'

export const Header = () => {
  const navigate = useNavigate();
  const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL

  const navigateHome = (value) => {
    navigate("/",{state:{value:value}})
  };
  const logout_ = () => {
    logout({
      returnTo: window.location.origin,
    });
    removeAllDataInLocal();
  };
  const { loginWithRedirect, isAuthenticated, logout, user } = useAuth0();

  const onNavigateDeveloper = () => {
    loginWithRedirect({
      appState: { role: DEVELOPERROLE, type: "INDIVIDUAL" },
      userRole: DEVELOPERROLE,
      userType: "INDIVIDUAL",
    });

  };
  
  useEffect(() => {
    if (isAuthenticated && user.email_verified === false) {
      logout_();
      notification.error({
        message: "Please Verify Email!",
        duration: 10,
      });
    }
  }, [isAuthenticated]);
  const open = (value) => {
    window.open(value)
  };


  const navigateToBlog = (value) => {
    navigate('/blog')
  };

  const onNavigatekcsensor = () => {
    navigate('/krypcoreSensor')
  };


  const tooltipStyle = {
    background: "white", // Set the background color to white
    color: "black", // Set the text color
    // Add more styles as needed
  };

  return (
    <>
      <div className="header-wrap">
          <nav className="navbar navbar-expand-lg">
            <div className="navbar-logo-division">
              <Link className="navbar-brand" to="/" aria-label="">
                <img src={Logo} alt="KrypC" className="logo" />
              </Link>
            </div>
            <button
              className="navbar-toggler"
              type="button"
              data-toggle="collapse"
              data-target="#navbarSupportedContent"
              aria-controls="navbarSupportedContent"
              aria-expanded="false"
              aria-label="Toggle navigation"
            >
              <span className="navbar-toggler-icon"><MenuFoldOutlined style={{color:"#fff"}}/></span>
            </button>

            <div
              className="collapse navbar-collapse"
              id="navbarSupportedContent"
            >
              <ul className="navbar-nav ml-auto">

                <li className="nav-item">
                  <a className="nav-link" href="/">
                    Home
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" onClick={()=>navigateHome('start-with-krypCore')}>
                    Features
                  </a>
                </li>
                {/*<li className="nav-item">
                  <a className="nav-link" onClick={()=>navigateHome("pricing_works")}>
                    Plans
                  </a>
                </li>*/}
                <li className="nav-item">
                  <a className="nav-link" href="/pricing" >
                    Pricing
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href="/enterprise">
                    Enterprise
                  </a>
                </li>
                <li className="nav-item">
                  <a className="nav-link" href={blogs} title={"Blogs"}>

                    Blog
                  </a>
                </li>
                <li className="nav-item">
                  <a className={window.location.pathname.includes('/contact-us') ? 'nav-link active-link' : 'nav-link'} href="/contact-us">
                    Contact Us
                  </a>
                </li>

              </ul>
              <ul className="navbar-nav menu-right ml-auto d-flex align-items-center">
                {/*<li className="nav-item" >
                  <Tooltip  title={"On-Premise Tool for Cryptoforensics"} effect="solid"
                            style={tooltipStyle}>
                    <button className="header-signupbtn"  onClick={() => onNavigatekcsensor()}>
                      Krypcore Sensor
                    </button>
                  </Tooltip>
                </li> &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp; &nbsp;&nbsp;*/}
                <li>
                  {isAuthenticated ? (
                      <button
                          className="btn header-signupbtn"
                          onClick={() => logout_()}
                      >
                        Log Out
                      </button>
                  ) : (
                      <div>
                        &nbsp;&nbsp;
                        <span
                            className="text-white"
                            onClick={() => onNavigateDeveloper()}
                        >
                      Login
                    </span>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <button
                            className="header-signupbtn"
                            onClick={() => navigateHome('pricing_works')}
                        >
                          Get Started
                        </button>

                      </div>
                  )}
                </li>
              </ul>
            </div>
          </nav>
      </div>
    </>
  );
};

export default Header;
import React from "react";
import {Input, Tooltip} from "antd";
import { dateformatter } from "../../deps";

import "./styles.less";
import TruncateText from "../../library/TrunucateText/truncate";
import Skeleton from "react-loading-skeleton";
import {CopyOutlined} from "@ant-design/icons";
import {Icons} from "../../assets/icons";
import addIcon from "../../assets/icons/Mask group.svg";


export const Card = ({ item, onCopy, onExport, onNavigate }) => {
  const onClick = () => {
    onNavigate(item?.projectID);
  };
  return (
    <div className="card-box-container">
      <div className="name-container">
        {/*<div className="icon-container">{item.projectName.substring(0, 1)}</div>*/}
        <div className="name-section">
            <Tooltip title={item.projectName}><div className="project-name"><TruncateText text={item.projectName} maxLength={15}/></div></Tooltip>
            <Tooltip title={item.description}>
                <p className="text-left project-desc"><TruncateText text={item.description} maxLength={70}/></p>
            </Tooltip>

            <p className="project-id mb-0"><strong>{`APP ID: `}</strong> {item.projectID}<CopyOutlined className="float-right" onClick={() => onCopy(item.projectID)} /></p>
            <hr className="project-hr"/>
            {/*<div className="project-id">{item.projectID}</div>
              <div className="date-section">
                {dateformatter(item.createdAt, true)}
              </div>*/}
        </div>
      </div>
      <div className="description-section">
          {/*<Tooltip title={item.description} ><span>
             <TruncateText text={item.description} maxLength={35}/>
         </span></Tooltip><br/><br/>
          {item.teamId !== null && item.teamId !== undefined && <>
              <span><b>Team ID</b> - {item.teamId}</span><br/>
          </>
          }*/}
         {/* <span><b>Protocol Name</b></span><br/>
          <span>{item.endpoints.BlockchainEndpoint?.Protocol} - {item.endpoints.BlockchainEndpoint?.EndpointName}</span><br/>
          <span>{item.endpoints.DIEndpoint?.Protocol} - {item.endpoints.DIEndpoint?.EndpointName}</span><br/>
          <span>{item.endpoints.StorageEndpoint?.Protocol} - {item.endpoints.StorageEndpoint?.EndpointName}</span><br/>
          <span>{item.endpoints.SCEndpoint?.Protocol} - {item.endpoints.SCEndpoint?.EndpointName}</span><br/>*/}
          {/*<div>
              {item.industries !== undefined ? item.industries.map((item) => (
              <span className="badge badge-warning mr-2">{item}</span>
              )) : ""}
          </div>*/}
          {item.endpoints.BlockchainEndpoint && Array.isArray(item.endpoints.BlockchainEndpoint) &&
          item.endpoints.BlockchainEndpoint.map(endpoint => (
              <div key={endpoint.EndpointName} className="endpoint-name-section">
                  <Icons type={(endpoint.Protocol.split("-")[0]).toLowerCase()} />
                  <div className="pl-3 d1">
                      <p className="text-container p1">{endpoint.Protocol}</p>
                      <p className="p2">{endpoint.EndpointName}</p>
                  </div>
              </div>
          ))
          }

          {item.endpoints.BlockchainEndpoint && item.endpoints.BlockchainEndpoint?.Protocol !== undefined &&
          <div className="endpoint-name-section">
              <Icons type={(item.endpoints.BlockchainEndpoint?.Protocol).split("-")[0].toLowerCase()}/>
              <div class="pl-3 d1">
                  <p className="text-container p1">{item.endpoints.BlockchainEndpoint?.Protocol}</p>
                  <p className="p2">{item.endpoints.BlockchainEndpoint?.EndpointName}</p>
              </div>
          </div>
          }
          {item.endpoints.DIEndpoint &&
          <div className="endpoint-name-section">
              <Icons type={(item.endpoints.DIEndpoint?.Protocol).split("-")[0].toLowerCase()}/>
              <div class="pl-3 d1">
                  <p className="text-container p1">{item.endpoints.DIEndpoint?.Protocol}</p>
                  <p className="p2">{item.endpoints.DIEndpoint?.EndpointName}</p>
              </div>
          </div>
          }
          {item.endpoints.StorageEndpoint &&
          <div className="endpoint-name-section">
              <Icons type={(item.endpoints.StorageEndpoint?.Protocol).split("-")[0].toLowerCase()}/>
              <div class="pl-3 d1">
                  <p className="text-container p1">{item.endpoints.StorageEndpoint?.Protocol}</p>
                  <p className="p2">{item.endpoints.StorageEndpoint?.EndpointName}</p>
              </div>
          </div>
          }
          {item.endpoints.SCEndpoint &&
              <div className="endpoint-name-section">
                  <Icons type={(item.endpoints.SCEndpoint?.Protocol).split("-")[0].toLowerCase()}/>
                  <div class="pl-3 d1">
                      <p className="text-container p1">{item.endpoints.SCEndpoint?.Protocol}</p>
                      <p className="p2">{item.endpoints.SCEndpoint?.EndpointName}</p>
                  </div>
              </div>
          }


      </div>

      {/*<div className="btn-container">
        <button className="view-details" onClick={onClick}>
          Build It
        </button>
      </div>*/}
        <div class="project-card__footer">
            <button className="service-card__button" onClick={onClick}>Manage</button>
        </div>
    </div>
  );
};

export const EmptyCard = ({ onNavigate ,isLoading}) => {
    // const onClick = () => {
    //     onNavigate("/project/create-apps");
    // };
    return (<>
      {isLoading?(<Skeleton height={420} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <div className="empty-card-box-container">
            <div className="name-container">
            <div className="name-section">
                <div class="project-name">
                    You haven't created any dApp's yet.
                    <p>Create your first dApp!</p>
                </div>
            </div>
            </div>
            <div className="btn-container" style={{display: "flex",justifyContent:'center'}}>
                <button className="sc-button1" onClick={onNavigate}>
                        <span style={{
                            padding: "15px",
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '35px',
                            color:'#808080'
                        }}><img style={{marginBottom: "2px", marginRight: '5px', marginLeft: "5px"}} width="20px"
                                height="20px" src={addIcon}/> Create </span>
                </button>
            </div>
        </div>)}
    </>);
};

export const EndpointCard = ({onNavigate, isLoading}) => {
    const onClick = () => {
        onNavigate("/project/create-apps");
    };
    return (<>
        {isLoading ? (<Skeleton height={420} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (
            <div className="empty-card-box-container">
                <div className="name-container">
                    <div className="name-section">
                        <div class="project-name">
                            You haven't created any endpoint's yet.
                            <p>Create your first endpoint!</p>
                        </div>
                    </div>
                </div>
                <div className="btn-container" style={{display: "flex",justifyContent:'center'}}>
                    <button className="sc-button1" onClick={onNavigate}>
                        <span style={{
                            padding: "15px",
                            fontFamily: 'Poppins',
                            fontSize: '18px',
                            fontWeight: '700',
                            lineHeight: '35px',
                            color:'#808080'
                        }}><img style={{marginBottom: "2px", marginRight: '5px', marginLeft: "5px"}} width="20px"
                                height="20px" src={addIcon}/> Create </span>
                    </button>
                </div>
            </div>)}
    </>);
};

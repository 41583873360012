export const schemaJson = [
    {
        "type": "object",
        "properties": {
            "web3_clientVersion": {},
            "web3_sha3": {
                "type": "object",
                "properties": {
                    "DATA": {
                        "type": "string",
                        "title": "DATA"
                    }
                }
            },
            "net_version": {},
            "net_listening": {},
            "net_peerCount": {},
            "eth_protocolVersion": {},
            "eth_syncing": {},
            // "eth_coinbase": {},
            // "eth_mining": {},
            // "eth_hashrate": {},
            "eth_gasPrice": {},
            // "eth_accounts": {},
            "eth_blockNumber": {},
            "eth_getBalance": {
                "type": "object",
                "properties": {
                    "Address to check for balance": {
                        "type": "string",
                        "title": "Address to check for balance"
                    },
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    }
                }
            },
            "eth_getStorageAt": {
                "type": "object",
                "properties": {
                    "Address of the storage": {
                        "type": "string",
                        "title": "Address of the storage"
                    },
                    "Position in the storage": {
                        "type": "string",
                        "title": "Position in the storage"
                    },
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    }
                }
            },
            "eth_getTransactionCount": {
                "type": "object",
                "properties": {
                    "Address": {
                        "type": "string",
                        "title": "Address"
                    },
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    }
                }
            },
            "eth_getBlockTransactionCountByHash": {
                "type": "object",
                "properties": {
                    "Hash of a block": {
                        "type": "string",
                        "title": "Hash of a block"
                    }
                }
            },
            "eth_getBlockTransactionCountByNumber": {
                "type": "object",
                "properties": {
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    }
                }
            },
            "eth_getCode": {
                "type": "object",
                "properties": {
                    "Address": {
                        "type": "string",
                        "title": "Address"
                    },
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    }
                }
            },
            // "eth_sign": {
            //     "type": "object",
            //     "properties": {
            //         "Address": {
            //             "type": "string",
            //             "title": "Address"
            //         },
            //         "Message to sign": {
            //             "type": "string",
            //             "title": "Message to sign"
            //         }
            //     }
            // },
            // "eth_sendTransaction": {
            //     "type": "object",
            //     "properties": {
            //         "from": {
            //             "type": "string",
            //             "title": "From"
            //         },
            //         "to": {
            //             "type": "string",
            //             "title": "To"
            //         },
            //         "gas": {
            //             "type": "string",
            //             "title": "Gas"
            //         },
            //         "gasPrice": {
            //             "type": "string",
            //             "title": "Gas Price"
            //         },
            //         "value": {
            //             "type": "string",
            //             "title": "Value"
            //         },
            //         "data": {
            //             "type": "string",
            //             "title": "Data"
            //         },
            //         "nonce": {
            //             "type": "string",
            //             "title": "Nonce"
            //         }
            //     }
            // },
            "eth_sendRawTransaction": {
                "type": "object",
                "properties": {
                    "Signed transaction data": {
                        "type": "string",
                        "title": "Signed transaction data"
                    }
                }
            },
            "eth_call": {
                "type": "object",
                "properties": {
                    "Transaction call object": {
                        "type": "object",
                        "properties": {
                            "from": {
                                "type": "string",
                                "title": "From"
                            },
                            "to": {
                                "type": "string",
                                "title": "To"
                            },
                            "gas": {
                                "type": "string",
                                "title": "Gas"
                            },
                            "gasPrice": {
                                "type": "string",
                                "title": "Gas Price"
                            },
                            "value": {
                                "type": "string",
                                "title": "Value"
                            },
                            "data": {
                                "type": "string",
                                "title": "Data"
                            }
                        }
                    },
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    }
                }
            },
            "eth_estimateGas": {
                "type": "object",
                "properties": {
                    "Transaction call object": {
                        "type": "object",
                        "properties": {
                            "from": {
                                "type": "string",
                                "title": "From"
                            },
                            "to": {
                                "type": "string",
                                "title": "To"
                            },
                            "gas": {
                                "type": "string",
                                "title": "Gas"
                            },
                            "gasPrice": {
                                "type": "string",
                                "title": "Gas Price"
                            },
                            "value": {
                                "type": "string",
                                "title": "Value"
                            },
                            "data": {
                                "type": "string",
                                "title": "Data"
                            }
                        }
                    }
                }
            },
            "eth_getBlockByHash": {
                "type": "object",
                "properties": {
                    "Hash of a block": {
                        "type": "string",
                        "title": "Hash of a block"
                    },
                    "Return full transaction objects": {
                        "type": "boolean",
                        "title": "Return full transaction objects"
                    }
                }
            },
            "eth_getBlockByNumber": {
                "type": "object",
                "properties": {
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    },
                    "Return full transaction objects": {
                        "type": "boolean",
                        "title": "Return full transaction objects"
                    }
                }
            },
            "eth_getTransactionByHash": {
                "type": "object",
                "properties": {
                    "Hash of a transaction": {
                        "type": "string",
                        "title": "Hash of a transaction"
                    }
                }
            },
            "eth_getTransactionByBlockHashAndIndex": {
                "type": "object",
                "properties": {
                    "Hash of a block": {
                        "type": "string",
                        "title": "Hash of a block"
                    },
                    "Transaction index position": {
                        "type": "string",
                        "title": "Transaction index position"
                    }
                }
            },
            "eth_getTransactionByBlockNumberAndIndex": {
                "type": "object",
                "properties": {
                    "Block number": {
                        "type": "string",
                        "title": "Block number"
                    },
                    "Transaction index position": {
                        "type": "string",
                        "title": "Transaction index position"
                    }
                }
            },
            "eth_getTransactionReceipt": {
                "type": "object",
                "properties": {
                    "Hash of a transaction": {
                        "type": "string",
                        "title": "Hash of a transaction"
                    }
                }
            },
            // "eth_getUncleByBlockHashAndIndex": {
            //     "type": "object",
            //     "properties": {
            //         "Hash of a block": {
            //             "type": "string",
            //             "title": "Hash of a block"
            //         },
            //         "Uncle index position": {
            //             "type": "string",
            //             "title": "Uncle index position"
            //         }
            //     }
            // },
            // "eth_getUncleByBlockNumberAndIndex": {
            //     "type": "object",
            //     "properties": {
            //         "Block number": {
            //             "type": "string",
            //             "title": "Block number"
            //         },
            //         "Uncle index position": {
            //             "type": "string",
            //             "title": "Uncle index position"
            //         }
            //     }
            // },
            // "eth_getCompilers": {},
            // "eth_compileLLL": {
            //     "type": "object",
            //     "properties": {
            //         "Source code": {
            //             "type": "string",
            //             "title": "Source code"
            //         }
            //     }
            // },
            // "eth_compileSolidity": {
            //     "type": "object",
            //     "properties": {
            //         "Source code": {
            //             "type": "string",
            //             "title": "Source code"
            //         }
            //     }
            // },
            // "eth_compileSerpent": {
            //     "type": "object",
            //     "properties": {
            //         "Source code": {
            //             "type": "string",
            //             "title": "Source code"
            //         }
            //     }
            // },
            // "eth_newFilter": {
            //     "type": "object",
            //     "properties": {
            //         "Filter options": {
            //             "type": "object",
            //             "properties": {
            //                 "fromBlock": {
            //                     "type": "string",
            //                     "title": "From Block"
            //                 },
            //                 "toBlock": {
            //                     "type": "string",
            //                     "title": "To Block"
            //                 },
            //                 "address": {
            //                     "type": "string",
            //                     "title": "Address"
            //                 },
            //                 "topics": {
            //                     "type": "string",
            //                     "title": "Topics",
            //                 }
            //             }
            //         }
            //     }
            // },
            // "eth_newBlockFilter": {},
            // "eth_newPendingTransactionFilter": {},
            // "eth_uninstallFilter": {
            //     "type": "object",
            //     "properties": {
            //         "Filter id": {
            //             "type": "string",
            //             "title": "Filter id"
            //         }
            //     }
            // },
            // "eth_getFilterChanges": {
            //     "type": "object",
            //     "properties": {
            //         "Filter id": {
            //             "type": "string",
            //             "title": "Filter id"
            //         }
            //     }
            // },
            // "eth_getFilterLogs": {
            //     "type": "object",
            //     "properties": {
            //         "Filter id": {
            //             "type": "string",
            //             "title": "Filter id"
            //         }
            //     }
            // },
            // "eth_getLogs": {
            //     "type": "object",
            //     "properties": {
            //         "Filter options": {
            //             "type": "object",
            //             "properties": {
            //                 "fromBlock": {
            //                     "type": "string",
            //                     "title": "From Block"
            //                 },
            //                 "toBlock": {
            //                     "type": "string",
            //                     "title": "To Block"
            //                 },
            //                 "address": {
            //                     "type": "string",
            //                     "title": "Address"
            //                 },
            //                 "topics": {
            //                     "type": "array",
            //                     "title": "Topics",
            //                     "items": {
            //                         "type": "string"
            //                     }
            //                 }
            //             }
            //         }
            //     }
            // },
            // "eth_getWork": {},
            // "eth_submitWork": {
            //     "type": "object",
            //     "properties": {
            //         "Nonce": {
            //             "type": "string",
            //             "title": "Nonce"
            //         },
            //         "PowHash": {
            //             "type": "string",
            //             "title": "PowHash"
            //         },
            //         "MixDigest": {
            //             "type": "string",
            //             "title": "MixDigest"
            //         }
            //     }
            // },
            "eth_submitHashrate": {
                "type": "object",
                "properties": {
                    "Hashrate": {
                        "type": "string",
                        "title": "Hashrate"
                    },
                    "Client ID": {
                        "type": "string",
                        "title": "Client ID"
                    }
                }
            }
        }
    }
];

import { dateformatter } from "../../../../deps";
import React from "react";
import Restricted from "../../../../../auth/Restricted";

export const columns = (downloadFile) =>  [
    {
        title: "Purchase Date",
        dataIndex: "planDetails",
        render: (_, { planDetails }) => <span>{dateformatter(planDetails.subscriptionDate, false, false)}</span>,
    },
    {
        title: "Expiry Date",
        dataIndex: "planDetails",
        render: (_, { planDetails }) => <span>{dateformatter(planDetails.expiryDate, false, false)}</span>,
    },
    {
        title: "Payment Method",
        dataIndex: "paymentDetails",
        render: (_, { paymentDetails }) => <span>{paymentDetails != null ?paymentDetails.paymentMethod: ""}</span>,
    },
    {
        title: "Paid Amount",
        dataIndex: "paymentDetails",
        render: (_, { paymentDetails }) => <span>{paymentDetails != null ?paymentDetails.amount:0}</span>,
    },
    {
        title: "Plan Purchased",
        dataIndex: "planDetails",
        render: (_, { planDetails }) => <span>{planDetails.planName}</span>,
    },
    {
        title: "Subscription ID",
        dataIndex: "subscriptionId" ,
    },
    {
        title: "Invoice",
        dataIndex: "planDetails",
        width:"10%",
        render: (_, {_id,planDetails}) => (
            <Restricted>
                <button className="save-changes-btn1">
                    <a
                        onClick={() => downloadFile(_id,"Starter (Freemium)","")}
                        target="_new"
                    >
                        {"Download"}
                    </a>

                </button>
            </Restricted>
        )
    },
];

export const tempData = [

];

export const columns1 = (downloadFile) =>  [
    {
        title: "Bill Date",
        dataIndex: "planDetails",
        render: (_, { planDetails }) => <span>{dateformatter(planDetails.subscriptionDate, false, false)}</span>,
    },
    {
        title: "Consumption Date",
        dataIndex: "planDetails",
        render: (_, { planDetails }) => <span>{dateformatter(planDetails.expiryDate, false, false)}</span>,
    },
    {
        title: "Payment Method",
        dataIndex: "paymentDetails",
        render: (_, { paymentDetails }) => <span>{paymentDetails != null ?paymentDetails.paymentMethod:""}</span>,
    },
    {
        title: "Amount",
        dataIndex: "paymentDetails",
        render: (_, { paymentDetails }) => <span>{paymentDetails != null ?paymentDetails.amount:0}</span>,
    },
    {
        title: "Description",
        dataIndex: "" ,
    },
    {
        title: "Bill ID",
        dataIndex: "billingId" ,
    },
    {
        title: "Invoice",
        dataIndex: "planDetails",
        render: (_, {_id,planDetails}) => (
            <Restricted>
                <button className="save-changes-btn1">
                    <a
                        onClick={() => downloadFile(_id,"Consumption Credit","consumption")}
                        target="_new"
                    >
                        {"Download"}
                    </a>

                </button>
            </Restricted>
        )
    },
];

export const columnsForReferral = () =>  [
    {
        title: "Registered Date",
        dataIndex: "registeredOn",
        render: (_, { registeredOn }) => <span>{dateformatter(registeredOn, false, false)}</span>,
    },
    {
        title: "Email Address",
        dataIndex: "emailId",
        render: (_, { emailId }) =><span>{emailId}</span>,
    }
];

import React, {useEffect, useState} from "react";
import {useNavigate, useParams} from "react-router-dom";
import {DEVELOPERROLE, ENTERPRISEDEVELOPERROLE, getPATTokenDetails, getUserDetails} from "../../../deps";
import {fetchApi} from "../../../_config/api";
import {notification, Row} from "antd";
import {ACCESS} from "../../../_config/constant";
import {Card} from "../../smartContract/contractTemplates/card";
import {PaginationSection} from "../../../library/paginationSection";
import {Restricted} from "../../../../auth/Restricted";
import {useLocation} from "react-router";
import {LoadContract} from "./loadContract";
import Skeleton from "react-loading-skeleton";

export const ChooseTemplate = ({showLoader,isLoading}) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const navigate = useNavigate();
    const params = useParams();
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 6,
        searchText: "",
        filterValue: "",
    });
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const {
        profile: { userRole },
    } = getUserDetails();
    const token = getPATTokenDetails();
    const authToken = token.patToken;
    const [hitsList, setHitsList] = useState([]);
    const [templates, setTemplates] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    let chainIDVal = "";
    const location = useLocation();
    if (location.state != null) {
        chainIDVal = location.state.chainIDVal
    }
    const [role, setRole] = useState(null);
    const [loadContractOpen, setLoadContractOpen] = useState(false);
    const loadContractModal = () => {
        setLoadContractOpen(!loadContractOpen);
    };
    const [customId, setCustomId] = useState("");

    useEffect(() => {
        getAllTemplates();
    }, [params.id]);
    const getAllTemplates = async () => {
        setHitsList([3, 6, 9, 12]);
        showLoader(true);
        const filter = pageSetting.searchText !== "" ?
            {
                and: [
                    {
                        key: "name",
                        value: pageSetting.searchText,
                        opt: "regex",
                    },
                    {
                        key: "isActive",
                        value: "true",
                        opt: "eq",
                    },
                ],
            } : {
                and: [
                    {
                        key: "isActive",
                        value: "true",
                        opt: "eq",
                    },
                ],
            };

        try {
            const response = await fetchApi({
                method: "GET",
                url: `smart-contract-studio/templates?options={"page":${pageSetting.page - 1},"limit":${pageSetting.itemsPerPage+1},"sort":{"order": 1},"select":[""]}&filter=${JSON.stringify(filter)}`,
                isDevApp: false,
            });
            if (response.statusCode === 200) {
                let finalArr = [];
                if(response.docs.length > 0) {
                    for(var i=0;i<response.docs.length;i++) {
                        if (response.docs[i].templateType === "CUSTOM"){
                            setCustomId(response.docs[i]._id);
                        }else{
                            finalArr.push(response.docs[i]);
                        }
                    }
                }
                setTemplates(finalArr);
                showLoader(false);
                const totalcount = response?.meta?.totalDocs > 0 ? (response?.meta?.totalDocs -1) : 0;
                setTotalCount(totalcount);
            }
        } catch (error) {
            // TypeError: Failed to fetch
            showLoader(false);
            notification.error({
                message: error.toString(),
                duration: 3,
            });
        }
    };
    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getAllTemplates();

    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getAllTemplates();
    };
    const onClick = (template) => {
        if (template.templateType !== "WIZARD") {
            onProjectNavigate(template?._id);
        } else if(template.name === "Custom" && template.templateType === "CUSTOM") {
            onProjectNavigate(`/${template._id}`);
        } else {
            onProjectNavigate(`create/${template.name}`);
        }
    }
    const onClickCreateCustom = () => {
        navigate(`/smart-contract/contract-templates/${customId}`);
    }
    const onProjectNavigate = (path) => {
        navigate(`/smart-contract/contract-templates/${path}`);
    };

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;
    return (
          <div>
                <div className="template-header">Create your first contract</div>
                 <div style={{marginTop:"30px",fontSize:"35px",fontWeight:"700"}} className="template-header">Popular</div>
                 <div style={{fontSize:"16px",fontWeight:"400"}} className="template-header">Choose from our most popular templates</div>
              {isLoading ? (<Skeleton height={600} style={{marginTop: '30px'}} baseColor="#262626"
                                      highlightColor="#404040"></Skeleton>) : (
                 <div style={{display:"flex",justifyContent:"space-between",gap:"50px"}}>
                     <Restricted code={ACCESS.EASYNFT_LIST_COLLECTION} type={role}>
                             <div className="template-container">
                                 {/*<Row style={{ marginTop: "2rem" }} gutter={[32, 32]}>*/}
                                 {templates &&
                                     templates.length > 0 &&
                                     templates.map((template, index) => (
                                         <div className="template-box" onClick={() => {
                                             onClick(template)
                                         }}><span style={{
                                             fontSize: "16px",
                                             fontWeight: "700",
                                             marginTop: "20px",
                                             marginLeft: "20px"
                                         }}
                                                  className="template-header">{template.name}</span></div>
                                         // <Card
                                         //     itemIndx={index}
                                         //     item={"1"}
                                         //     isLoading={isLoading}
                                         //     template={templates}
                                         //     onNavigate={onProjectNavigate}
                                         //     showLoader={showLoader}
                                         // />
                                     ))}
                                 {/*</Row>*/}
                             </div>
                     </Restricted>
                     {/* <div style={{width:"40%",marginTop:"40px"}}>
                     <div style={{background:'transparent',borderColor:'#fff',float: "right",cursor:"pointer"}} className="add-contract-choose-button" onClick={onClickCreateCustom}>
                         <div className="add-contract-container-button-text">Create Custom</div>
                     </div>
                     <br />
                     <div style={{background:'#fff',borderColor:'#fff',color:'#4C59E3',cursor:"pointer",float: "right"}} className="add-contract-choose-button" onClick={()=> setLoadContractOpen(true)}>
                         <div className="add-contract-container-button-text">Load Existing</div>
                     </div>
                 </div> */}
                 </div>
              )}
              <div style={{width: "55%"}}>
                  <PaginationSection
                      last={last}
                      first={startIndex + 1}
                      hitsList={hitsList}
                      totalCount={totalCount}
                      page={pageSetting.page}
                      itemsPerPage={pageSetting.itemsPerPage}
                      onPageChange={onPageChange}
                      onPerPageChange={onPerPageChange}
                  />
              </div>
              <LoadContract
                  isLoadContractOpen={loadContractOpen}
                  loadContractToogleState={loadContractModal}
              />
          </div>
      );
}
import React from "react";

export const Telegram = ({ width = "24px", height = "24px" }) => (
    <svg style={{color:"#fff"}} width={width} height={width} color="#fff" strokeWidth="2"
         viewBox="0 0 24 24" fill="none"
         xmlns="http://www.w3.org/2000/svg" id="IconChangeColor">
        <path d="M18 8L5 12.5L9.5 14M18 8L9.5 14M18 8L14 18.5L9.5 14" stroke="#fff"
              strokeLinecap="round" strokeLinejoin="round"
              id="mainIconPathAttribute" strokeWidth="2" filter="none">

        </path> <path d="M12 22C17.5228 22 22 17.5228 22 12C22 6.47715 17.5228 2 12 2C6.47715 2 2 6.47715 2 12C2 17.5228 6.47715 22 12 22Z" stroke="#fff" strokeLinecap="round" strokeLinejoin="round" id="mainIconPathAttribute"></path> <filter id="shadow"><feDropShadow id="shadowValue" stdDeviation=".5" dx="0.3" dy="0" floodColor="black"></feDropShadow></filter><filter id="shadow"><feDropShadow id="shadowValue" stdDeviation=".5" dx="0" dy="0" floodColor="black"></feDropShadow></filter></svg>
);

import React, { useEffect, useState } from 'react'
import SideNav from './SideNav'
import Header from './Header'
import Router from './Router'
import { useLocation, useNavigate } from 'react-router-dom'
import fetchApi from '../../_config/api'
import { removeAllDataInLocal } from '../../deps'
import { useAuth0 } from '@auth0/auth0-react'
import { getPageTitle } from './constant'


const NewPrivateRouter = () => {
    const [isLoading, showLoader] = useState(false);
    const [pageTitle, setPageTitle] = useState("");
    const [highlightTitle, setHighlightTitle] = useState("");
    const navigate = useNavigate();
    const { logout } = useAuth0();
    const onNavigate = () => {
        navigate("/my-account/plan");
      };
      const onSupport = () => {
        navigate("/support");
      };
      const onSettings = () => {
        navigate("/account-settings");
      };
      const navigateTOAI = () => {
        navigate("/advanced/ai-assistant");
      };
      const onSignOut = () => {
        onUserLogout();
        removeAllDataInLocal();
        logout({
          returnTo: window.location.origin,
        });
      };
      const onUserLogout = () =>{
        fetchApi({ method: "POST", url: "auth/logout", })
            .then((response) => {
              if (response.statusCode === 200) {
                console.log("success logout")
              }
            })
            .catch(() => {
              console.log("failure logout")
            });
      }
      const { pathname } = useLocation();
      console.log(highlightTitle)

      useEffect(() => {
        setPageTitle(getPageTitle(pathname, navigate))
        const pageTitle = getPageTitle(pathname, navigate);
        if (pageTitle !== "Dashboard" && pageTitle.props?.className !== "breadcrumb-container"){
          const breadcrumbData = pageTitle.props?.children.props?.children[2].props.children
          setHighlightTitle(breadcrumbData);
        }else if (pageTitle !== "Dashboard" && pageTitle.props?.className === "breadcrumb-container"){
          setHighlightTitle(pageTitle?.props?.children[2]?.props?.children);
        }else{
          setHighlightTitle(getPageTitle(pathname, navigate))
        }
      }, [pathname]);


  return (
    <div className="developer-app-container">
        <SideNav
          hasPremium={false}
          pageTitle={pageTitle}
          highlightTitle={highlightTitle}
          onSignOut={onSignOut}
          onNavigate={onNavigate}
          onSupport={onSupport}
          onSettings={onSettings}
          navigateToAI={navigateTOAI}
        />
        <div className="site-layout1-new">
            <Header pageTitle={pageTitle}/>
            <div className="main-page-container">
                <Router isLoading={isLoading} showLoader={showLoader}/>
            </div>
        </div>
    </div>
  )
}

export default NewPrivateRouter

import React, { useState } from 'react'
import krypclogowhite from '../../../assets/icons/krypcore-white 2.svg'
import discord from '../../../assets/icons/discord 2.svg'
import twiter from '../../../assets/icons/twitter (1) 1.svg'
import telegram from '../../../assets/icons/telegram 1.svg'
import linkedin from '../../../assets/icons/linkedin 1.svg'
import youtube from '../../../assets/icons/youtube 1.svg'
import { notification } from 'antd'
import fetchApi from '../../../_config/api'
import { useNavigate } from 'react-router-dom'

const Footer = () => {

    const twitter1= process.env.REACT_APP_TWITTER_URL
    const telegram1 = process.env.REACT_APP_TELEGRAM_URL
    const discord1= process.env.REACT_APP_DISCORD_URL
    const linkedIn1= process.env.REACT_APP_LINKEDIN_URL
    const aboutus= process.env.REACT_APP_LANDING_PAGE_ABOUT_URL
    const career = process.env.REACT_APP_LANDING_PAGE_CAREER_URL
    const docs= process.env.REACT_APP_LANDING_PAGE_DOCS_URL
    const api= process.env.REACT_APP_LANDING_PAGE_API_URL
    const sdk= process.env.REACT_APP_LANDING_PAGE_SDK_URL
    const userManual= process.env.REACT_APP_LANDING_PAGE_USER_MANUAL_URL
    const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL
    const youtube1=  process.env.REACT_APP_YOUTUBE_URL
    const githuburl=process.env.REACT_APP_GITHUB_SDK

    const open = (value)=>{
        window.open(value)
    }

    const [query, setQuery] = useState(false)
    const navigate = useNavigate();

    const navigateHome = (value) => {
        if (window.location.pathname != "/"){
            navigate("/", { state: { value: value } })
        }else{
            scrollToPricing()
        }
    };
    const scrollToPricing = () => {
        document.getElementById('pricing_works').scrollIntoView({ behavior: "smooth" })
    };

    React.useEffect(() => {
        if (window.location.pathname.includes('contact-us')) {
            setQuery(true);
        }

    }, [])

    const[email,setEmail]=useState('')

    const submit = () => {
        if ( email === '') {
            notification.error({
                message: "please fill the details",
                duration: 2,
            });
        } else {
                // console.log('Captcha token:', captchaValue, captcha);
                const accessBody = {
                    emailId: email,
                    
                };
                fetchApi({method: "POST", url: `developer/contact-us`, data: accessBody})
                    .then((response) => {
                        if (response.status == true) {
                            notification.success({
                                message: response.message,
                                duration: 2,
                            });
                        }
                        setEmail("");
                       
                    })
                    .catch(() => {
                    });
            }
        
        ;
    }

  return (
    <div className='light-theme-footer-container'>
        <div className='light-theme-footer-container1'>
          <div>
            <img src={krypclogowhite} height='auto' width='auto'/>
            <div className="light-theme-footer-input-container light-theme-hiddenbreaktag1">
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className='light-theme-footer-input' type="text" placeholder="Enter email to Subscribe"/>
                    <button onClick={submit} className='light-theme-footer-input-submit-btn' type="submit">Submit</button>
                </div>
            <p className='light-theme-footer-container-para'>
            <strong>Krypcore Web3</strong> provides enterprises with scalable <br/> infrastructure, a powerful SDK and APIs that make<br/>  building, managing and deploying decentralized<br/>  solutions easier than ever.
            </p>
            <span className='light-theme-footer-logos-container'>
              <a href={discord1} target="_blank"><img src={discord} className='light-theme-footer-logos'/></a>
              <a href={twitter1} target="_blank"> <img src={twiter} className='light-theme-footer-logos'/></a>
              <a href={telegram1} target="_blank"> <img src={telegram}  className='light-theme-footer-logos'/></a>
              <a href={linkedIn1} target="_blank"> <img src={linkedin}  className='light-theme-footer-logos'/></a>
              <a href={youtube1} target="_blank"> <img src={youtube}  className='light-theme-footer-logos'/></a>
            </span>
          </div>
          <div className='light-theme-footer-privacy-policy-container'>
            <a href="/privacy-policy" style={{color:'white'}} className='light-theme-hiddenbreaktag'>Privacy Policy</a>
            <a href="/terms" style={{color:'white'}} className='light-theme-hiddenbreaktag'>Terms of Use</a>
          </div>
        </div>
        <div>
            <div className='light-theme-footer-left-container'>
                <div className='light-theme-footer-left-container1'>
                  <span className='light-theme-footer-left-container-text'><strong>Company</strong></span>
                  <a href={aboutus} target="_blank" className='light-theme-footer-left-container-text'>About Us</a>
                  <a href="/pricing" className='light-theme-footer-left-container-text'>Pricing</a>
                  <a href='/blog' className='light-theme-footer-left-container-text'>Blog</a>
                  <a href={career} className='light-theme-footer-left-container-text'>Careers</a>
                  <a href='/contact-us' className='light-theme-footer-left-container-text'>Contact Us</a>
                  <a href="/privacy-policy" className='light-theme-footer-left-container-text light-theme-hiddenbreaktag1 light-theme-hiddenbreaktag'>Privacy Policy</a>
                  <a href="/terms" className='light-theme-footer-left-container-text light-theme-hiddenbreaktag1 light-theme-hiddenbreaktag'>Terms of Service</a>
                </div>
                <div className='light-theme-footer-left-container2'>
                  <span className='light-theme-footer-left-container-text'><strong>Resources</strong></span>
                  <a href={docs} target="_blank" className='light-theme-footer-left-container-text'>Docs</a>
                  <a href={api} target="_blank" className='light-theme-footer-left-container-text'> API reference</a>
                  <a href='/faucet' className='light-theme-footer-left-container-text'>Faucet</a>
                  <a href={githuburl} target="_blank" className='light-theme-footer-left-container-text'>Github</a>
                </div>
            </div>
            <div className="light-theme-footer-right-bottom-content">
                <div className="light-theme-footer-input-container light-theme-hiddenbreaktag">
                    <input value={email} onChange={(e)=>setEmail(e.target.value)} className='light-theme-footer-input' type="text" placeholder="Enter email to Subscribe"/>
                    <button onClick={submit} className='light-theme-footer-input-submit-btn' type="submit">Submit</button>
                </div>
                <div className='light-theme-footer-privacy-policy-container '>
                <a href='https://krypc.com' target="_blank">
                  <span className='light-theme-hiddenbreaktag' style={{color:'white'}}>© 2024 KrypC Technologies</span>
                </a>
                
                </div>
            </div>
        
        </div>

    </div>
  )
}

export default Footer
import {Button, Checkbox, Form, Input, Modal, notification, Space, Spin} from "antd";
import {Menus} from "../../../assets";
import React, {useState} from "react";
import "./styles.less";
import {CopyOutlined } from "@ant-design/icons";
import copy from "./copy.png"
import Logo from "../../../assets/logos/krypcore_logo.avif";

export const ConnectAppkey = ({isOpen,toggleState,showLoader,isLoading, rpcUrl,dappId,walletId}) => {

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };

    if (!rpcUrl || rpcUrl.length < 25) {
        return <div className="text-inside-rectangle">{rpcUrl}</div>;
    }

    const firstTwenty = rpcUrl.substring(0, 20);
    const lastFive = rpcUrl.substring(rpcUrl.length - 5);
    const stars = '*****';




    return (
        <>
            <Modal bodyStyle={{width:"1000px"}} style={{marginRight:"250px"}} maskStyle={{backgroundColor: '#CFCFCF80'}} centered title="" footer={null} closable={false} open={isOpen}>
                <div>
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="modal-title" style={{ fontSize: '36px' }} >Connect to Krypcore</div>
                    <div className="create-dapp-container"
                         style={{display: 'flex', justifyContent: 'center', alignItems: 'center',flexDirection:"column",gap:"30px",paddingBottom:"30px"}}>
                        <div class="rectangle-143">
                            <div class="rectangle-146">
                                dApp Id
                            </div>
                            <div className="text-inside-rectangle">
                                {dappId}
                            </div>
                            <img src={copy} alt="" className="copy-icon" onClick={() => onCopy(dappId)}/>
                        </div>
                        <div class="rectangle-143">
                            <div class="rectangle-146">
                                API Endpoint
                            </div>
                            <div className="text-inside-rectangle">
                                {firstTwenty}&nbsp;{stars}&nbsp;{lastFive}
                            </div>
                            <img src={copy} alt="" className="copy-icon" onClick={() => onCopy(rpcUrl)}/>
                        </div>
                        <div class="rectangle-143">
                            <div class="rectangle-146">
                                Wallet Instance Id
                            </div>
                            <div className="text-inside-rectangle">
                                {walletId}
                            </div>
                            <img src={copy} alt="" className="copy-icon" onClick={() => onCopy(walletId)}/>
                        </div>


                    </div>
                </div>
            </Modal>
        </>
    );
}
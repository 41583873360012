import React from "react";

export const Github = ({ width = "38px", height = "38px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_github)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.9985 0.459473C8.50783 0.459473 0 8.96616 0 19.4603C0 27.8538 5.44366 34.9759 12.9938 37.4895C13.9444 37.6634 14.2909 37.0766 14.2909 36.5727C14.2909 36.1213 14.2744 34.9269 14.2644 33.3418C8.97941 34.4895 7.86434 30.7944 7.86434 30.7944C7.00003 28.5992 5.75429 28.0148 5.75429 28.0148C4.02916 26.8367 5.88489 26.86 5.88489 26.86C7.79197 26.9942 8.79508 28.8184 8.79508 28.8184C10.4899 31.7216 13.2426 30.883 14.325 30.3966C14.4976 29.1696 14.9887 28.332 15.5311 27.8573C11.3122 27.3768 6.87634 25.7473 6.87634 18.4666C6.87634 16.3915 7.61703 14.6967 8.83241 13.3682C8.63635 12.8876 7.98443 10.956 9.01922 8.33973C9.01922 8.33973 10.6137 7.82888 14.2436 10.2865C15.7587 9.86543 17.3847 9.65548 19.0002 9.64732C20.6145 9.65393 22.2393 9.86553 23.7568 10.2865C27.3843 7.82888 28.9765 8.33973 28.9765 8.33973C30.0134 10.956 29.3614 12.8876 29.1666 13.3682C30.3843 14.6967 31.1192 16.3915 31.1192 18.4666C31.1192 25.7659 26.6763 27.3721 22.4446 27.8421C23.1257 28.4288 23.7335 29.5883 23.7335 31.3612C23.7335 33.9005 23.7103 35.9499 23.7103 36.5727C23.7103 37.0813 24.0532 37.6727 25.0167 37.4872C32.561 34.969 38 27.8527 38 19.4603C38 8.96616 29.4922 0.459473 18.998 0.459473"
        fill="white"
      />
    </g>
    <defs>
      <clipPath id="clip_github">
        <rect width="38" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

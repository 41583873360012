import React from "react";
import { Link } from "react-router-dom";
import { ACCESS } from "../../_config/constant";
import { Restricted, isAllowed } from "../../../auth/Restricted";
import { getUserDetails } from "../../deps";
import { ENTERPRISEDEVELOPERROLE } from "../../deps";

import { ethers } from "ethers";
import { Input, notification } from "antd";
import { CopyOutlined, LinkOutlined } from "@ant-design/icons";

const { userRole } = getUserDetails().profile || {};
export const onCopy = (apiKey) => {
  navigator.clipboard.writeText(apiKey);
  notification.success({
    message: "Copied to clipboard",
    duration: 3,
  });
};
export const instancesColumn = (changeStatus) =>
  [
    {
      title: "Instance Name",
      key: "name",
      render: (_, { name, _id }) => (
        <Link to={`/smart-contract-studio/collections/${_id}`}>
          <span className="table_Link_smartContact">{name}</span>
        </Link>
      ),
    },
    {
      title: "API Key",
      dataIndex: "apiKey",
      render: (_, { apiKey }) => (
        <span>{apiKey && apiKey.apiKey ? apiKey.apiKey : "NA"}</span>
      ),
    },
    {
      title: "Allocated Team",
      dataIndex: "allocatedTeam",
      render: (_, { team }) => (
        <span>{team && team.name ? team.name : "NA"}</span>
      ),
    },
    {
      title: "Allocated Budget",
      dataIndex: "creditTotal",
    },
    {
      title: "Status",
      dataIndex: "isActive",
      hidden: userRole === ENTERPRISEDEVELOPERROLE ? true : false,
      render: (_, { _id, isActive }) => (
        <>
          {isAllowed(ACCESS.SC_UPDATE_INST) && (
            <Link>
              <button
                onClick={() => changeStatus(_id, isActive)}
                className={
                  isActive ? "save-changes-btn-difer" : "save-changes-btn"
                }
              >
                {isActive ? "Deactivate" : "Activate"}
              </button>
            </Link>
          )}

          {!isAllowed(ACCESS.SC_UPDATE_INST) && (
            <Link>
              <button
                className={
                  isActive ? "save-changes-btn" : "save-changes-btn-difer"
                }
              >
                {isActive ? "Active" : "Inactive"}
              </button>
            </Link>
          )}
        </>
      ),
    },
  ].filter((item) => !item.hidden);

function timeSince(date) {
  var seconds = Math.floor((new Date() - date) / 1000);

  var interval = seconds / 31536000;

  if (interval > 1) {
    return Math.floor(interval) + " years";
  }
  interval = seconds / 2592000;
  if (interval > 1) {
    return Math.floor(interval) + " months";
  }
  interval = seconds / 86400;
  if (interval > 1) {
    return Math.floor(interval) + " days";
  }
  interval = seconds / 3600;
  if (interval > 1) {
    return Math.floor(interval) + " hours";
  }
  interval = seconds / 60;
  if (interval > 1) {
    return Math.floor(interval) + " minutes";
  }
  return Math.floor(seconds) + " seconds";
}

export const transColumns = [
  {
    title: "Txn Hash",
    dataIndex: "tx_hash",
    render: (_, { tx_hash, chain }) => (
      <a>
        <div className="nft-collection-deploy">
          <div className="nft-collection-api-deploy">
            <a target="_blank" href={`${chain}`} >
              <>
              <span>{tx_hash.substring(0, 10) + "..."}</span>
              <LinkOutlined/>
              </>
            </a>
          </div>
        </div>
      </a>
    ),
  },
  {
    title: "",
    dataIndex: "tx_hash",
    render: (_,{tx_hash}) => (
        <CopyOutlined
                      onClick={() => onCopy(tx_hash)}
        />
    ),
  },
  {
    title: "Block",
    dataIndex: "block_height",
    render: (_, { block_height, chain }) => (
      <span>{block_height}</span>
    ),
  },
  {
    title: "Age",
    render: (_, { block_signed_at, chain }) => (
      <span>{timeSince(new Date(block_signed_at))}</span>
    ),
  },
  {
    title: "From",
    dataIndex: "from_address",
    render: (_, { from_address, chain }) => (
      <span>{from_address}</span>
    ),
  },
  {
    title: "To",
    dataIndex: "to_address",
    render: (_, { to_address, chain }) => (
        <span>{to_address ? to_address : "-"}</span>
    ),
  },
  {
    title: "Amount",
    render: (_, { value, chain }) => (
        <span>{value ? value : "-"}</span>
    ),
  },
  {
    title: "Txn Fee",
    render: (_, { gas_spent, gas_price, fees_paid, chain }) => (
        <span>{Number(ethers.utils.formatUnits(fees_paid, 18)).toFixed(8)}</span>
    ),
  },
];

import React from 'react'
import Group10 from './Icons/Group 173 (1).svg'
import Group11 from './Icons/Group 173.svg'
import Group12 from './Icons/Group 174 (1).svg'
import Group13 from './Icons/Group 174.svg'
import Group14 from './Icons/Group 175 (1).svg'
import Group15 from './Icons/Group 175.svg'
import { useInView } from 'react-intersection-observer'

const EnterprisePageMobileView = () => {
    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.1 // Trigger animation when 50% of the element is visible
      });
  return (
    <div ref={ref} className={`mobileViewEnterprise ${inView?'animate fadeInUp1':'hidden'} `}>
            <img src={Group13} className='enterpriseimg11' alt=''/>
            <img src={Group12} className='enterpriseimg11' alt=''/>
            <img src={Group11} className='enterpriseimg11' alt=''/>
            <img src={Group14} className='enterpriseimg11' alt=''/>
            <img src={Group15} className='enterpriseimg11' alt=''/>
            <img src={Group10} className='enterpriseimg11' alt=''/>
        </div>
  )
}

export default EnterprisePageMobileView
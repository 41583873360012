import React from 'react'

import Pricingplan from './Pricingplan'
import PricingDetails from './PricingDetails'
import './NewLandingPage.less'
import Header from '../new-light-theme/ui/Header'
import Footer from '../new-light-theme/ui/Footer'
import FooterPricing from "./FooterPricing";


const NewPricingPage = () => {
  return (
    // <div style={{backgroundColor:'black'}}>
      <div className="new-pricing-plan">
        <Header/>
        {/*<PricingDetails/>*/}
        <FooterPricing />
        <Footer/>
    </div>
  )
}

export default NewPricingPage
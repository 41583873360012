/* eslint-disable react/style-prop-object */
import React from "react";

export const TransactionArrow = ({ width = "50px", height = "50px" }) => (
  <svg 
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none" 
    xmlns="http://www.w3.org/2000/svg">
      <path d="M7.52311 10.7776C7.60834 10.8627 7.67595 10.9638 7.72208 11.0751C7.76821 11.1864 7.79196 11.3056 7.79196 11.4261C7.79196 11.5466 7.76821 11.6659 7.72208 11.7771C7.67595 11.8884 7.60834 11.9895 7.52311 12.0747L5.84836 13.7503H14.6667C14.9098 13.7503 15.143 13.8469 15.3149 14.0188C15.4868 14.1907 15.5834 14.4239 15.5834 14.667C15.5834 14.9101 15.4868 15.1433 15.3149 15.3152C15.143 15.4871 14.9098 15.5837 14.6667 15.5837H5.84836L7.52403 17.2593C7.6092 17.3444 7.67677 17.4455 7.72288 17.5567C7.769 17.6679 7.79275 17.7871 7.7928 17.9075C7.79284 18.0279 7.76917 18.1472 7.72313 18.2584C7.67709 18.3697 7.60959 18.4708 7.52449 18.5559C7.43938 18.6411 7.33833 18.7087 7.22711 18.7548C7.11589 18.8009 6.99667 18.8247 6.87627 18.8247C6.75587 18.8248 6.63663 18.8011 6.52538 18.755C6.41413 18.709 6.31303 18.6415 6.22786 18.5564L2.98653 15.3151C2.81468 15.1432 2.71814 14.9101 2.71814 14.667C2.71814 14.4239 2.81468 14.1908 2.98653 14.0189L6.22786 10.7776C6.39976 10.6057 6.63288 10.5092 6.87594 10.5092C7.11901 10.5092 7.35213 10.6057 7.52403 10.7776H7.52311ZM14.4769 3.44424C14.6348 3.28641 14.8448 3.1916 15.0676 3.17759C15.2903 3.16358 15.5106 3.23134 15.6869 3.36815L15.7731 3.44424L19.0144 6.68557C19.1723 6.84341 19.2671 7.05342 19.2811 7.2762C19.2951 7.49897 19.2273 7.7192 19.0905 7.89557L19.0144 7.98174L15.7731 11.2222C15.6082 11.3866 15.3868 11.482 15.154 11.4891C14.9212 11.4962 14.6945 11.4145 14.5198 11.2604C14.3451 11.1064 14.2356 10.8916 14.2135 10.6598C14.1914 10.428 14.2584 10.1964 14.4009 10.0122L14.4769 9.92599L16.1517 8.25032H7.33336C7.09972 8.25006 6.875 8.1606 6.70511 8.00021C6.53521 7.83982 6.43298 7.62061 6.41929 7.38738C6.40559 7.15414 6.48148 6.92447 6.63144 6.74531C6.7814 6.56614 6.99411 6.451 7.22611 6.4234L7.33336 6.41699H16.1526L14.4769 4.73949C14.3051 4.56759 14.2086 4.33447 14.2086 4.0914C14.2086 3.84834 14.3051 3.61522 14.4769 3.44332V3.44424Z" fill="white" />
  </svg>
);

export default TransactionArrow;

import React from 'react'
import Wallet from "./Wallet.avif"

export const WalletIcon = ({ width = "20px", height = "20px" }) => {
 return(
  <>
   <img alt="wallet-icon" src={Wallet}style={{height:height,width:width}}/>
  </>
 )
}


/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */

import React from "react";
import fetchApi from "../../../component/_config/api";
import { teamsOptions } from "./utils";
import { Modal } from "../../library";
import { Select, notification } from "antd";
import "./styles.less";
const { Option } = Select;

export const AddToTeamModal = ({ selectedRows }) => {
  const [isModalOpen, setIsModalOpen] = React.useState(false);
  const [teamList, setTeamList] = React.useState([]);
  const [selectedTeam, setSelectedTeam] = React.useState([]);
  const [selectedRole, setSelectedRole] = React.useState([]);
  const [roles, setRoles] = React.useState([
    {
      label: "Maintainer",
      value: "63c964fabdf94af2032b22ba"
    },
    {
      label: "Contributor",
      value: "63d7f7c0e9e0014df368599a"
    }
  ]);

  React.useEffect(() => {
    getTeamsList();
  }, []);

  const showModal = () => {
    setIsModalOpen(true);
  };

  const setModalState = (state) => {
    setIsModalOpen(state);
  };

  const handleChangeTeam = (teams) => {
    setSelectedTeam(teams);
  };

  const handleChangeRole = (teams) => {
    setSelectedRole(teams);
  };

  const getTeamsList = () => {
    fetchApi({ method: "GET", url: "teams", isDevApp: false })
      .then((response) => {
        const { docs = [] } = response;
        const teams = teamsOptions(docs);
        setTeamList([...teams]);
      })
      .catch(() => {
        setTeamList([]);
      });
  };

  const doSelectedToTeam = (action) => {
    if (selectedTeam.length > 0 && selectedRows.length > 0) {
      fetchApi({
        method: "PATCH",
        url: `teams/${action}-developers`,
        isDevApp: false,
        data: { 
          team: selectedTeam,
          developers: selectedRows.map(d => {
            return  {
              "user": d,
              "role": selectedRole
            }
          })
          },
      })
        .then((response) => {
          notification.success({
            message: "Successfully Added/Removed",
            duration: 2,
          });
        })
        .catch(() => {
          notification.error({
            message: "Not Able to Added/Removed",
            duration: 2,
          });
        });
    } else {
      notification.error({
        message: "Add Teams And Developers To Add Or Remove Them",
        duration: 3,
      });
    }
  };

  return (
    <>
      <button className="upgrade-btn add-to-team-button" onClick={showModal}>
        <span className="btn-text">Add To Team</span>
      </button>
      <Modal
        title="Add Developers To Team"
        primaryButtonText="Add"
        isModalOpen={isModalOpen}
        setModalState={setModalState}
        handlerMethod={doSelectedToTeam}
        isSelectedTeam={true}
      >
        <div className="add-to-teams">
          <Select
            size="large"
            mode="single"
            allowClear
            placeholder="Please select the teams"
            bordered={false}
            showArrow={true}
            onChange={handleChangeTeam}
            style={{
              width: 250,
            }}
            options={teamList}
          />
        </div>

        <div className="add-to-teams">
          <Select
            size="large"
            mode="single"
            allowClear
            placeholder="Select role"
            name="role"
            onChange={handleChangeRole}
            bordered={false}
            showArrow={true}
            style={{
              width: 250,
            }}
            >
            {roles && roles?.map((item, index) => (
                <Option key={index} value={item.value}>
                  {item.label}
                </Option>
              ))}
          </Select>
        </div>

      </Modal>
    </>
  );
};

export default AddToTeamModal;

import React, {useState} from "react";
import {Tooltip,Typography, Input, Space, Select, Radio, Checkbox, Form, notification} from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import {ENTERPRISEDEVELOPERROLE, getUserDetails} from "../../../deps";
import "./styles.less";
import {industries, industriesList} from "../constant";
import {onKeyPressInputValidation} from "../../../deps";
import {fetchApi} from "../../../_config/api";
import Skeleton from "react-loading-skeleton";

export const Project = ({ title, description, isLoading,teamMaintainer, globalSetting,industries,otherIndustries, onChange }) => {
    const userData = getUserDetails();
    const [teamList, setTeamList] = useState([]);
    const [industryInputVisible, setIndustryInputVisible] = useState(false);
    const [industryInputValue, setIndustryInputValue] = useState(otherIndustries);
    const onTextChange = (e) => {
    if(e.target.id === "teamId"){
        const value = e.target.value.split(":");
        if(value.length > 0){
            if(value[1] === "Contributor"){
                notification.error({
                    message: "You don't have the access to create dApp",
                    duration: 5,
                });
            }else if(value[1] === "Maintainer"){
                var index = e.nativeEvent.target.selectedIndex;
                onChange(e.target.id, value[0]);
                onChange("teamName",  e.nativeEvent.target[index].text);
            }else{
                notification.error({
                    message: "Please select a team",
                    duration: 5,
                });
            }
        }
    }else{
        if(e.target.id === "otherIndustries"){
            setIndustryInputValue(e.target.value);
        }
        onChange(e.target.id, e.target.value);
    }
  };
    const [form] = Form.useForm();
    form.setFieldsValue({
        "Industries": [],
    });
    let industryLists = [...industries];
    const industryList = industriesList;
    const [selectedIndustries, setSelectedIndustries] = React.useState([...industries]);

    const handleIndustryChange = (selectedOptions) => {
        const hasOthers = selectedOptions.includes("Others");
        if (hasOthers) {
            setIndustryInputVisible(true);
        } else {
            setIndustryInputVisible(false);
        }
        setSelectedIndustries(selectedOptions);
    };
    React.useEffect(() => {
        onChange("industries", selectedIndustries);
    }, [selectedIndustries]);
    React.useEffect(() => {
        if(industries.includes("Others")){
            setIndustryInputVisible(true);
        }
        if(userData.profile.userRole === ENTERPRISEDEVELOPERROLE){
            getTeamsList();
        }
    }, []);
    const getTeamsList = () => {
        fetchApi({ method: "GET", url: "projects/team-list", isDevApp: false })
            .then((response) => {
                const { docs = [] } = response;
                if(docs.length > 0){
                    setTeamList([...docs]);
                }else{
                    setTeamList([]);
                }
            })
            .catch(() => {
                setTeamList([]);
            });
    };
    return (
    <div className="column-container">
      <Space direction="vertical" size={30}>
          {userData.profile.userRole === ENTERPRISEDEVELOPERROLE &&
              <>
                  <select
                      className="dropdown-filter create-collection-drop"
                      placeholder="Team"
                      name="teamId"
                      id="teamId"
                      style={{maxWidth:"750px"}}
                      onChange={onTextChange}
                  >
                      <option key={"00"} value={""}>
                          Select Team
                      </option>
                      {teamList.map((team, ind) => (<option key={team.teamId} value={team.teamId+":"+team.roleName}>{team.name}</option>))}
                  </select>
              </>
          }
        <Typography.Text className="label-text d-inline label-text-22">
          Create dApp
            {/*<p className="subheader mt-2">This area describes the decentralized application you are building</p>*/}
            {/*<Tooltip title={"This area describes the decentralized application you are building"}><InfoCircleOutlined style={{cursor:"pointer"}}/></Tooltip>*/}
        </Typography.Text>
        {isLoading?(<Skeleton height={45} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
        <>
            <div className="d-grid">
                <label className="dapp-form-label">Give a unique name to your dApp</label>
                <Input
                    id="title"
                    className="input-container"
                    placeholder="E.g. Asset Tokenization"
                    defaultValue={title}
                    onChange={onTextChange}
                    onKeyPress={onKeyPressInputValidation}
                    maxLength={50}
                />
            </div>
        </>
        )}
        {isLoading?(<Skeleton height={130} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
           <>
               <div className="d-grid">
                   <label className="dapp-form-label">Give a brief description</label>
                   <Input.TextArea
                       rows={3}
                       id="description"
                       className="input-container"
                       placeholder="E.g. App for real world asset tokenization"
                       defaultValue={description}
                       onChange={onTextChange}
                       onKeyPress={onKeyPressInputValidation}
                       maxLength={500}
                   />
               </div>
           </>
        )}
          {/*<div>
          {isLoading?(<Skeleton height={50} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
              <Select
                  style={{width:'100%',maxWidth:'750px',height:'fit-content',minHeight:'50px',paddingTop:'10px',paddingBottom:'10px',fontSize:'16px'}}
                  mode="multiple"
                  className="dropdown-filter"
                  placeholder="Select industries"
                  value={selectedIndustries}
                  onChange={handleIndustryChange}
              >
                  {industriesList.map((industry) => (
                      <Select.Option key={industry.value} value={industry.value}>
                          {industry.label}
                      </Select.Option>
                  ))}
              </Select>
          )}
          </div>*/}
          <div>
          {industryInputVisible && (
              <Input
                  className="input-container"
                  id="otherIndustries"
                  style={{ width: "100%", maxWidth: "750px", height: "fit-content", minHeight: "50px", paddingTop: "10px", paddingBottom: "10px", fontSize: "16px" }}
                  placeholder="Enter Other Industry"
                  value={industryInputValue}
                  onChange={onTextChange}
              />
          )}
    </div>
          <Input
              id="teamMaintainer"
              className="input-container"
              placeholder="Maintainer ID"
              defaultValue={userData.profile.developerId}
              onChange={onTextChange}
              disabled={true}
              style={{display:"none"}}
          />

      </Space>
    </div>
  );
};

export default Project;

import {DeleteOutlined} from "@ant-design/icons";
import React from "react";

function TableRows({ rows, tableRowRemove, onValUpdate }) {
    return rows.map((rowsData, index) => {
        const { type,value } = rowsData;
        return (
            <tr key={index}>
                <td>
                    <select
                        style={{width:200,height:50}}
                        className="dropdown-filter input-container"
                        placeholder="Choose Data Type"
                        name="type"
                        value={type}
                        onChange={(event) => onValUpdate(index, event)}
                    >
                        <option disabled>Data Type</option>
                        <option value="string">string</option>
                        <option value="uint">uint</option>
                        <option value="bool">bool</option>
                        <option value="address">address</option>
                    </select>
                </td>
                <td>
                    <input
                        style={{width:200,height:50}}
                        value={value}
                        className="input-container"
                        placeholder="Value"
                        onChange={(event) => onValUpdate(index, event)}
                        name="value"
                    />
                </td>
                <td>
                    <DeleteOutlined style={{marginTop:15}} onClick={() => tableRowRemove(index)}/>
                </td>
            </tr>
        );
    });
}
export default TableRows;
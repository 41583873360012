import Skeleton from "react-loading-skeleton";
import {TableContent} from "../../../library/table";
import {PaginationSection} from "../../../library/paginationSection";
import React, {useEffect, useState} from "react";
import {dateformatter, getAuthToken, getDAppId, getPATTokenDetails, getUserDetails} from "../../../deps";
import {Link, useLocation, useParams} from "react-router-dom";
import {fetchApi, fetchApiGw} from "../../../_config/api";
import {CopyOutlined, LoadingOutlined} from "@ant-design/icons";
import "./styles.less";
import {Card} from "../../../library";
import {schemaJson} from "../DappDetails/schema";
import {Form, notification, Modal,Space, Spin,Tooltip,Image,Button,Upload} from "antd";
import { SchemaForm, createAsyncFormActions } from '@uform/antd';
import { registerFormField, connect } from '@uform/react-schema-renderer';
import {setFormData} from "../../nftstudio/utils";
import ReactJson from "react-json-view";
import TextArea from "antd/es/input/TextArea";
import { CardGradient } from "../../../library/card";


const actions = createAsyncFormActions();

const STATUS = {
    ACTIVE: "ACTIVE",
    PENDING: "PENDING",
    IN_ACTIVE: "IN-ACTIVE",
    FAILED: "FAILED",
    ALL: "ALL",
}
export const NcWalletPage = ({showLoader,isLoading}) => {
    const gwURL = process.env.REACT_APP_GATEWAY_URL;
    const {
        profile: { userRole, subscriptionId, userId },
    } = getUserDetails();
    const token = getPATTokenDetails();
    const authToken = token.patToken;
    const { instanceId } = useParams();
    const [collections, setCollections] = useState([]);
    const [instance, setInstance] = useState(null);
    const [instanceList, setInstanceList] = useState([]);
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: STATUS.ALL,
        chainOption: ""
    });
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [hitsList, setHitsList] = useState([]);
    const [totalCount, setTotalCount] = useState(0);
    const [addContractOpen, setAddContractOpen] = useState(false);

    const formRef = React.useRef(null);

    const [walletList, setWalletList] = React.useState([]);
    const [walletTypeSubList, setWalletTypeSubList] = useState([]);
    const [schema, setSchema] = useState(null);
    const [schemaDatas, setSchemaDatas] = useState(null);
    const location = useLocation()
    const walletURL = process.env.REACT_APP_GATEWAY_URL;
    const [custodialWallets, setCustodialWallets] = useState([]);
    const [dropDownWalletList, setDropDownWalletList] = useState([]);
    const [loading, setLoading] = useState(false);
    const [responseJson,setResponseJson] = React.useState(null);
    const [selectedWallet,setSelectedWallet] = React.useState("");
    const [subWalletType,setSubWalletType] = React.useState("");
    const [showFormLayout, setShowFormLayout] = useState(false);
    const walletSetupURL = process.env.REACT_APP_NCWALLET_INSTRUCTION_URL;
    const apiRefURL = process.env.REACT_APP_NC_WALLET_DOC_URL;
    const sdkURL = process.env.REACT_APP_WALLET_SDK_DOC_URL;
    const [curlCommand, setCurlCommand] = useState(null);

    useEffect(() => {
        getWalletList();
        fetchSchema();
        onGetCollection();
        onGetWalletList();

    }, []);
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    const fetchSchema = async () => {
        try {
            const response = schemaJson
            console.log("response",response)

            setSchemaDatas(response[0]);
            console.log("schemaData")
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    useEffect(() => {
        getAllInstance();
    }, [subscriptionId, token.patToken]);
    useEffect(() => {
        // onGetCollection();
    }, [instance]);
    const getAllInstance = () => {
        showLoader(true);
        const options = {
            method: "POST",
            headers: {
                'Content-Type': 'application/json',
                'Authorization': authToken
            },
            body: JSON.stringify({ "services": ["smart-contract-studio"] })
        };
        console.log("Options ", options)
        fetch(`${gwURL}/api/v0/instance/getInstance`, options)
            .then(response => response.json())
            .then((response) => {
                showLoader(false);
                if (response.Status === "SUCCESS") {
                    const { Data = [] } = response
                    if (response.Data) {
                        setInstanceList([...Data]);
                        const instanceObj = Data.find(e => e.instanceId === instanceId);
                        if (instanceObj && !instanceObj?.instanceId) {
                            notification.error({
                                message: "instanceId not found",
                                duration: 3,
                            });
                        }
                        setInstance(instanceObj);
                    } else {
                        setInstanceList([]);
                    }
                }
            })
            .catch((error) => {
                setInstanceList([]);
                showLoader(false);
                notification.error({
                    message: error.toString(),
                    duration: 3,
                });
            });


    };
    const onGetWalletList =()=>{
        let url = "";
        let payload = {}
        url = "/api/v0/ncWallet/getAllWalletsBySubId";
        payload = {
            "mode":"api",
            "instanceId": "",
        }

        fetchApiGw(
            {
                method: 'POST',
                url: walletURL+url,
                data: payload,useBase:false,isDevApp:false
            }).then(response =>{
            if (response.Status === "SUCCESS") {
                if (response.Data) {
                    setDropDownWalletList(response.Data)
                } else {
                    setDropDownWalletList([]);
                }
            }
        }).catch((error) => {
            setDropDownWalletList([]);
        });
    }
    const onGetCollection =()=>{
        showLoader(true);
        let walletInsId = "";
        let url = "";
        let payload = {}
            url = "/api/v0/ncWallet/getAllWalletsListBySubId";
            //walletInsId = location.state.ncWalletInstanceId;
            payload = {
                "mode":"api",
                // "serviceAPIKey":location.state.accessToken,
                "instanceId": "",
                "page":pageSetting.page-1,
                "pageSize":pageSetting.itemsPerPage
            }

        fetchApiGw(
            {
                method: 'POST',
                url: walletURL+url,
                data: payload,useBase:false,isDevApp:false
            }).then(response =>{
                console.log("status----------->",response.Status)
            if (response.Status === "SUCCESS") {
                if (response.Data.result) {
                    console.log("response.Data.result)---->",response.Data.result)
                    setCustodialWallets(response.Data.result);
                    setTotalCount(response.Data.totalCount);
                } else {
                    setTotalCount(0);
                    setCustodialWallets([]);
                }
                showLoader(false);
            }
        }).catch((error) => {
            setCustodialWallets([]);
            showLoader(false);
            setTotalCount(0);
        });
    }


    const ContractTable =[
        {
            title: "",
            dataIndex: "",
        },
        {
            title: <span style={{whiteSpace:'nowrap'}}>Wallet Name</span>,
            dataIndex: "name",
        },
        {
            title: <span style={{whiteSpace:'nowrap'}}>Wallet Address</span>,
            dataIndex: "address",
        },
        {
            title: <span style={{whiteSpace:'nowrap'}}>Instance Id</span>,
            dataIndex: "instance_id",
        },
        {
            title:  <span style={{whiteSpace:'nowrap'}}>Created On</span>,
            dataIndex: "created_at",
        },
        {
            title:  <span style={{whiteSpace:'nowrap'}}>Wallet Reference</span>,
            dataIndex: "wallet_id",
        },
        {
            title: <div style={{whiteSpace:'nowrap'}} >Action <span style={{visibility:'hidden'}}>Action----</span></div>,
            dataIndex: "action",
            render: (_, { name,is_locked,instance_id, wallet_id, address }) => (
                address !== "" ?
                <div style={{display:"flex",color:"white"}}>
                    <Link style={{color:"white"}}  to={"/ncWalletDetails-page"} state={{name,address,instance_id, wallet_id}}>
                        <button className="new-button">
                            {"Manage"}
                        </button>
                    </Link>
                    &nbsp;&nbsp;
                    <button className="new-button" onClick={() => handleButtonClick(is_locked, instance_id, wallet_id)}>
                        {is_locked ? "Unlock" : "Lock"}
                    </button>
                </div> : <></>
            ),
        },
    ];
    const handleButtonClick = (is_locked, instance_id, wallet_id) => {
        if (is_locked) {
            Activate(instance_id, wallet_id)
            console.log("Unlocking...");
        } else {
            Deactivate(instance_id, wallet_id)
            console.log("Locking...");
        }
    };
    const Deactivate =(instance_id, wallet_id)=>{
        // setIsLoading(true);
        fetchApiGw(
            {
                method: 'POST',
                url:walletURL+"/api/v0/ncWallet/deactivateWallet",
                data:{
                    "mode":"api",
                    "serviceAPIKey": wallet_id,
                },
                useBase:false,isDevApp:false,instanceId:instance_id
            })
            .then(data => {
                    console.log("data",data.Data)
                    if (data) {
                        // setIsLoading(false);
                        notification.success({
                            message:data.Message,
                            duration:2
                        })
                        onGetCollection()

                    } else {
                        // setIsLoading(false);
                        notification.error({
                            message:data.Message,
                            duration:2
                        })
                    }
                }
            );

    }
    const Activate =(instance_id, wallet_id)=>{
        // setIsLoading(true);
        fetchApiGw(
            {
                method: 'POST',
                url:walletURL+"/api/v0/ncWallet/activateWallet",
                data:{
                    "mode":"api",
                    "serviceAPIKey": wallet_id,
                },
                useBase:false,isDevApp:false,instanceId:instance_id
            })
            .then(data => {
                    console.log("data",data.Data)
                    if (data) {
                        // setIsLoading(false);
                        notification.success({
                            message:data.Message,
                            duration:2
                        })
                        onGetCollection()

                    } else {
                        // setIsLoading(false);
                        notification.error({
                            message:data.Message,
                            duration:2
                        })
                    }
                }
            );

    }
    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        onGetCollection();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        onGetCollection();
    };

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };
    const addContractModal = () => {
        setAddContractOpen(!addContractOpen);
    };



    const getWalletList = () => {
        // fetchApi({ method: "GET", url: "teams/getAllTeams", isDevApp: false })
        //     .then((response) => {
        //         const { docs = [] } = response;
        //         if(docs.length > 0){
        // setTeamList([...docs]);
        setWalletList([
            {walletId:"DevWallet",walletType:"Dev Wallet"},{walletId:"SelfManagedWallet",walletType:"Self Managed Wallet"}
        ]);

        // }else{
        //     setTeamList([]);
        //     }
        // })
        // .catch(() => {
        //     setTeamList([]);
        // });
    };




    const onChangeSelectWalletType = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setSelectedWallet(event.target.value);
        console.log("event.target.value",event.target.value)
        setWalletTypeSubList([
            {walletId:"createWallet",walletType:"Create Wallet"},
            {walletId:"getBalance",walletType:"Get Balance"},
            {walletId:"signMessage",walletType:"Sign Message"},
            {walletId:"verifySignatureOffChain",walletType:"Verify Signature OffChain"},
            {walletId:"deployContract",walletType:"Deploy Contract"},
            {walletId:"submitTransaction",walletType:"Submit Transaction"},
            {walletId:"estimateGas",walletType:"Estimate Gas"},
            {walletId:"callContract",walletType:"Call Contract"},
            {walletId:"signAndSubmitGaslessTxn",walletType:"Sign And Submit Gasless Tx"},
        ])
        // if(event.target.value === "DevWallet"){
        //     setWalletTypeSubList([
        //         {walletId:"deployDevWalletContract",walletType:"Deploy Contract"},
        //         {walletId:"callDevWalletContract",walletType:"Call Contract"},
        //         {walletId:"getDevWalletBalance",walletType:"Get Balance"},
        //         {walletId:"signDevWalletAndSubmitGaslessTx",walletType:"Sign And Submit Gasless Tx"},
        //         {walletId:"createDevWalletAndExecuteTx",walletType:"Create And Execute Tx"},
        //         {walletId:"signDevWalletEIP712Tx",walletType:"Sign EIP712 Tx"},
        //         {walletId:"verifyDevWalletSignatureOffChain",walletType:"Verify Signature OffChain"},
        //         {walletId:"signDevWalletMessage",walletType:"Sign Message"},
        //         {walletId:"signDevWalletTxHash",walletType:"Sign Tx Hash"},
        //         {walletId:"estimateDevWalletGas",walletType:"Estimate Gas"},
        //     ])
        // }else if(event.target.value === "SelfManagedWallet"){
        //     setWalletTypeSubList([
        //         {walletId:"createSMWWallet",walletType:"Create Wallet"},
        //         {walletId:"getSMWWalletBalance",walletType:"Get Balance"},
        //         {walletId:"signSMWWalletMessage",walletType:"Sign Message"},
        //         {walletId:"verifySMWWalletSignatureOffChain",walletType:"Verify Signature OffChain"},
        //         {walletId:"deploySMWWalletContract",walletType:"Deploy Contract"},
        //         {walletId:"submitSMWWalletTransaction",walletType:"Submit Transaction"},
        //         {walletId:"estimateSMWWalletGas",walletType:"Estimate Gas"},
        //         {walletId:"callSMWWalletContract",walletType:"Call Contract"},
        //         {walletId:"signSMWWalletAndSubmitGaslessTx",walletType:"Sign And Submit Gasless Tx"},
        //     ])
        // }
    };

    const onInputChangeCurl = (values,subTokenType) => {
        let data = {};
        const selectedProperties = schema.properties;

        for (const key in selectedProperties) {
            data[key] = selectedProperties[key].type;
        }
        for (const key in values) {
            if (data.hasOwnProperty(key)) {
                if(values[key] !== undefined && values[key] !== NaN){
                    data[key] = values[key];
                }
            }
        }
        let url = data.walletURL+"/wallet/"+subWalletType;
        console.log("url------------>",url)
        const filteredData = Object.fromEntries(
            Object.entries(data).filter(([key, value]) => value !== undefined && key !== "walletURL")
        );
        generateCurlCommand(url,filteredData)
    }

    const onChangeSelectSubWalletType = async (event, name) => {
        document.getElementById('response-box').style.display = 'none';
        actions.reset({validate:false});
        if(event.target.value !== ""){
            setShowFormLayout(true);
        }
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        // console.log("infraType ",event.target.value);
        setSubWalletType(event.target.value);
        console.log('obj1w:', event.target.value);
        console.log('obj1:', schemaDatas);

        if (schemaDatas) {
            // const schemaData = await response.json();
            // console.log('schemaData:', schemaData);
            console.log('obj1:', schemaDatas.properties.deployContract);
            setSchema(schemaDatas.properties[event.target.value]);

            // if(event.target.value === "mintFT"){
            //     setSchema(schemaDatas.properties.mintFT);
            //
            // }else{
            //     setSchema(schemaDatas.properties.burnFT);
            //
            // }

        } else {
            console.error('Failed to fetch schema:', schemaDatas);
        }
        createCurlData(event.target.value);
        // console.log('response:', response);
        //
        // const schemaData = await response.json();
        // console.log('response:', schemaData);
        //
        //     console.log('obj1:', schemaData.properties.mintFT);

    };


    const createCurlData = (subWalletType) => {
        console.log("subWalletType",subWalletType)
        setSchema("");
        setCurlCommand("");
        if (schemaDatas) {
            setSchema(schemaDatas.properties[subWalletType]);
            const data={};
            const selectedProperties = schemaDatas.properties[subWalletType].properties;
            for (const key in selectedProperties) {
                data[key] = selectedProperties[key].type;
            }
            let host = "";
            if(data.walletURL !== ""){
                host = data.walletURL;
            }
            let url = host+"/wallet/"+subWalletType;
            const filteredData = Object.fromEntries(
                Object.entries(data).filter(([key, value]) => value !== undefined && key !== "walletURL")
            );
            generateCurlCommand(url,filteredData)
        }
    }
    const generateCurlCommand = (url,data) => {
        let curlCommand = `curl -X POST "${url}" \\
         -H "Content-Type: application/json" \\
         -d '${JSON.stringify(data)}`;
        setCurlCommand(curlCommand)
    };
    const handleSubmit = (values) => {
        const data = Object.fromEntries(
            Object.entries(values).filter(([key, value]) => value !== undefined)
        );
        let url = data.walletURL+"/wallet/"+subWalletType;
        const filteredData = Object.fromEntries(
            Object.entries(values).filter(([key, value]) => value !== undefined && key !== "walletURL")
        );
        setLoading(true);
        fetch(url,{ method: "POST",
            headers: { 'Content-Type': 'application/json'},
            body:JSON.stringify(filteredData)
        }).then(response => response.json())
            .then(data => {
                document.getElementById('response-box').style.display = 'block';
                setResponseJson(data);
                showLoader(false);
                createCurlData(subWalletType);
                getWalletList();
                onGetWalletList();
                onGetCollection();
                actions.reset({validate:false});
                setLoading(false);
            })
            .catch((error) => {
                document.getElementById('response-box').style.display = 'block';
                setResponseJson(error);
                showLoader(false);
                actions.reset({validate:false});
                setLoading(false);
            });
        // let url = gwURL+"/api/v0/";
        // let instanceId = "";
        // if(selectedWallet !== ""){
        //     {dropDownWalletList.map((wallet, ind) => {
        //         if(wallet.walletId === selectedWallet){
        //             console.log("wallet------->",wallet);
        //             console.log("instanceId------->",wallet.instanceId);
        //             instanceId = wallet.instanceId;
        //         }
        //     })}
        // }
        // console.log("selectedWallet------->",selectedWallet);
        // // console.log("instanceId------->",instanceId);
        // console.log("data------->",data);
        // console.log("url------->",url);
        // fetchApiGw({
        //     method: "POST",
        //     url: url,
        //     isDevApp: false,
        //     useBase:false,
        //     data: data,
        //     instanceId: instanceId,
        //     // chainId:networks,
        //     // dAppID: projectId
        // })
        //     .then((response) => {
        //         notification.destroy();
        //         if(response && Object.keys(response).length > 0){
        //             if(response.Status === "SUCCESS") {
        //                 setLoading(false);
        //                 // notification.success({
        //                 //     message: response.Message,
        //                 //     duration: 5,
        //                 // });
        //                 // Show JSON response data in a box
        //                 // if (response.Data.result && Object.keys(response.Data.result).length > 0) {
        //                 //     showResponseData(response.Data.result);
        //                 // }
        //                 // actions.reset();
        //                 document.getElementById('response-box').style.display = 'block';
        //                 setResponseJson(response);
        //             } else{
        //                 setLoading(false);
        //                 actions.reset();
        //                 // notification.error({
        //                 //     message:response.Message,
        //                 //     duration: 5,
        //                 // });
        //                 document.getElementById('response-box').style.display = 'block';
        //                 setResponseJson(response);
        //             }
        //         }
        //     })
        //     .catch((error) => {
        //         setLoading(false);
        //         // console.error(error);
        //         // setDeployDisabled(false);
        //         actions.reset();
        //         // notification.destroy();
        //         // notification.error({
        //         //     message: error.message,
        //         //     duration: 3,
        //         // });
        //         document.getElementById('response-box').style.display = 'block';
        //         setResponseJson(error);
        //     });
    };
    const scrollToApiSandbox = () => {
        const apiSandboxHeader = document.getElementById('api-sandbox-header');
        if (apiSandboxHeader) {
            apiSandboxHeader.scrollIntoView({ behavior: 'smooth' });
        }
    };


    return (
        <div className="transaction-page-container">
            <div className="card-container grid-repeat-container marginBtm50 mt-lg-1">
                {/*{isLoading ? (<Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (*/}
                <a href={walletSetupURL} target="_blank" rel="noreferrer">
                    <CardGradient
                        text={<span style={{fontWeight: '700'}}>Wallet Setup</span>}
                        color="card4"
                    />
                </a>
                {/*)}*/}

                {/*{isLoading ? (<Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (*/}
                <a href={apiRefURL} target="_blank" rel="noreferrer">
                    <CardGradient

                        text={<span style={{fontWeight: '700'}}>API Reference</span>}
                        color="card2"
                    />
                </a>
                {/*)}*/}

                {/*{isLoading ? (<Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (*/}
                <a href={sdkURL} target="_blank" rel="noreferrer">
                    <CardGradient

                        text={<span style={{fontWeight: '700'}}>SDK Reference</span>}
                        color="card3"
                    />
                </a>
                    {/*)}*/}

                    {/*{isLoading ? (<Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (*/}
                <a onClick={scrollToApiSandbox}>
                    <CardGradient
                        text={<span style={{fontWeight: '700'}}>API Sandbox</span>}
                        color="card4"
                    />
                </a>
                {/*)}*/}
            </div>

            <div className="table-container">
                <div style={{display: "flex", justifyContent: "space-between"}} className="header-section">
                    <div className="text">My Wallets</div>
                </div>
                {isLoading ? (<Skeleton height={600} style={{marginTop: '30px'}} baseColor="#262626"
                                        highlightColor="#404040"></Skeleton>) : (
                    <TableContent
                        rowKey="developerId"
                        data={custodialWallets}
                        columns={ContractTable}
                        isDashboard={false}
                        pagination={false}
                    />)}
            </div>
            <PaginationSection
                last={last}
                first={startIndex + 1}
                hitsList={hitsList}
                totalCount={totalCount}
                page={pageSetting.page}
                itemsPerPage={pageSetting.itemsPerPage}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
            />

            <div className="header-section" id="api-sandbox-header">
                <div className="text">API Sandbox</div>
            </div>
            <div className="dapp-details-select-items1">

                <Space
                    direction="horizontal"
                    size={25}
                >
                    <Form
                        name="basic"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        // onFinish={onCreateEndpoint}
                        autoComplete="off"
                    >


                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="wallet"
                            name="wallet"
                            onChange={(event) => onChangeSelectWalletType(event, "wallet")}
                        >
                            <option value="" disabled selected>Choose Wallet</option>
                            {dropDownWalletList.map((wallet, ind) => (
                                <option key={wallet.walletId} value={wallet.walletId}>{wallet.walletName}</option>))}
                        </select>

                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="subWalletType"
                            name="subWalletType"
                            onChange={(event) => onChangeSelectSubWalletType(event, "subWalletType")}
                        >
                            <option value="" disabled selected>Choose Action</option>
                            {walletTypeSubList.map((wallet, ind) => (
                                <option key={wallet.walletId} value={wallet.walletId}>{wallet.walletType}</option>
                            ))}
                            {walletTypeSubList.length === 0 && (
                                <option value="createWallet">Create Wallet</option>
                            )}
                        </select>


                    </Form>
                </Space>
            </div>
            <br/>
            {showFormLayout && (
                <div className="form-sandbox-layout">
                    <div className="form-layout">
                        <SchemaForm actions={actions} schema={schema} onSubmit={handleSubmit} labelCol={11} wrapperCol={10}
                                    onChange={(values) => onInputChangeCurl(values, subWalletType)}>

                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                icon={loading ? antIcon : null}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    gap: '8px',
                                    marginLeft: '470px',
                                    width: '142px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Send Request
                            </Button>
                    </SchemaForm>
                </div>
                    <div className="code-layout" style={{ position: 'sticky' }}>
                        <TextArea
                            placeholder="//"
                            style={{ height: '600px', overflowY: 'auto', paddingTop: "40px" }}
                            className="curl-code-layout"
                            value={curlCommand}
                        />
                        <div>
                            <button className="copy-button" onClick={() => onCopy(curlCommand)}>
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            )}


            <div id="response-box" style={{display: "none"}}>
                <div
                    style={{
                        backgroundColor: "#383838",
                        padding: "10px",
                        overflowY: "auto",
                        maxHeight: "500px",
                        borderRadius: "20px",
                        marginTop: "50px"
                    }}
                >
                    <ReactJson style={{backgroundColor: "white", marginTop: 20, overflowX: "auto"}}
                               enableClipboard={true} src={responseJson}/>

                </div>
            </div>
        </div>
    );
}

import React from "react";

export const Locked = ({ width = "23px", height = "23px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 23 23"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_locked)">
      <path
        d="M16.2917 6.70841C16.2917 4.06629 14.1422 1.91675 11.5 1.91675C8.85792 1.91675 6.70837 4.06629 6.70837 6.70841V9.58341H5.75004C4.693 9.58341 3.83337 10.443 3.83337 11.5001V19.1667C3.83337 20.2238 4.693 21.0834 5.75004 21.0834H17.25C18.3071 21.0834 19.1667 20.2238 19.1667 19.1667V11.5001C19.1667 10.443 18.3071 9.58341 17.25 9.58341H8.62504V6.70841C8.62504 5.12333 9.91496 3.83341 11.5 3.83341C13.0851 3.83341 14.375 5.12333 14.375 6.70841H16.2917ZM17.25 11.5001L17.252 19.1667H5.75004V11.5001H17.25Z"
        fill="#20CD89"
      />
      <path
        d="M11.5 1.91675C8.85792 1.91675 6.70837 4.06629 6.70837 6.70842V9.58342H5.75004C4.693 9.58342 3.83337 10.443 3.83337 11.5001V19.1667C3.83337 20.2238 4.693 21.0834 5.75004 21.0834H17.25C18.3071 21.0834 19.1667 20.2238 19.1667 19.1667V11.5001C19.1667 10.443 18.3071 9.58342 17.25 9.58342H16.2917V6.70842C16.2917 4.06629 14.1422 1.91675 11.5 1.91675ZM17.25 11.5001L17.252 19.1667H5.75004V11.5001H17.25ZM8.62504 9.58342V6.70842C8.62504 5.12333 9.91496 3.83341 11.5 3.83341C13.0851 3.83341 14.375 5.12333 14.375 6.70842V9.58342H8.62504Z"
        fill="#646464"
      />
    </g>
    <defs>
      <clipPath id="clip_locked">
        <rect width="23" height="23" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

import React from "react";
import { fetchApi } from "../../_config/api";
import { notification } from "antd";

export const UserLockScreen = () => {
  const getUnlock = () => {
    fetchApi({ method: "POST", url: `unlock` })
      .then((response) => {
        notification.success({
          message: response?.message,
          duration: 3,
        });
      })
      .catch(() => {});
  };
  return (
    <div className="userLock">
      <h1>
        Your profile has been locked please send request to concerned person
      </h1>
      <button className="view-details" onClick={getUnlock}>
        Send Request
      </button>
    </div>
  );
};

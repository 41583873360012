import React from "react";
import { Dashboard } from "./dashboard";
import { Project } from "./project";
import { Transaction } from "./transaction";
import { MyAccount } from "./myAccount";
import { Help } from "./help";
import { Logout } from "./logout";
import { Logo } from "./logo";
import { Rocket } from "./rocket";
import { Bell } from "./bell";
import { Credits } from "./credits";
import { Request } from "./request";
import { Locked } from "./locked";
import { Unlocked } from "./unlocked";
import { Copy } from "./copy";
import { Filter } from "./filter";
import { CreditCard } from "./creditCard";
import { Percentage } from "./percentage";
import { Close } from "./close";
import { Tick } from "./tick";
import { TickOutline } from "./tickoutine";
import { TickInner } from "./tickInner";
import { Developers } from "./developers";
import { Disabled } from "./disabled";
import { Active } from "./active";
import { DropdownUpArrow } from "./dropdownUpArrow";
import { NFTstudio } from "./nftstudio";
import { UploadFile } from "./upload-file";
import { AddKey } from "./addKey";
import { SmartContract } from "./smartContract";
import { Chain } from "./chain";
import { TransactionArrow } from "./transactionArrow";
import { UniqueAddress } from "./uniqueAddress";
import { WalletIcon } from "./WalletIcon";
import {AccountSettings} from "./accountSettings";
import {Edit} from "./Edit";
import {Delete} from "./delete";
import {Refresh} from "./refresh";
import {Transfer} from "./transfer";
import {Inactive} from "./Inactive";
import {ProjectRevamp} from "./prj_revamp";
import {Endpoints} from "./endpoints";
import {Didmanager} from "./didmanager";
import {Advanced} from "./advanced";
import {AIAssistant} from "./ai-assistant";
import {Addon} from "./addon";
import {TickDefault} from "./tickdefault";
import {Faucet} from "./faucet";
import {DashboardEndpoints} from "./endpoints-dashboard";
import {DashboardProject} from "./dashboardproject";

export const Menus = ({ type = "", ...attributes }) => {
  //console.log("Types:: ",type);
  return (
    <>
      {(() => {
        switch (type?.toLowerCase()) {
          case "dashboard":
            return <Dashboard {...attributes} />;
          case "endpoints":
            return <Endpoints {...attributes} />;
          case "ds-endpoints":
            return <DashboardEndpoints {...attributes} />;
          case "faucet":
            return <Faucet {...attributes} />;
          case "dapp studio":
            return <Project {...attributes} />;
          // case "endpoint":
          //   return <Endpoints {...attributes} />;
          case "teams":
            return <Project {...attributes} />;
          case "ds-teams":
            return <DashboardProject {...attributes} />;
          case "developers":
            return <Developers {...attributes} />;
          case "api history":
            return <Transaction {...attributes} />;
          case "datalake":
            return <Transaction {...attributes} />;
          case "addons":
            return <Addon {...attributes} />;
          case "wallet manager":
            return <WalletIcon {...attributes} />;
          case "my account":
            return <MyAccount {...attributes} />;
          case "account settings":
            return <AccountSettings {...attributes} />;
          case "help":
            return <Help {...attributes} />;
          case "logout":
            return <Logout {...attributes} />;
          case "logo":
            return <Logo {...attributes} />;
          case "rocket":
            return <Rocket {...attributes} />;
          case "bell":
            return <Bell {...attributes} />;
          case "credit":
            return <Credits {...attributes} />;
          case "request":
            return <Request {...attributes} />;
          case "locked":
            return <Locked {...attributes} />;
          case "unlocked":
            return <Unlocked {...attributes} />;
          case "copy":
            return <Copy {...attributes} />;
          case "filter":
            return <Filter {...attributes} />;
          case "creditcard":
            return <CreditCard {...attributes} />;
          case "percentage":
            return <Percentage {...attributes} />;
          case "close":
            return <Close {...attributes} />;
          case "tick":
            return <Tick {...attributes} />;
          case "tick-outline":
            return <TickOutline {...attributes} />;
          case "tick-innerline":
            return <TickInner {...attributes} />;
          case "tick-default":
            return <TickDefault {...attributes} />;
          case "disabled":
            return <Disabled {...attributes} />;
          case "active":
            return <Active {...attributes} />;
          case "dropdown uparrow":
            return <DropdownUpArrow {...attributes} />;
          case "easy nft":
            return <NFTstudio {...attributes} />;
          case "enablers":
            return <NFTstudio {...attributes} />;
          case "upload file":
            return <UploadFile {...attributes} />;
          case "addkey":
            return <AddKey {...attributes} />;
          case "smart contract studio":
            return <SmartContract {...attributes} />;
          case "web3 services":
            return <SmartContract {...attributes} />;
          case "chain":
            return <Chain {...attributes} />;
          case "transaction arrow":
            return <TransactionArrow {...attributes} />;
          case "unique address":
            return <UniqueAddress {...attributes} />;
          case "edit":
            return <Edit {...attributes} />;
          case "delete":
            return <Delete {...attributes} />;
          case "refresh":
            return <Refresh {...attributes} />;
          case "transfer":
            return <Transfer {...attributes} />;
          case "inactive":
            return <Inactive {...attributes} />;
          case "did-manager":
            return <Didmanager {...attributes} />;
          case "add-ons":
            return <Advanced {...attributes} />;
          case "krypcore ai":
            return <AIAssistant {...attributes} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

import {dateformatter, getUserDetails} from "../../../deps";
import {Link} from "react-router-dom";
import {notification, Tooltip} from "antd";
import React from "react";
import {CopyOutlined, EyeOutlined, InfoCircleOutlined} from "@ant-design/icons";
import {AccountSettings} from "../../../assets/menus/accountSettings";

const { userRole } = getUserDetails().profile || {};

const onCopy = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    notification.success({
        message: "Copied to clipboard",
        duration: 3,
    });
};
export const EntColumns =[
    {
        title: "Endpoint Name",
        dataIndex: "endpointName",
        render: (_, { endpointName,_id,infraType }) => (
            <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                {endpointName}
            </Link>
        )
    },
    {
        title: "Protocol Name",
        dataIndex: "protocolName",
    },
    {
        title: "Network",
        dataIndex: "network",
    },

    {
        title: "User Endpoint",
        dataIndex: "userEndpoint",
        render: (_, { userEndpoint,_id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    {userEndpoint}
                </Link>
            </>
        ),
    },
    {
        title: "",
        dataIndex: "userEndpoint",
        render: (_, { userEndpoint,_id }) => (
            <a>
                <CopyOutlined onClick={() => onCopy(userEndpoint)} />
            </a>
        ),
    },

    {
        title: "dApp ID",
        dataIndex: "projectID",
    },
    {
        title: "Team ID",
        dataIndex: "teamID",
    },

    {
        title: "Status",
        dataIndex: "status",
    },
    {
        title: 'Action',
        dataIndex: '',
        render: (_, { _id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    <AccountSettings width="20px" height="20px" />
                </Link>
            </>
        ),
    }
];
export const EntStorageandDidcolumns = [
    {
        title: "Endpoint Name",
        dataIndex: "endpointName",
        render: (_, { endpointName,_id,infraType }) => (
            <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                {endpointName}
            </Link>
        )
    },
    {
        title: "Protocol",
        dataIndex: "protocolName",
    },
    {
        title: "User Endpoint",
        dataIndex: "userEndpoint",
        render: (_, { userEndpoint,_id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    {userEndpoint}
                </Link>
            </>

        ),
    },
    {
        title: "",
        dataIndex: "userEndpoint",
        render: (_, { userEndpoint,_id }) => (
            <a>
                <CopyOutlined onClick={() => onCopy(userEndpoint)} />
            </a>
        ),
    },
    {
        title: "Gateway URL",
        dataIndex: "ipfsGatewayUrl",
        render: (_, { ipfsGatewayUrl,_id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    {ipfsGatewayUrl}
                </Link>
            </>
        ),
    },
    {
        title: "",
        dataIndex: "ipfsGatewayUrl",
        render: (_, { ipfsGatewayUrl,_id,infraType }) => (
            <a>
                <CopyOutlined onClick={() => onCopy(ipfsGatewayUrl)} />
            </a>
        ),
    },

    {
        title: "dApp ID",
        dataIndex: "projectID",
    },
    {
        title: "Team ID",
        dataIndex: "teamID",
    },

    {
        title: "Status",
        dataIndex: "status",
    },
    {
        title: 'Action',
        dataIndex: '',
        render: (_, { _id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    <AccountSettings width="20px" height="20px" />
                </Link>
            </>
        ),
    }
];
export const EntDidcolumns = [
    {
        title: "Endpoint Name",
        dataIndex: "endpointName",
        render: (_, { endpointName,_id,infraType }) => (
            <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                {endpointName}
            </Link>
        )
    },
    {
        title: "Protocol",
        dataIndex: "protocolName",
    },
    {
        title: "User Endpoint",
        dataIndex: "userEndpoint",
        render: (_, { userEndpoint,_id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    {userEndpoint}
                </Link>
            </>

        ),
    },
    {
        title: "",
        dataIndex: "userEndpoint",
        render: (_, { userEndpoint,_id }) => (
            <a>
                <CopyOutlined onClick={() => onCopy(userEndpoint)} />
            </a>
        ),
    },
    {
        title: "dApp ID",
        dataIndex: "projectID",
    },
    {
        title: "Team ID",
        dataIndex: "teamID",
    },
    {
        title: "Status",
        dataIndex: "status",
    },
    {
        title: 'Action',
        dataIndex: '',
        render: (_, { _id,infraType }) => (
            <>
                <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
                    <AccountSettings width="20px" height="20px" />
                </Link>
            </>
        ),
    }
];

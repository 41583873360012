/* eslint-disable react/style-prop-object */
import React from "react";

export const Bundler = ({ width = "50px", height = "50px" }) => (
    <svg xmlns="http://www.w3.org/2000/svg" width="50" height="50" viewBox="0 0 50 50" fill="none">
        <circle cx="25" cy="25" r="24" fill="#F0F0F0" />
        <rect x="15" y="10" width="20" height="30" fill="#0072C6" />
        <circle cx="25" cy="40" r="4" fill="#FFF" />
    </svg>


);

export default Bundler;

import React, {useRef, useState} from "react";
import '../enterprisePagestyles.less'
import streamedCollabration from '../../../../assets/images/StreamlinedCollaboration.avif'
import customize from '../../../../assets/images/Customizableworkflows.avif'
import Limtless from '../../../../assets/images/Limitless.avif'
import Secutrity from '../../../../assets/images/Security.avif'
import SupportTeam from '../../../../assets/images/Supportteam.avif'
import Web3 from '../../../../assets/images/Web3.avif'

export const DappDevelopmentForEnterprise = () => {

  return (
    <>
        <div className="dapp-section">
            <h1 className="dapp-heading"><span className="dapp-highlight">Built for Enterprises</span></h1>
            <div className="dapp-card-container">
                <div className="dapp-card">
                    <div className="dapp-card-img">
                    <img src={streamedCollabration} alt="Image 1"/>
                    </div>
                    <div className="dapp-card-content">
                        <h3><span className="subheading-highlight">Streamlined Collaboration</span></h3>
                            <p>Boost dApp development through seamless team coordination with krypcore Web3. Easily establish focused teams for better communication, resource sharing, and alignment, vital for realizing your enterprise's Web3 goals.</p>
                    </div>

                </div>
                <div className="dapp-card">
                    <div className="dapp-card-img">
                    <img src={customize} alt="Image 2"/>
                    </div>
                        <div className="dapp-card-content">
                            <h3><span className="subheading-highlight">Customizable Workflows</span></h3>
                            <p>Design custom workflows to match your unique business requirements. Enhance efficiency by optimizing processes and resource distribution, ensuring peak enterprise performance.</p>
                        </div>
                </div>
                <div className="dapp-card">
                    <div className="dapp-card-img">
                        <img src={Web3} alt="Image 3"/>
                    </div>
                    <div className="dapp-card-content">
                        <h3><span className="subheading-highlight">Multifaceted Web3 Use Cases</span></h3>
                        <p>Realize the full spectrum of web3 innovation with krypcore’s powerful APIs and SDKs. Our platform supports diverse web3 verticals ranging from NFTs and DeFi to Gaming and Infrastructure. Innovate seamlessly, save time, enhance experiences.</p>
                    </div>
                </div>
                <div className="dapp-card">
                    <div className="dapp-card-img">
                        <img src={Secutrity} alt="Image 3"/>
                    </div>
                    <div className="dapp-card-content">
                        <h3><span className="subheading-highlight">Security and regulatory compliance</span></h3>
                        <p>krypcore prioritizes the utmost security and regulatory adherence. With SOC2 compliance, our comprehensive approach encompasses cutting-edge measures to safeguard your projects, ensuring data integrity and user privacy.</p>
                    </div>
                </div>
                <div className="dapp-card">
                    <div className="dapp-card-img">
                        <img src={Limtless} alt="Image 3"/>
                    </div>
                    <div className="dapp-card-content">
                        <h3><span className="subheading-highlight">Limitless Scalability</span></h3>
                        <p>Empower your enterprise's growth with krypcore. Our platform is engineered to handle increased demand without compromising performance. Scale confidently, knowing that krypcore supports your enterprise's journey to success.</p>
                    </div>
                </div>
                <div className="dapp-card">
                    <div className="dapp-card-img">
                        <img src={SupportTeam} alt="Image 3"/>
                    </div>
                    <div className="dapp-card-content">
                        <h3><span className="subheading-highlight">Expert Support Team</span></h3>
                        <p>Access a dedicated support team guiding you every step of the way. From onboarding to troubleshooting, our specialists ensure your interaction with krypcore is smooth and successful.</p>
                    </div>
                </div>
            </div>
        </div>
    </>
  );
};

export default DappDevelopmentForEnterprise;

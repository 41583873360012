import React from "react";

export const NFTstudio = ({ width = "20px", height = "20px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4 3.87803V3C4 1.75736 5.00736 0.75 6.25 0.75H13.75C14.9926 0.75 16 1.75736 16 3V3.87803M4 3.87803C4.23458 3.79512 4.48702 3.75 4.75 3.75H15.25C15.513 3.75 15.7654 3.79512 16 3.87803M4 3.87803C3.12611 4.18691 2.5 5.02034 2.5 6V6.87803M16 3.87803C16.8739 4.18691 17.5 5.02034 17.5 6V6.87803M17.5 6.87803C17.2654 6.79512 17.013 6.75 16.75 6.75H3.25C2.98702 6.75 2.73458 6.79512 2.5 6.87803M17.5 6.87803C18.3739 7.18691 19 8.02034 19 9V15C19 16.2426 17.9926 17.25 16.75 17.25H3.25C2.00736 17.25 1 16.2426 1 15V9C1 8.02034 1.62611 7.18691 2.5 6.87803"
      stroke="#E3E3E3"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

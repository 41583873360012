import React from 'react'
import { useInView } from 'react-intersection-observer';
import img1 from '../../../assets/icons/Group 811550.svg'
import img2 from '../../../assets/icons/Group 811551.svg'
import img3 from '../../../assets/icons/Group 811552.svg'
import img4 from '../../../assets/icons/Group 811553.svg'
import img5 from '../../../assets/icons/Group 811554.svg'
import img6 from '../../../assets/icons/Group 811555.svg'
import Paragraph from './Paragraph';
import useIntersectionObserver from './useIntersectionObserver';

const BlockchainHeader = () => {

  

      const [ref, isInView] = useIntersectionObserver({
        root: null,
        rootMargin: '0px',
        threshold: 0.5,
      });

  return (
    <div ref={ref} className={` BlockchainComplexities `} id='start-with-krypCore'>
      <div> 
        <h2 className={` blockchaincomplexheader ${isInView ?'animated animatedFadeInUp fadeInUp':'blockchaincomplexheader hidden'}`} >Effortless<br className="light-theme-hiddenbreaktag"  /> Blockchain<br className="light-theme-hiddenbreaktag"  /> Development with <br className="light-theme-hiddenbreaktag"  />  Essential<span className='blockchaincomplexheader-gradient'> Web3</span>  <br className="light-theme-hiddenbreaktag"  /><span className='blockchaincomplexheader-gradient1'>Services </span>  </h2>
        <p className='blockchaincomplexheader-para'>Krypcore Web3 consolidates key layers of <br className="light-theme-hiddenbreaktag"  /> dApp development into a unified platform, <br className="light-theme-hiddenbreaktag"  /> simplifying adoption and enabling enterprises <br className="light-theme-hiddenbreaktag"  /> and developers to overcome complexities <br className="light-theme-hiddenbreaktag"  /> and build dApps with ease.</p>
      </div>
    

      <div className='BlockchainComplexities-icon-container3'>
      <div className='BlockchainComplexities-icon-container1'>
        <div  className='BlockchainComplexities-icon-container1-inner'>
        <Paragraph
          gradient='back'
          frontContent={img1}
          frontContent1={'Infra'}
          backContent={'Access our infrastructure solutions, including both shared and dedicated nodes across supported protocols, ensuring a balance between continuous availability and reliability.'}
        />
        </div>
        
        <div>
        <Paragraph
          gradient='back2'
          frontContent={img2}
          frontContent1={'Token'}
          backContent={'An intuitive solution equipped with a suite of APIs and tools for effortlessly creating and managing ERC-20 tokens within your dApp.'}
        />
        </div>
        
      </div>
      <div className='BlockchainComplexities-icon-container1'>
        <div className='BlockchainComplexities-icon-container1-inner'>
        <Paragraph
          gradient='back3'
          frontContent={img3}
          frontContent1={<span>Data<br className="light-theme-hiddenbreaktag"  /> Services</span>}
          backContent={'Effortlessly consolidate fragmented blockchain data into detailed reports with Datalake. Additionally, access secure, tamper-proof storage through distributed protocols like IPFS.'}
        />
        </div>
        
        <div>
        <Paragraph
          gradient='back4'
          frontContent={img4}
          frontContent1={'Gasless'}
          backContent={'Remove the initial barrier of gas fees for users by employing Krypcore Web3s Gasless APIs, making Web3 accessible to a wider audience'}
        />
        </div>
        
      </div>
      <div className='BlockchainComplexities-icon-container1'>
        <div className='BlockchainComplexities-icon-container1-inner'>
        <Paragraph
          gradient='back5'
          frontContent={img5}
          frontContent1={<span>Wallet <br className="light-theme-hiddenbreaktag"  /> Services</span>}
          backContent={'Wallet APIs allows developers to manage and interact with different types of wallets. Build, manage, and swiftly integrate custodial, non-custodial, and smart contract wallets into dApps.'}
        />
        </div>
        
        <div>
        <Paragraph
          gradient='back6'
          frontContent={img6}
          frontContent1={<span>Smart <br className="light-theme-hiddenbreaktag"  /> Contract</span>}
          backContent={'Abstract the complexities of smart contract development with pre-defined templates and APIs'}
        />
        </div>
        
      </div>
        {/* <div className='BlockchainComplexities-icon-container1'>
          <div>
            <div className='BlockchainComplexities-icon-container'>
              <img src={img1} className='BlockchainComplexities-icon'/>
              <span className='BlockchainComplexities-icon-text'>Infra</span>
            </div>
          </div>
          <div>
            <div className='BlockchainComplexities-icon-container'>
              <img src={img2} className='BlockchainComplexities-icon'/>
              <span className='BlockchainComplexities-icon-text'>Token</span>
            </div>
          </div>
          
        </div>
        <div className='BlockchainComplexities-icon-container1'>
          <div className='BlockchainComplexities-icon-container'>
            <img src={img3} className='BlockchainComplexities-icon'/>
            <span className='BlockchainComplexities-icon-text'>Data<br className="light-theme-hiddenbreaktag"  /> Services</span>
          </div>
          <div className='BlockchainComplexities-icon-container'>
            <img src={img4} className='BlockchainComplexities-icon'/>
            <span className='BlockchainComplexities-icon-text'>Gasless</span>
          </div>
        </div>
        <div className='BlockchainComplexities-icon-container1'>
          <div className='BlockchainComplexities-icon-container'>
            <img src={img5} className='BlockchainComplexities-icon'/>
            <span className='BlockchainComplexities-icon-text'>Wallet <br className="light-theme-hiddenbreaktag"  /> Services</span>
          </div>
          <div className='BlockchainComplexities-icon-container'>
            <img src={img6} className='BlockchainComplexities-icon'/>
            <span className='BlockchainComplexities-icon-text'>Smart <br className="light-theme-hiddenbreaktag"  /> Contract</span>
          </div>
        </div> */}
      </div>
    </div>
  )
}

export default BlockchainHeader
import React from "react";
import ClientLogo1 from './Icons/algorand.svg'
import ClientLogo2 from './Icons/avalanche.svg'
import ClientLogo3 from './Icons/deca4.svg'
import ClientLogo4 from './Icons/deu.svg'
import ClientLogo5 from './Icons/hedera.svg'
import ClientLogo6 from './Icons/heineken.svg'
import ClientLogo7 from './Icons/henkel.svg'
import ClientLogo8 from './Icons/hyperledger.svg'
import ClientLogo9 from './Icons/maersk.svg'
import ClientLogo10 from './Icons/microsoft.svg'
import ClientLogo11 from './Icons/minehub.svg'
import ClientLogo12 from './Icons/polygon.svg'
import ClientLogo13 from './Icons/shiphol.svg'
import ClientLogo14 from './Icons/siemens.svg'
import ClientLogo15 from './Icons/solana.svg'

export const Clientlogo = () => {

    return (
      <>
        <div className="clientlogo-wrap">
            <div className="marquee-content">
          <div className="container-fluid">
            <div className="plandetails-sec2-image-container_1 mt-5">
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo1} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo2} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo3} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo4} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo5} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo6} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo7} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo8} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo9} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo10} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo11} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo12} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo13} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo14} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo15} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo1} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo2} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo3} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo4} className="clientlogowidth" alt="" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo5} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo6} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo7} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo8} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo9} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo10} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo11} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo12} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo13} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo14} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
              <div className="col-md-1">
                <div className="clientlogo-item">
                  <img src={ClientLogo15} className="clientlogowidth" alt="Simens" />
                </div>
              </div>
            </div>
          </div>
            </div>
          </div>
      </>
    );
}

export default Clientlogo;

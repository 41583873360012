import React from "react";
import {AccountSettings} from "../../../assets/menus/accountSettings";
import {formatAge} from "../../dataLake/column";
import Web3 from "web3";

export function weiToEther(weiAmount) {
    const web3 = new Web3();

    const ethAmount = web3.utils.fromWei(parseFloat(weiAmount), 'ether');
    return parseFloat(ethAmount);
}




export const gaslessProtocolConfigColumn  = [
    {
        title: "Network",
        key:"",
        dataIndex: "network",
    },
    {
        title: "Chain ID",
        key:"",
        dataIndex: "chainId",
    },
    {
        title: 'Whitelisted Contracts',
        dataIndex: 'numWhitelistedContracts',
        key: '',
    },
    {
        title: "No. of Txn",
        key:"",
        dataIndex: "numTransactions",
    },
    {
        title: "Gas Limit",
        key:"",
        dataIndex: "gasLimit",
        render: (_, { gasLimit }) => <span>{weiToEther(gasLimit)}</span>,
    },
    {
        title: 'Gas Used',
        key: '',
        dataIndex: 'gasUsed',
        render: (_, { gasUsed }) => <span>{weiToEther(gasUsed)}</span>,

    },
    {
        title: 'Remaining Gas',
        key: 'row',
        dataIndex: 'row',
        render: (_, row) => <span>{weiToEther(row.gasLimit - row.gasUsed )}</span>,

    },
];

export const gaslessFundedTxColumns = [
    {
        title: "Txn Hash",
        dataIndex: "transactionHash",
        key: "transactionHash",
    },
    {
        title: "Explore URL",
        dataIndex: "explorerUrl",
        key: "explorerUrl",
        render: (text, record) => <a href={text} target="_blank" rel="noopener noreferrer">{text}</a>,
    },
    {
        title: 'Age',
        dataIndex: 'timestamp',
        key: 'timestamp',
        render: (_, { timestamp }) => <span>{formatAge(timestamp)}</span>,
    },
    {
        title: "Gas Used",
        dataIndex: "gasUsed",
        key: "gasUsed",
        render: (_, { gasUsed }) => <span>{weiToEther(gasUsed)}</span>,
    },
    {
        title: "Linked Contract",
        dataIndex: "linkedContractName",
        key: "linkedContractName",
    },
    {
        title: 'Contract Address',
        dataIndex: 'linkedContractAddress',
        key: 'linkedContractAddress',
    },
    {
        title: 'Linked dApp',
        dataIndex: 'linkedDappName',
        key: 'linkedDappName',
    },
    {
        title: 'Network',
        dataIndex: 'network',
        key: 'network',
    },
    {
        title: 'Txn Status',
        dataIndex: 'transactionStatus',
        key: 'transactionStatus',
    },
];



export const  gaslessWhitelistedColumns  = (onViewDetails) =>  [
    {
        title: "Name",
        key:"contractName",
        dataIndex: "contractName",
    },
    {
        title: "Contract Address",
        dataIndex: "contractAddress",
    },
    {
        title: 'Network',
        dataIndex: 'network',
    },
    {
        title: "Chain ID",
        dataIndex: "chainId",
    },
    {
        title: "Gas Used",
        key:"gasUsed",
        dataIndex: "gasUsed",
        render: (_, { gasUsed }) => <span>{weiToEther(gasUsed)}</span>,
    },
    {
        title: 'No. of Txn',
        key: 'numberOfTransactions',
        dataIndex: 'numberOfTransactions',

    },
    {
        title: 'Linked dApp',
        key: 'linkedDAppName',
        dataIndex: 'linkedDAppName',

    },
    {
        title: 'Gas Limit ',
        key: 'gasLimitConfigured',
        dataIndex: 'gasLimitConfigured',
        render: (_, { gasLimitConfigured }) => <span>{weiToEther(gasLimitConfigured)}</span>,

    },
    {
        title: 'Manage ',
        dataIndex: 'row',
        key: 'row',
        render: (_, row) => (
            <>
                <a  onClick={() => onViewDetails(row)}>
                    <AccountSettings width="20px" height="20px" />
                </a>
            </>
        ),
    }
];

export const gasRequestColumn  = [
    {
        title: "Network",
        key:"",
        dataIndex: "network",
    },
    {
        title: "Chain ID",
        key:"",
        dataIndex: "chainId",
    },
    {
        title: 'Requested GasLimit',
        dataIndex: 'requestedGasLimit',
        key: '',
        render: (_, { requestedGasLimit }) => <span>{weiToEther(requestedGasLimit)}</span>,
    },
    {
        title: 'Created At',
        dataIndex: 'createdAt',
        key: 'createdAt',
    },
    {
        title: "Status",
        key:"",
        dataIndex: "gasRequestStatus",
    },

];
import React, { useEffect, useState } from "react";
import { PaginationSection, TableContent } from "../../../../library";
import { columnsForReferral } from "./billingColumns";
import { fetchApi } from "../../../../_config/api";
import { deepCopy, getUserDetails} from "../../../../deps";
import Skeleton from "react-loading-skeleton";
import {CopyOutlined} from "@ant-design/icons";
import {notification} from "antd";

export const Referral = ({ showLoader, activeKey, isLoading }) => {

    const appUrl = process.env.REACT_APP_PUBLIC_URL;
    const user = getUserDetails();
    const [pageSetting, setSetting] = React.useState({
        page: 1,
        itemsPerPage: 10,
        searchTextBilling: "",
        searchTextConsump: "",
        filterValue: "",
    });
    const [referralCode, setReferralCode] = React.useState(null);
    const [filteredData, setFilteredData] = React.useState([]);
    const [totalCountReferral, setTotalCountReferral] = React.useState(-1);

    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [hitsList, setHitsList] = React.useState([]);
    const [referralList, setReferralList] = useState([]);

    useEffect(() => {
        getReferralList()
    }, [activeKey]);

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };

    useEffect(() => {
        console.log("user::", user);
        if(user && user?.profile?.referralCode) {
            setReferralCode(user?.profile?.referralCode);
        }
    }, [user]);

    useEffect(() => {
        onFilterDataChangeBillingList();
    }, [referralList, pageSetting]);

    const getReferralList = () => {
        showLoader(true);
        let filter = {
            // and: [
            //   {
            //     key: "referredBy",
            //     value: "DEV_BAUH_182_20240123",
            //     opt: "eq",
            //   },
            // ],
        };;

        const Options = () => {
            setHitsList([5, 10, 15, 20]);
            return {
                select: "",
                sort: { createdAt: -1 },
                page: pageSetting.page - 1,
                limit: pageSetting.itemsPerPage,
            };
        };

        // console.log("filter::", filter);
        let requestLink = `auth/getReferrals?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({
            method: "GET",
            url: requestLink,
            isDevApp: false,
        })
            .then((response) => {
                console.log("response::", response);
                const { statusCode, data = [], totalCount } = response;
                if (statusCode === 200) {
                    setReferralList(data)
                    setTotalCountReferral(totalCount)
                }
                showLoader(false);
            })
            .catch((response) => {
                setReferralList([])
                showLoader(false);
            });
    };


    const onFilterDataChangeBillingList = () => {

        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > referralList.length
                ? referralList.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchTextBilling
                ? referralList.filter((item) =>
                    item.planName
                        .toLowerCase()
                        .includes(pageSetting.searchTextBilling.toLowerCase())
                )
                : deepCopy(referralList)
        )
            .slice(0, endIndex);
        setFilteredData(filterList);
    };


    useEffect(() => {
        setTotalCountReferral(totalCountReferral);
    }, [referralList]);

    const onPageChangeReferral = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getReferralList();
    };

    const onPerPageChangeReferral = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getReferralList();
    };

    const lastBilling =
        pageSetting.page * pageSetting.itemsPerPage > totalCountReferral
            ? totalCountReferral
            : pageSetting.page * pageSetting.itemsPerPage;

    return (
        <div className="plan-billing-container" style={{ paddingTop: "3.5rem" }}>
            <div className="billing-section">
                <div className="profile-header">Referrals</div>
                <p>Link: <strong>{`${appUrl}?referrer=${referralCode}`}<CopyOutlined className="" onClick={() => onCopy(`${appUrl}?referrer=${referralCode}`)} /></strong></p>
            </div>
            {isLoading ? (<Skeleton height={200} style={{ marginBottom: '50px' }} baseColor="#262626" highlightColor="#404040" />) : (
                <TableContent
                    pagination={false}
                    rowKey="planId"
                    columns={columnsForReferral()}
                    data={referralList}
                />
            )}
            <PaginationSection
                last={lastBilling}
                first={startIndex + 1}
                hitsList={hitsList}
                totalCount={totalCountReferral}
                page={pageSetting.page}
                itemsPerPage={pageSetting.itemsPerPage}
                onPageChange={onPageChangeReferral}
                onPerPageChange={onPerPageChangeReferral}
            />
        </div>

    );
};

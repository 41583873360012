import React from 'react'
import Header from './ui/Header'
import './lighttheme.less'
import Banner from './ui/Banner'
import Features from './ui/Features'
import BlockchainNetwork from './ui/BlockchainNetwork'
import Web3Features from './ui/Web3Features'
import Pricing from './ui/Pricing'
import KrypcoreBlogs from './ui/KrypcoreBlogs'
import BannerBottom from './ui/BannerBottom'
import Footer from './ui/Footer'
import JoinwithKrypc from './ui/JoinwithKrypc'
import KrypcoreFeatures2 from './ui/KrypcoreFeatures2'
import KrypcoreFeatures3 from './ui/KrypcoreFeatures3'
import KrypcoreFeatures4 from './ui/KrypcoreFeatures4'
import KrypcoreFeatures5 from './ui/KrypcoreFeatures5'
import BlockchainHeader from './ui/BlockchainHeader'
import Infrastructure from './ui/Infrastructure'
import Applications from './ui/Applications'
import ScrollAnimation from '../InfrastructureUI/ScrollAnimation'
import { useLocation } from 'react-router-dom'


const New_LandingPage = () => {
  const location=useLocation()
  console.log(location)
  return (
    <div className='bg-light2'>
      <div className='bg-light1'>
          <Header/>
          <Banner/>
          {/* <Infrastructure/> */}
      </div>
      <ScrollAnimation/>
      <BlockchainHeader/> 
      <Applications/>
      <BlockchainNetwork/>
      <BannerBottom/>
      {/* <Features/> */}
      {/* <Web3Features/> 
      <KrypcoreFeatures2/>
      <KrypcoreFeatures3/>
      <KrypcoreFeatures4/>
      <KrypcoreFeatures5/> */}
      <Pricing/>
      <KrypcoreBlogs/>
      {/* <JoinwithKrypc/> */}
      <Footer/>
    </div>
  )
}

export default New_LandingPage
import React, { useState } from "react";

import { useNavigate } from "react-router-dom";
import {Logos} from "../../assets";
import fetchApi from "../../_config/api";
import { notification } from "antd";
import Telegram1 from './Icons/icons8-telegram-logo.svg'
import Twitter1 from './Icons/icons8-twitterx.svg'


const NewLandingFooter = () => {
    const twitter= process.env.REACT_APP_TWITTER_URL
    const telegram = process.env.REACT_APP_TELEGRAM_URL
    const discord= process.env.REACT_APP_DISCORD_URL
    const linkedIn= process.env.REACT_APP_LINKEDIN_URL
    const aboutus= process.env.REACT_APP_LANDING_PAGE_ABOUT_URL
    const career = process.env.REACT_APP_LANDING_PAGE_CAREER_URL
    const docs= process.env.REACT_APP_LANDING_PAGE_DOCS_URL
    const api= process.env.REACT_APP_LANDING_PAGE_API_URL
    const sdk= process.env.REACT_APP_LANDING_PAGE_SDK_URL
    const userManual= process.env.REACT_APP_LANDING_PAGE_USER_MANUAL_URL
    const blogs = process.env.REACT_APP_LANDING_PAGE_BLOG_URL
    const youtube=  process.env.REACT_APP_YOUTUBE_URL

    const open = (value)=>{
        window.open(value)
    }

    const [query, setQuery] = useState(false)
    const navigate = useNavigate();

    const navigateHome = (value) => {
        if (window.location.pathname != "/"){
            navigate("/", { state: { value: value } })
        }else{
            scrollToPricing()
        }
    };
    const scrollToPricing = () => {
        document.getElementById('pricing_works').scrollIntoView({ behavior: "smooth" })
    };

    React.useEffect(() => {
        if (window.location.pathname.includes('contact-us')) {
            setQuery(true);
        }

    }, [])

    const[name,setName]=useState('')
    const[email,setEmail]=useState('')

    const submit = () => {
        if (name === '' || email === '') {
            notification.error({
                message: "please fill the details",
                duration: 2,
            });
        } else {
                // console.log('Captcha token:', captchaValue, captcha);
                const accessBody = {
                    emailId: email,
                    firstName: name,
                    
                };
                fetchApi({method: "POST", url: `developer/contact-us`, data: accessBody})
                    .then((response) => {
                        if (response.status == true) {
                            notification.success({
                                message: response.message,
                                duration: 2,
                            });
                        }
                        setName("");
                        setEmail("");
                       
                    })
                    .catch(() => {
                    });
            }
        
        ;
    }
    
    return (
        <div className="new-footer-wrapper">
          
            <div className="new_footer">
                <div className="container-fluid">
                <div className="row justify-content-between ">
                    <div className="new_footer_card left-card">
                    <div className="theme-heading2">
                        <h3 style={{fontWeight:500}} className="text-white text-left">Connect with us</h3><br/>
                    </div>
                    <div className="theme-heading3">
                        <p className="text-left">
                            {/* Harness the competitive advantage of utilizing Web3 Technologies for your business */}
                        </p>
                    </div>
                    <div className="theme-heading-input">
                        <input value={name} onChange={(e)=>setName(e.target.value)} type="text" className="mb-3 bg-dark border-0 p-2 " placeholder="Enter your name"/>
                        <input value={email} onChange={(e)=>setEmail(e.target.value)} type="text" className=" bg-dark border-0 p-2 "  placeholder="Enter your email"/>
                    </div>
                    <div className="theme-heading5">
                      <button onClick={submit}>Submit</button>
                    </div>
                    </div>
                    <div className="new_footer_card right-card">
                        <div className="sub-grid-item-1">
                         
                            <ul className="sub-grid-item-1-list">
                                <li ><a href={aboutus} target="_blank" className="subgrid-right-footer" >About us</a></li>
                                <li><a href="/pricing" className="subgrid-right-footer">Pricing</a></li>
                                <li ><a href={career} target="_blank" className="subgrid-right-footer">Careers</a></li>
                            </ul>
                        </div>
                        <div className="sub-grid-item-1">
                         
                            <ul className="sub-grid-item-1-list">
                                <li onClick={() => open(docs)}><a href={docs} target="_blank" className="subgrid-right-footer">Documentation</a></li>
                                <li onClick={() => open(api)}><a href={api} target="_blank" className="subgrid-right-footer">API Reference</a></li>
                                <li onClick={() => open(sdk)}><a href={sdk} target="_blank" className="subgrid-right-footer">SDK Reference</a></li>
                                <li onClick={() => open(userManual)}><a href={userManual} target="_blank" className="subgrid-right-footer">User Manual</a></li>
                                <li ><a href="/blog" className="subgrid-right-footer">Blogs</a></li>
                            </ul>
                        </div>

                    </div>
                    </div>
                </div>
                 <div className="text-right1">
                    <div className="social-icons-footer">
                
                        <a id="telegramid" aria-label="Twitter" className="telegramid" href={twitter} target="_blank"><img alt="twitter"  src={Twitter1} style={{width:'24px',height:'24px'}}/></a>
                        <a id="twitterid" aria-label="Telegram"  className="twitterid" href={telegram} target="_blank"><img alt="telegram" src={Telegram1} style={{width:'24px',height:'24px'}}/></a>
                        <a id="discordid" aria-label="Discord"  className="discordid" href={discord} target="_blank"><Logos type="footerdiscord"/></a>
                        <a id="linkedinid" aria-label="Linkedin"  className="linkedinid" href={linkedIn} target="_blank"><Logos type="footerlinkedin"/></a>
                        <a id="youtubeid" aria-label="Youtube"  className="youtubeid" href={youtube} target="_blank"><Logos type="footerYoutube"/></a>
                    </div>
                </div>
                <div className="bottom-section">
                    <div className="word-divider1">
                        <span className="copyright-text ff-heading mb-0">
                            © {new Date().getFullYear()}{" "}
                            <a href="https://krypc.com" target="_blank" rel="noopener noreferrer" style={{color:"#5C5F70"}}>
                                KrypC Technologies Pvt. Ltd
                            </a>
                        </span>
                    </div>
                    <div className="word-divider mt-3">
                        <p className="mb-0">
                            <a href="/privacy-policy"><span>Privacy Policy</span></a>
                            <a href="/terms"><span>Terms of Use</span></a>
                        </p>
                    </div>
                </div>
            </div>
        </div>
    )
};

export default NewLandingFooter;

/* eslint-disable react/style-prop-object */
import React from "react";

export const DID = ({ width = "50px", height = "50px" }) => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        width={width}
        height={height}
        viewBox="0 0 123 123"
        fill="none"
    >
        <mask
            id="mask_did"
            style={{"maskType":"alpha"}}
            maskUnits="userSpaceOnUse"
            x="10"
            y="10"
            width="102"
            height="102"
        >
            <rect x="10" y="10" width="102" height="102" fill="url(#pa__did)" />
        </mask>
        <g mask="url(#mask_did)">
            <rect x="10" y="10" width="102" height="102" fill="#fff" />
        </g>
        <defs>
            <pattern
                id="pa__did"
                patternContentUnits="objectBoundingBox"
                width="1"
                height="1"
            >
                <use href="#img__did" transform="scale(0.00195312)" />
            </pattern>
            <image
                id="img__did"
                width="512"
                height="512"
                href="data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgNzMuNiA3MiIgd2lkdGg9IjI1MDAiIGhlaWdodD0iMjQ0NiI+PGRlZnM+PHBhdGggaWQ9ImEiIGQ9Ik0zNy42IDM2LjhjMS4yIDAgMi4xLTEgMi4xLTIuMXMtLjktMi4xLTIuMS0yLjEtMi4xIDEtMi4xIDIuMS45IDIuMSAyLjEgMi4xek0zMS4zIDMxYzEuMiAwIDIuMS0xIDIuMS0yLjEgMC0xLjItLjktMi4xLTIuMS0yLjEtMS4yIDAtMi4xIDEtMi4xIDIuMSAwIDEuMi45IDIuMSAyLjEgMi4xem02LjMgMGMxLjIgMCAyLjEtMSAyLjEtMi4xIDAtMS4yLS45LTIuMS0yLjEtMi4xcy0yLjEgMS0yLjEgMi4xYzAgMS4yLjkgMi4xIDIuMSAyLjF6bTYuMyAwYzEuMiAwIDIuMS0xIDIuMS0yLjEgMC0xLjItLjktMi4xLTIuMS0yLjEtMS4yIDAtMi4xIDEtMi4xIDIuMSAwIDEuMiAxIDIuMSAyLjEgMi4xem0tMTIuNi01LjdjMS4yIDAgMi4xLTEgMi4xLTIuMSAwLTEuMi0uOS0yLjEtMi4xLTIuMS0xLjIgMC0yLjEgMS0yLjEgMi4xIDAgMS4yLjkgMi4xIDIuMSAyLjF6bTYuMyAwYzEuMiAwIDIuMS0xIDIuMS0yLjEgMC0xLjItLjktMi4xLTIuMS0yLjFzLTIuMSAxLTIuMSAyLjFjMCAxLjIuOSAyLjEgMi4xIDIuMXptNi4zIDBjMS4yIDAgMi4xLTEgMi4xLTIuMSAwLTEuMi0uOS0yLjEtMi4xLTIuMS0xLjIgMC0yLjEgMS0yLjEgMi4xIDAgMS4yIDEgMi4xIDIuMSAyLjF6bS02LjMtNS43YzEuMiAwIDIuMS0xIDIuMS0yLjEgMC0xLjItLjktMi4xLTIuMS0yLjFzLTIuMSAxLTIuMSAyLjEuOSAyLjEgMi4xIDIuMXptNi4zIDBjMS4yIDAgMi4xLTEgMi4xLTIuMSAwLTEuMi0uOS0yLjEtMi4xLTIuMS0xLjIgMC0yLjEgMS0yLjEgMi4xczEgMi4xIDIuMSAyLjF6bS0xMi42IDBjMS4yIDAgMi4xLTEgMi4xLTIuMSAwLTEuMi0uOS0yLjEtMi4xLTIuMS0xLjIgMC0yLjEgMS0yLjEgMi4xcy45IDIuMSAyLjEgMi4xek0zNi44IDcyTDAgMGg3My42TDM2LjggNzJ6Ii8+PC9kZWZzPjx1c2UgeGxpbms6aHJlZj0iI2EiIG92ZXJmbG93PSJ2aXNpYmxlIiBmaWxsLXJ1bGU9ImV2ZW5vZGQiIGNsaXAtcnVsZT0iZXZlbm9kZCIvPjwvc3ZnPg=="
            />
        </defs>
    </svg>
);

export default DID;

import {notification} from "antd";
import fetchApi, {fetchApiGw} from "../../../_config/api";
import {GASLESS_BACKEND_URL} from "../protocol-gasLimit-config/gaslessProtocolConfig";

export const apiCaller = (methodName, url, request, displayNotification = true) => {
    return fetchApiGw({ method: methodName, useBase:false, url:GASLESS_BACKEND_URL+url,isDevApp:false, data: request })
        .then((response) => {
            if (displayNotification){
                if (response.status == true) {
                    notification.success({
                        message: response.message,
                        duration: 4,
                    });
                } else if( response.message ) {
                    notification.error({
                        message: response.message,
                        duration: 4,
                    });
                }
            }
            return response
        })
        .catch((error) => {
            notification.error({
                message: error.message,
                duration: 4,
            });
        });
};
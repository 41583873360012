import React from "react";
import { Select } from "antd";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";

const { Option } = Select;

export const Pagination = ({ page, onChange, pages }) => {
  const onNext = () => {
    if (pages > page) {
      onChange(page + 1);
    }
  };
  const onPrev = () => {
    if (page > 1) {
      onChange(page - 1);
    }
  };

  return (
    <div className="pagination-container">
      <LeftOutlined
        className={`left-icon${page === 1 ? " disabled" : ""}`}
        onClick={onPrev}
      />
      <RightOutlined
        className={`right-icon${page === pages ? " disabled" : ""}`}
        onClick={onNext}
      />
      <div className="hits-per-page-container">
        <Select value={page} style={{ width: 65 }} onChange={onChange}>
          {[...Array(pages).keys()].map((item, index) => (
            <Option key={index} value={item + 1}>
              {item + 1}
            </Option>
          ))}
        </Select>
        <div className="page-text">{`of ${pages} pages`}</div>
      </div>
    </div>
  );
};

import React from "react";


import {ContactSupport} from "./contactSupport";
import {Helmet} from "react-helmet";

export const ContactForm= () => {




    return (
<>
    <Helmet>
        <meta charSet="utf-8" />
        <title>Get in Touch with Us - Contact Krypcore Web3</title>
        <meta name="description" content="Have inquiries, or suggestions? Reach out to Krypcore's dedicated team. Connect with us today Explore innovative solutions. Empower your dApp aspirations." />
        <link rel="canonical" href="http://krypcore.com" />
    </Helmet>
      <div className="plan-details2" id="pricing&plans">


                      <div className="plan-section1">
                          <div className="contact-page-container">
                              <div className="card-contact-left">

                                      <h3 className="contact-text">
                                          Contact Us
                                      </h3>

                                      <p 
                                        // style={{
                                        //     fontFamily: 'Poppins',
                                        //     fontSize: '18px',
                                        //     fontWeight: 400,
                                        //     lineHeight: '35px',
                                        //     textAlign: 'left'
                                        // }}
                                        >
                                          Krypcore Web3 empowers developers and enterprises to build and deploy
                                          decentralized applications (dApps) on multiple blockchain networks. Our
                                          infrastructure includes endpoints, node options, and blockchain/storage
                                          support.
                                      </p>



                              </div>
                              <ContactSupport/>
                          </div>


              </div>
      </div>
</>
    );
};

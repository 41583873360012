import React from "react";

export const NewContract = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.15527 5.43173C4.15527 5.20482 4.24541 4.98721 4.40586 4.82676C4.56631 4.66631 4.78392 4.57617 5.01083 4.57617H8.43305C8.65996 4.57617 8.87757 4.66631 9.03802 4.82676C9.19847 4.98721 9.28861 5.20482 9.28861 5.43173C9.28861 5.65863 9.19847 5.87625 9.03802 6.0367C8.87757 6.19714 8.65996 6.28728 8.43305 6.28728H5.01083C4.78392 6.28728 4.56631 6.19714 4.40586 6.0367C4.24541 5.87625 4.15527 5.65863 4.15527 5.43173Z"
      fill="white"
    />
    <path
      fill-rule="evenodd"
      clip-rule="evenodd"
      d="M4.15562 0.298828C3.24799 0.298828 2.37754 0.659382 1.73574 1.30117C1.09395 1.94296 0.733398 2.81342 0.733398 3.72105L0.733398 12.2766C0.733398 13.1842 1.09395 14.0547 1.73574 14.6965C2.37754 15.3383 3.24799 15.6988 4.15562 15.6988H17.8445C18.7521 15.6988 19.6226 15.3383 20.2644 14.6965C20.9062 14.0547 21.2667 13.1842 21.2667 12.2766V3.72105C21.2667 2.81342 20.9062 1.94296 20.2644 1.30117C19.6226 0.659382 18.7521 0.298828 17.8445 0.298828H4.15562ZM17.8445 2.00994H4.15562C3.70181 2.00994 3.26658 2.19022 2.94568 2.51111C2.62479 2.83201 2.44451 3.26724 2.44451 3.72105V9.70994H19.5556V3.72105C19.5556 3.26724 19.3753 2.83201 19.0544 2.51111C18.7336 2.19022 18.2983 2.00994 17.8445 2.00994ZM19.5556 11.4211H2.44451V12.2766C2.44451 12.7304 2.62479 13.1656 2.94568 13.4865C3.26658 13.8074 3.70181 13.9877 4.15562 13.9877H17.8445C18.2983 13.9877 18.7336 13.8074 19.0544 13.4865C19.3753 13.1656 19.5556 12.7304 19.5556 12.2766V11.4211Z"
      fill="white"
    />
  </svg>
);

export default NewContract;

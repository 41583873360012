/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-unused-vars */
import React, { useEffect, useState, useContext } from "react";
// import "./style.less"
// import '../myAccount/styles.less'
import {
    dateformatter, deepCopy,
    DEVELOPERROLE,
    DEVELOPERTYPE,
    ENTERPRISEADMINROLE, ENTERPRISEDEVELOPERROLE,
    getUserDetails, setPATToken,
    setUserDetails
} from "../../../../deps";
import { CreateClientCredentials } from "./createClientCredentials";
import { FieldSet, PaginationSection, TableContent, UserIcon } from "../../../../library";
import { columns, columnsDeveloper } from "./columns";
import fetchApi from "../../../../_config/api";
import { Transfer } from "./transfer"
import { notification } from "antd";
import { UserContext } from "../../../../context";
import { UploadPhoto } from "./upload";
import { CopyOutlined, EditOutlined } from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";



export const Account = ({ showLoader, isLoading }) => {
    console.log(isLoading)

    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const { profile, wallet } = getUserDetails() || {};
    const {
        photo = "",
        firstName = "",
        lastName = "",
        emailId,
        userRole = DEVELOPERROLE,
    } = profile || {};
    const name = firstName + " " + lastName;
    const [clientCredentialsOpen, setCreateClientCredentialsOpen] = React.useState(false);
    const [transferOpen, setTransferOpen] = React.useState(false);
    const [transferId, setTransferId] = React.useState("");
    const [hitsList, setHitsList] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);
    const [Id, setId] = React.useState("");
    const [message, setMessage] = React.useState("");
    const [filteredData, setFilteredData] = useState([]);
    const [users, setUsers] = useState([]);
    const [subscriptionId, setSubscriptionId] = React.useState("");
    const [createdisable, setCreatedisabled] = React.useState(false);
    const [showUpload, setUpload] = useState(false);
    const [userprofileData, setUserData] = useState({});
    const { setUserDetail } = useContext(UserContext);
    const [userDetails, setUserDetails1] = useState({});
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    let origin = window.location.origin;
    useEffect(() => {
        getClientCredentialsList()
        getUserData();
    }, []);
    useEffect(() => {
        onFilterDataChange();
    }, [filteredData, pageSetting]);
    const onCreateClientCredentials = () => {
        setCreateClientCredentialsOpen(!clientCredentialsOpen);
    };
    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };
    const onTransferOpen = () => {
        setTransferOpen(!transferOpen);
    };
    const userData = getUserDetails();

    const hideUpload = () => {
        setUpload(false);
    };

    const onSuccessUpload = (data) => {
        const userPicUpdate = {
            ...getUserDetails(),
            profile: {
                ...getUserDetails().profile,
                photo: data?.photo,
            },
        };
        setUserDetails(userPicUpdate);
        getUserData();
        setUpload(false);
    };



    const getUserData = () => {
        showLoader(true);
        fetchApi({ method: "GET", url: "developer/profile" })
            .then((response) => {
                setUserDetails1({ ...response });
                setUserData(deepCopy(response));
                setUserDetail(deepCopy(response));
                showLoader(false);
            })
            .catch(() => {
                showLoader(false);
            });
    };


    const onSave = () => {
        showLoader(true);
        const { firstName, lastName } = userDetails.profile;
        fetchApi({
            method: "PUT",
            url: "developer/profile",
            data: { firstName, lastName },
        })
            .then(() => {
                setUserData(deepCopy(userDetails));
                setUserDetail(deepCopy(userDetails));
                showLoader(false);
                notification.success({
                    message: "Successfully updated",
                    duration: 3,
                });
            })
            .catch(() => {
                showLoader(false);
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3,
                });
            });
    };

    const getSubscription = () => {
        return fetchApi({
            method: "POST",
            url: `subscription/get`,
            isDevApp: false,
        }).then((body) => {
            if (body.statusCode === 200 || body.statusCode === 201) {
                // console.log("response",body)
                setSubscriptionId(body.doc.subscriptionId)
            }
        });
    }
    const onLoadInstances = () => {
        getClientCredentialsList();
    };

    useEffect(() => {
        getSubscription()
    }, []);

    useEffect(() => {
        getClientCredentialsList();
    }, [users]);

    const onUploadPhoto = () => {
        setUpload(true);
    };
    const getClientCredentialsList = () => {
        const filter = {
            and: [
                {
                    key: "",
                    value: false,
                    opt: "",
                },
            ],
        };

        const Options = () => {
            return {
                select: "",
                sort: "",
                skip: (pageSetting.page - 1) * (pageSetting.itemsPerPage),
                limit: pageSetting.itemsPerPage,
            };
        };

        let requestLink = `client-credential/getAll/?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;
        console.log("accountinfo-getPATTokenFromAPI", new Date().toISOString())
        showLoader(true)
        fetchApi({ method: "GET", url: requestLink, isDevApp: false })
            .then((response) => {
                const { data = [] } = response;
                // console.log("DZCs",data.length)
                if(data.length > 0){
                    if(data[0] != null){
                        if(data[0].appName === "defaultApp"){
                            if(data[0].token !== ""){
                                setPATToken({ patToken: data[0].token })
                            }
                        }
                    }
                }
                if (userData.profile.userRole === "DEVELOPER" && data.length > 0) {
                    setCreatedisabled(true)
                    setFilteredData([...data])
                } else {
                    setCreatedisabled(false)
                }
                if (userData.profile.userType !== DEVELOPERTYPE) {
                    if (users.length > 0 && data.length > 0) {
                        let s = []
                        for (let i = 0; i < users.length; i++) {
                            for (let j = 0; j < data.length; j++) {
                                if (users[i].value === data[j].ownership) {
                                    data[j].userName = users[i].label
                                    s.push(data[j])
                                }
                            }
                        }
                        showLoader(false);
                        setFilteredData([...s])

                    } else {
                        setFilteredData([]);
                        showLoader(false);
                        setTotalCount(0)
                    }
                }
                setFilteredData([...data])
                setTotalCount(response.totalCount)
                // console.log("totalCount",filteredData.length)
                showLoader(false);
            })
            .catch((e) => {
                // console.log("error",e)
                setFilteredData([]);
                showLoader(false);
            });
    };

    const onFilterDataChange = () => {
        // console.log("onFilterDataChange========>")
        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > filteredData.length
                ? filteredData.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchText
                ? filteredData.filter((item) =>
                    item.status
                        .toLowerCase()
                        .includes(pageSetting.searchText.toLowerCase())
                )
                : deepCopy(filteredData),
                pageSetting.searchText
                    ? filteredData.filter((item) =>
                        item.protocolName
                            .toLowerCase()
                            .includes(pageSetting.searchText.toLowerCase())
                    )
                    : deepCopy(filteredData)
        )
            .filter((item) =>
                pageSetting.filterValue !== ""
                    ? (pageSetting.filterValue === "locked" && item.isLocked) ||
                    (pageSetting.filterValue === "unlocked" && !item.isLocked)
                    : true
            )
            .slice(0, endIndex);
    };
    useEffect(() => {
        setTotalCount(totalCount);
    }, [filteredData]);



    const handleAPI = (row, type) => {
        let data = {
            updateType: "",
            ownership: "",
            teamId: ""
        }
        if (type === "transfer") {
            // console.log("transfer",row)
            setTransferOpen(true)
            setTransferId(row._id)
            return
        } else if (type === "refresh") {
            data = { updateType: "REFRESH" }
            setMessage("Credentials Refreshed Successfully.")
            // console.log("refresh",row)
        } else if (type === "delete") {
            data = { updateType: "DELETED" }
            setMessage("Credentials Deleted Successfully.")
            // console.log("delete",row)
        } else if (type === "inactive") {
            if (row.status === "ACTIVE") {
                data = { updateType: "INACTIVE" }
            } else {
                data = { updateType: "ACTIVE" }
            }
            setMessage("Credentials Suspended Successfully.")
        }
        fetchApi({
            method: "PUT",
            url: `client-credential/update/${row._id}`,
            isDevApp: false,
            data: data,
        })
            .then((response) => {
                notification.destroy();
                if (response.statusCode === 201 || response.statusCode === 200) {
                    notification.success({
                        message: response.message,
                        duration: 5,
                    });
                    getClientCredentialsList()
                } else {
                    notification.error({
                        message: response.message,
                        duration: 5,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
            });

    }
    const onTextChange = (id, value) => {
        setUserDetails1((user) => {
            return {
                ...user,
                profile: {
                    ...user.profile,
                    [id]: value,
                },
            };
        });
    };
    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getClientCredentialsList();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        // console.log("pageSetting.itemsPerPage",pageSetting.itemsPerPage)
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getClientCredentialsList();
    };

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;


    let fileSizeInKB = ''
    let fileType = ''
    if (photo !== null && photo !== undefined && photo !== "") {
        const fileSizeInBytes = photo.length * 0.75;
        fileSizeInKB = fileSizeInBytes / 1024;
        fileType = photo.split(',')[0].match(/:(.*?);/)[1];
    }
    return (
        <div className="my-account-page-container">
            <div>
                <div className="profile-header" style={{marginBottom:"20px"}}>Personal Details</div>
                <div style={{display:"flex",gap:"30px"}}>
                    <div>
                        {isLoading ? (<Skeleton height={35} style={{ marginBottom: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (<div className="profile-text"><span style={{fontWeight:"700"}}>Name&nbsp;:</span>&nbsp;{firstName+" "+lastName}</div>)}
                        {isLoading ? (<Skeleton height={35} style={{ marginBottom: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (<div className="profile-text"><span style={{fontWeight:"700"}}>User ID&nbsp;:</span>&nbsp;{userData.profile.userType === DEVELOPERTYPE ? userData.profile.developerId : userData.profile.userType === "ENTERPRISE" ? userData.profile.enterpriseId : userData.profile.developerId}</div>)}
                    </div>
                    <div>
                        {isLoading ? (<Skeleton height={35} style={{ marginBottom: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (<div className="profile-text"><span style={{fontWeight:"700"}}>Email&nbsp;:</span>&nbsp;{emailId}</div>)}
                        {isLoading ? (<Skeleton height={35} style={{ marginBottom: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (<div className="profile-text"><span style={{fontWeight:"700"}}>Subscription ID&nbsp;:</span>&nbsp;{userData.profile.userRole === "ENTERPRISE-DEVELOPER" ? userData.profile.subscriptionId : subscriptionId}</div>)}
                        {userData.profile.userType === "ENTERPRISE" ? <>
                            {isLoading ? (<Skeleton height={35} style={{ marginBottom: '10px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (<div className="profile-text"><span style={{fontWeight:"700"}}>Enterprise ID&nbsp;:</span>&nbsp;{userData.profile.enterpriseId}</div>)}</> : <></>}
                    </div>
                </div>
            </div>
            <br />
            <div className="account-setting-enterprise">
                <div className="profile-header">User Credentials</div>

            </div>
            <CreateClientCredentials
                isOpen={clientCredentialsOpen}
                toggleState={onCreateClientCredentials}
                reloadCollection={onLoadInstances}
            />
            <Transfer
                isOpen={transferOpen}
                toggleState={onTransferOpen}
                id={transferId}
                reloadCollection={onLoadInstances}
            />
            <div className="table-container marginBtm50">
                {isLoading ? (<Skeleton height={200} style={{ marginBottom: '50px' }} baseColor="#262626" highlightColor="#404040" ></Skeleton>) : (
                    <TableContent
                        data={filteredData}
                        columns={getUserDetails().profile.userRole === ENTERPRISEADMINROLE ? columns(handleAPI) : columnsDeveloper(handleAPI)}
                        isDashboard={false}
                        pagination={false}

                    />
                )}
            </div>
            <PaginationSection
                last={last}
                first={startIndex + 1}
                hitsList={hitsList}
                totalCount={totalCount}
                page={pageSetting.page}
                itemsPerPage={pageSetting.itemsPerPage}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
            />
        </div>
    );
};

export default Account;

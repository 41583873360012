import React from "react";

export const Tick = ({ width = "19px", height = "19px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 19 19"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <circle cx="9.5" cy="9.5" r="9.5" fill="#20CD89" />
    <path
      d="M5.4652 8.78574C5.5852 8.66577 5.74794 8.59838 5.91763 8.59838C6.08732 8.59838 6.25006 8.66577 6.37007 8.78574L8.63288 11.0486L13.1572 6.52357C13.2166 6.46411 13.2872 6.41694 13.3648 6.38475C13.4425 6.35255 13.5257 6.33597 13.6098 6.33594C13.6938 6.33591 13.7771 6.35243 13.8547 6.38457C13.9324 6.41671 14.003 6.46383 14.0624 6.52325C14.1219 6.58266 14.1691 6.65321 14.2012 6.73085C14.2334 6.8085 14.25 6.89172 14.2501 6.97578C14.2501 7.05983 14.2336 7.14307 14.2014 7.22074C14.1693 7.29841 14.1222 7.36898 14.0627 7.42844L8.63288 12.8583L5.4652 9.69061C5.34523 9.57061 5.27783 9.40787 5.27783 9.23818C5.27783 9.06849 5.34523 8.90575 5.4652 8.78574Z"
      fill="#06082F"
    />
  </svg>
);

import React from "react";
import { Upload } from "antd";
import { Menus } from "../../assets";

const { Dragger } = Upload;

export const Uploader = ({ uploadBase, isBase = true }) => {
  const onGetbase = (file) => {
    if (!isBase) {
      uploadBase(file);
    } else {
      let reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => {
        uploadBase(reader.result);
      };
    }
  };

  return (
    <Dragger
      multiple={false}
      maxCount={1}
      beforeUpload={(file) => {
        onGetbase(file);
        return false;
      }}
      onRemove={(file) => {
        uploadBase(null);
      }}
    >
      <Menus type="upload file" />
      <p className="nft-upload-header">Drag and Drop file to upload</p>
      <p className="nft-upload-limmiter">or</p>
      <p className="nft-upload-browse">Browse</p>
    </Dragger>
  );
};

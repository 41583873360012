import React, { useEffect, useState } from 'react'
import {Badge, Popover, Collapse, notification } from "antd";
import { Menus } from "../../assets";
import { UserIcon } from "../../library";
import {
    getUserDetails,
    DEVELOPERROLE,
    ENTERPRISEDEVELOPERROLE, removeAllDataInLocal, getProfileImage,
} from "../../deps";
import { fetchApi } from "../../_config/api";
import {Link, useNavigate} from "react-router-dom";
import AiAssistant from "../../pages/advanced/aiAssistant";
import krypcMiniLogo from '../../assets/icons/Group 5.svg';
import { Dropdown, Space, Menu } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import Logo from "../../assets/logos/logo.avif";
import Account from "./MyAccount/images/account.png";
import Support  from "./MyAccount/images/support.png";
import Refferal from "./MyAccount/images/referral.png";
import Logout from "./MyAccount/images/logout.png";
import "./MyAccount/styles.less";
import {useAuth0} from "@auth0/auth0-react";
import detectEthereumProvider from "@metamask/detect-provider";
import star from "../../assets/icons/Group108.svg";
import DownIcon from "../../assets/menus/DownIcon";
import DownIcon1 from "../../assets/menus/DownIcon.avif";
import userProfileImage from "./MyAccount/images/profile.svg"


const Header = ({
    hasPremium = false,
  pageTitle,
  onNavigate,
  notificationList = [],
  onRead
}) => {
    const {
        photo,
        firstName = "",
        lastName = "",
        userRole = DEVELOPERROLE,
      } = getUserDetails().profile || {};
      const name = firstName + " " + lastName;
      const [endpointType, setEndpointType] = useState("Endpoint Type");
      const [data, setData] = useState([]);
      const navigate = useNavigate();
      const [historyTabOpen, setHistoryTabOpen] = React.useState(true);
      const [isLoading, showLoader] = useState(false);
    const { logout } = useAuth0();
    const [connecting, setConnecting] = useState(false);
    const initialState = { accounts: [] };
    const [wallet, setWallet] = useState(initialState);
    const profileImage = getProfileImage();
    const onClickAccount = () => {
        navigate(`/account-settings`);
      };
    const onSignOut = () => {
        onUserLogout();
        removeAllDataInLocal();
        logout({
            returnTo: window.location.origin,
        });
    };
    const onUserLogout = () =>{
        fetchApi({ method: "POST", url: "auth/logout", })
            .then((response) => {
                if (response.statusCode === 200) {
                    console.log("success logout")
                }
            })
            .catch(() => {
                console.log("failure logout")
            });
    }
    const items = [
        {
            key: '1',
            label: (
                <Link to={"/account-settings"}>
                    <img width="30px" height="30px" src={Account} alt="profile"/><span style={{paddingLeft:"20px",fontFamily:'Poppins',fontSize:'16px',fontWeight:'500',lineHeight:'35px'}}>My Account</span>
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link to={"/support"} state={{activeKey:"referral"}}>
                    <img width="30px" height="30px" src={Support} alt="profile"/><span style={{paddingLeft:"20px",fontFamily:'Poppins',fontSize:'16px',fontWeight:'500',lineHeight:'35px'}}>Support</span>
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link to={"/account-settings"} state={{activeKey:"referral"}}>
                    <img width="30px" height="30px" src={Refferal} alt="profile"/><span style={{paddingLeft:"20px",fontFamily:'Poppins',fontSize:'16px',fontWeight:'500',lineHeight:'35px'}}>Referral</span>
                </Link>
            ),
        },
        {
            key: '4',
            label: (
                <div>
                    <div onClick={onSignOut}>
                        <img width="30px" height="30px" src={Logout} alt="profile"/><span style={{paddingLeft:"20px",fontFamily:'Poppins',fontSize:'16px',fontWeight:'500',lineHeight:'35px'}}>Log Out</span>
                    </div>
                </div>
            ),
        },
    ];
    const menu = (
        <Menu>
            {items.map((item, index) => (
                <React.Fragment key={item.key}>
                    <Menu.Item>{item.label}</Menu.Item>
                    {index !== items.length - 1 && (
                        <hr style={{ background: "white", margin: "0px 20px 0px 20px" }} />
                    )}
                </React.Fragment>
            ))}
        </Menu>
    );

    useEffect(() => {
        getEndpointsList();
      }, [endpointType]);
    
      const getEndpointsList = () => {
        fetchApi({ method: "GET", url: "endpoints" })
          .then((response) => {
            const { docs = [] } = response;
            let list = []
            for (let i = 0; i < docs.length; i++) {
              if (docs[i].infraType === endpointType) {
                list.push(docs[i])
              }
              setData(list)
            }
          })
          .catch(() => {
            setData([]);
          });
      };
      const toggleHistoryTab = () => {
        setHistoryTabOpen(!historyTabOpen);
      }


    useEffect(() => {
        const refreshAccounts = (accounts) => {
            if (accounts.length > 0) {
                updateWallet(accounts);
            } else {
                setWallet(initialState);
            }
        };
        const getProvider = async () => {
            const provider = await detectEthereumProvider({ silent: true });
            if (provider) {
                const accounts = await window.ethereum.request(
                    { method: "eth_accounts" }
                );
                refreshAccounts(accounts);
                window.ethereum.on(
                    "accountsChanged",
                    refreshAccounts
                );
            }
        };
        getProvider();
        return () => {
            window.ethereum?.removeListener("accountsChanged", refreshAccounts);
        };
    }, []);
    const updateWallet = async (accounts) => {
        setWallet({ accounts });
    };
    const connectToMetaMask = async () => {
        if (connecting) return;
        setConnecting(true);
        if (window.ethereum) {
            try {
                let accounts = await window.ethereum.request({
                    method: "eth_requestAccounts",
                });
                updateWallet(accounts);
            } catch (error) {
                console.error(error);
            } finally {
                setConnecting(false);
            }
        } else {
            notification.error({
                message: "MetaMask is not installed",
                duration: 3,
            });
            setConnecting(false);
        }
    };


      console.log(pageTitle)


  return (
    <>
      <div className="header-container">
        <div className="header-text">{pageTitle}</div>
          <div className="profile-section">
              {wallet.accounts.length > 0 ? (
                  <div style={{margin: "40px", fontSize: "16px", padding:"7px 10px 7px 10px", border:"1px solid ", borderRadius:"15px"}}
                       className="header-text">{wallet.accounts[0] && `${wallet.accounts[0].slice(0, 8)}...`}</div>
              ) : <button className="connectmeta"
                          onClick={connectToMetaMask}>{connecting ? 'Connecting...' : 'Connect Wallet'}</button>
              }
              {
                  (hasPremium =
                      true || userRole === ENTERPRISEDEVELOPERROLE ? null : (
                          <button className="upgrade-btn" onClick={onNavigate}>
                              <Menus type="rocket"/>
                              <span className="btn-text">Upgrade</span>
                          </button>
                      ))
              }
              <button className="btn-ai-button" onClick={() => {
                  toggleHistoryTab(setHistoryTabOpen(true))
              }}>
                  <img src={star} alt="Star" style={{marginRight: '8px', height: '25px'}}/>
                  <span className='btn-ai-button-span'>Krypcore&nbsp;AI</span>
              </button>
              <div>
                  {profileImage ? (<img src={profileImage} alt="Profile" style={{ width: '50px', height: '50px', borderRadius: '50%' , marginLeft: "30px"}}/>) : (
                      <img src={userProfileImage} className='krypc-mini-logo' alt="Default Profile"/>)}
                  {/*<img src={krypcMiniLogo} className='krypc-mini-logo'/>*/}

              </div>

              {userRole !== ENTERPRISEDEVELOPERROLE && (
                  <>
                      {/* <div className=" notification">
                <Popover
                  placement=" bottomRight"
                  title=" Your notifications"
                  content={content}
                  trigger={" click"}
                >
                  <button
                    style={{
                      border: " none",
                      backgroundColor: " transparent",
                      marginBottom: ".30rem",
                  }}
                  >
                  <Badge
                      count={notificationList.length}
                      color="blueviolet"
                      style={notificationList.length === 0 ? { right: "5px" } : {}}
                      dot={notificationList.length === 0}
                    >
                      <Menus type="bell" />
                    </Badge>
                  </button>
                </Popover>
              </div> */}
                  </>
              )}
              {/*<UserIcon url={photo} name={name} click={onClickAccount} />*/}
              {/*<div style={{ display: "grid" }}>
            <div className="user-name"><a onClick={onClickAccount}>{firstName}</a></div>
            <div className="user-name">{getUserDetails().profile.userRole === "ENTERPRISE-ADMIN" ? "(ADMIN)" : getUserDetails().profile.userType === "INDIVIDUAL" ? "" : "(DEVELOPER)"}</div>
          </div>*/}
              {/*<div className="down-menu-item ml-3" style={{cursor:"pointer"}} onClick={onSignOut} title="Log out">
            <Menus type="logout" />
          </div>*/}
        </div>
        <div style={{padding: "10px 0px 25px 10px",cursor:'pointer'}}>
                <Dropdown overlay={menu}  overlayStyle={{ zIndex: 5000 }} trigger={['click']}>
                        <Space >
                            <img src={DownIcon1} style={{height: "14px", width: "16px"}}/>
                        </Space>
                </Dropdown>
              {/*<img width="15px" height="15px" src={DownArrow}/>*/}
        </div>
      </div>
      <AiAssistant chatOpen={toggleHistoryTab} isopen={historyTabOpen} showLoader={showLoader}/>
    </>
  )
}

export default Header
import React from "react";

const meunList = [
    {text: 'Home', to: '/home'},
    {text: 'About Us', to: '/about-us'},
    {text: 'Feature', to: '/feature'},
    {text: 'Pricing', to: '/pricing'},
]

export const Header = () => (
    <div className="header-container">
        {meunList.map((list, index) => {
            return(
                <div key={index} className="menu-item">{list.text}</div>
            )
        })}
        <button className="contact-us-btn">Contact Us</button>
    </div>
)

export default Header;
import { Menus } from "../../../assets";
import React, {useEffect, useState} from "react";
import { Modal, Space } from "antd";
import { AddWhitelistedContractLinkedToDApp } from "./addWhitlistedContractLinkedToDapp";
import {AddExistingContractAsWhitelisted} from "./addExistingContractAsWhitelisted";

export const AddWhitelistedContract = ({
                                           displayFormConst,
                                           isModalOpen,
                                           setModalState,
                                       }) => {
    const [displayForm, setDisplayForm] = useState(displayFormConst);

    function openForm(s) {
        setDisplayForm(s);
    }

    function callbackFromForm(){
        setDisplayForm("C")
        setModalState()
    }

    useEffect(()=>{
        setDisplayForm("C");
    },[displayFormConst])

    return (
        <>
            <Modal
                title=""
                footer={null}
                closable={false}
                visible={isModalOpen} // Change 'open' to 'visible'
                style={{ width: "100%" }}
                maskStyle={{backgroundColor: '#CFCFCF80'}} bodyStyle={{width:"750px"}}
            >
                <div className="nft-collection-popup">
                    <div className="close-icon" onClick={setModalState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text">{"Add Whitelisted Contract"}</div>
                    <Space
                        direction="vertical"
                        size={25}
                        style={{display: "flex", marginTop: "25px", paddingBottom: "30px", }}
                    >
                        {/*{displayForm === "C"&&(*/}
                        {/*    <div*/}
                        {/*        style={{*/}
                        {/*            display: "flex",*/}
                        {/*            justifyContent: "space-around",*/}
                        {/*            gap: "30px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <button style={{ flex: "1" }} onClick={() => openForm("A")}>*/}
                        {/*            Whitelist Contract Linked to dApp*/}
                        {/*        </button>*/}
                        {/*        <button style={{ flex: "1" }} onClick={() => openForm("B")}>*/}
                        {/*            Existing Contract and Link to dApp*/}
                        {/*        </button>*/}
                        {/*    </div>*/}
                        <div
                            style={{
                                display: "flex",
                                // justifyContent: "space-around",
                                gap: "30px",
                                flexDirection: "column",
                                alignItems: "center"
                            }}
                        >
                            <button style={{flex: "1", padding: "12px", width: "50%"}} onClick={() => openForm("A")}>
                                Whitelist Contract Linked to dApp
                            </button>
                            <button style={{flex: "1", padding: "12px", width: "50%"}} onClick={() => openForm("B")}>
                                Existing Contract and Link to dApp
                            </button>
                        </div>
                        {/*)}*/}

                        {displayForm === "A" && (
                            <AddWhitelistedContractLinkedToDApp callBackFunc={callbackFromForm}/>
                        )}
                        {displayForm === "B" && (
                            <AddExistingContractAsWhitelisted callBackFunc={callbackFromForm}/>
                        )}
                    </Space>
                </div>
            </Modal>
        </>
    );
};

// Essentials
import React, {useEffect, useState} from "react";
//Utils
import { fetchApi } from "../../../../_config/api";
// Components and Styles
import "../../../nftstudio/styles.less";
import '../styles.less'
import { Modal, Space, Input, Form, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Menus } from "../../../../assets";
import {DEVELOPERTYPE, ENTERPRISEADMINROLE, getUserDetails} from "../../../../deps";

export const CreateClientCredentials = ({
                                            isOpen,
                                            toggleState,
                                            reloadCollection,
                                        }) => {
    const formRef = React.useRef(null);
    const [chains, setChains] = React.useState([]);
    const [users, setUsers] = useState([]);

    const [error, setError] = React.useState({
        appName:"",userId:"",teamId:""
    });

    const [deployDisabled, setDeployDisabled] = React.useState(false);
    const userData = getUserDetails();
    React.useEffect(() => {
        formRef?.current?.resetFields();
        setError({
            appName:"",
            userId: "",
            teamId: ""
        })
    }, [isOpen]);

    const onCreateClientCredentials = (value) => {
        if (value.appName === "" || value.appName === undefined){
            setError({
                appName:"please enter the Data"})
            return
        }else if (value.ownership === "" || value.ownership === undefined){
            setError({userId:"please choose the userId"})
            return
        }else if(value.teamId === "" || value.teamId === undefined){
            setError({teamId:"please choose the userId"})
            return
        }else{
            setError({
                appName:"",
                userId: "",
                teamId: ""
            })
        }
        // }

        let data = {}
        if (userData.profile.userType === DEVELOPERTYPE) {
            data = {
                appName: value.appName,
                ownership: "",
                teamId: "",
            }
        }else{
            data = {
                ...value
            }
        }
        // console.log("fromdata",data)
        fetchApi({
            method: "POST",
            url: "client-credential/create",
            isDevApp: false,
            data: data,
        })
            .then((response) => {
                toggleState();
                if (response.statusCode === 201 || response.statusCode === 200){
                    notification.success({
                        message:
                            "New Credential Created Successfully.",
                        duration: 5,
                    });
                    reloadCollection()
                }else{
                    notification.error({
                        message: response.message,
                        duration: 5,
                    });
                }

            })
            .catch((error) => {
                console.error(error);
                notification.error({
                    message: "Not able to Create the Credentials",
                    duration: 3,
                });
            });
    };

    const onChangeSelect = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
    };

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text">Create Client Credentials</div>
                    <Space
                        direction="vertical"
                        size={25}
                        style={{ display: "flex", marginTop: "25px" }}
                    >
                        <Form
                            name="basic"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onCreateClientCredentials}
                            autoComplete="off"
                        >
                            <Form.Item name="appName">
                                <Input
                                    className="input-container"
                                    placeholder="Enter Data"
                                    maxlength = {20}
                                />
                            </Form.Item>
                            <p className="error_text">{error.appName}</p>
                            {userData.profile.userRole === ENTERPRISEADMINROLE?
                                <><Form.Item name="ownership">
                                    <>
                                        <select
                                            className="dropdown-filter create-collection-drop"
                                            placeholder="Choose User ID"
                                            name="ownership"
                                            onChange={(event) => onChangeSelect(event, "ownership")}
                                        >
                                            <option value="">Choose User ID</option>
                                            {users.map((users, ind) => (
                                                <option key={ind} value={users.value}>
                                                    {users.label}
                                                </option>
                                            ))}
                                        </select>
                                    </>
                                </Form.Item>
                                    <p className="error_text">{error.userId}</p>
                                    <Form.Item name="teamId">
                                        <>
                                            <select
                                                className="dropdown-filter create-collection-drop"
                                                placeholder="Choose Team ID"
                                                name="teamId"
                                                onChange={(event) =>
                                                    onChangeSelect(event, "teamId")
                                                }
                                            >
                                                <option value="">Choose Team ID</option>
                                                {chains.map((chain, ind) => (
                                                    <option key={ind} value={chain.value}>
                                                        {chain.label}
                                                    </option>
                                                ))}
                                            </select>
                                        </>
                                    </Form.Item>
                                    <p className="error_text">{error.teamId}</p>
                                </>:<></>}
                            <div className="deploy-nft-collection">
                                <button disabled={deployDisabled} htmlType="submit">
                                    {!deployDisabled ? (
                                        "Submit"
                                    ) : (
                                        <Spin indicator={antIcon} />
                                    )}
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    );
};

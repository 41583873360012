import React, {useEffect, useState} from 'react'
import {Modal, Space, Input, Form, notification} from "antd";
import { Menus } from '../../../assets';
import {LoadingOutlined, MinusCircleOutlined, PlusCircleOutlined, UserOutlined} from '@ant-design/icons';
import { Button } from 'antd';
import fetchApi from "../../../_config/api";

const CreateTeams = (
    {
        isOpen,
        toggleState,
    }
) => {
    const [developers, setDevelopers] = useState([
        {
            email:"",
            developerId: "",
            roleId: "",
        },
    ]);
    const [roles, setRoles] = useState([]);
    const [data, setData] = useState({
        name: "",
    });
    useEffect(() => {
        getRoles()
    }, []);
    const  getRoles = () => {
        return fetchApi({
            method: "GET",
            url: `teams/getDeveloperRoles`,
            isDevApp: false,
        }).then((response) => {
            if (response.statusCode === 200 || response.statusCode === 201) {
                let role = response.doc.map((role) => ({
                    label: `${role.name}`,
                    value: role.roleId,
                }));
                setRoles([...role])
            }
        })
    }
    const addDeveloper = () => {
        if (developers.every((item) => item.email !== "" && item.roleId !== "")) {
            setDevelopers([
                ...developers,
                {
                    email:"",
                    developerId: "",
                    roleId: "",
                },
            ]);
        }
    };

    const removeDeveloper = (index) => {
        const rows = [...developers];
        rows.splice(index, 1);
        setDevelopers(rows);
    };

    const handleChange = (index, event, name) => {
        const value = event.target.value;
        // if(name === "email"){
        //     const isValueAlreadySelected = developers.some((item, i) => i !== index && item[name] === value);
        //     if (isValueAlreadySelected) {
        //         notification.error({
        //             message: "Developer already selected",
        //             duration: 3,
        //         });
        //         return;
        //     }
        // }
        const newList = developers.map((item, i) => {
            if (i === index) {
                return { ...item, [name]: value };
            }
            return item;
        });

        setDevelopers(newList);
    };
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    const [loading, setLoading] = useState(false);
    const onTextChange = (e) => {
        onChange(e.target.id, e.target.value);
    };
    const onChange = (id, value) => {
        data[id] = value;
        setData({ ...data });
    };
    const addTeam = () => {
        let accessBody = ""
        const hasEmptyDeveloperIds = developers.some(
            (item) => item.email === ""
        );
        const hasEmptyRoleIds = developers.some((item) => item.roleId === "");
        if (hasEmptyDeveloperIds){
            notification.error({
                message: "Please enter email",
                duration: 3,
            });
            return;
        }if (hasEmptyRoleIds){
            notification.error({
                message: "Please select role",
                duration: 3,
            });
            return;
        }
        accessBody = {
            ...data,
            enterpriseId: "",
            developers,
            projects:[]
        };
        setLoading(true);
        fetchApi({
            method: "POST",
            url: `teams/createTeam`,
            data: accessBody,
            isDevApp: false,
        })
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    setLoading(false);
                    notification.success({
                        message: "successfully created",
                        duration: 3,
                    });
                    toggleState();
                }else{
                    setLoading(false);
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });
                }
            })
            .catch((err) => {
                setLoading(false);
            });
    };

    return (
        <>
            <Modal maskStyle={{backgroundColor: '#CFCFCF80'}} centered  title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup endpoint-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text d-inline">
                        <p style={{paddingLeft:"50px"}} className="popup-text-endpoint">Create New Team</p>
                    </div>
                    <Space
                        direction="vertical"
                        size={50}
                        style={{paddingLeft:"50px"}}
                    >
                        <Form
                            name="basic"
                            // ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={addTeam}
                            autoComplete="off"
                        >
                            <div className='create-dapp-form-row'>

                                <Form.Item
                                    className="teamName"
                                    name="name"
                                    rules={[
                                        {
                                            // required: isEnterpriseAdmin,
                                            message: 'Missing Team Name',
                                        },
                                    ]}
                                >
                                    <div className="input-wrapper">
                                        <label htmlFor="teamName" className="input-label">
                                            <p>Team Name</p>
                                        </label>
                                        <input
                                            maxLength={100}
                                            id="name"
                                            placeholder="Enter Team Name"
                                            className="input-field"
                                            onChange={onTextChange}
                                            value={data.name}
                                        />
                                    </div>
                                </Form.Item>

                                <div className="Inviting">




                                {developers.map((data, index) => {
                                    const { roleId, developerId } = data;
                                    return (
                                        <React.Fragment key={index}>
                                            <div className="team">
                                                <Form.Item
                                                    className="teamEmail"
                                                    name="email"
                                                    rules={[
                                                        {
                                                            // required: isEnterpriseAdmin,
                                                            message: 'Missing Team Member',
                                                        },
                                                    ]}
                                                >
                                                    <div className="input-wrapper">
                                                        <label htmlFor="email" className="input-label">
                                                            <p>Team Member</p>
                                                        </label>
                                                        <div className="input-wrapper1">
                                                            <input
                                                                maxLength={100}
                                                                id="email"
                                                                placeholder="Enter email"
                                                                className="input-field"
                                                                // onChange={(evnt) => handleChange(index, evnt, "email")}
                                                                onKeyDown={(evnt) => {
                                                                    // Regular expression to match only email characters
                                                                    const validEmailCharacters = /^[a-zA-Z0-9@._-]*$/;
                                                                    if (!validEmailCharacters.test(evnt.key)) {
                                                                        evnt.preventDefault();
                                                                    }
                                                                }}
                                                                onChange={(evnt) => handleChange(index, evnt, "email")}
                                                            />

                                                        </div>
                                                    </div>
                                                </Form.Item>

                                                <div className="role">


                                                    <Form.Item
                                                        className="teamEmail"
                                                        name="roleId"
                                                        rules={[
                                                            {
                                                                // required: isEnterpriseAdmin,
                                                                message: 'Missing Role',
                                                            },
                                                        ]}
                                                    >
                                                        <label htmlFor="email" className="input-label">
                                                            <p>Select Role</p>
                                                        </label>
                                                        <select
                                                            className="dropdown-filter select-role-width"
                                                            placeholder="Select Role"
                                                            name="roleId"
                                                            value={roleId}
                                                            onChange={(evnt) => handleChange(index, evnt, "roleId")}
                                                        >
                                                            <option value="">Select Role</option>
                                                            {roles &&
                                                                roles?.map((item, index) => (
                                                                    <option key={index} value={item.value}>
                                                                        {item.label}
                                                                    </option>
                                                                ))}
                                                        </select>
                                                    </Form.Item>

                                                    {developers.length !== index + 1 && (
                                                        <Button
                                                            type="default"
                                                        shape="round"
                                                        icon={<MinusCircleOutlined/>}
                                                        onClick={() => {
                                                            removeDeveloper(index);
                                                        }}
                                                    />
                                                )}

                                                 <div className="addrem">

                                                {developers.length === index + 1 && (
                                                    <>
                                                        <Button
                                                            type="default"
                                                            shape="round"
                                                            icon={<PlusCircleOutlined/>}
                                                            onClick={addDeveloper}
                                                        />
                                                        {developers.length > 1 &&
                                                            <Button
                                                                type="default"
                                                                shape="round"
                                                                icon={<MinusCircleOutlined/>}
                                                                onClick={() => {
                                                                    removeDeveloper(index);
                                                                }}
                                                            />
                                                        }

                                                    </>

                                                )}
                                                 </div>

                                                </div>
                                            </div>

                                        </React.Fragment>
                                    );
                                })}

                                </div>
                            </div>

                            <div className="deploy-nft-collection">
                                <Button className="custom-button" htmlType="submit" loading={loading} icon={loading ? antIcon : null} style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    gap: '8px',
                                    marginLeft: '470px',
                                    width: '142px',
                                    justifyContent: 'center',
                                    alignItems: 'center',
                                    borderColor: 'transparent'
                                }}>
                                    Add Team
                                </Button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    )
}

export default CreateTeams

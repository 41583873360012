import React from "react";

export const CreditCard = ({ width = "28px", height = "28px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 28 28"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M4.66669 10.4999C4.66669 10.1905 4.7896 9.89375 5.0084 9.67496C5.22719 9.45617 5.52393 9.33325 5.83335 9.33325H10.5C10.8094 9.33325 11.1062 9.45617 11.325 9.67496C11.5438 9.89375 11.6667 10.1905 11.6667 10.4999C11.6667 10.8093 11.5438 11.1061 11.325 11.3249C11.1062 11.5437 10.8094 11.6666 10.5 11.6666H5.83335C5.52393 11.6666 5.22719 11.5437 5.0084 11.3249C4.7896 11.1061 4.66669 10.8093 4.66669 10.4999Z"
      fill="#20CD89"
    />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M4.66667 3.5C3.42899 3.5 2.242 3.99167 1.36683 4.86683C0.491665 5.742 0 6.92899 0 8.16667L0 19.8333C0 21.071 0.491665 22.258 1.36683 23.1332C2.242 24.0083 3.42899 24.5 4.66667 24.5H23.3333C24.571 24.5 25.758 24.0083 26.6332 23.1332C27.5083 22.258 28 21.071 28 19.8333V8.16667C28 6.92899 27.5083 5.742 26.6332 4.86683C25.758 3.99167 24.571 3.5 23.3333 3.5H4.66667ZM23.3333 5.83333H4.66667C4.04783 5.83333 3.45434 6.07917 3.01675 6.51675C2.57917 6.95434 2.33333 7.54783 2.33333 8.16667V16.3333H25.6667V8.16667C25.6667 7.54783 25.4208 6.95434 24.9832 6.51675C24.5457 6.07917 23.9522 5.83333 23.3333 5.83333ZM25.6667 18.6667H2.33333V19.8333C2.33333 20.4522 2.57917 21.0457 3.01675 21.4832C3.45434 21.9208 4.04783 22.1667 4.66667 22.1667H23.3333C23.9522 22.1667 24.5457 21.9208 24.9832 21.4832C25.4208 21.0457 25.6667 20.4522 25.6667 19.8333V18.6667Z"
      fill="#20CD89"
    />
  </svg>
);

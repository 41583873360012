import React from "react";
import { getUserDetails } from "../component/deps";

export const Restricted = ({ code, type, children }) => {
  const userData = getUserDetails();
  let permissions = userData.profile.permissions;
  if (type && userData.profile.teamPermissions) {
    permissions = userData.profile.teamPermissions[type] || [];
  }
  // console.log("permissions::", permissions);
  const isAllowed = permissions.some((e) => e.code === code);
  if (isAllowed || !code) return <>{children}</>;
};

export const isAllowed = (code, type = "admin") => {
  const userData = getUserDetails();
  let permissions = userData.profile.permissions;
  if (
    type &&
    userData.profile.teamPermissions &&
    userData.profile.teamPermissions[type]
  ) {
    permissions = userData.profile.teamPermissions[type];
  }
  return permissions.some((e) => e.code === code);
};

export default Restricted;

import React from "react";

export const Rocket = ({ width = "20px", height = "20px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 21"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M19.2476 2.08238C18.2654 1.86237 17.2619 1.75087 16.2553 1.74988C15.3047 1.68951 14.3515 1.81769 13.4505 2.12703C12.5496 2.43637 11.7187 2.92077 11.0056 3.55238C10.0665 4.49154 9.13617 5.42779 8.21456 6.36113C7.15587 6.24738 4.64477 6.18613 2.96488 7.88363C2.80192 8.04757 2.71045 8.26934 2.71045 8.5005C2.71045 8.73166 2.80192 8.95343 2.96488 9.11738L12.8518 19.0224C13.0157 19.1853 13.2375 19.2768 13.4686 19.2768C13.6998 19.2768 13.9215 19.1853 14.0854 19.0224C15.7916 17.2724 15.7391 14.8049 15.6341 13.7724L18.4427 10.9724C21.2338 8.18113 19.9651 2.93988 19.9126 2.72113C19.8722 2.56331 19.7886 2.4199 19.6711 2.30705C19.5536 2.1942 19.4069 2.11639 19.2476 2.08238ZM17.1828 9.73863L14.0679 12.8361C13.9671 12.938 13.8928 13.0631 13.8516 13.2004C13.8104 13.3377 13.8036 13.4831 13.8317 13.6236C14.0207 14.7942 13.8562 15.9946 13.3592 17.0711L4.89851 8.59238C5.99206 8.08905 7.2145 7.93623 8.39829 8.15488C8.53835 8.17311 8.68074 8.15717 8.8133 8.10841C8.94586 8.05965 9.06464 7.97953 9.1595 7.87488C9.1595 7.87488 10.3844 6.60613 12.2306 4.75988C13.3657 3.84823 14.803 3.39828 16.2553 3.49988C16.9385 3.50326 17.6202 3.56178 18.2939 3.67488C18.5039 4.92613 18.8364 8.08488 17.1828 9.73863Z"
      fill="#20CD89"
    />
    <path
      d="M14.7068 9.01221C15.6733 9.01221 16.4568 8.22871 16.4568 7.26221C16.4568 6.29571 15.6733 5.51221 14.7068 5.51221C13.7403 5.51221 12.9568 6.29571 12.9568 7.26221C12.9568 8.22871 13.7403 9.01221 14.7068 9.01221Z"
      fill="#20CD89"
    />
    <path
      d="M5.31836 14C3.56836 14.875 3.56836 18.375 3.56836 18.375C5.18804 18.3288 6.73862 17.7086 7.94336 16.625L5.31836 14Z"
      fill="#20CD89"
    />
  </svg>
);

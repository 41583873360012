// Essentials
import React from "react";
//Utils
import { fetchApi } from "../../_config/api";
// Components and Styles
import "../nftstudio/styles.less";
import "./styles.less";
import {Modal, Space, Input, Form, notification, Spin, Select} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import {Icons, Menus} from "../../assets";
import TextArea from "antd/es/input/TextArea";

export const SendQuery = ({
                                      isOpen,
                                      toggleState,
                                      id,
                                      reloadCollection,
                                      activeKey,
                                      query,
    subscription,emailId,name
                                  }) => {
    const [form] = Form.useForm();
    const formRef = React.useRef(null);
    const [deployDisabled, setDeployDisabled] = React.useState(false);

    React.useEffect(() => {
    }, []);


    const onCreateRequest = (value) => {
        setDeployDisabled(true);
        let data = {
            ...value,
        };
        data.name = name;
        fetchApi({
            method: "POST",
            url: "developer/support",
            isDevApp: false,
            data: data,
        })
            .then((response) => {
                if(response.statusCode === 200 || response.statusCode === 201){
                    notification.success({
                        message:
                            "Query Submitted Successfully.",
                        duration: 5,
                    });
                    reloadCollection();
                    toggleState();
                    query(true);
                    // activeKey(infraType);
                } else if(response.message){
                    notification.error({
                        message:response.message,
                        duration: 5,
                    });
                }
                setDeployDisabled(false);
                form.resetFields();
            })
            .catch((error) => {
                console.error(error);
                setDeployDisabled(false);
                notification.error({
                    message: error.toString(),
                    duration: 3,
                });
            });
    };


    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text justify-content-start">Submit your Query</div>
                    <Space
                        direction="vertical"
                        size={25}
                        style={{ display: "flex", marginTop: "25px" }}
                    >
                        <Form
                            name="basic"
                            form={form}
                            ref={formRef}
                            initialValues={{
                                emailId: emailId,
                                subscriptionId: subscription
                            }}
                            onFinish={onCreateRequest}
                            autoComplete="off"
                        >
                            <Form.Item name="subscriptionId" rules={[
                                {
                                    required: true,
                                    message: "Missing Subscription Field",
                                },
                            ]}>
                                <Input
                                    disabled
                                    style={{color:"aliceblue"}}
                                    name="subscriptionId"
                                    className="input-container"
                                    placeholder="Subscription Id"
                                    maxLength = {50}
                                />
                            </Form.Item>
                            <Form.Item name="emailId" rules={[
                                {
                                    required: true,
                                    message: "Missing Email Field",
                                },
                            ]}>
                                <Input
                                    style={{color:"aliceblue"}}
                                    disabled
                                    name="emailId"
                                    className="input-container"
                                    placeholder="Email Id"
                                    maxLength = {50}
                                />
                            </Form.Item>
                            {/*<p className="error_text">{error.emailId}</p>*/}

                            <Form.Item name="query" rules={[
                                {
                                    required: true,
                                    message: "Missing Query Field",
                                },
                            ]}>
                                <TextArea
                                    name="query"
                                    className="input-container"
                                    placeholder="Query"
                                    maxLength = {200}
                                    style={{minHeight:200}}
                                />
                            </Form.Item>



                            <div className="deploy-nft-collection justify-content-start">
                                <button disabled={deployDisabled} htmlType="submit">
                                    {!deployDisabled ? (
                                        "Send"
                                    ) : (
                                        <Spin indicator={antIcon} />
                                    )}
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    );
};
/* eslint-disable react/style-prop-object */
import React from "react";

export const UniqueAddress = ({ width = "50px", height = "50px" }) => (
<svg
  width={width}
  height={height}
  viewBox="0 0 22 22"
  fill="none"
  xmlns="http://www.w3.org/2000/svg">
  <path d="M14.9967 12.834C15.07 12.229 15.125 11.624 15.125 11.0007C15.125 10.3773 15.07 9.77232 14.9967 9.16732H18.095C18.2417 9.75398 18.3334 10.3682 18.3334 11.0007C18.3334 11.6332 18.2417 12.2473 18.095 12.834M13.3742 17.9307C13.9242 16.9131 14.3459 15.8132 14.6392 14.6673H17.3434C16.4553 16.1966 15.0463 17.355 13.3742 17.9307ZM13.145 12.834H8.85504C8.76337 12.229 8.70837 11.624 8.70837 11.0007C8.70837 10.3773 8.76337 9.76315 8.85504 9.16732H13.145C13.2275 9.76315 13.2917 10.3773 13.2917 11.0007C13.2917 11.624 13.2275 12.229 13.145 12.834ZM11 18.2973C10.2392 17.1973 9.62504 15.9782 9.24921 14.6673H12.7509C12.375 15.9782 11.7609 17.1973 11 18.2973ZM7.33337 7.33398H4.65671C5.53566 5.8006 6.9436 4.64035 8.61671 4.07065C8.06671 5.08815 7.65421 6.18815 7.33337 7.33398ZM4.65671 14.6673H7.33337C7.65421 15.8132 8.06671 16.9131 8.61671 17.9307C6.94711 17.3547 5.54115 16.1961 4.65671 14.6673ZM3.90504 12.834C3.75837 12.2473 3.66671 11.6332 3.66671 11.0007C3.66671 10.3682 3.75837 9.75398 3.90504 9.16732H7.00337C6.93004 9.77232 6.87504 10.3773 6.87504 11.0007C6.87504 11.624 6.93004 12.229 7.00337 12.834M11 3.69482C11.7609 4.79482 12.375 6.02315 12.7509 7.33398H9.24921C9.62504 6.02315 10.2392 4.79482 11 3.69482ZM17.3434 7.33398H14.6392C14.3523 6.19865 13.9275 5.10272 13.3742 4.07065C15.0609 4.64815 16.4634 5.81232 17.3434 7.33398ZM11 1.83398C5.93087 1.83398 1.83337 5.95898 1.83337 11.0007C1.83337 13.4318 2.79914 15.7634 4.51823 17.4825C5.36943 18.3337 6.37996 19.0089 7.49211 19.4695C8.60426 19.9302 9.79626 20.1673 11 20.1673C13.4312 20.1673 15.7628 19.2015 17.4819 17.4825C19.2009 15.7634 20.1667 13.4318 20.1667 11.0007C20.1667 9.79687 19.9296 8.60487 19.4689 7.49272C19.0083 6.38057 18.3331 5.37004 17.4819 4.51884C16.6306 3.66764 15.6201 2.99242 14.508 2.53176C13.3958 2.07109 12.2038 1.83398 11 1.83398Z" fill="white"/>
</svg>
);

export default UniqueAddress;

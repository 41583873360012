import React from 'react'
import Group10 from './Icons/Group 173 (1).svg'
import Group11 from './Icons/Group 173.svg'
import Group12 from './Icons/Group 174 (1).svg'
import Group13 from './Icons/Group 174.svg'
import Group14 from './Icons/Group 175 (1).svg'
import Group15 from './Icons/Group 175.svg'
import { useNavigate } from 'react-router-dom'
import { useInView } from 'react-intersection-observer'
import EnterprisePageMobileView from './EnterprisePageMobileView'

const EnterprisePage = () => {

  const navigate = useNavigate();

  const onNavigate = () => {
    navigate("/contact-us");
    window.scrollTo({ top: 0, behavior: 'smooth' });
};

const { ref, inView } = useInView({
  triggerOnce: true, // Only trigger animation once
  threshold: 0.1 // Trigger animation when 50% of the element is visible
});

  return (
    <div className='NewLandingEnterpriceN-wrapper' id="enterprise-wrap">
         <div className='NewLandingEnterpriceN' >
       <div  className='NewLandingEnterprice'>
        <div className='d-flex justify-content-between   landingpageenterprise'>
            <div>
                <div className="theme-heading">
                <h3 style={{color:'black'}}>For Enterprise</h3><br/>
             </div>
            <div className="theme-heading1">
                <p style={{color:'black'}} className='text-left'>Transform your business with Krypcore Web3!</p>
            </div>

            </div>
         
            <div>
            <button className="banner_btn_docs" onClick={() => onNavigate()}>Contact Us</button>
            </div>
            
        </div>
        <div ref={ref}  className={`laptopViewEnterprise d-flex pb-5 ${inView?'animated fadeInUp':'hidden'}`}>
            <div className='d-flex flex-column' style={{width:'400px'}}>
              <img src={Group13}  className='enterprice_img1' alt='Illustration-of-Enterprise-Package'/>
              <img src={Group12}   className='enterprice_img2' alt='Illustration-of-Enterprise-Package'/>
            </div>
            <div className='d-flex flex-column' style={{width:'400px'}}>
              <img src={Group11}   className='enterprice_img2' alt='Illustration-of-Enterprise-Package'/>
              <img src={Group14}  className='enterprice_img1' alt='Illustration-of-Enterprise-Package'/>
            </div>
            <div className='d-flex flex-column' style={{width:'400px'}}>
                <img src={Group15}   className='enterprice_img2' alt='Illustration-of-Enterprise-Package'/>
            <img src={Group10}  className='enterprice_img1' alt='Illustration-of-Enterprise-Package'/>
            </div>
        </div>
        <EnterprisePageMobileView/>
       
       </div>
    </div>

    </div>
   
  )
}

export default EnterprisePage
import React, { useEffect, useRef, useState } from "react";
import { Typography, Space, notification } from "antd";
import { InfoCircleOutlined } from "@ant-design/icons";
import { useNavigate } from "react-router-dom";
import ReCAPTCHA from "react-google-recaptcha";
import {Helmet} from "react-helmet";
import fetchApi from "../../_config/api";


export const ContactSupport = () => {


    const [selectedOption, setSelectedOption] = useState(null);
    const [isOpen, setIsOpen] = useState(false);
    const [userMessage, setUserMessage] = useState("");
    const [userSubject, setUserSubject] = useState("");
    const dropdownRef = useRef(null);
    console.log(userSubject)

    const toggleDropdown = () => setIsOpen(!isOpen);

    const handleOptionClick = (option) => {
        setUserSubject(option);
        setIsOpen(false);
    };

    const handleClickOutside = (event) => {
        if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
            setIsOpen(false);
        }
    };

    useEffect(() => {
        document.addEventListener('mousedown', handleClickOutside);
        return () => {
            document.removeEventListener('mousedown', handleClickOutside);
        };
    }, []);


    const options = [
        { id: 0, name: "Plan Pricing" },
        { id: 1, name: "Partnerships" },
        { id: 2, name: "General Queries" },
        { id: 3, name: "Enterprise Enquiry" },
    ];
    // const [firstName, setUserfirstName] = useState("");
    // const [lastName, setUserlastName] = useState("");
    const [userName, setUserName] = useState("");
    const [organization, setOrganizationName] = useState("");
    const [userEmail, setUserEmail] = useState("");
    const [captcha, setCaptcha] = useState("");
    

    const [captchaValue, setCaptchaValue] = useState('');
    const [isChecked, setIsChecked] = useState(false);
    console.log(captchaValue)

    const navigate = useNavigate()

    const handleCaptchaChange = (value) => {
        // console.log('Captcha token:', captchaValue);
        setCaptchaValue(value);
    };
    const onCapchaTextChange = (e) => {
        setCaptcha(e.target.value);
    };
    const onFirstNameTextChange = (e) => {
        setUserName(e.target.value);
    };
    // const onLastNameTextChange = (e) => {
    //     setUserlastName(e.target.value);
    // };
    const onOrgnizationTextChange = (e) => {
        setOrganizationName(e.target.value);
    };

    const onEmailTextChange = (e) => {
        setUserEmail(e.target.value);
    };

    const onMsgTextChange = (e) => {
        setUserMessage(e.target.value);
    };
    const isFormValid = () => {
        return (
            userName.trim() !== '' &&
            userEmail.trim() !== '' &&
            userSubject.trim() !== '' &&
            // captcha.trim() !== '' &&
            isChecked
        );
    };
    const submit = () => {
        if (userEmail === '' || userName === '' || userSubject === '') {
            notification.error({
                message: "please fill the details",
                duration: 2,
            });
        } else {
            if (captchaValue === "") {
                // console.log('Captcha token:', captchaValue,captcha);
                notification.error({
                    message: "Captcha is incorrect",
                    duration: 2,
                });
            } else {
                // console.log('Captcha token:', captchaValue, captcha);
                const accessBody = {
                    emailId: userEmail,
                    firstName: userName,
                    lastName: userName,
                    // userName: userName,

                    organization: organization,
                    message: userMessage,
                    subject: userSubject,
                };
                fetchApi({ method: "POST", url: `developer/contact-us`, data: accessBody })
                    .then((response) => {
                        setUserName("");
                        // setUserlastName("");
                        setOrganizationName("");
                        setUserEmail("");
                        setUserMessage("");
                        setUserSubject("");
                        setCaptcha("");
                        navigate('/thankyou')
                    })
                    .catch(() => {
                    });
            }
        }
        ;
    }
    const { Title } = Typography;
    return (
        <>
            <Helmet>
                <meta charSet="utf-8" />
                <title>Get in Touch with Us - Contact Krypcore Web3</title>
                <meta name="description" content="Have inquiries, or suggestions? Reach out to Krypcore's dedicated team. Connect with us today Explore innovative solutions. Empower your dApp aspirations." />
                <link rel="canonical" href="http://krypcore.com" />
            </Helmet>
            <div className="card-contact-right animate fadeInRight four">
                <Space
                    direction="vertical"
                    size="middle"
                    style={{
                        display: "flex",
                        padding: "2rem",
                        paddingLeft: "2rem",
                        paddingRight: "2rem",
                    }}
                >
                    <div className="get-in-touch-h2">
                      Get In Touch!
                     </div>
                    <Space direction="vertical" size={15} style={{display: "flex"}}>
                        <input
                            type="text"
                            className="contact-form-input-container"
                            placeholder="Name"
                            value={userName}
                            onChange={onFirstNameTextChange}
                        />

                        <input
                            type="text"
                            className="contact-form-input-container"
                            placeholder="Organisation"
                            value={organization}
                            onChange={onOrgnizationTextChange}
                        />
                        <input
                            type="email"
                            className="contact-form-input-container"
                            value={userEmail}
                            placeholder="Email"
                            onChange={onEmailTextChange}
                        />


                        {/* <div>
                            <select
                                className="contact-form-input-container"
                                placeholder="Select a Category"
                                value={userSubject}
                                onChange={(e) => setUserSubject(e.target.value)}
                            >
                                <option
                                    value=""
                                    disabled
                                    style={{backgroundColor: "#090a2c"}}
                                >
                                    Subject
                                </option>
                                {options.map((option) => (
                                    <option
                                        key={option.id}
                                        value={option.name}
                                        style={{
                                            backgroundColor: "#090a2c",
                                            color: "white",
                                        }}
                                    >
                                        {option.name}
                                    </option>
                                ))}
                            </select>
                        </div> */}
                        <div className="custom-select-container" ref={dropdownRef}>
                            <div className="custom-select" onClick={toggleDropdown}>
                                {selectedOption || "Subject"}
                            </div>
                            {isOpen && (
                                <div className="custom-options-container">
                                    {options.map((option, index) => (
                                        <div
                                            key={index}
                                            className="custom-option"
                                            onClick={() => handleOptionClick(option.name)}
                                        >
                                            {option.name}
                                        </div>
                                    ))}
                                </div>
                            )}
                        </div>
                        <div>
                <textarea
                    className="contact-form-input-container"
                    placeholder="How can we help you?"
                    style={{height:'100px'}}
                    value={userMessage}
                    onChange={onMsgTextChange}
                />
                        </div>
                        <div className="im-not-robot-container">
                            <div className="capcha">
                                <div style={{background: 'transparent'}}>
                                    <ReCAPTCHA
                                        //  ref={recaptchaValue}
                                        sitekey="6LeujVIpAAAAACAQuaJV8rCdUcMH-VupSR8yVuec"
                                        onChange={handleCaptchaChange}
                                    />


                                </div>
                            </div>

                            
                        </div>
                        
                        <div className="capcha" style={{marginTop: "10px"}}>
                                {/*<InfoCircleOutlined style={{ fontSize: "18px", color: "#d40b0b !important" }} />*/}

                                <span>To refresh, click the captcha.</span>
                            </div>

                        <div className="form-group d-flex ">
                            <div className="mt-1">
                                <input type="checkbox" id="cb1"
                                       checked={isChecked}
                                       onChange={(e) => setIsChecked(e.target.checked)}
                                />
                                <label htmlFor="cb1" style={{width: '34px'}}></label>
                            </div>
                            <div className="pl-10">
                                <p style={{
                                    fontFamily: "'Poppins'",
                                    fontSize: "13px",
                                    fontWeight: "400",
                                    lineHeight: "22px",
                                    textAlign: "left",
                                    marginRight: "30px",
                                }}>
                                    By Submitting this form, I agree to Krypcore's Terms of Service, Privacy Policy and
                                    to be contacted using the info provided</p>
                            </div>
                        </div>
                        <button
                            className="btn form-submit-btn"
                            onClick={submit}
                            // disabled={!isFormValid()}
                        >
                            Submit
                        </button>
                    </Space>
                </Space>
            </div>
        </>
    );
};

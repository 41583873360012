import React from 'react';
const TruncateText = ({ text, maxLength })=> {
    if(text !== undefined) {
        if (text.length <= maxLength) {
            return <a>{text}</a>;
        } else {
            const truncatedText = text.slice(0, maxLength) + '...';
            return <a>{truncatedText}</a>;
        }
    }
}
export default TruncateText;
import React, {useEffect, useState} from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {Button,notification, Space, Spin,Modal} from "antd";
import {InfoCircleOutlined, LoadingOutlined} from "@ant-design/icons";
import CaptchaCode from 'react-captcha-code';
import {fetchApi} from "../../_config/api";
import { isAddress } from 'web3-validator';
import {useMetaMask} from "metamask-react";
import ReCAPTCHA from "react-google-recaptcha";

const FaucetTransactionPage = (props) => {
    const navigate = useNavigate();
    const location = useLocation();
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [link, setLink] = useState(false);
    const [lState,setLState] = useState(null);
    const handleOk = () => {
        setIsModalVisible(false);
        navigate("/faucet");
    };
    let currencySymbol = "", chainID = "", description = "", faucetID = "", maximumWalletBalance = "",
        protocolName = "";
    useEffect(() => {
        if (location.state != null) {
            setLState(location.state);
        }
        if(lState != null){
            currencySymbol = lState.data?.currencySymbol;
            chainID = lState.data?.chainID;
            description = lState.data?.description;
            faucetID = lState.data?.faucetID;
            maximumWalletBalance = lState.data?.maximumWalletBalance;
            protocolName = lState.data?.protocolName;
        }
    })

    const [captcha, setCaptcha] = useState("");
    const [captchaValue, setCaptchaValue] = useState('');
    const [walletAddress, setWalletAddress] = useState("");
    const [isValid, setIsValid] = useState(true);
    const [isValidCaptcha, setIsValidCaptcha] = useState(true);
    const [isDisabled, setIsDisabled] = useState(false);
    console.log(isDisabled)
    console.log(isValid)

    const handleCaptchaChange = (value) => {
        setCaptchaValue(value);
    };
    // const recaptchaRef = React.useRef();
    // const recaptchaValue =async()=>{
    //     const token = await recaptchaRef.current.executeAsync()
    //   setCaptcha(token)
    // }

    console.log(captchaValue)

    const onCapchaTextChange = (e) => {
        setCaptcha(e.target.value);
    };
    const onWalletAddressChange = (e) => {
        setWalletAddress(e.target.value);
    };
    const isFormValid = () => {
        return (
            walletAddress.trim() !== '' &&
            captcha.trim() !== ''
        );
    };
    const submit = () => {
        console.log(captchaValue)
        let formattedBalance;
        if (walletAddress === '') {
            notification.error({
                message: "Please enter Wallet Address",
                duration: 2,
            });
        } else {
            const isAddressValid = isAddress(walletAddress);
            if (!isAddressValid) {
                setIsValid(false);
                return;
            }else{
                setIsValid(true);
            }
            if (captchaValue ==="") {
                setIsValidCaptcha(false);
                return;
            }else{
                setIsValidCaptcha(true);
            }

            if (ethereum) {
                const walletAddressToCheck = walletAddress;
                ethereum
                    .request({
                        method: "eth_getBalance",
                        params: [walletAddressToCheck, "latest"],
                    })
                    .then((response) => {
                        const balanceInWei = parseInt(response, 16);
                        const balanceInEth = balanceInWei / 10 ** 18;
                        if (balanceInEth > maximumWalletBalance) {
                            notification.error({
                                message: "Balance is already greater than "+maximumWalletBalance.toString(),
                                duration: 5,
                            });
                            return;
                        }
                        setIsDisabled(true);
                        const accessBody = {
                            walletAddress: walletAddress,
                            protocolName: protocolName,
                            faucetID: faucetID,
                            chainID: chainID,
                        };
                        fetchApi({ method: "POST", url: `faucet/send-funds`, data: accessBody })
                            .then((resp) => {
                                console.log(resp)
                                setIsDisabled(false);
                                if (resp.statusCode === 200) {
                                    setCaptcha("");
                                    setWalletAddress("");
                                    // notification.success({
                                    //     message: resp?.message,
                                    //     duration: 5,
                                    // });
                                    // navigate("/faucet");
                                    setIsModalVisible(true);
                                    setLink(resp.doc.url);
                                } else {
                                    notification.error({
                                        message: resp?.message,
                                        duration: 5,
                                    });
                                }
                            })
                            .catch((error) => {
                                setIsDisabled(false);
                                notification.destroy();
                                notification.error({
                                    message: error.toString(),
                                    duration: 5,
                                });
                            });
                    })
                    .catch((error) => {
                        console.error("Error getting balance:", error);
                        notification.error({
                            message: "Error retrieving balance",
                            duration: 5,
                        });
                    });
            } else {
                setIsValidCaptcha(false);
                notification.error({
                    message: "MetaMask is not installed or not accessible.",
                    duration: 5,
                });
            }
        }
    };

    const { status, account, ethereum, connect } = useMetaMask();
    const [balance, setBalance] = React.useState(null);

    const handleConnect = async () => {
        try {
            await connect();
        } catch (error) {
            console.error("Error connecting to MetaMask:", error);
        }
    };

    React.useEffect(() => {
        if (status === "connected" && ethereum) {
            ethereum
                .request({
                    method: "eth_getBalance",
                    params: [account, "latest"],
                })
                .then((response) => {
                    const balanceInWei = parseInt(response, 16);
                    const balanceInEth = balanceInWei / 10 ** 18;
                    // Format balance to have 4 decimal places
                    const formattedBalance = balanceInEth.toLocaleString(undefined, {
                        minimumFractionDigits: 4,
                        maximumFractionDigits: 4,
                    });
                    setWalletAddress(account);
                    setBalance(formattedBalance);
                })
                .catch((error) => {
                    console.error("Error getting balance:", error);
                });
        }
    }, [status, account, ethereum]);

   // const isMetamaskAvailable = Boolean(window.ethereum);

    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <div className="faucet-page-container">
            <div className="faucet-heading d-block">
                {/*<h2>
                    {protocolName}
                </h2><br/>*/}
                <div className="theme-heading text-center d-block" style={{marginTop:'40px'}}>
                    <h3  style={{color:'white !important'}}>{protocolName}</h3><br/>
                    <p class="subheader1 text-center" >Get your testnet tokens to test your dApp</p>
                </div>
                {/* <h1 class="header-two text-center">{protocolName}</h1>
                <p class="subheader text-center">Get your testnet tokens to test your dApp</p> */}
            </div>
            <div className="faucet-transaction-card-container">
                <div  className="faucet-transaction-card">
                    <div style={{margin:"20px",width:'100%'}}>
                        <Space direction="vertical" size={27} style={{ display: "flex" }}>
                            <div style={{ display: "flex",flexDirection:'column' }}>
                              <span className="faucet-transaction-card-header">Send {currencySymbol} to Your Wallet</span>
                              <label className="faucet-transaction-card-label1">{description}</label>
                            </div>


                            <div className="wallet-input-container">
                            <label className="faucet-transaction-card-label">Enter your wallet address</label>
                            <input
                                className="faucet-transaction-input-container"
                                value={walletAddress}
                                placeholder="Enter Your Wallet Address (0x...)"
                                onChange={onWalletAddressChange}
                            />
                                {/*<MetaMaskWalletInfo />*/}
                                {/* {isMetamaskAvailable && status !== "connected" && <div>
                                    <button
                                        className="connect-wallet-gradient-button"
                                        onClick={handleConnect}
                                        disabled={!isMetamaskAvailable}
                                    >
                                        Connect Wallet
                                    </button>
                                </div>} */}
                                {/*{!isMetamaskAvailable && (
                                    <div className="warning-content ">MetaMask is not available. Please install it.</div>
                                )}
                                {status === "connected" &&
                                    <div>
                                        <label className="faucet-transaction-card-label">Balance: {balance !== null ? `${balance}` : "Loading..."}</label>
                                    </div>
                                }*/}
                            </div>
                            {isValid ?
                                null : (
                                    <div style={{ color: 'red',marginTop: '-20px' }}>Invalid wallet address</div>
                                )}
                                {/* <input
                                style={{width:"200px"}}
                                className="faucet-transaction-input-container"
                                type="captcha"
                                value={captcha}
                                placeholder="Enter Captcha here"
                                onChange={onCapchaTextChange}
                            /> */}

                            <div className="faucet-transaction-capcha">
                                <div style={{ background: 'transparent',marginTop:'10px'}}>
                                    {/* <CaptchaCode
                                        charNum={4}
                                        style={{ fontFamily: 'Arial', fontSize: '30px', fontWeight: 'bold', color: 'blue' }}
                                        width={200} height={60}
                                        onChange={handleCaptchaChange}
                                    /> */}
                                     <label className="faucet-transaction-card-label">Verify the reCaptcha below</label>
                                    <ReCAPTCHA
                                    //  ref={recaptchaValue}
                                        sitekey="6LeujVIpAAAAACAQuaJV8rCdUcMH-VupSR8yVuec"
                                        onChange={handleCaptchaChange}
                                    />
                                    {isValidCaptcha ?
                                null : (
                                    <div style={{ color: 'red',marginTop:'10px',lineHeight:0 }}>Incorrect Captcha</div>
                                )}

                                </div>
                            </div>
                            <div className="faucet-instruction">
                            <div className="faucet-transaction-capcha">
                                <InfoCircleOutlined style={{ fontSize: "18px", color: "#d40b0b !important"}} />
                                <span>Please enter the correct captcha to submit the form.</span>
                            </div>
                            <div className="faucet-transaction-capcha">
                                <InfoCircleOutlined style={{ fontSize: "18px", color: "#d40b0b !important" }} />
                                <span>To refresh, click the captcha.</span>
                            </div>

                            </div>

                            <button
                                className="gradient-button"
                                onClick={submit}
                                // disabled={!isFormValid() || isDisabled}
                            >
                                {!isDisabled ? "Send" :  <><Spin indicator={antIcon} /> <span style={{marginLeft:"10px"}}>Dispensing</span></>}
                            </button>
                        </Space>
                    </div>
                </div>
            </div>
            <Modal className="faucet-custom-modal"  title="" footer={null} open={isModalVisible} closable={false}>
                <div className="faucet-popup-container">
                    {/*<div className="close-icon" onClick={handleOk}>*/}
                    {/*    <Menus type="close" />*/}
                    {/*</div>*/}
                    <div className="popup-title">
                        Funds Dispensed Successfully
                    </div>
                    <div className="popup-msg">
                        <a style={{textDecoration:"underline",color:"blue"}} href={link} target="_blank"  rel="noopener noreferrer" >Click here</a> to view your transaction details
                    </div>
                    <div className="btn-section">
                        <button className="ok-btn" onClick={handleOk}>
                            OK
                        </button>
                    </div>
                </div>
            </Modal>
        </div>
    );
}
export default FaucetTransactionPage

import React, {useEffect, useState} from 'react';
import '../style.less';
import {gaslessFundedTxColumns, weiToEther} from "../utils/columns";
import {Filters, PaginationSection, TableContent} from "../../../library";
import {useLocation, useNavigate} from "react-router-dom";
import {apiCaller} from "../utils/utils";
import {GASLESS_URLS} from "../utils/constants";
import {getUserDetails} from "../../../deps";
import {Spin} from "antd";

export const ManageContractPage = ({ showLoader }) => {
    const userDetails = getUserDetails()
    const location = useLocation();
    const navigate = useNavigate();
    const [fundedTransactions,setFundedTransactionList]= useState([])
    const [whitelistedContractDetails, setWhitelistedContractDetails] = useState(location.state)
    const [protocolAndGasConf,setProtocolAndGasConf]= useState({})
    const [totalCount,setTotalCount]= useState([])
    const [gasLimit, setGasLimit] = React.useState(0);
    const [maxAllowedGasLimit, setMaxAllowedGasLimit] = React.useState(10);
    const [showLoader1, setLoader] = useState(false);
    const [showLoader2, setLoader2] = useState(false);

    const [pageSetting, setSetting] = useState({
        page: 1,
        limit: 5,
        searchValue: location.state?.contractAddress,
        searchIn:["linkedContractAddress"],
        filterValue: "",
        filterIn:[],
        userId: userDetails.profile.developerId
    });



    const last =
        pageSetting.page * pageSetting.limit > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.limit;

    const startIndex = (pageSetting.page - 1) * pageSetting.limit;

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
    };

    const listAllFundedTransactions =  async () => {
        let resp = await apiCaller("POST", GASLESS_URLS.LIST_ALL_FUNDED_TRANSACTIONS , pageSetting, false)
        setFundedTransactionList( resp.data.data)
        setTotalCount(resp.data.total)
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const formData =  {
            gasLimitConfigured: Number(gasLimit), // Convert gasLimit to a number
            contractAddress: whitelistedContractDetails.contractAddress,
            dAppId: whitelistedContractDetails.linkedDAppId,
            chainId: whitelistedContractDetails.chainId
        }
        let resp = await apiCaller('POST', GASLESS_URLS.UPDATE_GAS_CONFIGURED_LIMIT, formData, true);
        if (resp.status) {
            setWhitelistedContractDetails(resp.data)
        }
        setGasLimit(0)
        setLoader(false);
    }

    const getProtocolGasConfig =  async () => {
        let resp = await apiCaller("POST", GASLESS_URLS.GET_PROTOCOL_CONF_BY_CHAIN_AND_USER , { userId:userDetails.profile.developerId, chainId:whitelistedContractDetails.chainId } , false)
        if (resp.status == true){
            setProtocolAndGasConf(resp.data)
            setMaxAllowedGasLimit(weiToEther(resp.data.gasLimit - resp.data.gasUsed))
        }

    }
    const deactivatedWhitelistedContract =  async () => {
        setLoader2(true);
        let reqBody={
            contractAddress: whitelistedContractDetails.contractAddress,
            dAppId: whitelistedContractDetails.linkedDAppId
        }
        let resp = await apiCaller("POST", GASLESS_URLS.DEACTIVATE_WHITELISTED_CONTRACT , reqBody, true)
        setLoader2(false);
        if (resp.status == true){
            navigate(-1)
        }
    }

    useEffect(()=>{
        listAllFundedTransactions().then()
        getProtocolGasConfig().then()
    },[])


    return (
        <div className="gasless-manager-container">
            <div className="header-text">{`Manage Whitelisted Contract`}</div>
            <br/>
            <div className={"gasless-form"}>
                <p className="td-text">Contract Name : {whitelistedContractDetails?.contractName}</p>
                <p className="td-text">Contract Address : {whitelistedContractDetails?.contractAddress}</p>
                <p className="td-text">Linked dApp : {whitelistedContractDetails?.linkedDAppName}</p>
                <p className="td-text">Network : {whitelistedContractDetails?.network}</p>
                <p className="td-text">Chain ID : {whitelistedContractDetails?.chainId}</p>
                <p className="td-text">Gas Used : {weiToEther(whitelistedContractDetails?.gasUsed)}</p>
                <p className="td-text">Number of Gasless Transactions Recorded : {whitelistedContractDetails?.numberOfTransactions}</p>
            </div>

            <div className="gasless-header-section marginTop10px">
                <div className="header-text">{`Transaction History`}</div>
                <div className="gasless-filter">
                    <Filters
                        showCreateBtn={false}
                        showFilters={false}

                    />
                </div>
            </div>

            <div  className="table-container marginBtm50">
                <TableContent
                    rowKey="projectId"
                    data={fundedTransactions}
                    columns={gaslessFundedTxColumns}
                    isDashboard={false}
                    pagination={false}
                />
                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={[5, 10, 50]}
                    totalCount={totalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.limit}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />
            </div>

            <div className="gasless-form">
                <div className="header-text">{`Edit Gas Limit`}</div>
                <p className="td-text">Gas Limit : {weiToEther(whitelistedContractDetails?.gasLimitConfigured)}</p>
                <form className={'marginBtm30'} onSubmit={handleSubmit}>
                        <div style={{display:"flex", flexDirection:"row", justifyContent:"space-between"}}>
                            <label className="td-text" style={{ wordBreak: 'break-word' }}>
                                Based on the expected number of transactions on your dApp, enter your required gas limit (ether)
                            </label>
                                <input
                                    style={{width:"20%"}}
                                    type={'number'}
                                    className="input-container"
                                    placeholder="Gas Limit ( ether )"
                                    name="requestedGasLimit"
                                    value={gasLimit}
                                    onChange={(e) => setGasLimit(e.target.value)}
                                    required={true}
                                    max={maxAllowedGasLimit}
                                />
                        </div>
                    {gasLimit > maxAllowedGasLimit && (
                        <div className="error-message">Gas limit cannot exceed the maximum allowed.</div>
                    )}
                    <button
                        style={{ width: "15%" }}
                        type="submit"
                        className="gradient-button"
                        disabled={gasLimit > protocolAndGasConf.gasLimit} // Disable the button if gasLimit is greater than protocolAndGasConf.gasLimit
                    >
                        <Spin spinning={showLoader1}>
                            Set Gas Limit
                        </Spin>

                    </button>

                </form>

                <div>
                    <label className="header-text">{`Remove From Whitelist`}</label>
                    <p className="td-text" style={{ fontStyle: 'italic' }}>
                        This action will remove your contract from whitelisted contracts, it will no longer be allowed for gasless transactions
                    </p>

                    <button style={{width:"15%"}}  className="gradient-button" onClick={deactivatedWhitelistedContract}>
                        <Spin spinning={showLoader2}>
                            Confirm Removal
                        </Spin>
                    </button>
                </div>
            </div>

       </div>
    );
};

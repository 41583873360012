// Essentials
import React, { useState } from "react"; //useEffect, useContext
// Style
import "./styles.less";
// Components
import { fetchApi, fetchApiGw } from "../../_config/api";
import { useParams } from "react-router-dom"; //useLocation
import { Button, Form, notification, Popconfirm, Row, Spin, Tooltip } from "antd";
import { erc20, erc721, erc1155 } from "@dinesh-kd/wizard";
import Highlight from "react-highlight";
import { Input, Col } from "antd"; //Checkbox, Radio, Space
import { Buffer } from "buffer";
import ERC20 from "./contracts/erc20";
import ERC721 from "./contracts/erc721";
import ERC1155 from "./contracts/erc1155";
import { useNavigate } from "react-router-dom";
import { CopyOutlined, LoadingOutlined } from "@ant-design/icons";
import { getUserDetails } from "../../deps";
import { getPATTokenDetails } from "../../deps";
import TruncateText from "../../library/TrunucateText/truncate";
import Skeleton from "react-loading-skeleton";


const STANDARD = {
  ERC20: "ERC20",
  ERC721: "ERC721",
  ERC1155: "ERC1155",
}

const CUSTODIAL_WALLET = "custodial";
const NON_CUSTODIAL_WALLET = "non-custodial";
const DEVELOPER_WALLET = "developer";

export const onCopy = (apiKey) => {
  notification.destroy();
  navigator.clipboard.writeText(apiKey);
  notification.success({
    message: "Copied to clipboard",
    duration: 3,
  });
};

export const CreateSmartContract = ({ showLoader,isLoading }) => {
  const gwURL = process.env.REACT_APP_GATEWAY_URL;
  // const walletURL = process.env.REACT_APP_WALLET_GATEWAY_URL;
  const walletURL = gwURL + "/api/v0/wallet";
  const params = useParams();
  const navigate = useNavigate();
  const {
    profile: { userRole, subscriptionId },
  } = getUserDetails();
  const token = getPATTokenDetails()
  const authToken = token.patToken; // subscriptionId + "_" + 
  const [isDeploying, setIsDeploying] = useState(false);
  const [contract, setContract] = useState(erc20.print());
  const [contactType, setContactType] = useState(STANDARD.ERC20);
  const [data, setData] = useState({});
  const [custodialWallet, setCustodialWallet] = useState({});
  const [nonCustodialWallet, setNonCustodialWallet] = useState({});
  const [developerInstance, setDeveloperInstance] = useState(null);
  const [instanceList, setInstanceList] = useState([]);
  const [instance, setInstance] = useState(null);
  const [canSubmit, setCanSubmit] = React.useState(false);
  const [confirmationMessage, setConfirmationMessage] = React.useState("");
  const [confirmLoading, setConfirmLoading] = useState(false);


  const handleChange = (cont) => {
    setContract(cont.contract);
    setData(cont);
    console.log("contract::", cont);
  };

  React.useEffect(() => {
    if (data.walletType === CUSTODIAL_WALLET) {
      if (data.custodialWallet) {
        getWallet(data.custodialWallet)
      }
    }
    if (data.walletType === NON_CUSTODIAL_WALLET) {
      const { projectDetails: { services: { NonCustodialWallet } } } = instance;
      setNonCustodialWallet({ instanceID: NonCustodialWallet?.InstanceID, walletId: data.custodialWallet, walletAddress: "" })
    }
    if (data.walletType === DEVELOPER_WALLET) {
      const {
        projectDetails: {
          services: {
            DeveloperWallet: {
              InstanceID: DevWalInstanceId
            },
            NonCustodialWallet: {
              InstanceID: NonCusWalInstanceId
            }
          }
        }
      } = instance;
      console.log("DevWalInstanceId::", DevWalInstanceId);
      console.log("NonCusWalInstanceId::", NonCusWalInstanceId);
      setDeveloperInstance(DevWalInstanceId)
    }
  }, [data.walletType, data.custodialWallet]);

  React.useEffect(() => {
    if (params.contract === STANDARD.ERC20) {
      setContract(erc20.print());
    } else if (params.contract === STANDARD.ERC721) {
      setContract(erc721.print());
    } else if (params.contract === STANDARD.ERC1155) {
      setContract(erc1155.print());
    }
  }, [contactType]);

  React.useEffect(() => {

    getAllInstance();

  }, [params.id]);

  const cancel = () => {
    setCanSubmit(false);
  };

  const fetchGasFee = async () => {
    if (data.name === "" || data.name === undefined) {
      notification.error({
        message: "Name is mandatory",
        duration: 2,
      });
      return false;
    }

    if (data.chain === "" || data.chain === undefined) {
      notification.error({
        message: "please select chain to deploy",
        duration: 2,
      });
      return false;
    }

    if (data.Access === "" || data.Access === undefined) {
      data.Access = "ownable"
    }

    console.log("data.walletType: ", data.walletType)

    if(data.walletType === undefined) {
      notification.error({
        message: "please select wallet type to deploy",
        duration: 2,
      });
      return false;
    }

    if(params.contract === STANDARD.ERC1155) {
      if(data.uri === "" || data.uri === undefined) {
        notification.error({
          message: "Base URI is mandatory",
          duration: 2,
        });
        return false;
      }
    }

    let serviceApiKey = custodialWallet.serviceApiKey;
    let walletInstanceId = custodialWallet.instanceId;
    // let url = "/api/v0/wallet/estimateGas";
    if (data.walletType === NON_CUSTODIAL_WALLET) {
      serviceApiKey = nonCustodialWallet.walletId;
      walletInstanceId = nonCustodialWallet.instanceID;
      // url = "/api/v0/ncWallet/estimateGas";
    }

    if (data.walletType === DEVELOPER_WALLET) {
      serviceApiKey = "";
      walletInstanceId = developerInstance;
    }

    console.log("serviceApiKey: ", serviceApiKey)
    console.log("walletInstanceId: ", walletInstanceId)

    console.log("data.privateKey: ", data.privateKey)
    if(data.walletType === NON_CUSTODIAL_WALLET && (serviceApiKey === undefined || walletInstanceId === undefined)) {
      notification.error({
        message: "please choose wallet to deploy",
        duration: 2,
      });
      return false;
    }

    console.log("data.privateKey: ", data.privateKey)
    if(data.walletType === DEVELOPER_WALLET && data.privateKey === undefined) {
      notification.error({
        message: "please enter the private key",
        duration: 2,
      });
      return false;
    }

    console.log("data.privateKey: ", data.privateKey)
    if(data.walletType === DEVELOPER_WALLET && walletInstanceId === "") {
      notification.error({
        message: "Developer wallet instance missing",
        duration: 2,
      });
      return false;
    }

    setIsDeploying(true);
    setConfirmLoading(true);

    console.log("Data: ", data)
    let newPrivateKey = data.privateKey;
    if(newPrivateKey !== undefined && newPrivateKey !== null && !newPrivateKey.startsWith('0x')){
      newPrivateKey = '0x'+newPrivateKey;
    }
    const payload = {
      name: data.name,
      mode: "api",
      serviceAPIKey: serviceApiKey,
      chainId: data.chain,
      gas: 0,
      value: 0,
      method: "",
      // params: [{type:"string",value:data.name},{type: "string",value: data.symbol}],
      isContractTxn: true,
      contractABI: "",
      byteCode: "",
      contractCodeAsBase64: Buffer.from(contract).toString("base64").replace(/=*$/, ""),
      walletType: data.walletType,
      walletInstance: walletInstanceId,
      privateKey: newPrivateKey
    };
    const options = {
      method: "POST",
      url: `smart-contract-studio/estimateGas`,
      data: payload,
      isDevApp: false,
    }
    // console.log("payload: ", payload);
    // return false;
    fetchApi(options)
      .then((response) => {
        if (response.statusCode === 200 && response.doc !== null) {
          const { doc = {} } = response;
          setConfirmationMessage("Do you want to proceed with this gas fee " + doc.estimatedGas + " Gwei ?");
          setCanSubmit(true);
        } else {
          notification.error({
            message: response.message,
            duration: 3,
          });
          setCanSubmit(false);
        }
        setIsDeploying(false);
      })
      .catch((error) => {
        // console.error("ee",error);
        setIsDeploying(false);
        setCanSubmit(false);
        notification.error({
          message: error.toString(),
          duration: 3,
        });
      }).finally((error) => {
        setConfirmLoading(false);
      });
  };

  const deploy = () => {
    if (data.name === "" || data.name === undefined) {
      notification.error({
        message: "Name is mandatory",
        duration: 2,
      });
      return false;
    }

    if (data.chain === "" || data.chain === undefined) {
      notification.error({
        message: "please select chain to deploy",
        duration: 2,
      });
      return false;
    }

    if (data.Access === "" || data.Access === undefined) {
      data.Access = "ownable"
    }


    console.log("custodialWallet::", data);

    let serviceApiKey = custodialWallet.serviceApiKey;
    let walletInstanceId = custodialWallet.instanceId;
    let walletAddress = custodialWallet.address;
    if (data.walletType === NON_CUSTODIAL_WALLET) {
      serviceApiKey = nonCustodialWallet.walletId;
      walletInstanceId = nonCustodialWallet.instanceID;
      walletAddress = nonCustodialWallet.walletAddress;
    }

    if (data.walletType === DEVELOPER_WALLET) {
      serviceApiKey = "";
      walletInstanceId = developerInstance;
    }
    let newPrivateKey = data.privateKey;
    if(newPrivateKey !== undefined && newPrivateKey !== null && !newPrivateKey.startsWith('0x')){
      newPrivateKey = '0x'+newPrivateKey;
    }
    const accessBody = {
      projectID: instance?.ProjectId,
      serviceInstance: instance?.instanceKey,
      subId: subscriptionId,
      name: data.name,
      ercStandard: params.contract,
      walletType: data.walletType,
      custodialWalletAccessToken: serviceApiKey,
      walletInstance: walletInstanceId,
      walletAddress: walletAddress,
      chainId: data.chain,
      privateKey: newPrivateKey,
      contractCodeAsBase64: Buffer.from(contract)
        .toString("base64")
        .replace(/=*$/, ""),
    };

    setIsDeploying(true);
    setConfirmLoading(true);
    const options = {
      method: "POST",
      url: `smart-contract-studio/contracts`,
      data: accessBody,
      isDevApp: false,
    }


    fetchApi(options)
      .then((response) => {
        if (response.statusCode === 200 || response.statusCode === 201) {
          notification.success({
            message: "contract successfully Created",
            duration: 2,
          });
          setIsDeploying(false);
          setConfirmLoading(false);
          navigate(-2);
        } else {
          notification.error({
            message: response.message,
            duration: 2,
          });
          setIsDeploying(false)
          setConfirmLoading(false);
        }
      })
      .catch(error => {
        // console.log("error::", error);
        notification.error({
          message: error.message,
          duration: 2,
        });
        setIsDeploying(false)
        setConfirmLoading(false);
      });
  };

  const antIcon = (
    <LoadingOutlined
      style={{
        fontSize: 24,
      }}
      spin
    />
  );

  const getAllInstance = () => {
    showLoader(true);
    const options = {
      method: "POST",
      headers: {
        'Content-Type': 'application/json',
        'Authorization': authToken
      },
      body: JSON.stringify({ "services": ["smart-contract-studio"] })
    };

    fetch(`${gwURL}` + "/api/v0/instance/getInstance", options)
      .then(response => response.json())
      .then((response) => {
        showLoader(false);
        if (response.Status === "SUCCESS") {
          const { Data = [] } = response
          if (response.Data) {
            setInstanceList([...Data]);

            const instanceObj = Data.find(e => e.instanceId === params?.instanceId);
            if (instanceObj && !instanceObj?.instanceId) {
              notification.error({
                message: "instanceId not found",
                duration: 3,
              });
            }
            setInstance(instanceObj);
          } else {
            setInstanceList([]);
          }
        }
      })
      .catch((response) => {
        setInstanceList([]);
        showLoader(false);
      });
  };

  const getWallet = async (wallet) => {

    if (instance) {

      // console.log("instance::", instance);

      const { projectDetails: { services: { CustodialWallet } } } = instance;

      const { walletName } = JSON.parse(wallet);
      // console.log("wallet::", wallet);
      // console.log("walletName::", walletName);

      const payload = {
        mode: "creds",
        name: walletName
      };
      // console.log("payload::", payload);

      const options = {
        method: "POST",
        mode: "cors",
        body: JSON.stringify(payload),
        headers: {
          'Content-Type': 'application/json',
          'Authorization': authToken,
          'Instanceid': CustodialWallet?.InstanceID
        },
      };

      fetch(`${walletURL}/getWallet`, options)
        .then(response => response.json())
        .then((response) => {
          // showLoader(false);
          if (response.Status === "SUCCESS") {
            const { Data = [] } = response;
            // console.log("Data::", Data);
            setCustodialWallet(Data)
          }
        })
        .catch((response) => {
          // showLoader(false);
        });

    }
  };

  return (
    <>
      <div className="studio-header nft-collection">
        <div>
        {isLoading?(<Skeleton height={25} width={200} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <h4 className="text">{`Deploy Contract`}</h4>
        )}
        {isLoading?(<Skeleton height={20} width={200} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <Tooltip title={instance?.projectDetails?.projectName}><p>dApp Name: <TruncateText text={instance?.projectDetails?.projectName} maxLength={15} /></p></Tooltip>
        )}
        {isLoading?(<Skeleton height={20} width={250} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
          <p>
            {`Instance ID: ${instance?.instanceId}`}
            <CopyOutlined style={{ zIndex: 1, position: "relative", left: "5px" }}
              onClick={() => {
                notification.success({ message: "Copied to clipboard", duration: 2 });
                navigator.clipboard.writeText(instance?.instanceId);
              }}
            />
          </p>
        )}
        </div>
      </div>
      <br /> <br />
      <div className="smartcontract">
        <ul className="contract-menu">
          {params.contract === STANDARD.ERC20 && (
            <li>
              {isLoading?(<Skeleton height={40} width={110} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
              <button style={{ cursor: "auto" }}
                className={`contract active`}
              >
                {STANDARD.ERC20}
              </button>
              )}
            </li>
          )}

          {params.contract === STANDARD.ERC721 && (
            <li>
              {isLoading?(<Skeleton height={40} width={110} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
              <button style={{ cursor: "auto" }}
                className={`contract active`}

              >
                {STANDARD.ERC721}
              </button>
              )}
            </li>
          )}

          {params.contract === STANDARD.ERC1155 && (
            <li>
              {isLoading?(<Skeleton height={40} width={110} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
              <button style={{ cursor: "auto" }}
                className={`contract active`}
              >
                {STANDARD.ERC1155}
              </button>
              )}
            </li>
          )}
        </ul>

        <Row>
          <Col span={12}>
            {params.contract === STANDARD.ERC20 && (
              <ERC20 isLoading={isLoading} instance={instance} onChangeEvent={handleChange}></ERC20>
            )}
            {params.contract === STANDARD.ERC721 && (
              <ERC721 isLoading={isLoading} instance={instance} onChangeEvent={handleChange}></ERC721>
            )}
            {params.contract === STANDARD.ERC1155 && (
              <ERC1155 isLoading={isLoading} instance={instance} onChangeEvent={handleChange}></ERC1155>
            )}

            <Form.Item>
              <Popconfirm
                placement='top'
                title={confirmationMessage}
                onConfirm={(e) => {
                  setIsDeploying(true);
                  deploy()
                      .finally(() => setIsDeploying(false));
                }}
                okButtonProps={{
                  loading: confirmLoading,
                }}
                okText='Proceed'
                cancelText='Cancel'
                open={canSubmit}
                onCancel={cancel}
              >
                {isLoading?(<Skeleton height={42} width={180} style={{marginTop:'22px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <button disabled={isDeploying}
                  className="contractbtn mt-3"
                  style={{ color: '#fff', padding: '0px 8px' }}
                  type='button'
                  onClick={fetchGasFee}
                >
                  {isDeploying ? <Spin indicator={antIcon} /> : "Deploy"}
                </button>
                )}
              </Popconfirm>
            </Form.Item>

          </Col>
          <Col span={12}>
            {contactType && (<>
              {isLoading?(<Skeleton height={250} style={{marginTop:'22px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
              <>
                <div className="copy-alignment"><CopyOutlined onClick={() => onCopy(contract)} /></div>
                <Highlight style={{ marginTop: "20%" }} className="python">{contract}</Highlight>
              </>)}
              </> )}
            
          </Col>
        </Row>
      </div>
    </>
  );
};

export default CreateSmartContract;

import React from "react";

export const Stellar = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_903_3210)">
      <path
        d="M61.9459 122.811C95.8601 122.811 123.351 95.3196 123.351 61.4054C123.351 27.4912 95.8601 0 61.9459 0C28.0317 0 0.540527 27.4912 0.540527 61.4054C0.540527 95.3196 28.0317 122.811 61.9459 122.811Z"
        fill="white"
      />
      <path
        d="M60.9773 26.8781C56.421 26.7511 51.8854 27.5388 47.6388 29.1948C43.3922 30.8508 39.5206 33.3414 36.2531 36.5194C32.9856 39.6974 30.3884 43.4983 28.6151 47.6973C26.8418 51.8963 25.9283 56.4082 25.9287 60.9663C25.9287 61.836 25.9623 62.6991 26.0295 63.5621C26.1215 64.7824 25.8505 66.0027 25.2506 67.0693C24.6507 68.1359 23.7486 69.0012 22.6579 69.5563L19.7297 71.0507V78.0154L28.3533 73.6196L31.1472 72.1958L33.9009 70.7955L83.2987 45.6231L88.8496 42.7955L100.324 36.9491V29.9844L88.9504 35.7872L79.2454 40.7337L32.383 64.6031C32.2257 63.3938 32.1472 62.1756 32.148 60.9562C32.1544 56.0929 33.4312 51.3158 35.852 47.0978C38.2727 42.8798 41.7534 39.3674 45.9492 36.9085C50.145 34.4496 54.9104 33.1294 59.7734 33.0788C64.6364 33.0282 69.4282 34.2489 73.6743 36.62L79.2286 33.7891L80.0581 33.366C74.4968 29.3302 67.846 27.0688 60.9773 26.8781V26.8781ZM100.324 43.9172L36.7049 76.3095L31.154 79.1437L19.7297 84.97V91.928L31.0734 86.1454L40.7783 81.1989L87.6911 57.2992C87.8484 58.5152 87.9269 59.7401 87.9261 60.9663C87.923 65.8351 86.6466 70.6184 84.2235 74.8414C81.8004 79.0644 78.315 82.5803 74.1132 85.04C69.9115 87.4997 65.1394 88.8177 60.2708 88.8631C55.4023 88.9086 50.6065 87.6799 46.3595 85.2991L46.0203 85.4805L39.9992 88.5498C45.0865 92.2427 51.0957 94.4574 57.3628 94.9492C63.6299 95.441 69.911 94.1907 75.512 91.3365C81.1131 88.4823 85.8161 84.1353 89.1015 78.7757C92.3868 73.4161 94.1266 67.2526 94.1286 60.9663C94.1286 60.0932 94.095 59.2133 94.0278 58.3469C93.9359 57.1271 94.2066 55.9072 94.8059 54.8407C95.4052 53.7742 96.3063 52.9086 97.396 52.3527L100.324 50.8584V43.9172Z"
        fill="black"
      />
    </g>
    <defs>
      <clipPath id="clip0_903_3210">
        <rect
          width="122.811"
          height="122.811"
          fill="white"
          transform="translate(0.540527)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default Stellar;

import React from 'react'
import DownIcon1 from "./DownIcon.avif"

const DownIcon = () => {
  return (
    <div className="ml-5">
   <img src={DownIcon1} style={{height:"16px",width:"16px"}}/>
  </div>
  )
}

export default DownIcon
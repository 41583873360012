import React, {useEffect, useState} from 'react'
import {Modal, Space, Input, Form, Dropdown, Menu, notification} from "antd";
import { Menus } from '../../../assets';
import { UserOutlined } from '@ant-design/icons';
import { Button } from 'antd';
import fetchApi from "../../../_config/api";



const AddMember = ({ isOpen, toggleState,teamId,reload }) => {
    const [roles, setRoles] = useState([]);
    const formRef = React.useRef(null);
    const [developers, setDevelopers] = useState(
        {
            email:"",
            developerId: "",
            roleId: "",
        });
    useEffect(() => {
        getRoles()
    }, []);
    const  getRoles = () => {
        return fetchApi({
            method: "GET",
            url: `teams/getDeveloperRoles`,
            isDevApp: false,
        }).then((response) => {
            if (response.statusCode === 200 || response.statusCode === 201) {
                let role = response.doc.map((role) => ({
                    label: `${role.name}`,
                    value: role.roleId,
                }));
                setRoles([...role])
            }
        })
    }
    const addTeamMember = () => {
        // showLoader(true);
        let formData = {
            ...formRef.current?.getFieldsValue(),
        };
        // const data = {};
        formData.teamId = teamId;
        // data.developers.email = formData.email;
        // data.developers.roleId = formData.roleId;
        fetchApi({
            method: "POST",
            url: `teams/add-team-member`,
            data: formData,
            isDevApp: false,
        })
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    // showLoader(false);
                    notification.success({
                        message: "Team member added successfully",
                        duration: 3,
                    });
                    // reload();
                    toggleState(); // Close the popup after successful addition
                    formRef.current?.resetFields(); // Clear the form fields
                    reload();
                }else{
                    // showLoader(false);
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });
                }
                // toggleAddMember();
            })
            .catch((err) => {
                // showLoader(false);
            });
    }
    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup endpoint-popup">

                    <div className="close-icon" onClick={() => {
                        toggleState();
                        formRef.current?.resetFields();
                    }}>
                        <Menus type="close"/>
                    </div>

                    <div className="popup-text d-inline">
                        <p className="popup-text-endpoint">Add Member</p>
                    </div>
                    <Space
                        direction="vertical"
                        size={25}
                    >
                        <Form
                            name="basic"
                            ref={formRef}
                            // initialValues={{
                            //     remember: true,
                            // }}
                            onFinish={addTeamMember}
                            autoComplete="off"
                        >
                            <div className='create-dapp-form-row'>

                                <div className="Add-member-container">

                                    <div className="Add-member-container-one">

                                        <div>

                                            <Form.Item
                                                className="MemberName"
                                                name="email"
                                                rules={[
                                                    {
                                                        // required: isEnterpriseAdmin,
                                                        message: 'Missing Email',
                                                    },
                                                ]}
                                            >
                                                <div className="input-wrapper">
                                                    <label htmlFor="teamName" className="input-label">
                                                        <p>Email</p>
                                                    </label>

                                                    <input
                                                        id="email"
                                                        placeholder="Enter email"
                                                        className="add-member-input-field"
                                                    />
                                                </div>
                                            </Form.Item>
                                        </div>


                                        <Form.Item
                                            className="MemberRole"
                                            name="roleId"
                                            rules={[
                                                {
                                                    // required: isEnterpriseAdmin,
                                                    message: 'Select Role',
                                                },
                                            ]}
                                        >
                                            <div className="input-wrapper">
                                                <label htmlFor="teamName" className="input-label">
                                                    <p>Role</p>
                                                </label>
                                                <select id="teamName" className="add-member-input-field" name="roleId">
                                                    <option value="">Select Role</option>
                                                    {roles &&
                                                        roles?.map((item, index) => (
                                                            <option key={index} value={item.value}>
                                                                {item.label}
                                                            </option>
                                                        ))}
                                                </select>
                                            </div>
                                        </Form.Item>




                                    </div>

                                    {/*<div className="Add-member-container-two">*/}

                                    {/*    <Form.Item*/}
                                    {/*        className="EmailInvite"*/}
                                    {/*        name="email"*/}
                                    {/*        rules={[*/}
                                    {/*            {*/}
                                    {/*                // required: isEnterpriseAdmin,*/}
                                    {/*                message: 'Missing Team Name',*/}
                                    {/*            },*/}
                                    {/*        ]}*/}
                                    {/*    >*/}
                                    {/*        <div className="input-wrapper">*/}
                                    {/*            <label htmlFor="teamName" className="input-label">*/}
                                    {/*                <p>Invite</p>*/}
                                    {/*            </label>*/}
                                    {/*            <input*/}
                                    {/*                id="teamName"*/}
                                    {/*                placeholder="Mail"*/}
                                    {/*                className="add-member-input-field"*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </Form.Item>*/}



                                    {/*    <Form.Item*/}
                                    {/*        className="CreditLimit"*/}
                                    {/*        name="credit"*/}
                                    {/*        rules={[*/}
                                    {/*            {*/}
                                    {/*                // required: isEnterpriseAdmin,*/}
                                    {/*                message: 'Missing Team Name',*/}
                                    {/*            },*/}
                                    {/*        ]}*/}
                                    {/*    >*/}
                                    {/*        <div className="input-wrapper">*/}
                                    {/*            <label htmlFor="teamName" className="input-label">*/}
                                    {/*                <p>Credit Limit</p>*/}
                                    {/*            </label>*/}
                                    {/*            <input*/}
                                    {/*                id="teamName"*/}
                                    {/*                placeholder="Set Credit Limit"*/}
                                    {/*                className="add-member-input-field"*/}
                                    {/*            />*/}
                                    {/*        </div>*/}
                                    {/*    </Form.Item>*/}

                                    {/*</div>*/}
                                </div>


                            </div>

                            <div className="deploy-nft-collection">
                                <button className="custom-button" htmlType="submit">
                                    Add Member
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    )
}

export default AddMember;
import React, { useEffect, useState } from 'react';
import '../style.less';
import { apiCaller } from '../utils/utils';
import { GASLESS_URLS } from '../utils/constants';
import {getUserDetails} from "../../../deps";
import RequestGaslessList from "./requestGaslessList";
import {useNavigate} from "react-router-dom";
import {Spin} from "antd";

export const RequestGasLimit = ({ showLoader }) => {
    const navigate = useNavigate();
    const userDetails = getUserDetails()
    const [networksDropdownArray, setNetworkDropdown] = React.useState([]);
    const [selectedProtocol, setSelectedProtocol] = React.useState('');
    const [gasLimit, setGasLimit] = React.useState('');
    const [additionalInformation, setAdditionalInformation] = React.useState('');
    const [showLoader1, setLoader] = useState(false);

    async function getNetworksDropDownArray() {
        let resp = await apiCaller('POST', GASLESS_URLS.LIST_ALL_SUPPORTED_NETWORKS, {userId: userDetails.profile.developerId}, false);
        setNetworkDropdown(resp.data.data);
    }

    function navigateBack (){
        navigate("/addons/gasless");
    }

    useEffect(() => {
        showLoader(true)
        getNetworksDropDownArray().then();
    }, []);

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoader(true);
        const selectedNetwork = networksDropdownArray.find(item => item.chainId == selectedProtocol);

        const formData = {
            protocol: selectedProtocol,
            network: selectedNetwork.network,
            chainId: selectedNetwork.chainId,
            requestedGasLimit: parseFloat( gasLimit),
            additionalInformation: additionalInformation,
            userId: userDetails.profile.developerId,
            subscriptionId: userDetails.profile.subscriptionId
        };

        let resp = await apiCaller('POST', GASLESS_URLS.CREATE_GAS_LIMIT_REQUEST, formData, true);
        setLoader(false);
        if (resp.status) {
            setAdditionalInformation('');
            setGasLimit('');
            setSelectedProtocol('');
            navigateBack()
        }
    };

    function callBack(){
        showLoader(false)
    }

    return (
        <>
            <div className="gasless-manager-container marginBtm30">
                <div className="header-text-inner">Submit the form to make gasless Transactions</div>
                <br />
                <div className="gasless-form">
                    <form onSubmit={handleSubmit}>
                        <table>
                            <tbody className="gasless-body">
                            <tr>
                                <td className="td-text">Protocol to Enable Gasless Transactions on</td>
                                <td>
                                    <select
                                        required={true}
                                        aria-errormessage={'select supported protocol'}
                                        className="dropdown-filter create-collection-drop"
                                        name="network"
                                        size="large"
                                        id="protocol"
                                        value={selectedProtocol}
                                        onChange={(e) => setSelectedProtocol(e.target.value)}
                                        style={{
                                            width: 250,
                                            color:`${selectedProtocol==""?'gray':'white'}`
                                        }}
                                    >
                                        <option value="" disabled selected >Supported Protocol</option>
                                        {networksDropdownArray.map((item, index) => (
                                            <option key={index} value={item.chainId}>
                                                {item.network}
                                            </option>
                                        ))}
                                    </select>
                                </td>
                            </tr>
                            <tr>
                                <td className="td-text" style={{ wordBreak: 'break-word' }}>
                                    Based on the expected number of transactions on <br/> your dApp, enter your required gas limit (ether)
                                </td>
                                <td>
                                    <input
                                        required={true}
                                        aria-errormessage={'Enter gas limit'}
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Gas Limit"
                                        name="requestedGasLimit"
                                        value={gasLimit}
                                        onChange={(e) => setGasLimit(e.target.value)}
                                    />
                                </td>
                            </tr>
                            <tr>
                                <td className="td-text">Additional Information</td>
                                <td>
                                    <textarea
                                        className="gasless-input-container create-collection-drop"
                                        name="additionalInformation"
                                        placeholder="How can I help you?"
                                        value={additionalInformation}
                                        onChange={(e) => setAdditionalInformation(e.target.value)}
                                        rows={2}
                                    />
                                </td>
                            </tr>
                            </tbody>
                        </table>
                        <br />
                        <div className="td-text1" >
                            {/* Our team will reach out to you to enable gasless transactions for your requested protocol. */}
                            Note: If the gas limit falls below 0.5 ether, the system will automatically generate a protocol 
                        </div>
                        <div className="td-text1" >
                        configuration based on the requested gas limit.
                        <button type="submit" className="gradient-button1">
                            <Spin spinning={showLoader1}>
                                Submit
                            </Spin>

                        </button>
                        </div>

                        <br />
                        
                    </form>
                </div>
            </div>

            <RequestGaslessList callBack={callBack}/>
        </>
    );
};

import { motion, useTransform, useScroll, useAnimation } from "framer-motion";
import React, { useEffect, useRef, useState } from "react";
import './NewUi.css'
import New_LandingPage from "../new-light-theme/New_LandingPage";
import Img1 from '../../assets/icons/Artboard 1 1.svg'
import Img2 from '../../assets/icons/Artboard 8 1.svg'
import Img3 from '../../assets/icons/Artboard 6 1.png'

const ScrollAnimation = () => {
  return (
    <div className="element">
      {/* <div className="element1">
      <span className="element2">
          Scroll up
        </span>
      </div> */}
      
      <HorizontalScrollCarousel />
      <MobileView/>
      {/* <div className="element1">
        <span className="element2">
          Scroll up
        </span>
      </div> */}
    </div>
  );
};

const MobileView =()=>{
  return(
    <div className="mobileview-infra">
      <div className="light-theme-image-box1 image-box1 "    id='value1'> 
            <h2 className="mobileview-infra-header">Infrastructure <br  className="light-theme-hiddenbreaktag"  /> Complexity</h2>
            <p className='light-theme-right-para'>
              Developing dApps requires complex infrastructure and <br  className="light-theme-hiddenbreaktag"  />
              nodes that are resource-intensive. Krypcore Web3 <br  className="light-theme-hiddenbreaktag"  />
              simplifies this with web3 APIs and ready-to-use nodes <br  className="light-theme-hiddenbreaktag"  />
                for EVM and non-EVM networks, enabling developers to <br  className="light-theme-hiddenbreaktag"  />
                quickly deploy nodes, save bandwidth, and <br  className="light-theme-hiddenbreaktag"  />
                concentrate on core dApp functionalities.
            </p>
            <img src={Img1} className='light-theme-infrastructure-img' height='auto' width='auto'/>
      
          </div>
          <div className="light-theme-image-box1 image-box1"   id='value2'>
            <h2 className="mobileview-infra-header">Data <br  className="light-theme-hiddenbreaktag"  />Complexity</h2>
            <p className='light-theme-right-para'>
            Querying and retrieving sequential data from blockchains <br  className="light-theme-hiddenbreaktag"  />
            can be challenging, consuming time and resources.<br  className="light-theme-hiddenbreaktag"  />
            Krypcore Web3 solves this by offering a Datalake,<br  className="light-theme-hiddenbreaktag"  />
              delivering businesses clear, actionable insights into their<br  className="light-theme-hiddenbreaktag"  />
              dApps activities for improved decision-making.
            </p>
            <img src={Img2} className='light-theme-infrastructure-img' height='auto' width='auto'/>
      
          </div> 
          <div className="light-theme-image-box1 image-box1"  id='value3'> 
            <h2 className="mobileview-infra-header">Adoption <br  className="light-theme-hiddenbreaktag"  /> Complexity</h2>
                 <p className='light-theme-right-para'>
                   Blockchain and web3 are increasingly adopted by <br  className="light-theme-hiddenbreaktag"  />
                   enterprises, though complexity remains a challenge.<br  className="light-theme-hiddenbreaktag"  />
                    Krypcore Web3 simplifies this with a managed backend <br  className="light-theme-hiddenbreaktag"  />
                    infrastructure that consolidates dApp development into<br  className="light-theme-hiddenbreaktag"  />
                     one platform, enabling efficient enterprise adoption of <br  className="light-theme-hiddenbreaktag"  />
                     these technologies.
                 </p>
                 <img src={Img3} className='light-theme-infrastructure-img' height='auto' width='auto'/>
            
                </div>      
    </div>
  )
}

const HorizontalScrollCarousel = () => {
  const targetRef = useRef(null);
  const { scrollYProgress } = useScroll({
    target: targetRef,
  });

  const y = useTransform(scrollYProgress, [0, 1], ["80%", "-320%"]);
  const controls = useAnimation();

  useEffect(() => {
    controls.start({ y: -500 }); // Adjust the value based on your desired scroll distance
  }, []);

  const [componentInView, setComponentInView] = useState(null);
  const componentRefs = [useRef(), useRef(), useRef()]; // Create refs for your components
  console.log(componentInView,)
  useEffect(() => {
    const observerOptions = {
      root: null,
      rootMargin: '0px',
      threshold: 0.5, // Adjust this threshold as needed
    };

    const observerCallback = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Set the component in view based on its index
          setComponentInView(entry.target.dataset.index);
        }
      });
    };

    const observer = new IntersectionObserver(observerCallback, observerOptions);

    // Observe each component
    componentRefs.forEach((ref) => {
      observer.observe(ref.current);
    });

    // Cleanup observer on unmount
    return () => {
      observer.disconnect();
    };
  }, []);

  return (
    <>
   
    <section 
    //   ref={targetRef} 
      className="element3"
      
    >
       
    
      <div className="element4">
      <div className='light-theme-left-scroll1'>
              <div className={ `${componentInView==='0'&&'light-theme-right-side-header-tabs-active2'}`}>
              <p className={ `${componentInView==='0'?'light-theme-right-side-header-tabs-active1 light-theme-right-side-header-tabs1':'light-theme-right-side-header-tabs1'}`}>
                  Infrastructure <br  className="light-theme-hiddenbreaktag"  /> Complexity
                </p>
              </div>
              <div className={ `${componentInView==='1'&&'light-theme-right-side-header-tabs-active2'}`}>
                <p className={ `${componentInView==='1'?'light-theme-right-side-header-tabs-active1 light-theme-right-side-header-tabs1':'light-theme-right-side-header-tabs1'}`}>
                  Data <br  className="light-theme-hiddenbreaktag"  />Complexity
                </p>
                </div>
                <div className={ `${componentInView==='2'&&'light-theme-right-side-header-tabs-active2'}`}>
                <p className={ `${componentInView==='2'?'light-theme-right-side-header-tabs-active1 light-theme-right-side-header-tabs1':'light-theme-right-side-header-tabs1'}`}>
                  <span> Adoption <br  className="light-theme-hiddenbreaktag"  /> Complexity</span> 
                </p>
                </div>
              </div>
        <motion.div style={{ y }} className="element5"
        animate={controls}
        transition={{ type: "spring", stiffness: 50, damping: 20 }} // Adjust the stiffness and damping for the desired scroll speed
        >
          {/* {cards.map((card) => {
            return <Card card={card} key={card.id} />;
          })} */}
          <div
            
            className="element6"
            
            >
            <div
                style={{
                // backgroundImage: `url(${card.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                }}
                className="element7"
            ></div>
            <div className="element8" >
            <div className="light-theme-image-box image-box1 "  ref={componentRefs[0]} data-index={0}  id='value1'> 
                 <p className='light-theme-right-para'>
                   Developing dApps requires complex infrastructure and <br  className="light-theme-hiddenbreaktag"  />
                    nodes that are resource-intensive. Krypcore Web3 <br  className="light-theme-hiddenbreaktag"  />
                    simplifies this with web3 APIs and ready-to-use nodes <br  className="light-theme-hiddenbreaktag"  />
                     for EVM and non-EVM networks, enabling developers to <br  className="light-theme-hiddenbreaktag"  />
                      quickly deploy nodes, save bandwidth, and <br  className="light-theme-hiddenbreaktag"  />
                      concentrate on core dApp functionalities.
                 </p>
                 <img src={Img1} className='light-theme-infrastructure-img' height='auto' width='auto'/>
            
                </div>
            </div>
            </div>
            <div
            
            className="element6"
            
            >
            <div
                style={{
                // backgroundImage: `url(${card.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                }}
                className="element7"
            ></div>
            <div className="element8">
            <div className="light-theme-image-box image-box1"  ref={componentRefs[1]} data-index={1} id='value2'> 
                 <p className='light-theme-right-para'>
                 Querying and retrieving sequential data from blockchains <br  className="light-theme-hiddenbreaktag"  />
                 can be challenging, consuming time and resources.<br  className="light-theme-hiddenbreaktag"  />
                  Krypcore Web3 solves this by offering a Datalake,<br  className="light-theme-hiddenbreaktag"  />
                   delivering businesses clear, actionable insights into their<br  className="light-theme-hiddenbreaktag"  />
                    dApps activities for improved decision-making.
                 </p>
                 <img src={Img2} className='light-theme-infrastructure-img' height='auto' width='auto'/>
            
                </div>
            </div>
            </div>
            <div
            
            className="element6"
            
            >
            <div
                style={{
                // backgroundImage: `url(${card.url})`,
                backgroundSize: "cover",
                backgroundPosition: "center",
                }}
                className="element7"
            ></div>
            <div className="element8">
            <div className="light-theme-image-box image-box1" ref={componentRefs[2]} data-index={2} id='value3'> 
                 <p className='light-theme-right-para'>
                   Blockchain and web3 are increasingly adopted by <br  className="light-theme-hiddenbreaktag"  />
                   enterprises, though complexity remains a challenge.<br  className="light-theme-hiddenbreaktag"  />
                    Krypcore Web3 simplifies this with a managed backend <br  className="light-theme-hiddenbreaktag"  />
                    infrastructure that consolidates dApp development into<br  className="light-theme-hiddenbreaktag"  />
                     one platform, enabling efficient enterprise adoption of <br  className="light-theme-hiddenbreaktag"  />
                     these technologies.
                 </p>
                 <img src={Img3} className='light-theme-infrastructure-img' height='auto' width='auto'/>
            
                </div>
            </div>
            </div>
                </motion.div>
            </div>
            </section>
            </> );
        };



export default ScrollAnimation;


import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { fetchApi } from "../../../_config/api";
import {Collapse, Input, notification} from "antd";
import Skeleton from 'react-loading-skeleton'
import 'react-loading-skeleton/dist/skeleton.css'
import CopyImage from '../../../assets/icons/imagecopy.svg'
import checkmark from '../../../assets/icons/checkmark.svg'
import AddIcon from '../../../assets/icons/Mask group.svg'



import {
    Card,
    TableContent, PaginationSection,
} from "../../../library";
import { activityColumns } from "./columns";
import {
    getUserDetails,
    ENTERPRISEDEVELOPERROLE,
    DEVELOPERROLE, getPATTokenDetails,
} from "../../../deps";
import {
    DeveloperCard,
    EndpointCard3,
    ExampleCard,
    ProjectCard,
    ReleaseCard,
    TutorialCard
} from "../../../library/card";
import { AccountInfoInfo } from "./accountInfo";
import {CopyOutlined} from "@ant-design/icons";
import {Menus} from "../../../assets";
import './styles.less'
import Endpoints from "../Endpoints";
import CreateDapp from "./CreateDapp";

const Dashboard = ({ showLoader,isLoading }) => {

  let noOfApiRan = 0;
    const navigate = useNavigate();
    const [cardData, setCardData] = useState({});
    const [createDappOpen, setCreateDappOpen] = useState(false);
    const [activityList, setActivityList] = useState(null);
    const { userRole = DEVELOPERROLE, subscriptionId } = getUserDetails().profile || {};
    const { Panel } = Collapse;
    const [projectId, setProjectId] = useState("");
    const [projectDetails, setProjectDetails] = useState({});
    const [data, setData] = useState([]);
    const [projectcarddata, setProjectcardData] = useState([]);
    const [endPointsNav, setEndPointsNav] = useState(true)
    const [teamList, setTeamList] = useState([]);
    const [isCopied, setIsCopied] = useState(false);
    const [isCopiedauth, setIsCopiedauth] = useState(false);
    const userData = getUserDetails();
    console.log("userData---->",userData)
    const pattoken = getPATTokenDetails();

    useEffect(() => {
        noOfApiRan = 0;
        getCardData();
        getActivityList();
        getEndpointsList();
        getProjectListforcard();
    }, []);

    useEffect(() => {
        if (userData.profile.userRole === ENTERPRISEDEVELOPERROLE) {
            getTeamsList();
        }
    }, []);

    const [pageSetting, setSetting] = React.useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const [totalCount, setTotalCount] = React.useState(-1);
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [hitsList, setHitsList] = React.useState([]);


    const toggleLoader = () => {
        noOfApiRan++;
    };

    const getTeamsList = () => {
        const filter = { value: "", status: "" }

        const Options = () => {
            return {
                sort: "",
                page: 0,
                limit: 2,
            };
        };

        let requestLink = `teams/getAll?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({ method: "GET", url: requestLink, isDevApp: false })
            .then((response) => {
                const { docs = [] } = response;
                if (docs.length > 0) {
                    setTeamList([...docs]);
                } else {
                    setTeamList([]);
                }
                toggleLoader();
            })
            .catch(() => {
                setTeamList([]);
                toggleLoader();
            });
    };

    const getCardData = () => {
        fetchApi({ method: "GET", url: "developer/dashboard-data" })
            .then((response) => {
                setCardData(response.docs);
                toggleLoader();
            })
            .catch(() => {
                setCardData({ totalProjects: 0, availableCredits: 0, totalCredits: 0 });
                toggleLoader();
            });
    };

    const onCopy = (apiKey, type) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
        if (type === 'subscription') {
            setIsCopied(true);
            setTimeout(() => setIsCopied(false), 2000);
        } else if (type === 'auth') {
            setIsCopiedauth(true);
            setTimeout(() => setIsCopiedauth(false), 2000);
        }
    };

    const onExport = () => {
        showLoader(true);
        fetchApi({
            method: "GET",
            url: `projects/${projectId}/exportConfig`,
        })
            .then((response) => {
                const dataStr =
                    "data:text/json;charset=utf-8," +
                    encodeURIComponent(JSON.stringify(response));
                const projectExport = document.getElementById("projectExport");
                projectExport.setAttribute("href", dataStr);
                projectExport.setAttribute("download", `${projectDetails?.name}.json`);
                projectExport.click();
                showLoader(false);
                notification.success({
                    message: "Successfully exported",
                    duration: 3,
                });
            })
            .catch(() => {
                showLoader(false);
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3,
                });
            });
    };

    const onResetApi = () => {
        showLoader(true);
        fetchApi({
            method: "PATCH",
            url: `projects/${projectId}/resetApikey`,
        })
            .then((response) => {
                if (response?.apiKey) {
                    projectDetails.apiKey = response.apiKey;
                    setProjectDetails({ ...projectDetails });
                }
                showLoader(false);
                notification.success({
                    message: "API Key has been successfully reset",
                    duration: 3,
                });
            })
            .catch(() => {
                showLoader(false);
                notification.error({
                    message: "Something went wrong. Please try again",
                    duration: 3,
                });
            });
    };

    const onCreateEndpointRedirect = (record) => {
        navigate(`/endpoints`, { state: { endPointsNav: endPointsNav } });
    }

    const onViewEndpointRedirect = () => {
        navigate(`/endpoints`);
    }

    const onCreateProjectRedirect = (record) => {
        navigate(`/project/create-apps`);
    };

    const onViewProjectRedirect = (record) => {
        navigate(`/project`);
    };

    const onProjectRedirect = (projectID) => {
        navigate(`/project/${projectID}`);
    };

    const getEndpointsList = () => {
        fetchApi({ method: "GET", url: "endpoints" })
            .then((response) => {
                const { docs = [] } = response;
                let list = []
                const data = docs.reverse()
                for (let i = 0; i < data.length; i++) {
                    if (data[i].infraType === "blockchainNode") {
                        if (list.length <= 1) {
                            list.push(data[i])
                        }
                    }
                    setData(list)
                }
            })
            .catch(() => {
                setData([]);
            });
    };

    const getProjectListforcard = () => {
        const Options = () => {
            setHitsList([3, 6, 9, 12]);
            return {
                select: [],
                sort: {},
                page: pageSetting.page - 1,
                limit: pageSetting.itemsPerPage,
            };
        };
        const filter = {};
        const data = {
            "filterType": 'All',
            "filterValue": ""
        }
        fetchApi({
            method: "GET", url: `projects/findAll?options=${JSON.stringify(Options())}&filter=${JSON.stringify(filter)}&searchFilter=${JSON.stringify(data)}`,
            isDevApp: false,
        })
            .then((response) => {
                const { docs = [] } = response;
                let list = []
                for (let i = 0; i < docs.length; i++) {
                    if (list.length <= 1) {
                        list.push(docs[i])
                    }
                }
                setProjectcardData(list)
            })
            .catch((response) => {
                setProjectcardData([]);
            });
    };

    const getActivityList = async () => {
        const Options = () => {
            setHitsList([3, 6, 9, 12]);
            return {
                select: [],
                sort: {},
                page: pageSetting.page - 1,
                limit: pageSetting.itemsPerPage,
            };
        };
        showLoader(true);
        const filter = pageSetting.searchText !== "" ?
            {
                and: [
                    {
                        key: "service",
                        value: pageSetting.searchText,
                        opt: "eq",
                    },
                ],
            } : {
                and: [
                    {
                        key: "subscriptionId",
                        value: subscriptionId,
                        opt: "eq",
                    },
                ],
            };
        try {
            const response = await fetchApi({
                method: "GET",
                url: `developer/dashboard/activity-list?options=${JSON.stringify(Options())}&filter=${JSON.stringify(filter)}`,
                isDevApp: false,
            });
            if (response.statusCode === 200) {
                setActivityList(response.docs);
                showLoader(false);
                setTotalCount(response.meta.totalDocs)
            }
        } catch (error) {
            showLoader(false);
            notification.error({
                message: error.toString(),
                duration: 3,
            });
        }
    };
    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getActivityList();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getActivityList();
    };

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;


            const onCreateDappModal = () => {
                setCreateDappOpen(!createDappOpen);
              };
    const userEmail = userData.profile.emailId;
    const userName = userEmail.substring(0, userEmail.indexOf('@'));

  return (
    <div className="dashboard-container">
            
            <div className="row">
            <div className="col-md-5">
                <h1 className="header-one-new">Hello <span className="header-one-new-gradient">{userName},</span></h1>
                <p className="subheader-new">Feel free to explore, get started when you are ready</p>
                <div className="dashboard-subheader-container">
                    <p style={{display: 'flex', alignItems: 'center', whiteSpace: 'nowrap'}} className="subheader-new">
                        {`Subscription ID`} :
                        <span className=""
                              style={{width: "80%", alignItems: 'center', paddingLeft: '10px', display: 'flex'}}>
                            <span>{userData.profile.subscriptionId}</span>
                                <img
                                    src={isCopied ? checkmark : CopyImage}
                                    className="copyimage-new"
                                    onClick={() => onCopy(userData.profile.subscriptionId, 'subscription')}
                                    onMouseOver={(e) => {
                                        e.target.style.backgroundColor = '#434343';
                                        e.target.style.borderRadius = '12px'; // Adjust border radius on hover
                                    }}
                                    onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                                />
                        </span>
                    </p>
                    <p style={{display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'space-between',
                        whiteSpace: 'nowrap'
                    }} className="subheader-new">
                        {`Auth Token`} :
                        <span className="float-right span-fr"
                              style={{width: "80%", paddingLeft: '10px', marginTop: '5px', display: 'flex'}}>
                            <Input
                                type="password"
                                style={{width: '160px'}}
                                className="pwd-style"
                                readOnly={true}
                                value={pattoken.patToken !== "" ? pattoken.patToken : ""}
                            />
                            <img
                                src={isCopiedauth ? checkmark : CopyImage}
                                className="copyimage-new"
                                onClick={() => onCopy(pattoken.patToken, 'auth')}
                                onMouseOver={(e) => {
                                    e.target.style.backgroundColor = '#434343';
                                    e.target.style.borderRadius = '12px'; // Adjust border radius on hover
                                }}
                                onMouseOut={(e) => e.target.style.backgroundColor = 'transparent'}
                            />
                        </span>
                    </p>
                </div>

            </div>
                <div className="col-md-1">

                </div>
                <div className="col-md-6">
                    <div className="card-container grid-repeat-container marginBtm50 mt-lg-3">
                        {isLoading ? (
                            <Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (
                            <Card
                                type="ds-teams"
                                count={cardData?.totalProjects}
                                text="dApps Created"
                                color="orange"
                            />
                        )}

                        {isLoading ? (
                            <Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (
                            <Card
                                type="active"
                                count={cardData?.availableCredits}
                                text="Credits Used"
                                color="blue"
                            />
                        )}

                        {isLoading ? (
                            <Skeleton height={124} baseColor="#262626" highlightColor="#404040"></Skeleton>) : (
                            <Card
                                type="credit"
                                count={cardData?.totalCredits}
                            text="Credits Left"
                            color="purple"
                        />
                    )}

                    {isLoading?(<Skeleton height={124} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                        <Card
                            type="request"
                            count={cardData?.totalRequests}
                            text="Total Api Hits"
                            color="thickblue"
                        />
                    )}
                </div>
            </div>
            </div>




            <Endpoints showLoader={showLoader} isLoading ={isLoading} isOpen={createDappOpen} toggleState={onCreateDappModal} />
           
            {/* {isLoading?(<Skeleton height={200} baseColor="#262626" highlightColor="#404040" ></Skeleton>):( 
                <TableContent
                    pagination={false}
                    text="Recent API requests"
                    tooltip="This is a tabular summary of the information like method used ,API Call Time, API Credits Exhausted etc while writing the code."
                    rowKey="id"
                    columns={activityColumns}
                    data={activityList}
                />
            )}
          
            <PaginationSection
                last={last}
                first={startIndex + 1}
                hitsList={hitsList}
                totalCount={totalCount}
                page={pageSetting.page}
                itemsPerPage={pageSetting.itemsPerPage}
                onPageChange={onPageChange}
                onPerPageChange={onPerPageChange}
            /> */}
            <CreateDapp 
                isOpen={createDappOpen}
                toggleState={onCreateDappModal}
            />
        </div>
  )
}

export default Dashboard

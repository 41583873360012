// Essentials
import React, {useState} from "react";
//Utils
import { fetchApi } from "../../../../_config/api";
// Components and Styles
import "../../../nftstudio/styles.less";
import '../styles.less'
import { Modal, Space, Input, Form, notification, Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Menus } from "../../../../assets";
import {DEVELOPERTYPE, ENTERPRISEADMINROLE, getUserDetails} from "../../../../deps";

export const Transfer = ({
                             isOpen,
                             toggleState,
                             id,
                             reloadCollection,
                         }) => {
    const formRef = React.useRef(null);
    const [chains, setChains] = React.useState([]);
    const [error, setError] = React.useState({
        team:"",developer:""
    });

    const [users, setUsers] = useState([]);

    const [deployDisabled, setDeployDisabled] = React.useState(false);
    const userData = getUserDetails();



    React.useEffect(() => {
        if(userData.profile.userRole === ENTERPRISEADMINROLE) {
            fetchApi({
                method: "GET",
                url: `teams`,
                isDevApp: false,
            })
                .then((response) => {
                    // console.log("response",response)
                    if (response.statusCode === 200 || response.statusCode === 201 ) {
                        if (response.docs) {
                            let users = response.docs.map((team) => ({
                                label: `${team.name}`,
                                value: team.teamId,
                                Id: team._id
                            }));
                            setChains([...users]);
                        }else{
                            setChains([]);
                        }
                    }else{
                        console.error(response.message);
                    }
                })
                .catch((error) => {
                    console.error(error);
                });
        }
    }, []);

    React.useEffect(() => {
        formRef?.current?.resetFields();
        setError({
            team:"",developer:""})
    }, [isOpen]);


    const  fetchDeveloperList = (teamId) =>{
        // console.log("teamId",teamId)
        return fetchApi({
            method: "GET",
            url: `teams/${teamId}`,
            isDevApp: false,
        }).then((body) => {
            if (body.statusCode === 200 || body.statusCode === 201 ) {
                let users = body.doc.developers.map((user) => ({
                    label: `${user.firstName} ${user.lastName}`,
                    value: user._id,
                }));
                setUsers([...users]);
                // console.log("users", users)
            }else{
                // console.log(body.message)
            }
        });
    }

    const onCreateClientCredentials = (value) => {
        if (value.team === "" || value.team === undefined){
            setError({team:"please choose the team"})
            return
        }else if(value.developer === "" || value.developer === undefined){
            setError({developer:"please choose the developer"})
            return
        }else{
            setError({
                team:"",developer:""})
        }
        let data = {
            updateType: "TRANSFER",
            ownership: value.developer,
            teamId: value.team
        }
        // console.log("fromdata",data)
        fetchApi({
            method: "PUT",
            url: `client-credential/update/${id}`,
            isDevApp: false,
            data: data,
        })
            .then((response) => {
                toggleState();
                if (response.statusCode === 201 || response.statusCode === 200){
                    notification.success({
                        message:
                            "Credentials Transfered Successfully.",
                        duration: 2,
                    });
                    reloadCollection()
                }else{
                    notification.error({
                        message: response.message,
                        duration: 2,
                    });
                }
            })
            .catch((error) => {
                console.error(error);
                notification.error({
                    message: "Not able to Transfer the Credentials",
                    duration: 3,
                });
            });
    };

    const onChangeSelect = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        const index = event.target.selectedIndex;
        const el = event.target.childNodes[index]
        let option =  el.getAttribute('data-remove');
        if(option != null){
            fetchDeveloperList(option)
        }
    };


    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );

    return (
        <>
            <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text">OwnerShip Transfer Details</div>
                    <Space
                        direction="vertical"
                        size={25}
                        style={{ display: "flex", marginTop: "25px" }}
                    >
                        <Form
                            name="basic"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            onFinish={onCreateClientCredentials}
                            autoComplete="off"
                        >
                            <Form.Item name="team">
                                <>
                                    <select
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Choose Team"
                                        name="team"
                                        onChange={(event) =>
                                            onChangeSelect(event, "team")
                                        }
                                    >
                                        <option value="">Choose Team</option>
                                        {chains.map((chain, ind) => (
                                            <option key={ind} value={chain.value} data-remove={chain.Id}>
                                                {chain.label}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Form.Item>
                            <p className="error_text">{error.team}</p>
                            <Form.Item name="developer">
                                <>
                                    <select
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Choose Developer"
                                        name="developer"
                                        onChange={(event) =>
                                            onChangeSelect(event, "developer")
                                        }
                                    >
                                        <option value="">Choose Developer</option>
                                        {users.map((users, ind) => (
                                            <option key={ind} value={users.value}>
                                                {users.label}
                                            </option>
                                        ))}
                                    </select>
                                </>
                            </Form.Item>
                            <p className="error_text">{error.developer}</p>
                            <div className="deploy-nft-collection">
                                <button disabled={deployDisabled} htmlType="submit">
                                    {!deployDisabled ? (
                                        "Submit"
                                    ) : (
                                        <Spin indicator={antIcon} />
                                    )}
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
        </>
    );
};

import React from "react";

export const Addon = ({ width = "20px", height = "20px" }) => (
    <svg width="17" height="18" viewBox="0 0 17 18" fill="none" xmlns="http://www.w3.org/2000/svg">
      <path d="M4.1875 7.875C5.94791 7.875 7.375 6.44791 7.375 4.6875C7.375 2.92709 5.94791 1.5 4.1875 1.5C2.42709 1.5 1 2.92709 1 4.6875C1 6.44791 2.42709 7.875 4.1875 7.875Z" stroke="#7D7575" stroke-width="1.5"/>
      <path d="M12.8125 16.5C14.5729 16.5 16 15.0729 16 13.3125C16 11.5521 14.5729 10.125 12.8125 10.125C11.0521 10.125 9.625 11.5521 9.625 13.3125C9.625 15.0729 11.0521 16.5 12.8125 16.5Z" stroke="#7D7575" stroke-width="1.5"/>
      <path d="M4.1875 16.5C5.94791 16.5 7.375 15.0729 7.375 13.3125C7.375 11.5521 5.94791 10.125 4.1875 10.125C2.42709 10.125 1 11.5521 1 13.3125C1 15.0729 2.42709 16.5 4.1875 16.5Z" stroke="#7D7575" stroke-width="1.5"/>
      <path d="M13 1.5V7.5M16 4.5H10" stroke="#7D7575" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </svg>
);

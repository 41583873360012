/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { fetchApi } from "../../_config/api";
import { TableContent, PaginationSection, Filters } from "../../library";
import {InfoCircleOutlined, ReloadOutlined} from "@ant-design/icons";
import {deepCopy, DEVELOPERROLE, ENTERPRISEDEVELOPERROLE, getUserDetails} from "../../deps";
import {columns, Didcolumns, storageandDidcolumns, tempData} from "./columns";
import "./styles.less";
import {notification, Tooltip} from 'antd';
import {EntColumns, EntDidcolumns, EntStorageandDidcolumns} from "./enterpriseColumns";
import {CreateEndpoint} from "./createEndpoint";
import Endpoints from "./index";

export const EnpointsList = ({ dashboard,tabName,queryblock,querystorage,querydid,querybundler,onCreateChange}) => {
  const [pageSetting, setSetting] = useState({
    page: 1,
    itemsPerPage: 10,
    searchText: "",
    filterValue: "",
  });
  const {
    profile: { userRole },
  } = getUserDetails();
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hitsList, setHitsList] = useState([]);
  const [totalCount, setTotalCount] = useState(-1);
  const [createEndpointOpen, setCreateEndpointOpen] = useState(false);
  const [role, setRole] = React.useState(null);
  const [apiKey, setApiKey] = React.useState("");
  const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;

  useEffect(() => {
    if (tabName === "blockchainNode" && queryblock){
      getEndpointsList();
    }
    if (tabName === "storageNode" && querystorage){
      getEndpointsList();
    }
    if (tabName === "didNode" && querydid){
      getEndpointsList();
    }
    if (tabName === "bundlerNode" && querybundler){
      getEndpointsList();
    }
  }, [tabName])

  useEffect(() => {
    if (dashboard){
      getEndpointsList();
    }
  }, [dashboard])

  useEffect(() => {
    onFilterDataChange();
  }, [data, pageSetting]);


  const getEndpointsList = () => {
    setHitsList([5, 10, 15, 20, 25]);
    const filter = {
      and: [
        {
          key: "",
          value: false,
          opt: "",
        },
      ],
    };

    const Options = () => {
      return {
        select: "",
        sort: "",
         skip:  (pageSetting.page - 1) * (pageSetting.itemsPerPage) ,
         limit: pageSetting.itemsPerPage,
      };
    };
    fetchApi({ method: "GET", url: `endpoints/infraType/${tabName}?options=${JSON.stringify(
          Options()
      )}&filter=${JSON.stringify(filter)}` })
      .then((response) => {
        const { docs = [] } = response;
        // console.log("docs",docs)
        setData(docs)
        setTotalCount(response.meta.totalDocs[0].count)
        /*let list = []
        for(let i=0; i < docs.length;i++){
          if(docs[i].infraType === tabName){
            list.push(docs[i])
          }
          console.log("response",list)*/

        // }
      })
      .catch(() => {
        setData([]);
      });
  };
  const onFilterDataChange = () => {
    const endIndex =
        pageSetting.page * pageSetting.itemsPerPage > data.length
            ? data.length
            : pageSetting.page * pageSetting.itemsPerPage;

    const filterList = (
        pageSetting.searchText
            ? data.filter((item) =>
                item.status
                    .toLowerCase()
                    .includes(pageSetting.searchText.toLowerCase())
            )
            : deepCopy(data),
            pageSetting.searchText
                ? data.filter((item) =>
                    item.protocolName
                        .toLowerCase()
                        .includes(pageSetting.searchText.toLowerCase())
                )
                : deepCopy(data)
    )
        .filter((item) =>
            pageSetting.filterValue !== ""
                ? (pageSetting.filterValue === "locked" && item.isLocked) ||
                (pageSetting.filterValue === "unlocked" && !item.isLocked)
                : true
        )
        .slice(0, endIndex);
    setFilteredData(filterList);
  };
  useEffect(() => {
    setTotalCount(totalCount);
  }, [data]);


  const onTextChange = (value) => {
    pageSetting.searchText = value;
    setSetting({ ...pageSetting });

  };

  const onRadioChange = (value) => {
    pageSetting.filterValue = value;
    setSetting({ ...pageSetting });
  };

  const onPageChange = (pageNumber) => {
    pageSetting.page = Number(pageNumber);
    setSetting({ ...pageSetting });
    getEndpointsList();
  };

  const onPerPageChange = (value) => {
    pageSetting.itemsPerPage = Number(value);
    pageSetting.page = 1;
    setSetting({ ...pageSetting });
    getEndpointsList();
  };

  const last =
    pageSetting.page * pageSetting.itemsPerPage > totalCount
      ? totalCount
      : pageSetting.page * pageSetting.itemsPerPage;



  const onCreateEndpointModal = () => {
    setCreateEndpointOpen(!createEndpointOpen);
  };

  return (
      <>
    <div className="transaction-page-container">
      <div className="transaction-header-section1 marginBtm">
        <div className="header-section1">
          <Filters
            showCreateBtn={userRole !== ENTERPRISEDEVELOPERROLE}
            showSearch = {false}
            showFilters={false}
            value={pageSetting.searchText}
            selectedValue={pageSetting.filterValue}
            onRadioChange={onRadioChange}
            onTextChange={onTextChange}
            radioOptions={[
              { value: "locked", text: "Lock Index" },
              { value: "unlocked", text: "UnLock Index" },
              { value: "", text: "See All" },
            ]}
            btnText={"Create Endpoint"}
            onNavigate={onCreateEndpointModal}
            createBtnClassName={"gradient-button mr-3"}
          />
      </div>
      <CreateEndpoint
          isOpen={createEndpointOpen}
          toggleState={onCreateEndpointModal}
          reloadCollection={
            userRole !== DEVELOPERROLE
                ? getEndpointsList
                : getEndpointsList
          }
          activeKey={tabName}
          query={onCreateChange}
          role={userRole}
      />
    </div>

      <div className="table-container">
        {userRole === DEVELOPERROLE ?
            <TableContent
                rowKey="endpointId"
                data={filteredData}
                columns={tabName === "storageNode"? storageandDidcolumns: tabName === "didNode"? Didcolumns : columns}

                isDashboard={false}
                pagination={false}
            />:
            <TableContent
                rowKey="endpointId"
                data={filteredData}
                columns={tabName === "storageNode"? EntStorageandDidcolumns: tabName === "didNode"? EntDidcolumns : EntColumns}

                isDashboard={false}
                pagination={false}
            />
        }
        <PaginationSection
            last={last}
            first={startIndex + 1}
            hitsList={hitsList}
            totalCount={totalCount}
            page={pageSetting.page}
            itemsPerPage={pageSetting.itemsPerPage}
            onPageChange={onPageChange}
            onPerPageChange={onPerPageChange}
        />
      </div>
    </div>

    </>
  );
};

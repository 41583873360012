import React, {useState} from "react";
import {Button, Form, notification, Space} from "antd";
import {createAsyncFormActions, SchemaForm} from "@uform/antd";
import TextArea from "antd/es/input/TextArea";
import {setFormData} from "../../nftstudio/utils";
import {fetchApiGw} from "../../../_config/api";
import {getAuthToken, getDAppId, getPATTokenDetails, getUserDetails} from "../../../deps";
import {schemaJson} from "./schema";
import {LoadingOutlined} from "@ant-design/icons";
import ReactJson from "react-json-view";

export const EnpointsAPISandboxPage = ({methodList,rpcURL}) => {
    const formRef = React.useRef(null);
    const [showFormLayout, setShowFormLayout] = useState(false);
    const actions = createAsyncFormActions();
    const [loading, setLoading] = useState(false);
    const [responseJson,setResponseJson] = React.useState(null);
    const [methodName, setMethodName] = useState("");
    const [schema, setSchema] = useState(null);
    const [curlCommand, setCurlCommand] = useState(null);
    const [schemaDatas, setSchemaDatas] = useState(null);
    const [methodNameList, setMethodNameList] = useState([]);
    React.useEffect(() => {
        if(rpcURL !== undefined && rpcURL !== ""){
            fetchSchema();
        }
    }, [rpcURL]);
    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };

    const fetchSchema = async () => {
        try {
            const response = schemaJson
            setSchemaDatas(response[0]);
            const propertyNames = getFirstLevelPropertyNames(schemaJson);
            setMethodNameList(propertyNames);
            console.log("propertyNames----->",propertyNames)
        } catch (error) {
            console.error('Error fetching schema:', error);
        }
    };

    const getFirstLevelPropertyNames = (schema) => {
        if (schema.length === 0) return [];
        const firstSchema = schema[0];
        if (firstSchema && firstSchema.properties && typeof firstSchema.properties === 'object') {
            return Object.keys(firstSchema.properties);
        }
        return [];
    };

    const onChangeSelectMethod = (event, name) => {
        document.getElementById('response-box').style.display = 'none';
        setResponseJson({});
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        setShowFormLayout(true);
        setMethodName(event.target.value);
        actions.reset({validate:false});
        createCurlData(event.target.value);
    };
    const createCurlData = (methodName) => {
        setSchema("");
        setCurlCommand("");
        if (schemaDatas) {
            setSchema(schemaDatas.properties[methodName]);
            const selectedProperties = schemaDatas.properties[methodName]?.properties;
            const data={};
            for (const key in selectedProperties) {
                data[key] = selectedProperties[key].type;
            }
            const dataObj = Object.values(data).filter(value => value !== undefined);
            const url = rpcURL;
            generateCurlCommand(url,dataObj,methodName)

        } else {
            console.error('Failed to fetch schema:', schemaDatas);
        }
    }

    const generateCurlCommand = (url,data,methodName) => {
        let dataObj = {};
        dataObj.id = 1;
        dataObj.jsonrpc = "2.0";
        dataObj.method = methodName;
        dataObj.params = data;
        // let curlCommand = `curl -X POST "${url}" \\
        //  -H "Content-Type: application/json" \\
        //  -d '${JSON.stringify(dataObj).replace(/'/g, "\\'")}'`;
        const jsonData = JSON.stringify(dataObj).replace(/'/g, "\\'");
        // Generate the curl command
        let curlCommand = `curl -X POST "${url}" \\
    -H "Content-Type: application/json" \\
    -d '${jsonData}'`;

        setCurlCommand(curlCommand)

    };
    const onInputChangeCurl = (values,methodName) => {
        const data = Object.values(values).filter(value => value !== undefined);
        console.log("data---------->",data)
        const url = rpcURL;
        generateCurlCommand(url,data)
    }
    const handleSubmit = (values) => {
        setLoading(true); // Show spinner
        if(methodName !== ""){
            const data = Object.values(values).filter(value => value !== undefined);
            let url = rpcURL;
            let instanceId = "";
            let dataObj = {};
            dataObj.id = 1;
            dataObj.jsonrpc = "2.0";
            dataObj.method = methodName;
            dataObj.params = data;
            fetchApiGw({
                method: "POST",
                url: url,
                isDevApp: false,
                useBase:false,
                data: dataObj,
                instanceId: instanceId,
                chainId:"",
                dAppID: ""
            })
                .then((response) => {
                    setLoading(false);
                    actions.reset({validate:false});
                    createCurlData(methodName)
                    document.getElementById('response-box').style.display = 'block';
                    setResponseJson(response);
                })
                .catch((error) => {
                    setLoading(false);
                    notification.destroy();
                    actions.reset({validate:false});
                    notification.error({
                        message: "Something went wrong. Please try again",
                        duration: 3,
                    });
                    // createCurlData(methodName)
                    // document.getElementById('response-box').style.display = 'block';
                    // setResponseJson();
                });
        }else{
            notification.destroy();
            actions.reset();
            notification.error({
                message: "Please select action to continue",
                duration: 3,
            });
        }
    };
    return (
        <>
            <div className="dapp-details-select-items" style={{marginTop: "20px"}}>
                <Space
                    direction="horizontal"
                    size={25}
                >
                    <Form
                        name="basic"
                        ref={formRef}
                        initialValues={{
                            remember: true,
                        }}
                        // onFinish={onCreateEndpoint}
                        autoComplete="off"
                    >


                        <select
                            className="dropdown-filters mega-menu-select dapp-details-custom-select"
                            placeholder="Method"
                            name="method"
                            onChange={(event) => onChangeSelectMethod(event, "method")}
                        >
                            <option value="" disabled selected>Choose Method</option>
                            {methodNameList?.map((method, ind) => (
                                <option key={method}
                                        value={method}>{method}</option>))}
                        </select>
                    </Form>
                </Space>
            </div>
            {showFormLayout && (
                <div className="form-sandbox-layout">
                    <div className="form-layout" style={{height:'300px',overflowY:"scroll"}}>
                        <SchemaForm actions={actions} schema={schema} onSubmit={handleSubmit} labelCol={11}
                                    wrapperCol={10}
                                    onChange={(values) => onInputChangeCurl(values, methodName)}>
                            <Button
                                type="primary"
                                htmlType="submit"
                                loading={loading}
                                icon={loading ? antIcon : null}
                                style={{
                                    display: 'flex',
                                    flexDirection: 'row-reverse',
                                    gap: '8px',
                                    marginLeft: '470px',
                                    width: '142px',
                                    justifyContent: 'center',
                                    alignItems: 'center'
                                }}
                            >
                                Send Request
                            </Button>
                        </SchemaForm>
                    </div>
                    <div className="code-layout" style={{position: 'sticky'}}>
                        <TextArea placeholder="//" style={{height: '300px', overflowY: 'auto', paddingTop: "40px"}}
                                  className="curl-code-layout"
                                  value={curlCommand}/>
                        <div>
                            <button className="copy-button"
                                    onClick={() => onCopy(curlCommand)}>
                                Copy
                            </button>
                        </div>
                    </div>
                </div>
            )}
            <div id="response-box" style={{display: "none"}}>
                <div
                    style={{
                        backgroundColor: "#383838",
                        padding: "10px",
                        overflowY: "auto",
                        maxHeight: "500px",
                        borderRadius: "20px",
                        marginTop: "50px"
                    }}
                >
                    <ReactJson style={{backgroundColor: "white", marginTop: 20, overflowX: "auto"}}
                               enableClipboard={true} src={responseJson}/>

                </div>
            </div>
        </>
    );
}
import React from "react";

export const ExistingContract = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g opacity="0.6" clipPath="url(#clip_copy)">
      <path
        d="M16.988 3.89307H6.43245C6.21144 3.89307 5.99948 3.98086 5.8432 4.13714C5.68692 4.29342 5.59912 4.50539 5.59912 4.7264V18.0597C5.59912 18.2807 5.68692 18.4927 5.8432 18.649C5.99948 18.8053 6.21144 18.8931 6.43245 18.8931H16.988C17.209 18.8931 17.421 18.8053 17.5773 18.649C17.7335 18.4927 17.8213 18.2807 17.8213 18.0597V4.7264C17.8213 4.50539 17.7335 4.29342 17.5773 4.13714C17.421 3.98086 17.209 3.89307 16.988 3.89307ZM16.7102 17.782H6.71023V5.00418H16.7102V17.782Z"
        fill="#CDCDCD"
      />
      <path
        d="M15.0422 1.94735C15.0422 1.72633 14.9544 1.51437 14.7981 1.35809C14.6418 1.20181 14.4298 1.11401 14.2088 1.11401H3.65328C3.43227 1.11401 3.2203 1.20181 3.06402 1.35809C2.90774 1.51437 2.81995 1.72633 2.81995 1.94735V15.2807C2.81995 15.5017 2.90774 15.7137 3.06402 15.8699C3.2203 16.0262 3.43227 16.114 3.65328 16.114H3.93106V2.22512H15.0422V1.94735Z"
        fill="#CDCDCD"
      />
    </g>
    <defs>
      <clipPath id="clip_copy">
        <rect
          width="20"
          height="20"
          fill="white"
          transform="translate(0.59375 0.000976562)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default ExistingContract;

import React from 'react'
import Relation from '../../../assets/icons/relation 1.svg'
import Relation1 from '../../../assets/icons/image 124.svg'
import arrow from '../../../assets/icons/arrow 31.svg'
import { useLocation } from 'react-router-dom'
import fetchApi from '../../../_config/api'
import { useAuth0 } from '@auth0/auth0-react'
import { DEVELOPERROLE } from '../../../deps'
import { notification } from 'antd'

import useIntersectionObserver from './useIntersectionObserver';
const Banner = () => {
  
  const { loginWithRedirect } = useAuth0();
  const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("referrer");

  const onNavigateSignup = () => {
    if(referralCode != null && referralCode !== ""){
        fetchApi({
            method: "POST",
            url: `auth/validateReferer`,
            isDevApp: false,
            data: { referralCode },
        })
            .then((response) => {
                //console.log("ref--",response)
                if ( response.statusCode !== 200) {
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });

                }else {
                    const {emailId: referralEmail } = response?.doc;
                    loginWithRedirect({
                        screen_hint: 'signup',
                        appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                        userRole: DEVELOPERROLE,
                        userType: "INDIVIDUAL",
                        referralCode,
                        referralEmail
                    });
                }
            })
            .catch((err) => {
                // console.log(err);
            });
    }else{
        loginWithRedirect({
            screen_hint: 'signup',
            appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
            userRole: DEVELOPERROLE,
            userType: "INDIVIDUAL",
            referralCode: ""
        });
    }
};


const [ref, isInView] = useIntersectionObserver({
  root: null,
  rootMargin: '0px',
  threshold: 0.5,
});


  return (
    <div className='light-theme-banner-container'>
        <div className='faucetcontainer'>
          <div className='faucetlogo1'>
          <a href='/faucet' className='faucetlogo'>
            {/* <img src={Relation} className='faucetlogo-properties'/> */}
            <img src={Relation1} className='faucetlogo-properties1'/>
            <span className='faucetlogo-text'>Testnet<br/> Faucet</span>
        </a>
          </div>
        
        </div>
        
        <h1 ref={ref} className={`light-theme-banner ${isInView ?'animated animatedFadeInUp fadeInUp':'hidden'}`} >Simplify<span className='light-theme-banner11'> Blockchain Complexities</span> <br className="light-theme-hiddenbreaktag" /> and Web3 Adoption. </h1>
        <p className='light-theme-banner-para'>Our infrastructure and services lay the foundation for efficiently creating, managing, <br className="light-theme-hiddenbreaktag"  />and scaling decentralized applications in today's dynamic digital world. </p>
        <div className='light-theme-banner-button-container'>
           <button className='light-theme-banner-button' onClick={() => onNavigateSignup()}>Get Started <img src={arrow} height='15px' width='20px' style={{marginLeft:'5px'}}/></button>
           <a href='/contact-us'>
             <button className='light-theme-banner-button1' >Request a Demo <img src={arrow} height='15px' width='20px' style={{marginLeft:'5px'}}/></button>
             </a>
        </div>
        
    </div>
  )
}

export default Banner
import React from "react";

export const Google = ({ width = "120", height = "120" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 120 120"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M109.027 50.2075H105V50H60V70H88.2575C84.135 81.6425 73.0575 90 60 90C43.4325 90 30 76.5675 30 60C30 43.4325 43.4325 30 60 30C67.6475 30 74.605 32.885 79.9025 37.5975L94.045 23.455C85.115 15.1325 73.17 10 60 10C32.3875 10 10 32.3875 10 60C10 87.6125 32.3875 110 60 110C87.6125 110 110 87.6125 110 60C110 56.6475 109.655 53.375 109.027 50.2075Z"
      fill="#FFC107"
    />
    <path
      d="M15.765 36.7275L32.1925 48.775C36.6375 37.77 47.4025 30 60 30C67.6475 30 74.605 32.885 79.9025 37.5975L94.045 23.455C85.115 15.1325 73.17 10 60 10C40.795 10 24.14 20.8425 15.765 36.7275Z"
      fill="#FF3D00"
    />
    <path
      d="M60 110C72.9151 110 84.65 105.058 93.5226 97.02L78.0476 83.925C72.8589 87.871 66.5187 90.0052 60 90C46.995 90 35.9525 81.7075 31.7925 70.135L15.4875 82.6975C23.7625 98.89 40.5676 110 60 110Z"
      fill="#4CAF50"
    />
    <path
      d="M109.028 50.2075H105V50H60V70H88.2575C86.2855 75.541 82.7334 80.3829 78.04 83.9275L78.0475 83.9225L93.5225 97.0175C92.4275 98.0125 110 85 110 60C110 56.6475 109.655 53.375 109.028 50.2075Z"
      fill="#1976D2"
    />
  </svg>
);

import React from "react";
import { Discord } from "./discord";
import { Github } from "./github";
import { Google } from "./google";
import { LinkedIn } from "./linkedin";
import { Slack } from "./slack";
import { Twitter } from "./twitter";
import { DiscordWhite } from "./discordWhite";
import {Email} from "./email";
import {Telegram} from "./telegram";
import {FooterDiscord} from "./footerDiscord";
import {FooterTwitter} from "./footer_twitter";
import {FooterLinkedIn} from "./footer_linkedIn";
import {FooterYoutube} from "./footer_youtube";
import {Medium} from "./medium";

export const Logos = ({ type = "", ...attributes }) => {
  return (
    <>
      {(() => {
        switch (type?.toLowerCase()) {
          case "slack":
            return <Slack {...attributes} />;
          case "twitter":
            return <Twitter {...attributes} />;
          case "github":
            return <Github {...attributes} />;
          case "discord":
            return <Discord {...attributes} />;
          case "linkedin":
            return <LinkedIn {...attributes} />;
          case "google":
            return <Google {...attributes} />;
          case "discordWhite":
            return <DiscordWhite {...attributes} />;
          case "email":
            return <Email {...attributes} />;
          case "telegram":
            return <Telegram {...attributes} />;
          case "logo":
            return <Telegram {...attributes} />;
          case "footerdiscord":
            return <FooterDiscord {...attributes} />;
          case "footertwitter":
            return <FooterTwitter {...attributes} />;
          case "footerlinkedin":
            return <FooterLinkedIn {...attributes} />;
          case "footeryoutube":
            return <FooterYoutube {...attributes} />;
          case "medium":
            return <Medium {...attributes} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

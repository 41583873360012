import React from "react";
import {useLocation, useNavigate} from "react-router-dom";
import {useAuth0} from "@auth0/auth0-react";
import rightTickIcon from './Icons/Group 141.svg'
import fetchApi from "../../_config/api";
import {notification} from "antd";
import {DEVELOPERROLE} from "../../deps";
import { useInView } from "react-intersection-observer";


export const Pricingplan = ({refe}) => {
    const navigate = useNavigate();

    const onNavigate = () => {
        navigate("/contact-us");
        window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const onNavigatePlan = () => {
        // console.log("plan")
       navigate("/pricing");
       window.scrollTo({ top: 0, behavior: 'smooth' });
    };
    const { loginWithRedirect } = useAuth0();
    const location = useLocation();
    const searchParams = new URLSearchParams(location.search);
    const referralCode = searchParams.get("referrer");
    const onNavigateSignup = () => {
        if(referralCode != null && referralCode !== ""){
            fetchApi({
                method: "POST",
                url: `auth/validateReferer`,
                isDevApp: false,
                data: { referralCode },
            })
                .then((response) => {
                    //console.log("ref--",response)
                    if ( response.statusCode !== 200) {
                        notification.error({
                            message: response.message,
                            duration: 3,
                        });

                    }else {
                        const {emailId: referralEmail } = response?.doc;
                        loginWithRedirect({
                            screen_hint: 'signup',
                            appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: referralCode,referralEmail },
                            userRole: DEVELOPERROLE,
                            userType: "INDIVIDUAL",
                            referralCode,
                            referralEmail
                        });
                    }
                })
                .catch((err) => {
                    // console.log(err);
                });
        }else{
            loginWithRedirect({
                screen_hint: 'signup',
                appState: { role: DEVELOPERROLE, type: "INDIVIDUAL",referralCode: "" },
                userRole: DEVELOPERROLE,
                userType: "INDIVIDUAL",
                referralCode: ""
            });
        }
    };

    const { ref, inView } = useInView({
        triggerOnce: true, // Only trigger animation once
        threshold: 0.1 // Trigger animation when 50% of the element is visible
      });

  return (
    <>
      <div  className="pricingplan-wrapper" id="pricing_works">
        {/*<div className="container">*/}

          <div className="theme-heading">
              <h3 className="text-left">Start Building with Krypcore</h3><br/>
          </div>
          <div className="theme-heading1">
              {/*<p className="text-left"> Choose a plan tailored to <br className="hiddenbreaktag" /> your need - No Credit card required </p>*/}
          </div>
          <div ref={ref} className=" container-fluid p-0 pt-xl-3">
            <div className="row ">
              <div  className=" col-xl-4 mt-4">
                  <div  className={` ${inView?'plandetail-card animate fadeInUp1':"hidden"}`}>
                      {/*<span className="prisingTag">Starter</span>*/}
                      <p>
                          <span className="plan-credit-num">Starter</span>
                      </p>
                      <p className="pricingTextTag">Access a broad range of features and <br/> tools that empower your projects.</p>

                      <ul className="Starter-plan-basic-ul">
                          {/*<span className="d-flex items-center hidden"><img  src={rightTickIcon} alt="" className="mr-2"/>Starter Web3 Services</span>*/}
                          <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Starter-Package" className="mr-2"/>50,000 credits/month</li>
                          <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Starter-Package" className="mr-2"/>Build upto 5 dApps</li>
                          <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Starter-Package" className="mr-2"/>Support from community</li>
                          <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Starter-Package" className="mr-2"/>Access to starter suite of services</li>
                      </ul>
                      <span onClick={() => onNavigatePlan()} style={{cursor:'pointer'}}>View all</span>

                      <button className="contact-us-button-popular" onClick={() => onNavigateSignup()}>
                          Sign Up for Free
                      </button>


                  </div>

              </div>

              <div ref={ref} className=" col-xl-4 mt-4">
                  <div  className={` ${inView?'pro-plandetail-card animate fadeInUp1':"hidden"}`}>
                      {/*<span className="prisingTag">Developer</span>*/}
                      {/*<p><span className="plan-credit-num">$99</span> /month</p>*/}
                      <p><span className="plan-credit-num">Developer</span></p>
                      <p className="pricingTextTag">Unlock a suite of premium features <br/>  tailored for professional teams.
                      </p>


                      <ul className="Pro-plan-basic-ul">
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Developer-Package" className="mr-2"/>250,000 credits/month</li>
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Developer-Package" className="mr-2"/>Build upto 50 dApps</li>
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Developer-Package" className="mr-2"/>Priority support</li>
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Developer-Package" className="mr-2"/>Access to advanced suite of services</li>
                      </ul>
                      <span onClick={() => onNavigatePlan()} style={{cursor:'pointer'}}>View all</span>
                      <button
                          className="contact-us-button-popular" onClick={() => onNavigate()}
                      >
                          $99/ month
                      </button>
                  </div>

              </div>
              <div ref={ref} className=" col-xl-4 mt-4">
                  <div  className={`${inView?'plandetail-card  animate fadeInUp1':"hidden"}`}>

                 {/* <span className="prisingTag">Pro</span>*/}
                  <p><span className="plan-credit-num">
                  Enterprise</span> </p>
                      <p className="pricingTextTag">Need more? Get in touch with us to explore a <br/> fully tailored service that addresses your need.</p>


                      <ul className="Enterprise-plan-basic-ul">
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Enterprise-Package" className="mr-2"/>Custom credits/month</li>
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Enterprise-Package" className="mr-2"/>Custom dApp Limits</li>
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Enterprise-Package" className="mr-2"/>Priority Support</li>
                      <li className="d-flex items-center "><img  src={rightTickIcon} alt="Illustration-of-Enterprise-Package" className="mr-2"/>Access to complete suite of services</li>
                      </ul>
                    <span className="hidden" onClick={() => onNavigatePlan()} style={{cursor:'pointer'}}>View all</span>
                      <button className="contact-us-button-popular1" onClick={() => onNavigate()}>
                          Contact Us
                      </button>
                  </div>
              </div>
            </div>
          </div>

      </div>

    </>
  );
};

export default Pricingplan;

import React from "react";
import { ItemPerPage } from "./itemPerPage";
import { Pagination } from "./pagination";
import "./styles.less";

export const PaginationSection = ({
  page,
  first,
  last,
  hitsList,
  itemsPerPage,
  totalCount,
  onPageChange,
  onPerPageChange,
}) => {
  // console.log("totalCount",totalCount,itemsPerPage,Math.floor((totalCount + itemsPerPage - 1) / itemsPerPage))
  return (
    <>
      {totalCount > 1 ? (
        <div className="pagination-bottom-section">
          <ItemPerPage
            value={itemsPerPage}
            list={hitsList}
            first={first}
            last={last}
            total={totalCount}
            onChange={onPerPageChange}
          />
          <Pagination
            page={page}
            pages={Math.ceil(totalCount / itemsPerPage)}

            onChange={onPageChange}
          />
        </div>
      ) : null}
    </>
  );
};

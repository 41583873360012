import React from "react";

export const Slack = ({ width = "38px", height = "38px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 38 38"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip_slack)">
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M10.5557 23.9352C10.5557 21.7216 12.2295 19.9395 14.3087 19.9395C16.388 19.9395 18.0618 21.7216 18.0618 23.9352V33.7708C18.0618 35.9845 16.388 37.7666 14.3087 37.7666C12.2295 37.7666 10.5557 35.9845 10.5557 33.7708V23.9352Z"
        fill="#E01E5A"
        stroke="#E01E5A"
        strokeWidth="0.45679"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.9342 27.4456C21.7206 27.4456 19.9385 25.7717 19.9385 23.6925C19.9385 21.6133 21.7206 19.9395 23.9342 19.9395H33.7699C35.9835 19.9395 37.7656 21.6133 37.7656 23.6925C37.7656 25.7717 35.9835 27.4456 33.7699 27.4456H23.9342Z"
        fill="#ECB22D"
        stroke="#ECB22D"
        strokeWidth="0.45679"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9385 4.2301C19.9385 2.01648 21.6124 0.234375 23.6916 0.234375C25.7708 0.234375 27.4446 2.01648 27.4446 4.2301V14.0658C27.4446 16.2794 25.7708 18.0615 23.6916 18.0615C21.6124 18.0615 19.9385 16.2794 19.9385 14.0658V4.2301Z"
        fill="#2FB67C"
        stroke="#2FB67C"
        strokeWidth="0.45679"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M4.2301 18.0613C2.01648 18.0613 0.234375 16.3875 0.234375 14.3083C0.234375 12.2291 2.01648 10.5552 4.2301 10.5552H14.0658C16.2794 10.5552 18.0615 12.2291 18.0615 14.3083C18.0615 16.3875 16.2794 18.0613 14.0658 18.0613H4.2301Z"
        fill="#36C5F1"
        stroke="#36C5F1"
        strokeWidth="0.45679"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M19.9375 34.0129C19.9365 34.506 20.0328 34.9945 20.2211 35.4503C20.4093 35.9062 20.6857 36.3203 21.0344 36.669C21.3831 37.0178 21.7973 37.2942 22.2531 37.4824C22.7089 37.6706 23.1974 37.767 23.6906 37.7659C25.7698 37.7659 27.4437 36.0921 27.4437 34.0129C27.4447 33.5197 27.3484 33.0312 27.1601 32.5754C26.9719 32.1196 26.6955 31.7054 26.3468 31.3567C25.9981 31.008 25.5839 30.7316 25.1281 30.5433C24.6723 30.3551 24.1838 30.2587 23.6906 30.2598H19.9375V34.0129Z"
        fill="#ECB22D"
        stroke="#ECB22D"
        strokeWidth="0.45679"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M34.0119 18.0631H30.2588V14.31C30.2588 12.2308 31.9327 10.5569 34.0119 10.5569C36.0911 10.5569 37.765 12.2308 37.765 14.31C37.765 16.3892 36.0911 18.0631 34.0119 18.0631Z"
        fill="#2FB67C"
        stroke="#2FB67C"
        strokeWidth="0.45679"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M3.98649 19.9385H7.73958V23.6916C7.73958 25.7708 6.0657 27.4446 3.98649 27.4446C3.49334 27.4457 3.00483 27.3493 2.54901 27.1611C2.09319 26.9729 1.67903 26.6965 1.33032 26.3477C0.9816 25.999 0.70519 25.5849 0.516952 25.129C0.328714 24.6732 0.232356 24.1847 0.233407 23.6916C0.233407 21.6124 1.90728 19.9385 3.98649 19.9385Z"
        fill="#E01E5A"
        stroke="#E01E5A"
        strokeWidth="0.45679"
        strokeLinecap="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M18.0609 3.98893V7.74202H14.3078C13.8146 7.74307 13.3261 7.64671 12.8703 7.45847C12.4145 7.27024 12.0003 6.99383 11.6516 6.64511C11.3029 6.29639 11.0265 5.88224 10.8382 5.42642C10.65 4.9706 10.5536 4.48209 10.5547 3.98893C10.5547 1.90972 12.2286 0.235848 14.3078 0.235848C14.8009 0.234797 15.2894 0.331156 15.7453 0.519394C16.2011 0.707631 16.6152 0.984041 16.964 1.33276C17.3127 1.68147 17.5891 2.09563 17.7773 2.55145C17.9656 3.00727 18.0619 3.49578 18.0609 3.98893Z"
        fill="#36C5F1"
        stroke="#36C5F1"
        strokeWidth="0.45679"
        strokeLinecap="round"
      />
    </g>
    <defs>
      <clipPath id="clip_slack">
        <rect width="38" height="38" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

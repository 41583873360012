import React, {useRef, useState} from "react";
import '../demoStyles.less'

export const Banner = () => {

  return (
    <>
      <div className="Bannersection-wrap" id="home">
        <div className="container">
          <div className="col-md-10 mx-auto banner-head-container">
            <h1 className="banner-heading">
              Krypcore Web3 <span className="banner-highlight">Experience/Demo</span>
            </h1>
          </div>
        </div>
        <div className="experience-card-container">
          <div className="experience-card">
            <p>Card 1 content goes here.</p>
            <button className="launch-btn">Launch</button>
          </div>
          <div className="experience-card">
            <p>Card 2 content goes here.</p>
            <button className="launch-btn">Launch</button>
          </div>
          <div className="experience-card">
            <p>Card 3 content goes here.</p>
            <button className="launch-btn">Launch</button>
          </div>
          <div className="experience-card">
            <p>Card 4 content goes here.</p>
            <button className="launch-btn">Launch</button>
          </div>
        </div>
      </div>
    </>
  );
};

export default Banner;

import React from "react";
import {Input} from "antd";
import { CopyOutlined } from "@ant-design/icons";
import "./styles.less";
import {getPATTokenDetails, getUserDetails} from "../../deps";
import fetchApi from "../../_config/api";
import Skeleton from "react-loading-skeleton";

export const AccountInfoInfo = ({ text,tooltip, item, onCopy, onResetApi, onExport,isLoading }) => {
    const userDetails = getUserDetails()
    const [credit, setMonthlyCredit] = React.useState("");
    const pattoken = getPATTokenDetails();


    const  getSubscription = () =>{
        return fetchApi({
            method: "POST",
            url: `subscription/get`,
            isDevApp: false,
        }).then((body) => {
            if (body.statusCode === 200 || body.statusCode === 201){
                const { doc = [] } = body;
                if(doc !== null) {
                    setMonthlyCredit(doc.planDetails.credits.value);
                }
            }
        });
    }

    React.useEffect(() => {
        getSubscription();

    }, []);


    return (
        <div>
            <div className="container-text marginBtm15">
                <div className="text">{text}</div>
            </div>
            {isLoading?(<Skeleton height={320}  baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
                <div className="project-info-container" style={{height: "320px"}}>
                    <div className="account-label">User Name <span className="float-right">{userDetails.profile.firstName}</span></div>
                    <div className="account-label">Credit Limit<span className="float-right">{credit}</span></div>
                    <div className="account-label">Subscription ID</div>
                    <div className="apiKey-section marginBtm15">
                        <Input className="input-visible" readOnly={true} value={userDetails.profile.subscriptionId} />
                        <CopyOutlined onClick={() => onCopy(userDetails.profile.subscriptionId)} />
                    </div>
                    <div className="account-label">Auth Token</div>
                    <div className="apiKey-section">
                    <Input.Password readOnly={true} value={pattoken.patToken !== ""?pattoken.patToken:""} />
                    <CopyOutlined onClick={() => onCopy(pattoken.patToken)} />
                    </div>
            </div>
            )}
    </div>
);
};

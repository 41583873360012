import React, {useEffect, useState} from "react";
import {PaginationSection, TableContent} from "../../../library";
import {columns} from "./columns";
import {Link, useLocation, useNavigate} from "react-router-dom";
import {AccountSettings} from "../../../assets/menus/accountSettings";
import {CrossIcon, GearIcon } from "../../../assets/icons";
import {Menus} from "../../../assets";
import {Modal, notification} from "antd";
import AddMember from "./AddMember";
import AssignProject from "./AssignProject";
import assignProject from "./AssignProject";
import fetchApi from "../../../_config/api";


export const ManageTeam = ({ showLoader, isLoading }) => {
    const [teamData, setTeamData] = useState([]);
    const [assignData, setAssignData] = useState([]);
    const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false); // State to control delete confirmation dialog
    const location = useLocation();
    let teamId = "", teamName = ""
    if (location.state != null) {
        teamName = location.state.teamName;
        teamId = location.state.teamId;
    }
    const [isOpen, setIsOpen] = useState(false);
    const [openAddMember, setOpenAddMember] = useState(false);
    const [openAssignProject, setOpenAssignProject] = useState(false);
    const [devTotalCount, setDevTotalCount] = useState(0);
    const [projTotalCount, setProjTotalCount] = useState(0);
    const handleDeleteTeam = () => {
        // Implement delete team logic here
        // For now, just show delete confirmation dialog
        setShowDeleteConfirmation(true);
    };
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const [hitsList, setHitsList] = useState([]);
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [pageSettingForProjects, setPageSettingForProjects] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const [hitsListForProjects, setHitsListForProjects] = useState([]);
    const startIndexForProject = (pageSettingForProjects.page - 1) * pageSettingForProjects.itemsPerPage;
    const navigate = useNavigate();
    React.useEffect(() => {
        getTeamMembers();
        getAssignProjects();
        setHitsList([5, 10, 15, 20]);
        setHitsListForProjects([5, 10, 15, 20]);
    }, []);
    const getTeamMembers = () => {
        const filter = {value:teamId,status:""}
        const Options = () => {
            // setHitsList([5, 10, 15, 20]);
            return {
                select: [],
                sort: {},
                page: pageSetting.page - 1,
                limit: pageSetting.itemsPerPage,
            };
        };
        let requestLink = `teams/get-team-members?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({ method: "GET", url: requestLink, isDevApp: false })
            .then((response) => {
                const { docs = [] } = response;

                if(docs[0]?.data.length > 0){
                    setTeamData(docs[0]?.data);
                    setDevTotalCount(response.totalCount);
                }else{
                    setTeamData([]);
                }
            })
            .catch(() => {
                setTeamData([]);
            });
    }

    const getAssignProjects = () => {
        const filter = {value:teamId,status:""}
        const Options = () => {
            // setHitsListForProjects([5, 10, 15, 20]);
            return {
                select: [],
                sort: {},
                page: pageSettingForProjects.page - 1,
                limit:  pageSettingForProjects.itemsPerPage,
            };
        };

        let requestLink = `teams/get-team-projects?options=${JSON.stringify(
            Options()
        )}&filter=${JSON.stringify(filter)}`;

        fetchApi({ method: "GET", url: requestLink, isDevApp: false })
            .then((response) => {
                const { docs = [] } = response;
                console.log("docs---->",docs[0]);
                if(docs[0]?.data.length > 0){
                    setAssignData(docs[0]?.data);
                    setProjTotalCount(response.totalCount);
                    console.log("setProjTotalCount---->",projTotalCount);
                }else{
                    setAssignData([]);
                    setProjTotalCount(0);
                }
            })
            .catch(() => {
                setAssignData([]);
                setProjTotalCount(0);
            });
    }
    useEffect(() => {
        setDevTotalCount(devTotalCount);
    }, [teamData]);
    useEffect(() => {
        setProjTotalCount(projTotalCount);
    }, [assignData]);
    const handleConfirmDelete = () => {
        // // Implement delete team logic here
        // // After deletion, close the confirmation dialog
        setShowDeleteConfirmation(false);
        showLoader(true);
        const data = {};
        data.teamId = teamId;
        fetchApi({
            method: "POST",
            url: `teams/remove-team`,
            data: data,
            isDevApp: false,
        })
            .then((response) => {
                if (response.statusCode === 200 || response.statusCode === 201) {
                    showLoader(false);
                    notification.success({
                        message: "Team deleted successfully",
                        duration: 3,
                    });
                    toggleAddMember();
                    navigate("/teams");
                }else{
                    showLoader(false);
                    notification.error({
                        message: response.message,
                        duration: 3,
                    });
                }
                toggleAddMember();
            })
            .catch((err) => {
                showLoader(false);
            });
    };

    const handleCancelDelete = () => {
        // Close the confirmation dialog
        setShowDeleteConfirmation(false);
    };


    const columns = [
        {
            title: "Email",
            dataIndex: "email",
        },
        {
            title: "Roles",
            dataIndex: "roleName",
        },
        {
            title: "Credits Used",
            dataIndex: "creditsUsed",
            render: (_, { _id }) => (
                <>
                    <span>0</span>
                </>
            ),
        },
        {
            title: "Credit Limit",
            dataIndex: "creditLimit",
            render: (_, { _id }) => (
                <>
                    <span>0</span>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, { _id }) => (
                <>
                    {/*<Link to={"/manage-teams"} state={{ id: _id }}>*/}
                        Manage&nbsp;&nbsp;&nbsp;
                        <AccountSettings width="20px" height="20px" />
                    {/*</Link>*/}
                </>
            ),
        }
    ];
    const projectColumns = [
        {
            title: "Project Name",
            dataIndex: "projects.projectName",
            render: (_, { projects }) => (
                <>
                    <span>{projects.projectName}</span>
                </>
            ),
        },
        {
            title: "Project Id",
            dataIndex: "projects.projectId",
            render: (_, { projects }) => (
                <>
                    <span>{projects.projectId}</span>
                </>
            ),
        },
        {
            title: 'Action',
            dataIndex: '',
            render: (_, { _id }) => (
                <>
                    <Link to={"/manage-teams"} state={{ id: _id }}>
                        Manage&nbsp;&nbsp;&nbsp;
                        <AccountSettings width="20px" height="20px" />
                    </Link>
                </>
            ),
        }
    ];
    const toggleState = () => {
        setIsOpen(!isOpen);
    }
    const toggleAddMember = () => {
        setOpenAddMember(!openAddMember);
    }
    const toggleAssignProject = () => {
        setOpenAssignProject(!assignProject);
    }
    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        getTeamMembers();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        getTeamMembers();
    };
    const last =
        pageSetting.page * pageSetting.itemsPerPage > devTotalCount
            ? devTotalCount
            : pageSetting.page * pageSetting.itemsPerPage;
    const onPageChangeForProject = (pageNumber) => {
        pageSettingForProjects.page = Number(pageNumber);
        setPageSettingForProjects({ ...pageSettingForProjects });
        getAssignProjects();
    };

    const onPerPageChangeForProject = (value) => {
        pageSettingForProjects.itemsPerPage = Number(value);
        pageSettingForProjects.page = 1;
        setPageSettingForProjects({ ...pageSettingForProjects });
        getAssignProjects();
    }
    const lastForProject =
        pageSettingForProjects.page * pageSettingForProjects.itemsPerPage > projTotalCount
            ? projTotalCount
            : pageSettingForProjects.page * pageSettingForProjects.itemsPerPage;

    return (
        <>

            <Modal style={{width:"600px"}} bodyStyle={{minHeight:"200px",width:"600px",marginLeft: "350px"}} maskStyle={{backgroundColor: '#CFCFCF80'}} centered title="" footer={null}
                   closable={false}
                   open={isOpen}>


                        <div className="close-icon" onClick={toggleState}>
                            <Menus type="close"/>
                        </div>
                        {/* Confirmation dialog */}
                        {/*{showDeleteConfirmation && (*/}
                        <div className="confirmation-dialog">
                            <p>Are you sure you want to delete team?</p>
                            <div className="confirmation-button">
                                <button onClick={handleConfirmDelete}>Delete</button>
                                <button onClick={handleCancelDelete}>Cancel</button>
                            </div>
                        </div>



                        {/*)}*/}
            </Modal>

            <div className="manage-teams-button-container">

                <div className="delete-button">
                    {/* Button 1: Delete Team */}
                    <button className="button-with-icon" onClick={()=>{setIsOpen(true)}}>
                        <span className="delete-icon"></span> Delete Team
                    </button>
                </div>
                <div className="add-member-button">
                    {/* Button 2: Manage Limits */}
                    <button className="button-with-icon" onClick={()=> {setOpenAddMember(true)}}>
                        <span className="add-member-icon"></span> Add Member
                    </button>
                </div>
                <div className="manage-limit-button">
                    {/* Button 2: Manage Limits */}
                    <button className="button-with-icon">
                        <span className="manage-limit-icon"></span> Manage Limit
                    </button>
                </div>
            </div>
            <div className="text">{teamName}</div>
            <div className="table-container" style={{marginBottom: "50px"}}>
                <TableContent
                    rowKey="nft"
                    data={teamData}
                    columns={columns}
                    isDashboard={false}
                    pagination={false}
                />
                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={hitsList}
                    totalCount={devTotalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.itemsPerPage}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />
            </div>

            <div>

                <button className="assign-project-button" onClick={()=> {setOpenAssignProject(true)}}>
                    Assign Project
                </button>

            </div>


            <div className="text">Assigned Projects</div>
            <div className="table-container">
                <TableContent
                    rowKey="projects"
                    data={assignData}
                    columns={projectColumns}
                    isDashboard={false}
                    pagination={false}
                />
                <PaginationSection
                    last={lastForProject}
                    first={startIndexForProject + 1}
                    hitsList={hitsListForProjects}
                    totalCount={projTotalCount}
                    page={pageSettingForProjects.page}
                    itemsPerPage={pageSettingForProjects.itemsPerPage}
                    onPageChange={onPageChangeForProject}
                    onPerPageChange={onPerPageChangeForProject}
                />
            </div>
            <AddMember
                isOpen={openAddMember}
                toggleState={toggleAddMember}
                teamId={teamId}
                reload={getTeamMembers}
            />
            <AssignProject
                isOpen={openAssignProject}
                toggleState={toggleAssignProject}
                reload={getAssignProjects}
                teamId={teamId}
            />
        </>
    );
}
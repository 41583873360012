import React from 'react'
import './NewLandingPage.less'
import { ContactForm } from './contactForm'
import Header from '../new-light-theme/ui/Header'
import Footer from '../new-light-theme/ui/Footer'

const ContactUs = () => {
  return (
    <div style={{backgroundColor:'black'}}>
        <Header/>
      <ContactForm/>
        <Footer/>

    </div>
  )
}

export default ContactUs
import React from "react";
import { Steps } from "antd";
import { Input, Space } from "antd";
import { Slider } from "antd";
import { notification } from "antd";
import fetchApi from "../../_config/api";
import { getUserDetails } from "../../deps";

const { Step } = Steps;
notification.config({ duration: 2 });

export const CreateInstance = ({ step = 0, modalState, reloadInstance }) => {
  const {
    profile: { enterpriseId },
  } = getUserDetails();
  const [stepSize, setStepSize] = React.useState(step);
  const [budjetValue, setBudjetValue] = React.useState(0);
  const [maxBudjetValue, setMacxbudjetValue] = React.useState(250);
  const [teams, setTeams] = React.useState([]);
  const [instancedata, setInstancedata] = React.useState({
    name: "",
    teamId: "",
  });

  React.useEffect(() => {
    getActiveTeamsList();
  }, []);

  const getActiveTeamsList = () => {
    const filter = {
      and: [
        {
          key: "teamLock",
          value: false,
          opt: "eq",
        },
      ],
    };

    const Options = () => {
      return {
        select: "",
        sort: "",
        skip: 0,
        limit: 100,
      };
    };

    let requestLink = `teams/?options=${JSON.stringify(
      Options()
    )}&filter=${JSON.stringify(filter)}`;
    fetchApi({ method: "GET", url: requestLink, isDevApp: false })
      .then((response) => {
        const { docs = [] } = response;
        setTeams(response.docs);
      })
      .catch((error) => {
        notification.error({
          message: error.toString(),
          duration: 3,
        });
      });
  };

  const onChange = (name, value) => {
    setInstancedata({
      ...instancedata,
      [name]: value,
    });
  };

  const nextStep = () => {
    setStepSize(1);
    if (stepSize === 1) {
      onCreateInstance();
    }
  };

  const backStep = () => {
    setStepSize(0);
  };

  const onCreateInstance = () => {
    let data = {
      ...instancedata,
      allocatedBudget: budjetValue,
      enterpriseRef: enterpriseId,
      enterprise: enterpriseId,
      serviceRef: "smart-contract",
    };
    fetchApi({
      method: "POST",
      // url: "smart-contract-instance",
      url: "smart-contract-studio/get-developer-instance",
      isDevApp: false,
      data: data,
    })
      .then((response) => {
        if (response.statusCode === 201) {
          notification.success({
            message: "successfully Created",
            duration: 2,
          });
        } else {
          notification.error({ message: response.message, duration: 2 });
        }

        modalState(false);
        reloadInstance();
        setStepSize(0);
        setBudjetValue(0);
        setMacxbudjetValue(250);
        setTeams([]);
        setInstancedata({
          name: "",
          teamId: "",
        });
      })
      .catch((error) => {
        notification.error({
          message: error.toString(),
          duration: 3,
        });
      });
  };

  return (
    <>
      <div className="popup-request-container">
        <div className="nft-instance-create">
          <Steps current={stepSize} size="large">
            <Step title="Instance Details" />
            <Step title="Allocate Budget" />
          </Steps>
        </div>
        {stepSize == 0 && (
          <InstanceDetails
            onChange={onChange}
            teams={teams}
            data={instancedata}
          />
        )}
        {stepSize == 1 && (
          <IconSlider
            setBudjetValue={setBudjetValue}
            budjetValue={budjetValue}
            max={maxBudjetValue}
            min={0}
          />
        )}
        <div className="btn-section mt-4 nft-instance-spacing">
          <button className="cancel-btn" onClick={backStep}>
            Back
          </button>
          <button className="ok-btn" onClick={nextStep}>
            {stepSize == 1 ? "Create" : "Continue"}
          </button>
        </div>
      </div>
    </>
  );
};

export const InstanceDetails = ({ onChange, teams = [], data }) => {

  const onTextChange = (e) => {
    onChange(e.target.name, e.target.value);
  };


  return (
    <div className="column-add-project-container mt-4">
      <Space direction="vertical" size={20}>
        <Input
          style={{ background: "#2e2e2e" }}
          className="input-container"
          placeholder="Instance Name"
          name="name"
          onChange={onTextChange}
          value={data.name}
        />
        <select
          style={{ width: "100%" }}
          className="dropdown-filter mint-nft-select"
          name="teamId"
          onChange={onTextChange}
        >
          <option value="">Select Team</option>
          {teams.length > 0 ?
            teams.map((team) => (
              <option value={team._id}>{team.name}</option>
            )) : null}

        </select>
      </Space>
    </div>
  );
};

export const IconSlider = (
  { setBudjetValue, budjetValue, min = 0, max = 400 },
  props
) => {
  const marks = {
    [`${min}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{min}</strong>,
    },
    [`${max}`]: {
      style: {
        color: "#d5d5d5",
        marginTop: "15px",
      },
      label: <strong>{max}</strong>,
    },
  };
  return (
    <div className="icon-wrapper mt-4">
      <Slider
        marks={marks}
        min={min}
        max={max}
        {...props}
        onChange={setBudjetValue}
        value={budjetValue}
      />
      <div className="desc-text mt-5">{budjetValue}-Allocate Budget For your Instance</div>
    </div>
  );
};

import React from "react";
import "./style.less"
import FaucetPage from "./FaucetPage";
import Header from "../new-light-theme/ui/Header";
import Footer from "../new-light-theme/ui/Footer";
import '../new-light-theme/lighttheme.less'

const FaucetLandingPage = () => {
    return (
        <div className="bg-light2">
            <Header />
            <FaucetPage/>
            <Footer />
        </div>
    );

}
export default FaucetLandingPage 
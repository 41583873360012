import React, {useEffect, useState} from "react";
import {PaginationSection, TableContent} from "../../../../library";
import {setUp_column} from "../column";
import {CreateDataLakeSetup} from "./create-setup";
import fetchApi from "../../../../_config/api";
import '../styles.less';
import {getUserDetails} from "../../../../deps";
import {Form, Input, notification, Spin, Tooltip} from "antd";
import {LoadingOutlined} from "@ant-design/icons";
import Skeleton from "react-loading-skeleton";
import FilterDropdown from "../utility/filterDropDown";

export const DataLakeSetup = ({ showLoader,isLoading }) => {
    const userDetails = getUserDetails()
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
    });

    const [setupHistory, setSetupHistory] = useState([]);
    const [selectedSetup, setSelectedSetupDetails] = useState([]);
    const [toggleAddSetup, setToggleAddSetup] = useState(false);
    const [isUpdate, setIsUpdate] = useState(false);
    const [pageLoad, setPageLoad] = useState(false);
    const [filteredHistory, setFilteredHistory] = useState([]);
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const [totalCount, setTotalCount] = useState(-1);
    const [disableAddIntegrationButton, setDisableAddIntegrationButton] = useState(false);
    const [addIntegrationLoaderBtn, setAddIntegrationLoaderBtn] = React.useState(false);
    const [displayURLSetup, setDisplayURLSetup] = React.useState(false);

    const [dataLakeAddOnPlan, setDataLakeAddOnPlan] = useState({});
    const [modalType, setModalType] = useState("");
    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;
    const [isSaveDisabled, setIsSaveDisabled] = useState(true);
    const [form] = Form.useForm();

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });

    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
    };

    const displayClickedDetails = (data) =>{
        setSelectedSetupDetails(data)
        setToggleAddSetup(true)
        setIsUpdate(true)
    }

    const toggleAddSetupModel = (type) =>  {
        setToggleAddSetup(!toggleAddSetup)
        setIsUpdate(false)
        if(type === "url"){
            setModalType("url");
        }else if(type === "address"){
            setModalType("address");
        }
    }

    const syncDataFromAllDBS = () => {
        setAddIntegrationLoaderBtn(true)
        fetchApi({ method: "POST", url: "dataLake/syncDataLakeToLatest"})
            .then((response) => {
                if (response?.statusCode == 200){
                    notification.success({
                        message: response.message,
                        duration: 3,
                    });
                }else {
                    notification.error({
                        message: "Failed to sync the data",
                        description: response.message,
                        duration: 3,
                    });
                }
                setAddIntegrationLoaderBtn(false)
            })
            .catch((error) => {
                throw error;
            });
    };

    const getSetup = (searchTest) => {
        fetchApi({ method: "POST", url: "dataLake/getSetup", data:{searchText:searchTest} })
            .then((response) => {
                response?.statusCode == 200 ? setSetupHistory(response?.docs):setSetupHistory([])
                setTotalCount(response.docs.length)

                const filteredHistory = response.docs.filter((item) => item.status === "true");
                setFilteredHistory(filteredHistory)

            })
            .catch((error) => {
                throw error;
            });
    };

    const getSubscription = () => {
        return fetchApi({
            method: "POST",
            url: `subscription/get`,
            data: JSON.stringify({ subscriptionId: userDetails.profile.subscriptionId }),
            isDevApp: false,
        }).then((body) => {
            if (body.statusCode === 200 || body.statusCode === 201) {
                const services = body.doc?.planDetails?.services;
                const dataLakeService = services.find(
                    (service) => service.key === "dataLake"
                );

                setDataLakeAddOnPlan(body.doc?.planDetails?.addonFeatures)

                if (dataLakeService && !dataLakeService.isRequired) {
                    notification.error({
                        message: "You are not subscribed to Data Lake.",
                        description: "Please subscribe to Data Lake to access the features.",
                        duration: 4,
                    });
                    setDisableAddIntegrationButton(!dataLakeService.isRequired)
                }
            }
        });
    };



    useEffect( () => {
        getSetup()
        getSubscription()
    },[pageLoad])

    const callBack = () =>{
        setToggleAddSetup(false)
        setPageLoad(!pageLoad)
    }

    const validateUrl = async () => {
        let url =  selectedSetup.dbUrl
        url = form.getFieldValue("dbUrl")
        fetchApi({ method: "POST", url: "dataLake/validateDBUrl", data: { dbUrl: url } })
            .then((response) => {
                if (response.statusCode === 200) {
                    setIsSaveDisabled(true);
                    setDisplayURLSetup(false);
                    notification.success({
                        message: "Connection Successful",
                        duration: 3,
                    });
                    form.setFieldValue("dbUrl", url)
                } else {
                    setIsSaveDisabled(true);
                    setDisplayURLSetup(false);
                    notification.error({
                        message: "Connection failed. MongoDB URL is not reachable.",
                        duration: 3,
                    });
                }
            })
            .catch((error) => {
                setIsSaveDisabled(true);
                setDisplayURLSetup(false);
                notification.error({
                    message: "Connection failed. MongoDB URL is not reachable.",
                    duration: 3,
                });
            });

    };


    const antIcon = (
        <LoadingOutlined
            style={{
                fontSize: 24,
            }}
            spin
        />
    );
    const handleDbUrlChange = (e) => {
        if(e.target.value !== ""){
            setIsSaveDisabled(false);
        }else{
            setIsSaveDisabled(true);
        }
    };
    return(
        <>
            <div className="transaction-page-container">
            {/*{isLoading?(<Skeleton height={50} style={{marginBottom:'10px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(<>*/}
                {/*<div className="transaction-header-section">*/}
                {/*    <div className="header-section">*/}
                        {/*<div className="text">Current Configuration</div>*/}
                        {/*<div className=""><i className="ant-form-item-explain-warning"><b>Notice</b> : User data will be stored in default storage only for*/}
                        {/*    a period of time upto {dataLakeAddOnPlan?.datalake?.value || 100} records, Users are advised add their*/}
                        {/*    database to the system by following "add integration" button</i></div>*/}
                {/*    </div>*/}
                {/*    <div className="deploy-nft-collection">*/}
                {/*        {totalCount > 0 &&(*/}
                {/*            <Tooltip title="Transfer all data from the default storage/deactivated database instance to the current active database instance!">*/}
                {/*                <button  className="refresh-collections" onClick={syncDataFromAllDBS}>*/}

                {/*                    {!addIntegrationLoaderBtn ? (*/}
                {/*                        "Sync"*/}
                {/*                    ) : (*/}
                {/*                        <Spin indicator={antIcon} />*/}
                {/*                    )}*/}

                {/*                </button>*/}
                {/*            </Tooltip>*/}
                {/*        )}*/}
                {/*        <button className="gradient-button" onClick={toggleAddSetupModel}  disabled={disableAddIntegrationButton} >*/}
                {/*            Add Integration*/}
                {/*        </button>*/}
                {/*    </div>*/}
                {/*</div>*/}
                {/*</>)}*/}

                <div className="table-container marginBtm50 mt-5">
                    <div className="transaction-header-section">
                        <div className="header-section" style={{flexDirection:"row"}}>
                            <div  style={{fontWeight:'400',fontSize:'24px',color:'white'}}>Saved Configurations</div>
                        </div>
                        <div className="url-container">
                            {!displayURLSetup ?
                                <button className="url-container-btn1" onClick={() => setDisplayURLSetup(true)}
                                >URL Setup</button>
                                :
                                <div style={{display: "flex", justifyContent: "space-between", position: "relative"}}>
                                    <Form.Item
                                        name="dbUrl"
                                        rules={[
                                            {
                                                required: true,
                                                message: "Missing DB Url",
                                            },
                                        ]}
                                        style={{flex: 1}}
                                    >
                                        <div style={{position: "relative"}}>
                                            <Input
                                                className="datalake-url-input-container"
                                                style={{
                                                    background: "#2e2e2e",
                                                    color: "#bfbfbf",
                                                    paddingRight: "100px",
                                                    width:"100px !important"
                                                }}
                                                placeholder="DB URL"
                                                onChange={handleDbUrlChange}
                                            />
                                            <button
                                                disabled={isSaveDisabled}
                                                className={isSaveDisabled ? "datalake-url-validate-disabled-button" : "datalake-url-validate-button"}
                                                onClick={validateUrl}
                                                type="button"
                                            >
                                                <span className="validate-text">Validate</span>
                                            </button>
                                        </div>
                                    </Form.Item>
                                </div>
                            }
                            <button className="url-container-btn2" onClick={() => toggleAddSetupModel("address")}
                                    disabled={disableAddIntegrationButton}>Address Setup
                            </button>
                        </div>
                    </div>
                    {isLoading ? (
                        <Skeleton height={200} style={{marginBottom: '10px', marginTop: '30px'}} baseColor="#262626"
                                  highlightColor="#404040"></Skeleton>) : (
                        <TableContent
                            data={setupHistory}
                            columns={setUp_column(displayClickedDetails)}
                            isDashboard={false}
                            pagination={false}
                        />
                    )}
                    <PaginationSection
                        last={last}
                        first={startIndex + 1}
                        hitsList={[5, 10, 15, 20, 25]}
                        totalCount={totalCount}
                        page={pageSetting.page}
                        itemsPerPage={pageSetting.itemsPerPage}
                        onPageChange={onPageChange}
                        onPerPageChange={onPerPageChange}
                    />
                </div>
            </div>

            <CreateDataLakeSetup
                type={modalType}
                setupDetails={selectedSetup}
                callbackUrl={callBack} isUpdate={isUpdate}
                title={"Datalake Address Setup"}
                isModalOpen={toggleAddSetup}
                setModalState={toggleAddSetupModel}
            />

        </>
    )
}

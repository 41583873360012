import React, { useState } from 'react'
import {Modal, Space, Input, Form, notification, Spin, Select, Switch} from "antd";
import { LoadingOutlined } from "@ant-design/icons";
import { Menus } from '../../../assets';

const CreateDapp = (
    {
        isOpen,
        toggleState,
    }
) => {
    const [ipfsValue,setIpfsValue] = useState(0)

    const onDecrement=()=>{
        if(ipfsValue>0){
            setIpfsValue((prev)=>prev-1)
        }
        
    }
    const onIncrement=()=>{
        setIpfsValue((prev)=>prev+1)
    }
  return (
    <>
       <Modal title="" footer={null} closable={false} open={isOpen}>
                <div className="nft-collection-popup endpoint-popup">
                    <div className="close-icon" onClick={toggleState}>
                        <Menus type="close" />
                    </div>
                    <div className="popup-text d-inline">
                        <p className="popup-text-endpoint">Create Endpoint</p>
                    </div>
                    <Space
                        direction="vertical"
                        size={25}
                    >
                        <Form
                            name="basic"
                            // ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onCreateEndpoint}
                            autoComplete="off"
                        >
                          <div className='create-dapp-form-row'>
                            
                          <Form.Item name="teamID"
                                rules={[
                                     {
                                        //  required: isEnterpriseAdmin,
                                         message: "Missing Team",
                                     },
                                 ]}
                            >
                                <>
                                    <select
                                        style={{fontSize:"16px"}}
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Team"
                                        name="teamID"
                                        // onChange={(event) => onChangeSelectEndPoint(event, "teamID")}
                                    >
                                        <option key={"00"} value={""}>
                                            Choose Chain
                                        </option>
                                        {/* {teamList.map((team, ind) => (<option key={team.teamId} value={team.teamId}>{team.name}</option>))} */}
                                    </select>
                                </>
                            </Form.Item>
                            <Form.Item name="network" rules={[
                                    {
                                        required: true,
                                        message: "Missing Network Name",
                                    },
                                ]}>
                                    <>
                                        <select  style={{fontSize:"16px"}} className="dropdown-filter create-collection-drop" placeholder="Network"
                                                name="network"
                                                // onChange={(event) => onChangeSelectEndPoint(event, "network")}
                                        >
                                            <option key={"00"} value={""}>
                                                Select Network
                                            </option>
                                            {/* {
                                                networkList.map((obj, ind) => (

                                                    <option key={obj.network} value={obj.network}>
                                                        {obj.network}
                                                    </option>
                                                ))
                                            } */}

                                        </select>
                                    </>
                                </Form.Item>
                            
                           

                          </div>
                          <div className='create-dapp-form-row' style={{marginBottom:'30px'}}>
                          
                            <div style={{display:'flex',alignItems:'center'}}>
                            <label class="switch">
                                <input type="checkbox"/>
                                <span class="slider round"></span>
                                </label>
                                <span className='subheader-new'>IPFS</span>
                            </div>
                            <div className='createdappbtn-container'>
                                <span className='createdappbtn-container-btn' onClick={onDecrement}>-</span>
                                <span className='createdappbtn-container-number'>{ipfsValue}</span>
                                <span className='createdappbtn-container-btn1' onClick={onIncrement}>+</span>
                            </div>
                          </div>

                          <div className='create-dapp-form-row'>
                           
                            <Form.Item name="protocolName" rules={[
                                {
                                    required: true,
                                    message: "Missing Protocol Name",
                                },
                            ]}>
                                <>
                                    <select
                                        style={{fontSize:"16px"}}
                                        className="dropdown-filter create-collection-drop"
                                        placeholder="Protocol Name"
                                        name="protocolName"
                                        // onChange={(event) => onChangeSelectProtocolName(event, "protocolName",infraType)}
                                    >
                                        <option key={"00"} value={""}>
                                            Select Protocol
                                        </option>
                                        {/* {infraType === "storageNode"?
                                            storageProtocolList.map((chain, ind) => (
                                                <option key={chain.name} value={chain.protocol}>
                                                    {chain.name}
                                                </option>
                                            )):infraType === "didNode" ? didProtocolList.map((chain, ind) => (
                                                <option key={chain.name} value={chain.protocol}>
                                                    {chain.name}
                                                </option>
                                            )): infraType === "blockchainNode" ? protocolNames.map((chain, ind) => (
                                               
                                                <option key={chain} value={chain}>
                                                    {chain}
                                                </option>

                                            )) : bundlerProtocolNames.map((chain, ind) => (

                                                <option key={chain} value={chain}>
                                                    {chain}
                                                </option>

                                            ))
                                        } */}

                                    </select>
                                </>
                            </Form.Item>
                           
                            <Form.Item name="infraType"

                                >
                                    <>
                                        <select
                                            style={{fontSize:"16px"}}
                                            className="dropdown-filter create-collection-drop"
                                            placeholder="Infra Type"
                                            name="infraType"
                                            // onChange={(event) => onChangeSelect(event, "infraType")}
                                        >
                                            {/* {infraTypes.map((infra, ind) => ( infra.id === activeKey ? <option key={infra.id} value={infra.id} selected={true}>{infra.name}</option>:<option key={infra.id} value={infra.id}>{infra.name}</option>))} */}
                                        </select>
                                    </>
                                </Form.Item>
                             </div>



                            <div className="deploy-nft-collection">
                                <button
                                //  disabled={deployDisabled} 
                                 htmlType="submit">
                                    {/* {!deployDisabled ? ( */}
                                        Create dApp
                                     {/* ) : (
                                        <Spin indicator={antIcon} />
                                     )} */}
                                </button>
                            </div>
                        </Form>
                    </Space>
                </div>
            </Modal>
    </>
  )
}

export default CreateDapp
import Skeleton from "react-loading-skeleton";
import {TableContent} from "../../../library/table";
import {PaginationSection} from "../../../library/paginationSection";
import React, {useEffect, useState} from "react";
import {
    dateformatter,
    deepCopy,
    DEVELOPERROLE,
    ENTERPRISEDEVELOPERROLE,
    getPATTokenDetails,
    getUserDetails
} from "../../../deps";
import {useLocation, useNavigate, useParams} from "react-router-dom";
import {fetchApi, fetchApiGw} from "../../../_config/api";
import Image, {Form, Input, notification, Space, Tooltip} from "antd";
import {CopyOutlined} from "@ant-design/icons";
import "../SmartContract/styles.less";
import addIcon from "../SmartContract/addIcon.png"
import {CreateEndpoint} from "../Endpoints/createEndpoint";
import CopyImage from "../../../assets/icons/imagecopy.svg";
import {ethers} from "ethers";


export const NcWalletDetailsPage = ({showLoader,isLoading}) => {
    const [pageSetting, setSetting] = useState({
        page: 1,
        itemsPerPage: 10,
        searchText: "",
        filterValue: "",
    });
    const { userRole = DEVELOPERROLE} = getUserDetails().profile || {};
    const [data, setData] = useState([]);
    const [filteredData, setFilteredData] = useState([]);
    const [hitsList, setHitsList] = useState([]);
    const [totalCount, setTotalCount] = useState(-1);
    const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
    const[walletData,setWalletData]=useState([])

    const [protocolList, setProtocolList] = React.useState([]);
    const [protocolNames, setProtocolNames] = React.useState([]);
    const [balance, setBalance] = React.useState(0);
    const [displayEndpointSelect, setDisplayEndpointSelect] = useState(false);
    const formRef = React.useRef(null);


    const [protocols, setProtocols] = React.useState([]);
    const [selectedProtocol, setSelectedProtocol] = React.useState([]);

    const location = useLocation();
    let walletId = "";
    let walletName = "";
    let instanceId = "";
    let projectName = "";
    let address = "";
    let blockchainEndpoint = [];
    if (location.state != null && location.state.instance_id != null) {
        walletId = location.state.wallet_id
        walletName = location.state.name
        instanceId = location.state.instance_id
        address = location.state.address
    }

    console.log("instanceId-------->",instanceId);
    const [chainID, setChainID] = React.useState("");
    const walletURL = process.env.REACT_APP_GATEWAY_URL;
    const [chainIDVal, setChainIDVal] = useState("");
    const [projectId, setProjectId] = useState("");

    useEffect(() => {
        if(blockchainEndpoint !== null && blockchainEndpoint !== undefined && blockchainEndpoint.length > 0){
            if(blockchainEndpoint.length > 1){
                setDisplayEndpointSelect(true);
            }else{
                setChainIDVal(blockchainEndpoint[0].ChainId);
                setDisplayEndpointSelect(false);
            }
        }
    }, []);
    useEffect(() => {
        getProtocalList();
        getProject();
    }, []);
    const getProject = () => {
        fetchApi({
            url: "developer/project-details/"+instanceId,
            method: "GET",
        }).then((response) => {
            // console.log("response",response)
            if(response !== null && response.doc.length > 0){
                if(response.doc[0].endpoints.BlockchainEndpoint !== null){
                    setProjectId(response.doc[0].projectID);
                    setProtocols(response.doc[0].endpoints.BlockchainEndpoint);
                }
            }
        });
    };

    const getProtocalList = () => {
        let blockchainList = []
        fetchApi({ method: "GET", url: `protocols/getAllProtocols` })
            .then((response) => {
                if (response.docs) {
                    const { docs = [] } = response;
                    for (let i = 0; i < response.docs.length; i++) {
                        if (response.docs[i].protocol !== "DID" && response.docs[i].protocolType !== "bundler" && response.docs[i].protocol !== "IPFS") {
                            blockchainList.push(response.docs[i]);
                        }
                    }
                }
            })
            .catch(() => {

            }).finally(() => {
            setProtocolList(blockchainList);
            const grades = [...new Set(blockchainList.map(item => ({
                protocol: item.name,
                chainId: item.chainId,
            })))];
            setProtocolNames([...grades]);
        });
    };

    const onGetBalance = (chainId) => {
        showLoader(true);
        fetchApiGw(
            {
                method: 'POST',
                url: walletURL + "/api/v0/utils/getBalance",
                data: {
                    "address": address,
                    "chainId": chainId
                },
                useBase: false, isDevApp: false, instanceId: instanceId,chainId:chainIDVal
            })
            .then(data => {
                if (data.Data) {
                    if (data.Data !== null && data.Data.balance !== undefined) {

                        // setBalance(data.Data.balance)

                        console.log("data.Data.balance:", data.Data.balance);
                        const { balance } = data.Data;
                        if(balance > 0) {
                            const balanceBigNumber = ethers.BigNumber.from(balance.toString());
                            const formatEthBalance = ethers.utils.formatUnits(balanceBigNumber, "ether");
                            setBalance(Number(formatEthBalance).toFixed(8));

                            // const val = ((1.50*89920));
                            // console.log("val:", val);
                            // const balanceBigNumber1 = ethers.BigNumber.from(val);
                            // console.log("balanceBigNumber1:", balanceBigNumber1);
                            // // const formatEthBalance1 = ethers.utils.parseUnits(val, "ether");
                            // // console.log("formatEthBalance1:", formatEthBalance1);
                            // const formatEthBalance2 = ethers.utils.formatUnits(balanceBigNumber1, "ether");
                            // console.log("formatEthBalance2:", formatEthBalance2);

                            // setBalance(Number(val).toFixed(8));
                        } else {
                            console.log("balance:", balance);
                            setBalance(0);
                        }

                        showLoader(false);
                    } else {
                        showLoader(false);
                        notification.error({
                            message: data.Message,
                            duration: 3
                        })
                    }
                } else {
                    setBalance(0)
                    notification.error({
                        message: data.Message,
                        duration: 3
                    })
                    showLoader(false);
                }
                showLoader(false);

            })
            .catch((err) => {
                console.log("err", err.message);
                notification.destroy();
                notification.error({
                    message: err.message,
                    duration: 3,
                });

                showLoader(false);
            });
    };


    const columns = [
        {
            title: 'Transaction Hash',
            dataIndex: 'txn_hash',
            key: 'txn_hash',
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
        },
        {
            title: '',
            dataIndex: 'txn_hash',
            key: 'x',
            render: (txn_hash) =>
                <a>
                    <CopyOutlined onClick={() => onCopy(txn_hash)} />
                </a>
        },
        {
            title: 'To',
            dataIndex: 'to',
        },
        {
            title: '',
            dataIndex: '',
            key: 'x',
        },
        {
            title: '',
            dataIndex: 'to',
            key: 'x',
            render: (to) =>
                <a>
                    <CopyOutlined onClick={() => onCopy(to)} />
                </a>
        },
        {
            title: 'Nonce',
            dataIndex: 'nonce',
        },
        {
            title: 'Gas',
            dataIndex: 'gas',
        },

    ];

    const {
        photo,
        firstName = "",
        lastName = "",
    } = getUserDetails().profile || {};
    const name = firstName + " " + lastName;
    // console.log(firstName )

    useEffect(() => {
        //   getProjectList();
        setHitsList([3, 6, 9, 12, 15]);
    }, []);

    useEffect(() => {
        onFilterDataChange();
    }, [data, pageSetting]);

    useEffect(() => {
        setTotalCount(filteredData.length);
    }, [filteredData]);

    useEffect(() => {
        if(chainIDVal !== ""){
            getAllWalletsTransaction();
        }
    }, [chainIDVal]);

    const getAllWalletsTransaction = () => {
        console.log("dasdASDASD",projectId)
        fetchApiGw(
            {
                method: 'POST',
                url: walletURL + "/api/v0/ncWallet/listWalletTxns",
                data: {
                    "mode": "api",
                    "serviceAPIKey": walletId,
                },
                useBase: false, isDevApp: false, instanceId: instanceId,chainId:chainIDVal,dAppID:projectId
            })
            .then(data => {
                    if(data.Status === "SUCCESS"){
                        if (data.Data !== null) {
                            setWalletData(data.Data)
                        } else {
                            setWalletData([]);
                        }
                    }else{
                        setWalletData([]);
                    }

                }
            ).catch((error) => {
            setWalletData([]);
            notification.error({
                message: error.toString(),
                duration: 3,
            });
        })

    }

    const onFilterDataChange = () => {
        const endIndex =
            pageSetting.page * pageSetting.itemsPerPage > data.length
                ? data.length
                : pageSetting.page * pageSetting.itemsPerPage;

        const filterList = (
            pageSetting.searchText
                ? data.filter((item) =>
                    item.name
                        .toLowerCase()
                        .includes(pageSetting.searchText.toLowerCase())
                )
                : deepCopy(data)
        )
            .filter((item) =>
                pageSetting.filterValue !== ""
                    ? (pageSetting.filterValue === "locked" && item.projectLock) ||
                    (pageSetting.filterValue === "unlocked" && !item.projectLock)
                    : true
            )
            .slice(startIndex, endIndex);
        setFilteredData(filterList);
    };

    const onPageChange = (pageNumber) => {
        pageSetting.page = Number(pageNumber);
        setSetting({ ...pageSetting });
        // getAllWalletsTransaction();
    };

    const onPerPageChange = (value) => {
        pageSetting.itemsPerPage = Number(value);
        pageSetting.page = 1;
        setSetting({ ...pageSetting });
        // getAllWalletsTransaction();
    };

    const onCopy = (apiKey) => {
        navigator.clipboard.writeText(apiKey);
        notification.success({
            message: "Copied to clipboard",
            duration: 3,
        });
    };
    const onChangeChainID = (e) => {
        const { name, value } = e.target;
        setChainIDVal(value);
    };

    const last =
        pageSetting.page * pageSetting.itemsPerPage > totalCount
            ? totalCount
            : pageSetting.page * pageSetting.itemsPerPage;

    const onChangeSelectProtocol = (event, name) => {
        formRef.current?.setFieldsValue({
            [name]: event.target.value,
        });
        setChainIDVal(event.target.value)
        onGetBalance(event.target.value)
    };

    return (
        <div className="transaction-page-container">
            <div className="table-container">
                <div className="text">{walletName}</div>
                <br/>
                <div className="row">
                    <div className="col-md-6">
                        <p className="self-managed-text">{`Instance ID`} : <span className="">{instanceId}<img
                            src={CopyImage} className="copyimage-new" onClick={() => onCopy(instanceId)}/></span></p>
                        <p className="self-managed-text">{`Wallet Address`} : <span className="">{address}<img
                            src={CopyImage} className="copyimage-new" onClick={() => onCopy(address)}/></span></p>
                        <p className="self-managed-text">{`Service API Key`} : <span className="">{walletId}<img
                            src={CopyImage} className="copyimage-new" onClick={() => onCopy(walletId)}/></span></p>
                    </div>
                </div>
                <div className="dapp-details-select-items"
                     className={'dapp-details-select-items'}>
                    <Space
                        direction="horizontal"
                        size={25}
                    >
                        <Form
                            name="basic"
                            ref={formRef}
                            initialValues={{
                                remember: true,
                            }}
                            // onFinish={onCreateEndpoint}
                            autoComplete="off"
                        >
                            <label className={"self-managed-label"}>
                                Get Balance : </label>

                            <select
                                className="dropdown-filter mega-menu-select dapp-details-custom-select"
                                placeholder="Protocol Name"
                                name="protocolName"
                                onChange={(event) => onChangeSelectProtocol(event, "protocol")}
                            >
                                <option key={"00"} value={""}>
                                    Select Protocol
                                </option>
                                {protocolNames.map((chain, ind) => (
                                    <option key={ind} value={chain.chainId}>
                                        {chain.protocol}
                                    </option>
                                ))}
                            </select>

                        </Form>
                    </Space>
                </div>
                <div
                    className="self-managed-label">Balance : {(balance !== undefined && balance > 0) ? balance : 0}</div>
                <br/>
                <br />
                <div style={{display: "flex"}}>
                    <label style={{width: "auto !important"}} className={"self-managed-label"}>
                        Select Protocol : </label>
                    <select
                        className="dropdown-filter mega-menu-select dapp-details-custom-select"
                        placeholder="Select Protocol"
                        name="chainId"
                        onChange={onChangeChainID}
                    >
                        <option key={"00"} value={""}>
                            Select Protocol
                        </option>
                        {protocols.map((chain, ind) => (
                            <option key={ind} value={chain.ChainId}>
                                {chain.Protocol}
                            </option>
                        ))}
                    </select>

                </div>
                {isLoading ? (<Skeleton height={600} style={{marginBottom: '50px'}} baseColor="#262626"
                                        highlightColor="#404040"></Skeleton>) : (
                    <TableContent
                        rowKey="walletId"
                        data={walletData}
                        columns={columns}

                        isDashboard={false}
                        pagination={false}
                    />
                )}
                {/* <div className="data-section">
                    {data.map((item) => (
                    <Card key={item.projectId} item={item} onCopy={onCopy} onExport={onExport} onNavigate={onProjectNavigate}/>
                    ))}
                  </div> */}
                <PaginationSection
                    last={last}
                    first={startIndex + 1}
                    hitsList={hitsList}
                    totalCount={totalCount}
                    page={pageSetting.page}
                    itemsPerPage={pageSetting.itemsPerPage}
                    onPageChange={onPageChange}
                    onPerPageChange={onPerPageChange}
                />


            </div>
        </div>
    );
}

import React from "react";
import { Authereum } from "./authereum";
import { BlockChain } from "./blockChain";
import { Coinbase } from "./coinbase";
import { Ethereum } from "./ethereum";
import { Filecoin } from "./filecoin";
import { IPFS } from "./ipfs";
import { Metamask } from "./metamask";
import { Polygon } from "./polygon";
import { ProjectDetails } from "./project_details";
import { Solana } from "./solana";
import { Stellar } from "./stellar";
import { Storage } from "./storage";
import { Storej } from "./storej";
import { Tezos } from "./tezos";
import { Wallet } from "./wallet";
import { WalletConnect } from "./walletConnect";
import { CeramicNetwork } from "./ceramicNetwork";
import { Hedera } from "./hedera";
import { Arbitrium } from "./arbitrium";
import { Optimism } from "./optimism";
import { Avalanche } from "./avalanche";
import { Binance } from "./binance";
import { NewContract } from "./newContract";
import { ExistingContract } from "./existingContract";
import DID from "./did";
import Bundler from "./bundler";

export const Icons = ({ type = "", ...attributes }) => {
  return (
    <>
      {(() => {
        switch (type?.toLowerCase()) {
          case "polygon":
            return <Polygon {...attributes} />;
          case "ethereum":
            return <Ethereum {...attributes} />;
          case "solana":
            return <Solana {...attributes} />;
          case "stellar":
            return <Stellar {...attributes} />;
          case "tezos":
            return <Tezos {...attributes} />;
          case "authereum":
            return <Authereum {...attributes} />;
          case "coinbase":
            return <Coinbase {...attributes} />;
          case "metamask":
            return <Metamask {...attributes} />;
          case "ipfs":
            return <IPFS {...attributes} />;
          case "filecoin":
            return <Filecoin {...attributes} />;
          case "storej":
            return <Storej {...attributes} />;
          case "project_details":
            return <ProjectDetails {...attributes} />;
          case "blockchain":
            return <BlockChain {...attributes} />;
          case "wallet":
            return <Wallet {...attributes} />;
          case "storage":
            return <Storage {...attributes} />;
          case "walletconnect":
            return <WalletConnect {...attributes} />;
          case "ceramicnetwork":
            return <CeramicNetwork {...attributes} />;
          case "hedera":
            return <Hedera {...attributes} />;
          case "arbitrum":
            return <Arbitrium {...attributes} />;
          case "optimism":
            return <Optimism {...attributes} />;
          case "avalanche":
            return <Avalanche {...attributes} />;
          case "binance":
            return <Binance {...attributes} />;
          case "bsc":
            return <Binance {...attributes} />;
          case "new contract":
              return <NewContract {...attributes} />;
          case "existing contract":
            return <ExistingContract {...attributes} />;
          case "did":
            return <DID {...attributes} />;
          case "base":
            return <Coinbase {...attributes} />;
          case "bundler":
            return <Bundler {...attributes} />;
          default:
            return null;
        }
      })()}
    </>
  );
};

export default Icons;

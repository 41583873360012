import React from "react";
import "./style.less";
import { getUserDetails } from "../../deps";
import { ACCESS } from "../../_config/constant";
import DownIcon from "../../assets/menus/DownIcon";
import UpIcon from "../../assets/menus/UpIcon";
import DashboardIcon from '../../assets/icons/Group 26.svg'
import RightIcon from "../../assets/menus/RightIcon";
import smIcon from '../../assets/icons/full-service 2.svg'
import gasIcon from '../../assets/icons/gas-station 1.svg'
import dataIcon from '../../assets/icons/Group 56.svg'
import apiIcon from '../../assets/icons/api 1.svg'
import historyIcon from '../../assets/icons/history 1.svg'
import projectIcon from '../../assets/icons/projecticon.svg'
import WalletIcon from  '../../assets/icons/Mask groupWallet.svg'
import TeamIcon from  '../../assets/icons/group-chat 1.svg'
import arrowIcon from './ArrowIcon/arrow-icon.svg'


export const menuList = {
  "ENTERPRISE-ADMIN": [
    { id: "dashboard", name: "Dashboard", code: ACCESS.MENU_DASHBOARD },
      { id: "endpoints", name: "Endpoints", code: ACCESS.END_POINTS },
    { id: "enterprise-admin-developers", name: "Developers", code: ACCESS.MENU_DEVELOPERS },
    { id: "teams", name: "Teams", code: ACCESS.MENU_TEAMS },
    { id: "transaction", name: "API History", code: ACCESS.MENU_TRANSACTION },
      { id: "faucet", name: "Faucet", code: ACCESS.MENU_FAUCET},

      {
          id: "advanced",
          name: "Add-ons",
          code: ACCESS.MENU_ADVANCED,
          iconClosed: <DownIcon />,
          iconOpened: <RightIcon />,
          subMenu: [
              { id: "advanced/gaslessTransactions", name: "Gasless Transactions"},
              { id: "dataLake-setup", name: "Datalake Setup" },{ id: "dataLake-report", name: "Datalake Report" }
          ],
      },
  ],
  "ENTERPRISE-DEVELOPER": [
    { id: "dashboard", name: "Dashboard", code: ACCESS.MENU_DASHBOARD },
      { id: "endpoints", name: "Endpoints", code: ACCESS.END_POINTS },
      { id: "project", name: "dApp Studio", code: ACCESS.MENU_PROJECT },
      { id: "teams", name: "Teams", code: ACCESS.MENU_TEAMS },
  ],
  DEVELOPER: [
    { id: "dashboard", name: "My Krypcore", src:DashboardIcon},
      {
          id: "advanced",
          name: "My Projects",
          src:projectIcon,
          code: ACCESS.MENU_ADVANCED,
          iconClosed: <DownIcon />,
          iconOpened: <RightIcon />,
          subMenu: [
              { id: "my-endpoints", name: "My Endpoints" },{ id: "my-dapps", name: "My dApps" }
          ],
      },
    { id: "smart-contract", name: "Smart Contract",src:smIcon},
    { id: "gasless", name: "Gasless",src:gasIcon},
      { id: "nc-wallet", name: "Self-Managed Wallet",src:WalletIcon},
    { id: "datalake", name: "Datalake",src:dataIcon},
    { id: "apihistory", name: "API  History",src:historyIcon},
      { id: "teams", name: "Teams",src:TeamIcon},
    // { id: "endpoints", name: "Endpoints", code: ACCESS.END_POINTS },
    // { id: "project", name: "dApp Studio", code: ACCESS.MENU_PROJECT },
    // { id: "transaction", name: "API History", code: ACCESS.MENU_TRANSACTION},
    // { id: "faucetpage", name: "Faucet", code: ACCESS.MENU_FAUCET},
      // {   id: "addons",
      //     name: "Addons",
      //     code: ACCESS.MENU_ADDON,
      //     iconClosed: <DownIcon />,
      //     iconOpened: <RightIcon />,
      //     subMenu: [{ id: "addons/gasless", name: "Gasless" }, { id: "addons/dataLake-setup", name: "Data Lake Setup" },{ id: "addons/dataLake-report", name: "Data Lake Report" }],
      // },
      ]
};

export const getPageTitle = (pathname, navigate) => {
  const {
    profile: { userRole },
  } = getUserDetails();

  console.log(pathname)

  if (pathname) {
    const list = menuList[userRole].find((item) => pathname.includes(item.id));
  //   const scId = pathname.split("/")[2];
  //   if (pathname.includes("/create-apps")) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate("/project")}>
  //           {list?.name}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Create dApp</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/dashboard`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">My Krypcore</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/my-endpoints`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">My Endpoints</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/my-dapps`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">My dApps</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/nc-wallet`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">Self-Managed Wallet</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/datalake`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">Datalake</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/apihistory`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">Api History</span>
  //       </span>
  //     );
  //   }
  //   // else if(pathname.includes(`/manage-teams`)) {
  //   //   return (
  //   //     <span className="breadcrumb-container">
  //   //       <span className="main-page" onClick={() => navigate(-1)}>
  //   //
  //   //
  //   //       </span>
  //   //       <span className="separator"> {""}</span>
  //   //       <span className="second-page">Teams</span>
  //   //     </span>
  //   //   );
  //   // }
  //   else if(pathname.includes(`/smart-contract`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">Smart Contract</span>
  //       </span>
  //     );
  //   }else if(pathname.includes(`/gasless/enableGasless`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //         Gasless
  //
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Enable Gasless</span>
  //       </span>
  //     );
  //   }
  //   else if(pathname.includes(`/gasless`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //
  //
  //         </span>
  //         <span className="separator"> {""}</span>
  //         <span className="second-page">Gasless</span>
  //       </span>
  //     );
  //   }  else if(pathname.includes(`/smart-contract-studio/${scId}/dashboard/`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           Smart Contract Studio
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Contract Details</span>
  //       </span>
  //     );
  //   } else if(pathname.includes(`/smart-contract-studio/${scId}/interact/`)) {
  //       return (
  //         <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //             Smart Contract Studio
  //           </span>
  //           <span className="separator"> {"/"}</span>
  //           <span className="second-page">Interact</span>
  //         </span>
  //       );
  //   } else if(pathname.includes(`/smart-contract-studio/${scId}/contract-templates/create/ERC20`)) {
  //     return (
  //         <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           Contract Templates
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">ERC20</span>
  //       </span>
  //     );
  //   } else if(pathname.includes(`/smart-contract-studio/${scId}/contract-templates/create/ERC721`)) {
  //     return (
  //         <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           Contract Templates
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">ERC721</span>
  //       </span>
  //     );
  //   } else if(pathname.includes(`/smart-contract-studio/${scId}/contract-templates/create/ERC1155`)) {
  //     return (
  //         <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           Contract Templates
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">ERC1155</span>
  //       </span>
  //     );
  //   } else if(pathname.includes(`/smart-contract-studio/${scId}/contract-templates/`)) {
  //     return (
  //         <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           Contract Templates
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Templates</span>
  //       </span>
  //     );
  //   } else if(pathname.includes(`/smart-contract-studio/${scId}/contract-templates`)) {
  //     return (
  //         <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           Smart Contract Studio
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Contract Templates</span>
  //       </span>
  //     );
  //   } else if (pathname.includes(`/smart-contract-studio/${scId}/contracts`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //           dApp Details
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Contract List</span>
  //       </span>
  //     );
  //   } else if(pathname.includes(`/smart-contract-studio/instances`)) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate(-1)}>
  //         {"dApp Details"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">SC Instance</span>
  //       </span>
  //     )
  //   } else if (pathname.includes(`/smart-contract-studio/`)) {
  //       return (
  //         <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //             dApp Details
  //           </span>
  //           <span className="separator"> {"/"}</span>
  //           <span className="second-page">Details</span>
  //         </span>
  //       );
  //   } else if (
  //     pathname.includes("/dashboard/") ||
  //     pathname.includes("/project/")
  //     // pathname.includes("/teams/")
  //   ) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate("/project")}>
  //           {list?.name}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">dApp Details</span>
  //       </span>
  //     );
  //   } else if(pathname.includes("/dataLake-setup/")) {
  //     return (
  //       <span className="breadcrumb-container">
  //         <span className="second-page" onClick={() => navigate(-1)}>
  //           {"Datalake Configuration"}
  //         </span>
  //       </span>
  //     );
  //   } else if(pathname.includes("/dataLake-report/")) {
  //       return (
  //           <span className="breadcrumb-container">
  //         <span className="second-page" onClick={() => navigate(-1)}>
  //           {"Datalake Report"}
  //         </span>
  //       </span>
  //       );
  //   }else if(pathname.includes("/faucetpage")) {
  //       return (
  //           <span className="breadcrumb-container">
  //               <span className="main-page" onClick={() => navigate(-1)}>
  //               {"Dashboard"}
  //         </span>
  //               <span className="separator"> {">"}</span>
  //         <span className="second-page">Faucet</span>
  //         </span>
  //       )
  //
  //   }else if(pathname.includes("/endpointsDetails/")) {
  //     return (
  //         <span className="breadcrumb-container">
  //         <span className="main-page" onClick={() => navigate("endpoints")}>
  //           {list?.name}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Endpoint Details</span>
  //       </span>
  //       )
  //   } else if(pathname.includes("/walletTransaction")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           Wallet
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Wallet Transaction</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/wallet-inner")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           dApp Details
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Wallet</span>
  //         </span>
  //       )
  //   }else if(pathname.includes("/ncWallet-inner")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           dApp Details
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">SMWallet</span>
  //         </span>
  //       )
  //   }else if(pathname.includes("/scWallet-inner")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           dApp Details
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">SCWallet</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/nft-instance")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">{"NFT Instance"}</span>
  //         </span>
  //       )
  //   }  else if(pathname.includes("/nft-studio/collection")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"NFT Collection"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Mint NFT</span>
  //         </span>
  //       )
  //   }  else if(pathname.includes("/nft-studio/mint")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"Mint NFT"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Mint New NFT</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/nft-studio")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">NFT Collection</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/ft-instance")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">{"FT Instance"}</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/ft-manager/collection")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"FT Manager"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Mint FT</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/ft-manager")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">FT Manager</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/did-manager-view")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //               <span className="separator"> {"/"}</span>
  //         <span className="second-page">DID Manager</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/did-manager")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //               <span className="separator"> {"/"}</span>
  //         <span className="second-page">DID Instance</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/storage-create")){
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //               <span className="separator"> {"/"}</span>
  //         <span className="second-page">Storage Manager File Details</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/storage-manager")) {
  //       return (
  //       <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //               <span className="separator"> {"/"}</span>
  //         <span className="second-page">Storage Instance</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/custodial-wallet")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"dApp Details"}
  //         </span>
  //               <span className="separator"> {"/"}</span>
  //         <span className="second-page">Wallet Instance</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/advanced/gasless-transactions")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate(-1)}>
  //           {"Gasless Transactions"}
  //         </span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/advanced/ai-assistant")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">AI Assistant</span>
  //         </span>
  //       )
  //   } else if(pathname.includes("/account-settings")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Account Settings</span>
  //         </span>
  //       )
  //   }else if(pathname.includes("/block-details")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="main-page" onClick={() => navigate("/dataLake-report")}>
  //               {"Data Lake"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">BlockDetails</span>
  //         </span>
  //       )
  //   }else if(pathname.includes("/dataLake-setup")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Data Lake Setup</span>
  //         </span>
  //       )
  //   }  else if(pathname.includes("/dataLake-report")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Data Lake Report</span>
  //         </span>
  //       );
  //   }else if(pathname.includes("/support")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Support</span>
  //         </span>
  //       )
  //   }
  //   // else if(pathname.includes("/teams")) {
  //       // return (
  //       //     <span className="breadcrumb-container">
  //       //     <span className="main-page" onClick={() => navigate("/dashboard")}>
  //       //         {"Dashboard"}
  //       //   </span>
  //       //   <span className="separator"> {"/"}</span>
  //       //   <span className="second-page">Teams</span>
  //       //   </span>
  //       // )
  //   // }
  //   else if(pathname.includes("/developerDetails")) {
  //       return (
  //           <span className="breadcrumb-container">
  //               <span className="main-page" onClick={() => navigate("/teams")}>
  //               {"Teams"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Team Details</span>
  //         </span>
  //       )
  //   }else if(pathname.includes("/developers/add")) {
  //       return (
  //           <span className="breadcrumb-container">
  //           <span className="main-page" onClick={() => navigate("/enterprise-admin-developers")}>
  //           {"Developers"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Add Developers</span>
  //           </span>
  //       )
  //   }
  //   // else if(pathname.includes("/edit-teams")) {
  //   //       return (
  //   //             <span className="breadcrumb-container">
  //   //             <span className="main-page" onClick={() => navigate(-1)}>
  //   //             {"Teams"}
  //   //             </span>
  //   //             <span className="separator"> {"/"}</span>
  //   //             <span className="second-page">Developers List</span>
  //   //             </span>
  //   //             )
  //   // }
  //   else if(pathname.includes("/ncWalletTransaction")) {
  //       return (
  //           <span className="breadcrumb-container">
  //               <span className="main-page" onClick={() => navigate(-1)}>
  //               {"SMWallet"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">Wallet Transactions</span>
  //         </span>
  //       )
  //   }else if(pathname.includes("/dAppDetails-page")) {
  //       return (
  //           <span className="breadcrumb-container">
  //               <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //         </span>
  //         <span className="separator"> {"/"}</span>
  //         <span className="second-page">dApp Details</span>
  //         </span>
  //       )
  //   }
  //   if(!pathname.includes("/dashboard")) {
  //       return (
  //         <>
  //           <span className="breadcrumb-container">
  //             <span className="main-page" onClick={() => navigate("/dashboard")}>
  //               {"Dashboard"}
  //             </span>
  //             <span className="separator"> {"/"}</span>
  //             <span className="second-page">{list?.name}</span>
  //           </span>
  //         </>
  //       );
  //   } else {
      if (pathname.includes("/endpointsDetails/")) {
          return (
              <span className="breadcrumb-container">
                  <span className="main-page" onClick={() => navigate("/my-endpoints")}>
                     <img src={arrowIcon} alt="Go back" style={{paddingRight:"10px", paddingBottom: "5px"}} className="arrow-icon"/>
                  </span>

              <span className="second-page">
                My Endpoint
              </span>
            </span>
          );
      } else if (pathname.includes("/my-endpoints")) {
          return (
              <span className="breadcrumb-container">
              <span className="second-page">
                My Projects
              </span>
            </span>
            );
        }else if (pathname.includes("/dapp-endpointsDetails/")) {
            return (
                <span className="breadcrumb-container">
              <span className="second-page">
                My Projects
              </span>
            </span>
            );
        }else if (pathname.includes("/dapp-endpointsDetails/")) {
            return (
                <span className="breadcrumb-container">
              <span className="second-page">
                My Projects
              </span>
            </span>
            );

      }else if (pathname.includes("/dAppDetails-page")) {
            return (
                <span className="breadcrumb-container">
                <span className="main-page" onClick={() => navigate("/my-dapps")}>
                 <img src={arrowIcon} alt="Go back" style={{paddingRight: "10px", paddingBottom: "5px"}}
                      className="arrow-icon"/>
                </span>

              <span className="second-page">
                My dApp
              </span>
            </span>
            );
      } else if (pathname.includes("/my-dapps")) {
          return (
              <span className="breadcrumb-container">
              <span className="second-page">
                My Projects
              </span>
            </span>
          );
        }else if (pathname.includes("/support")) {
            return (
                <span className="breadcrumb-container">
              <span className="second-page">
                Support
              </span>
            </span>
            );
        }else if (pathname.includes("/account-settings")) {
            return (
                <span className="breadcrumb-container">
              <span className="second-page">
                My Account
              </span>
            </span>
            );
        }else{
          return list?.name || "";
      }
  //   }
  //
  }
  return "";
};

import React from "react";

export const IPFS = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 123 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_903_3176)">
      <path
        d="M7.75671 92.1105L60.9348 122.813L114.113 92.1105V30.7051L60.9348 0.00476074L7.75671 30.7075V92.1105Z"
        fill="url(#paint0_linear_903_3176)"
      />
      <path
        d="M55.4463 7.40015L16.8904 29.6611C16.9675 30.3457 16.9675 31.0367 16.8904 31.7214L55.4487 53.9822C58.6998 51.5834 63.1347 51.5834 66.3858 53.9822L104.944 31.7211C104.867 31.0365 104.867 30.3458 104.944 29.6611L66.388 7.40025C63.1368 9.79914 58.7017 9.79914 55.4504 7.40025L55.4463 7.40015ZM108.646 37.8702L70.0469 60.3791C70.4988 64.3942 68.2812 68.2349 64.5782 69.8511L64.6215 114.125C65.253 114.401 65.8512 114.746 66.4056 115.155L104.964 92.8947C104.512 88.8796 106.73 85.0387 110.433 83.4224V38.9011C109.801 38.6255 109.203 38.2802 108.648 37.8711L108.646 37.8702ZM13.2253 38.1176C12.6709 38.5267 12.0726 38.8723 11.4412 39.1479V83.6692C15.1442 85.2854 17.3617 89.1262 16.9098 93.1413L55.4657 115.402C56.0201 114.993 56.6187 114.647 57.2502 114.372V69.8505C53.5471 68.2344 51.3296 64.3936 51.7815 60.3785L13.2256 38.1166L13.2253 38.1176Z"
        fill="url(#paint1_linear_903_3176)"
      />
      <path
        d="M60.936 122.811L114.114 92.1082V30.7028L60.936 61.4055V122.811Z"
        fill="black"
        fillOpacity="0.25098"
      />
      <path
        d="M60.9348 122.811V61.4055L7.75671 30.7028V92.1082L60.9348 122.811Z"
        fill="black"
        fillOpacity="0.039216"
      />
      <path
        d="M7.75671 30.7027L60.9348 61.4054L114.113 30.7027L60.9348 0L7.75671 30.7027Z"
        fill="black"
        fillOpacity="0.13018"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_903_3176"
        x1="7.75671"
        y1="61.4078"
        x2="114.114"
        y2="61.4078"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#4A9EA1" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_903_3176"
        x1="11.4419"
        y1="61.4009"
        x2="110.431"
        y2="61.4009"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#63D3D7" />
      </linearGradient>
      <clipPath id="clip0_903_3176">
        <rect
          width="122.811"
          height="122.811"
          fill="white"
          transform="translate(0.0810547)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default IPFS;

import React from "react";

export const Request = ({ width = "46px", height = "46px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 22 22"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_1_2929)">
      <path
        d="M11.3049 19.9855C16.0992 19.9855 19.9855 16.0992 19.9855 11.3049C19.9855 6.51065 16.0992 2.62435 11.3049 2.62435C6.51065 2.62435 2.62435 6.51065 2.62435 11.3049C2.62435 16.0992 6.51065 19.9855 11.3049 19.9855Z"
        stroke="white"
        strokeWidth="2.08333"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M13.3083 14.4217C13.4096 14.4217 13.5067 14.3814 13.5783 14.3098C13.65 14.2382 13.6902 14.141 13.6902 14.0397C13.6902 13.9384 13.65 13.8413 13.5783 13.7696C13.5067 13.698 13.4096 13.6578 13.3083 13.6578C13.207 13.6578 13.1098 13.698 13.0382 13.7696C12.9666 13.8413 12.9263 13.9384 12.9263 14.0397C12.9263 14.141 12.9666 14.2382 13.0382 14.3098C13.1098 14.3814 13.207 14.4217 13.3083 14.4217ZM7.96105 9.07444C8.06234 9.07444 8.15949 9.0342 8.23112 8.96257C8.30275 8.89094 8.34299 8.79379 8.34299 8.69249C8.34299 8.59119 8.30275 8.49404 8.23112 8.42242C8.15949 8.35079 8.06234 8.31055 7.96105 8.31055C7.85975 8.31055 7.7626 8.35079 7.69097 8.42242C7.61934 8.49404 7.5791 8.59119 7.5791 8.69249C7.5791 8.79379 7.61934 8.89094 7.69097 8.96257C7.7626 9.0342 7.85975 9.07444 7.96105 9.07444Z"
        fill="white"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M14.4236 8.31055L8.3125 14.4217"
        stroke="white"
        strokeWidth="1.66667"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </g>
    <defs>
      <clipPath id="clip0_1_2929">
        <rect width="22" height="22" fill="white" />
      </clipPath>
    </defs>
  </svg>
);

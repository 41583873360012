import React from "react";
import { Icons } from "../../../assets";

export const Card = ({
  className = "text-container",
  width = "21px",
  height = "21px",
  value = {},
}) => {
    return (
        (typeof(value) === "object" ?
            <>
                <div className="column">
                    <Icons width={width} height={height} type={value !== null && value !== undefined ? value.svgId: null} />
                    <div className={className}>{
                        (value.BlockchainEndpoint !== undefined ? value.BlockchainEndpoint.EndpointName:"") ||
                         (value.StorageEndpoint !== undefined ? value.StorageEndpoint.EndpointName:"") ||
                        (value.DIEndpoint !== undefined ? value.DIEndpoint.EndpointName:"") ||
                        (value.SCEndpoint !== undefined ? value.SCEndpoint.EndpointName:"") ||
                        ((value.CustodialWallet !== undefined && value.CustodialWallet.isRequired === true) ? value.CustodialWallet.ServiceName:"") ||
                        ((value["NonCustodialWallet"] !== undefined && value["NonCustodialWallet"].isRequired === true) ? value["NonCustodialWallet"].ServiceName:"") ||
                        ((value.StorageManager !== undefined && value.StorageManager.isRequired === true) ? value.StorageManager.ServiceName:"")  ||
                        ((value.SmartContractStudio !== undefined && value.SmartContractStudio.isRequired === true) ? value.SmartContractStudio.ServiceName:"") ||
                        ((value.EasyNFT !== undefined && value.EasyNFT.isRequired === true) ? value.EasyNFT.ServiceName:"") ||
                        ((value.DID_Manager !== undefined && value.DID_Manager.isRequired === true) ? value.DID_Manager.ServiceName:"") ||
                        ((value.SmartContractWallet !== undefined && value.SmartContractWallet.isRequired === true) ? value.SmartContractWallet.ServiceName:"") ||
                        ((value.FT_Manager !== undefined && value.FT_Manager.isRequired === true) ? value.FT_Manager.ServiceName:"") ||
                        value.name }</div>
                </div>
            </>
        :
                <>
                    <div className="column">
                        <Icons width={width} height={height} type={value !== null && value !== undefined ? value.svgId: null} />
                        <div className={className}>{value}</div>
                    </div>
                </>
        )
);
}
    // console.log("value--->",typeof(value))



export default Card;

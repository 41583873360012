import React from "react";


export const Terms = () => {

  return (
    <>
        <div className="terms_container">
                  <div className="terms-heading">Krypcore Web3 General Terms of Service</div>

            <div className="terms-content">
                <p className="terms-content-paragraph">
                    This Site <a href="https://www.krypcore.com">(www.krypcore.com)</a> is owned and operated by KrypC Corp and its Affiliates (hereinafter “KrypC” or “We”). By continuing to browse and use it, whether as a registered user or a guest, user (“You” or “User”) agrees to comply with and be bound by this General Terms of Service (“Terms”).
                    By accessing our Platform or availing our Services, the User agrees to be bound by these Terms. We reserve the right to modify these Terms, at any point in the future, without prior notice to you. The updated copy of these Terms will be available on our Online Platforms along with the date of last revision. Your continued usage of our Platforms will serve as your acceptance of the revised Terms. These Terms should be read in conjunction with our Privacy Policy and any details, features, schemes or terms and conditions mentioned on our Platforms for any specific service or feature.
                </p>
                <p className="terms-content-paragraph_bold">
                    Beta launch: Please note that our Platform is currently in beta, which means that we are still testing and improving our Services and technology. Therefore, our services may not function at optimum efficiency and speed, and may contain errors or bugs. We appreciate your feedback and patience as we work to provide you with the best possible experience. As a beta user, you acknowledge and agree that our Platform is provided "as is" and "as available", without any warranties or guarantees of any kind, either express or implied. To the fullest extent permitted by law, we disclaim all liability for any loss, damage, injury, or expense arising from or relating to your use of our website, whether direct or indirect, incidental or consequential, special or exemplary. We reserve the right to modify, suspend, or terminate our website or any part of it at any time, without prior notice or liability.
                </p>
                <h5 className="terms-content-heading">
                    1. Definitions
                </h5>
                <p className="terms-content-paragraph">
                    1.1 “Affiliate” means a direct or indirect subsidiary, a holding company, any other subsidiary or controlled entity of that holding company.
                </p>
                <p className="terms-content-paragraph"> 1.2 “Platform” refers to and includes the website www.krypc.com, as may be accessed though a desktop, mobile or any other medium, Web3 Core, any application, software, extension or add-on required for or associated with the use the Services provided by KrypC or any successor trade / brand name as adopted by KrypC.</p>
                <p className="terms-content-paragraph"> 1.3 “Services” means access to the current, available version of the Platform, associated software and Subscription services offered through the Platform in accordance with these Terms.</p>
                <p className="terms-content-paragraph">1.4 “Subscription” refers to a non-exclusive, non-transferable and single-use licence given to the User to to access and use Our web3 core technology infrastructure for a specified period of time and under certain conditions. </p>
                <p className="terms-content-paragraph">1.5 “Subscription” refers to a non-exclusive, non-transferable and single-use licence given to the User to to access and use Our web3 core technology infrastructure for a specified period of time and under certain conditions. </p>
                <p className="terms-content-paragraph">1.6 “Intellectual Property Rights” means all intellectual property rights or proprietary rights available under applicable laws including without limitation patents, utility models, rights to inventions, copyright and related rights, designs, trademarks and service marks, trade names and domain names, rights in get-up, goodwill and the right to sue for passing off or unfair competition, rights in designs, rights in computer software, database rights, rights to preserve the confidentiality of information (including know-how and trade secrets) and any other intellectual property rights, including all applications for (and rights to apply for and be granted), renewals or extensions of, and rights to claim priority from, such rights and all similar or equivalent rights or forms of protection which subsist or will subsist, now or in the future, in any part of the world.</p>
                <p className="terms-content-paragraph">1.7 “Terms” means this terms of service (including any affiliate terms of service) and the privacy policy (“Privacy Policy”) provided or prescribed on our Platform and/or made available by us in writing (whether electronically or over email or by publication on online channels or such other form of communication) from time to time including any modifications or updates thereto, and all other pages, indices page, contract terms, policies and guides relating to the operation of our Platform or to any product, service, software, API available on our Platfrom as may be updated by us from time to time in our sole discretion.</p>
                <h5 className="terms-content-heading">
                    2. Grant of Licence
                </h5>
                <p className="terms-content-paragraph">2.1 Subject to these Terms, KrypC hereby grants to the User a non-transferable, non-exclusive, non-sublicensable, limited term and single-person use licence to access and use our Platform. </p>
                <p className="terms-content-paragraph">2.2 User will not use the Platform to develop modifications and/or derivatives of any nature without the express prior written consent of KrypC in each instance. </p>
                <h5 className="terms-content-heading">
                    3. Subscription services
                </h5>
                <p className="terms-content-paragraph">3.1 Subscription to KrypC Platform and Services will allow Users to develop and deploy smart contracts, NFTs, Daaps and other web3 applications through the tech-stack developed by KrypC. Users will buy Subscription to receive certain ‘API credits’ which can be used to execute specific commands and functions. API credits required to develop, deploy or execute a particular transaction will depend on the nature and complexity of the transaction.</p>
                <p className="terms-content-paragraph">3.2 API Credits will be valid for a limited period, as shown in User’s account, and accordingly expire at the end of validity period. User should read and understand the validity period, number of API Credits and the terms and conditions of use of API Credits as detailed on the Platform before buying the Subscription. The Subscription amount paid to Us is NOT refundable. Any remaining unused API Credits remaining in User account cannot be refunded or redeemed for any value under any circumstances. API Credits can only be used to call API to interact with our technological infrastructure as available on our Platform and cannot be used to obtain any product or services from any third party service provider or merchants.</p>
                <p className="terms-content-paragraph">3.3 We only provide technology infrastructure services by providing you access to our tech-stack which can be used to develop and deploy many applications, smart contracts, NFTs and dApps. You are encouraged to read and understand the scope of functions of our technology infrastructure before buying Subscription. Our Platform and Services currently includes:</p>
                <ul className="terms-list-content">
                <li>Smart Contract Studio - To develop and deploy smart contracts and to create various types of tokens (e.g. ERC 20, ERC ERC 1155, ERC 1400, ERC 3643 etc.)</li>
                <li>Easy NFT - To Mint, transfer and burn NFTs</li>
                <li>Staking node infrastructure service</li>
                <li>Creation and management of decentralised identities</li>
                <li>Wallet infrastructure - To create and manage digital wallets</li>
                <li>Gasless dApp development</li>
                <li>Data services</li>
                </ul>
                <p className="terms-content-paragraph">This list is indicative and we reserves the right to implement new versions and upgrades of the Services including, but not limited to, changes that effect modifications to the design, operational method, technical specifications, systems, and other functions, etc. of the Services, at any time without prior notice.</p>
                <p className="terms-content-paragraph">3.4 USERS UNDERSTAND AND AGREE THAT KRYPC WILL NOT, UNDER ANY CIRCUMSTANCES, HAVE ACCESS TO, CONTROL OVER OR HAVE CUSTODY OF ANY OF THE DIGITAL ASSETS, VIRTUAL DIGITAL ASSETS OR VIRTUAL ASSETS, BY WHATEVER NAME KNOWN OR DESCRIBED, BELONGING TO THE USER WHILE PROVIDING ANY SERVICES THROUGH ITS PLATFORM UNDER THESE TERMS.</p>
                <h5 className="terms-content-heading">
                    4. User Representations and Warranties
                </h5>
                <p className="terms-content-paragraph">You as a User or Subscriber to our Platform or Services represent, understand and agree that :</p>
                <p className="terms-content-paragraph">4.1 You have attained the age of majority and are competent to contract as per applicable law. If you are registering on behalf of any entity, you are legally authorised to enter into agreements on behalf of the entity as per applicable laws;</p>
                <p className="terms-content-paragraph">4.2 YOUR USE OF OUR SERVICES WILL NOT VIOLATE ANY LAWS AND REGULATIONS INCLUDING LICENSING OR APPROVAL REQUIREMENTS, ANTI-MONEY LAUNDERING, TERROR FINANCING, FOREIGN EXCHANGE, PRIVACY, DATA PROTECTION AND OTHER LAWS OR REGULATIONS AS APPLICABLE TO YOU AS PER YOUR JURISDICTION;</p>
                <p className="terms-content-paragraph">4.3 YOU AND YOU ALONE ARE RESPONSIBLE TO ENSURE COMPLIANCE WITH ALL APPLICABLE LAWS INCLUDING TAXATION, DATA PRIVACY, SECURITIES LAW, CONTRACT LAW, DIGITAL ASSET REGULATIONS AND OTHER LAWS, RULES OR REGULATIONS WHICH MAY BE APPLICABLE TO THE PRODUCT, DAPP, PROGRAMME, APPLICATION, TOKEN, NFT OR ANY OTHER OUTPUT CREATED BY YOU USING OUR PLATFORM OR SERVICES.</p>
                <p className="terms-content-paragraph">4.4 You will not circumvent, remove, degrade, deactivate or use any robot, spider, scraper or other means to access our Platform or Services. You also agree not to decompile, reverse engineer, and disassemble any software or other products or services available on our Platform. Additionally, you agree not to upload, post, e-mail or otherwise send or transmit any material designed to interrupt, destroy, or limit the functionality of our Platform or Services.</p>
                <p className="terms-content-paragraph">4.5 We reserve the right to suspend, block, cancel or reverse a transaction and/or refuse to continue Your access to our Platform or Services if we discover that you are in breach of these Terms or any Applicable Law or if we have reasonable reason to believe that your account has been used for any suspicious or unlawful transaction or purpose.</p>
                <p className="terms-content-paragraph">4.6 We may also initiate any action under the order or direction of the Government, law enforcement authority or court. We also reserve our right to refuse to open your User account with us or suspend, withhold or terminate your User Account in our sole discretion. We will not be responsible for any losses, whether direct or indirect, that you may incur as a result of our action (suspension of termination of your User account) or complying with Applicable Laws, the guidance or direction of any such regulatory authority or government agency, or any writ of attachment, lien, levy, subpoena, warrant, or other legal order.</p>
                <p className="terms-content-paragraph">4.7 Our Platform may be subject to restrictions, limitations, delays, and other problems inherent in the use of the internet and electronic communications, blockchain networks or an industry involving regulatory uncertainties. </p>
                <p className="terms-content-paragraph">4.8 You will keep your account details up to date and will immediately inform us in case of any change in user information;</p>
                <p className="terms-content-paragraph">4.9 You understand that your access credentials such as user name and password are confidential information and that you should not disclose such information to any third party. You also agree that you alone are responsible for taking necessary precautions to protect your own account and personal information;</p>
                <p className="terms-content-paragraph">4.10 You are providing true, accurate, current, and complete personal information at the time of registration and agree to maintain and keep the information up to date;</p>
                <p className="terms-content-paragraph">4.11 You will not use the Site or any Services in order to develop, create, deploy, conceal or disguise any application, software, programme or similar technological interface which may result in violation of any applicable law, breach of any AML laws, privacy laws or to deal in any unlawful virtual dgitial assets, fiat, property, funds, or proceeds. </p>
                <p className="terms-content-paragraph">4.12 We make no representations, warranties, or guarantees to you of any kind. Our Platform and Services are offered strictly on an as-is, where-is basis and, without limiting the generality of the foregoing, are offered without any representation as to merchantability or fitness for any particular purpose. Without limiting the foregoing, We do not represent or warrant that the Site, the Services or associated materials are accurate, complete, reliable, current, error-free, or free of viruses or other harmful components. Users does not have any right to compensation in the event of lack of access due to technical interruptions, glitches, errors or communication issues caused as a result of interruptions or communication problems on the Internet or other private and public networks, for other accessibility deficiencies outside of our control.</p>
                <h5 className="terms-content-heading">
                    5. Limitation of Liability
                </h5>
                <p className="terms-content-paragraph">5.1 To the extent permitted by law, We will not be liable for any loss or damage caused to you through use of our Platforms or Services. </p>
                <p className="terms-content-paragraph">5.2 We, our subsidiaries, affiliates, its shareholders, its directors, officers, employees, representatives, contractors, suppliers shall not be liable for any incidental, special, punitive, consequential or similar damages or liabilities whatsoever arising out of our Services, any performance or non-performance of Services provided by us.</p>
                <p className="terms-content-paragraph">5.3 Our total liability to any user shall not in any event exceed the Subscription amount paid by such User during the six (6) months immediately preceding the date of claim for any direct, indirect or consequential loss or damage irrespective of the nature of the claim.</p>
                <p className="terms-content-paragraph">5.4 We will adopt and maintain a reasonable standard of security, however, we will not be liable to you for any loss or damage caused to you on account of any malware attacks, distributed denial of service, consensus-based attacks, sybil attacks, phishing or hacking.</p>
                <p className="terms-content-paragraph">5.5 We may engage the services of Third Party Service Providers in connection with the development, operation and maintenance of our Platform and providing our Services. We shall not be liable for any suspension, halt or interruption of our Services where such circumstances arise or result from any interruptions, errors, default or otherwise by any such third parties.</p>
                <p className="terms-content-paragraph">5.6 Notwithstanding any provision in this Agreement to the contrary, We (to the fullest extent permitted by Applicable Laws) shall not in any circumstances be liable for any loss, damage, liability, expense, fines or penalties or any other amount directly or indirectly caused by the use or operation of the our Platform or Services or the introduction of viruses, worms or other destructive programs or any other commercial or economic losses, including such losses that arise out of or in connection with third party cyber-attacks or unauthorized access or use of our Platforms, or the loss of any funds or technology or virtual assets that may be stored or available in your User Account.</p>
                <h5 className="terms-content-heading">
                    6. Force majeure
                </h5>
                <p className="terms-content-paragraph">6.1 We shall not assume any and all risks and liability, in connection with any force majeure event, including acts of God, labor disputes or other industrial disturbances,electrical, telecommunications, hardware, software or other utility failures, software or smart contract system bugs or weaknesses, earthquakes, storms or other nature-related events, acts or orders of government, actions of the government, change of laws, introduction of new law, acts of terrorism or war, epidemic, pandemic, technological change, changes to any blockchain related protocol, information security attacks on service providers, and fraud conducted by third parties, and such other similar events which are beyond reasonable control of KrypC.</p>
                <h5 className="terms-content-heading">
                    7. Indemnification
                </h5>
                <p className="terms-content-paragraph">7.1 You shall indemnify and hold harmless Us, our affiliates, subsidiaries, our officers, directors, agents, and employees, from any claim or demand, or actions including reasonable attorneys' fees, made by any third party or penalty imposed due to or arising out or relating to (a) your use of our Services, (b) your breach of these Terms, or (c) your violation of any law, rules or regulations or the rights of any other user or a third party.</p>
                <h5 className="terms-content-heading">
                    8. Intellectual Property Rights
                </h5>
                <p className="terms-content-paragraph">8.1 KrypC retains its Intellectual Property Rights and all right, title, and interest in and to the Platform and Documentation, and all patent, copyright, trade secret and other intellectual property rights contained therein and all derivatives thereto, subject only to the licenses specifically granted to User under these Terms. Notwithstanding any other provision of these Terms, nothing in this Terms shall be construed as limiting or restricting our right or ability to market, distribute, license or otherwise exploit our Intellectual Property Rights in our Platform or our Services in any manner. All rights not expressly granted to the User herein are reserved by KrypC.  There are no implied rights. </p>
                <h5 className="terms-content-heading">
                    9. Miscellaneous
                </h5>
                <p className="terms-content-paragraph">9.1 Assignment: Any right, licence or access granted to you under these Terms cannot be assigned or transferred to any third person and any contract, arrangement or attempt to transfer such rights shall be void ab initio. We may assign, or transfer any or all rights and obligations that accrued in our favor under these Terms, at our sole discretion, to our subsidiaries, affiliated entities, or any other third party without any restriction. </p>
                <p className="terms-content-paragraph">9.2 Amendments: We reserve our rights to amend these Terms, with or without notice. The up to date copy of these Terms will be available on our Platform mentioning the data of last update. We urge you to regularly review and take notice of changes made in these Terms. We will endeavor to intimate you in case of any material change in these Terms to the extent possible and feasible. Your continued use of our Services would be deemed to be your acceptance to be bound by the updated Terms. If you do not agree with any part of these Terms or any amendments thereof, we insist that you stop using the Platforms and Services with immediate effect. </p>
                <p className="terms-content-paragraph">9.3 Dispute Resolution: Any dispute arising out of or in connection with the formation, performance, interpretation, nullification, termination or invalidation of this Agreement or related thereto in any manner whatsoever, shall be referred to and adjudicated by arbitration in accordance with the provisions set forth under the Dubai International Arbitration Center (DIAC) Arbitration Rules (“the Rules”), by a single arbitrator appointed in compliance with the Rules. The seat of arbitration shall be in Dubai and the language of arbitration shall be English.</p>
                <p className="terms-content-paragraph">9.4 Governing Law: These Terms are governed by the laws of New Jersey, United States of America. Any dispute arising out of or in connection with the user of our Platform or Services under these Terms shall be subject to the exclusive jurisdiction of the courts at New Jersey, USA.</p>
                <p className="terms-content-paragraph">9.5 Entire Agreement: These Terms, including Privacy Policy and AML Policy are product or service specific details or terms as may be mentioned on our Platform, comprise the entire understanding and agreement between You and Us and it supersedes any and all prior discussions, agreements and understandings of any kind (including any information, material or communication you may have seen on our Platforms or may have received from Us).</p>


            </div>
        </div>
    </>
  );
};

export default Terms;

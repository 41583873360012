import React from "react";

export const Coinbase = ({ width = "50px", height = "50px" }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 124 123"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <g clipPath="url(#clip0_903_3187)">
      <path
        d="M62.2162 122.811C96.1304 122.811 123.622 95.3196 123.622 61.4054C123.622 27.4912 96.1304 0 62.2162 0C28.302 0 0.810791 27.4912 0.810791 61.4054C0.810791 95.3196 28.302 122.811 62.2162 122.811Z"
        fill="#1652F0"
      />
      <path
        d="M51.5313 25.8665C60.1258 22.3289 70.0249 22.0872 78.7806 25.195C84.1828 27.0713 89.1093 30.2251 93.1687 34.2385C89.2475 38.2135 85.2917 42.1501 81.3359 46.0867C78.2012 43.1553 74.3375 40.9415 70.0824 40.1933C63.5445 38.8811 56.4616 40.8916 51.562 45.4037C47.9784 48.619 45.5036 53.0659 44.7516 57.8312C43.5929 64.3155 45.6839 71.2832 50.1999 76.0716C53.5456 79.7243 58.1805 82.153 63.0917 82.8014C69.7064 83.8182 76.5629 81.3434 81.3359 76.7315C85.284 80.672 89.259 84.5894 93.1572 88.5797C86.742 94.9872 78.0132 99.0658 68.9544 99.6643C60.874 100.313 52.6056 98.3061 45.7415 93.982C36.7288 88.4147 30.2023 78.9531 28.3184 68.5168C26.9947 61.2153 27.6968 53.5071 30.5975 46.6507C34.4573 37.3156 42.1579 29.6458 51.5313 25.8665Z"
        fill="white"
      />
    </g>
  </svg>
);

export default Coinbase;

/* eslint-disable jsx-a11y/anchor-is-valid */
/* eslint-disable jsx-a11y/anchor-has-content */
/* eslint-disable react/style-prop-object */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from "react";
import { fetchApi } from "../../../_config/api";
import {Link, useNavigate} from "react-router-dom";
import { notification } from "antd";
import {Card, EmptyCard} from "../../project/card";
import { PaginationSection, Filters, TableContent } from "../../../library";
import {
  deepCopy,
  getUserDetails,
  DEVELOPERROLE,
  ENTERPRISEDEVELOPERROLE,
} from "../../../deps";
import { tempData } from "../../project/tempData";
import "./styles.less";
import { ACCESS } from "../../../_config/constant";
import {CloseCircleOutlined, SearchOutlined} from "@ant-design/icons";
import Restricted from "../../../../auth/Restricted";
import Skeleton from "react-loading-skeleton";
import { DAppcolumns } from "./columns";
import {ConnectAppkey} from "../MyProjects/connect-appkey";
import addIcon from "../SmartContract/addIcon.png";
import {CreateDApp} from "../MyProjects/create-dapp";

export const DappTable = ({ showLoader,isLoading }) => {
  const navigate = useNavigate();
  const [pageSetting, setSetting] = useState({
    page: 1,
    itemsPerPage: 10,
    searchText: "",
    filterValue: "",
  });
  const { userRole,userType } = getUserDetails().profile || {};
  const [data, setData] = useState([]);
  const [filteredData, setFilteredData] = useState([]);
  const [hitsList, setHitsList] = useState([]);
  const [totalCount, setTotalCount] = useState(-1);
  const startIndex = (pageSetting.page - 1) * pageSetting.itemsPerPage;
  const [filterType, setFilterType] = React.useState('all');
  const [filterValue, setFilterValue] = React.useState('');
  const [rpcUrl, setrpcUrl] = React.useState('');
  const [isFilter, setIsFilter] = React.useState(false);
  const [showSearch, setShowSearch] = React.useState(false);
  const [isProtocolType, setIsProtocolType] = React.useState(false);
  const [allProtocolDetails, setAllProtocolDetails] = React.useState([]);
  const [isService, setIsService] = React.useState(false);
  const [showclose,setShowclose] = useState(false)
  const [loader1,setLoader1]=useState()
  const [createDAppOpen, setCreateDAppOpen] = useState(false);
  console.log(loader1)
  const [dAppId, setDAppId] = React.useState('');
  const [walletId, setWalletId] = React.useState('');

  const [teamList, setTeamList] = useState([]);
  const userData = getUserDetails();
  console.log(filteredData)
  console.log(data)

  React.useEffect(() => {
    if(userData.profile.userRole === ENTERPRISEDEVELOPERROLE){
      getTeamsList();
    }
  }, []);



  const [connectAppKeyOpen, setConnectAppKeyOpen] = useState(false);

  const addOpenConnectAppKeyModal = (endpoints,dappId,services) => {
    setConnectAppKeyOpen(true); // Function to set connectAppKeyOpen to true
    setrpcUrl(endpoints?.BlockchainEndpoint[0]?.RPC_URL);
    setDAppId(dappId);
    setWalletId(services?.NonCustodialWallet?.InstanceID);
  };

  const DAppcolumns = [
    {
      title: "",
      dataIndex: "",
    },
    {
      title: "dApp Name",
      dataIndex: "projectName",
      // render: (_, { endpointName,_id,infraType }) => (
      //     <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
      //       {endpointName}
      //     </Link>
      // )
    },
    {
      title: "App ID",
      dataIndex: "projectID",
    },
    {
      title: "",
      dataIndex: "",
    },
    {
      title: "",
      dataIndex: "",
    },
    {
      title: "Description",
      dataIndex: "description",
      // render: (_, { userEndpoint,_id,infraType }) => (
      //     <>
      //       <Link to={"/endpointsDetails/"} state={{id:_id,infraType:infraType}}>
      //         {userEndpoint}
      //       </Link>
      //     </>

      // ),
    },
    // {
    //   title: "App ID",
    //   dataIndex: "userEndpoint",
    //   render: (_, { userEndpoint,_id }) => (
    //       <a>
    //         <CopyOutlined onClick={() => onCopy(userEndpoint)} />
    //       </a>
    //   ),
    // },
    {
      title: "Token",
      dataIndex: "token",
    },
    {
      title: "",
      dataIndex: "",
    },

    // {
    //   title: "Status",
    //   dataIndex: "status",
    // },
    {
      title: <div >Action <span style={{visibility:'hidden'}}>Action----</span></div>,
      dataIndex: '',
      render: (_, {projectID, endpoints,services }) => (
          <>
            <div>
              <button className="new-button" onClick={() => addOpenConnectAppKeyModal (endpoints,projectID,services)} >App Key</button>
              &nbsp;&nbsp;
              <Link to={`/my-dapps/dAppDetails-page/${projectID}`}>
                <button className="new-button" style={{color: "white"}}>Manage dApp</button>
              </Link>
            </div>
          </>
      ),
    }
  ];

  useEffect(() => {
    getProjectList();
    getProtocolDetails();
    setHitsList([3, 6, 9, 12, 15]);
  }, []);

  useEffect(() => {
    onFilterDataChange();
  }, [data, pageSetting]);

  const getTeamsList = () => {
    const filter = {value:"",status:""}

    const Options = () => {
      return {
        sort: "",
        page: 0,
        limit: 2,
      };
    };

    let requestLink = `teams/getAll?options=${JSON.stringify(
        Options()
    )}&filter=${JSON.stringify(filter)}`;

    fetchApi({ method: "GET", url: requestLink, isDevApp: false })
        .then((response) => {
          const { docs = [] } = response;
          if(docs.length > 0){
            setTeamList([...docs]);
          }else{
            setTeamList([]);
          }
        })
        .catch(() => {
          setTeamList([]);
        });
  };
  const getProjectList = (value) => {
    ;
    // setLoader1(true)
    if(isFilter === true && filterType !== "all") {
      if (filterValue === ""){
        notification.error({
          message: "Please enter/select value to search",
          duration: 3,
        });
        ;
        return false;
      }
    }
    const Options = () => {
      setHitsList([3, 6, 9, 12]);
      return {
        select: [],
        sort: {},
        page: pageSetting.page - 1,
        limit: pageSetting.itemsPerPage,
      };
    };
    const filter = {};
    let data = {}
    if (value === 'clear'){
      data = {
        "filterType":'',
        "filterValue":''
      }
    }else{
      data = {
        "filterType":filterType,
        "filterValue":filterValue
      }
    }
  // setLoader1(true)  
    fetchApi({ method: "GET", url: `projects/findAll?options=${JSON.stringify(Options())}&filter=${JSON.stringify(filter)}&searchFilter=${JSON.stringify(data)}`, isDevApp: false })
    .then((response)=>{
      if (response.statusCode === 200) {
        if(response.docs.length>0){
          setData(response.docs);
          ;
          setLoader1(false)
          setTotalCount(response.meta.totalDocs)
        }
        else{
          setLoader1(false)
        }
           
       }
    }).catch((error)=>{
      setLoader1(false);
      ;
        notification.error({
          message: error.toString(),
          duration: 3,
        });
    })
    // try {
    //   setLoader1(true)
    //   const response = await fetchApi({
    //     method: "GET",
    //     url: `projects/findAll?options=${JSON.stringify(Options())}&filter=${JSON.stringify(filter)}&searchFilter=${JSON.stringify(data)}`,
    //     isDevApp: false,
    //   });
    //   if (response.statusCode === 200) {
    //     setData(response.docs);
    //     setLoader1(false)
    //     ;
    //     setTotalCount(response.meta.totalDocs)
    //   }
    // }catch (error) {
    //   ;
    //   setLoader1(false)
    //   notification.error({
    //     message: error.toString(),
    //     duration: 3,
    //   });
    // }
  };
    

  const onFilterDataChange = () => {
    const endIndex =
      pageSetting.page * pageSetting.itemsPerPage > data.length
        ? data.length
        : pageSetting.page * pageSetting.itemsPerPage;

    const filterList = (
      pageSetting.searchText
        ? data.filter((item) =>
        item.name.toLowerCase().includes(pageSetting.searchText.toLowerCase()) || item.description.toLowerCase().includes(pageSetting.searchText.toLowerCase())
          )
        : deepCopy(data)
    )
      .filter((item) =>
        pageSetting.filterValue !== ""
          ? (pageSetting.filterValue === "locked" && item.projectLock) ||
            (pageSetting.filterValue === "unlocked" && !item.projectLock)
          : true
      )
      .slice(startIndex, endIndex);
    setFilteredData(filterList);
  };


  const onPageChange = (pageNumber) => {
    pageSetting.page = Number(pageNumber);
    setSetting({ ...pageSetting });
    getProjectList();
  };

  const onSearchFilter = () => {
    pageSetting.page = Number(1);
    setSetting({ ...pageSetting });
    getProjectList();
  }

  const onPerPageChange = (value) => {
    pageSetting.itemsPerPage = Number(value);
    pageSetting.page = 1;
    setSetting({ ...pageSetting });
    getProjectList();
  };

  const onCopy = (apiKey) => {
    navigator.clipboard.writeText(apiKey);
    notification.success({
      message: "Copied to clipboard",
      duration: 3,
    });
  };

  const onExport = (item) => {
    ;
    fetchApi({ method: "GET", url: `projects/${item.projectID}/exportConfig` })
      .then((response) => {
        // console.log("respone----------->",response)
        const dataStr =
          "data:text/json;charset=utf-8," +
          encodeURIComponent(JSON.stringify(response));
        const projectExport = document.getElementById("projectExport");
        projectExport.setAttribute("href", dataStr);
        projectExport.setAttribute("download", `${item.projectName}.json`);
        projectExport.click();
        notification.success({
          message: "Successfully exported",
          duration: 3,
        });
      })
      .catch(() => {
        ;
        notification.error({
          message: "Something went wrong. Please try again",
          duration: 3,
        });
      });
  };

  const onNavigate = () => {
    navigate("/my-dapps",{state:{openModal:true}});
  };

  const onProjectNavigate = (id) => {
    navigate(`/project/${id}`);
  };

  const last =
    pageSetting.page * pageSetting.itemsPerPage > totalCount
      ? totalCount
      : pageSetting.page * pageSetting.itemsPerPage;
  const getProtocolDetails = () => {
    fetchApi({ method: "GET", url: `protocols/getAllProtocols` })
        .then((response) => {
          ;
          if (response.docs) {
            if(response.docs.length > 0){
              setAllProtocolDetails(response.docs);
            }
          }else{
            setAllProtocolDetails([]);
          }
        })
        .catch(() => {
          ;
        });
  };
  const onClear = (e) => {
    if (document.getElementById("proreset")){
      document.getElementById("proreset").value = ''
      getProjectList('clear')
      setShowclose(false);
    }
    console.log("filter",filterValue);

  };
   const reset = () =>{
     if (document.getElementById("proreset")){
       document.getElementById("proreset").value = ''
     }else{
       setIsProtocolType(false)
     }
   }
  const onChangeSelect = (e) => {
     if (filterValue !== ''){
       reset()
     }
    setFilterType('');
    setFilterValue('');
    setFilterType(e.target.value);
    if (e.target.value === ""){
      setIsFilter(false);
      setShowSearch(false);
    }else{
      setIsFilter(true);
      if ( e.target.value === "all"){
        setShowSearch(false)
      }else{
        setShowSearch(true)
      }
      if (e.target.value === "endpoints"){
        setIsProtocolType(true)
      }else{
        setIsProtocolType(false)
      }
      if (e.target.value === "services"){
        setIsService(true)
      }else{
        setIsService(false)
      }
    }
  };


  const onChange = (e) => {
    setFilterValue('');
    setFilterValue(e.target.value);
  };
  const onKeypress = (e) => {
    if (e.target.value !== '') {
      setShowclose(true)
    }else{
      setShowclose(false)
    }
  };

  const addConnectAppKeyModal = () => {
    setConnectAppKeyOpen(!connectAppKeyOpen);
  };
  const addCreateDAppModal = () => {
    setCreateDAppOpen(!createDAppOpen);
  };
  const openCreateDAppModal = () => {
    setCreateDAppOpen(true);
  };

  return (
    <div className="project-page-container" style={{marginTop:'40px'}}>
      <a id="projectExport" style={{ display: "none" }} />
      {/* {isLoading?(<Skeleton height={55} style={{marginBottom:'50px'}} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(
         <></>
      )} */}
      {userData.profile.userRole === ENTERPRISEDEVELOPERROLE && teamList.length === 0 ? <></> :
          /*<div className="data-section">*/<>
            {isLoading?(<Skeleton height={420} baseColor="#262626" highlightColor="#404040" ></Skeleton>):(<>

            {data.length > 0 ?
                <>
                  <div style={{ display: 'flex', justifyContent: 'flex-end'  }} >
                    <button className="sc-button" onClick={onNavigate}>
                      <span style={{
                        padding: "15px",
                        fontFamily: 'Poppins',
                        fontSize: '18px',
                        fontWeight: '700',
                        lineHeight: '35px'
                      }}><img style={{marginBottom: "2px", marginRight: '5px', marginLeft: "5px"}} width="20px"
                              height="20px" src={addIcon}/> Create </span>
                    </button>
                  </div>
                  <TableContent
                      rowKey="endpointId"
                      data={filteredData}
                      columns={DAppcolumns}

                      isDashboard={false}
                      pagination={false}
                  />
                  {/* <div className="data-section">
                    {data.map((item) => (
                    <Card key={item.projectId} item={item} onCopy={onCopy} onExport={onExport} onNavigate={onProjectNavigate}/>
                    ))}
                  </div> */}
                  <PaginationSection
                      last={last}
                      first={startIndex + 1}
                      hitsList={hitsList}
                      totalCount={totalCount}
                      page={pageSetting.page}
                      itemsPerPage={pageSetting.itemsPerPage}
                      onPageChange={onPageChange}
                      onPerPageChange={onPerPageChange}
                  />
                </>
                : <>
                  {loader1 === undefined ?
                      <Skeleton height={420} baseColor="#262626" highlightColor="#404040"></Skeleton> :
                      <EmptyCard onNavigate={onNavigate} isLoading={isLoading}/>}
                </>}
              {/*{data.length > 0 ? data.map((item) => (
                <Card
                    key={item.projectId}
                    item={item}
                    onCopy={onCopy}
                    onExport={onExport}
                    onNavigate={onProjectNavigate}
                />
            )) : <EmptyCard
                onNavigate={onNavigate}
                isLoading={isLoading}
            />}*/}

           </> )}
          {/*</div>*/}
            </>
      }
      <ConnectAppkey
          isOpen={connectAppKeyOpen}
          toggleState={addConnectAppKeyModal}
          showLoader={showLoader}
          isLoading={isLoading}
          rpcUrl={rpcUrl}
          dappId={dAppId}
          walletId={walletId}
      />
      <CreateDApp
          isOpen={createDAppOpen}
          toggleState={addCreateDAppModal}
          showLoader={showLoader}
          isLoading={isLoading}
          getProjectLit={getProjectList}
      />

    </div>
  );
};

export default DappTable;

